import React from "react";
import { FormattedMessage } from "react-intl";
import messages from "bundles/common/intl/messages";
import Cookies from "universal-cookie";

const universalCookies = new Cookies();
export interface FormErrorProps {
  message: string;
  id: string;
  code: string;
  userNameErrorCount: number;
}
/**
 *
 * @param code => Cognito Error Code.
 * @param triggeredFrom => Optional? Triggered Form
 */
function renderMessage(
  code: string,
  message: string,
  userNameErrorCount: number,
) {
  const supportLang = universalCookies.get("ulang")
    ? universalCookies.get("ulang").toLowerCase() === "en"
      ? "en-en"
      : universalCookies.get("ulang").toLowerCase()
    : "en-en";

  const checkDirectLoginFlag = message
    ? JSON.stringify(message).includes("DirectLoginNotAllowedException")
    : false;
  const disableUserFlag = message
    ? JSON.stringify(message).includes("DisableInactiveUserException")
    : false;
  switch (code) {
    case "DirectLoginNotAllowed":
      return <FormattedMessage {...messages.directLoginError} />;
    case "InactiveUser":
      return <FormattedMessage {...messages.inactiveUserError} />;
    case "ValidationError":
      return message;
    case "UserNotFoundException":
      if (checkDirectLoginFlag) {
        return <FormattedMessage {...messages.directLoginError} />;
      } else if (disableUserFlag) {
        return <FormattedMessage {...messages.inactiveUserError} />;
      }
      if (userNameErrorCount >= 11) {
        return (
          <FormattedMessage
            {...messages.duplicateUserNameError}
            values={{
              contactSupport: (
                <a
                  href={`${process.env.REACT_APP_GE_SUPPORT_URL}/${supportLang}/contact_support`}
                >
                  Contact Support
                </a>
              ),
            }}
          />
        );
      } else {
        return <FormattedMessage {...messages.usernameOrPasswordError} />;
      }
    case "NotAuthorizedException":
      if (userNameErrorCount >= 11) {
        return (
          <FormattedMessage
            {...messages.duplicateUserNameError}
            values={{
              contactSupport: (
                <a
                  href={`${process.env.REACT_APP_GE_SUPPORT_URL}/${supportLang}/contact_support`}
                >
                  Contact Support
                </a>
              ),
            }}
          />
        );
      } else {
        return <FormattedMessage {...messages.usernameOrPasswordError} />;
      }
    case "PasswordResetRequired":
      return "Your password has expired. Please enter your email address to reset the password."
    default:
      return "Something went wrong";
  }
}

export const FormError: React.SFC<FormErrorProps> = (props) => {
  return (
    <div
      id={props.id}
      className="error"
      style={
        props.message
          ? { display: "block", textAlign: "center", width: "100%" }
          : { display: "none" }
      }
    >
      <span>
        {renderMessage(
          props.code,
          props.message,
          props.userNameErrorCount,
        )}
      </span>
    </div>
  );
};
