// @ts-nocheck
import React from "react";

export default function DeDeContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>Copyright-, Marken- und sonstige Bekanntmachungen</b>
          </center>
        </div>
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>GLOBALENGLISH COPYRIGHT</b>
          </p>
          <p />
          <p>
            {" "}
            Copyright © 1999–2012 GlobalEnglish Corporation, alle Rechte
            vorbehalten{" "}
          </p>
          <br />
          <p>
            {" "}
            <b>COPYRIGHTS VON DRITTANBIETERN</b>{" "}
          </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>, Erstauflage, 1995
            <br /> © Longman Group UK Limited 1995{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> © Longman Group Limited 1995{" "}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p*/}
          <p>
            {" "}
            Copyright © 1995–1998 Macromedia, Inc., alle Rechte vorbehalten{" "}
          </p>
          <p>
            {" "}
            Copyright © 1999 Microsoft Corporation, alle Rechte vorbehalten{" "}
          </p>
          <p>
            {" "}
            Copyright © 1999 Sipro Lab Telecom Inc., alle Rechte vorbehalten{" "}
          </p>
          <p>
            {" "}
            Copyright © Business English Pod Limited, alle Rechte vorbehalten
            Alle Inhalte, einschließlich Podcasts, Phrasecasts, Videocasts,
            Lernhinweisen und elektronischen Hörbüchern, sind Eigentum der
            Business English Pod Limited und werden von der GlobalEnglish
            Corporation mit Genehmigung der Eigentümer verwendet.{" "}
          </p>
          <p>
            {" "}
            WGrammar Grammar Checker Engine Copyright © 1998–2006 Wintertree
            Software Inc. (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.wintertree-software.com"
            >
              <div
                onmouseout="this.className='blu11'"
                onmouseover="this.className='blu11rllover'"
                className="blu11"
              >
                www.wintertree-software.com
              </div>
            </a>
            ){" "}
          </p>
          <p>
            {" "}
            Einschließlich der EduSpeak<sup>®</sup>-Spracherkennungssoftware
            unter Lizenz von SRI International
            <sup>®</sup>. Copyright © SRI International.{" "}
          </p>
          {/*p> Systran Translation Engine &copy; SYSTRAN Software, Inc </p*/}
          <p>
            {" "}
            Die abgebildeten Fotos von AP/Wide World Photos sind
            urheberrechtlich geschützt und Eigentum von Associated Press. Jede
            Verwendung ohne vorherige Genehmigung von AP/Wide World Photos ist
            untersagt. Jeder Missbrauch wird strafrechtlich verfolgt.{" "}
          </p>
          <p>
            {" "}
            Die abgebildeten Fotos von PhotoDisc sind urheberrechtlich geschützt
            und Eigentum der PhotoDisc, Inc. Bilder Copyright 1999 PhotoDisc,
            Inc.{" "}
          </p>
          <p>
            {" "}
            Copyright © Barron's Educational Series, Inc., aus HOW TO PREPARE
            FOR THE TOEIC<sup>®</sup>{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i> © 1998 AMACOM NEW MEDIA, eine 100 %ige
            Tochtergesellschaft der American Management Association, New York.
            Alle Rechte vorbehalten.{" "}
          </p>
          <p>
            {" "}
            Die abgebildete ClipArt von Microsoft ist urheberrechtlich geschützt
            und Eigentum der Microsoft Corporation und/oder ihrer Zulieferer.
            Jede Verwendung ohne vorherige Genehmigung von Microsoft ist
            untersagt.{" "}
          </p>
          <p>
            {" "}
            Die abgebildete ClipArt von ArtToday ist urheberrechtlich geschützt
            und Eigentum der Zedcor, Inc., einer 100 %igen Tochtergesellschaft
            der IMSI Inc. und/oder ihrer Zulieferer. Jede Verwendung ohne
            vorherige Genehmigung von Zedcor ist untersagt.{" "}
          </p>
          <p>
            {" "}
            Die abgebildete ClipArt von Eyewire ist urheberrechtlich geschützt
            und Eigentum der Eyewire Inc. und/oder ihrer Zulieferer. Jede
            Verwendung ohne vorherige Genehmigung von Eyewire ist untersagt.{" "}
          </p>
          <p>
            {" "}
            Die abgebildeten Fotos von Corbis sind urheberrechtlich geschützt
            und Eigentum der Corbis Corporation und/oder ihrer Zulieferer. Jede
            Verwendung ohne vorherige Genehmigung von Corbis ist untersagt.{" "}
          </p>
          <p>
            {" "}
            Die abgebildeten Fotos von ImageQuest sind urheberrechtlich
            geschützt und Eigentum von ImageQuest und/oder seinen Zulieferern.
            Jede Verwendung ohne vorherige Genehmigung von ImageQuest ist
            untersagt.{" "}
          </p>
          <p />
          <br />
          <p>
            <b>Marken von GlobalEnglish</b>
          </p>
          <p />
          <p>
            Im Folgenden werden einige der Marken im Besitz der GlobalEnglish
            Corporation sowie ausgewählte Gattungsbezeichnungen für diese Marken
            in den Vereinigten Staaten und anderen Ländern genannt. Wenn ein
            Name oder ein Logo nicht in dieser Liste aufgeführt ist, stellt dies
            keinen Verzicht auf das geistige Eigentumsrecht dar, das die
            GlobalEnglish Corporation oder ihre Tochterunternehmen an Produkten,
            Funktionen, Service-Bezeichnungen oder Logos haben. Wenn Sie
            Hinweise zum korrekten Gebrauch der Produktnamen und Marken von
            GlobalEnglish benötigen, fordern Sie bitte ein Exemplar der
            allgemeinen Markenrichtlinien von GlobalEnglish an.
          </p>
          <p />
          <br />
          <style
            dangerouslySetInnerHTML={{
              __html: " \t\ttd{padding:10px !important} \t\t",
            }}
          />
          <table
            border={1}
            cellPadding={10}
            cellSpacing={0}
            bordercolor="#808080"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  TradeMark
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Status
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  Enterprise Fluency
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Bloom
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Corporate Learning Service
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Edge
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo Pro
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Magazine
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Mobile Learning Network
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Productivity Toolbar
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP+
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Motivator
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Executive Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>MARKEN VON DRITTANBIETERN</b>
          </p>
          <p />
          <p>
            {" "}
            Adobe und Flash sind Marken bzw. eingetragene Marken von Adobe
            Systems Incorporated in den Vereinigten Staaten und/oder anderen
            Ländern.
          </p>
          <p>
            {" "}
            Microsoft ist eine eingetragene Marke der Microsoft Corporation.{" "}
          </p>
          {/*p> Langenscheidt KG ist eine eingetragene Marke der Langenscheidt KG, Berlin und M&#252;nchen. </p*/}
          <p>
            {" "}
            Living Language und Colophon sind eingetragene Marken der Random
            House, Inc.{" "}
          </p>
          <p>
            {" "}
            Sipro und das Sipro-Logo sind Marken der Sipro Lab Telecom Inc.
            ACELP<sup>®</sup> ist eine eingetragene Marke der Université de
            Sherbrooke und wird von der Sipro Lab Telecom Inc. in Lizenz
            verwendet.{" "}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International, das SRI International-Logo, EduSpeak und
                  das EduSpeak-Logo sind Marken oder eingetragene Marken der SRI
                  International.
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p> SYSTRAN ist eine eingetragene Marke oder Dienstleistungsmarke von SYSTRAN Software, Inc., und das SYSTRAN-Logo ist eine eingetragene Dienstleistungsmarke von SYSTRAN Software, Inc. </p*/}
          <p> AP/Wide World Photos ist eine Marke von Associated Press. </p>
          <p> PhotoDisc ist eine eingetragene Marke der PhotoDisc, Inc. </p>
          <p>
            {" "}
            Business English Pod, www.businessenglishpod.com und die Business
            English Pod-Logos sind Marken der Business English Pod Limited.{" "}
          </p>
          <p>
            {" "}
            Alle anderen Marken sind Eigentum der jeweiligen
            Markenrechtsinhaber.{" "}
          </p>
          <p />
        </div>
      </div>
    </>
  );
}
