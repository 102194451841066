import React, { useState, useEffect } from "react";
import { InputField } from "bundles/common/components/InputField";
import { FormError } from "bundles/common/components/FormError";
import { ButtonField } from "bundles/common/components/ButtonField";
import { BasicSkeleton } from "bundles/common/components/BasicSkeleton";
import { Loader } from "bundles/common/components/Loader";
import { FormattedMessage } from "react-intl";
import Cookies from "universal-cookie";
import messages from "bundles/common/intl/messages";
import { MaintenancePage } from "bundles/common/components/MaintenanceWindow/MaintenanceWindow";
const urlParams = new URLSearchParams(window.location.search);
const user = urlParams.get("username") || "";
const universalCookies = new Cookies();
const supportLang = universalCookies.get("ulang")
  ? universalCookies.get("ulang").toLowerCase() === "en"
    ? "en-en"
    : universalCookies.get("ulang").toLowerCase()
  : "en-en";

export interface LoginFormProps {
  errorCount: any;
  forgotPassword: any;
  error:any;
  resetError:any;
  username:any;
  differentAccount:any;
  submit:any;
  loading:any;

}

export const RegisterSection = () => (
  <div>
    <a
      href={`${process.env.REACT_APP_GE_REG_URL
        }?trans_lang=${supportLang.toUpperCase()}`}
      title="Register"
    >
      <FormattedMessage {...messages.register} />
    </a>
  </div>
);

export const LoginForm: React.SFC<LoginFormProps> = (props:LoginFormProps) => {
  const [userName, setUsername] = useState(user);
  const [password, setPassword] = useState("");
  const [duplicateUsername, setDuplicateUsername] = useState(0);
  const [validateUsername, setValidateUsername] = useState({});
  const [validatePassword, setValidatePassword] = useState({});
  const [showValidationError, setShowValidationError] = useState(false);
  const [showMaintenance, setShowMaintenance] = useState(false)

  useEffect(() => {
    if (props.errorCount !== 0) emptyPassword();
  }, [props.errorCount]);

  useEffect(()=>{
    setShowMaintenance(new Date() < new Date(process.env.REACT_APP_MAINTENANCE_WINDOW_LASTDATE)?true:false)
    console.log(urlParams.get("forgotpassword"))
    if(urlParams.get("forgotpassword")){
      props.forgotPassword(true);
    }
  },[])
  useEffect(() => {
    if (
      props.error.code === "UserNotFoundException" ||
      props.error.code === "NotAuthorizedException"
    ) {
      setDuplicateUsername(duplicateUsername + 1);
    }
  }, [props.error.code]);

  useEffect(() => {
    if (userName) setValidateUsername({});
    if (password) {
      props.resetError();
      setValidatePassword({});
    }
    setUsername(props.username ? props.username : userName);
  }, [userName, password]);

  function handleSubmit() {
    validate();
    if (userName && password) {
      props.submit(userName, password);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  }

  // function handleDifferentAccount() {
  //   props.differentAccount(true);
  //   setUsername("");
  // }

  function handleForgotPassword(val) {
    props.forgotPassword(val);
  }

  function validate() {
    if (userName) {
      setValidateUsername({});
    }
    if (password) {
      setValidatePassword({});
    }
    if (userName && password) {
      setShowValidationError(false);
    }

    if (!userName) {
      setValidateUsername({
        validateId: "reqUsername",
        validateMessage: <FormattedMessage {...messages.validateUsername} />,
      });
      setShowValidationError(true);
    }

    if (!password) {
      setValidatePassword({
        validateId: "reqPassword",
        validateMessage: <FormattedMessage {...messages.validatePassword} />,
      });
      setShowValidationError(true);
    }
  }

  function emptyPassword() {
    setPassword("");
  }

  return (
   <>
    
    {  
  showMaintenance ? <MaintenancePage closeWindow={()=>{setShowMaintenance(false)}} />:
  <BasicSkeleton title={<FormattedMessage {...messages.welcome} />}>
    <div className="lform" data-name="f" id="login-form">
      {!showValidationError && (
        <FormError
          id="reqUsername"
          code={props.error.code}
          message={props.error.message}
          userNameErrorCount={duplicateUsername}
        />
      )}
      <InputField
                label={<FormattedMessage {...messages.username} />}
                labelId="lblUsename"
                value={props.username ? props.username : userName}
                id="txtUsername"
                className="txt"
                name="LoginForm[username]"
                type="text"
                onChangeFnWithParam={(e) => setUsername(e.target.value)}
                placeHolder={<FormattedMessage {...messages.username}/>}
                validation={validateUsername}
                showValidation={showValidationError}     />
      <InputField
                label={<FormattedMessage {...messages.password} />}
                labelId="lblPassword"
                value={password}
                id="txtPassword"
                className="txt"
                name="LoginForm[password]"
                type="password"
                onClickFn={props.resetError}
                onChangeFnWithParam={(e) => setPassword(e.target.value)}
                onKeyDownWithParam={(e) => handleKeyPress(e)}
                placeHolder={<FormattedMessage {...messages.password} />}
                validation={validatePassword}
                showValidation={showValidationError}      />
      <fieldset className="button-padding">
        <div className="remember">
          <input
            type="checkbox"
            value="1"
            id="remember_me"
            name="remember_me"
          />
          <span>Remember me</span>
          <input type="hidden" value="" name="redirectUrl" />
          <input type="hidden" value="" name="source" />
          <input type="hidden" value="EN" name="lang_code_upper" />
        </div>
        {props.loading ? (
          <Loader />
        ) : (
          <ButtonField
            value={<FormattedMessage {...messages.logIn} />}
            id="btnLogin"
            type="submit"
            name="yt0"
            onClickFn={handleSubmit}
            isLoading={props.error}
          />
        )}
      </fieldset>
    </div>
    <div className="login-foot">
      <ul>
        <RegisterSection />
        <div className="first">
          <a
            href="#"
            title="Forgot your password?"
            onClick={()=>{handleForgotPassword(true)}}
          >
            {<FormattedMessage {...messages.forgotPassword} />}
          </a>
        </div>
      </ul>
    </div>
  </BasicSkeleton>
}
   </>
          
  );
};
