import React from "react";

export interface BasicSkeletonProps {
  title: any;
  children: any;
}

export const BasicSkeleton: React.SFC<BasicSkeletonProps> = (props) => (
  <div id="content" className="content content-wrapper">
    <div className="loginWrap welcome-pod">
      <div
        className="login"
        id="login_block"
        style={{ display: "block !important" }}
      >
        <h2>{props.title}</h2>
        <div id="notificationWrap" className="notificationWrap" />
        <div className="lform" id="login-form">
          {props.children}
        </div>
      </div>
    </div>
  </div>
);
