import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { API, graphqlOperation } from "aws-amplify";
import { APPSYNC_AUTHENTICATION_TYPE, MFA_GE_FLAG } from '../../constants';
import { datadogLogs } from "@datadog/browser-logs";

const AmazonCognitoIdentity = require("amazon-cognito-identity-js");
const CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;
const globalFetch: any = global;
globalFetch.fetch = require("node-fetch");
const cookieUtils = require("./cookieHelper");

const GetPeopleCall = (geId: string) => {
  return `query{
    getpeopleInformation(geId:"${geId}"){
        flags
        serviceConfiguration
        {
            features
        }
      }
  }`;
};

const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_USERPOOLID,
  ClientId: process.env.REACT_APP_AWS_CLIENT_ID,
  endpoint: process.env.REACT_APP_CUSTOM_COGNITO_URL
};

const userPool = new CognitoUserPool(poolData);

export const isMFAUser = (geId: string, cognitoUser: any) => {

  //For OnePortal Users, ignore calling platform API, since geId doesn't exist.
  if(!geId){
    return new Promise(resolve => resolve({peopleInfo: {}, cognitoUser}))
  }

  const data = API.graphql(
    graphqlOperation(GetPeopleCall(geId)),
  ) as Promise<any>;
  return new Promise((resolve, reject) => {
    data
      .then((peopleData: any) => {
        if (peopleData && peopleData.data) {
          resolve({
            peopleInfo: peopleData,
            cognitoUser,
          });
        } else {
          // Empty People data
          const err = {
            name: "EMPTYPEOPLEDATA",
            message: "People call returned empty data!",
          };
          reject(err);
        }
      })
      .catch((err: any) => {
        datadogLogs.logger.error('Cognito exception', err);
        reject(err);
      });
  });
};

const awsConfig = {
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: APPSYNC_AUTHENTICATION_TYPE,
}

export const setAWSConfig = (jwToken) => {
  API.configure(awsConfig);
  API.configure({
    API: {
      graphql_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      graphql_headers: () => ({
        Authorization: `${jwToken}`,
      }),
    },
  });
};

export const authUser = (username: string, password: string, callback: any) => {
  var authDetails = {
    Username: username,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authDetails);
  const userData = {
    Username: username.trim(),
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function (result: any) {
      setAWSConfig(result.idToken.jwtToken);
      //MFA Authentication is only handled for GE Users. OnePortal Users' MFA are yet to be handled
      isMFAUser(result.idToken.payload["custom:geId"] || "", cognitoUser).then(
        async ({ peopleInfo, cognitoUser }) => {
          const flags = peopleInfo?.data?.getpeopleInformation?.flags || "0";
          const MFAPlatformEnabled = !!(parseInt(flags, 10) & Number(MFA_GE_FLAG));

          if (MFAPlatformEnabled) {
            // FOR MFA users
            callback(null, result, true, -1, cognitoUser, true);
          } else {

            const features = peopleInfo?.data?.getpeopleInformation?.serviceConfiguration.features || {};
            result.features = features;
            // FOR non MFA users
            var data = await cookieUtils.setCookies(result);
            if (data) {
              const response = {
                message: "User Signed In Successfully",
                isAuthenticated: true,
                response: data.response,
                lsLearnerPortalUrl: data.response.lsLearnerPortalUrl || null,
              };
              callback(null, response, false, -1, null, false);
              if (data.response.lms) {
                const err = {
                  code: "DirectLoginNotAllowed",
                  name: "DisableLMSUser",
                  message:
                    "You must log in from your company's learning portal.",
                };
                callback(err, null, false, -1, null, false);
              } else if (data.response.isActive) {
                const response = {
                  lsLearnerPortalUrl: data.response.lsLearnerPortalUrl || null,
                  message: "User Signed In Successfully",
                  isAuthenticated: true,
                  response: data.response,
                };
                callback(null, response, false, -1, null, false);
              }else if (data.response.msg === "Success") {
                window.location.replace(data.response.url);
              }else {
                const err = {
                  code: "InactiveUser",
                  name: "DisableInactiveUser",
                  message:
                    "Your account is not active. Contact your administrator.",
                };
                callback(err, null, false, -1, null, false);
              }
            } else {
              const err = {
                code: "SETCOOKIEFAILURE",
                name: "FAILEDTOSETCOOKIE",
                message: "Something went wrong!",
              };
              callback(err, null, false, -1, null, false);
            }
          }
        },
        (err: any) => {
          callback(err, null, false, -1, null, false);
        },
      );
    },
    onFailure: function (err) {
      callback(err, null, false, -1, null, false);
    },

    /**
     * @function newPasswordRequired {<Promise>}
     * @param {*} userAttributes
     */
    newPasswordRequired: function (userAttributes) {
      delete userAttributes.email_verified;
      var newPassword = window.prompt("Please choose a new password..");
      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        userAttributes,
        this,
      );
    },
    totpRequired: (res) => {
      callback(null, {}, true, 1, cognitoUser, true);
    },
  });
};
