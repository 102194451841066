// @ts-nocheck
import React from 'react';

function RuRuContent() {
  return (
    <div id="agreement_container">
      <div style={{ padding: '1% 5% 0 5%' }}>
        <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
          <b>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
          <b>Вступление </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          Мы рады, что Вы решили воспользоваться услугами GlobalEnglish, представленными на сайте или в мобильном
          приложении (далее по тексту именуемые «Услуги»).
          <br />
          <br /> Наши Услуги заключаются в предоставлении инструментария для изучения английского языка и
          онлайн-разработки, в том числе данные, текст, онлайн-тесты, проекты, видео, аудиоклипы, голосовые записи,
          опубликованные посты и комментарии, сценарии, графика, изображения, их отбор и композиции, программное
          обеспечение, учебные пособия, занятия, обучение, тренинги, сообщения и интерактивные функции, созданные и/или
          доступные на сайте и в мобильном приложении (совместно именуемые «Контент»). Входя на наш сайт, ознакамливаясь
          с информацией о нашей компании, ознакамливаясь с нашими Услугами, Вы уже являетесь потребителем
          предоставляемых нами Услуг.
          <br />
          <br /> Пожалуйста, внимательно ознакомьтесь с нижеизложенными положениями, подтвердите Ваше понимание их
          содержания и согласие с ними, прежде чем продолжить пользоваться Услугами.
          <br />
          <br />
          <b>
            ЕСЛИ ВАМ НЕ ИСПОЛНИЛОСЬ 18 ЛЕТ, ПОЖАЛУЙСТА, В ОБЯЗАТЕЛЬНОМ ПОРЯДКЕ ОЗНАКОМЬТЕСЬ С НАСТОЯЩИМ СОГЛАШЕНИЕМ
            ВМЕСТЕ С РОДИТЕЛЕМ ИЛИ ИНЫМИ ЗАКОННЫМ ПРЕДСТАВИТЕЛЯМИ (УСЫНОВИТЕЛЯМИ, ОПЕКУНАМИ, ПОПЕЧИТЕЛЯМИ) И ЗАРУЧИТЕСЬ
            ИХ СОГЛАСИЕМ НА ИСПОЛЬЗОВАНИЕ ВАМИ НАШИХ УСЛУГ ДО НАЧАЛА ИХ ИСПОЛЬЗОВАНИЯ. ПОЛЬЗУЯСЬ НАШИМИ УСЛУГАМИ, ВЫ
            ГАРАНТИРУЕТЕ, ЧТО ВАМ ИСПОЛНИЛОСЬ 18 ЛЕТ ИЛИ ЧТО ВЫ ПОЛУЧИЛИ СООТВЕТСТВУЮЩЕЕ СОГЛАСИЕ ВАШИХ РОДИТЕЛЕЙ ИЛИ
            ИНЫХ ЗАКОННЫХ ПРЕДСТАВИТЕЛЕЙ (УСЫНОВИТЕЛЕЙ, ОПЕКУНОВ, ПОПЕЧИТЕЛЕЙ) И ПРАВОМОЧНЫ ВСТУПИТЬ В ДОГОВОРНЫЕ
            ПРАВООТНОШЕНИЯ с GLOBALENGLISH.
          </b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
          <b>ОБЩИЕ ПОЛОЖЕНИЯ</b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 1. ЮРИДИЧЕСКИЕ ОБЯЗАТЕЛЬСТВА </b>
          <br />
          <br />
          <b>
            {' '}
            ВАШ ДОСТУП К НАШЕМУ САЙТУ И ИСПОЛЬЗОВАНИЕ УСЛУГ GLOBALENGLISH (ЯВЛЯЕТСЯ ПРЕДМЕТОМ НАСТОЯЩЕГО
            ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ, ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ И ПОЛИТИКОЙ COOKIE (ВСЕ ТРИ ДОКУМЕНТА ЯВЛЯЮТСЯ
            НЕОТЪЕМЛЕМОЙ ЧАСТЬЮ ОБЩЕГО, ИМЕНОВАННОГО КАК «УСЛОВИЯ ИСПОЛЬЗОВАНИЯ»). ВХОДЯ НА НАШ САЙТ, ПОСЕЩАЯ ЕГО,
            РЕГИСТРИРУЯСЬ И/ИЛИ ИСПОЛЬЗУЯ УСЛУГИ GLOBALENGLISH, ВЫ ПОДТВЕРЖДАЕТЕ, ЧТО ВЫ ОЗНАКОМИЛИСЬ И ПОНЯЛИ ПОЛОЖЕНИЯ
            УСЛОВИЙ ИСПОЛЬЗОВАНИЯ НАШИХ УСЛУГ И СОГЛАШАЕТЕСЬ С ИХ ЮРИДИЧЕСКОЙ СИЛОЙ, ОБЯЗУЕТЕСЬ ИХ НЕУКОСНИТЕЛЬНО
            СОБЛЮДАТЬ И ГАРАНТИРУЕТЕ, ЧТО ВЫ ДОСТИГЛИ НЕОБХОДИМОГО ВОЗРАСТА В ПОНИМАНИИ УСЛОВИЙ ДАННОГО СОГЛАШЕНИЯ ДЛЯ
            ВСТУПЛЕНИЯ В ДОГОВОРНЫЕ ПРАВООТНОШЕНИЯ С GLOBALENGLISH.
          </b>
          <br />
          <br /> Вы можете провести обзор наших политик
          <a
            href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
            rel="noopener noreferrer"
            target="_blank"
          >
            здесь
          </a>
          . Эти политикивключены в настоящее «Пользовательское соглашение» или «Соглашение» посредством ссылки, и все в
          целом они составляют Условия использования.
          <br />
          <br />
          <b>
            Обращаем Ваше внимание, что в настоящем Соглашении содержатся положения, регламентирующие порядок
            урегулирования претензий между сторонами. В частности, это Положения об отказе от гарантий, Положения об
            ограничении ответственности и Положения о правовых спорах, приведенные ниже. Положения настоящего Соглашения
            также содержат арбитражное соглашение, в соответствии с которым, за некоторыми исключениями, все претензии к
            нам и жалобы на нас, Вы должны будете передавать на рассмотрения в обязательный и окончательно определенный
            нами арбитраж. Вы имеете право на предъявление претензии лишь в индивидуальном порядке, но никак не в
            качестве истца либо члена какой-либо коллективной инициативы, или участника каких-либо исков или
            процессуальных действий. Вы вправе ходатайствовать о материальном возмещении, судебном запрете или
            освобождении от ответственности лишь в индивидуальном порядке.
            <br />
            <br /> НАСТОЯТЕЛЬНО ПРОСИМ ВАС НЕ ПОЛЬЗОВАТЬСЯ НАШИМИ УСЛУГАМИ И ПОКИНУТЬ НАШ САЙТ, В СЛУЧАЕ ВАШЕГО
            НЕСОГЛАСИЯ С КАКОЙ-ЛИБО ИЗ ЧАСТЕЙ УСЛОВИЙ ИСПОЛЬЗОВАНИЯ.
          </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. СФЕРА ДЕЙСТВИЯ </b>
          <br />
          <br /> Настоящее Соглашение распространяется как на Пользователей нашими услугами, так и на Посетителей наших
          сайтов. Лица, которые зарегистрировались у нас напрямую («Операция B2C»), в том числе и на условиях
          тестирования, и лица, зарегистрированные их работодателями либо через работодателей («Операция B2B»),
          именуются «Пользователи», тогда как незарегистрированные пользователи и посетители, просматривающие наши
          сайты, именуются «Посетителями». Термин «Пользователи» также включает в себя учителей, тренеров, специалистов
          по обучению и любых иных лиц, посещающих сайты GlobalEnglish с целью предоставления услуг иным пользователям.
          Местоимения второго лица в любом падеже («Вы», «Вам», «Вас» и т.п.), употребленные в нижеизложенном тексте,
          адресованы вышеуказанным категориям лиц, а именно, Пользователям и Посетителям.
          <br />
          <br /> Настоящее Соглашение распространяется на все Услуги GlobalEnglish.
          <br />
          <br /> При Вашей первичной регистрации в качестве Пользователя и в момент получения Вами доступа к нашим
          Услугам мы можем попросить Вас подтвердить свое согласие на соблюдение условий использования, щелкнув поле «Я
          согласен» или предоставив свою электронную подпись или любое иное средство аутентификации.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>3.ВНЕСЕНИЕ ИЗМЕНЕНИЙ В УСЛОВИЯ ИСПОЛЬЗОВАНИЯ </b>
          <br />
          <br />
          GlobalEnglish может вносить изменения в Условия использования в любое время, включая настоящее Соглашение,
          Политику cookie и Политику конфиденциальности, применимые к условиям использования Вами Услуг. Такие изменения
          вступают в силу немедленно после уведомления, направленного Вам электронным письмом на адрес Вашей электронной
          почты, указанный Вами при создании учетной записи в связи с вашей учетной записью в Службе (если это
          применимо), либо иными способами. Если Вы были зарегистрированы на нашем Сайте через Вашего работодателя или
          компанию, то обязанность уведомления о внесении изменений в наши Условия использования ложится на Вашего
          Работодателя или компанию.
          <br />
          <br /> Если Вы не согласны с такими изменениями, Вы имеете право удалить Вашу учетную запись и прекратить
          использование нашего сайта. Однако, Ваше дальнейшее использование наших Услуг после момента публикации или
          направления уведомления о внесении изменений в настоящие Условия, будет расцениваться как Ваше согласие с
          внесением таких изменений в Условия использования.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4.УСЛОВИЯ ИСПОЛЬЗОВАНИЯ УСЛУГ ТРЕТЬИХ ЛИЦ </b>
          <br />
          <br />
          Сервисы могут содержать ссылки на сторонние сайты («Сайты третьих лиц»), а также статьи, фотографии, тексты,
          переводы, графику, изображения, рисунки, аудио, видео, информацию и иной контент или элементы, принадлежащие
          третьим лицам или исходящие от третьих лиц («Контент третьих лиц»). Такие Сторонние сайты и Контент третьих
          лиц нами не изучаются, не отслеживаются и не проверяются на предмет корректности, уместности или полноты, и мы
          не несем никакой ответственности за Сайты третьих сторон, к которым осуществляется доступ через Сайт, или за
          Контент третьих лиц, размещенный на Сайте или доступный через Сайт.
          <br />
          <br /> Включение, ссылка или создание условий для использования какого-либо Стороннего сайта или любого
          Стороннего контента не подразумевает поддержки и/или одобрения со стороны GlobalEnglish. GlobalEnglish
          оставляет за собой право удалять такие ссылкам в любое время без предварительного уведомления. Ссылки на Сайте
          на любые компании, организации, продукты либо услуги, или иную информацию не являются и не подразумевают
          одобрение, спонсорство или рекомендацию, какую-либо их связь с GlobalEnglish.
          <br />
          <br /> Если Вы решите покинуть наш Сайт и получить доступ к сторонним сайтам либо получить доступ или начать
          использование какого-либо стороннего контента, Вы совершаете это на свой страх и риск, и Вы осведомлены о том,
          что наши условия и политики не распространяются на порядок использования Вами сторонних сайтов. Настоятельно
          рекомендуем Вам ознакомиться с условиями и политиками сторонних сайтов перед их использованием.
          <br />
          <br /> Мы предлагаем к использованию мобильные приложения через Android Market и iTunes Store (совместно с
          организациями, которые их обслуживают, именуемые далее по тексту - «Провайдеры»). Использование этих мобильных
          приложений также регулируется положениями и условиями Провайдеров, включая, помимо прочего, Правила
          использования, изложенные в Условиях обслуживания App Store. Если Вы загрузили какое-либо из этих мобильных
          приложений из iTunes Store, то этим Вы подтверждаете и соглашаетесь с фактом ознакомления и согласия с
          положениями Условий обслуживания App Store. Если Вы загрузили приложения из Android Market, то этим Вы
          подтверждаете и соглашаетесь с фактом ознакомления и согласия с положениями Условий обслуживания Android
          Market. Обратите внимание, что у Провайдеров отсутствуют какие-либо обязательства по предоставлению Вам
          технического обслуживания или поддержки в связи с использованием Вами Услуг. Провайдеры не предоставляют
          никаких гарантий на Услуги. Если какое-либо из мобильных приложений не соответствует какой-либо применимой
          гарантии и Вы загрузили такое приложение из iTunes Store, тогда Вы имеете право на предъявление претензии о
          возмещении покупной цены приложения (если таковая имеется) в адрес компании Apple. Настоящие Условия
          распространяются на использование Вами всех мобильных приложений, а также иных наших Услуг.
          <br />
          <br /> Вы принимаете и соглашаетесь с тем, что GlobalEnglish не несет ответственности за содержание, продукты,
          услуги или работоспособность этих сторонних сайтов и/или приложений. Вы безоговорочно отказываетесь от любых
          претензий к GlobalEnglish относительно сайтов или приложений третьих лиц. В частности, GLOBALENGLISH НЕ НЕСЕТ
          ОТВЕТСТВЕННОСТИ ЗА COOKIE, ВЕБ-МАЯКИ, НЕВИДИМЫЕ GIF-ФАЙЛЫ ИЛИ ДРУГОЙ КОД, КОТОРЫЙ ВЫ ИЛИ ДРУГИЕ СТОРОНЫ
          СОХРАНЯЕТЕ НА СВОЕМ КОМПЬЮТЕРЕ ТЕМ ИЛИ ИНЫМ СПОСОБОМ, ВКЛЮЧАЯ ЛЮБОЕ ОБЪЕДИНЕНИЕ КОНТЕНТА САЙТА С ТАКИМ КОДОМ.
          Например, если вы заходите на сторонний сайт по ссылке, размещенной на этом сайте, Ваш браузер или иные файлы
          cookie на Вашем компьютере могут привести к тому, что на этот сайт будет отправлен заголовок реферера,
          идентифицирующий часть Вашей истории посещенных страниц или URL-адреса, с которого произошел Ваш визит.
          GlobalEnglish не несет перед Вами никакой ответственности в связи с любой такой передачей информации, будь то
          информация, связанная с GlobalEnglish или исходящая от GlobalEnglish.
          <br />
          <br /> ЕСЛИ ВЫ ИСПОЛЬЗУЕТЕ ПЕРЕВОДЫ, ОСУЩЕСТВЛЯЕМЫЕ GOOGLE: GOOGLE НЕ ПРЕДОСТАВЛЯЕТ НИКАКИХ ГАРАНТИЙ
          ОТНОСИТЕЛЬНО ПЕРЕВОДОВ, ПРЯМЫХ ЛИБО КОСВЕННЫХ, ВКЛЮЧАЯ КАКИЕ-ЛИБО ГАРАНТИИ ТОЧНОСТИ, НАДЕЖНОСТИ, КОРРЕКТНОСТИ,
          ЛЮБЫЕ ВОЗМОЖНЫЕ ГАРАНТИИ ЦЕЛЕВОЙ ПРИГОДНОСТИ ПЕРЕВОДОВ.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>5. КРИТЕРИИ ПРИЕМЛЕМОСТИ ОБСЛУЖИВАНИЯ </b>
          <br />
          <br />
          <b>5.1 Защита конфиденциальности детей и ограничения на использование наших услуг. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Услуги не предназначены для использования лицами, не достигшими минимального возраста в стране, из которой
            они получают доступ к Услугам. Например, «минимальный возраст» в Европейском союзе, Европейском
            экономическом пространстве и Швейцарии составляет 16 лет, если иное не установлено законодательством
            конкретной страны. В Соединенных Штатах минимальный возраст составляет 13 лет. Любая регистрация,
            использование или доступ к Услугам лицами, не достигшими минимального возраста, запрещены и являются
            нарушением настоящих Условий.
            <br />
            <br /> Используя Услуги, Вы заявляете и гарантируете, что вам 13 лет или более.
          </span>
          <br />
          <br />
          <b>5.2 Школы и учителя </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Школы, округа или учителя в Соединенных Штатах, которые хотят, чтобы их ученики в возрасте до 13 лет
            создавали учетные записи GlobalEnglish, соглашаются с тем, что они несут ответственность за соблюдение
            Закона США о защите конфиденциальности детей в Интернете («COPPA») и, в соответствующих случаях, Закона о
            правах и конфиденциальности семьи в сфере образования («FERPA»). Это означает, что школы, округа или учителя
            должны уведомить родителей или иных законных представителей (усыновителей, опекунов, попечителей) учащихся о
            сборе личной информации, который будет осуществляться GlobalEnglish, и получить согласие родителей/ иных
            законных представителей (усыновителей, опекунов, попечителей) до момента создания учащимися учетных записей
            и начала использования ими Услуг. При получении такого согласия школы, округа и учителя должны предоставить
            родителям/ иным законным представителям (усыновителям, опекунам, попечителям) копию нашей Политики
            конфиденциальности, сохранять все полученные согласия и предоставить их нам в случае получения от нас
            соответствующего запроса.
            <br />
            <br />
            Для получения дополнительной информации о соблюдении COPPA, посетите
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
              rel="noopener noreferrer"
              target="_blank"
            >
              сайт Федеральной торговой комиссии.
            </a>
            <br />
            <br /> Если Вы находитесь за пределами США, мы будем рассчитывать на то, что Вы получите то необходимое
            согласие или одобрение от родителей или иных законных представителей (усыновителей, опекунов, попечителей)
            любого учащегося, которое требуется в соответствии с положениями аналогичного действующего законодательства,
            а также в качестве условия использования Вами и Вашими учениками наших Услуг, Вы соглашаетесь с тем, что
            ответственность за соблюдение таких законов возлагается на Вас.
          </span>
          <br />
          <br />
          <b>5.3 Родительское согласие </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            <b>
              ПОЛЬЗУЯСЬ НАШИМИ УСЛУГАМИ, ВЫ ГАРАНТИРУЕТЕ, ЧТО ВАМ ИСПОЛНИЛОСЬ 18 ЛЕТ ИЛИ ЧТО ВЫ ПОЛУЧИЛИ СООТВЕТСТВУЮЩЕЕ
              СОГЛАСИЕ ВАШИХ РОДИТЕЛЕЙ ИЛИ ИНЫХ ЗАКОННЫХ ПРЕДСТАВИТЕЛЕЙ (УСЫНОВИТЕЛЕЙ, ОПЕКУНОВ, ПОПЕЧИТЕЛЕЙ) И
              ПРАВОМОЧНЫ ВСТУПИТЬ В ДОГОВОРНЫЕ ПРАВООТНОШЕНИЯ с GLOBALENGLISH.
              <br />
              <br /> Мы ожидаем, что любое использование Услуг детьми будет осуществляться только под руководством,
              надзором и с согласия их родителей, иных законных представителей (усыновителей, опекунов, попечителей)
              и/или уполномоченных должностных лиц школы. GlobalEnglish полагается на родителей, иных законных
              представителей (усыновителей, опекунов, попечителей) и школы, относительно того, что несовершеннолетние
              пользуются Услугами, только если они осознают свои права и обязанности, изложенные в настоящих Условиях
              использования.
              <br />
              <br /> GlobalEnglish может по своему усмотрению отказать в предоставлении Услуг любому физическому или
              юридическому лицу и изменить свои критерии приемлемости в любое время.
            </b>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6.КОНФИДЕНЦИАЛЬНОСТЬ</b>
          <br />
          <br />
          <b>6.1 Положение о конфиденциальности </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Пожалуйста, ознакомьтесь с политикой конфиденциальности
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              здесь
            </a>
            .
            <br />
            <br /> Мы будем обрабатывать персональные данные в соответствии с политикой конфиденциальности, с
            положениями которой Вы согласились при первичном использовании Услуг либо в порядке планового обновления
            политики конфиденциальности.
          </span>
          <br />
          <br />
          <b>6.2 Персональные данные. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Для использования Сервисов у Вас должна быть действующая учетная запись. Для регистрации с целью получения
            наших Услуг Вы должны предоставить GlobalEnglish идентификатор пользователя, адрес электронной почты и
            другую информацию, которая считается информацией, идентифицирующей личность («Персональные данные»).
            Информация, которую мы получаем от Вас, необходима GlobalEnglish для предоставления Услуг либо для
            направления Вам информации об Услугах, запрос о которых Вы направляли, любая же иная информация не является
            обязательной. В некоторых случаях сами Услуги позволяют Вам предоставлять информацию о себе и о Вашей
            организации.
            <br />
            <br /> Настоящим Вы обязуетесь: (i) предоставлять точную, актуальную и полную информацию о Вас и, в случае
            операции B2B, о Вашей организации в соответствии с регистрационными формами на сайте; (ii) соблюдать
            конфиденциальность Вашего пароля и иной информации, связанной с безопасностью вашей учетной записи; (iii)
            поддерживать и своевременно обновлять Личные данные и любую иную информацию, предоставляемую Вами
            GlobalEnglish, с целью обеспечения точности, актуальности и полноты такой информации; и (iv) нести полную
            ответственность за любое использование Вашей учетной записи и за любые действия, которые происходят через
            Вашу учетную запись.
            <br />
            <br /> GlobalEnglish не берет на себя никаких обязательств по проверке точности личных данных, которые Вы
            предоставляете с целью регистрации.
          </span>
          <br />
          <br />
          <b>6.3 Международная передача Персональных данных </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            При предоставлении Услуг GlobalEnglish полагается на своих сотрудников по всему миру и использует сторонних
            поставщиков и хостинг-партнеров для хранения информации и иных технических функций, связанных с услугами по
            обучению. Таким образом, Ваши Персональные данные обрабатываются и передаются сотрудникам и партнерам
            GlobalEnglish в разных странах, некоторые из которых могут не обеспечивать такой же уровень защиты Ваших
            Персональных данных, который закреплен на законодательном уровне в стране Вашего гражданства или проживания.
            <b>
              Получая доступ, регистрируясь и/или используя наши Услуги, Вы даете согласие на передачу и обработку ваших
              Персональных данных в различных странах, в которых расположены GlobalEnglish, ее филиалы и партнеры с
              целью предоставдения Услуг.
            </b>
          </span>
          <br />
          <br />
          <b>6.4 Уведомления и сообщения. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Вы принимаете и соглашаетесь с тем, что Услуги могут включать в себя определенные сообщения от
            GlobalEnglish, такие как, объявления служб, административные сообщения и т.д., и что такие сообщения
            являются неотъемлемой частью Услуг, от получения которых Вы не можете отказаться.
            <br />
            <br /> Настоящим Вы соглашаетесь с тем, что GlobalEnglish может направлять Вам уведомления и сообщения
            следующими способами: (i) в рамках Сервиса; (ii) посредством контактной информации, предоставленной Вами
            GlobalEnglish (например, адрес электронной почты или номер мобильного телефона). Вы обязуетесь регулярно
            обновлять свою контактную информацию.
          </span>
          <br />
          <br />
          <b>6.5 Конфиденциальность иных лиц </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Если Вы получаете информацию о другом Пользователе в процессе использования Вами наших Услуг, Вы обязуетесь
            использовать такую информацию лишь в тех целях, для которых она была Вам предоставлена. Вы обязуетесь не
            раскрывать, не продавать и не распространять информацию о Пользователе третьим лицам в целях, не связанных с
            использованием Услуг. Вы обязуетесь не использовать такую информацию в маркетинговых целях, если только Вами
            не было получено на это согласие конкретного пользователя.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. ПОЛЬЗОВАТЕЛЬСКИЕ ЛИЦЕНЗИИ </b>
          <br />
          <br />
          <b>7.1 Лицензия на использование наших Услуг</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Вам предоставляется ограниченная, индивидуальная, неисключительная, не подлежащая переуступке и передаче, не
            сублицензируемая лицензия для доступа и использования Услуг только для личного некоммерческого
            использования. GlobalEnglish может по своему собственному усмотрению временно приостановить предоставление
            Услуг либо окончательно прекратить их предоставление, а также время от времени вносить изменения в его
            функционал без предварительного уведомления.
            <br />
            <br /> Для использования Услуг Вам необходимо получить доступ к Всемирной паутине, осуществить доступ к
            Услугам напрямую, либо же через устройства, обеспечивающие доступ к веб-контенту. Кроме того, у Вас должно
            быть все оборудование, необходимое для такого подключения к сети Интернет, включая компьютер и модем или
            другое устройство, обеспечивающее такой доступ. Вы соглашаетесь с тем, что обязаны самостоятельно оценивать
            и принимать на себя все риски, связанные с использованием Услуг, включая любую зависимость от точности,
            полноты или полезности предоставляемого контента. Ни GlobalEnglish, ни его лицензиары не гарантируют
            точность или полноту любого контента.
            <br />
            <br /> Услуги не предназначены и не лицензированы для использования их в опасных условиях (например, во
            время вождения, под водой или в среде, влажность которой превышает возможность использования соответствующих
            технических устройств).
            <br />
            <br />
            Право на доступ к Услугам аннулируется в случае, если использование Услуг запрещается, либо если
            предложение, продажа или предоставление Услуг противоречит соответствующим и применимым действующим
            нормативно-правовым актам.
            <br />
            <br />
            Использование Услуг в любых целях, отличных от предусмотренных в Условиях использования, является нарушением
            Условий использования, авторских и имущественных прав GlobalEnglish и/или ее лицензиаров и субподрядчиков.
            GlobalEnglish имеет право приостановить или прекратить действие Вашей учетной записи и отказать Вам в любых
            текущих или последующих Услугах, если у GlobalEnglish существуют разумные основания подозревать, что Вы
            используете Сервисы в нарушение настоящих Условий использования. Вы соглашаетесь с тем, что GlobalEnglish не
            несет ответственности перед Вами за любое изменение, приостановку или прекращение предоставления Услуг
            частично либо полностью.
          </span>
          <br />
          <br />
          <b>7.2 Пользовательский контент</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Услуги предоставляют Вам и другим Пользователям возможность создания и размещения данных и информации
            (например, отвечать на наши тесты) и загружать материалы в сервисы сообщества («Пользовательский контент»).
            При условии соблюдения положений настоящих Условиях Вы сохраняете право собственности на свой
            Пользовательский контент в полном объеме.
            <br />
            <br /> Загружая любой Пользовательский контент в наши Сервисы, Вы предоставляете GlobalEnglish безотзывную,
            бессрочную, неисключительную, передаваемую, полностью оплаченную, всемирно действующую лицензию (с правом
            сублицензии) с целью хранения, использования, воспроизведения, публичного исполнения, публичного показа,
            изменения, совершения переводов и выдержек (полностью или частично), на обработку и распространения такого
            Пользовательского контента в случае необходимости в связи с предоставлением Услуг с целью:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (а) соблюдения процессуальных норм, включая, помимо прочего, реагирование на юридические запросы, судебные
              приказы и другие подобные правительственные запросы;
              <br />
              (б) обеспечения исполнения настоящего Соглашение;
              <br />
              (в) реагирования на уведомления о нарушении прав третьих лиц;
              <br />
              (г) защиты прав, собственности и личной безопасности GlobalEnglish, его сотрудников, пользователей и
              общественности.
            </span>
            <br />
            <br /> Вы даете разрешение другим Пользователям Услуг просматривать ваш Пользовательский контент и, в
            соответствующих случаях, сотрудничать с Вами в отношении такогоПользовательского контента в той степени и
            объеме, в которой Вы решаете поделиться любым Пользовательским контентом с другими Пользователями.
            <br />
            <br /> Обратите внимание на то, что GlobalEnglish не имеет обязательств по обзору, изучению и проверке
            Пользовательского контента на предмет точности, не имеет обязательств по его исправления или удалению. Тем
            не менее он оставляет за собой право контролировать Пользовательский контент и удалять те его части,
            которые, по мнению GlobalEnglish, являются оскорбительными или иным образом нарушающими Условия
            использования.
            <br />
            <br /> GlobalEnglish не имеет обязательств по резервному копированию или хранению Пользовательского
            контента. Вы несете единоличную ответственность за свои собственные расходы на создание резервных копий и
            замену любого Пользовательского контента, который Вы публикуете, храните на Сайте или предоставляете
            GlobalEnglish.
            <br />
            <br /> Пользовательский контент не отражает взгляды и мнения GlobalEnglish. Настоящим Вы удостоверяете свое
            понимание того, что ответственность за Пользовательский контент полностью возлагается на Пользователя,
            которым он создается. Вы принимаете, что при пользовании Услугами Вы можете столкнуться с Пользовательским
            контентом, который является оскорбительным, непристойным либо нежелательным.
            <br />
            <br /> В дополнение к предоставленной выше лицензии на Пользовательский контент, Вы признаете и соглашаетесь
            с тем, что любые вопросы, комментарии, предложения, идеи, отзывы или другая информация об Услугах
            («Материалы»), предоставляемые Вами GlobalEnglish, не являются конфиденциальными и GlobalEnglish имеет право
            на неограниченное использование и распространение этих материалов для любых целей, коммерческих либо иных,
            без Вашего согласия и возмещения Вам компенсации. Кроме того, информация, размещаемая, разглашаемая или
            передаваемая Вами в рамках или посредством пользования Услугами или продуктами, предоставляемыми сообществом
            GlobalEnglish, не расценивается как конфиденциальная в понимании соответствующего действующего
            законодательства.
            <br />
            <br /> Вы осознаете, что техническая обработка и передача контента сайта, включая Ваш Пользовательский
            контент, может включать (а) передачу по различным сетям; и (б) внесения изменений с целью соответствия и
            адаптации к техническим требованиям подключающихся сетей или устройств.
            <br />
            <br />
            <b>
              GlobalEnglish не предоставляет гарантий в отношении Пользовательского контента. Пользовательский контент
              не отражает мнения и взгляды GlobalEnglish. Ни при каких обстоятельствах GlobalEnglish не несет никакой
              ответственности и не берет на себя обязательства в отношении Пользовательского контента или его утрату по
              какой-либо причине или в отношении каких-либо претензий, причинения ущерба или убытков, возникших в
              результате использования Пользовательского контента или его утраты.
            </b>
          </span>
          <br />
          <br />
          <b>7.3 Требования к поведению Пользователя</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Вы несете полную ответственность за все действия, совершаемые от Вашей учетной записи. Вы обязуетесь:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (а) немедленно уведомлять GlobalEnglish о любом несанкционированном использовании Вашей учетной записи
              либо любом ином нарушении безопасности, и
              <br />
              (б) выходить из своей учетной записи в конце каждого сеанса.
            </span>
            <br />
            <br />
            GlobalEnglish не несет ответственность за причинение каких-либо убытков или ущерба, возникшего в результате
            несоблюдения Вами Условий использования.
            <br />
            <br />
            Вы несете единоличную ответственность за обеспечение того, что настоящие Условия обслуживания соответствуют
            всем применимым к Вам законам, правилам и положениям, и право на доступ к Услугам аннулируется, если
            настоящие Условия предоставления услуг или использование Услуг запрещены полностью или в той степени, в
            которой они предлагаются, если продажа или предоставление Услуг противоречит любому соответствующему
            применимому действующему законодательству, правилам или нормам.
          </span>
          <br />
          <br />
          <b>7.4 Запреты в рамках поведения Пользователя </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Ниже приведены примеры использования Услуг, которые являются незаконными либо запрещены GlobalEnglish.
            GlobalEnglish оставляет за собой право расследовать и предпринимать соответствующие правовые действия против
            любого, кто, по мнению GlobalEnglish, нарушает это положение, в том числе, но не ограничиваясь, удалять
            оскорбительный контент из сайта или приложения, приостанавливать или закрывая учетную запись нарушающих
            Пользователей и сообщая о них правоохранительные органы.
            <br />
            <br /> Вы не имеете права:
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (а) адаптировать, загружать, редактировать, транслировать, осуществлять обратный инжиниринг, дублировать,
              публиковать, модифицировать, распространять, отображать, передавать или иным образом копировать и/или
              распространять любой контент GlobalEnglish или иные материалы, относящиеся к Услугам, если на это не было
              предоставлено специальное разрешение GlobalEnglish;
              <br />
              (б) создавать или использовать методы кадрирования для включения любой части Услуг;
              <br />
              (в) осуществлять сбор, перехватывать, использовать, получать доступ или иным образом копировать любую
              часть Услуг с помощью любого бота, паука, сканера, шпионского ПО, движка, устройства, программного
              обеспечения или любого другого автоматического устройства, утилиты или ручного процесса любого рода;
              <br />
              (г) использовать Услуги или любые функции, доступные в Услугах, любым способом с целью прервать,
              повредить, отключить, перегружать или нанести ущерб Услугам;
              <br />
              (д) продавать, лицензировать, сдавать в аренду или иным образом использовать Услуги в коммерческих целях;
              или же
              <br />
              (е) участвовать в любой деятельности, которая препятствует доступу или использованию данных Услуг другими
              Пользователями.
              <br />
              (ж) Публиковать, отправлять по электронной почте или иным образом передавать любой Пользовательский
              контент, (i) являющийся незаконным, вредоносным, угрожающим, оскорбительным, деликатным, откровенно
              насильственным, дискредитирующим, порочным, непристойным, неприличным, порнографическим, клеветническим,
              вмешивающимся в чью-то личную жизнь (вплоть до, но не ограничиваясь наличием в контенте любого адреса,
              адреса электронной почты, номера телефона и другой контактной информации), разжигающим вражду по расовым,
              этническим или иным признакам; (ii) который Вы не имеете права передавать в соответствии с каким-либо
              законом или в рамках договорных или фидуциарных отношений; (iii) создает видимость или реальную угрозу
              конфиденциальности или безопасности любого лица; (iv) нарушает чью-то интеллектуальную собственность или
              другие права собственности любой из сторон; (v) представляет собой нежелательную или несанкционированную
              рекламу, рекламные материалы, коммерческую деятельность и/или продажи, «нежелательную почту», «спам»,
              «письма счастья», «схемы пирамиды», «конкурсы», «лотереи» или любую иную форму рекламной деятельности;
              (vi) содержит программные вирусы или любой другой компьютерный код, файлы или программы, предназначенные
              для прерывания, уничтожения или ограничения функциональности любого компьютерного, программного или
              телекоммуникационного оборудования; или (vii) по мнению GlobalEnglish является нежелательным или
              ограничивает, препятствует любому другому лицу пользоваться Услугами, или подвергает (может подвергнуть)
              GlobalEnglish или его Пользователей понесению любого ущерба или возникновению у них ответственности любого
              вида;
              <br />
              (з) Создавать ложную идентификацию, искажать свою личность, выдавать себя за другое физическое или
              юридическое лицо, или ложно заявлять или иным образом искажать Вашу связь с физическим или юридическим
              лицом;
              <br />
              (и) запрашивать личную информацию от любого лица, не достигшего 18 лет;
              <br />
              (к) причинять вред несовершеннолетним;
              <br />
              (л) пропагандировать причинение физического вреда или травмы в отношении какой-либо группы или отдельных
              лиц, иным образом преследовать кого-либо;
              <br />
              (м) предоставлять или продвигать обучающую информацию о незаконных действиях, таких как, например,
              инструкции по сборке бомб, гранат и другого оружия или взрывчатых веществ;
              <br />
              (н) собирать, используя автоматические скрипты или иным образом, содержимое Сайта или адреса электронной
              почты, контактную информацию или другую личную информацию других пользователей с Сайта для любых целей,
              включая, помимо прочего, отправку нежелательных электронных писем или нежелательного контактирования с
              Пользователями или воспроизведение контента Сайта;
              <br />
              (о) рекламировать, предлагать, продавать или покупать любые товары или услуги для любых коммерческих
              целей, которые специальным образом не были разрешены;
              <br />
              (п) вмешиваться или нарушать работу Сайта, серверов или сетей, подключенных к Сайту, оказывать
              неподчинение любым требованиям, процедурам, политикам или правилам сетей, подключенных к Сайту;
              <br />
              (р) нарушать любое применимое локальное, государственное, национальное, международное право, любые
              нормативные акты, имеющие силу закона; продвигать или пропагандировать какую-либо преступную деятельность
              или предприятие, предоставлять обучающую информацию о незаконной деятельности;
              <br />
              (с) получать или пытаться получить доступ, иным образом получить какие-либо материалы или информацию с
              помощью любых средств, которые не были преднамеренно предоставлены или не предусмотрены на Сайте;
              <br />
              (т) обходить функции безопасности или любые средства контроля доступа; или же
              <br />
              (у) посредством Услуг создавать конфликт интересов или подрывать цели Услуг, например, обмениваться
              отзывами с другими Пользователями, нправлять или запрашивать отзывы.
            </span>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. ПРАВА ИНТЕЛЛЕКТУАЛЬНОЙ СОБСТВЕННОСТИ </b>
          <br />
          <br />
          <b>8.1Права GlobalEnglish на интеллектуальную собственность </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Весь контент Услуг, включая их Контент и любое вспомогательное программное обеспечение, являются
            собственностью GlobalEnglish, ее лицензиаров или партнеров и защищены США и международными законами об
            авторском праве и иными законами об интеллектуальной собственности.
            <br />
            <br /> Воспроизведение, распространение, изменение или публикация любой части Услуг без письменного согласия
            GlobalEnglish и/или его лицензиаров и т.п., в иных целях, нежелиуказанных ниже, строго запрещено.
            <br />
            <br /> Авторизованные Пользователи могут осуществлять доступ и использовать Услуги и Контент, а также
            загружать или распечатывать разумное количество копий частей Контента, к которым Пользователь надлежащим
            образом получил доступ исключительно для личного использования Пользователем в целях обучения языку, при
            условии, что Пользователь поддерживает все уведомления об авторских правах или других правах собственности
            на все копии такого Контента.
            <br />
            <br /> Если не указано иное, товарные знаки, появляющиеся в Услугах, являются товарными знаками
            GlobalEnglish, его лицензиаров или аффилированных лиц. Вы соглашаетесь не отображать, не унижать и не
            портить товарные знаки, не использовать какие-либо сбивающие с толку сходные знаки или использовать их таким
            образом, чтобы это могло исказить право собственности на такие знаки или создать впечатление, что
            GlobalEnglish поддерживает какой-либо продукт или услугу. Вы не можете воспроизводить или использовать
            товарные знаки, торговые наименования и/или фирменный стиль GlobalEnglish без предварительного письменного
            разрешения GlobalEnglish. Вся деловая репутация, наработанная в результате использования товарных знаков
            GlobalEnglish, будет действовать исключительно в наших интересах.
            <br />
            <br /> Любое использование сайтов, приложений или Контента, за исключением случаев, специально оговоренных в
            настоящем Соглашении, запрещено и автоматически аннулирует Ваши права в отношении использования Услуг и
            Контента, регламентированные настоящим Соглашении. Все права GlobalEnglish или его лицензиаров, которые
            прямо не регламентированы настоящими Условиями, сохраняются за GlobalEnglish и его лицензиарами.
          </span>
          <br />
          <br />
          <b>8.2 Товарные знаки третьих лиц </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Названия продуктов и услуг и логотипы, используемые и отображаемые на Сайте, кроме товарных знаков
            GlobalEnglish, приведенных выше, могут быть товарными знаками или знаками обслуживания их соответствующих
            владельцев, которые могут быть связаны или не связаны с GlobalEnglish. Ничто в настоящих Условиях или на
            Сайте не должно быть истолковано как передача прав интеллектуальной собственности (в том числе и косвенно),
            в силу эстоппеля или каким-либо иным образом, на какую-либо лицензию или права на использование любых
            торговых марок, представленных на Сайте, без нашего предварительного письменного разрешения в каждом из
            таких случаев. Вся деловая репутация, возникшая в результате использования товарных знаков GlobalEnglish,
            будет действовать исключительно в наших интересах.
          </span>
          <br />
          <br />
          <b>
            8.3 Жалобы на нарушение авторских прав в соответствии с Законом о защите авторских прав в цифровую эпоху.
          </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Если у Вас возникли какие-либо проблемы относительно авторских прав на какие-либо материалы, размещенные на
            Сайте иными лицами, пожалуйста, сообщите нам об этом.
            <br />
            <br /> GlobalEnglish будет обрабатывать и расследовать уведомления о предполагаемом нарушении и
            предпринимать соответствующие меры в соответствии с Законом о защите авторских прав в цифровую эпоху
            («DMCA») и другими применимыми законами об интеллектуальной собственности в отношении любого предполагаемого
            или фактического нарушения.
            <br />
            <br /> Уведомление о заявленном нарушении авторских прав должно быть отправлено по электронной почте агенту
            по авторским правам GlobalEnglish по адресу copyright@globalenglish.com (строка темы: «DMCA Takedown
            Request»).
            <br />
            <br />
            Вы также можете связаться с нами по почте по адресу:
            <br />
            <b>Получатель: Жалобы на нарушение авторских прав/юридический отдел </b>
            <br /> Южная Грант-стрит, 1875, офис 700
            <br /> Сан-Матео, Калифорния 94402 США
            <br />
            <br />
            <br /> Чтобы уведомление вступило в силу, оно должно быть в письменной форме и содержать следующую
            информацию: (i) электронная или физическая подпись лица, уполномоченного действовать от имени владельца
            авторских прав или других прав интеллектуальной собственности; (ii) описание защищенной авторским правом
            работы или другой интеллектуальной собственности, право на которое, по Вашему мнению, было нарушено; (iii)
            описание того, где материал, нарушающий, на Ваш взгляд, авторские права, расположен на Сайте, с достаточным
            количеством деталей для его обнаружения; (iv) информация, достаточная для обратной связи с Вами (адрес,
            номер телефона и адрес электронной почты); (v) Ваше заявление о том, что Вы добросовестно полагаете, что
            оспариваемое использование не разрешено владельцем авторских прав или интеллектуальной собственности, его
            агентом или законом; (vi) Ваше заявление, сделанное под страхом наказания за лжесвидетельство, что
            приведенная в Вашем Уведомлении информация является точной, и что Вы являетесь владельцем авторских прав,
            интеллектуальной собственности или уполномочены действовать от имени владельца авторских прав или
            интеллектуальной собственности.
            <br />
            <br /> В соответствии с DMCA и другим применимым законодательством, GlobalEnglish принял политику
            прекращения предоставления Услуг, в соответствующих обстоятельствах и по собственному усмотрению
            GlobalEnglish, пользователям, которые считаются повторными нарушителями. GlobalEnglish также может по своему
            усмотрению ограничить доступ к Сайту и/или прекратить членство любых пользователей, которые нарушают
            какие-либо права интеллектуальной собственности других лиц, независимо от того, имеет ли местоповторное
            нарушение или нет.
          </span>
          <br />
          <br />
          <b>8.4 Удаление Пользовательского контента, подозреваемого в нарушении </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Если GlobalEnglish удалил часть Вашего Пользовательского контента, подозреваемого в нарушении прав
            интеллектуальной собственности третьих лиц, и Вы уверены, что Ваш Пользовательский контент, который был
            удален (или к которому был отключен доступ), не нарушает права, или что у Вас есть разрешение от Владельца
            авторских прав, от его агента или у Вас есть право в соответствии с законом размещать и использовать такой
            контент в своем Пользовательском контенте, Вы можете отправить агенту по авторским правам письменное
            встречное уведомление, содержащее следующую информацию: (i) Вашу электронную или физическую подпись; (ii)
            идентификацию контента, который был удален или доступ к которому был заблокирован, место, в котором контент
            появился до того, как он был удален или отключен; (iii) заявление о том, что Вы добросовестно полагаете, что
            контент был удален или отключен в результате ошибки или ошибочной идентификации контента; и (iv) Ваше имя,
            адрес, номер телефона и адрес электронной почты, заявление о том, что Вы соглашаетесь с юрисдикцией
            федерального суда, расположенного в Северном округе Калифорнии, и заявление о том, что Вы примете судебное
            уведомление от лица, которое предоставило уведомление о предполагаемом нарушении.
            <br />
            <br /> Если Агент по авторским правам получает встречное уведомление, GlobalEnglish направляет копию
            встречного уведомления исходной стороне, подавшей жалобу, с уведомлением этого лица о том, что оно может
            заменить удаленный контент или прекратить его отключение в течение 10 рабочих дней. Если предполагаемый
            владелец авторских прав не подаст иск в суд на автора контента, участника или Пользователя, удаленный
            контент может быть заменен или же доступ к нему может быть восстановлен в течение 10–14 рабочих дней или
            более после получения встречного уведомления по адресу: по усмотрению GlobalEnglish.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            {' '}
            9. МЕЖДУНАРОДНОЕ ИСПОЛЬЗОВАНИЕ И ЭКСПОРТНЫЙ КОНТРОЛЬ{' '}
          </b>
          <br />
          <br /> Признавая глобальный характер Интернета, Вы соглашаетесь соблюдать все локальные правила, касающиеся
          поведения в Интернете и приемлемого Пользовательского контента. Вы соглашаетесь соблюдать все применимые
          законы, касающиеся передачи технических данных, экспортируемых из США или страны, в которой Вы проживаете.
          <br />
          <br /> Вы признаете, что Услуги или их часть могут регулироваться законами США об экспортном контроле. Вы не
          будете экспортировать, реэкспортировать или передавать какую-либо часть Сервиса или любую связанную
          техническую информацию, или материалы прямо и косвенно в нарушение любого применимого экспортного закона или
          нормативного акта. Вы заявляете и гарантируете, что Вы не являетесь гражданином или не находитесь в стране,
          подпадающей под эмбарго правительства США, или в стране, которая была определена правительством США в качестве
          страны, поддерживающей террористов, и что Вы не числитесь в списке правительства США в качестве запрещенной
          или ограниченной стороны.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. СУДЕБНЫЙ ЗАПРЕТ </b>
          <br />
          <br /> Вы признаете, что любое использование Сервиса, противоречащее Условиям использования, может нанести
          непоправимый ущерб GlobalEnglish, его филиалам, лицензиарам, партнерам, посредникам и Пользователям. При таких
          обстоятельствах GlobalEnglish, его аффилированные лица, лицензиары, партнеры, посредники и пользователи будут
          иметь право на справедливую помощь без внесения залога или других гарантий, включая, помимо прочего,
          предварительную и постоянную судебную защиту.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11.ПРОСТОИ </b>
          <br />
          <br /> Услуги могут быть периодически временно недоступны по причине технического обслуживания либо по иным
          причинам. GlobalEnglish не несет ответственности за любые перебои, задержки в работе или передаче, кражу или
          уничтожение, несанкционированный доступ или изменение пользовательских сообщений или любого другого контента,
          доступного через Сайт.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12. ОТКАЗ ОТ ГАРАНТИЙ </b>
          <br />
          <br /> Услуги могут быть периодически временно недоступны по причине технического обслуживания либо по иным
          причинам. GlobalEnglish не несет ответственности за любые перебои, задержки в работе или передаче, кражу или
          уничтожение, несанкционированный доступ или изменение пользовательских сообщений или любого другого контента,
          доступного через Сайт. Ни при каких обстоятельствах GlobalEnglish не будет нести никакой ответственности за
          какие-либо телесные повреждения или смерть в результате использования Услуг, любого Пользовательского
          контента, Контента третьих лиц, любого использования продуктов или услуг, предоставляемых Пользователями.
          <br />
          <br /> НАСТОЯЩИМ ВЫ ПРИНИМАЕТЕ И СОГЛАШАЕТЕСЬ С ТЕМ, ЧТО:
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            {' '}
            (a) УСЛУГИ, ВКЛЮЧАЯ ВЕСЬ КОНТЕНТ, ПРЕДОСТАВЛЯЮТСЯ «КАК ЕСТЬ» И ТАК, КАК ВОЗМОЖНО.
            <br />
            <br /> (б) GLOBALENGLISH ОТКАЗЫВАЕТСЯ ОТ КАКИХ-ЛИБО ЗАВЕРЕНИЙ И ГАРАНТИЙ В ОТНОШЕНИИ УСЛУГ, ЯВНЫХ ИЛИ
            ПОДРАЗУМЕВАЕМЫХ, ВКЛЮЧАЯ ПОДРАЗУМЕВАЕМЫЕ ГАРАНТИИ В ОТНОШЕНИИ ПРАВА СОБСТВЕННОСТИ, ТОВАРНОГО КАЧЕСТВА,
            ПРИГОДНОСТИ ДЛЯ ОСОБЫХ ЦЕЛЕЙ ИЛИ ОТСУТСТВИЯ НАРУШЕНИЙ АВТОРСКИХ ПРАВ.
            <br />
            <br /> (в) GLOBALENGLISH НЕ ГАРАНТИРУЕТ И НЕ ОБЕЩАЕТ НИКАКИХ КОНКРЕТНЫХ РЕЗУЛЬТАТОВ ОТ ИСПОЛЬЗОВАНИЯ УСЛУГ,
            ИЛИ ТОГО, ЧТО УСЛУГИ БУДУТ ОТВЕЧАТЬ ВАШИМ ТРЕБОВАНИЯМ ИЛИ ОЖИДАНИЯМИ.
            <br />
            <br />
            (г) GLOBALENGLISH НЕ УТВЕРЖДАЕТ И НЕ ГАРАНТИРУЕТ, ЧТО ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ, КОНТЕНТ, МАТЕРИАЛЫ В УСЛУГАХ
            ИЛИ НА САЙТЕ ЯВЛЯЮТСЯ ТОЧНЫМИ, ПОЛНЫМИ, НАДЕЖНЫМИ, АКТУАЛЬНЫМИ, НЕ СОДЕРЖАТ ОШИБОК, ЧТО САЙТ И УСЛУГИ, ИЛИ
            ЕГО СЕРВЕРЫ НЕ СОДЕРЖАТ ВИРУСОВ ИЛИ ИНЫХ ВРЕДОНОСНЫХ ПРОГРАММ. НАСТОЯЩИМ ВЫ ПРИНИМАЕТЕ И СОГЛАШАЕТЕСЬ С ТЕМ,
            ЧТО ВЫ ЗАГРУЖАЕТЕ ИЛИ ИНЫМ ОБРАЗОМ ПОЛУЧАЕТЕ КОНТЕНТ, МАТЕРИАЛЫ, ДАННЫЕ ИЛИ ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ ОТ ИЛИ
            ЧЕРЕЗ УСЛУГИ, ИЛИ ОТ ПРОВАЙДЕРА, НА СВОЙ СТРАХ И РИСК, И ЧТО ВЫ НЕСЕТЕ ПОЛНУЮ ОТВЕТСТВЕННОСТЬ ЗА
            ИСПОЛЬЗОВАНИЕ УКАЗАННОГО, ЗА ЛЮБОЙ УЩЕРБ ВАШЕЙ КОМПЬЮТЕРНОЙ СИСТЕМЕ, ПОТЕРЮ ДАННЫХ ИЛИ ИНОЙ ПРИЧИНЕННЫЙ ВРЕД
            ЛЮБОГО РОДА В СВЯЗИ С ИСПОЛЬЗОВАНИЕМ САЙТА ИЛИ УСЛУГ.
            <br />
            <br /> (д) GLOBALENGLISH НЕ ГАРАНТИРУЕТ, ЧТО ЛЮБЫЕ ОШИБКИ ИЛИ ДЕФЕКТЫ УСЛУГ БУДУТ ИСПРАВЛЕНЫ.
            <br />
            <br />
            (е) GLOBALENGLISH НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ЗА ПОВЕДЕНИЕ ПОЛЬЗОВАТЕЛЕЙ, КОНТЕНТ ТРЕТЬИХ ЛИЦ И КОНТЕНТ
            ПОЛЬЗОВАТЕЛЕЙ.
            <br />
            <br />
            (ж) Услуги могут быть периодически временно недоступны по причине технического обслуживания либо по иным
            причинам. GlobalEnglish не несет ответственности за любые перебои, задержки в работе или передаче, кражу или
            уничтожение, несанкционированный доступ или изменение пользовательских сообщений или любого другого
            контента, доступного через Сайт. Ни при каких обстоятельствах GlobalEnglish не будет нести никакой
            ответственности за какие-либт телесные повреждения или смерть в результате использования Услуг, любого
            Пользовательского контента, Контента третьих лиц, любого использования продуктов или услуг, предоставляемых
            Пользователями.
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</b>
          <br />
          <br /> GLOBALENGLISH ИЛИ ЕГО ДИРЕКТОРА, СОТРУДНИКИ, АГЕНТЫ, ЛИЦЕНЗИОРЫ, ПАРТНЕРЫ НИ В КОЕМ СЛУЧАЕ НЕ НЕСУТ
          ОТВЕТСТВЕННОСТЬ ЗА ПРИЧИНЕНИЕ ВАМ ИЛИ ЛЮБОМУ ИНОМУ ТРЕТЬЕМУ ЛИЦУ КАКИХ-ЛИБО НЕПОСРЕДСТВЕННЫХ, ПОСЛЕДУЮЩИХ,
          ПРЕДПОЛОЖИТЕЛЬНЫХ, НЕНАМЕРЕННЫХ, ЦЕЛЕНАПРАВЛЕННЫХ ИЛИ ШТРАФНЫХ УБЫТКОВ, ВКЛЮЧАЯ ЛЮБУЮ НЕПОЛУЧЕННУЮ ПРИБЫЛЬ ИЛИ
          УТРАЧЕННЫЕ ДАННЫЕ, ВОЗНИКШИЕ ВСЛЕДСТВИЕ (I) ИСПОЛЬЗОВАНИЯ ИЛИ НЕВОЗМОЖНОСТИ ИСПОЛЬЗОВАНИЯ ВАМИ УСЛУГ, ЛЮБОГО
          СОДЕРЖАНИЯ, ДРУГИХ МАТЕРИАЛОВ, НАХОДЯЩИХСЯ ИЛИ ЗАГРУЖАЕМЫХ ИЗ САЙТА, ​​УСЛУГ ИЛИ ПРОВАЙДЕРОВ, (II) РАСХОДОВ НА
          ПОКУПКУ ТОВАРОВ И УСЛУГ, ВОЗНИКАЮЩИХ ИЗ ПРИОБРЕТЕННЫХ ИЛИ ПОЛУЧЕННЫХ ТОВАРОВ, ДАННЫХ, ИНФОРМАЦИИ ИЛИ УСЛУГ,
          ИЛИ ПОЛУЧЕННЫХ СООБЩЕНИЙ, ИЛИ ВСЛЕДСТВИЕ ЗАКЛЮЧЕНИЯ ВАМИ СДЕЛОК НА САЙТЕ ИЛИ ПОСРЕДСТВОМ НЕГО; (III)
          НЕСАНКЦИОНИРОВАННОГО ДОСТУПА ИЛИ ИЗМЕНЕНИЯ ВАШИХ ИНФОРМАЦИИ ИЛИ ДАННЫХ ТРЕТЬЕЙ СТОРОНОЙ; (IV) УТВЕРДЖЕНИЙ ИЛИ
          ПОВЕДЕНИЯ ТРЕТЬЕЙ СТОРОНЫ НА САЙТЕ; ИЛИ (V) ЛЮБОЙ ИНОЙ ПРИЧИНЫ, ВОЗНИКШЕЙ В СВЯЗИ С УСЛУГАМИ. GLOBALENGLISH НИ
          В КОЕМ СЛУЧАЕ НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ПЕРЕД ВАМИ ЗА КАКОЕ-ЛИБО ПРИЧИНЕНИЕ УЩЕРБА ИЛИ УБЫТКОВ НА СУММУ,
          ПРЕВЫШАЮЩУЮ ТУ, КОТОРУЮ ВЫ ЗАПЛАТИЛИ GLOBALENGLISH ЗА ПОСЛЕДНИЕ ДВЕНАДЦАТЬ (12) МЕСЯЦЕВ, НО В ЛЮБОМ СЛУЧАЕ НЕ
          ВЫШЕ 100 ДОЛЛАРОВ США (100 $) ДАЖЕ В ТОМ СЛУЧАЕ, ЕСЛИ КОМПАНИЯ GLOBALENGLISH БЫЛА ОСВЕДОМЛЕНА О ВОЗМОЖНОСТИ
          ПРИЧИНЕНИЯ ТАКИХ УБЫТКОВ. КОЛИЧЕСТВО ПРЕТЕНЗИЙ НИКОИМ ОБРАЗОМ НЕ УВЕЛИЧИВАЕТ УСТАНОВЛЕННЫЙ ЛИМИТ СУММЫ
          ОТВЕТСТВЕННОСТИ.
          <br />
          <br /> ЮРИСДИКЦИИ НЕКОТОРЫХ СТРАН НЕ ДОПУСКАЮТ ИСКЛЮЧЕНИЯ ОПРЕДЕЛЕННЫХ ГАРАНТИЙ, ОГРАНИЧЕНИЙ ИЛИ ОСВОБОЖДЕНИЯ
          ОТ ОТВЕТСТВЕННОСТИ ЗА НЕНАМЕРЕННЫЙ ИЛИ КОСВЕННЫЙ УЩЕРБ. СООТВЕТСТВЕННО, ТАКИЕ ОГРАНИЧЕНИЯ НЕПРИМЕНИМЫ ПО
          ОТНОШЕНИЮ К ВАМ.
          <br />
          <br /> В СЛУЧАЕ, ЕСЛИ ВЫ НЕ УДОВЛЕТВОРЕНЫ САЙТОМ, ПОЛНОСТЬЮ ИЛИ ЧАСТИЧНО, ЛИБО НАСТОЯЩИМИ УСЛОВИЯМИ, ​​ВЫ
          ИМЕЕТЕ ПРАВО ПРЕКРАТИТЬ ИСПОЛЬЗОВАТЬ САЙТ.
          <br />
          <br /> GLOBALENGLISH НИ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ НЕ НЕСЕТ ОТВЕТСТВЕННОСТЬ ЗА ФИЗИЧЕСКИЙ УЩЕРБ ИЛИ СМЕРТЬ,
          ВЫЗВАННЫЕ ИСПОЛЬЗОВАНИЕМ УСЛУГ, КОНТЕНТОМ ТРЕТЬИХ ЛИЦ ИЛИ КОНТЕНТОМ ПОЛЬЗОВАТЕЛЯ.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14. СРОК ИСКОВОЙ ДАВНОСТИ </b>
          <br />
          <br />
          Вы соглашаетесь с тем, что независимо от какого-либо закона, регламентирующего обратное, все возможные
          претензии или иски против GlobalEnglish, возникающие в связи с использованием Вами Услуг или связанные с ними,
          должны быть поданы в течение одного (1) года с момента возникновения такой претензии или причины иска, в
          противном случае они будут считаться таковыми, срок исковой давности для которых истек.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            15. ОСВОБОЖДЕНИЕ ОТ ОТВЕТСТВЕННОСТИ И ГАРАНТИЯ ВОЗМЕЩЕНИЯ{' '}
          </b>
          <br />
          <br />
          Настоящим Вы соглашаетесь освободить от ответственности и оградить компанию GlobalEnglish, ее филиалы,
          должностных лиц, сотрудников, директоров и агентов от любых претензий, исков, издержек, обязательств, убытков,
          ущербов, расходов, включая гонорары адвокатов в разумных пределах, предъявленных GlobalEnglish любым третьим
          лицом по причине, в результате или в связи с использованием Вами Сайта, Услуг, Вашего Пользовательского
          контента, Вашего подключения к Сайту или к Услугам, нарушения Вами настоящих Условий, нарушения каких-либо
          прав другого Пользователя, в соответствии с любым действующим законодательством или иным образом вытекающим из
          использования Вами Услуг. Ваши обязательства по возмещению включают, но не ограничиваются этим, претензии к
          GlobalEnglish о нарушении прав интеллектуальной собственности третьих лиц Вашим Пользовательским контентом.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>16. ПРЕКРАЩЕНИЕ ПРЕДОСТАВЛЕНИЯ УСЛУГ </b>
          <br />
          <br /> Если Ваша подписка по какой-либо причине будет прекращена, GlobalEnglish отключает Ваш доступ к
          Услугам. Вы не сможете получить доступ к какому-либо Контенту, включая Ваш Пользовательский контент, несмотря
          на то, что копии таких данных должны храниться в нашей системе в течение трех (3) лет в целях резервного
          копирования.
          <br />
          <br /> Если Вы направите GlobalEnglish запрос об удалении Ваших Персональных данных и Пользовательского
          контента, мы приложим все разумные усилия для его выполнения и уведомим Вас о факте удаления.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            17. ОТКАЗ ОТ ПРАВ И НЕЗАВИСИМОСТЬ ПОЛОЖЕНИЙ ДОГОВОРА{' '}
          </b>
          <br />
          <br /> Неспособность GlobalEnglish исполнить или реализовать какое-либо право или положение настоящих Условий
          не означает отказ от такого права или положения в каком-либо ином случае. Если какое-либо положение настоящих
          Условий будет признано незаконным, недействительным или по какой-либо причине неисполнимым, то это не должно
          влиять на действительность и применимость всех оставшихся положений.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>18. ПОЛНОТА СОГЛАШЕНИЯ </b>
          <br />
          <br /> Настоящее Соглашение представляет собой всю полноту договоренностей между Вами и GlobalEnglish в
          отношении использования Услуг и отменяет действие любых предыдущих договоренностей и соглашений между Вами и
          GlobalEnglish, касающихся использования Вами Услуг.
          <br />
          <br /> Однако в случае, если между Вашей Компанией и GlobalEnglish имеется действующее Соглашение на покупку
          каких-либо Услуг, настоящие Условия не отменяют действие условий такого соглашения, заключенного Вашей
          Компанией с GlobalEnglish, ее дочерними компаниями или аффилированными лицами. В случае возникновения
          каких-либо разногласий между сторонами такое Соглашение будет иметь преимущественную силу.
          <br />
          <br /> Настоящее Соглашение составлено на английском языке, и такая версия будет иметь преимущественную силу
          во всех отношениях, любая неанглийская версия настоящего Соглашения предназначена исключительно для удобства
          пользования. Печатная версия этого Соглашения и любое уведомление, представленное в электронной форме, имеют
          ту же юридическую силу в рамках судебного или административного процесса на основании или в связи с этим
          Соглашением, в той же степени и на тех же условиях, что и иные деловые документы и записи, первоначально
          созданные и хранящиеся на бумажных носителях. Названия разделов в настоящих Условиях предназначены только для
          удобства восприятия и не имеют юридической либо договорной силы.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            19. ВЫБОР ПРИМЕНИМОГО ПРАВА, МЕСТА СУДЕБНОГО РАССМОТРЕНИЯ И АРБИТРАЖА{' '}
          </b>
          <br />
          <br />
          Настоящие Условия регулируются законодательством штата Калифорния без учета принципов коллизии.
          <br />
          <br /> По выбору и усмотрению GlobalEnglish все споры, претензии или разногласия, возникающие в связи с
          настоящими Условиями, Сайтом или относящиеся к ним, которые не могут быть разрешены путем переговоров,
          передаются на рассмотрение обязательного арбитража, который будет проводиться JAMS или его правопреемником.
          Если иное не установлено сторонами, то место проведения арбитража – город Сан-Франциско, штат Калифорния.
          Стороны могут согласовать на взаимных началах одного конкретного арбитра, или же единоличный арбитр может быть
          назначен JAMS. Рассмотрение спора будет проводиться в соответствии с правилами и положениями, установленными
          JAMS, если иное не оговорено в настоящих Условиях. Арбитраж должен начаться в течение сорока пяти (45) дней с
          даты подачи письменного требования об арбитраже любой из сторон. Решение арбитра должно быть принято и
          доставлено сторонам в течение шестидесяти (60) дней после завершения арбитража и в течение шести (6) месяцев с
          момента выбора арбитра. Арбитр не имеет права присудить убытки сверх ограничения по фактическим
          компенсационным, прямым убыткам, установленным в Условиях, и не может увеличивать фактические убытки,
          назначать штрафные санкции или любые другие убытки, которые были целенаправленно исключены из применимых в
          соответствии с Условиями, и каждая сторона настоящим безоговорочно отказывается от каких-либо претензий в
          отношении таких убытков. Арбитр может, по своему усмотрению, присудить возмещение судебных издержек (включая
          судебные издержки в разумных пределах и расходы выигравшей стороны) проигравшей стороне. Любая из сторон,
          отказывающаяся от добровольного исполнения решения арбитров, будет нести ответственность за издержки и
          расходы, включая гонорары адвокатов, понесенные другой стороной при принудительном исполнении решения. Однако
          в случае временного или предварительного судебного запрета любая сторона может обратиться в суд без
          предварительного арбитража с целью избежать немедленного и непоправимого ущерба. Положения этого арбитражного
          раздела будут применяться в любом суде надлежащей юрисдикции.
          <br />
          <br /> Любые споры или разногласия, не подлежащие арбитражу, Вы и GlobalEnglish соглашаетесь передавать на
          рассмотрение персональной и исключительной юрисдикции судов штата и федеральных судов, расположенных в округе
          Сан-Франциско, штат Калифорния.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. КОНТАКТНЫЕ ДАННЫЕ И АДРЕСНЫЕ РЕКВИЗИТЫ СТОРОН</b>
          <br />
          <br />
          Вы можете направить запрос о прекращении действия Вашей учетной записи GlobalEnglish Service, направив
          GlobalEnglish запрос о прекращении посредством ссылки «Связаться с нами» по адресу
          <a href="https://support.globalenglish.com/" rel="noopener noreferrer" target="_blank">
            https://support.globalenglish.com/
          </a>
          . Ваш доступ к Услугам и их использованию, включая любой контент, может быть заблокирован GlobalEnglish в
          любое время после прекращения действия Вашей учетной записи.
          <br />
          <br /> Запросы могут быть направлены по электронной или обычной почте. Сайт также может направлять Вам
          уведомления о внесении изменений в настоящие Условия или об иных вопросах, как правило, публикуя уведомления
          или ссылки на уведомления на Сайте.
          <br />
          <br /> Уведомления, связанные с нашей политикой конфиденциальности, могут быть отправлены по адресу
          <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
            privacy@globalenglish.com.
          </a>
          <br />
          <br /> Все иные юридически значимые уведомления или иная корреспонденция в адрес GlobalEnglish должна
          направляться на электронный адрес:
          <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
            legal@globalenglish.com
          </a>{' '}
          или на физический адрес:
          <br />
          <br /> 1875 S Grant Street, Suite 700
          <br /> Сан-Матео, Калифорния 94402
          <br /> Получатель: Юридический отдел
          <br />
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>ОСОБЫЕ УСЛОВИЯ ДЛЯ ОПЕРАЦИИ B2B </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          {' '}
          Если Ваша учетная запись в GlobalEnglish зарегистрирована через Вашего работодателя, использование Вами наших
          Услуг также регулируется нашим соглашением с Вашей компанией («Компания»). Настоящие Условия использования не
          отменяют условий соглашения с Вашей компанией. Вы не можете использовать (или продолжать использовать) Услуги,
          если Ваша Компания не авторизует Вас в качестве конечного пользователя на основании их лицензии от
          GlobalEnglish. Если Вы попросите нас закрыть Вашу учетную запись, GlobalEnglish может потребовать разрешение
          на то Вашей компании, прежде чем закроет Вашу учетную запись. Вы соглашаетесь использовать Услуги
          исключительно в соответствии с разрешением Вашей Компании. У Вас может существовать возможность общаться с
          другими лицами из Вашей Компании или загружать документы, связанные с Вашей Компанией, через Сервисы, но
          только с разрешения вашей Компании.
          <br />
          <br /> Настоящим Вы признаете и соглашаетесь с тем, что все данные, сгенерированные в связи с использованием
          Вами Услуг («Данные пользователя»), могут быть доступны для Вашей компании и что GlobalEnglish не несет перед
          Вами никакой ответственности на основании информации, предоставленной Вашей компании в результате
          использования Вами наших Услуг.
          <br />
          <br /> Поскольку Ваша подписка на доступ к Услугам действует только в течение срока действия лицензии
          Компании, то если ваша Компания прекратит действие лицензии с GlobalEnglish, Вы утратите доступ к Услугам и
          Пользовательскому контенту. GlobalEnglish может прекратить или приостановить действие учетной записи Вашей
          Компании, Вашей учетной записи или возможности использовать Сервисы, полностью или частично, без
          предварительного уведомления в случае, если (i) Ваша Компания не уплатит в установленном порядке какие-либо
          сборы, причитающиеся GlobalEnglish, или (ii) Вы или Ваша Компания нарушаете Условия или любые иные правила,
          регулирующие использование Услуг.
          <br />
          <br /> Компания GlobalEnglish может получить предписание или быть обязанной по закону возвратить в Вашу
          Компанию все данные, относящиеся к использованию Вами Услуг, включая Ваши Личные данные и Пользовательский
          контент. Если Ваша компания не требует вернуть или стереть такие данные, GlobalEnglish обязуется хранить эти
          данные в течение трех (3) лет после истечения срока действия или прекращения контракта с Вашим работодателем.
          В таких случаях Вы можете приобрести подписку на услугу непосредственно у GlobalEnglish и продолжить доступ к
          программе без потери информации о ходе работы. Вы можете разрешить передачу информации о Вашем прогрессе и
          профиле в новую подписку с Вашим новым работодателем. Вы также можете запросить все данные, относящиеся к
          использованию Вами Услуг, в формате, совместимом с будущим импортом.
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>ОСОБЫЕ УСЛОВИЯ ДЛЯ ОПЕРАЦИ B2C </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          Вы также можете заключить соглашение о подписке с GlobalEnglish как физическое лицо, что включает в себя либо
          подписку на три (3) или шесть (6) месяцев, либо годовой или многолетний план подписки (каждый из вариантов
          далее по тексту - «План подписки»).
          <br />
          <br /> Вам будет выставляться ежемесячный или ежегодный счет за соответствующий План подписки приблизительно в
          один и тот же день каждого месяца/года (в зависимости от обстоятельств) до тех пор, пока Вы не отмените свой
          План подписки. Во избежание недоразумений, пожалуйста, обратите внимание, что не разрешается отменять,
          сокращать количество мест или понижать рейтинг выбранной Вами Услуги до истечения годового срока вашей
          подписки. Возврат платежей по Плану подписки не производится.
          <br />
          <br /> Сборы, надлежащие к оплате за Услуги («Сборы»), доступны на Сайте и/или в опубликованном на актуальную
          дату прейскуранте GlobalEnglish. Цена, указанная за Услугу, исключает все налоги и сборы, если не указано
          иное. Вы несете ответственность за уплату любых налогов и всех иных обязательных платежей, связанных с
          использованием Услуг (например, сборы за передачу данных и обмен валюты). Вам надлежит оплачивать сборы в
          валюте, указанной GlobalEnglish для Вашей учетной записи. GlobalEnglish оставляет за собой право изменить
          указанную валюту в любое время.
          <br />
          <br />
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}> Оплата </b>
          <br />
          <br /> Оплата производится при покупке подписной лицензии. Мы сотрудничаем с PayPal и Stripe с целью обработки
          Ваших платежей. Мы не получаем и не обрабатываем информацию о кредитных картах или иных способах оплаты,
          которую Вы предоставляете указанным поставщикам услуг.
          <br />
          <br /> Все услуги оплачиваются заранее за выбранный период (ежемесячно, ежегодно или иным образом) и возврату
          не подлежат. Это также распространяется и на обновленные учетные записи.
          <br />
          <br /> -----
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>Уведомление для пользователей Калифорнии</b>
          <br />
          <br /> Если Вы являетесь жителем Калифорнии, раздел 1789.3 Гражданского кодекса Калифорнии разрешает Вам
          запрашивать информацию о раскрытии Ваших личных данных третьим сторонам для целей прямого маркетинга третьих
          лиц. Калифорнийский закон о защите прав потребителей, который вступит в силу в 2021 году (Гражданский кодекс с
          1798.100 по 1709.198), также дает Вам определенные права:
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            право знать, какие Личные данные были собраны о Вас и получать копии,
            <br />
            <br />
            право на удаление,
            <br />
            <br />
            право знать, каким третьим лицам были проданы Ваши Личные данные, и право требовать прекращения такой
            продажи и
            <br />
            <br />
            право быть свободным от дискриминации в случае реализации Вами некоторых прав Вашего потребителя.
          </span>
          <br /> Кроме того, в соответствии с разделом 22581 Калифорнийского кодекса о предпринимательской деятельности
          и профессиях, несовершеннолетним лицам, в настоящее время проживающим в Калифорнии ,разрешается удалять
          контент, который они публично разместили на Сайте. Тем не менее обратите внимание, что GlobalEnglish не может
          обеспечить полное или совершенное удаление контента, который Вы разместили, и что могут быть обстоятельства,
          при которых закон не требует или не допускает удаление данных даже по Вашему требованию.
          <br />
          <br />
          На случай, если Вы хотите направить запрос на реализацию своих прав:
          <br />
          <br /> Поставщиком веб-сайта и связанных с ним услуг является корпорация GlobalEnglish, расположенная по
          адресу: улица Саут-Грант, 1875, офис 700, Сан-Матео, Калифорния 94402, США. Адрес электронной почты:
          privacy@globalenglish.com.
          <br /> На дату публикации настоящих Условий с Сайта не взимаются прямые платежи. Если у Вас есть претензии в
          отношении услуг, предоставляемых на Сайте, или Вы хотите запросить дополнительную информацию, пожалуйста,
          свяжитесь с нами по указанному выше физическому адресу с описанием Вашего запроса. Мы не можем гарантировать
          ответ в любой конкретный период времени. Если Вы не будете удовлетворены результатами, свяжитесь с Отделом
          помощи при рассмотрении жалоб Отдела обслуживания потребителей Департамента по делам потребителей штата
          Калифорния по физическому адресу: 1625 Бульвар Норс Маркет, офис 112, Сакраменто, Калифорния 95834, или по
          телефону (916) 445-1254 или (800) 952-5210.
        </p>
      </div>
    </div>
  );
}

export default RuRuContent;
