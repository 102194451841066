export function validateOTP(data){
  let domain=process.env.REACT_APP_API_GATEWAY_URL
  console.log(JSON.stringify(data))
return fetch(domain+"/validate",
{
    method: 'POST',
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data)
})
}
export function resendOTP(data){
  let domain=process.env.REACT_APP_API_GATEWAY_URL
  console.log(JSON.stringify(data))
return fetch(domain+`/checkuser`,
{  
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    body:JSON.stringify({
      "username":`${data.username}`,
    "email":`${data.email}`,
    "type":"GET"}),
    
      
})
}