/* eslint-disable */
// @ts-nocheck
import React from "react";

function EnContent() {
  return (
    <>
      <div
        className="olPrint"
        style={{ cursor: "pointer", display: "none" }}
      ></div>
      <div id="agreement_container">
        <table
          style={{
            height: "300px !important",
            width: "100%",
            cellSpacing: "0",
            cellPadding: "0",
            border: "0",
          }}
        >
          <tbody>
            <tr>
              <td width="50%" valign="top" align="center">
                {" "}
                &nbsp;
                <br /> &nbsp;
                <br />
                <div
                  style={{
                    color: "#666666",
                    fontSize: "10",
                    fontFamily: "arial",
                  }}
                >
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.globalenglish.com"
                  >
                    <img
                      alt=""
                      src={require("../../../assets/images/ge_ls_logo.png")}
                      alt="GE-Logo"
                    />
                  </a>
                  <br />
                  <a
                    style={{
                      color: "#71a4be",
                      textDecoration: "none",
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.globalenglish.com/whoweare/aboutus/our_philosophy"
                  >
                    <b>Learn more about us</b>
                  </a>{" "}
                </div>{" "}
              </td>
              <td width="50%" valign="top" align="left">
                {" "}
                &nbsp;
                <br /> &nbsp;
                <br />
                <div
                  style={{
                    color: "#666666",
                    fontSize: "13px",
                    fontFamily: "arial",
                  }}
                >
                  <b>GlobalEnglish</b>
                  <br /> 1875 South Grant Street, Suite 700
                  <br /> San Mateo, CA 94402
                  <br /> USA
                  <br /> &nbsp;
                  <br /> Main: +1 415-906-7991
                  <br /> Sales: +1 415-915-0676
                  <br /> &nbsp;
                  <br />{" "}
                  <table
                    cellSpacing="0"
                    cellPadding="0"
                    border="0"
                    bgColor="#ffffff"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <img
                            alt=""
                            src={require("../../../assets/images/button_green_off_left.png")}
                            id="contactUsbtnLeft"
                            alt="Contact Us"
                          />
                        </td>
                        <td
                          valign="middle"
                          background={require("../../../assets/images/button_green_off_center.png")}
                          align="center"
                          onMousever="this.style.color=\'#ffffff\';"
                          style={{
                            fontFamily: "arial",
                            fontSize: "11px",
                            backgroundRepeat: "repeat-x",
                            color: "#28903b",
                          }}
                          id="contactUsbtnCenter"
                        >
                          <a
                            style={{ textDecoration: "none" }}
                            href="http://www.globalenglish.com/company/contact/"
                            rel="noopener noreferrer"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Contact Us
                          </a>
                        </td>
                        <td>
                          <img
                            alt=""
                            src={require("../../../assets/images/button_green_off_right.png")}
                            id="contactUsbtnRight"
                            alt="Contact Us"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>{" "}
                  &nbsp;
                  <br />
                  <b>
                    For technical assistance or to report a problem, please{" "}
                    <a
                      style={{
                        color: "#71a4be",
                        textDecoration: "none",
                      }}
                      href="https://support.globalenglish.com/en-en/contact_support"
                      rel="noopener noreferrer"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      click here
                    </a>
                    .
                  </b>{" "}
                </div>{" "}
              </td>{" "}
            </tr>{" "}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default EnContent;
