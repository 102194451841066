import { defineMessages } from "react-intl";

export default defineMessages({
  welcome: {
    id: "app.components.welcome",
    defaultMessage: "Welcome!",
  },
  username: {
    id: "app.components.username",
    defaultMessage: "Username/Email",
  },
  email: {
    id: "app.components.email",
    defaultMessage: "Email Address",
  },
  emailSuccess: {
    id: "app.component.forgotPassword.emailSuccess",
    defaultMessage:
      "We've sent you an email containing a link that will allow you to reset your password.",
  },
  emailMultipleUser: {
    id: "app.component.forgotPassword.emailMultipleUser",
    defaultMessage: "Multiple accounts associated with this email address. Contact your administrator."
  },
  emailFail: {
    id: "app.component.forgot.emailFail",
    defaultMessage: "Email address is incorrect",
  },
  password: {
    id: "app.components.password",
    defaultMessage: "Password",
  },
  logIn: {
    id: "app.components.login",
    defaultMessage: "Log in",
  },
  resetPassword: {
    id: "app.component.forgotPassword.resetPassword",
    defaultMessage: "Reset Password",
  },
  forgotPassword: {
    id: "app.components.forgot.password",
    defaultMessage: "Forgot your password?",
  },
  register: {
    id: "app.components.register",
    defaultMessage: "Register",
  },
  help: {
    id: "app.components.help",
    defaultMessage: "Help",
  },
  globalenglishCorp: {
    id: "app.components.footer.globalenglish.corp",
    defaultMessage: "GlobalEnglish Corporation.",
  },
  allRightsReserved: {
    id: "app.components.footer.all.rights.reserved",
    defaultMessage: "All Rights Reserved.",
  },
  copyright: {
    id: "app.components.footer.copyright",
    defaultMessage: "Copyright",
  },
  privacyPolicy: {
    id: "app.components.footer.privacy.policy",
    defaultMessage: "Privacy Policy",
  },
  userAgreement: {
    id: "app.components.footer.user.aggrement",
    defaultMessage: "User Agreement",
  },
  contactUs: {
    id: "app.components.footer.contact.us",
    defaultMessage: "Contact Us",
  },
  confirm: {
    id: "app.components.forgotPassword.confirm",
    defaultMessage: "Confirm",
  },
  profileSuccess: {
    id: "app.components.profileSuccess",
    defaultMessage: "Your profile has been updated successfully",
  },
  duplicateUserNameError: {
    id: "app.components.duplicateUserNameError",
    defaultMessage:
      "We're sorry, we are unable to process your log-in at this time, Please contact  {contactSupport} for more details.",
  },
  usernameOrPasswordError: {
    id: "app.components.usernameOrPasswordError",
    defaultMessage: "Username or Password is incorrect",
  },
  validEmailError: {
    id: "app.components.validEmailError",
    defaultMessage: "Type a valid email address.",
  },
  directLoginError: {
    id: "app.components.directLoginError",
    defaultMessage: "You must log in from your company's learning portal.",
  },
  inactiveUserError: {
    id: "app.components.inactiveUserError",
    defaultMessage: "Your account is not active. Contact your administrator.",
  },
  validateUsername: {
    id: "app.components.validateUsername",
    defaultMessage: "Type your username.",
  },
  validatePassword: {
    id: "app.components.validatePassword",
    defaultMessage: "Type your password.",
  },

  mfaTitle: {
    id: "app.components.mfa.mfaTitle",
    defaultMessage: "Please setup multi-factor authentication",
  },
  instructionTitle: {
    id: "app.components.mfa.instructionTitle",
    defaultMessage: "This is a one-time setup process. Just follow these three steps:",
  },
  instruction1: {
    id: "app.components.mfa.instruction1",
    defaultMessage: "Download the authenticator app for {andUrl} and {iosUrl}.",
  },
  instruction2: {
    id: "app.components.mfa.instruction2",
    defaultMessage: "In the app, add a <b>work</b> or <b>school account</b>.",
  },
  instruction3: {
    id: "app.components.mfa.instruction3",
    defaultMessage: "Scan <b>QR code</b> to complete the setup.",
  },
  instruction4: {
    id: "app.components.mfa.instruction4",
    defaultMessage: "Enter the code generated by the authenticator app below.",
  },
  step1: {
    id: "app.components.mfa.step1",
    defaultMessage: "Step 1",
  },
  step2: {
    id: "app.components.mfa.step2",
    defaultMessage: "Step 2",
  },
  step3: {
    id: "app.components.mfa.step3",
    defaultMessage: "Step 3",
  },
  step4: {
    id: "app.components.mfa.step4",
    defaultMessage: "Step 4",
  },
  verifyText: {
    id: "app.components.mfa.verifyText",
    defaultMessage: "Verify",
  },
  codeError1: {
    id: "app.components.mfa.codeError1",
    defaultMessage: "Incorrect 6-digit code!",
  },
  codeError2: {
    id: "app.components.mfa.codeError2",
    defaultMessage: "Please provide a 6-digit number",
  },
  mfaTitle2: {
    id: "app.components.mfa.mfaTitle2",
    defaultMessage: "Enter the verification code from the authenticator app",
  },
  ssoErrorTitle: {
    id: "app.components.ssoErrorTitle",
    defaultMessage: "SSO ERROR",
  },
  ssoError: [
    {
      id: "DefaultException",
      description: "Default message to be displayed",
      defaultMessage: "Please re-try login from your company portal",
    },
    {
      id: "RegistrationException",
      description: "User not created in the group",
      defaultMessage: "Please re-try login from your company portal",
    },
    {
      id: "IDPException",
      description: "IDP failure",
      defaultMessage: "Please re-try login from your company portal",
    },
    {
      id: "TimeoutException",
      description: "Timeout",
      defaultMessage: "Please re-try login from your company portal",
    },
    {
      id: "CertificateException",
      description: "Certificate failure",
      defaultMessage: "Please re-try login from your company portal",
    },
    {
      id: "DeactivatedAccountException",
      description: "The Cognito user profile is mapped to the Deactivated account",
      defaultMessage: "Please re-try login from your company portal",
    },
    {
      id: "NotSAMLUserException",
      description: "The user is not present in the SAML group",
      defaultMessage: "Please re-try login from your company portal",
    },
    {
      id: "AttributeMapException",
      description: "Attributes are not mapped correctly",
      defaultMessage: "Please re-try login from your company portal",
    },
    {
      id: "AttributeUpdateException",
      description: "Username already exist, not able to update user",
      defaultMessage: "Please re-try login from your company portal",
    },
    {
      id: "ExistingUserException",
      description: "Username already exist, not able to create user",
      defaultMessage: "Please re-try with different username, given username exist",
    },
    {
      id: "DBException",
      description: " Connection to DB failed",
      defaultMessage: "Please re-try login after sometime",
    },
    {
      id: "LicenseNotFoundException",
      description: "License not Found",
      defaultMessage: "License not exist in our portal Dashboard",
    },
    {
      id: "UserNotFoundException",
      description: "User not Found in TMS",
      defaultMessage: "User not found in portal TMS ",
    },
    {
      id: "UserNotFoundException",
      description: "User not Found in Dashboard",
      defaultMessage: "User not found in portal Dashboard ",
    },
  ],

  somethingWentWrongError: {
    id: "app.components.somethingWentWrongError",
    defaultMessage: "Something went wrong!",
  },
  choose: {
    id: "app.components.resetPassword.choose",
    defaultMessage: "Choose",
  },
  samePasswordError: {
    id: "app.components.resetPassword.samePasswordError",
    defaultMessage:
      "Enter a new password, please make sure its not a previously used one.",
  },
  redirectToLogin: {
    id: "app.components.resetPassword.redirectToLogin",
    defaultMessage: "Redirecting to {login} page",
  },
  passwordUpdated: {
    id: "app.components.resetPassword.passwordUpdated",
    defaultMessage: "Your password has been changed.",
  },
  resetPasswordTitle: {
    id: "app.components.resetPassword.resetPasswordTitle",
    defaultMessage: "Choose Username and Reset password",
  },
  confirmNewPassword: {
    id: "app.components.resetPassword.confirmNewPassword",
    defaultMessage: "Confirm new password",
  },
  newPassword: {
    id: "app.components.resetPassword.newPassword",
    defaultMessage: "New password",
  },
  passwordMustInclude: {
    id: "app.components.resetPassword.passwordMustInclude",
    defaultMessage: "Your password must include:",
  },
  conditionLength: {
    id: "app.components.resetPassword.conditionLength",
    defaultMessage: "Between 8 to 32 characters",
  },
  conditionUppercase: {
    id: "app.components.resetPassword.conditionUppercase",
    defaultMessage: "At least one uppercase letter",
  },
  conditionLowercase: {
    id: "app.components.resetPassword.conditionLowercase",
    defaultMessage: "At least one lowercase letter",
  },
  conditionSpecialCharacters: {
    id: "app.components.resetPassword.conditionSpecialCharacters",
    defaultMessage: "At least one digit or one of these symbols $!#&@?%*_",
  },
  passwordRequiredError: {
    id: "app.components.passwordRequiredError",
    defaultMessage: "Password is required",
  },
  passwordsDontMatch: {
    id: "app.components.resetPassword.passwordsDontMatch",
    defaultMessage: "Passwords don't match",
  },
  back: {
    id: "app.components.resetPassword.back",
    defaultMessage: "Back",
  },
  update: {
    id: "app.components.resetPassword.update",
    defaultMessage: "Update",
  },
  about: {
    id: "app.components.about",
    defaultMessage: "About",
  },
  legal: {
    id: "app.components.legal",
    defaultMessage: "Legal",
  },
  privacy: {
    id: "app.components.privacy",
    defaultMessage: "Privacy",
  },
  terms: {
    id: "app.components.terms",
    defaultMessage: "Terms and conditions",
  },
  language: {
    id: "app.components.language",
    defaultMessage: "English",
  },
});
