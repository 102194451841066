// @ts-nocheck
import React from "react";

function PlPlContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>Prawa autorskie, znaki towarowe i inne noty prawne</b>
          </center>
        </div>
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>PRAWA AUTORSKIE GLOBALENGLISH</b>
          </p>
          <p />
          <p>
            {" "}
            Copyright © 1999-2013 GlobalEnglish Corporation, wszelkie prawa
            zastrzeżone{" "}
          </p>
          <br />
          <p>
            <b>PRAWA AUTORSKIE STRON TRZECICH</b>{" "}
          </p>
          <p> </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>, wydanie pierwsze, 1995
            <br /> © Longman Group UK Limited 1995{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> © Longman Group Limited 1995{" "}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p*/}
          <p>
            {" "}
            Copyright © 1995-1998 Macromedia, Inc., wszelkie prawa zastrzeżone{" "}
          </p>
          <p>
            {" "}
            Copyright © 1999 Microsoft Corporation, wszelkie prawa zastrzeżone{" "}
          </p>
          <p>
            {" "}
            Copyright © 1999 Sipro Lab Telecom Inc., wszelkie prawa zastrzeżone{" "}
          </p>
          <p>
            {" "}
            Copyright © Business English Pod Limited, wszelkie prawa
            zastrzeżone. Wszelkie treści, w tym podcasty, phrasecasty,
            videocasty, notatki oraz książki elektroniczne w formacie audio są
            własnością firmy Business English Pod Limited i są wykorzystywane za
            pozwoleniem firmy GlobalEnglish Corporation.{" "}
          </p>
          <p>
            {" "}
            WGrammar Grammar Checker Engine Copyright © 1998-2006 Wintertree
            Software Inc. ({" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.wintertree-software.com"
            >
              <div
                onmouseout="this.className='blu11'"
                onmouseover="this.className='blu11rllover'"
                className="blu11"
              >
                www.wintertree-software.com
              </div>
            </a>
            ){" "}
          </p>
          <p>
            {" "}
            Zawiera oprogramowanie do rozpoznawania mowy EduSpeak<sup>®</sup> na
            licencji firmy SRI International
            <sup>®</sup>. Copyright © SRI International.{" "}
          </p>
          {/*p> Systran Translation Engine &copy; SYSTRAN Software, Inc </p*/}
          <p>
            {" "}
            Zdjęcia dostarczone przez AP/Wide World Photos są chronione prawem
            autorskim i są własnością agencji Associated Press. Użytek bez
            wcześniejszego zezwolenia ze strony AP/Wide World Photos jest
            niedozwolony. W przypadku naruszenia praw autorskich zostaną podjęte
            odpowiednie działania prawne.{" "}
          </p>
          <p>
            {" "}
            Zdjęcia dostarczone przez PhotoDisc są chronione prawem autorskim i
            są własnością PhotoDisc, Inc. Images © copyright 1999 PhotoDisc,
            Inc.{" "}
          </p>
          <p>
            {" "}
            Copyright © Barron's Educational Series, Inc., z HOW TO PREPARE FOR
            THE TOEIC<sup>®</sup>{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i> © 1998 AMACOM NEW MEDIA — podmiot zależny
            będący w całości własnością firmy American Management Association,
            New York. Wszelkie prawa zastrzeżone.{" "}
          </p>
          <p>
            {" "}
            Obrazy clip art dostarczone przez firmę Microsoft są chronione
            prawem autorskim i są własnością firmy Microsoft Corporation i/lub
            jej dostawców. Użytek bez wcześniejszej zgody firmy Microsoft jest
            zakazany.{" "}
          </p>
          <p>
            {" "}
            Obrazy clip art dostarczone przez firmę ArtToday są chronione prawem
            autorskim i są własnością firmy Zedcor, Inc., — podmiotu zależnego
            będącego w całości własnością firmy IMSI Inc. — i/lub jej dostawców.
            Użytek bez wcześniejszej zgody firmy Zedcor jest zakazany.{" "}
          </p>
          <p>
            {" "}
            Obrazy clip art dostarczone przez firmę Eyewire są chronione prawem
            autorskim i są własnością firmy Eyewire Inc. i/lub jej dostawców.
            Użytek bez wcześniejszej zgody firmy Eyewire jest zakazany.{" "}
          </p>
          <p>
            {" "}
            Zdjęcia dostarczone przez firmę Corbis są chronione prawem autorskim
            i są własnością firmy Corbis Corporation i/lub jej dostawców. Użytek
            bez wcześniejszej zgody firmy Corbis jest zakazany.{" "}
          </p>
          <p>
            {" "}
            Zdjęcia dostarczone przez firmę ImageQuest są chronione prawem
            autorskim i są własnością firmy ImageQuest i/lub jej dostawców.
            Użytek bez wcześniejszej zgody firmy ImageQuest jest zakazany.{" "}
          </p>
          <p />
          <br />
          <p>
            <b>Znaki towarowe GlobalEnglish</b>
          </p>
          <p />
          <p>
            Poniżej znajdują się niektóre znaki towarowe należące do firmy
            GlobalEnglish Corporation oraz ogólne nazwy dla tych znaków
            towarowych obowiązujące w USA oraz innych państwach. Brak logo lub
            nazwy na liście nie oznacza zrzeczenia się jakichkolwiek lub
            wszystkich praw do własności intelektualnej, które firma
            GlobalEnglish Corporation lub jej spółki zależne posiadają względem
            nazw lub logo swoich produktów, funkcji lub usług. Aby dowiedzieć
            się, jak poprawnie odwoływać się do nazw produktów oraz znaków
            towarowych firmy GlobalEnglish, należy poprosić o kopię wskazówek
            dotyczących znaków towarowych GlobalEnglish.
          </p>
          <p />
          <br />
          <style
            dangerouslySetInnerHTML={{
              __html: " \t\ttd{padding:10px !important} \t\t",
            }}
          />
          <table
            border={1}
            cellPadding={10}
            cellSpacing={0}
            bordercolor="#808080"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  TradeMark
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Status
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  Enterprise Fluency
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Bloom
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Corporate Learning Service
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Edge
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo Pro
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Magazine
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Mobile Learning Network
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Productivity Toolbar
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP+
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Motivator
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Executive Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>ZNAKI TOWAROWE STRON TRZECICH</b>
          </p>
          <p />
          <p>
            {" "}
            Adobe i Flash są znakami towarowymi lub zarejestrowanymi znakami
            handlowymi firmy Adobe Systems Incorporated, działającej na terenie
            Stanów Zjednoczonych i/lub innych krajów
          </p>
          <p>
            {" "}
            Microsoft jest zarejestrowanym znakiem towarowym firmy Microsoft
            Corporation.{" "}
          </p>
          {/*p> Langenscheidt KG jest zarejestrowanym znakiem towarowym firmy Langenscheidt KG, Berlin and Munich. </p*/}
          <p>
            {" "}
            Living Language i Colophon są zarejestrowanymi znakami towarowymi
            firmy Random House, Inc.{" "}
          </p>
          <p>
            {" "}
            Sipro i logo Sipro są znakami towarowymi firmy Sipro Lab Telecom
            Inc. ACELP<sup>®</sup> jest zastrzeżonym znakiem towarowym
            Université Sherbrooke używanym na licencji Sipro Lab Telecom Inc.{" "}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International, logo SRI International, EduSpeak oraz logo
                  EduSpeak są znakami towarowymi lub zastrzeżonymi znakami
                  towarowymi firmy SRI International.
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p> SYSTRAN jest zastrze&#380;onym znakiem towarowym lub oznaczeniem us&#322;ugi firmy SYSTRAN Software, Inc., a logo SYSTRAN jest zastrze&#380;onym oznaczeniem us&#322;ugi firmy SYSTRAN Software, Inc. </p*/}
          <p>
            {" "}
            AP/Wide World Photos jest znakiem towarowym agencji Associated
            Press.{" "}
          </p>
          <p>
            {" "}
            PhotoDisc jest zastrzeżonym znakiem towarowym firmy PhotoDisc, Inc.{" "}
          </p>
          <p>
            {" "}
            Logo Business English Pod, www.businessenglishpod.com oraz Business
            English Pod są znakami towarowymi firmy Business English Pod
            Limited.{" "}
          </p>
          <p>
            {" "}
            Wszystkie inne znaki towarowe są własnością odpowiednich
            właścicieli.{" "}
          </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default PlPlContent;
