// @ts-nocheck
import React from "react";

function ZhTwContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>版權，商標及其他法律相關事項</b>
          </center>
        </div>
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>GLOBALENGLISH 著作權</b>
          </p>
          <p />
          <p> Copyright © 1999-2013 GlobalEnglish Corporation 版權所有 </p>
          <br />
          <p>
            {" "}
            <b>第三方著作權</b>{" "}
          </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>，第 1 版，1995
            <br /> © Longman Group UK Limited 1995{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> © Longman Group Limited 1995{" "}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p*/}
          <p> Copyright © 1995 - 1998 Macromedia, Inc. 版權所有 </p>
          <p> Copyright © 1999 Microsoft Corporation 版權所有 </p>
          <p> Copyright © 1999 Sipro Lab Telecom Inc. 版權所有 </p>
          <p>
            {" "}
            Copyright © Business English Pod Limited
            版權所有。所有內容，包括播客、Phrasecast、Videocast、學習重點及音訊電子書，皆為
            Business English Pod Limited 之財產，必須徵得 GlobalEnglish
            Corporation 的許可方能使用。{" "}
          </p>
          <p>
            {" "}
            WGrammar Grammar Checker Engine Copyright © 1998-2006 Wintertree
            Software Inc. ({" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.wintertree-software.com"
            >
              <div
                onmouseout="this.className='blu11'"
                onmouseover="this.className='blu11rllover'"
                className="blu11"
              >
                www.wintertree-software.com
              </div>
            </a>
            ){" "}
          </p>
          <p>
            {" "}
            包含由 SRI International<sup>®</sup> 授權的 EduSpeak<sup>®</sup>{" "}
            語音辨識軟體。Copyright © SRI International.{" "}
          </p>
          {/*p> Systran Translation Engine &copy; SYSTRAN Software, Inc </p*/}
          <p>
            {" "}
            AP/Wide World Photos 所提供的相片受著作權保護，並且屬於 Associated
            Press 之財產。未經 AP/Wide World Photos
            事先許可，不得擅自使用。如有違反上述規定之情事，將依法處理。{" "}
          </p>
          <p>
            {" "}
            PhotoDisc 所提供的相片受著作權保護，並且屬於 PhotoDisc, Inc.
            之財產。影像 © copyright 1999 PhotoDisc, Inc.{" "}
          </p>
          <p>
            {" "}
            Copyright © Barron's Educational Series, Inc.，出自 HOW TO PREPARE
            FOR THE TOEIC<sup>®</sup>{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i> © 1998 AMACOM NEW MEDIA，紐約 American
            Management Association 之全資附屬公司。版權所有。{" "}
          </p>
          <p>
            {" "}
            Microsoft 所提供的美工圖案受著作權保護，並為 Microsoft Corporation
            與/或其供應商之財產。未經 Microsoft 事先許可，不得擅自使用。{" "}
          </p>
          <p>
            {" "}
            ArtToday 所提供的美工圖案受著作權保護，並且屬於 IMSI Inc.
            之全資附屬公司 Zedcor, Inc. 與/或其供應商之財產。未經 Zedcor
            事先許可，不得擅自使用。{" "}
          </p>
          <p>
            {" "}
            Eyewire 所提供的美工圖案受著作權保護，並為 Eyewire Inc.
            與/或其供應商之財產。未經 Eyewire 事先許可，不得擅自使用。{" "}
          </p>
          <p>
            {" "}
            Corbis 所提供的照片受著作權保護，並為 Corbis Corporation
            與/或其供應商之財產。未經 Corbis 事先許可，不得擅自使用。{" "}
          </p>
          <p>
            {" "}
            ImageQuest 所提供的照片受著作權保護，並為 ImageQuest
            與/或其供應商之財產。未經 ImageQuest 事先許可，不得擅自使用。{" "}
          </p>
          <p />
          <br />
          <p>
            <b>GlobalEnglish 商標</b>
          </p>
          <p />
          <p>
            {" "}
            以下列出 GlobalEnglish Corporation
            所擁有之部分商標及該等商標於美國及/或其他國家之建議統稱。對於本清單未列出的名稱或商標圖案，並不代表
            GlobalEnglish Corporation
            或其子公司放棄旗下任何商品、功能或服務名稱或標誌之任何或全部智慧財產權。如需有關正確參照
            GlobalEnglish 商品名稱及商標之指南，請索取一份 GlobalEnglish
            商標通用指南。
          </p>
          <p />
          <br />
          <style
            dangerouslySetInnerHTML={{
              __html: " \t\ttd{padding:10px !important} \t\t",
            }}
          />
          <table
            border={1}
            cellPadding={10}
            cellSpacing={0}
            bordercolor="#808080"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  TradeMark
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Status
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  Enterprise Fluency
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Bloom
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Corporate Learning Service
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Edge
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo Pro
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Magazine
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Mobile Learning Network
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Productivity Toolbar
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP+
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Motivator
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Executive Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>第三方商標</b>
          </p>
          <p />
          <p>
            {" "}
            Adobe 和 Flash 是 Adobe Systems Incorporated
            在美國及/或其他國家的商標或註冊商標
          </p>
          <p> Microsoft 是 Microsoft Corporation 的註冊商標。 </p>
          {/*p> Langenscheidt KG &#26159; Langenscheidt KG, Berlin and Munich &#30340;&#35387;&#20874;&#21830;&#27161;&#12290; </p*/}
          <p>
            {" "}
            Living Language 和 Colophon 是 Random House, Inc. 的註冊商標。{" "}
          </p>
          <p>
            {" "}
            Sipro 與 Sipro 標誌是 Sipro Lab Telecom Inc. 的商標。ACELP
            <sup>®</sup> 是 Université Sherbrooke 的註冊商標，經 Sipro Lab
            Telecom Inc. 授權而使用。{" "}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International、SRI International 標誌、EduSpeak 與
                  EduSpeak 標誌是 SRI International 的商標或註冊商標。
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p> SYSTRAN &#26159; SYSTRAN Software, Inc. &#30340;&#35387;&#20874;&#21830;&#27161;&#25110;&#26381;&#21209;&#27161;&#31456;&#65307;SYSTRAN &#27161;&#35468;&#26159; SYSTRAN Software, Inc. &#30340;&#35387;&#20874;&#26381;&#21209;&#27161;&#31456;&#12290;</p*/}
          <p> AP/Wide World Photos 是 Associated Press 的商標。 </p>
          <p> PhotoDisc 是 PhotoDisc, Inc. 的註冊商標。 </p>
          <p>
            {" "}
            Business English Pod、www.businessenglishpod.com 和 Business English
            Pod 標誌是 Business English Pod Limited 的商標。{" "}
          </p>
          <p> 其他所有商標是其他各所有人之財產。 </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default ZhTwContent;
