// @ts-nocheck
import React from "react";

function TrTrContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>Telif Hakları, Ticari Marka ve DiÄŸer Yasal Bildirimler</b>
          </center>
        </div>
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>GLOBALENGLISH TELİF HAKKI</b>
          </p>
          <p />
          <p>
            {" "}
            Telif Hakkı© 1999-2013 GlobalEnglish Corporation, tüm hakları
            saklıdır{" "}
          </p>
          <br />
          <p>
            {" "}
            <b>ÜÇÜNCÜ TARAF TELİF HAKLARI</b>{" "}
          </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>, İlk Baskı, 1995
            <br /> © Longman Group UK Limited 1995{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> © Longman Group Limited 1995{" "}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p*/}
          <p>
            {" "}
            Telif Hakkı © 1995 - 1998 Macromedia, Inc., tüm hakları saklıdır{" "}
          </p>
          <p>
            {" "}
            Telif Hakkı © 1999 Microsoft Corporation, tüm hakları saklıdır{" "}
          </p>
          <p>
            {" "}
            Telif Hakkı © 1999 Sipro Lab Telecom Inc., tüm hakları saklıdır{" "}
          </p>
          <p>
            {" "}
            Telif Hakkı © Business English Pod Limited, tüm hakları saklıdır.
            Podcast'ler, phrasecast'ler, videocast'ler, çalışma notları ve sesli
            e-kitaplar dahil tüm içerik, Business English Pod Limited'e aittir
            ve GlobalEnglish Corporation tarafından izin alınarak
            kullanılmaktadır.{" "}
          </p>
          <p>
            {" "}
            WGrammar Gramer Kontrolü Motoru Telif Hakkı{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.wintertree-software.com"
            >
              <div
                onmouseout="this.className='blu11'"
                onmouseover="this.className='blu11rllover'"
                className="blu11"
              >
                ©
              </div>
            </a>{" "}
            1998-2006 Wintertree Software Inc. (www.wintertree-software.com){" "}
          </p>
          <p>
            {" "}
            SRI International<sup>®</sup> lisansı altında kullanılan EduSpeak
            <sup>®</sup> konuşma tanıma yazılımını içerir. Telif Hakkı © SRI
            International.{" "}
          </p>
          {/*p> Systran &#199;eviri Motoru &copy; SYSTRAN Software, Inc </p*/}
          <p>
            {" "}
            AP/Wide World Photos tarafından sağlanan fotoğraflar, telif hakkı
            ile korunmaktadır ve mülkiyeti Associated Press'e aittir. AP/Wide
            World Photos'tan önceden izin alınmaksızın kullanımı yasaktır. Bunun
            ihlali, yasal işleme tabidir.{" "}
          </p>
          <p>
            {" "}
            PhotoDisc tarafından sağlanan fotoğraflar telif hakkı ile
            korunmaktadır ve mülkiyeti PhotoDisc, Inc. Images ©'a aittir; telif
            hakkı 1999 PhotoDisc, Inc.{" "}
          </p>
          <p>
            {" "}
            Telif Hakkı © HOW TO PREPARE FOR THE TOEIC<sup>®</sup>'den Barron's
            Educational Series, Inc.{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i> © 1998 AMACOM NEW MEDIA, American
            Management Association New York'un bağlı kuruluşu. Tüm hakları
            saklıdır.{" "}
          </p>
          <p>
            {" "}
            Microsoft tarafından sağlanan küçük resimler telif hakkı ile
            korunmaktadır ve mülkiyeti Microsoft Corporation ve/veya
            tedarikçilerine aittir. Microsoft'tan önceden izin alınmaksızın
            kullanımı yasaktır.{" "}
          </p>
          <p>
            {" "}
            ArtToday tarafından sağlanan telif hakkı ile korunmaktadır ve
            mülkiyeti, IMSI Inc. ve/veya sağlayıcılarının bağlı şirketi olan
            Zedcor, Inc.,'e aittir. Zedcor'dan önceden izin alınmaksızın
            kullanımı yasaktır.{" "}
          </p>
          <p>
            {" "}
            Eyewire tarafından sağlanan küçük resimler telif hakkı ile
            korunmaktadır ve mülkiyeti Eyewire Inc. ve/veya tedarikçilerine
            aittir. Eyewire'dan önceden izin alınmaksızın kullanımı yasaktır.{" "}
          </p>
          <p>
            {" "}
            Corbis tarafından sağlana fotoğraflar telif hakkı ile korunmaktadır
            ve mülkiyeti Corbis Corporation ve/veya tedarikçilerine aittir.
            Corbis'ten önceden izin alınmaksızın kullanımı yasaktır.{" "}
          </p>
          <p>
            {" "}
            ImageQuest tarafından sağlanan fotoğraflar telif hakkı ile
            korunmaktadır ve mülkiyeti ImageQuest ve/veya tedarikçilerine
            aittir. ImageQuest'dan önceden izin alınmaksızın kullanımı yasaktır.{" "}
          </p>
          <p />
          <br />
          <p>
            <b>GlobalEnglish Ticari Markaları</b>
          </p>
          <p />
          <p>
            Aşağıda GlobalEnglish Corporation'a ait bazı ticari markalar ve bu
            ticari markalar için Amerika Birleşik Devletleri ve/veya diğer
            ülkelerde önerilen genel terimler bulunmaktadır. Bir ad veya logonun
            bu listede görünmemesi, GlobalEnglish Corporation veya bağlı
            şirketlerinin ürün, özellik ya da servis adı veya logo ilgili fikri
            mülkiyet haklarından tamamen ya da kısmen feragat ettikleri anlamına
            gelmez. GlobalEnglish ürün adları ve ticari markalarına nasıl uygun
            şekilde referans verileceğine dair talimatlar için lütfen
            GlobalEnglish genel ticari marka kılavuzunun bir kopyasını isteyin.
          </p>
          <p />
          <br />
          <style
            dangerouslySetInnerHTML={{
              __html: " \t\ttd{padding:10px !important} \t\t",
            }}
          />
          <table
            border={1}
            cellPadding={10}
            cellSpacing={0}
            bordercolor="#808080"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  TradeMark
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Status
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  Enterprise Fluency
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Bloom
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Corporate Learning Service
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Edge
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo Pro
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Magazine
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Mobile Learning Network
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Productivity Toolbar
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP+
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Motivator
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Executive Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>ÜÇÜNCÜ TARAF TİCARİ MARKALARI</b>
          </p>
          <p />
          <p>
            {" "}
            Adobe ve Flash, Adobe Systems Incorporated şirketinin ABD ve/veya
            diğer ülkelerde ticari markaları veya tescilli ticari markalarıdır.
          </p>
          <p>
            {" "}
            Microsoft, Microsoft Corporation şirketinin tescilli ticari
            markasıdır.{" "}
          </p>
          {/*p> Langenscheidt KG, Langenscheidt KG, Berlin and Munich &#351;irketinin tescilli ticari markas&#305;d&#305;r. </p*/}
          <p>
            {" "}
            Living Language ve Colophon, Random House, Inc. şirketinin tescilli
            ticari markalarıdır.{" "}
          </p>
          <p>
            {" "}
            Sipro ve Sipro logosu, Sipro Lab Telecom Inc. şirketinin ticari
            markalarıdır. Sipro Lab Telecom Inc. lisansı altında kullanılan
            ACELP<sup>®</sup>, Université Sherbrooke'un tescilli ticari
            markasıdır.{" "}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International, SRI International logosu, EduSpeak ve
                  EduSpeak logosu; SRI International'ın ticari markaları ya da
                  tescilli ticari markalarıdır.
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p> SYSTRAN, SYSTRAN Software, Inc.'&#305;n tescilli ticari markas&#305; veya hizmet markas&#305;d&#305;r ve SYSTRAN logosu, SYSTRAN Software, Inc.'&#305;n tescilli hizmet markas&#305;d&#305;r. </p*/}
          <p> AP/Wide World Photos, Associated Press'in ticari markasıdır. </p>
          <p>
            {" "}
            PhotoDisc, PhotoDisc, Inc. şirketinin tescilli ticari markasıdır.{" "}
          </p>
          <p>
            {" "}
            Business English Pod, www.businessenglishpod.com ve Business English
            Pod logoları, Business English Pod Limited şirketinin ticari
            markalarıdır.{" "}
          </p>
          <p>
            {" "}
            Diğer tüm ticari markalar, ilgili hak sahiplerinin ticari
            markalarıdır.{" "}
          </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default TrTrContent;
