/* eslint-disable */
// @ts-nocheck
import React from "react";

function CzCzContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>Copyright, ochranné známky a další právní oznámení</b>
          </center>
        </div>
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>GLOBALENGLISH COPYRIGHT</b>
          </p>
          <p />
          <p>
            {" "}
            Copyright © 1999–2012 GlobalEnglish Corporation, všechna práva
            vyhrazena{" "}
          </p>
          <br />
          <p>
            {" "}
            <b> COPYRIGHTY TŘETÍCH STRAN</b>{" "}
          </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>, první vydání, 1995
            <br /> © Longman Group UK Limited 1995{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> © Longman Group Limited 1995{" "}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p*/}
          <p>
            {" "}
            Copyright © 1995–1998 Macromedia, Inc., všechna práva vyhrazena{" "}
          </p>
          <p>
            {" "}
            Copyright © 1999 Microsoft Corporation, všechna práva vyhrazena{" "}
          </p>
          <p>
            {" "}
            Copyright © 1999 Sipro Lab Telecom Inc., všechna práva vyhrazena{" "}
          </p>
          <p>
            {" "}
            Copyright © Business English Pod Limited, všechna práva vyhrazena.
            Veškerý obsah včetně vysílání podcast, phrasecast, videocast,
            studijních poznámek a zvukových elektronických knih je vlastnictvím
            společnosti Business English Pod Limited a je používán se svolením
            společnosti GlobalEnglish Corporation.{" "}
          </p>
          <p>
            {" "}
            WGrammar Grammar Checker Engine Copyright© 1998–2006 Wintertree
            Software Inc. (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.wintertree-software.com"
            >
              <div
                onmouseout="this.className='blu11'"
                onmouseover="this.className='blu11rllover'"
                className="blu11"
              >
                www.wintertree-software.com
              </div>
            </a>
            ){" "}
          </p>
          <p>
            {" "}
            Obsahuje software pro rozpoznávání řeči EduSpeak<sup>®</sup>, v
            licenci od společnosti SRI International
            <sup>®</sup>. Copyright © SRI International.{" "}
          </p>
          {/*p> Systran Translation Engine &copy; SYSTRAN Software, Inc </p*/}
          <p>
            {" "}
            Fotografie poskytnuté společností AP/Wide World Photos jsou chráněny
            autorskými právy a jsou vlastnictvím společnosti Associated Press.
            Jakékoli použití bez předchozího souhlasu společnosti AP/Wide World
            Photos je zakázáno. Jakékoli zneužití může být předmětem soudní
            žaloby.{" "}
          </p>
          <p>
            {" "}
            Fotografie poskytnuté společností PhotoDisc jsou chráněny autorskými
            právy a jsou vlastnictvím společnosti PhotoDisc, Inc. Images ©
            copyright 1999 PhotoDisc, Inc.{" "}
          </p>
          <p>
            {" "}
            Copyright © Barron's Educational Series, Inc., z HOW TO PREPARE FOR
            THE TOEIC<sup>®</sup>{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i> © 1998 AMACOM NEW MEDIA, dceřiná společnost
            v úplném vlastnictví organizace American Management Association, New
            York. Všechna práva vyhrazena.{" "}
          </p>
          <p>
            {" "}
            Kliparty poskytnuté společností Microsoft jsou chráněny autorskými
            právy a jsou vlastnictvím společnosti Microsoft Corporation a jejích
            obchodních partnerů. Jakékoli použití bez předchozího souhlasu
            společnosti Microsoft je zakázáno.{" "}
          </p>
          <p>
            {" "}
            Kliparty poskytnuté společností ArtToday jsou chráněny autorskými
            právy a jsou vlastnictvím společnosti Zedcor, Inc., dceřiné
            společnosti v úplném vlastnictví organizace IMSI Inc. a jejích
            obchodních partnerů. Jakékoli použití bez předchozího souhlasu
            společnosti Zedcor je zakázáno.{" "}
          </p>
          <p>
            {" "}
            Kliparty poskytnuté společností Eyewire jsou chráněny autorskými
            právy a jsou vlastnictvím společnosti Eyewire Inc. a jejích
            obchodních partnerů. Jakékoli použití bez předchozího souhlasu
            společnosti Eyewire je zakázáno.{" "}
          </p>
          <p>
            {" "}
            Fotografie poskytnuté společností Corbis jsou chráněny autorskými
            právy a jsou vlastnictvím společnosti Corbis Corporation a jejích
            obchodních partnerů. Jakékoli použití bez předchozího souhlasu
            společnosti Corbis je zakázáno.{" "}
          </p>
          <p>
            {" "}
            Fotografie poskytnuté společností ImageQuest jsou chráněny
            autorskými právy a jsou vlastnictvím společnosti ImageQuest a jejích
            obchodních partnerů. Jakékoli použití bez předchozího souhlasu
            společnosti ImageQuest je zakázáno.{" "}
          </p>
          <p />
          <br />
          <p>
            <b>Ochranné známky společnosti GlobalEnglish</b>
          </p>
          <p />
          <p>
            Zde jsou uvedeny některé ochranné známky vlastněné společností
            GlobalEnglish Corporation a navrhované obecné podmínky pro tyto
            ochranné známky ve Spojených státech a jiných zemích. Nepřítomnost
            názvu nebo loga v tomto seznamu nezakládá zřeknutí se jakýchkoli či
            všech práv na duševní vlastnictví, jichž společnost GlobalEnglish
            Corporation nebo její pobočky nabyly k jakémukoli názvu nebo logu
            svého produktu, funkce či služby. Vyžádejte si kopii obecných pokynů
            společnosti GlobalEnglish týkajících se ochranných známek, které vám
            poskytnou vodítko pro správný způsob odkazování na názvy produktů a
            ochranné známky společnosti GlobalEnglish.
          </p>
          <p />
          <br />
          <style
            dangerouslySetInnerHTML={{
              __html: " \t\ttd{padding:10px !important} \t\t",
            }}
          />
          <table
            border={1}
            cellPadding={10}
            cellSpacing={0}
            bordercolor="#808080"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  TradeMark
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Status
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  Enterprise Fluency
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Bloom
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Corporate Learning Service
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Edge
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo Pro
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Magazine
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Mobile Learning Network
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Productivity Toolbar
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP+
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Motivator
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Executive Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>OCHRANNÉ ZNÁMKY TŘETÍ STRANY</b>
          </p>
          <p />
          <p>
            {" "}
            Adobe a Flash jsou buď ochranné známky nebo registrované ochranné
            známky Adobe Systems Incorporated ve Spojených státech a/nebo jiných
            zemích.
          </p>
          <p>
            {" "}
            Microsoft je registrovaná ochranná známka společnosti Microsoft
            Corporation.{" "}
          </p>
          {/*p> Langenscheidt KG je registrovan&#225; ochrann&#225; zn&#225;mka spole&#269;nosti Langenscheidt KG, Berlin and Munich. </p*/}
          <p>
            {" "}
            Living Language a Colophon jsou registrované ochranné známky
            společnosti Random House, Inc.{" "}
          </p>
          <p>
            {" "}
            Sipro a logo Sipro jsou ochranné známky společnosti Sipro Lab
            Telecom Inc. ACELP<sup>®</sup> je registrovaná ochranná známka
            společnosti Université Sherbrooke používaná v licenci Sipro Lab
            Telecom Inc.{" "}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International, logo SRI International, EduSpeak a logo
                  EduSpeak jsou ochrannými známkami nebo registrovanými
                  ochrannými známkami společnosti SRI International.{" "}
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p> SYSTRAN je registrovan&#225; ochrann&#225; zn&#225;mka nebo zna&#269;ka slu&#382;by spole&#269;nosti SYSTRAN Software, Inc. a logo SYSTRAN je registrovan&#225; zna&#269;ka slu&#382;by spole&#269;nosti SYSTRAN Software, Inc. </p*/}
          <p>
            {" "}
            AP/Wide World Photos je registrovaná ochranná známka společnosti
            Associated Press.{" "}
          </p>
          <p>
            {" "}
            PhotoDisc je registrovaná ochranná známka společnosti PhotoDisc,
            Inc.{" "}
          </p>
          <p>
            {" "}
            Business English Pod, www.businessenglishpod.com a loga Business
            English Pod jsou ochranné známky společnosti Business English Pod
            Limited.{" "}
          </p>
          <p>
            {" "}
            Všechny ostatní ochranné známky jsou majetkem příslušných vlastníků.{" "}
          </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default CzCzContent;
