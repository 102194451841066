// @ts-nocheck
import React from "react";

function JaJpContent() {
  return (
    <>
      <div
        className="olPrint"
        style={{ cursor: "pointer", display: "none" }}
      ></div>
      <div id="agreement_container">
        <table
          style={{
            height: "300px !important",
            width: "100%",
            cellSpacing: "0",
            cellPadding: "0",
            border: "0",
          }}
        >
          <tbody>
            <tr>
              <td width="50%" valign="top" align="center">
                {" "}
                &nbsp;
                <br /> &nbsp;
                <br />
                {/*// world map */}{" "}
                <div
                  style={{
                    color: "#666666",
                    fontSize: "13px",
                    fontFamily: "arial",
                  }}
                >
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.globalenglish.com"
                  >
                    <img
                      alt=""
                      src={require("../../../assets/images/ge-logo2.png")}
                    />
                  </a>
                  <br />
                  <a
                    style={{ color: "#71a4be", textDecoration: "none" }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.globalenglish.com/whoweare/aboutus/our_philosophy"
                  >
                    <b>当社について</b>
                  </a>{" "}
                </div>{" "}
              </td>
              <td width="50%" valign="top" align="left">
                {" "}
                &nbsp;
                <br /> &nbsp;
                <br /> {/*// contact info */}
                <div
                  style={{
                    color: "#666666",
                    fontSize: "13px",
                    fontFamily: "arial",
                  }}
                >
                  <b>GlobalEnglish</b>
                  <br /> 1875 South Grant Street,Suite 700
                  <br /> San Mateo, CA 94402
                  <br /> USA
                  <br /> &nbsp;
                  <br /> 代表: +1 415-906-7991
                  <br /> 営業: +1 415-915-0676
                  <br /> &nbsp;
                  <br />
                  <table
                    cellSpacing={0}
                    cellPadding={0}
                    border={0}
                    bgcolor="#ffffff"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <img
                            alt=""
                            src={require("../../../assets/images/button_green_off_left.png")}
                            id="contactUsbtnLeft"
                          />
                        </td>
                        <td
                          valign="middle"
                          background={require("../../../assets/images/button_green_off_center.png")}
                          align="center"

                          onmouseover="this.style.color=\'#ffffff\';"
                          style={{
                            fontFamily: "arial",
                            fontSize: "11px",
                            backgroundRepeat: "repeat-x",
                            color: "#28903b",
                          }}
                          id="contactUsbtnCenter"
                        >
                          <a
                            style={{ textDecoration: "none" }}
                            href="http://www.globalenglish.com/company/contact"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            お問い合わせ
                          </a>
                        </td>
                        <td>
                          <img
                            alt=""
                            src={require("../../../assets/images/button_green_off_right.png")}
                            id="contactUsbtnRight"
                          />
                        </td>
                      </tr>{" "}
                    </tbody>
                  </table>{" "}
                  &nbsp;
                  <br />{" "}
                  <b>
                    テクニカルサポートまたは問題の報告については、
                    <a
                      style={{ color: "#71a4be", textDecoration: "none" }}
                      href="https://support.globalenglish.com/ja-jp/contact_support"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {" "}
                      ここをクリック
                    </a>
                    してください。
                  </b>{" "}
                </div>{" "}
              </td>{" "}
            </tr>{" "}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default JaJpContent;
