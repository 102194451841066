// @ts-nocheck
import React from "react";
import { BasicSkeleton } from "bundles/common/components/BasicSkeleton";
import { Loader } from "bundles/common/components/Loader";
import { datadogLogs } from "@datadog/browser-logs";
const isUrlParams = window.location.search === "" ? true : false;

class Logout extends React.Component {
  constructor(props: any) {
    super(props);

    this.state = {
      error: null,
      redirectUrl: null,
      ssoUser: false,
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  logoutUser = async (url, noOfTries) => {
    try {
      this.setState({ loading: true });
      return await fetch(url, { credentials: "include" });
    } catch (err) {
      if (noOfTries === 0) {
        this.setState({ loading: false });
        datadogLogs.logger.error('Logout exception', err);
        throw err;
      }
      return await logoutUser(url, noOfTries - 1);
    }
  };

  UNSAFE_componentWillMount() {
    const ssoLogoutUrl = isUrlParams
      ? `${process.env.REACT_APP_GE_BASE_URL}/logout`
      : `${process.env.REACT_APP_GE_BASE_URL}/logout${window.location.search}`;
    const response = this.logoutUser(ssoLogoutUrl, 2);
    response
      .then((response) =>
        response.json().then((result) => {
          console.log("Result: ");
          if (result.ssoUser) {
            this.setState({
              ssoUser: true,
              redirectUrl: result.url,
              loading: false,
            });
          } else if (!result.ssoUser && result.url != null) {
            if (
              result.url ===
                window.location.protocol +
                  "//" +
                  window.location.host +
                  window.location.pathname ||
              "https://qa01-login.learnship.today/logout"
            ) {
              window.location.replace(
                `${process.env.REACT_APP_GE_LOGIN_URL}/${window.location.search}`,
              );
            } else window.location.replace(result.url);
          } else {
            setTimeout(() => {
              window.location.replace("/" + window.location.search);
            }, 2000);
            this.setState({ ssoUser: false, loading: false });
          }
        }),
      )
      .catch((error) => {
        datadogLogs.logger.error('Logout exception', error);
        if (error && !error.name.includes("SyntaxError")) {
          this.setState({ error, loading: false });
        } else {
          setTimeout(() => {
            window.location.replace("/" + window.location.search);
          }, 5000);
          this.setState({ error: null, loading: false });
        }
      });
  }

  handleSubmit() {
    const { redirectUrl } = this.state;
    if (redirectUrl) {
      window.location.href = this.state.redirectUrl;
    }
  }

  render() {
    const { redirectUrl, error, loading } = this.state;
    let title =
      !error || redirectUrl ? "You have been logged out" : "Logout Failed";
    return (
      <BasicSkeleton title={loading ? "Loading" : title}>
        <div className="lform" name="f" id="login-form">
          {loading ? (
            <fieldset
              className="button-padding"
              style={{ textAlign: "center" }}
            >
              <Loader />
            </fieldset>
          ) : null}
          {error && !loading && !redirectUrl ? (
            <React.Fragment>
              <fieldset
                className="button-padding"
                style={{ textAlign: "center" }}
              >
                <h3>Try Again Later</h3>
              </fieldset>
            </React.Fragment>
          ) : null}
          {redirectUrl && (
            <React.Fragment>
              <fieldset
                className="button-padding"
                style={{ textAlign: "center" }}
              >
                <h3>
                  You can{" "}
                  <u>
                    <a
                      href={redirectUrl}
                      onClick={this.handleSubmit}
                      title="log back"
                    >
                      log back in
                    </a>
                  </u>{" "}
                  or you can close this window
                </h3>
              </fieldset>
            </React.Fragment>
          )}
        </div>
      </BasicSkeleton>
    );
  }
}

export default Logout;
