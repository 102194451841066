// @ts-nocheck
import React from "react";

function RuRuContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>
              Авторское право, торговая марка, прочие юридические уведомления
            </b>
          </center>
        </div>
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>АВТОРСКИЕ ПРАВА GLOBALENGLISH</b>
          </p>
          <p />
          <p> © GlobalEnglish Corporation, 1999-2013 гг. Все права защищены </p>
          <br />
          <p>
            <b>АВТОРСКИЕ ПРАВА ТРЕТЬИХ ЛИЦ</b>{" "}
          </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>. - 1-е изд. - 1995 г.
            <br /> © Longman Group UK Limited. - 1995 г.{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> © Longman Group Limited, 1995 г.{" "}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p*/}
          <p> © Macromedia, Inc., 1995 - 1998 гг. Все права защищены </p>
          <p> © Microsoft Corporation, 1999 г. Все права защищены </p>
          <p> © Sipro Lab Telecom Inc., 1999 г. Все права защищены </p>
          <p>
            {" "}
            © Business English Pod Limited. Все права защищены. Весь контент,
            включая подкасты, фразкасты, видеокасты, методические рекомендации и
            аудиокниги, являются собственностью компании Business English Pod
            Limited и используются с разрешения GlobalEnglish Corporation.{" "}
          </p>
          <p>
            {" "}
            WGrammar Grammar Checker Engine © Wintertree Software Inc. ({" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.wintertree-software.com"
            >
              <div
                onmouseout="this.className='blu11'"
                onmouseover="this.className='blu11rllover'"
                className="blu11"
              >
                www.wintertree-software.com
              </div>
            </a>
            ), 1998-2006 гг.{" "}
          </p>
          <p>
            {" "}
            Включает в себя программное обеспечение для распознавания речи
            EduSpeak<sup>®</sup> по лицензии от SRI International<sup>®</sup>. ©
            SRI International.{" "}
          </p>
          {/*p> Systran Translation Engine &copy; SYSTRAN Software, Inc </p*/}
          <p>
            {" "}
            Фотографии, предоставленные AP/Wide World Photos, защищены авторским
            правом и являются собственностью агентства Associated Press. Любое
            использование без предварительного разрешения AP/Wide World Photos
            запрещено. Нарушение преследуется по закону.{" "}
          </p>
          <p>
            {" "}
            Фотографии, предоставленные корпорацией PhotoDisc, защищены
            авторским правом и являются собственностью корпорации PhotoDisc,
            Inc./ Изображения © PhotoDisc, Inc., 1999 г.{" "}
          </p>
          <p>
            {" "}
            © Barron's Educational Series, Inc., из серии HOW TO PREPARE FOR THE
            TOEIC<sup>®</sup>{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i> © AMACOM NEW MEDIA, 1998 г. AMACOM NEW
            MEDIA – это стопроцентное дочернее предприятие ассоциации American
            Management Association, Нью-Йорк. Все права защищены.{" "}
          </p>
          <p>
            {" "}
            Коллекции картинок, предоставленные корпорацией Microsoft, защищены
            авторским правом и являются собственностью корпорации Microsoft
            и/или ее дочерних предприятий. Любое использование без
            предварительного разрешения Microsoft запрещено.{" "}
          </p>
          <p>
            {" "}
            Коллекции картинок, предоставленные компанией ArtToday, защищены
            авторским правом и являются собственностью Zedcor, Inc.,
            стопроцентного дочернего предприятия IMSI Inc., и/или ее дочерних
            предприятий. Любое использование без предварительного разрешения
            корпорации Zedcor запрещено.{" "}
          </p>
          <p>
            {" "}
            Коллекции картинок, предоставленные корпорацией Eyewire, защищены
            авторским правом и являются собственностью корпорации Eyewire Inc.
            и/или ее дочерних предприятий. Любое использование без
            предварительного разрешения корпорации Eyewire запрещено.{" "}
          </p>
          <p>
            {" "}
            Фотографии, предоставленные корпорацией Corbis, защищены авторским
            правом и являются собственностью корпорации Corbis и/или ее дочерних
            предприятий. Любое использование без предварительного разрешения
            корпорации Corbis запрещено.{" "}
          </p>
          <p>
            {" "}
            Фотографии, предоставленные компанией ImageQuest, защищены авторским
            правом и являются собственностью компании ImageQuest и/или ее
            дочерних предприятий. Любое использование без предварительного
            разрешения ImageQuest запрещено.{" "}
          </p>
          <p />
          <br />
          <p>
            <b>Торговые марки GlobalEnglish</b>
          </p>
          <p />
          <p>
            Ниже приведены некоторые товарные знаки, принадлежащие GlobalEnglish
            Corporation, и рекомендуемые общие обозначения для товарных знаков в
            США и других странах. Отсутствие названия или логотипа в этом списке
            не является отказом от прав интеллектуальной собственности (любого
            из них или всех сразу), установленных GlobalEnglish Corporation или
            ее подразделениями на свои продукты, функции или служебные
            наименования или логотипы. Чтобы узнать, как правильно использовать
            товарные названия и товарные знаки GlobalEnglish, запросите,
            пожалуйста, экземпляр общих руководящих принципов использования
            товарных знаков GlobalEnglish.
          </p>
          <p />
          <br />
          <style
            dangerouslySetInnerHTML={{
              __html: " \t\ttd{padding:10px !important} \t\t",
            }}
          />
          <table
            border={1}
            cellPadding={10}
            cellSpacing={0}
            bordercolor="#808080"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  TradeMark
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Status
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  Enterprise Fluency
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Bloom
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Corporate Learning Service
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Edge
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo Pro
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Magazine
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Mobile Learning Network
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Productivity Toolbar
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP+
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Motivator
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Executive Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>ТОВАРНЫЕ ЗНАКИ ТРЕТЬИХ ЛИЦ</b>
          </p>
          <p />
          <p>
            {" "}
            Adobe и Flash являются товарными знаками или зарегистрированными
            товарными знаками Adobe Systems Incorporated в США и других странах
          </p>
          <p> Microsoft является торговой маркой корпорации Microsoft. </p>
          {/*p> Langenscheidt KG &#1103;&#1074;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103; &#1079;&#1072;&#1088;&#1077;&#1075;&#1080;&#1089;&#1090;&#1088;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1085;&#1086;&#1081; &#1090;&#1086;&#1088;&#1075;&#1086;&#1074;&#1086;&#1081; &#1084;&#1072;&#1088;&#1082;&#1086;&#1081; &#1082;&#1086;&#1084;&#1087;&#1072;&#1085;&#1080;&#1080; Langenscheidt KG, Berlin and Munich. </p*/}
          <p>
            {" "}
            Living Language и Colophon являются зарегистрированными торговыми
            марками Random House, Inc.{" "}
          </p>
          <p>
            {" "}
            Sipro и логотип Sipro являются торговыми марками Sipro Lab Telecom
            Inc. ACELP <sup>®</sup> является зарегистрированной торговой маркой
            Université Sherbrooke и используется по лицензии Sipro Lab Telecom
            Inc.{" "}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International, логотип SRI International, EduSpeak и
                  логотип EduSpeak являются торговыми знаками или
                  зарегистрированными торговыми знаками SRI International.
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p> SYSTRAN &#1103;&#1074;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103; &#1079;&#1072;&#1088;&#1077;&#1075;&#1080;&#1089;&#1090;&#1088;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1085;&#1099;&#1084; &#1090;&#1086;&#1088;&#1075;&#1086;&#1074;&#1099;&#1084; &#1079;&#1085;&#1072;&#1082;&#1086;&#1084; &#1080;&#1083;&#1080; &#1079;&#1085;&#1072;&#1082;&#1086;&#1084; &#1086;&#1073;&#1089;&#1083;&#1091;&#1078;&#1080;&#1074;&#1072;&#1085;&#1080;&#1103; SYSTRAN Software, Inc., &#1072; &#1083;&#1086;&#1075;&#1086;&#1090;&#1080;&#1087; SYSTRAN &#1103;&#1074;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103; &#1079;&#1072;&#1088;&#1077;&#1075;&#1080;&#1089;&#1090;&#1088;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1085;&#1099;&#1084; &#1079;&#1085;&#1072;&#1082;&#1086;&#1084; &#1086;&#1073;&#1089;&#1083;&#1091;&#1078;&#1080;&#1074;&#1072;&#1085;&#1080;&#1103; SYSTRAN Software, Inc. </p*/}
          <p>
            {" "}
            AP/Wide World Photos является торговой маркой агентства Associated
            Press.{" "}
          </p>
          <p>
            {" "}
            PhotoDisc является зарегистрированной торговой маркой PhotoDisc,
            Inc.{" "}
          </p>
          <p>
            {" "}
            Business English Pod, логотипы www.businessenglishpod.com и Business
            English Pod являются торговыми марками компании Business English Pod
            Limited.{" "}
          </p>
          <p> Все прочие торговые марки принадлежат своим владельцам. </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default RuRuContent;
