export const domains = {
  learnship: {
    dynamic: "learnship.today",
    staging: "learnship.tech",
    production: "learnship.com",
  },
};

export const MFA_GE_FLAG = "(1 << 10)";
export const WEB_DOMAIN_URL = "globalenglish.com";
export const APPSYNC_AUTHENTICATION_TYPE = "AMAZON_COGNITO_USER_POOLS";
