import messages from "bundles/common/intl/messages";
import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import ErrorPageImg from "../../../../assets/images/ErrorPageImg.png";
import { domains } from "../../../../constants";
import { errorMessage } from "./errorMsg.js";

const urlParams = new URLSearchParams(window.location.search);
// const isUrlParams = window.location.search === "" ? true : false;
let ssoErrorExists;

export const ErrorPage = () => {
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (urlParams.has("error_type")) {
      const error_type = urlParams.get("error_type");
      setErrorMsg(error_type);
    }
    // Added to display SSO error to end-user
    if(urlParams.has("sso")) {
      const sso = urlParams.get("sso").replace('_', ' ');
      if(sso != '' && sso != null && sso != undefined) {
          setErrorMsg(messages.ssoErrorTitle.defaultMessage);
          ssoErrorExists = (Number(sso) < messages.ssoError.length) ? sso : 0;
      }
    }
  }, [urlParams]);
  return (
    <div className="errorPage">
      <div
        className="errorImgDiv"
        style={{
          float: "left",
          width: "50%",
        }}
      >
        <img className="errorImg" src={ErrorPageImg} />
      </div>
      <div
        style={{
          float: "right",
          background: "white",
          width: "50%",
          height: "84vh",
        }}
      >
        {errorMsg && (
          <div
            className="errorMsg"
            style={{
              fontSize: "22px",
              margin: "35vh 10vh",
            }}
          >
            <div className="errorHeading">
              {" "}
              {errorMsg === "INVALID_USER"
                ? errorMessage[errorMsg].errorHeading
                : errorMsg}
            </div>
            <div
                className="errorDescription"
                style={{
                  fontSize: "16px",
                  margin: "4vh auto",
                }}
              >
                {errorMsg === "INVALID_USER" &&
                  errorMessage[errorMsg].errorDescription}{" "}
                <br />
                { (isNaN(ssoErrorExists)) ? 
                    <div style={{ margin: "5px 0" }}>
                     <span> Here&#39;s a link back to the</span>
                      <a
                        href={`https://login.${
                          domains.learnship[process.env.REACT_APP_ENV]
                        }`}
                      >
                        Login
                      </a>{" "}
                      page.
                    </div>
               : 
                <div style={{ margin: "5px 0" }}>
                 { messages.ssoError[ssoErrorExists].id} : <FormattedMessage {...messages.ssoError[ssoErrorExists]} />
                </div>
              }
              </div>
          </div>
        )}
      </div>
    </div>
  );
};
