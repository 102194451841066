// @ts-nocheck
import React from 'react';

function ZhTwContent() {
  return (
    <div id="agreement_container">
      <div style={{ padding: '1% 5% 0 5%' }}>
        <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
          <b>使用者協議</b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
          <b>簡介 </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          歡迎使用 GlobalEnglish 服務，無論是使用網站還是行動應用程式（統稱為「服務」）。
          <br />
          <br />{' '}
          我們的服務為英語學習工具以及線上解決方案，包括但不限於資料、文本、線上測試、設計、視訊、音訊片段、錄音、撰寫部落格和評論、腳本、圖形、圖片及其選取和排列、軟體、教程、學習班、指導、培訓、透過網站和行動應用程式生成或可以存取的交流和互動功能（統稱為「內容」）。造訪網站閱讀有關我司的資訊，詢問服務均為該服務的一部分。
          <br />
          <br /> 請仔細閱讀下列內容，在繼續之前確認您了解並同意該條款。
          <br />
          <br />
          <b>
            如果您未滿 18
            週歲，請務必與您的家長或監護人一同閱讀此協議，並在使用我們的服務之前獲得他們的同意。您使用我們的服務，則表示您已年滿
            18 週歲或已征得家長或監護人的同意，可與 GLOBALENGLISH 簽訂此合約。{' '}
          </b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
          <b>一般條款 </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 1. 法律約束的協議</b>
          <br />
          <br />
          <b>
            {' '}
            您造訪我們網站以及使用 GLOBALENGLISH 的服務時，均受到下方使用者協議、隱私政策和 COOKIE
            政策（三份檔案統稱為「使用條款」）的約束。您讀取、造訪、註冊和/或使用 GLOBALENGLISH
            的服務則代表，您已閱讀並理解我們的服務的使用條款，同意受此服務條款不可撤銷的法律約束，並已達到合法年齡，同意受此服務條款的約束，與
            GLOBALENGLISH 簽訂受到法律約束的合約。{' '}
          </b>
          <br />
          <br /> 您可
          <a
            href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
            rel="noopener noreferrer"
            target="_blank"
          >
            在這裡
          </a>
          獲取我們的政策概覽。這些政策透過引用結合至此「使用者協議」或「協議」中，共同構成使用條款。
          <br />
          <br />
          <b>
            請注意，此協議包含您我雙方解決相互之間發生索賠方式的規定。具體參見以下免責聲明、歸責原則以及法律糾紛規定。本協議還包括一項仲裁協議，除少數例外情況外，要求您對我方的索賠提交具有約束力的最終仲裁。本協議規定您只能以個人名義提出主張，而不能以集體訴訟或代表訴訟中的原告或成員提出。您只能以個人名義提出救濟（包括金錢救濟、禁令救濟以及宣告式救濟）。
            <br />
            <br /> 如何您在任何時候不同意此協議下的任何條款，請勿使用此服務或進一步瀏覽我們網站。
          </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. 適用範圍 </b>
          <br />
          <br /> 此協議適用於我們的服務使用者及網站訪客。直接我們註冊的個人（「
          <b>B2C 交易</b>」），即使是基於試用，以及由或透過用人單位（「
          <b>B2B 交易</b>」）進行註冊的個人將被稱為「
          <b>使用者</b>」，而未註冊使用者和瀏覽我們網站的訪客被稱為「
          <b>訪客</b>」。術語「
          <b>使用者</b>」還包括教師、教練、學習專家以及造訪 GlobalEnglish
          網站為其他使用者提供服務的任何其他人。使用者和訪客均稱為「
          <b>您/貴方</b>」。
          <br />
          <br /> 此協議適用於所有 GlobalEnglish 的服務。
          <br />
          <br />{' '}
          您首次註冊為使用者及存取我們的服務時，我們可能會要求您透過點擊方框「我同意」或提供您的電子簽名或任何其他授權方法，重申您同意受到此協議內的條款約束。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>3.使用條款的變更 </b>
          <br />
          <br />
          GlobalEnglish 可能隨時會修改使用條款，包括適用於您的服務使用的此協議、Cookie
          政策和隱私政策。此項修改應在通知後立即生效，通知應透過在本服務中發佈，向在相關服務帳戶（如適用）或其他方式中提供的電子郵寄地址發送電子郵件通知。如果您透過用人單位或公司註冊了我們的服務，用人單位或公司應向您通知使用條款的變更。
          <br />
          <br />{' '}
          如果您反對變更，則可以關閉帳戶並停止造訪我們的網站。然而，在我們發佈或發送有關條款變更通知後，您繼續使用我們的服務，則被視為您接受使用條款的修改。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4.協力廠商使用條款 </b>
          <br />
          <br />
          服務可能包括其他網站的連結（「協力廠商網站」）以及文章、照片、文字、翻譯、圖形、圖片、設計、聲音、視訊、資訊，以及其他屬於或源於協力廠商的內容或專案（「協力廠商內容」）。我們不會對此類協力廠商網站和協力廠商內容的準確性、適當性或完整性進行調查、監控或檢查，對於透過本網站存取的任何協力廠商網站或透過本網站發佈或提供的任何協力廠商內容，我們概不負責。
          <br />
          <br /> 包含、連結至或允許使用任何協力廠商網站或任何協力廠商內容不表示 GlobalEnglish
          認可或支援這些網站或內容。GlobalEnglish
          保留隨時關閉這些連結而不另行通知的權利。服務中對任何公司、組織、產品、服務或其他資訊的引用，並不構成或暗示
          GlobalEnglish 對這些內容的認可、贊助或推薦，或與之有任何關聯。
          <br />
          <br />{' '}
          如果您決定離開我們網站，造訪協力廠商網站，或存取或使用任何協力廠商內容，您將自行承擔風險，並且您應了解我們的條款和政策不再適用於您使用此類協力廠商網站。在使用之前，請務必查看適用於協力廠商此類產品和服務的條款和政策。
          <br />
          <br />
          我們透過 Android Market 和 iTunes
          Store（和維護這些平台的實體統稱為「供應商」）提供行動應用程式。您使用這些移動應用程式也受到供應商條款與條件的約束，包括但不限於
          App Store 服務條款中規定的使用規則限制。如果您已從 iTunes Store
          下載任何此類移動應用程式，則表示您已確認並同意您已閱讀並同意 App Store 服務條款。如果您已從 Android Market
          下載此類移動應用程式，則表示您已確認並同意您已閱讀並同意 Android Market
          服務條款。供應商沒有義務也不會向您提供與您使用服務相關的任何維護或支援服務。供應商不會對服務提供任何形式的保修條款。如果任何行動應用程式未能符合任何適用的保修條款，而您已從
          iTunes Store 下載此類應用程式，您可以通知 Apple，Apple
          將退還此類應用程式的購買價格（如有）。這些條款適用於您使用所有行動應用程式及我們的其他服務。
          <br />
          <br /> 您承認並同意，GlobalEnglish
          對這些協力廠商網站或應用程式的內容、產品、服務或性能概不負責。您不可撤銷地放棄針對 GlobalEnglish
          與協力廠商網站或應用程式相關的任何索賠。特別是，GLOBALENGLISH 對於您或其他方以任何方式放置在您電腦中的
          COOKIE、網站信標、透明影像圖檔、或其他代碼不承擔任何責任，包括網站內容與此類代碼的任何關聯。例如，如果您透過網站上的連結造訪協力廠商網站，您的瀏覽器或電腦上的其他
          cookie 可能會導致向網站發送網址標頭，表明您的一些瀏覽歷史記錄或您造訪的網址。GlobalEnglish
          對任何此類傳輸概不負責，無論該傳輸是否包含與 GlobalEnglish 相關或源自 GlobalEnglish 的資訊。
          <br />
          <br /> 如果您使用 GOOGLE 提供的翻譯：GOOGLE
          不承擔與翻譯有關的所有明示或暗示的擔保，包括對準確性、可靠性、適銷性及針對特定用途的適用性和非侵權的任何暗示擔保。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>5. 服務資格標準 </b>
          <br />
          <br />
          <b>5.1 保護兒童隱私及使用我們服務的限制。 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            本服務不適用於存取本服務所在國家/地區的最低年齡的任何人。例如，除非特定國家/地區法律另有規定，歐盟，歐洲經濟區和瑞士的「最低年齡」均為
            16 週歲。在美國，最低年齡則為 13
            週歲。禁止未滿最低年齡的任何人註冊、使用或存取本服務，並構成違反這些條款的行為。
            <br />
            <br /> 使用服務，則表明並保證您已年滿 13 週歲或以上。
          </span>
          <br />
          <br />
          <b>5.2 學校和教師</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            希望 13 週歲以下的學生創建 GlobalEnglish
            帳戶的美國學校、校區或教師同意他們有責任遵守美國兒童線上隱私保護法（「COPPA」），並在適用範圍內遵守《家庭教育權和隱私法》（「FERPA」）。這意味著學校、校區或教師必須將
            GlobalEnglish
            要收集的個人身分資訊通知學生的家長或監護人，並在學生創建帳戶和使用本服務之前，獲得家長/監護人的同意。獲得同意後，學校、校區和教師必須向家長/監護人提供我們的隱私政策副本，將所有同意書存檔，並在我們需要時提供給我們。有關遵守COPPA
            的更多資訊，請參見
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
              rel="noopener noreferrer"
              target="_blank"
            >
              聯邦貿易委員會的網站
            </a>
            。
            <br />
            <br />{' '}
            如果您在美國境外，我們將依靠您獲得類似法律所涵蓋的任何學生的家長/監護人所需的同意或批准，作為您和您的學生使用我們服務的條件，您同意有責任遵守此類法律。
          </span>
          <br />
          <br />
          <b>5.3 家長同意書 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            <b>
              您使用我們的服務，則表示您已年滿 18 週歲或已征得家長或監護人的同意，可與 GLOBALENGLISH 簽訂此合約。
              <br />
              <br /> 我們希望兒童使用本服務只能在其家長、監護人和/或授權學校官員的指導、監督和同意下進行。GlobalEnglish
              依靠家長、監護人和學校來確保未成年人只有在了解了本使用條款中規定的權利和責任的情況下才可使用本服務。
              <br />
              <br /> GlobalEnglish 可自行決定拒絕向任何個人或實體提供本服務並可隨時變更其資格標準。
            </b>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6.隱私 </b>
          <br />
          <br />
          <b>6.1 隱私聲明 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            請查看
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              此處
            </a>
            提供的隱私政策。
            <br />
            <br />
            我們將根據您在首次存取服務時同意的隱私政策，或作為隱私政策定期更新的一部分來處理您的個人資料。
          </span>
          <br />
          <br />
          <b>6.2 您提供的資料。 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            要使用本服務，您必須擁有一個有效的帳戶。要註冊我們的服務，您必須向 GlobalEnglish 提供使用者
            ID、電子郵寄地址和其他被視為個人身分資訊（「個人資料」）的資訊。我們向您收集的一些資訊是 GlobalEnglish
            提供本服務所必需的資訊，或甚至僅為了向您發送您一直查詢的本服務資訊，其他資訊則為可選資訊。如適用，本服務還允許您顯示有關您自己和組織的資訊。
            <br />
            <br /> 您同意：(i) 按照本服務內註冊表格的提示，在B2B
            交易情況下，提供有關您和組織的準確、最新和完整的資訊；(ii) 對您的密碼及與帳戶安全相關的其他資訊保密；(iii)
            維護並及時更新您向 GlobalEnglish 提供的個人資料和任何其他資訊，確保此類資訊的準確、最新和完整；並 (iv)
            對您帳戶的所有使用和透過您的帳戶執行的任何操作承擔全部責任。
            <br />
            <br /> GlobalEnglish 不負責驗證您為註冊提供的個人資料的準確性。
          </span>
          <br />
          <br />
          <b>6.3 個人資料的國際傳輸 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            為了提供本服務，GlobalEnglish
            依靠其遍佈全球的工作人口，利用協力廠商供應商和託管合作夥伴，提供存儲和其他技術相關的教學服務。因此，您的個人資料將經過處理並傳輸至在不同國家/地區的
            GlobalEnglish 員工和分包商，其中有些國家/地區可能無法提供在您的國籍或居住國的個人資料同等水平的法律保護。
            <b>
              {' '}
              通過存取、註冊和/或使用我們的服務，您同意將您的個人資料傳輸至
              GlobalEnglish、其附屬公司和分包商所在的各個國家/地區並進行處理。{' '}
            </b>
          </span>
          <br />
          <br />
          <b>6.4 通知和消息。</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            您了解並同意服務可能包含 GlobalEnglish
            的某些通信，如服務公告、管理消息等，並且這些通信將視為本服務的一部分，您無法選擇不接收這些通信。
            <br />
            <br /> 據此，您同意 GlobalEnglish 可透過以下方式向您發送通知和消息：(i) 本服務內發送；(ii) 透過您向
            GlobalEnglish 提供的聯絡方式（如電子郵件或手機號碼）發送。您同意隨時更新您的聯絡方式。
          </span>
          <br />
          <br />
          <b>6.5 他人隱私 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            如果您在使用我們服務過程中獲得了其他使用者的相關資訊，您同意僅將此資訊用於提供給您的目的。出於與本服務無關的目的，您不得向協力廠商披露、出售或分發使用者資訊。除非獲得特定使用者的同意，否則您不得將該資訊用於行銷目的。
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. 使用者許可 </b>
          <br />
          <br />
          <b>7.1 使用我們服務的許可 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            您被授予有限、個人、非獨占、不可轉讓、非可再授權且不可轉讓的許可，僅用於非商業性個人用途的存取和使用本服務。GlobalEnglish
            可自行決定暫時或永久終止本服務或隨時變更本服務的功能，恕不另行通知。
            <br />
            <br />{' '}
            要使用本服務，您必須直接或透過存取基於網站內容的裝置造訪萬維網。此外，您必須擁有連接至萬維網所需的所有裝置，包括電腦和數據機或其他造訪裝置。您同意您必須評估並承擔與使用服務相關的所有風險，包括對其所提供內容的準確性、完整性或有用性的任何依賴。GlobalEnglish
            及其許可方均不保證任何內容的準確性或完整性。
            <br />
            <br />
            本服務的設計或許可不適用於危險環境（例如駕駛時、在水中或水周圍）。
            在禁止使用本服務的情況下，或在提供、銷售本服務或提供與任何適用法律、法規或規章相衝突的情況下，將撤銷對本服務的存取權。
            <br />
            <br />
            將本服務用於「使用條款」以外的任何目的，均違反了「使用條款」以及 GlobalEnglish
            和/或許可方及分包商的版權和所有權。如果 GlobalEnglish 有合理理由懷疑您違反這些「使用條款」使用本服務，則
            GlobalEnglish 有權暫停或終止您的帳戶，並拒絕為您提供任何及所有當前或未來的服務。您同意，GlobalEnglish
            對於本服務或服務任何部分的任何修改、暫停或中止概不負責。
          </span>
          <br />
          <br />
          <b>7.2 使用者內容</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            本服務為您和其他使用者提供了建立資料和資訊的機會（例如，透過提供對我們測試的回應）以及上載基於社區的服務的材料（「使用者內容」）。根據這些條款授予我們的權利，您保留對使用者內容的完全所有權。
            <br />
            <br /> 透過上載任何使用者內容至我們的服務，您授予 GlobalEnglish
            不可撤銷、永久、非獨占、可轉讓、完全支付、全球許可（帶轉授權），如有需要，有關服務提供，可存儲、使用、複製、公開展示、公開顯示、修改、翻譯、摘錄（全部或部分）、處理和散佈此類使用者內容：
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) 遵守法律程序，包括但不限於回應法律要求、司法命令和其他類似的政府要求；
              <br />
              (b) 執行本協議；
              <br />
              (c) 回應聲稱任何內容侵犯了協力廠商的權利；
              <br />
              (d)保護 GlobalEnglish、其員工、使用者和公眾的權利、財產和人身安全。
            </span>
            <br />
            <br />{' '}
            如果您選擇與本服務的其他使用者共用任何使用者內容，則同意允許這些使用者查看您的使用者內容，並在適用範圍內與您就此類使用者內容進行協作。
            <br />
            <br /> 請注意，GlobalEnglish
            沒有義務隨時對此類使用者內容進行篩選、審核、驗證準確性、更正或刪除。然而，GlobalEnglish
            保留對使用者內容的監控權，並在絕對酌情權內，刪除其認為具有攻擊性或違反使用條款的內容。
            <br />
            <br /> GlobalEnglish
            沒有義務對使用者內容進行備份或存儲。您需自行承擔創建備份副本並替換您在網站上發佈或存儲的或向 GlobalEnglish
            提供的任何使用者內容的費用。
            <br />
            <br /> 此使用者內容不一定反映 GlobalEnglish
            的觀點。您了解使用者內容將由創建此類使用者內容的使用者單獨負責。您了解透過使用本服務，可能會接觸到攻擊性、不雅或令人反感的使用者內容。
            <br />
            <br /> 除了對上述使用者內容授予許可外，您承認並同意，您向 GlobalEnglish
            提供的有關本服務的任何問題、評論、建議、想法、回饋或其他資訊（「提交內容」）均為非機密資訊，GlobalEnglish
            有權以任何目的（商業或其他方式）無限制地使用並傳播這些提交內容，無需經過您的同意或對您進行補償。此外，您在
            GlobalEnglish
            基於社區的服務或產品上或通過該等服務或產品發佈、披露或共用的資訊不屬於機密資訊的定義，因該術語通常已定義了。
            <br />
            <br /> 您了解網站的技術處理和傳輸，包括您的使用者內容，可能涉及 (a) 透過各種網路傳輸；以及 (b)
            以符合並適應連接網路或裝置的技術要求的變更。
            <br />
            <br />
            <b>
              GlobalEnglish 不對使用者內容作出任何聲明或保證。此使用者內容不一定反映 GlobalEnglish
              的觀點。在任何情況下，GlobalEnglish
              均不對使用者內容或因任何原因或因使用或丟失而導致的任何索賠，損壞或損失承擔任何責任或義務。
            </b>
          </span>
          <br />
          <br />
          <b>7.3 必要的使用者行為</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            對您帳戶下發生的所有行為負全部責任。您同意：
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) 任何未經授權使用您的帳戶或任何其他違反安全規定的行為，請立即通知 GlobalEnglish，並
              <br />
              (b) 確保在每次會話結束時退出帳戶。
            </span>
            <br />
            <br />
            GlobalEnglish 不能也不會對因您未能遵守而造成的任何損失或損壞負責
            <br />
            <br />
            您全權負責確保這些服務條款符合適用於您的所有法律、法規，且在提供、銷售本服務或提供與任何適用法律、法規或規章相衝突的情況下，將撤銷對本服務的存取權或禁止使用本服務。
          </span>
          <br />
          <br />
          <b>7.4 禁止的使用者行為 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            以下是 GlobalEnglish 非法或禁止使用的一些示例。在 GlobalEnglish 的絕對酌情權內，GlobalEnglish
            保留對任何人進行調查並採取適當法律措施的權利，包括但不限於從網站或應用程式中刪除不當內容，暫停或終止此類使用者的帳戶，並向執法當局檢舉。
            <br />
            <br /> 您不可以：
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) 除非經 GlobalEnglish 特別授權，不得對本服務中任何 GlobalEnglish
              的內容或其他材料進行改編、下載、修訂、廣播、逆向工程、複製、發佈、修改、散播、顯示、傳輸或以其他方式複製和/或分發。
              <br />
              (b) 建造或利用框架技術來封閉本服務的任何部分；
              <br />
              (c)
              透過使用任何機器人程式、爬蟲程式、網路爬蟲、間諜軟體、引擎、裝置、軟體或任何其他自動裝置、實用程式或任何類型的手動流程，對服務的任何部分進行收集、獲取、使用、存取或以其他方式進行複製；
              <br />
              (d) 以任何方式使用本服務或本服務中的任何可用功能，以中斷、損壞、禁用、覆蓋或損害本服務；
              <br />
              (e) 出售、許可、出租或以其他方式將本服務用於商業目的；或
              <br />
              (f) 參與任何干擾其他使用者存取、使用或享用本服務的活動。
              <br />
              (g) 發佈、以電子郵件發送或以其他方式傳輸以下任何使用者內容：(i)
              非法、有害、威脅性、辱駡、騷擾、侵權、過度暴力、誹謗、低俗、淫穢、不雅、色情、損害他人名譽、侵犯他人私隱（包括但不限於任何地址、電子郵箱、手機號碼以及其他聯絡資訊）、具種族仇恨、民族仇恨或其他令人不快的使用者內容；(ii)
              根據任何法律或根據契約關係或信託關係，您無權傳輸的使用者內容；(iii)
              對任何人構成或造成隱私或安全風險的使用者內容；(iv)
              侵犯任何一方的任何智慧財產權或其他專有權利的使用者內容；(v)
              構成來路不明或未經授權的廣告、宣傳資料、商業活動和/或銷售、「垃圾郵件」、「垃圾資訊」、「連環信」、「非法傳銷」、「競賽」、「彩票抽獎」或任何其他形式的業務招攬的使用者內容；(vi)
              包含旨在中斷、毀壞或限制任何電腦軟體、硬體或電信裝置功能的軟體病毒或任何其他電腦代碼、檔案或程式的使用者內容；或
              (vii) 根據 GlobalEnglish 自身判斷，會引起異議或限制或阻礙任何其他人使用或享用服務，或可能讓 GlobalEnglish
              或其使用者承擔任何損害或任何類型的責任的使用者內容；
              <br />
              (h) 製造虛假證明、歪曲您的身分、冒充任何人或實體，或虛假陳述或以其他方式歪曲您與他人或實體的關係；
              <br />
              (i) 向未滿 18 週歲的任何人索取個人資訊；
              <br />
              (j) 以任何方式傷害未成年人；
              <br />
              (k) 對任何團體或個人造成人身傷害或傷亡，「跟蹤」或以其他方式騷擾任何人；
              <br />
              (l) 提供或宣傳有關非法活動的教學資訊，例如，關於如何裝配炸彈、手榴彈以及其他武器或引爆裝置的說明；
              <br />
              (m)
              透過使用自動腳本或以其他方式，用於任何目的從網站收穫或收集網站內容或其他使用者的電子郵寄地址、聯絡資訊或其他使用者的私人資訊，包括但不限於向使用者發送來路不明的電子郵件或者進行其他來路不明的溝通，或複製內容網站；
              <br />
              (n) 為了任何未特定授權的商業目的，進行宣傳或提供出售或購買任何商品或服務；
              <br />
              (o) 干擾或破壞網站或連接至網站的伺服器或網路，或違反任何連接至網站的網路要求、程式、政策或法規；
              <br />
              (p)
              違反任何適用的地區、州、國家或國際法，或任何具有法律效力的法規；促進或者推動任何犯罪活動或企業，或提供違法活動相關的指導性資訊；
              <br /> (q) 透過本網站無意使用或提供的方式，獲取或試圖存取或以其他方式獲取任何材料或資訊；
              <br />
              (r) 覆蓋安全功能或略過或規避任何存取控制；或
              <br />
              (s) 以可能造成利益衝突或破壞本服務目的的方式使用本服務，例如與其他使用者進行交易評論或撰寫或徵求尖刻評論。
            </span>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. 智慧財產權 </b>
          <br />
          <br />
          <b>8.1 GlobalEnglish 的智慧財產權 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            本服務的全部內容，包括其內容和任何支援軟體，均為
            GlobalEnglish、其許可方或分包商的專有財產，受美國和國際版權及其他智慧財產權法的保護。
            <br />
            <br /> 未經 GlobalEnglish
            和/或其許可方的明確書面同意，除下文規定以外，嚴禁複製、再分發、修改或發佈本服務的任何部分。
            <br />
            <br />
            授權使用者可以存取及使用本服務和內容，下載或列印使用者已正確獲取存取權限的內容部分的合理數量副本，僅供使用者個人用於語言培訓目的，前提是使用者對此類內容的所有副本保留所有版權或其他所有權聲明。
            <br />
            <br /> 除非另有說明，本服務中出現的商標均為
            GlobalEnglish、其許可方或其附屬公司的商標。您同意不展示、貶低或玷污本商標，也不使用任何容易混淆的類似商標，或以歪曲此類商標所有權的方式使用這些商標，或造成
            GlobalEnglish 認可任何產品或服務的印象。未經 GlobalEnglish 事先書面許可，您不得複製或使用 GlobalEnglish
            的商標、商業名稱和/或商業外觀。使用 GlobalEnglish 商標而產生的商譽均屬於我們的獨家收益。
            <br />
            <br />{' '}
            禁止使用協議特定授權以外的網站、應用程式或內容，將自動終止所授予您的使用本服務與本內容的權利。GlobalEnglish
            及其許可方保留未在本條款中未明確授予的其所有權利。
          </span>
          <br />
          <br />
          <b>8.2 協力廠商商標 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            透過網站使用和展示的產品和服務的名稱和商標，除上述 GlobalEnglish
            商標外，可以是各自所有者的商標或服務商標，其所有者可能認可或不認可 GlobalEnglish，也可能與 GlobalEnglish
            有關聯或連接。未經我們事先書面許可，本條款或網站上的任何內容均不得解釋為以暗示、禁止反悔或其他方式授予使用本網站顯示的任何商標的任何許可或權利。使用
            GlobalEnglish 商標而產生的商譽均屬於我們的獨家收益。
          </span>
          <br />
          <br />
          <b>8.3 根據數位千年版權法提出的版權控訴 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            如果您對其他人在本服務上發佈的任何資料有任何版權問題，請告知我們。
            <br />
            <br /> GlobalEnglish
            將處理並調查涉嫌侵權的通知，並根據「數位千年版權法」（「DMCA」）和其他適用智慧財產權法，對任何涉嫌或實際侵權行為採取適當措施。
            <br />
            <br /> 聲明版權侵權的通知應透過電子郵件發送至 GlobalEnglish 的版權代理機構
            copyright@globalenglish.com（主題行：「DMCA 撤除請求」）。
            <br />
            <br /> 您也可以通過電子郵件聯絡我們：
            <br />
            <b>聯絡人：版權控訴/法律部 </b>
            <br /> 1875 South Grant Street, Suite 700
            <br /> San Mateo, CA 94402 USA
            <br />
            <br />
            <br /> 僅書面通知有效，並應包含以下資訊：(i)
            獲授權代表版權所有人或其他智慧財產權利益相關人的電子或親筆簽名；(ii)
            您聲稱遭到侵權的受版權保護作品或其他智慧財產權的描述；(iii)
            描述您聲稱侵權的材料在本服務中的位置，並在我們網站中可找到該材料的詳細資料；(iv) GlobalEnglish
            可聯絡到您的充分資訊（地址、電話號碼和電子郵寄地址）；(v)
            您聲明確信所爭議的使用未經版權或智慧財產權所有人、其代理或法律授權；(vi)
            您以版權或智慧財產權所有人或授權代表版權或智慧財產權所有人的名義聲明，上述通知資訊正確真實，否則將承擔作偽證的責任。
            <br />
            <br /> 根據 DMCA 和其他適用法律，在適當情況下 GlobalEnglish
            自行決定採取政策終止被視為屢次侵權者的使用者。GlobalEnglish
            還可自行決定限制存取本網站和/或終止任何侵犯他人智慧財產權的使用者的會員資格，無論是否存在任何重複侵權。
          </span>
          <br />
          <br />
          <b>8.4 刪除涉嫌侵權的使用者內容</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            如果 GlobalEnglish
            刪除了一些涉嫌侵犯協力廠商智慧財產權的使用者內容，並且您認為刪除的使用者內容（或禁用的存取權限）未侵權，或您獲得版權所有人、版權所有人代理的授權，或根據法律不構成侵權，要發佈和使用您的使用者內容中的內容，您可以向版權代理機構發出包含以下資料的書面異議通知：(i)您的電子或親筆簽名；(ii)
            識別已刪除或已禁用存取的內容，以及在刪除或禁用之前顯示內容的位置；(iii)
            聲明您確信該內容因錯誤或錯誤識別內容而被刪除或禁用；以及 (iv)
            您的姓名、地址、電話號碼和電子郵寄地址，您同意加利福尼亞州北部地方聯邦法院管轄權的聲明和聲明您接受提供涉嫌侵權通知方的傳票送達。
            <br />
            <br /> 如果版權代理機構收到異議通知，GlobalEnglish 將向原控訴方發送異議通知副本，通知該人可在 10
            個工作日內替換刪除的內容或停止禁用該內容。除非版權所有人提出針對內容提供者，會員或使用者尋求法院命令的訴訟，否則在收到異議通知
            10 至 14 個工作日或以上的時間內，將替換已刪除的內容，或恢復對該內容的訪問，由我們自行決定。{' '}
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 9. 國際使用和出口管制</b>
          <br />
          <br />{' '}
          認識到互聯網的全球性，您同意遵守有關線上行為和可接受的使用者內容的所有當地法規。您同意遵守有關從美國或您所在國家/地區出口的技術資料傳輸的所有適用法律。
          <br />
          <br />{' '}
          您承認本服務或部分服務可能受美國出口管制法的約束。您不得違反任何適用的出口法律或法規，直接或間接地出口、再出口或轉讓本服務的任何或任何相關技術資訊或材料。您聲明並保證您不是受美國政府禁運限制的國家或被美國政府指定為「支持恐怖主義」的國家的居民，也並未列載於美國政府任何禁止或受限人名單中。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. 禁令救濟 </b>
          <br />
          <br /> 您承認任何違反「使用條款」使用本服務均可能對
          GlobalEnglish、其附屬公司、許可方、分包商、代理商和使用者造成難以彌補的損害。在這種情況下，GlobalEnglish
          及其附屬公司、許可方、分包商、代理商和使用者將有權獲得公平的救濟，無需繳納保金或其他擔保，包括但不限於初步和永久禁令救濟。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11. 服務停止時間 </b>
          <br />
          <br /> 出於維護或其他原因，服務可能會不時暫時不可用。GlobalEnglish
          對使用者通信或透過網站獲取的任何其他內容的任何中斷，延遲操作或傳輸，盜竊或破壞，未經授權的存取或變更不承擔任何責任。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12. 免責條款 </b>
          <br />
          <br /> 出於維護或其他原因，服務可能會不時暫時不可用。GlobalEnglish
          對使用者通信或透過本服務獲取的任何其他內容的任何中斷，延遲操作或傳輸，盜竊或破壞，未經授權的存取或變更不承擔任何責任。在任何情況下，GlobalEnglish
          均不對因使用本服務、任何使用者內容或協力廠商內容、或使用使用者提供的任何產品或服務而造成的任何人身傷害或死亡承擔任何責任。
          <br />
          <br /> 您了解並同意：
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            {' '}
            (a) 本服務，包括本服務中的所有內容，均按「現狀」提供並可用。
            <br />
            <br /> (b) GLOBALENGLISH
            拒絕就服務作出任何及所有明示或暗示的聲明和保證，包括對所有權，適銷性，特定用途的適用性或非侵權性的暗示保證。
            <br />
            <br /> (c) GLOBALENGLISH 既不保證也不承諾使用本服務的任何具體結果及本服務將滿足您的要求或期望。
            <br />
            <br />
            (d) GLOBALENGLISH
            不聲明或保證本服務或網站上的軟體、內容或材料準確、完整、可靠、最新或無誤，或者本網站或本服務或其伺服器，沒有病毒或其他有害組件。因此，在不限制上述規定的情況下，您了解並同意，您經或透過服務或經基於您自己判斷並自承風險的提供方，下載或以其他方式獲取內容、材料、資料或軟體，對於您的使用以及可能對您的電腦系統造成的任何損壞、資料丟失或其他類型的危害，您將承擔全部責任。
            <br />
            <br /> (e) GLOBALENGLISH 不保證服務中的任何錯誤或缺陷都會得到糾正。
            <br />
            <br />
            (f) 對於使用者、協力廠商內容和使用者內容的行為， GLOBALENGLISH 概不負責。
            <br />
            <br />
            (g) 出於維護或其他原因，服務可能會不時暫時不可用。GlobalEnglish
            對使用者通信或透過本服務獲取的任何其他內容的任何中斷，延遲操作或傳輸，盜竊或破壞，未經授權的存取或變更不承擔任何責任。在任何情況下，GlobalEnglish
            均不對因使用本服務、任何使用者內容或協力廠商內容、或使用使用者提供的任何產品或服務而造成的任何人身傷害或死亡承擔任何責任。
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. 責任限制</b>
          <br />
          <br />{' '}
          在任何情況下，對於您或任何協力廠商因任何間接、後果性、懲戒性、偶然性、特殊性或懲罰性的損失，GLOBALENGLISH
          或其董事、員工、代理、許可方或分包商均概不負責，包括因以下原因導致的任何利潤損失或丟失資料：(I)
          您使用或無法使用本服務，或透過造訪網站、服務或提供方或從中下載的任何內容或其他材料；(II)
          透過或經網站，購買或獲取的任何商品、資料、資訊或服務，收到的資訊，進行的交易，而產生的替換商品或服務的購買成本；(III)
          任何一方未經授權存取或變更您的傳輸或資料；(IV) 網站上任何協力廠商的聲明或行為；或 (V)
          與本服務相關的任何其他事項。在任何情況下，對於所有損害、虧損或訴因，GLOBALENGLISH
          對您的總負債不能超過您在過去十二 (12) 個月中支付給 GLOBALENGLISH
          的數額，否則，如果超出這個數額，也不得超過一百美元 ($100)，儘管 GLOBALENGLISH
          了解到或已被告知此類損失的可能。此協議下存在超過一項索賠時，不會增加此限額。
          <br />
          <br />{' '}
          某些司法管轄區不允許排除某些保證或限制，或排除偶然性或結果性損失的賠償責任。因此，上述某些限制可能不適用於您。
          <br />
          <br /> 如果您對本網站的任何部分或這些網站條款不滿意，您唯一可用的補救措施即是停止使用本網站。
          <br />
          <br /> 在任何情況下，對於因使用服務、協力廠商內容或使用者內容而造成的人身傷害或死亡，GLOBALENGLISH 概不負責。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14. 訴訟時效 </b>
          <br />
          <br />
          您同意，無論任何訴訟或法律是否有其他規定，因您使用服務而產生的或與您使用服務相關的針對 GlobalEnglish
          提出的任何索賠或訴訟，須在該等索賠或訴訟發生後一 (1) 年內提出申請，否則將永久禁止申請。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>15. 補償 </b>
          <br />
          <br />
          您同意，因您使用本網站、服務、您的使用者內容、您連接本網站或服務、您違反本條款或您侵犯其他使用者的任何權利或違反任何法律，或因您使用本服務而產生的其他不當行為導致任何協力廠商對
          GlobalEnglish 發起的任何及所有索賠、訴訟、費用、債務、損失、損害賠償、費用，包括合理的律師費用，您應免除
          GlobalEnglish 及其附屬公司和人員、員工、主管和代理之責任，為其補償並使其免受損害。您的補償義務包括但不限於對
          GlobalEnglish 提出的任何使用者內容侵犯協力廠商智慧財產權的索賠。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>16. 終止使用 </b>
          <br />
          <br /> 如果您的訂閱由於任何原因而終止，GlobalEnglish
          將禁用您對本服務的存取。您將無法存取任何內容，包括使用者內容，但此類資料副本將在我們的系統中保留三 (3)
          年作為備份。
          <br />
          <br /> 如果您要求 GlobalEnglish 刪除您的個人資料和使用者內容，我方將盡一切合理努力刪除，並確認刪除時間。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>17. 棄權和可分割性 </b>
          <br />
          <br /> 如果 GlobalEnglish 未能行使或執行本條款的任何權利或規定，不應構成在該情況下及任何其他情況下
          GlobalEnglish
          對此類權利或規定的放棄。如果經確定本條款的任何規定非法、無效或因任何原因無法執行，該等確定不影響任何其他規定的有效性和可執行性。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>18. 完整協議</b>
          <br />
          <br /> 本協議構成您與 GlobalEnglish 之間關於使用服務的完整協議，並取代先前您與 GlobalEnglish
          之間就使用服務達成的任何理解和協議。
          <br />
          <br /> 儘管有前述規定，如果貴公司已與 GlobalEnglish 就購買任何服務達成現有協議的，本條款不會改變貴公司與
          GlobalEnglish、其子公司或附屬公司已達成的此類協議的條款或條件，若條款發生衝突，以該協議的條款為准。
          <br />
          <br />{' '}
          本協議採用英語編寫，該版本在所有方面均占控制主導地位，本協議的任何非英語版本僅用於調解目的。本協議的印刷版本及任何以電子形式發出的通知印刷版本，可用於基於本協議或與本協議有關的司法或行政程式，且須符合與其他業務檔案及記錄相同的條件，而該等檔案及紀錄最初以列印形式生成並維護。本條款中的章節標題僅為方便而設，不具有法律或合同效力。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>19. 法律、法庭和仲裁選擇 </b>
          <br />
          <br />
          本條款應受加利福尼亞州法律管轄，不考慮其法律條款的衝突。
          <br />
          <br /> 根據 GlobalEnglish
          的選擇及自行決定，所有因本條款或網站引起的或與本條款或網站相關的糾紛、索賠或爭議如未能透過雙方協議解決的，可在
          JAMS
          或其繼任者面前，透過有約束力的仲裁來解決。除非各方當事人另行商定，仲裁將於雙方共同商定的一名仲裁員面前，在加利福尼亞州三藩市進行，或如果雙方當事人不同意由
          JAMS 指定一名仲裁員，則按照 JAMS
          頒佈的法規法規進行，除非專門修改本條款。仲裁必須于任何一方提出書面仲裁申請之日起四十五 (45)
          天內開始。仲裁員應在仲裁結束後六十 (60) 天內以及選定仲裁員後六 (6)
          個月內作出裁決和判定並傳達。仲裁員沒有權力根據條款中規定的實際補償、間接損害判定超過限制的損失，不得增加實際損失或判定懲罰性損失，或任何條款明確排除的其他損失，各方特此永久放棄任何對此類損失的索賠。仲裁員可自行決定對任一訴訟方的費用和開支（包括勝訴方的合理法律費用和開支）進行評估。任何一方拒絕遵守仲裁員的命令，將承擔另一方在執行裁決過程中產生的費用和開支，包括律師費。儘管有前述規定，在臨時或初步禁令救濟的情況下，未經事先仲裁，任何一方不得在法庭上進行訴訟，避免直接且不能挽回的損害。本仲裁章節的規定在任何擁有有效管轄權的法院均可強制執行。
          <br />
          <br /> 對於上文所述無需仲裁的任何爭議或索賠，您和 GlobalEnglish
          同意遵守加利福尼亞州三藩市郡的州法院和聯邦法院的個人及專屬管轄權。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. 通知和聯絡方式 </b>
          <br />
          <br />
          您可透過連結
          <a href="https://support.globalenglish.com/" rel="noopener noreferrer" target="_blank">
            https://support.globalenglish.com/
          </a>{' '}
          中的「聯絡我們」表格向 GlobalEnglish 發送終止通知，要求終止您的 GlobalEnglish
          服務帳戶。您的帳戶終止後，GlobalEnglish 可隨時阻止您存取和使用服務，包括其中的任何內容。
          <br />
          <br />{' '}
          我們將透過電子郵件或普通郵件通知您。本網站亦可透過在本服務上顯示的一般通知或通知連結，向您提供有關本條款或其他事項的變更通知。
          <br />
          <br /> 有關我方隱私政策的通知可發送至
          <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
            privacy@globalenglish.com
          </a>
          。
          <br />
          <br /> 所有發送給 GlobalEnglish 的其他法律通知或其他通信須發送至以下電子郵寄地址：
          <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
            legal@globalenglish.com
          </a>
          ，或以下物理地址：
          <br />
          <br /> 1875 S Grant Street, Suite 700
          <br /> San Mateo, CA 94402
          <br />
          提請
          <b>法律顧問</b>注意
          <br />
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>B2B 交易的特定術語 </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          {' '}
          若您透過您的雇主獲得您的 GlobalEnglish
          帳戶，在使用我方服務時，應遵守我方與貴公司（「公司」）的協議約定。「使用條款」不會改變與貴公司的協議條款。若貴公司未授權您成為
          GlobalEnglish 許可的最終使用者，無法使用（或繼續使用）該服務。如果您要求我方終止您的帳戶，在 GlobalEnglish
          終止您的帳戶之前，需得到貴公司的許可。
          <br />
          <br />{' '}
          您同意，僅在貴公司允許時，方可使用該服務。您將機會透過該服務與貴公司內部人員溝通，或上載與貴公司相關的檔案，但未經貴公司授權，您不得、且無法有以上行為。
          <br />
          <br />{' '}
          您承認並同意，貴公司可訪問您通過使用該服務生成的所有資料（「使用者資料」）。基於使用我方服務產生的與貴公司共用的資訊，GlobalEnglish
          不承擔任何責任。
          <br />
          <br /> 由於您的存取服務訂閱僅在貴公司的許可期內進行，如果貴公司終止其 GlobalEnglish
          的許可，您將無法存取服務和使用者內容。如果 (i) 貴公司根據本協議，在 GlobalEnglish 到期時未支付任何應付費用，或
          (ii) 您或貴公司違反本條款或任何管制服務使用的其他法規，GlobalEnglish
          可（全部或部分）終止或暫停貴公司帳戶、您的帳戶或使用服務的能力，恕不另行通知。
          <br />
          <br /> GlobalEnglish
          須向貴公司歸還與您使用服務相關的所有資料，包括您的個人資料和使用者內容。如果貴公司未要求歸還或刪除此類資料，GlobalEnglish
          將在與貴公司的合同期滿或終止後，將該等資料儲存三 (3) 年。此情況下，歡迎您直接從 GlobalEnglish
          訂購服務，繼續存取課程，且不丟失進度資訊。您可授權將您的進度和個人資料資訊轉至您的新雇主的新訂閱中。另外，您可要求將您使用該服務的所有相關資料，按照與後期導入資料相相容的格式。
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>B2C 交易的特定術語 </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          您可選擇以個人名義與 GlobalEnglish 簽訂訂閱協議，協議可包括三 (3) 個月或六 (6)
          個月的訂閱，或年度或多年的訂閱計劃（統稱為「訂閱計劃」）。
          <br />
          <br />{' '}
          在您取消訂閱計劃之前，我司將在每月或每年（視情況而定）的某一天或前後，對您的適用訂閱計劃按月或按年收取費用。為免生疑慮，請注意，在訂閱計劃滿一週年之前，不得取消、減少訂閱名額數量或對您選擇的服務進行降級。訂閱計劃付款後不得退款。
          <br />
          <br /> 服務的相應費用（「費用」）可在網站和/或當前公佈的 GlobalEnglish
          價目表中查閱。除另有說明外，服務規定價格不包括所有稅費。您須支付使用服務附帶的任何稅費和所有其他費用（例如，資料費用和貨幣匯率清算）。費用使用
          GlobalEnglish 向您帳戶中的報價貨幣支付。GlobalEnglish 保留隨時變更報價貨幣的權利。
          <br />
          <br />
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}> 付款 </b>
          <br />
          <br /> 購買訂閱許可時，即可付款。我們已與 PayPal 和 Stripe
          合作，處理付款。我們不會收到或處理您向這些服務供應商提供的任何信用卡資訊或其他付款方式。
          <br />
          <br /> 所有服務皆應於選定時期（每月、每年或其他方式）預付，恕不退款。這包括更新的帳戶。
          <br />
          <br /> -----
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>加利福尼亞使用者須知 </b>
          <br />
          <br /> 如果您是加利福尼亞州居民，根據《加利福尼亞州民法》第 1798.3
          條，出於協力廠商的直接行銷目的，您可獲取有關向協力廠商披露您的個人資料的資訊。《加利福尼亞州消費者隱私法》（州民法典第
          1798.100 條至第 1709.198 條）將於 2021 年生效，其中規定您享有以下權利：
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            (a) 有權了解收集您個人資訊的物件，可獲得資訊副本，
            <br />
            <br />
            (b) 有權刪除，
            <br />
            <br />
            (c) 有權了解購買您個人資料的協力廠商，及有權要求停止此類銷售，
            <br />
            <br />
            (d) 在您行使消費者權利時，享有免受歧視的權利。
          </span>
          <br /> 此外，《加州商業和職業規範》第 22581
          條目前允許未成年加州居民要求刪除其在網站上公開發佈的內容。但需注意，GlobalEnglish
          無法確保完整或全面刪除您發佈的內容，而且在某些情況下，即使您提出要求，法律也不要求或不允許刪除。
          <br />
          <br />
          如果您想要求行使您的權利：
          <br />
          <br /> 本網站及相關服務供應商為 GlobalEnglish Corporation，地址：1875 South Grant Street, Suite 700, San
          Mateo, CA 94402, USA。電子郵箱地址：privacy@globalenglish.com。
          <br /> 自本條款生效之日起，本網站不對消費者收取任何直接費用。
          如果您對本網站提供的服務有任何不滿，或希望得到更多資訊，請按照上述地址聯絡我們，並說明您的要求。我們無法保證在任何特定時間段進行回覆。如果您仍不滿意，請致信
          1625 North Market Blvd., Suite N 112, Sacramento, CA 95834，或致電 (916) 445-1254 或 (800)
          952-5210，聯絡加利福尼亞州消費者事務部消費者服務部門投訴協助單位。
        </p>
      </div>
    </div>
  );
}

export default ZhTwContent;
