import { Route } from "react-router-dom";
import React from "react";
import { Logout } from "components/Logout";
import { HomeContainer } from "containers/Home";
import { PasswordContainer } from "containers/Password";
import { ResetPassword } from "components/ResetPassword";
import { ErrorContainer } from "containers/Error";
import { OTPVerify } from "containers/OTPVerfiy";

export default [
  <Route path="/site/logout" component={Logout} key="1" />,
  <Route path="/logout" component={Logout} key="2" />,
  <Route path="/resetpassword" component={ResetPassword} key="7" />,
  <Route
    path="/forgotpassword"
    render={(props: any) => <HomeContainer {...props} forgotPassword={true} />}
    key="3"
  />,
  <Route path="/passwordupdated" component={PasswordContainer} key="4" />,
  <Route path="/otp/:username/:email" component={OTPVerify} key="7" />,
  <Route path="/" component={HomeContainer} key="5" />,
  
  <Route
    path="/error"
    render={(props: any) => <HomeContainer {...props} showErrorPage={true} />}
    key="6"
  />,
];
