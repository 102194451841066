import Cookies from "universal-cookie";
import EnContent from "utils/translations/Copyright/en";
import CzCzContent from "utils/translations/Copyright/cz-cz";
import DeDeContent from "utils/translations/Copyright/de-de";
import EsMxContent from "utils/translations/Copyright/es-mx";
import EsEsContent from "utils/translations/Copyright/es-es";
import FrFrContent from "utils/translations/Copyright/fr-fr";
import ItItContent from "utils/translations/Copyright/it-it";
import JaJpContent from "utils/translations/Copyright/ja-jp";
import KoKoContent from "utils/translations/Copyright/ko-ko";
import PlPlContent from "utils/translations/Copyright/pl-pl";
import PtBrContent from "utils/translations/Copyright/pt-br";
import RuRuContent from "utils/translations/Copyright/ru-ru";
import TrTrContent from "utils/translations/Copyright/tr-tr";
import ZhCnContent from "utils/translations/Copyright/zh-cn";
import ZhTwContent from "utils/translations/Copyright/zh-tw";

function Copyright() {
  const cookie = new Cookies();
  const language = cookie.get("ulang") ? cookie.get("ulang") : "en";

  const content = {
    en: EnContent(),
    "cz-cz": CzCzContent(),
    "de-de": DeDeContent(),
    "es-mx": EsMxContent(),
    "es-es": EsEsContent(),
    "fr-fr": FrFrContent(),
    "it-it": ItItContent(),
    "ja-jp": JaJpContent(),
    "ko-ko": KoKoContent(),
    "pl-pl": PlPlContent(),
    "pt-br": PtBrContent(),
    "ru-ru": RuRuContent(),
    "tr-tr": TrTrContent(),
    "zh-cn": ZhCnContent(),
    "zh-tw": ZhTwContent(),
  };

  return content[language.toLowerCase()] || null;
}

export default Copyright;
