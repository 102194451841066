import { polyfill } from "es6-promise";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./globalStyles.css";
import App from "./App";
import { IntlProvider } from "react-intl";
import messages_en from "./utils/translations/en.json";
import messages_cz from "./utils/translations/cz-cz.json";
import messages_de from "./utils/translations/de-de.json";
import messages_es_mx from "./utils/translations/es-mx.json";
import messages_es from "./utils/translations/es-es.json";
import messages_fr from "./utils/translations/fr-fr.json";
import messages_it from "./utils/translations/it-it.json";
import messages_ja_jp from "./utils/translations/ja-jp.json";
import messages_ko from "./utils/translations/ko-ko.json";
import messages_pl from "./utils/translations/pl-pl.json";
import messages_pt_br from "./utils/translations/pt-br.json";
import messages_ru from "./utils/translations/ru-ru.json";
import messages_tr from "./utils/translations/tr-tr.json";
import messages_zh_cn from "./utils/translations/zh-cn.json";
import messages_zh_tw from "./utils/translations/zh-tw.json";
import Cookies from "universal-cookie";
import { datadogRum } from '@datadog/browser-rum';
polyfill();

const messages = {
  en: messages_en,
  "cz-cz": messages_cz,
  "de-de": messages_de,
  "es-mx": messages_es_mx,
  "es-es": messages_es,
  "fr-fr": messages_fr,
  "it-it": messages_it,
  "ja-jp": messages_ja_jp,
  "ko-ko": messages_ko,
  "pl-pl": messages_pl,
  "pt-br": messages_pt_br,
  "ru-ru": messages_ru,
  "tr-tr": messages_tr,
  "zh-cn": messages_zh_cn,
  "zh-tw": messages_zh_tw,
};

const cookie = new Cookies();
const language = cookie.get("ulang") ? cookie.get("ulang").toLowerCase() : "en";

ReactDOM.render(
  <BrowserRouter>
    <IntlProvider locale={language} messages={messages[language]}>
      <App />
    </IntlProvider>
  </BrowserRouter>,
  document.getElementById("root"),
);
