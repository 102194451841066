// @ts-nocheck
import React from 'react';

function FrFrContent() {
  return (
    <div id="agreement_container">
      <div style={{ padding: '1% 5% 0 5%' }}>
        <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
          <b>CONTRAT D’UTILISATION</b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
          <b>INTRODUCTION</b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          Bienvenue aux services de GlobalEnglish, soit sur le site Web soit sur les applications mobile (ci-après
          collectivement désignés les « Services »).
          <br />
          <br /> Nos Services sont des outils pour l’apprentissage de l’anglais et des solutions en ligne, y compris,
          sans limitation, des donnés, textes, tests en ligne, graphismes, vidéos, clips audio, enregistrements de voix,
          articles et commentaires écrits, scripts, illustrations, images et leur sélection et arrangement, logiciels,
          tutoriels, cours, modules d’encadrement, de formation, de communication et interactifs générés ou rendus
          disponibles sur le site Web et les applications mobiles (ci-après collectivement désignés le « Contenu »).
          L’accès à notre site Web pour lire sur notre entreprise et se renseigner sur les Services fait partie
          intégrante des Services.
          <br />
          <br /> Veuillez lire attentivement les termes ci-dessous et confirmer les avoir compris et acceptés avant de
          continuer.
          <br />
          <br />
          <b>
            SI VOUS AVEZ MOINS DE 18 ANS, ASSUREZ-VOUS DE LIRE CE CONTRAT AVEC VOS PARENTS OU VOTRE TUTEUR LÉGAL ET
            D’OBTENIR LEUR CONSENTEMENT AVANT D’UTILISER NOS SERVICES. EN UTILISANT NOS SERVICES, VOUS ATTESTEZ QUE VOUS
            AVEZ PLUS DE 18 ANS OU QUE VOUS AVEZ OBTENU LE CONSENTEMENT DE VOS PARENTS OU DE VOTRE TUTEUR LÉGAL ET QUE
            VOUS AVEZ LA PERMISSION DE PASSER CE CONTRAT AVEC GLOBALENGLISH.{' '}
          </b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
          <b>CONDITIONS GÉNÉRALES </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 1. CONTRAT JURIDIQUEMENT CONTRAIGNANT </b>
          <br />
          <br />
          <b>
            {' '}
            VOTRE ACCÈS À NOTRE SITE WEB ET L’UTILISATION DES SERVICES DE GLOBALENGLISH FONT L’OBJET DU CONTRAT
            D’UTILISATION CI-APRÈS, À LA POLITIQUE DE CONFIDENTIALITÉ ET À LA POLITIQUE CONCERNANT LES COOKIES (TOUS LES
            TROIS DOCUMENTS ÉTANT COLLECTIVEMENT DÉSIGNÉS LES « CONDITIONS D’UTILISATION »). EN CONSULTANT, VISITANT, EN
            VOUS INSCRIVANT ET/OU EN UTILISANT LES SERVICES DE GLOBALENGLISH, VOUS CONFIRMEZ AVOIR LU ET COMPRIS LES
            CONDITIONS D’UTILISATION DE NOS SERVICES ET VOUS ACCEPTEZ D’ÊTRE LÉGALEMENT ET IRRÉVOCABLEMENT LIÉ PAR
            CELLES-CI ET QUE VOUS AVEZ L’ÂGE LÉGAL POUR ACCEPTER D’ÊTRE LIÉ PAR LESDITES CONDITIONS ET POUR PASSER UN
            CONTRAT JURIDIQUEMENT CONTRAIGNANT AVEC GLOBALENGLISH.
          </b>
          <br />
          <br /> Cliquez
          <a
            href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
            rel="noopener noreferrer"
            target="_blank"
          >
            ici
          </a>{' '}
          pour avoir une vue d’ensemble de nos politiques. Ces politiques sont intégrées par renvoi dans le présent
          « Contrat d’utilisation » ou « Contrat » et elles constituent, dans l’ensemble, les conditions d’utilisation.
          <br />
          <br />
          <b>
            Il convient de noter que le présent Contrat comprend des dispositions régissant la modalité dont nous
            pouvons solutionner les potentielles plaintes. Voir notamment les dispositions ci-dessous concernant
            l’Exonération de garanties, la Limitation de responsabilité et les Litiges. Il comprend également un accord
            d’arbitrage qui, sous réserve de certaines exceptions, requiert que vous soumettiez toutes plaintes contre
            nous à l’arbitrage exécutoire et définitif. Vous ne serez autorisé à entamer des procédures
            qu’individuellement, non comme plaignant ou membre d’un groupe de quelque procédure ou recours collectif ou
            de groupe que ce soit. Vous ne serez autorisé à demander des réparations (y compris des réparations
            pécuniaires, injonctives et déclaratoires) que sur une base individuelle.
            <br />
            <br /> NE PAS UTILISER LES SERVICES OU NE PLUS NAVIGUER SUR NOTRE SITE WEB SI, À TOUT MOMENT, VOUS N’ÊTES
            PAS D’ACCORD AVEC L’UNE OU L’AUTRE DES CONDITIONS DES PRÉSENTES.
          </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. APPLICABILITÉ</b>
          <br />
          <br /> Le présent Contrat s’applique tant aux Utilisateurs de nos services qu’aux Visiteurs de nos sites Web.
          Les personnes qui se sont inscrites directement avec nous (une
          <b>« B2C transaction »</b> - transaction marchand-consommateur), même à titre d’essai, et les personnes qui se
          sont inscrites par ou par l’entremise de leurs employeurs (une
          <b>“B2B transaction”</b>) sont désignées les
          <b>« Utilisateurs »</b> tandis que les utilisateurs non-inscrits et les visiteurs qui consultent nos sites Web
          sont désignés les
          <b>« Visiteurs »</b>. Le terme « Utilisateurs » comprend également les professeurs, les formateurs, les
          spécialistes de l’apprentissage et toute autre personne qui consulte les sites Web de GlobalEnglish afin de
          fournir des services à d’autres Utilisateurs. Tant les Utilisateurs que les Visiteurs sont désignés
          <b>« Vous »</b>.
          <br />
          <br /> Le présent Contrat s’applique à l’ensemble des services de GlobalEnglish.
          <br />
          <br /> Lorsque vous vous inscrivez pour la première fois comme Utilisateur et lorsque vous accédez à nos
          Services, nous vous demanderons éventuellement de réitérer votre accord d’être lié par les conditions des
          présentes en cliquant sur la boîte « J’accepte » ou en fournissant votre signature électronique ou tout autre
          moyen d’authentification.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>3.CHANGEMENT AUX CONDITIONS D’UTILISATION </b>
          <br />
          <br />
          GlobalEnglish peut changer les Conditions d’utilisation à tout moment, y compris ce Contrat, la Politique
          concernant les cookies et la Politique de confidentialité s’appliquant à votre utilisation des Services.
          Lesdits changements prendront effet immédiatement moyennant avis donné par affichage dans le cadre du Service,
          par message électronique envoyé à l’adresse électronique que vous avez fournie en relation avec votre compte
          Services (s’il y a lieu) ou par d’autres moyens. Si vous vous êtes inscrit pour nos Services par l’entremise
          de votre employeur ou d’une société, votre employeur ou société vous notifierait des changements à nos
          Conditions d’utilisation.
          <br />
          <br /> Si vous vous opposez auxdits changements, vous pouvez fermer votre compte et ne plus consulter notre
          site Web. Pourtant, l’utilisation de notre Service après l’affichage ou l’envoi d’un avis concernant les
          changements à ces conditions sera réputée valoir acceptation desdits changements aux Conditions d’utilisation.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4.CONDITIONS D’UTILISATION DES TIERS </b>
          <br />
          <br />
          Les Services pourraient contenir des liens vers d’autres sites Web (« Sites Web des tiers ») tout comme des
          articles, photos, textes, traductions, illustrations, images, graphismes, sons, vidéos, renseignements et
          autres contenus ou éléments appartenant à des tiers ou provenant de ceux-ci (le « Contenu des tiers »). Nous
          ne vérifions pas l’exactitude, la pertinence ou l’exhaustivité des Sites Web des tiers et le Contenu des tiers
          et nous ne sommes pas responsables des Sites Web des tiers consultés par l’entremise de notre site Web ou de
          tout Contenu des tiers affiché ou disponible par l’entremise du site Web.
          <br />
          <br /> En incluant, en reliant ou en permettant l’utilisation de tout Site Web des tiers ou de tout Contenu
          des tiers n’implique pas que GlobalEnglish les approuvent ou les endossent. GlobalEnglish se réserve le droit
          d’éliminer lesdits liens à tout moment, sans préavis. Les références dans le cadre des Services à des
          compagnies, organisations, produits ou services ou autres informations ne constituent ou n’impliquent pas leur
          endossement, parrainage ou recommandation par GlobalEnglish, ou quelque affiliation que ce soit avec ceux-ci.
          <br />
          <br /> Si vous décidez de quitter notre site Web et de consulter les Sites Web des tiers ou de consulter ou
          d’utiliser tout Contenu des tiers, vous le faites à vos risques et n’oubliez pas que nos conditions et
          politiques ne s’appliquent plus à votre utilisation desdits Sites Web des tiers. Veuillez vérifier les
          conditions et les politiques applicables aux produits et services des tiers avant de les utiliser.
          <br />
          <br /> Nous offrons des applications mobiles par l’entremise d’Android Market comme d’iTunes Store
          (collectivement désignés, avec les entités qui les maintiennent, les « Fournisseurs »). Votre utilisation
          desdites applications mobiles fera aussi l’objet des conditions générales des Fournisseurs, y compris, sans
          limitation, des Règles d’utilisation prévues dans les Conditions d’utilisation d’App Store. Si vous avez
          téléchargé l’une ou l’autre de ces applications mobiles sur iTunes Store, vous confirmez et convenez d’avoir
          lu et accepté les Conditions d’utilisation d’App Store. Si vous les avez téléchargées sur Android Market, vous
          confirmez et convenez d’avoir lu et accepté les Conditions d’utilisation d’Android Market. Les Fournisseurs
          n’ont aucune obligation, et n’en auront pas, d’assurer des services d’entretien ou de soutien en raison de
          votre utilisation des Services. Les Fournisseurs n’offrent aucune garantie pour les Services. Au cas où l’une
          ou l’autre des applications mobiles ne respecte pas la garantie applicable et si vous l’avez téléchargée sur
          iTunes Store, vous pouvez en notifier Apple et Apple vous remboursera le prix d’achat (le cas échéant) de
          ladite application. Ces Conditions s’appliquent à votre utilisation de toutes les applications mobiles et de
          tous nos autres Services.
          <br />
          <br /> Vous confirmez et acceptez que GlobalEnglish n’est ni responsable ni redevable du contenu, des
          produits, des services ou du rendement des sites Web ou applications des tiers. Vous renoncez de manière
          irrévocable à toute réclamation contre GlobalEnglish en ce qui concerne les sites Web ou les applications des
          tiers. Notamment, GLOBALENGLISH N’EST PAS RESPONSABLE DES COOKIES, PIXELS ESPIONS, GIFS INVISIBLES OU AUTRE
          CODES QUE VOUS OU AUTRES PARTIES PLACEZ SUR VOTRE ORDINATEUR DE QUELQUE MANIÈRE QUE CE SOIT, Y COMPRIS TOUTE
          ASSOCIATION DU CONTENU DES SITES WEB AUDIT CODE. Par exemple, si vous consultez un Site Web des tiers en
          cliquant sur un lien sur le Site Web, votre navigateur ou autres cookies sur votre ordinateur pourraient faire
          envoyer un référent audit site Web en fournissant certaines informations de votre historique de navigation ou
          l’URL que vous venez d’utiliser. GlobalEnglish ne saurait être tenue responsable envers vous d’une telle
          transmission, que la transmission contienne ou non des informations associées à GlobalEnglish ou provenant de
          celle-ci.
          <br />
          <br /> SI VOUS UTILISEZ DES TRADUCTIONS GÉNÉRÉES PAR GOOGLE : GOOGLE DÉCLINE TOUTE GARANTIE, EXPLICITE OU
          IMPLICITE, CONCERNANT LES TRADUCTIONS, Y COMPRIS TOUTE GARANTIE CONCERNANT L’EXACTITUDE, LA FIABILITÉ ET TOUTE
          GARANTIE IMPLICITE DE QUALITÉ MARCHANDE, D’ADAPTATION À UN EMPLOI PARTICULIER ET D’ABSENCE DE CONTREFAÇON.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>5. CRITÈRES D’ADMISSIBILITÉ DU SERVICE </b>
          <br />
          <br />
          <b>5.1 Protection de la vie privée des enfants et restrictions sur l’utilisation de nos Services. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Les Services ne sont pas destinés à l’utilisation de quiconque de moins de l’Âge minimum au pays où
            ils/elles consultent les Services. Par exemple, l’ « Âge minimum » dans l’Union Européenne », l’Espace
            Economique Européen et la Suisse est de 16 ans, sauf indication contraire des lois nationales spécifiques.
            Aux États-Unis, l’âge minimum est de 13 ans. Toute inscription aux Services, utilisation ou consultation de
            ceux-ci par quiconque de moins de l’Âge minimum est interdite et constitue une violation des présentes
            Conditions.
            <br />
            <br /> En utilisant les Services, vous déclarez et garantissez que vous avez 13 ans ou plus.
          </span>
          <br />
          <br />
          <b>5.2 Écoles et professeurs </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Les écoles, les départements ou les professeurs aux États-Unis qui veulent que leurs élèves âgés de moins de
            13 ans créent des comptes GlobalEnglish acceptent qu’ils sont responsables de respecter la Children's Online
            Privacy Protection Act (« COPPA ») (Loi américaine sur la protection de la confidentialité des enfants en
            ligne) et, dans la mesure applicable, la Family Educational Rights and Privacy Act (« FERPA ») (Loi sur la
            confidentialité et le droit à l’éducation des familles). Cela signifie que les écoles, les départements ou
            les professeurs doivent notifier les parents ou les tuteurs légaux des élèves sur les renseignements
            personnels que GlobalEnglish va collecter, et qu’ils doivent obtenir le consentement des parents/tuteurs
            légaux avant que les élèves créent des comptes et utilisent les Services. Lorsqu’ils obtiennent ledit
            consentement, les écoles, les départements et les professeurs doivent fournir aux parents/tuteurs légaux un
            exemplaire de notre Politique de confidentialité et conserver tous les consentements afin de nous les
            transmettre si nous les demandons. Pour plus d’informations sur le respect de la COPPA, veuillez consulter
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
              rel="noopener noreferrer"
              target="_blank"
            >
              le site Web de la Federal Trade Commission
            </a>{' '}
            (Commission fédérale américaine du commerce).
            <br />
            <br /> Si vous résidez en dehors des États-Unis, nous comptons sur vous pour obtenir le consentement
            nécessaire et l’accord des parents/tuteurs légaux de tout élève faisant l’objet des lois similaires et, une
            condition pour l’utilisation des Services par vous ou par vos élèves est d’accepter la responsabilité de se
            conformer auxdites lois.
          </span>
          <br />
          <br />
          <b>5.3 Consentement parental </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            <b>
              EN UTILISANT NOS SERVICES, VOUS DÉCLAREZ QUE VOUS ÊTES ÂGÉ DE PLUS DE 18 ANS OU QUE VOUS AVEZ OBTENU LE
              CONSENTEMENT DE VOS PARENTS OU DE VOTRE TUTEUR LÉGAL DE PASSER LE PRÉSENT CONTRAT AVEC GLOBALENGLISH.
              <br />
              <br /> Nous nous attendons à ce que toute utilisation par des enfants aura lieu avec le soutien,
              l’assistance et le consentement de leurs parents, tuteurs légaux et/ou responsables scolaires habilités.
              GlobalEnglish compte sur les parents, les tuteurs légaux et les écoles pour s’assurer que les enfants
              mineurs n’utilisent les Services que s’ils/elles comprennent leurs droits et responsabilités prévues dans
              les présentes Conditions d’utilisation.
              <br />
              <br /> À sa seule discrétion, GlobalEnglish peut refuser à tout moment de fournir les Services à une
              personne ou entité quelconque et de modifier ses critères d’admissibilité.{' '}
            </b>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6.CONFIDENTIALITÉ</b>
          <br />
          <br />
          <b>6.1 Déclaration de confidentialité </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Veuillez consulter la Politique de confidentialité disponible
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              ici
            </a>
            .
            <br />
            <br /> Nous allons traiter les données personnelles en conformité avec la Politique de confidentialité à
            laquelle vous avez consenti la première fois que vous avez consulté le service ou dans le cadre d’une mise à
            jour de la Politique de confidentialité.
          </span>
          <br />
          <br />
          <b>6.2 Les données que vous nous transmettez. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Afin d’utiliser les Services, vous devez avoir un compte valide. Afin de vous inscrire pour nos Services,
            vous devez fournir à GlobalEnglish un ID utilisateur, une adresse courriel et autres informations réputées
            comme des renseignements personnels (« Données personnelles »). Certaines des informations que nous
            collectons de vous sont nécessaires pour que GlobalEnglish puisse fournir les Services ou seulement pour
            vous envoyer des informations sollicitées concernant les Services, et autres sont optionnelles. De plus, les
            Services vous permettront d’afficher des informations sur vous-même et sur votre organisation, le cas
            échéant.
            <br />
            <br /> Vous acceptez : (i) de fournir des informations exactes, actuelles et complètes sur vous-même et, au
            cas d’une transaction B2B, sur votre organisation, lorsque ces informations sont demandées dans les
            formulaires d’inscription aux Services ; (ii) de préserver la confidentialité de votre mot de passe et
            d’autres informations concernant la sécurité de votre compte ; (iii) de préserver et mettre à jour
            promptement les Données personnelles et toutes autres informations que vous fournissez à GlobalEnglish, de
            tenir lesdites informations à jour, exactes et complètes ; et (iv) d’être entièrement responsable de toute
            utilisation de votre compte et de toutes actions ayant lieu par l’entremise de votre compte.
            <br />
            <br /> GlobalEnglish décline toute obligation de vérifier l’exactitude des Données personnelles que vous
            fournissez aux fins d’inscription.
          </span>
          <br />
          <br />
          <b>6.3 Transfert international des données personnelles </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Afin de fournir les Services, GlobalEnglish compte sur sa main-d’œuvre à travers le monde et utilise des
            fournisseurs tiers et des partenaires d’hébergement pour le stockage et autres services à caractère
            technologique et d’enseignement. Vos Données personnelles sont traitées et transférées aux employés et
            sous-traitants de GlobalEnglish dans plusieurs pays, dont certains n’offrent peut-être pas le même niveau de
            protection duquel bénéficient vos Données personnels dans votre pays de citoyenneté ou résidence.
            <b>
              En consultant, en vous inscrivant et/ou en utilisant nos Services, vous acceptez d’avoir vos Données
              personnelles transférées et traitées dans l’ensemble des pays où GlobalEnglish, ses filiales et
              sous-traitants sont situés aux fins des Services.
            </b>
          </span>
          <br />
          <br />
          <b>6.4 Avis et messages. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Vous comprenez et acceptez que les Services pourraient inclure des communications émanant de GlobalEnglish,
            par ex. des annonces de service, des messages administratifs, etc., et que ces communications sont réputées
            faire partie des Services et que vous ne pourrez pas les refuser.
            <br />
            <br /> Vous acceptez que GlobalEnglish peut vous transmettre des avis et des messages en utilisant les
            modalités suivantes : (i) dans le cadre du Service ; (ii) aux coordonnées que vous avez fournies à
            GlobalEnglish (par ex. adresse électronique ou numéro de téléphone mobile). Vous vous engagez à tenir vos
            coordonnées mises à jour en tout temps.
          </span>
          <br />
          <br />
          <b>6.5 La vie privée des autres </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Au cas où vous obtenez des informations sur un autre Utilisateur au cours de votre utilisation de nos
            Services, vous vous engagez à utiliser lesdites informations aux seules fins pour lesquelles elles vous ont
            été fournies. Vous ne pouvez pas divulguer, vendre ou distribuer les informations d’un Utilisateur à un
            tiers à des fins sans rapport avec les Services. Vous ne pouvez pas utiliser lesdites informations à des
            fins commerciales, à moins que vous n’obteniez le consentement d’un certain Utilisateur à cet effet.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. LICENCES D’UTILISATION </b>
          <br />
          <br />
          <b>7.1 Licence pour l’utilisation de nos Services </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Nous vous accordons par la présente une licence limitée, personnelle, non-exclusive, non-cessible, ne
            pouvant pas faire l’objet d’une sous-licence, et non-transférable afin de consulter et d’utiliser les
            Services à des fins personnelles et non-commerciales seulement. GlobalEnglish peut, à sa seule discrétion,
            interrompre temporairement ou annuler de façon permanente le Service, ou modifier les caractéristiques des
            Services de temps en temps, sans préavis.
            <br />
            <br /> Afin d’utiliser les Services, vous devez obtenir le droit d’accès au World Wide Web, soit directement
            soit par le biais de dispositifs qui peuvent accéder au contenu accessible sur internet. De plus, vous devez
            avoir tous les équipements nécessaires pour vous connecter au World Wide Web, y compris un ordinateur et un
            modem et autres dispositifs d’accès. Vous vous engagez à évaluer et à supporter tous les risques liés à
            l’utilisation des Services, y compris la confiance que vous places en l’exactitude, l’exhaustivité ou
            l’utilité du contenu fourni. Ni GlobalEnglish ni ses concédants ne garantissent l’exactitude ou
            l’exhaustivité de quelque contenu que ce soit.
            <br />
            <br /> Les Services ne sont ni conçus, ni cédés sous licence pour être utilisés en environnements dangereux
            (par ex. lorsque vous conduisez, dans ou près de l’eau).
            <br />
            <br /> Le droit d’accéder au Service sera révoqué lorsque l’utilisation du Service est interdite ou dans la
            mesure où l’offre, la vente ou la prestation du Service sont contraires à quelque loi, règle ou règlement
            applicable.
            <br />
            <br />
            L’utilisation du Service à d’autres fins que celles prévues dans les Conditions d’utilisation constitue une
            violation des Conditions d’utilisation et des droits d’auteur et de propriété intellectuelle de
            GlobalEnglish et/ou de ses concédants et sous-traitants. GlobalEnglish a le droit de suspendre ou de
            supprimer votre compte et de refuser toute ou partie de l’utilisation courante ou future des Services si
            GlobalEnglish a des soupçons raisonnables quant à votre utilisation des Services en violation desdites
            Conditions d’utilisation. Vous acceptez que GlobalEnglish ne soit pas responsable envers vous de quelque
            modification, suspension ou arrêt en tout ou en partie des Services.
          </span>
          <br />
          <br />
          <b>7.2 Contenu utilisateur</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Les Services vous donnent la possibilité de créer des données et des informations (par ex. en fournissant
            des réponses à nos tests) et de télécharger des contenus sur les services communautaires (« Contenu
            utilisateur »). Sous réserve des droits qui nous sont accordés par ces Conditions, vous conservez la
            propriété intégrale de votre Contenu utilisateur.
            <br />
            <br /> En téléchargeant tout Contenu utilisateur sur nos Services, vous accordez à GlobalEnglish une licence
            irrévocable, perpétuelle, non-exclusive, transférable, entièrement payée et universelle (avec le droit
            d’accorder des sous-licences) pour stocker, utiliser, reproduire, exécuter et afficher publiquement,
            modifier, traduire, extraire (en tout ou en partie), traiter et distribuer le Contenu utilisateur lorsque
            cela est nécessaire pour la prestation des Services afin:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) De se conformer aux exigences de la loi, y compris, sans limitation, de répondre aux demandes
              juridiques, décisions judiciaires et autres demandes gouvernementales similaires ;
              <br />
              (b) D’exécuter le Contrat ;
              <br />
              (c) De répondre aux réclamations visant la violation des droits des tiers par le Contenu ;
              <br />
              (d) De protéger les droits, la propriété et la sécurité personnelle de GlobalEnglish, de ses employés, des
              Utilisateurs et du public.
            </span>
            <br />
            <br /> Dans la mesure où vous choisissez de partager tout Contenu utilisateur avec autres Utilisateurs des
            Services, vous vous engagez à permettre auxdits Utilisateurs de voir votre Contenu utilisateur et, dans la
            mesure applicable, de collaborer avec vous sur ledit Contenu utilisateur.
            <br />
            <br /> Veuillez noter que GlobalEnglish n’a pas l’obligation de filtrer, réviser, vérifier l’exactitude,
            corriger ou supprimer le Contenu utilisateur. Cependant, GlobalEnglish se réserve le droit de surveiller le
            Contenu utilisateur et de supprimer, à leur discrétion, le contenu qu’ils jugent offensant ou autrement
            constitue un manquement aux Conditions d’utilisation.
            <br />
            <br /> GlobalEnglish n’a aucune obligation de sauvegarde ou stockage en ce qui concerne le Contenu
            utilisateur. Il vous incombe entièrement, à vos frais exclusifs, de créer des copies de sauvegarde et de
            remplacer tout Contenu utilisateur que vous affichez ou stockez sur le site Web ou que vous fournissez à
            GlobalEnglish.
            <br />
            <br /> Le Contenu utilisateur ne reflète pas nécessairement les opinions de GlobalEnglish. Vous comprenez
            que le Contenu utilisateur relève de la responsabilité exclusive de l’Utilisateur qui le crée. Vous
            comprenez qu’en utilisant les Services, vous pouvez être exposé(e) à un Contenu utilisateur offensant,
            indécent ou répréhensible.
            <br />
            <br /> En plus de l’octroi de licence au Contenu utilisateur prévu ci-dessus, vous confirmez et acceptez que
            les questions, commentaires, suggestions, idées, rétroactions ou autres informations sur les Services
            (« Soumissions »), que vous fournissez à GlobalEnglish sont non-confidentiels et que GlobalEnglish aura
            droit à l’utilisation et à la diffusion libre desdites Soumissions, à quelque fin que ce soit, commercial ou
            autre, sans qu’une reconnaissance ou compensation vous soit accordée. De plus, les informations affichées,
            divulguées ou partagées sur ou par l’entremise des Services ou produits communautaires de GlobalEnglish ne
            seront pas couvertes par la définition des informations confidentielles, au sens où on entend généralement
            ce terme.
            <br />
            <br />
            Vous comprenez que le traitement technique et la transmission du site Web, y compris de votre Contenu
            utilisateur, peut impliquer (a) des transmissions sur des réseaux divers ; et (b) des modifications afin de
            se conformer et de s’adapter aux exigences techniques des réseaux ou dispositifs de connexion.
            <br />
            <br />
            <b>
              GlobalEnglish ne fournit aucune garantie ou assurance au sujet du Contenu utilisateur. Le Contenu
              utilisateur ne reflète pas nécessairement les opinions de GlobalEnglish. En aucun cas, GlobalEnglish ne
              saurait être tenue responsable du Contenu utilisateur ou de la perte de celui-ci pour quelque raison que
              ce soit ou des réclamations, dommages ou pertes découlant de son utilisation ou de sa perte.
            </b>
          </span>
          <br />
          <br />
          <b>7.3 Conduite imposée aux Utilisateurs</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Vous êtes entièrement responsable de l’ensemble des activités réalisées sur votre compte. Vous acceptez :
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) d’aviser GlobalEnglish promptement de toute utilisation non autorisée de Votre compte ou de toute
              autre violation de sécurité s’y rapportant, et
              <br /> (b) de vous assurer que vous fermez chacune de vos sessions.
            </span>
            <br />
            <br />
            GlobalEnglish n’engage pas et n’engagera pas sa responsabilité en cas de perte ou de dommage découlant de
            votre manquement aux présentes.
            <br />
            <br />
            Vous serez entièrement responsable de vous assurer que ces Conditions d’utilisation respectent l’ensemble
            des lois, règles et règlements applicables et le droit d’accéder au Service sera révoqué si les Conditions
            d’utilisation ou l’utilisation du Service sont interdites ou dans la mesure où l’offre, la vente ou la
            prestation du Service sont contraires à quelque loi, règle ou règlement applicable.
          </span>
          <br />
          <br />
          <b>7.4 Conduite interdite aux Utilisateurs </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Les actes suivants sont des exemples du type d’utilisation illégale ou interdite par GlobalEnglish.
            GlobalEnglish se réserve le droit d’enquêter et de prendre des mesures légales appropriées contre toute
            personne qui, à la seule discrétion de GlobalEnglish, viole cette disposition, y compris, entre autres,
            retirer du site Web ou de l’application le contenu offensant, suspendre ou supprimer le compte de tels
            Utilisateurs et signaler vos actes aux autorités policières.
            <br />
            <br /> Vous n’êtes pas autorisé à :
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) adapter, télécharger, réviser, transmettre, procéder à une ingénierie inverse, dupliquer, modifier,
              diffuser, afficher, transférer ou autrement copier et/ou distribuer le Contenu ou tout autre matériel de
              GlobalEnglish concernant le Service, sauf autorisation expresse de GlobalEnglish.
              <br />
              (b) cadrer ou utiliser des techniques de cadrage pour joindre quelque partie que ce soit du Service ;
              <br />
              (c) collecter, obtenir, utiliser, accéder ou autrement copier toute partie du Service en utilisant des
              bots, spiders, crawlers, espiogiciels, moteurs, dispositifs, logiciels ou autres dispositifs ou
              utilitaires automatiques, ou procédures manuelles de quelque nature que ce soit ;
              <br />
              (d) utiliser les Services ou toutes autres fonctions disponibles des Services de quelque manière que ce
              soit avec l’intention d’interrompre, d’endommager, de mettre hors service, de surcharger ou de détériorer
              les Services ;
              <br />
              (e) vendre, donner sous licence, louer ou autrement utiliser les Services à des fins commerciales ; ou
              <br />
              (f) vous livrer à une activité qui entrave l’accès d’un autre Utilisateur, son utilisation ou sa
              jouissance de ce Service.
              <br />
              (g) afficher, envoyer par courriel ou transmettre de toute autre façon du Contenu utilisateur (i) qui est
              illégal, nuisible, menaçant, abusif, harcelant, délictueux, excessivement violent, diffamatoire, vulgaire,
              obscène, indécent, pornographique, injurieux, intrusif dans la vie privée d’autrui (incluant, sans
              limitation, des adresses, courriels, numéros de téléphone et autres coordonnées), incitant à la haine, au
              racisme, à la ségrégation ethnique ou autrement répréhensible ; (ii) pour lequel vous n’avez pas droit de
              le transmettre en vertu d’une loi ou d’une relation contractuelle ou fiduciaire quelconque ; (iii) qui
              pose ou crée un risque à la vie privée ou à la sécurité de quelque personne que ce soit ; (iv) qui porte
              atteinte aux droits de propriété intellectuelle ou aux droits propriétaires de quelque partie que ce
              soit ; (v) qui constitue des documents publicitaires ou promotionnels non sollicités ou non autorisés, des
              activités commerciales et/ou ventes, du pourriel, du pollupostage, des chaînes de courriels, des ventes en
              cascade, des concours, des sweepstakes ou toute autre forme de sollicitation ; (vi) qui contient des virus
              informatiques ou autres codes machine, fichiers ou programmes conçus pour interrompre, détruire ou limiter
              le fonctionnement d’un logiciel ou du matériel informatique ou du matériel de télécommunications ; ou
              (vii) qui, à la seule discrétion de GlobalEnglish, est répréhensible ou limite ou empêche toute autre
              personne d’utiliser les Services ou d’en profiter, ou qui pourrait exposer GlobalEnglish ou ses
              utilisateurs à tout type de dommage ou responsabilité ;
              <br />
              (h) créer une fausse identité, dénaturer votre identité, usurper l’identité de toute personne physique ou
              morale, ou présenter de manière inexacte ou délibérément erronée vos rapports avec une telle personne
              physique ou morale ;
              <br />
              (i) solliciter des renseignements personnels auprès de toute personne âgée de moins de 18 ans ;
              <br />
              (j) nuire aux mineurs par quelque moyen que ce soit ;
              <br />
              (k) inciter à la violence physique et aux blessures contre tout groupe ou toute personne, « traquer » ou
              autrement harceler quiconque ;
              <br />
              (l) fournir ou promouvoir des instructions concernant les activités illégales, telles que : instructions
              sur la fabrication de bombes, grenades et autres armes ou dispositifs incendiaires ;
              <br />
              (m) collecter ou recueillir depuis le site Web, par l’entremise des scripts automatisés ou autrement, les
              contenus du site Web ou les adresses électroniques, les coordonnées ou autres renseignements personnels
              des Utilisateurs, pour quelque usage que ce soit, y compris, notamment pour envoyer des courriels non
              sollicités ou autres communications non sollicitées aux Utilisateurs ou pour reproduire le contenu du site
              Web ;
              <br />
              (n) faire de la publicité pour la vente ou proposer à la vente tous produits ou services à toutes fins
              commerciales qui ne sont pas expressément autorisées ;
              <br />
              (o) entraver ou perturber le site Web, les serveurs ou les réseaux connectés au site Web, ou désobéir à
              des exigences, procédures, politiques ou règlementations des réseaux connectés au site Web ;
              <br />
              (p) enfreindre toute loi locale, régionale, nationale ou internationale applicable ou toutes
              règlementations ayant force de loi ; faciliter ou encourager toute activité ou entreprise criminelle, ou
              fournir des instructions sur des activités illégales ;
              <br /> (q) obtenir ou tenter d’accéder à des documents ou informations par des moyens qui ne sont pas mis
              à leur disposition ou fournis par le site Web de manière délibérée ;
              <br />
              (r) outrepasser les fonctions de sécurité ou contourner ou éluder des contrôles d’accès ; ou
              <br />
              (s) utiliser les Services d’une manière qui pourrait créer un conflit d’intérêt ou compromettre le but des
              Services, par ex. négocier des avis avec autres Utilisateurs ou écrire ou solliciter des avis compères.
            </span>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. DROITS DE PROPRIÉTÉ INTELLECTUELLE </b>
          <br />
          <br />
          <b>8.1 Droits de propriété intellectuelle de GlobalEnglish </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Tout le contenu des Services, y compris leur Contenu et le logiciel de soutien représentent la propriété
            exclusive de GlobalEnglish, de ses concédants et sous-traitants, et ladite propriété est protégée par les
            lois américaines et internationales sur les droits d’auteur et la propriété intellectuelle.
            <br />
            <br />
            La reproduction, la redistribution, la modification ou la publication de quelque partie que ce soit des
            Services sans le consentement express par écrit de GlobalEnglish et/ou de ses concédants ou autrement que
            prévu ci-dessus sont strictement interdites.
            <br />
            <br /> Les Utilisateurs autorisés peuvent accéder aux Services et au Contenu et les utiliser, les
            télécharger ou imprimer un nombre raisonnable de copies des portions du Contenu auquel l’Utilisateur a
            obtenu l’accès de manière appropriée, uniquement pour l’usage personnel de l’Utilisateur à des fins de
            formation linguistique, à condition que l’Utilisateur conserve tous les avis de droit d’auteur ou de
            propriété sur toutes les copies dudit Contenu.
            <br />
            <br />
            Sauf indication contraire, les marques des Services sont les marques de GlobalEnglish, ses concédants ou ses
            filiales. Vous vous engagez à ne pas afficher, dénigrer ou dénaturer les marques et à ne pas utiliser de
            marques similaires déroutants ou à ne pas les utiliser de manière à déformer la propriété desdites marques
            ou de manière à créer l’impression que GlobalEnglish endosse tout produit ou service. La reproduction ou
            l’utilisation des marques, nom commerciaux et/ou marques de fabrique de GlobalEnglish sans l’autorisation
            écrite préalable de GlobalEnglish sont interdites. Toute survaleur découlant de l’utilisation des marques de
            GlobalEnglish sera à notre profit exclusif.
            <br />
            <br /> Toute utilisation des sites Web, des applications ou du Contenu autrement que spécifiquement autorisé
            aux présentes est interdite et supprimera automatiquement vos droits relatifs à l’utilisation des Services
            et du Contenu accordés par la présente. Tous les droits de GlobalEnglish ou de ses concédants qui ne sont
            pas expressément accordés dans les présentes Conditions sont réservés à GlobalEnglish et ses concédants.
          </span>
          <br />
          <br />
          <b>8.2 Marques de commerce des tiers </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Les noms et les logos des produits et des Services, utilisés et affichés sur le site Web, autres que les
            marques de GlobalEnglish susmentionnés, pourraient être des marques de commerce ou des marques de service
            appartenant à leurs propriétaires respectifs qui peuvent endosser ou être affiliés ou connectés à
            GlobalEnglish ou non. Rien dans ces Conditions ou sur le site Web devrait être interprété comme conférant,
            implicitement, par réclusion ou autrement, une licence ou un droit quelconque d’utiliser les marques
            affichées sur le site Web sans avoir obtenu l’autorisation écrite préalable dans chaque cas. Toute survaleur
            découlant de l’utilisation des marques de GlobalEnglish sera à notre profit exclusif.
          </span>
          <br />
          <br />
          <b>
            8.3 Plaintes concernant les droits d’auteurs en vertu de la Digital Millennium Copyright Act (Loi sur les
            droits d’auteur du millénaire numérique){' '}
          </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Veuillez nous informer si vous avez des préoccupations visant les droits d’auteur des matériaux publiés sur
            les Services par d’autres personnes.
            <br />
            <br /> GlobalEnglish va traiter et enquêter les notifications de violation présumée et va prendre des
            mesures appropriées en vertu de la Digital Millennium Copyright Act (« DMCA ») et de toutes autres lois sur
            la propriété intellectuelle applicables pour ce qui est de toute violation présumée ou réelle.
            <br />
            <br /> Une notification visant une violation présumée du droit d’auteur doit être transmise par courriel au
            Responsable des droits d’auteur de GlobalEnglish au copyright@globalenglish.com (Ligne objet : « DMCA
            Demande de retrait »).
            <br />
            <br /> Vous pouvez aussi nous contacter à notre adresse :
            <br />
            <b>Attn : Plaintes concernant les droits d’auteur/Service Juridique </b>
            <br /> 1875 South Grant Street, Suite 700
            <br /> San Mateo, CA 94402 USA
            <br />
            <br />
            <br /> Pour prendre effet, la notification doit être faite par écrit et doit contenir les informations
            suivantes : (i) une signature électronique ou physique de la personne autorisée à agir au nom du
            propriétaire du droit d’auteur ou autre intérêt de la propriété intellectuelle ; (ii) une description de
            l’œuvre protégée par droits d’auteur ou de toute autre propriété intellectuelle dont vous estimez que les
            droits ont été enfreints ; (iii) une description de l’emplacement du matériel sur le Service, que vous
            indiquez faire l’objet d’une contravention, avec le plus de détails pour nous permettre de le localiser sur
            le site Web ; (iv) des informations suffisantes pour permettre à GlobalEnglish de vous contacter (adresse,
            numéro de téléphone et adresse électronique) ; (v) une déclaration par laquelle vous estimez de bonne foi
            que l’utilisation contestée n’a pas été autorisée par le propriétaire des droits d’auteur ou de la propriété
            intellectuelle, par son représentant ou par la loi ; (vi) une déclaration dans laquelle vous confirmez, sous
            peine de parjure, que les informations susvisées dans votre Notification sont exactes et que vous êtes le
            propriétaire des droits d’auteur ou de la propriété intellectuelle ou que vous êtes autorisé à agir au nom
            du propriétaire des droits d’auteur ou de la propriété intellectuelle.
            <br />
            <br /> En conformité avec la DMCA et toute autre loi applicable, GlobalEnglish a adopté une politique de
            résiliation, dans des circonstances appropriées et à la discrétion exclusive de GlobalEnglish, des comptes
            des utilisateurs considérés comme récidivistes. GlobalEnglish peut également, à sa seule discrétion, limiter
            l’accès au site Web et/ou résilier la qualité de membre des utilisateurs qui enfreignent tous droits de
            propriété intellectuelle des autres, qu’ils soient récidivistes ou non.
          </span>
          <br />
          <br />
          <b>8.4 Suppression du Contenu utilisateur soupçonné d’atteinte aux droits </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Au cas où GlobalEnglish a supprimé une partie de votre Contenu utilisateur soupçonné de porter atteinte aux
            droits de propriété intellectuelle des tiers et si vous considérez que votre Contenu utilisateur qui a été
            supprimé (ou dont l’accès a été désactivé) n’est pas enfreignant, ou si vous êtes autorisé par le
            propriétaire des droits d’auteur, le représentant du propriétaire des droits d’auteur ou par la loi pour
            publier et utiliser le contenu dans votre Contenu utilisateur, vous pouvez envoyer au Responsable des droits
            d’auteur un avis de contestation par écrit comprenant les informations suivantes : (i) votre signature
            électronique ou physique ; (ii) l’identification du contenu qui a été supprimé ou dont l’accès a été
            désactivé et l’endroit où le contenu apparaissait avant ces opérations ; (iii) une déclaration par laquelle
            vous estimez de bonne foi que le contenu a été supprimé ou rendu indisponible par erreur ou à cause d’une
            identification erronée du contenu ; et (iv) votre nom, adresse, numéro de téléphone et adresse électronique,
            une déclaration par laquelle vous acceptez la compétence de la cour fédérale du District Nord de Californie
            et une déclaration par laquelle vous acceptez la signification d’un acte de procédure de la personne qui a
            fourni la notification de l’atteinte présumée.
            <br />
            <br /> Si le Responsable des droits d’auteur reçoit un avis de contestation, GlobalEnglish enverra une copie
            de l’avis de contestation à la partie plaignante pour l’informer de la possibilité de remplacer le contenu
            supprimée ou cesser de le bloquer dans un délai de 10 jours ouvrables. À moins que le propriétaire des
            droits d’auteur ne dépose une action judiciaire contre le fournisseur du contenu, le membre ou
            l’utilisateur, le contenu supprimé peut être remplacé ou rétabli, dans un délai de 10 à 14 jours ouvrables
            ou plus suite à la réception de l’avis de contestation, à notre discrétion exclusive.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            {' '}
            9. UTILISATION INTERNATIONALE ET CONTRÔLES DES EXPORTATIONS{' '}
          </b>
          <br />
          <br /> En reconnaissant l’aspect mondial de l’internet, vous acceptez de vous conformer aux lois locales
          concernant la conduite en ligne avec un Contenu Utilisateur acceptable. Vous acceptez de vous conformer aux
          lois applicables sur la diffusion de l’information technique provenant des États-Unis ou du pays où vous
          résidez.
          <br />
          <br /> Vous confirmez que les Services, ou une partie de ceux-ci, peuvent faire l’objet des lois sur le
          contrôle des exportations des États-Unis. Vous acceptez de ne pas exporter, réexporter ou transférer aucune
          partie du Service ou des informations ou documents techniques de manière directe ou indirecte en violation de
          toute loi ou règlementation sur l’exportation en vigueur. Vous déclarez et garantissez que vous n’êtes pas
          citoyen ou résident d’un pays qui fait l’objet d’un embargo imposé par le Gouvernement américain ou qui a été
          désigné par le Gouvernement américain en tant que pays « qui soutient les terroristes » et que vous n’êtes pas
          sur liste du Gouvernement américain concernant les parties faisant l’objet des interdictions ou des
          restrictions.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. INJONCTION </b>
          <br />
          <br /> Vous confirmez que toute utilisation du Service contraire aux Conditions d’utilisation est de nature à
          causer des dommages irréparables à GlobalEnglish, à ses filiales, concédants, sous-traitants, revendeurs et
          Utilisateurs. Dans ces circonstances, GlobalEnglish, ses filiales, concédants, sous-traitants, revendeurs et
          Utilisateurs seront en droit d’exiger une compensation équitable sans dépôt d’une caution ou autre garantie
          financière, y compris, sans s’y limiter, une injonction provisoire et permanente.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11. DURÉE D’INDISPONIBILITÉ </b>
          <br />
          <br /> Les Services pourraient être temporairement indisponibles de temps en temps pour maintenance ou
          d’autres raisons. GlobalEnglish décline toute responsabilité pour l’interruption, le retard d’exécution ou de
          transmission, le vol ou la destruction, l’accès non autorisé ou la modification des communications de
          l’Utilisateur ou de tout autre contenu rendu disponible sur le site Web.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12. STIPULATION D’EXONÉRATION DE GARANTIES </b>
          <br />
          <br />
          Les Services pourraient être temporairement indisponibles de temps en temps pour maintenance ou d’autres
          raisons. GlobalEnglish décline toute responsabilité pour l’interruption, le retard d’exécution ou de
          transmission, le vol ou la destruction, l’accès non autorisé ou la modification des communications de
          l’Utilisateur ou de tout autre contenu rendu disponible par l’entremise des Services. GlobalEnglish ne saurait
          être tenue responsable à quelque titre que ce soit pour des blessures ou décès découlant de l’utilisation des
          Services, du Contenu utilisateur ou du Contenu des tiers, ou de toute utilisation des produits ou services
          fournis par les Utilisateurs.
          <br />
          <br /> VOUS COMPRENEZ ET ACCEPTEZ QUE :
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            {' '}
            (a) LES SERVICES, Y COMPRIS TOUT LE CONTENU DE CEUX-CI, SONT FOURNIS « TELS QUELS » ET TELS QUE DISPONIBLES.
            <br />
            <br /> (b) GLOBALENGLISH SE DÉGAGE DE TOUTE RESPONSABILITÉ QUANT AUX DÉCLARATIONS ET GARANTIES, EXPRESSES OU
            IMPLICITES, RELATIVES AUX SERVICES, Y COMPRIS AUX GARANTIES DE TITRE, QUALITÉ MARCHANDE, ADAPTATION À UN
            USAGE PARTICULIER OU ABSENCE DE CONTREFAÇON.
            <br />
            <br /> (c) GLOBALENGLISH NE GARANTIT ET NE PROMET NI DE RÉSULTATS SPÉCIFIQUES DÉCOULANT DE L’UTILISATION DU
            SERVICE NI QUE LES SERVICES RÉPONDRONT À VOS EXIGENCES OU ATTENTES.
            <br />
            <br />
            (d) GLOBALENGLISH NE DÉCLARE ET NE GARANTIT PAS QUE LE LOGICIEL, LE CONTENU OU LES MATÉRIELS DES SERVICES OU
            DU SITE WEB SONT PRÉCIS, COMPLETS, FIABLES, ACTUELS OU EXEMPTS D’ERREURS OU QUE LE SITE WEB OU LES SERVICES,
            OU LEURS SERVEURS, SONT EXEMPTS DE VIRUS OU D’AUTRES ÉLÉMENTS NOCIFS. PAR CONSÉQUENCE, SANS RESTREINDRE LA
            PORTÉE GÉNÉRALE DE CE QUI PRÉCÈDE, VOUS COMPRENEZ ET ACCEPTEZ QUE SI VOUS TÉLÉCHARGEZ OU AUTREMENT OBTENEZ
            DU CONTENU, DES MATÉRIELS, DES DONNÉES OU DES LOGICIELS DEPUIS OU PAR L’ENTREMISE DES SERVICES OU DEPUIS UN
            FOURNISSEUR, À VOTRE DISCRÉTION ET RISQUE, VOUS SEREZ ENTIÈREMENT RESPONSABLE DE LEUR UTILISATION ET DE TOUT
            DOMMAGE CAUSÉ À VOTRE SYSTÈME INFORMATIQUE, DE TOUTE PERTE DE DONNÉES OU DE TOUT AUTRE PRÉJUDICE POUVANT EN
            RÉSULTER.
            <br />
            <br /> (e) GLOBALENGLISH NE GARANTIT PAS QUE LES ERREURS OU LES DÉFAUTS DES SERVICES SERONT CORRIGÉS.
            <br />
            <br />
            (f) GLOBALENGLISH N’EST PAS RESPONSABLE DE LA CONDUITE DES UTILISATEURS, DU CONTENU DES TIERS ET DU CONTENU
            UTILISATEUR.
            <br />
            <br />
            (g) Les Services pourraient être temporairement indisponibles de temps en temps pour maintenance ou d’autres
            raisons. GlobalEnglish décline toute responsabilité pour l’interruption, le retard d’exécution ou de
            transmission, le vol ou la destruction, l’accès non autorisé ou la modification des communications de
            l’Utilisateur ou de tout autre contenu rendu disponible par l’entremise des Services. GlobalEnglish ne
            saurait être tenue responsable à quelque titre que ce soit pour des blessures ou décès découlant de
            l’utilisation des Services, du Contenu utilisateur ou du Contenu des tiers, ou de toute utilisation des
            produits ou services fournis par les Utilisateurs.
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. LIMITATION DE RESPONSABILITÉ</b>
          <br />
          <br /> GLOBALENGLISH OU SES DIRIGEANTS, EMPLOYÉS, REPRÉSENTANTS, CONCÉDANTS OU SOUS-TRAITANTS NE SERONT EN
          AUCUN CAS RESPONSABLES ENVERS VOUS OU DES TIERS DES DOMMAGES INDIRECTS, CONSÉCUTIFS, EXEMPLAIRES, ACCESSOIRES,
          SPÉCIAUX OU PUNITIFS, Y COMPRIS DE TOUTE PERTE DE PROFITS OU DE DONNÉES DÉCOULANT : (I) DE VOTRE UTILISATION
          OU INCAPACITÉ À UTILISER LES SERVICES, OU QUELQUE PART DU CONTENU OU D’AUTRES MATÉRIELS CONSULTÉS OU
          TÉLÉCHARGÉS SUR LE SITE WEB, LES SERVICES OU LES FOURNISSEURS, (II) DU COÛT D’ACQUISITION DE PRODUITS ET
          SERVICES ÉQUIVALENTS DÉCOULANT DES PRODUITS, DONNÉES, INFORMATIONS OU SERVICES ACQUIS OU OBTENUS OU DES
          MESSAGES REÇUS OU DES TRANSACTIONS RÉALISÉES PAR L’ENTREMISE OU DEPUIS LE SITE WEB ; (III) DE L’ACCÈS NON
          AUTORISÉ À VOS TRANSMISSIONS OU DONNÉES OU DE L’ALTÉRATION DE CELLES-CI PAR QUELQUE PARTIE QUE CE SOIT ; (IV)
          DES DÉCLARATIONS OU DE LA CONDUITE DE TOUT TIERS SUR LE SITE WEB ; OU (V) DE TOUT AUTRE ASPECT LIÉ AUX
          SERVICES. GLOBALENGLISH N’ENGAGE EN AUCUN CAS L’ENTIÈRE RESPONSABILITÉ ENVERS VOUS POUR DES DOMMAGES, PERTES
          OU CAUSES D’ACTION DÉPASSANT LE MONTANT QUE VOUS AVEZ PAYÉ À GLOBALENGLISH AU COURS DES DERNIERS DOUZE (12)
          MOIS, OU, AU CAS D’UN MONTANT SUPÉRIEUR, CENT DOLLARS (100$), BIEN QUE GLOBALENGLISH SOIT CONSCIENTE OU AIT
          ÉTÉ AVISÉE DE LA POSSIBILITÉ DESDITS DOMMAGES. L’EXISTENCE DE PLUSIEURS RÉCLAMATIONS RELATIVES AUX PRÉSENTES
          N’AUGMENTERA PAS CETTE LIMITE.
          <br />
          <br /> DANS CERTAINES JURIDICTIONS, L’EXCLUSION DE CERTAINES GARANTIES OU LA LIMITATION OU L’EXCLUSION DE
          RESPONSABILITÉ NE SONT PAS PERMISES POUR LES DOMMAGES ACCESSOIRES OU CONSÉCUTIFS. PAR CONSÉQUENT, CERTAINES
          DES LIMITATIONS SUSMENTIONNÉES PEUVENT NE PAS VOUS CONCERNER.
          <br />
          <br /> SI VOUS N’ÊTES PAS SATISFAIT PAR UNE PARTIE QUELCONQUE DU SITE WEB OU PAR LES CONDITIONS D’UTILISATION
          DU SITE WEB, VOTRE SEUL ET UNIQUE RECOURS CONSISTE À CESSER D’UTILISER LE SITE WEB.
          <br />
          <br /> GLOBALENGLISH NE SERA RESPONSABLE EN AUCUN CAS DES BLESSURES OU DÉCÈS DÉCOULANT DE L’UTILISATION DES
          SERVICES OU DU CONTENU DES TIERS OU DU CONTENU UTILISATEUR.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14. DÉLAI DE PRESCRIPTION </b>
          <br />
          <br />
          Vous acceptez que, malgré un statut ou une loi quelconque à l’effet contraire, toute réclamation ou cause
          d’action contre GlobalEnglish découlant de votre utilisation des Services ou s’y rapportant doit être déposée
          dans un délai d’un (1) an suivant la survenance de ladite réclamation ou cause, sinon la réclamation ou
          l’action sera définitivement prescrite.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>15. INDEMNISATION </b>
          <br />
          <br />
          Vous acceptez d’indemniser, tenir à couvert et exonérer GlobalEnglish, ses filiales et leurs directeurs,
          employés, dirigeants et représentants de toute responsabilité découlant des réclamations, actions, coûts,
          obligations, pertes, dommages, dépenses, y compris des frais d’avocat dans une limite raisonnable, des tiers
          contre GlobalEnglish en raison de ou découlant de votre utilisation du site Web, de votre Contenu utilisateur,
          de votre connexion au site Web ou aux Services, de votre atteinte aux présentes Conditions ou aux droits d’un
          autre Utilisateur ou de toute loi ou autrement découlant de votre utilisation des Services ou s’y rapportant.
          Vos obligations d’indemnisation comprennent, sans limitation, les réclamations contre GlobalEnglish
          conformément auxquelles le Contenu utilisateur atteint aux droits de propriété intellectuelle d’un tiers.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>16. RÉSILIATION </b>
          <br />
          <br /> Si votre souscription est résiliée pour quelque raison que ce soit, GlobalEnglish va désactiver votre
          accès aux Services. Vous n’aurez plus accès à aucun Contenu, y compris votre Contenu utilisateur, bien que des
          copies desdites données restent dans notre système pour une période de trois (3) ans à des fins de sauvegarde.
          <br />
          <br /> Si vous demandez que GlobalEnglish supprime vos Données personnelles et le Contenu utilisateur, nous
          déployons tous les efforts raisonnables pour respecter votre choix et nous allons vous confirmer une fois
          l’opération terminée.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>17. RENONCIATION ET DIVISIBILITÉ </b>
          <br />
          <br /> L’omission de GlobalEnglish d’exercer ou d’appliquer un droit ou une disposition quelconque des
          présentes Conditions ne constitue pas une renonciation audit droit ou à ladite provision, dans ce cas ou dans
          tout autre cas. Si l’une des dispositions des présentes Conditions se révèle illégale, nulle ou inapplicable
          pour quelque raison que ce soit, elle n’aura aucun effet sur la validité et l’applicabilité des autres
          dispositions.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>18. INTÉGRALITÉ DU CONTRAT </b>
          <br />
          <br /> Le présent Contrat constitue l’accord intégral entre vous et GlobalEnglish en ce qui concerne
          l’utilisation des Services et annule toute entente et tout accord entre vous et GlobalEnglish concernant votre
          utilisation des Services.
          <br />
          <br /> Nonobstant la portée générale de ce qui précède, au cas où votre Entreprise a un Accord en vigueur avec
          GlobalEnglish pour l’acquisition des Services, ces Conditions n’ont pas pour objet de modifier les conditions
          dudit accord entre votre Entreprise et GlobalEnglish, ses subsidiaires ou filiales, et dans la mesure de leur
          incompatibilité, les conditions dudit accord prévaudront.
          <br />
          <br /> Le présent Contrat a été rédigé en anglais et la version anglaise aura la prévalence à tous égards, et
          la version non-anglaise de ce Contrat sert exclusivement à des fins d’adaptation. Une version imprimée du
          présent contrat et de tout avis donné sous forme électronique seront admissibles devant les instances
          judiciaires ou administratives sur la base de ce contrat ou s’y rapportant dans la même mesure et sous réserve
          des mêmes conditions que les autres documents commerciaux et documents d’archive initialement produits et
          conservés sous forme imprimée. Les titres des articles des présentes Conditions sont fournis à des fins de
          commodité et n’ont aucun effet légal ou contractuel.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>19. LOI APPLICABLE, FORUM ET ARBITRAGE</b>
          <br />
          <br />
          Les présentes Conditions seront régies par les lois de l’État de Californie sans égard aux dispositions
          concernant les conflits des lois.
          <br />
          <br /> Au choix et à la discrétion exclusive de GlobalEnglish, les conflits, réclamations ou controverses
          découlant des présentes Conditions ou du Site Web ou s’y rapportant, et qui ne sont pas réglés par accord
          mutuel pourraient être réglés par arbitrage exécutoire mené devant JAMS, ou son successeur. Sauf convention
          contraire des parties, l’arbitrage aura lieu à San Francisco, Californie, devant un arbitre unique convenu par
          les parties, ou si les parties ne peuvent pas parvenir à un accord, un arbitre unique nommé par JAMS.
          L’arbitrage sera mené en conformité avec les règles et règlementations de JAMS, sauf disposition expresse
          contraire aux présentes Conditions. L’arbitrage doit débuter dans un délai de quarante-cinq (45) jours de la
          date à laquelle une demande écrite d’arbitrage a été soumise par l’une des parties. La décision et le jugement
          de l’arbitre seront rédigés et transmis dans un délai de soixante (60) jours de la fin de la procédure
          d’arbitrage et dans un délai de six (6) mois de la sélection de l’arbitre. L’arbitre n’aura pas le pouvoir
          d’accorder des dommages en sus de la limite des dommages réels, compensatoires et directs prévus dans les
          Conditions et ne peut pas multiplier les dommages réels ou les dommages punitifs ou tous autres dommages qui
          sont expressément exclus en vertu des Conditions, et chaque partie renonce irrévocablement auxdits dommages. À
          sa discrétion, l’arbitre peut évaluer les coûts et les dépenses (y compris les frais judiciaires raisonnables
          de la partie gagnante) contre toute partie à la poursuite. Toute partie qui refuse de se conformer à un ordre
          des arbitres sera responsable des coûts et dépenses, y compris les frais d’avocat, encourus par l’autre partie
          en vue de l’exécution de la sentence arbitrale. Nonobstant la portée générale de ce qui précède, dans le cas
          d’une injonction provisoire ou préliminaire, l’une ou l’autre des parties peut porter la cause devant un
          tribunal judiciaire, sans arbitrage préalable, afin d’éviter un dommage immédiat et irréparable. Les
          dispositions de cette clause d’arbitrage seront exécutoires par tout tribunal compétent.
          <br />
          <br /> En ce qui concerne les disputes ou les réclamations qui ne font pas l’objet de l’arbitrage, comme
          indiqué ci-dessus, vous et GlobalEnglish convenez de vous soumettre à la compétence personnelle et exclusive
          des juridictions fédérales et étatiques de San Francisco, Californie.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. AVIS ET CONTACTS </b>
          <br />
          <br />
          Vous pouvez demander la fermeture de votre compte GlobalEnglish Service en donnant avis de résiliation à
          GlobalEnglish par le biais du formulaire « Contactez-nous » à
          <a href="https://support.globalenglish.com/" rel="noopener noreferrer" target="_blank">
            https://support.globalenglish.com/
          </a>
          . Votre accès aux Services et l’utilisation de ceux-ci, y compris de tout contenu y afférent, peuvent être
          interdits par GlobalEnglish en tout temps suite à la fermeture de votre compte.
          <br />
          <br /> Les avis à votre attention peuvent vous être transmis par courriel ou par courrier ordinaire. Le site
          Web peut aussi vous transmettre des avis sur les modifications des présentes Conditions ou sur autres aspects
          en affichant des avis ou des liens vers les avis, généralement sur le Service.
          <br />
          <br /> Les avis visant notre Politique de confidentialité peuvent être envoyés à
          <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
            privacy@globalenglish.com.
          </a>
          <br />
          <br /> Toutes mentions légales ou autres communications à GlobalEnglish doivent être transmises à l’adresse
          électronique suivante :
          <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
            legal@globalenglish.com
          </a>{' '}
          ou à l’adresse physique suivante :
          <br />
          <br /> 1875 S Grant Street, Suite 700
          <br /> San Mateo, CA 94402
          <br /> à l’attention du Conseiller juridique
          <br />
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>DISPOSITIONS SPÉCIFIQUES CONCERNANT LES TRANSACTIONS B2B (transactions marchand-consommateur) </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          {' '}
          Si vous avez créé un compte avec GlobalEnglish par l’entremise de votre employeur, votre utilisation de nos
          Services fera également l’objet de notre contrat avec votre société (une « Société »). Les Conditions
          d’utilisation ne sont pas conçues pour modifier les conditions du contrat avec votre Société. Vous ne pouvez
          pas utiliser (ou continuer d’utiliser) les Services si votre Société ne vous autorise pas comme utilisateur
          final de leur licence avec GlobalEnglish. Si vous nous demandez de fermer votre compte, l’accord de votre
          Société pourrait être nécessaire avant que GlobalEnglish ferme votre compte.
          <br />
          <br /> Vous acceptez d’utiliser les Services uniquement dans la mesure permise par votre Société. Vous
          pourriez avoir l’occasion de communiquer avec d’autres personnes à l’intérieur de votre Société ou de
          télécharger des documents relatifs à votre Société par l’entremise des Services, mais cela ne vous est pas
          permis et vous ne pouvez pas le faire sans l’autorisation de votre Société.
          <br />
          <br /> Vous confirmez et acceptez que toutes les données générées par votre utilisation de nos Services
          (« Données de l’utilisateur ») pourraient être accessibles par votre Société et que GlobalEnglish ne sera pas
          responsable envers vous sur la base des informations partagées avec votre Société comme résultat de votre
          utilisation de nos Services.
          <br />
          <br /> Puisque votre souscription pour accéder aux Services ne continue que pour la durée de la licence de la
          société, si la licence de votre Société avec GlobalEnglish est résiliée, vous perdrez l’accès aux Services et
          au Contenu utilisateur. GlobalEnglish peut fermer ou suspendre le compte de votre Société, votre compte ou la
          possibilité d’utiliser les Services, en tout ou en partie, sans préavis, au cas où (i) votre Société manque
          d’acquitter ses frais à GlobalEnglish, à leur échéance, en vertu des présentes, ou (ii) vous ou votre Société
          enfreignez les Conditions ou toutes autres règles régissant l’utilisation des Services.
          <br />
          <br /> GlobalEnglish pourrait avoir l’obligation de retourner à votre Société toutes les données relatives à
          votre utilisation des Services, y compris vos Données personnelles et le Contenu utilisateur. Si votre Société
          ne sollicite pas que lesdites données soient retournées ou supprimées, GlobalEnglish va conserver les données
          pour une période de trois (3) ans suite à l’expiration ou à la résiliation du contrat avec votre employeur.
          Dans ce cas, vous pouvez acheter une souscription au service auprès de GlobalEnglish pour continuer d’avoir
          accès au programme sans aucune perte d’informations de suivi. Vous pouvez autoriser le transfert de vos
          informations de suivi et de profile à une nouvelle souscription avec votre nouvel employeur. Vous pouvez aussi
          demander toutes les données relatives à votre utilisation des Services dans un format compatible avec les
          importations futures.
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>DISPOSITIONS SPÉCIFIQUES CONCERNANT LES TRANSACTIONS B2C (transactions entreprise-consommateur) </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          Vous pouvez aussi passer un accord de souscription avec GlobalEnglish en tant que personne physique, ce qui
          pourrait comprendre une souscription de trois (3) ou six (6) mois ou un plan de souscription annuel ou
          pluriannuel (chacun, un « Plan de souscription »).
          <br />
          <br /> Le Plan de souscription applicable vous sera facturé mensuellement ou annuellement, le ou
          approximativement le même jour de chaque mois/an (le cas échéant) jusqu’à ce que vous annulez votre Plan de
          souscription. Pour éviter toute ambiguïté, veuillez noter que vous n’aurez pas le droit d’annuler, de réduire
          le nombre de sièges ou de déclasser le Service que vous avez choisi avant l’anniversaire de votre Plan de
          souscription. Les paiements pour le Plan de souscription ne seront pas remboursés.
          <br />
          <br /> Les frais applicables au Service (« Frais ») sont disponibles sur le site Web et/ou dans la liste de
          prix de GlobalEnglish alors en vigueur. Le prix indiqué pour le Service ne comprend ni taxes, ni frais, sauf
          disposition contraire. Vous êtes responsable de toutes taxes et de tous autres frais occasionnés par
          l’utilisation des Services (par exemple, les frais relatifs aux données et les règlements en monnaie
          étrangère). Vous payerez les Frais dans la monnaie indiquée par GlobalEnglish pour votre compte. GlobalEnglish
          se réserve le droit de modifier la monnaie indiquée en tout temps.
          <br />
          <br />
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}> Paiement </b>
          <br />
          <br /> Le paiement doit être effectué lors de l’achat d’une licence de souscription. Nous avons signé des
          partenariats avec PayPal et Stripe pour le traitement de vos paiements. Nous ne recevons ou ne traitons pas
          d’informations de carte de crédit ou de toute autre méthode de paiement que vous indiquez auxdits fournisseurs
          de services.
          <br />
          <br /> Tous les Services sont prépayés pour la période sélectionnée (mensuellement, annuellement ou autrement)
          et sont non-remboursables. Cela comprend les comptes renouvelés.
          <br />
          <br /> -----
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>Avis aux utilisateurs de Californie</b>
          <br />
          <br /> Si vous êtes un résident de Californie, le Code Civil de Californie, Article 1789.3 vous permet de
          solliciter des informations concernant la divulgation de vos Données personnelles à des tiers à des fins
          commerciales directes des tiers. La California Consumer Privacy Act (Loi de Californie sur la vie privée des
          consommateurs) qui prendra effet en 2021 (Code Civil, Articles 1798.100 à 1709.198) vous donnes aussi certains
          droits :
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            (a) le droit de savoir lesquelles de vos Données personnelles ont été collectées, et d’en recevoir des
            copies,
            <br />
            <br />
            (b) le droit de suppression,
            <br />
            <br />
            (c) le droit de savoir à quels tiers vos Données personnelles auraient été vendues et le droit de demander
            la cessation de ladite vente, et
            <br />
            <br />
            (d) le droit de ne pas être soumis(e) à la discrimination lors de l’exercice de vos droits de consommateur.{' '}
          </span>
          <br /> De plus, le California Business and Profession Code (Code commercial et professionnel de Californie),
          article 22581 permet à présent aux résidents mineurs de Californie de solliciter la suppression du contenu
          accessible publiquement sur le site Web. Cependant, veuillez noter que GlobalEnglish ne peut pas assurer la
          suppression complète ou compréhensive du contenu que vous avez affiché et qu’il pourrait y avoir des
          situations où la loi n’exige ou ne permet pas la suppression, même si cela est demandé.
          <br />
          <br />
          Si vous voulez faire une demande pour exercer vos droits :
          <br />
          <br /> Le fournisseur du site Web et des services connexes est GlobalEnglish Corporation, ayant le siège à
          1875 South Grant Street, Suite 700, San Mateo, CA 94402, USA. L’adresse électronique est :
          privacy@globalenglish.com.
          <br /> En date des présentes Conditions, aucun frais n’est imputé au consommateur par le site Web. Si vous
          avez une réclamation concernant les services fournis sur le site Web ou si vous souhaitez demander plus
          d’informations, veuillez nous contacter à l’adresse physique ci-dessus avec une description de votre demande.
          Nous ne pouvons pas garantir une réponse dans un certain délai. Si vous n’êtes pas satisfait(e), veuillez
          contacter The Complaint Assistance Unit of the Division of Consumer Services (L’unité d’assistance aux
          plaintes du département pour les services de protection du consommateur) du California Department of Consumer
          Affairs (Ministère de la protection du consommateur de Californie) par écrit à 1625 North Market Blvd., Suite
          N 112, Sacramento, CA 95834, ou par téléphone au (916) 445-1254 ou (800) 952-5210.
        </p>
      </div>
    </div>
  );
}

export default FrFrContent;
