import Cookies from "universal-cookie";
import EnContent from "utils/translations/PrivacyPolicy/en";
import CzCzContent from "utils/translations/PrivacyPolicy/cz-cz";
import DeDeContent from "utils/translations/PrivacyPolicy/de-de";
import EsMxContent from "utils/translations/PrivacyPolicy/es-mx";
import EsEsContent from "utils/translations/PrivacyPolicy/es-es";
import FrFrContent from "utils/translations/PrivacyPolicy/fr-fr";
import ItItContent from "utils/translations/PrivacyPolicy/it-it";
import JaJpContent from "utils/translations/PrivacyPolicy/ja-jp";
import KoKoContent from "utils/translations/PrivacyPolicy/ko-ko";
import PlPlContent from "utils/translations/PrivacyPolicy/pl-pl";
import PtBrContent from "utils/translations/PrivacyPolicy/pt-br";
import RuRuContent from "utils/translations/PrivacyPolicy/ru-ru";
import TrTrContent from "utils/translations/PrivacyPolicy/tr-tr";
import ZhCnContent from "utils/translations/PrivacyPolicy/zh-cn";
import ZhTwContent from "utils/translations/PrivacyPolicy/zh-tw";

const cookie = new Cookies();
const language = cookie.get("ulang") ? cookie.get("ulang").toLowerCase() : "en";

const content = {
  en: EnContent(),
  "cz-cz": CzCzContent(),
  "de-de": DeDeContent(),
  "es-mx": EsMxContent(),
  "es-es": EsEsContent(),
  "fr-fr": FrFrContent(),
  "it-it": ItItContent(),
  "ja-jp": JaJpContent(),
  "ko-ko": KoKoContent(),
  "pl-pl": PlPlContent(),
  "pt-br": PtBrContent(),
  "ru-ru": RuRuContent(),
  "tr-tr": TrTrContent(),
  "zh-cn": ZhCnContent(),
  "zh-tw": ZhTwContent(),
};

function PrivacyPolicy() {
  return content[language.toLowerCase()];
}

export default PrivacyPolicy;
