import React from "react";

export interface ButtonFieldProps {
  name: string;
  value: any;
  id: string;
  onClickFn: () => void;
  disableBtn?: boolean;
  type:string;
  isLoading:any;
}

export const ButtonField: React.SFC<ButtonFieldProps> = (props:ButtonFieldProps) => {
  return (
    <div className="login_button">
      <span
        className="button"
        style={{ display: "block", width: "100%", margin: 0 }}
      >
        <button
          className="buttonIn"
          style={
            props.disableBtn ? { opacity: "0.50", cursor: "not-allowed" } : {}
          }
          id={props.id}
          name={props.name}
          onClick={props.onClickFn}
          disabled={props.disableBtn}
        >
          {props.value}
        </button>
      </span>
    </div>
  );
};
