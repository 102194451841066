// @ts-nocheck
import React from "react";

function EsEsContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>Copyrights, marcas registradas, y otras advertencias legales</b>
          </center>
        </div>
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>COPYRIGHT DE GLOBALENGLISH</b>
          </p>
          <p />
          <p>
            {" "}
            Copyright © 1999-2013 GlobalEnglish Corporation, reservados todos
            los derechos.{" "}
          </p>
          <br />
          <p>
            {" "}
            <b> COPYRIGHT DE TERCEROS</b>{" "}
          </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>, Primera edición, 1995
            <br /> © Longman Group UK Limited 1995{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> © Longman Group Limited 1995{" "}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p*/}
          <p>
            {" "}
            Copyright © 1995 - 1998 Macromedia, Inc., reservados todos los
            derechos{" "}
          </p>
          <p>
            {" "}
            Copyright © 1999 Microsoft Corporation, reservados todos los
            derechos{" "}
          </p>
          <p>
            {" "}
            Copyright © 1999 Sipro Lab Telecom Inc., reservados todos los
            derechos{" "}
          </p>
          <p>
            {" "}
            Copyright © Business English Pod Limited, reservados todos los
            derechos Todo el contenido, incluidos los podcasts, phrasecasts,
            videocasts, apuntes y audiolibros, son propiedad de Business English
            Pod Limited, y se utiliza con la autorización de GlobalEnglish
            Corporation.{" "}
          </p>
          <p>
            {" "}
            WGrammar Grammar Checker Engine Copyright © 1998-2006 Wintertree
            Software Inc. ({" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.wintertree-software.com"
            >
              <div
                onmouseout="this.className='blu11'"
                onmouseover="this.className='blu11rllover'"
                className="blu11"
              >
                www.wintertree-software.com
              </div>
            </a>
            ){" "}
          </p>
          <p>
            {" "}
            Incluye el software de reconocimiento de voz EduSpeak<sup>
              ®
            </sup>{" "}
            bajo licencia de SRI International
            <sup>®</sup>. Copyright © SRI International.{" "}
          </p>
          {/*p> Systran Translation Engine &copy; SYSTRAN Software, Inc </p*/}
          <p>
            {" "}
            Las fotografías proporcionadas por AP/Wide World Photos están
            protegidas por copyright y son propiedad de la Associated Press.
            Queda prohibido su uso sin el consentimiento previo de AP/Wide World
            Photos. Se emprenderán acciones legales contra cualquier infracción.{" "}
          </p>
          <p>
            {" "}
            Las fotografías proporcionadas por PhotoDisc están protegidas por
            copyright y son propiedad de PhotoDisc, Inc. Images © copyright 1999
            PhotoDisc, Inc.{" "}
          </p>
          <p>
            {" "}
            Copyright © Barron's Educational Series, Inc., de HOW TO PREPARE FOR
            THE TOEIC<sup>®</sup>{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i>© 1998 AMACOM NEW MEDIA, empresa filial
            propiedad de American Management Association, Nueva York. Reservados
            todos los derechos.{" "}
          </p>
          <p>
            {" "}
            Las imágenes prediseñadas proporcionadas por Microsoft están
            protegidas por copyright y son propiedad de Microsoft Corporation o
            sus proveedores. Queda prohibido su uso sin el consentimiento previo
            de Microsoft.{" "}
          </p>
          <p>
            {" "}
            Las imágenes prediseñadas proporcionadas por ArtToday están
            protegidas por copyright y son propiedad de Zedcor, Inc., empresa
            filial propiedad de IMSI Inc. o sus proveedores. Queda prohibido su
            uso sin el consentimiento previo de Zedcor.{" "}
          </p>
          <p>
            {" "}
            Las imágenes prediseñadas proporcionadas por Eyewire están
            protegidas por copyright y son propiedad de Eyewire Inc. o sus
            proveedores. Queda prohibido su uso sin el consentimiento previo de
            Eyewire.{" "}
          </p>
          <p>
            {" "}
            Las fotografías proporcionadas por Corbis están protegidas por
            copyright y son propiedad de Corbis Corporation o sus proveedores.
            Queda prohibido su uso sin el consentimiento previo de Corbis.{" "}
          </p>
          <p>
            {" "}
            Las fotografías proporcionadas por ImageQuest están protegidas por
            copyright y son propiedad de ImageQuest sus proveedores. Queda
            prohibido su uso sin el consentimiento previo de ImageQuest.{" "}
          </p>
          <p />
          <br />
          <p>
            <b>Marcas comerciales de GlobalEnglish</b>
          </p>
          <p />
          <p>
            A continuación se indican algunas de las marcas comerciales
            propiedad de GlobalEnglish Corporation, así como los nombres
            genéricos propuestos en los Estados Unidos y otros países para
            dichas marcas. El hecho de que un nombre o logotipo no aparezca en
            esta lista no constituye una renuncia de los derechos de propiedad
            intelectual que GlobalEnglish Corporation o cualquiera de sus
            empresas filiales poseen sobre dicho logotipo o nombre de producto,
            característica o servicio. Si desea obtener más información sobre
            cómo denominar correctamente los productos y las marcas comerciales
            de GlobalEnglish, solicite una copia de las instrucciones generales
            para marcas comerciales de GlobalEnglish.
          </p>
          <p />
          <br />
          <style
            dangerouslySetInnerHTML={{
              __html: " \t\ttd{padding:10px !important} \t\t",
            }}
          />
          <table
            border={1}
            cellPadding={10}
            cellSpacing={0}
            bordercolor="#808080"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  TradeMark
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Status
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  Enterprise Fluency
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Bloom
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Corporate Learning Service
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Edge
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo Pro
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Magazine
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Mobile Learning Network
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Productivity Toolbar
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP+
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Motivator
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Executive Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>MARCAS COMERCIALES DE TERCEROS</b>
          </p>
          <p />
          <p>
            {" "}
            Adobe y Flash son marcas comerciales o marcas comerciales
            registradas de Adobe Systems Incorporated en los Estados Unidos o en
            otros países.
          </p>
          <p>
            {" "}
            Microsoft es una marca comercial registrada de Microsoft
            Corporation.{" "}
          </p>
          {/*p> Langenscheidt KG es una marca comercial registrada de Langenscheidt KG, Berlin and Munich. </p*/}
          <p>
            {" "}
            Living Language y Colophon son marcas comerciales registradas de
            Random House, Inc.{" "}
          </p>
          <p>
            {" "}
            Sipro y el logotipo de Sipro son marcas comerciales registradas de
            Sipro Lab Telecom Inc. ACELP<sup>®</sup> es una marca comercial
            registrada de la Université Sherbrooke utilizada bajo licencia de
            Sipro Lab Telecom Inc.{" "}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International, el logotipo de SRI International, EduSpeak
                  y el logotipo de EduSpeak son marcas comerciales o marcas
                  comerciales registradas de SRI International.
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p> SYSTRAN es una marca comercial registrada o marca de servicio de SYSTRAN Software, Inc., y el logotipo de SYSTRAN es una marca de servicio registrada de SYSTRAN Software, Inc. </p*/}
          <p>
            {" "}
            AP/Wide World Photos es una marca comercial de la Associated Press.{" "}
          </p>
          <p>
            {" "}
            PhotoDisc es una marca comercial registrada de PhotoDisc, Inc.{" "}
          </p>
          <p>
            {" "}
            Business English Pod, www.businessenglishpod.com, y los logotipos de
            Business English Pod son marcas comerciales de Business English Pod
            Limited.{" "}
          </p>
          <p>
            {" "}
            Todas las marcas comerciales son propiedad de sus respectivos
            titulares.{" "}
          </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default EsEsContent;
