// @ts-nocheck
import React from 'react';

function DeDeContent() {
  return (
    <>
      <div id="agreement_container">
        <div style={{ padding: '1% 5% 0 5%' }}>
          <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
            <b>NUTZUNGSVEREINBARUNG</b>
          </p>
          <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
            <b>EINLEITUNG </b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            Willkommen zu den Dienstleistungen der Webseite bzw. mobilen Applikationen von GlobalEnglish (nachfolgend
            gemeinsam die „Dienstleistungen“).
            <br />
            <br /> Unsere Dienstleistungen sind Englisch-Lernmittel und Onlinelösungen, darunter Daten, Texte,
            Onlinetests, Designs, Videos, Audio-Clips, Sprachaufzeichnungen, schriftliche Posts und Anmerkungen,
            Scripts, Grafiken, Bilder und deren Auswahl und Anordnung, Software, Übungen, Tutorials, Unterricht,
            Coaching, Training, Mitteilungen und interaktive Funktionen, welche durch die Webseite und mobilen
            Applikationen erzeugt bzw. zugänglich gemacht werden (nachfolgend gemeinsam der „Inhalt“). Der Zugriff auf
            unsere Webseite für Informationen über unser Unternehmen und Erkundigung bezüglich unserer Dienstleistungen
            gilt als Teil der Dienstleistungen.
            <br />
            <br /> Bitte lesen Sie die nachstehenden Bedingungen sorgfältig durch und bestätigen Ihr Verständnis und
            Einverständnis mit den nachstehend aufgeführten Bedingungen, bevor Sie fortfahren.
            <br />
            <br />
            <b>
              WENN SIE UNTER 18 JAHREN ALT SIND, LESEN SIE DIESE VEREINBARUNG MIT IHREN ELTERN ODER IHREM VORMUND DURCH
              UND ERHALTEN SIE DEREN/DESSEN ZUSTIMMUNG VOR NUTZUNG UNSERER DIENSTLEISTUNGEN. DURCH NUTZUNG UNSERER
              DIENSTLEISTUNGEN ERKLÄREN SIE, DASS SIE ÜBER 18 JAHRE ALT SIND ODER SOLCH EINE ZUSTIMMUNG VON IHREN ELTERN
              ODER IHREM VORMUND ERHALTEN HABEN UND DIESEN VERTRAG MIT GLOBALENGLISH ABSCHLIESSEN DÜRFEN.{' '}
            </b>
          </p>
          <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
            <b>ALLGEMEINE BEDINGUNGEN</b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 1. RECHTSVERBINDLICHE VEREINBARUNG </b>
            <br />
            <br />
            <b>
              {' '}
              DER ZUGRIFF AUF UNSERE WEBSEITE UND DIE NUTZUNG DER DIENSTLEISTUNGEN VON GLOBALENGLISH IHRERSEITS
              UNTERLIEGEN DER NACHSTEHENDEN NUTZUNGSVEREINBARUNG, DER DATENSCHUTZERKLÄRUNG UND DER COOKIE-RICHTLINIE
              (ALLE DREI DOKUMENTE NACHFOLGEND GEMEINSAM DIE „NUTZUNGSBEDINGUNGEN“). DURCH ZUGRIFF AUF DIE, BESUCH,
              REGISTRIERUNG UND /ODER NUTZUNG DER DIENSTLEISTUNGEN VON GLOBALENGLISH ERKLÄREN SIE, DASS SIE DIE
              NUTZUNGSBEDINGUNGEN UNSERER DIENSTLEISTUNGEN GELESEN UND VERSTANDEN HABEN UND VEREINBAREN, RECHTLICH UND
              UNWIDERRUFLICH DURCH DIESE GEBUNDEN ZU SEIN UND DAS MINDESTALTER ZUR VEREINBARUNG DER VERBINDLICHEN NATUR
              DIESER BEDINGUNGEN UND ZUM ABSCHLUSS EINES RECHTSVERBINDLICHEN VERTRAGES MIT GLOBALENGLISH ERREICHT ZU
              HABEN.{' '}
            </b>
            <br />
            <br /> Eine Übersicht über unsere Richtlinien finden Sie
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              hier
            </a>
            . Diese Richtlinien sind Bestandteil dieser „Nutzungsvereinbarung“ bzw. „Vereinbarung“ und bilden gemeinsam
            die Nutzungsbedingungen.
            <br />
            <br />
            <b>
              Bitte beachten Sie, dass diese Vereinbarung Bestimmungen enthält, welche regeln, wie Forderungen, die wir
              jeweils gegeneinander haben können, beizulegen sind. Siehe insbesondere die nachstehenden Bestimmungen zu
              Gewährleistungsausschluss, Haftungsbegrenzung und Rechtsstreitigkeiten. Sie enthält ebenfalls eine
              Schiedsgerichtsvereinbarung, laut welcher Sie - mit wenigen Ausnahmen - Forderungen Ihrerseits gegen uns
              einem verbindlichen und endgültigen Schiedsverfahren unterwerfen müssen. Sie dürfen Forderungen nur
              individuell geltend machen und nicht als Kläger oder Sammelkläger in einer Sammelklage bzw. Verbandsklage
              oder -Verfahren. Sie dürfen nur auf individueller Basis Entschädigung suchen (inklusive finanzielle
              Entschädigung, Unterlassungsansprüche und Feststellungsklagen).
              <br />
              <br /> BENUTZEN SIE DIE DIENSTLEISTUNGEN NICHT UND NAVIGIEREN SIE UNSERE WEBSEITE NICHT WEITER, WENN SIE
              ZU EINEM BELIEBIGEN ZEITRAUM EINEM TEIL DIESER BEDINGUNGEN NICHT ZUSTIMMEN.
            </b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. ANWENDBARKEIT </b>
            <br />
            <br /> Diese Vereinbarung gilt sowohl für Nutzer unserer Dienstleistungen als auch Besucher auf unseren
            Seiten. Einzelpersonen, die sich direkt bei uns registriert haben (eine
            <b>„B2C-Transaktion“</b>), selbst probeweise, und jene, welche durch oder von ihren Arbeitgebern registriert
            sind (eine
            <b>„B2B-Transaktion“</b>) werden als
            <b>„Nutzer“</b> bezeichnet, wohingegen unregistrierte Nutzer und Besucher unserer Webseiten
            <b>„Besucher“</b> sind. Der Begriff
            <b>“Nutzer”</b> umfasst auch Lehrer, Trainer, Learning-Spezialisten und andere Personen, welche auf die
            GlobalEnglish-Seiten zugreifen, um den anderen Nutzern Dienstleistungen zu bieten. Sowohl Nutzer als auch
            Besucher werden als
            <b>„Sie“</b> bezeichnet.
            <br />
            <br /> Diese Vereinbarung gilt für alle Dienstleistungen von GlobalEnglish.
            <br />
            <br />
            Wenn Sie sich zum ersten Mal als Nutzer registrieren und auf unsere Dienstleistungen zugreifen, werden Sie
            dazu aufgefordert, Ihre Zustimmung zur verbindlichen Natur der hier aufgeführten Bedingungen zu wiederholen,
            indem Sie das Kästchen „Ich stimme zu“ anklicken, oder mittels Ihrer elektronischen Unterschrift oder einer
            anderen Art der Authentifizierung.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>3.ÄNDERUNGEN DER NUTZUNGSBEDINGUNGEN </b>
            <br />
            <br />
            GlobalEnglish kann die Nutzungsbedingungen inklusive dieser Vereinbarung, der Cookie-Richtlinie und der
            Datenschutzerklärung, welche für Ihre Nutzung der Dienstleistungen gelten, jederzeit ändern. Solche
            Änderungen treten sofort bei Mitteilung in Kraft, welche durch Posten innerhalb der Dienstleistungen,
            elektronische Nachricht an Ihre E-Mail Anschrift, welche Sie in Verbindung mit Ihrem Dienstleistungskonto
            bereitgestellt haben (falls zutreffend), oder andere Mittel erfolgen. Wurden Sie durch Ihren Arbeitgeber
            oder ein Unternehmen für unsere Dienstleistungen registriert, kann Ihr Arbeitgeber oder das Unternehmen Sie
            von den Änderungen unserer Nutzungsbedingungen unterrichten.
            <br />
            <br /> Sollten Sie diesen Änderungen nicht zustimmen, können Sie Ihr Konto schließen und die Nutzung unserer
            Webseite einstellen. Ihre fortgesetzte Nutzung unserer Dienstleistungen nach unserer Veröffentlichung oder
            Mitteilung der Änderungen dieser Nutzungsbedingungen gilt jedoch als Ihre Zustimmung zu solchen Änderungen
            der Nutzungsbedingungen.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4. NUTZUNGSBEDINGUNGEN DRITTER </b>
            <br />
            <br />
            Die Dienstleistungen können Links zu anderen Webseiten („Seiten Dritter“) als auch Artikel, Fotos, Texte,
            Übersetzungen, Grafiken, Bilder, Designs, Sound, Videos, Informationen und anderen Inhalt oder Gegenstände
            enthalten, welche Dritten gehören bzw. von Dritten erzeugt wurden (der „Inhalt Dritter“). Solche Seiten
            Dritter und Inhalte Dritter werden von uns nicht auf Richtigkeit, Angemessenheit oder Vollständigkeit
            untersucht, kontrolliert oder geprüft, und wir sind nicht verantwortlich für jegliche Seiten Dritter, auf
            welche durch die Webseite zugegriffen wird, bzw. jeglichen Inhalt Dritter, der auf der Webseite gepostet
            wird bzw. durch die Webseite verfügbar ist.
            <br />
            <br /> Die Aufnahme, Verlinkung oder Genehmigung der Nutzung von Seiten Dritter oder des Inhaltes Dritter
            stellt keine Zustimmung oder Billigung der-/desselben seitens GlobalEnglish dar. GlobalEnglish behält sich
            das Recht vor, diese Links ohne Ankündigung zu beenden. Bezüge auf Dienstleistungen von Unternehmen,
            Organisationen, Produkten oder Dienstleistungen oder andere Informationen stellen keine Billigung,
            Unterstützung oder Empfehlung derselben oder Zugehörigkeit zu diesen seitens GlobalEnglish dar noch werden
            diese impliziert.
            <br />
            <br /> Sollten Sie sich zum Verlassen unserer Webseite und Zugriff auf Seiten Dritter oder Zugriff oder
            Nutzung jeglichen Inhalts Dritter entscheiden, tun Sie dies auf eigene Gefahr und Sie müssen sich bewusst
            sein, dass Ihre Nutzung solcher Seiten Dritter nicht länger von unseren Geschäftsbedingungen und Richtlinien
            geregelt wird. Bitte prüfen Sie die Geschäftsbedingungen und Richtlinien, welche für solche anderen Produkte
            und Dienstleistungen Dritter gelten, vor deren Nutzung.
            <br />
            <br /> Wir bieten mobile Applikationen sowohl durch den Android Market als auch den iTunes Store an
            (nachstehend gemeinsam und mit den Einheiten, welche diese pflegen, „Dienstanbieter“). Ihre Nutzung dieser
            mobilen Applikationen unterliegt ebenfalls den Geschäftsbedingungen der Dienstanbieter, inklusive der in den
            App Store Nutzungsbedingungen (Terms of Service) aufgeführten Benutzungsregeln. Wenn Sie jegliche mobile
            Applikationen vom iTunes Store heruntergeladen haben, bestätigen Sie, dass Sie die App Store
            Nutzungsbedingungen (Terms of Service) gelesen und diesen zugestimmt haben. Haben Sie diese vom Android
            Market heruntergeladen, bestätigen Sie, dass Sie die Android Market Nutzungsbedingungen (Terms of Service)
            gelesen und diesen zugestimmt haben. Die Dienstanbieter sind weder verpflichtet, in Verbindung mit Ihrer
            Nutzung der Dienstleistungen Wartungs- oder Supportleistungen zu bieten noch werden sie dies tun. Die
            Dienstanbieter gewähren keinerlei Garantie für die Dienstleistungen. Sollten mobile Applikationen geltende
            Gewährleistungen nicht erfüllen und haben Sie solche Applikationen vom iTunes Store heruntergeladen, können
            Sie Apple davon informieren, und Apple wird den Kaufpreis solcher Applikationen (falls zutreffend)
            zurückerstatten. Diese Bedingungen gelten für Ihre Nutzung aller mobilen Applikationen als auch unserer
            anderen Dienstleistungen.
            <br />
            <br /> Sie bestätigen und erklären, dass GlobalEnglish für den Inhalt, die Produkte, Dienstleistungen oder
            Leistungsfähigkeit dieser Webseiten oder Applikationen Dritter weder verantwortlich noch haftbar ist. Sie
            verzichten hiermit unwiderruflich auf jeglichen Anspruch gegen GlobalEnglish in Verbindung mit Webseiten
            oder Applikationen Dritter. Insbesondere IST GLOBALENGLISH NICHT FÜR COOKIES, WEB BEACONS, CLEAR GIFS ODER
            ANDERE CODES VERANTWORTLICH, WELCHE SIE ODER ANDERE PARTEIEN AUF IHREM COMPUTER HINTERLASSEN, INKLUSIVE DER
            ASSOZIIERUNG VON WEBSEITENINHALT MIT SOLCHEM CODE. Wenn Sie zum Beispiel auf eine Seite Dritter durch einen
            Link auf der Webseite zugreifen, können Ihr Browser oder andere Cookies auf Ihrem Computer die Versendung
            eines Referrer-Headers an jene Seite verursachen, welcher Teile Ihrer Browsing-History oder die URL, von
            welcher Sie ursprünglich weitergeleitet wurden, angibt. GlobalEnglish ist Ihnen gegenüber in Verbindung mit
            solchen Übertragungen nicht haftbar, unabhängig davon, ob eine solche Übertragung Informationen enthält, die
            mit GlobalEnglish assoziiert werden oder von GlobalEnglish stammen.
            <br />
            <br /> BEI NUTZUNG VON GOOGLE-UNTERSTÜTZTEN ÜBERSETZUNGEN: GOOGLE ÜBERNIMMT WEDER AUSDRÜCKLICHE NOCH
            IMPLIZIERTE GEWÄHR BEZÜGLICH DER ÜBERSETZUNGEN, INKLUSIVE DER GEWÄHR FÜR GENAUIGKEIT, ZUVERLÄSSIGKEIT UND
            JEGLICHE IMPLIZIERTE GEWÄHRLEISTUNG DER HANDELSÜBLICHKEIT, EIGNUNG FÜR EINEN BESTIMMTEN ZWECK UND
            NICHTVERLETZUNG.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>5. KRITERIEN ZUR DIENSTLEISTUNGSBERECHTIGUNG </b>
            <br />
            <br />
            <b>
              5.1 Schutz der Privatsphäre von Kindern und Einschränkungen bei der Nutzung unserer Dienstleistungen.{' '}
            </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Die Dienstleistungen sind nicht von Personen zu benutzen, welche in dem Land, von welchem diese auf die
              Dienstleistung zugreifen, nicht das Mindestalter erreicht haben. „Mindestalter“ bedeutet in der
              Europäischen Union, dem europäischen Wirtschaftsraum und der Schweiz zum Beispiel 16 Jahre, sofern nicht
              anderweitig von konkreten Landesgesetzen bestimmt. In den Vereinigten Staaten beträgt das Mindestalter 13
              Jahre. Jegliche Registrierung, Nutzung oder Zugriff auf die Dienstleistungen von Personen unter dem
              Mindestalter ist untersagt und stellt eine Verletzung dieser Bedingungen dar.
              <br />
              <br /> Durch Nutzung der Dienstleistungen erklären und gewährleisten Sie, dass Sie 13 Jahre oder älter
              sind.
            </span>
            <br />
            <br />
            <b>5.2 Schulen und Lehrer </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Schulen, Bezirke oder Lehrer in den Vereinigten Staaten, welche die Erstellung eines GlobalEnglish-Kontos
              für ihre Studenten unter dem Mindestalter von 13 Jahren wünschen, bestätigen, dass sie für die Einhaltung
              des U.S. Children's Online Privacy Protection Act („COPPA“ - „Gesetz zum Schutz der Privatsphäre von
              Kindern im Internet“) sowie, soweit anwendbar, des Family Educational Rights and Privacy Act („FERPA“)
              verantwortlich sind. Das heißt, dass Schulen, Bezirke oder Lehrer die Eltern bzw. den Vormund der Schüler
              von den personenbezogenen Daten informieren müssen, welche GlobalEnglish sammelt, und die Zustimmung der
              Eltern/des Vormunds vor Erstellung von Konten und Nutzung der Dienstleistungen einholen müssen. Bei
              Einholung einer solchen Zustimmung sollen die Schulen, Bezirke oder Lehrer den Eltern/dem Vormund eine
              Kopie unserer Datenschutzerklärung bereitstellen und die jeweilige Zustimmung aufbewahren und uns auf
              Anfrage zur Verfügung zu stellen. Weitergehende Informationen zur Einhaltung des COPPA, siehe
              <a
                style={{ textDecoration: 'underline' }}
                href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
                rel="noopener noreferrer"
                target="_blank"
              >
                Webseite der Federal Trade Commission.{' '}
              </a>
              <br />
              <br /> Sollten Sie sich außerhalb der Vereinigten Staaten befinden, verlassen wir uns darauf, dass Sie die
              erforderliche Zustimmung oder Genehmigung von den Eltern oder dem Vormund eines jeglichen Schülers
              erhalten, welche ähnlichen Gesetzen unterliegt, und als Voraussetzung für die Nutzung unserer
              Dienstleistungen Ihrerseits und durch Ihre Schüler vereinbaren Sie, dass Sie für die Einhaltung solcher
              Gesetze verantwortlich sind.
            </span>
            <br />
            <br />
            <b>5.3 Zustimmung der Eltern </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              <b>
                DURCH DIE NUTZUNG UNSERER DIENSTLEISTUNGEN ERKLÄREN SIE, DASS SIE ÄLTER ALS 18 JAHRE SIND ODER EINE
                ENTSPRECHENDE ZUSTIMMUNG VON IHREN ELTERN BZW. VORMUND EINGEHOLT HABEN UND DIESEN VERTRAG MIT
                GLOBALENGLISH ABSCHLIESSEN DÜRFEN.
                <br />
                <br /> Wir erwarten, dass eine jegliche Nutzung durch Kinder nur unter Anleitung, Aufsicht und
                Zustimmung ihrer Eltern, ihres Vormunds und/oder den bevollmächtigten Schulvertretern erfolgt.
                GlobalEnglish verlässt sich auf Eltern, Vormunde und Schulen um sicherzustellen, dass Minderjährige die
                Dienstleistungen nur nutzen, wenn sie ihre in diesen Nutzungsbedingungen aufgeführten Rechte und
                Verantwortlichkeiten verstehen.
                <br />
                <br />
                GlobalEnglish kann nach eigenem Ermessen das Angebot der Dienstleistungen an eine Person oder eine
                Organisation verweigern und seine Berechtigungskriterien jederzeit ändern.
              </b>
            </span>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6.DATENSCHUTZ </b>
            <br />
            <br />
            <b>6.1 Datenschutzerklärung </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              {' '}
              Bitte lesen Sie die
              <a
                href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
                rel="noopener noreferrer"
                target="_blank"
              >
                hier
              </a>{' '}
              erhältliche Datenschutzerklärung sorgfältig durch.
              <br />
              <br /> Wir verarbeiten persönliche Daten gemäß der Datenschutzerklärung, welcher Sie beim erstmaligen
              Zugriff auf die Dienstleistungen oder als Teil eines geplanten Updates der Datenschutzerklärung zugestimmt
              haben.
            </span>
            <br />
            <br />
            <b>6.2 Von Ihnen bereitgestellte Daten. </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              {' '}
              Zur Nutzung der Dienstleistungen müssen Sie ein gültiges Konto besitzen. Zur Registrierung für unsere
              Dienstleistungen müssen Sie GlobalEnglish eine Benutzerkennung (User ID), eine E-Mail-Adresse und andere
              Informationen zur Verfügung stellen, welche als personenbezogene Daten („Persönliche Daten“) gelten.
              Einige der von uns über Sie gesammelten Informationen sind dafür notwendig, dass GlobalEnglish die
              Dienstleistungen bereitstellen kann oder Ihnen einfach nur Informationen über die Dienstleistungen senden
              kann, welche Sie angefragt haben, andere sind optional. Die Dienstleistungen ermöglichen es Ihnen auch,
              Informationen über sich selbst oder Ihre Organisation, falls zutreffend, darzustellen.
              <br />
              <br /> Sie vereinbaren: (i) richtige, aktuelle und vollständige Informationen über sich selbst und, im
              Falle einer B2B-Transaktion, über Ihre Organisation zur Verfügung zu stellen, wenn Sie innerhalb der
              Dienstleistungen mittels Registrierungsformular dazu aufgefordert werden; (ii) Ihr Passwort und andere
              Informationen bezüglich der Sicherheit Ihres Kontos geheim zu halten; (iii) die Persönlichen Daten und
              andere Informationen, welche Sie GlobalEnglish zur Verfügung gestellt haben, zu pflegen und unverzüglich
              zu aktualisieren, solche Informationen richtig, aktuell und vollständig zu halten; und (iv) für jegliche
              Nutzung Ihres Kontos und für jegliche Handlungen, die durch Ihr Konto erfolgen, in vollem Maße
              verantwortlich zu sein.
              <br />
              <br /> GlobalEnglish ist nicht dafür verantwortlich, die Richtigkeit der von Ihnen für die Registrierung
              zur Verfügung gestellten Persönlichen Daten zu bestätigen.
            </span>
            <br />
            <br />
            <b>6.3 Internationale Übertragung persönlicher Daten </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Zur Bereitstellung der Dienstleistungen verlässt sich GlobalEnglish auf seine Arbeitskräfte auf der ganzen
              Welt und benutzt zur Aufbewahrung und andere technologiebezogene und Lehrdienstleistungen Fremdanbieter
              und Hosting-Partner. Demzufolge werden Ihre Persönliche Daten verarbeitet und an GlobalEnglish-Mitarbeiter
              und Subunternehmer in unterschiedlichen Ländern übertragen, von denen manche eventuell nicht den gleichen
              Grad des Schutzes Ihrer Persönlichen Daten anbieten, wie in Ihrem Herkunftsland oder Wohnsitzland.
              <b>
                {' '}
                Durch den Zugriff auf unsere Dienstleistungen, die Registrierung und/oder Nutzung unserer
                Dienstleistungen stimmen Sie der Übertragung und Verarbeitung Ihrer Persönlichen Daten in die/den
                unterschiedlichen Länder/n für den Zweck der Dienstleistungen zu, in welchen sich GlobalEnglish, seine
                Tochterunternehmen und Subunternehmer befinden.
              </b>
            </span>
            <br />
            <br />
            <b>6.4 Nachrichten und Mitteilungen. </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Sie verstehen und sind damit einverstanden, dass die Dienstleistungen bestimmte Mitteilungen von
              GlobalEnglish enthalten können, wie zum Beispiel Dienstmitteilungen, administrative Meldungen usw., und
              dass diese Mitteilungen als Teil der Dienstleistungen gelten und Sie deren Erhalt nicht verweigern können.
              <br />
              <br /> Sie stimmen hiermit zu, dass GlobalEnglish Nachrichten und Mitteilungen an Sie folgendermaßen
              übermitteln kann: (i) innerhalb der Dienstleistung; (ii) an die Kontaktinformationen, welche Sie
              GlobalEnglish zur Verfügung gestellt haben (zum Beispiel E-Mail oder Mobiltelefonnummer). Sie vereinbaren,
              Ihre Kontaktinformationen jederzeit auf dem neuesten Stand zu halten.
            </span>
            <br />
            <br />
            <b>6.5 Privatsphäre Anderer </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Wenn Sie während der Nutzung unserer Dienstleistungen Informationen über andere Nutzer erhalten,
              verpflichten Sie sich, diese Informationen nur für den Zweck zu benutzen, für welche Ihnen diese
              bereitgestellt wurden. Sie dürfen diese Benutzerinformationen nicht an Dritte für andere Zwecke als die
              Dienstleistungen offenbaren, verkaufen oder vertreiben. Sofern Sie die Zustimmung des jeweiligen Nutzers
              nicht erhalten haben, dürfen Sie diese Informationen nicht für Vermarktungszwecke benutzen.
            </span>
            <br />
            <br />
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. BENUTZERLIZENZEN </b>
            <br />
            <br />
            <b>7.1Lizenz zur Nutzung unserer Dienstleistungen </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Sie erhalten eine begrenzte, persönliche, einfache, nicht abtretbare, nicht unterlizenzierbare und nicht
              übertragbare Lizenz zum Zugriff auf die und zur Nutzung der Dienstleistungen zur nichtkommerziellen,
              persönlichen Nutzung. GlobalEnglish kann nach eigenem Ermessen vorübergehend oder dauerhaft die
              Dienstleistungen einstellen oder die Funktionen der Dienstleistungen in Abständen ohne vorherige
              Ankündigung ändern.
              <br />
              <br /> Zur Nutzung der Dienstleistungen müssen Sie Zugang zum World Wide Web erhalten, entweder direkt
              oder durch Geräte, die auf webbasierte Inhalte zugreifen. Weiterhin müssen Sie über alle Ausrüstung
              verfügen, die zur Herstellung einer solchen Verbindung zum World Wide Web notwendig ist, inklusive ein
              Computer und Modem oder andere Zugangsgeräte. Sie verpflichten sich, die Nutzung der Dienstleistungen zu
              beurteilen und jegliche damit verbundenen Risiken zu tragen, inklusive jegliches Vertrauen auf die
              Richtigkeit, Vollständigkeit oder Nützlichkeit der von diesen bereitgestellten Inhalte. Weder
              GlobalEnglish noch seine Lizenzgeber garantieren die Richtigkeit oder Vollständigkeit des Inhaltes.
              <br />
              <br /> Die Dienstleistungen sind nicht zur Nutzung in gefährlicher Umgebung bestimmt oder lizenziert (zum
              Beispiel beim Autofahren, im oder in der Nähe von Wasser). Das Recht auf Zugriff auf die Dienstleistungen
              wird widerrufen, wenn die Nutzung der Dienstleistungen verboten ist oder soweit das Angebot, der Verkauf
              oder die Bereitstellung der Dienstleistungen gegen anwendbare Gesetze, Vorschriften oder Bestimmungen
              verstößt.
              <br />
              <br />
              Die Nutzung der Dienstleistungen für jegliche andere Zwecke als den in den Nutzungsbedingungen
              betrachteten ist eine Verletzung der Nutzungsbedingungen und des Urheberrechts und der Eigentumsrechte von
              GlobalEnglish und/oder seinen Lizenzgebern und Subunternehmern. GlobalEnglish hat das Recht zur Sperrung
              oder Auflösung Ihres Kontos und Verweigerung jeglicher gegenwärtigen und zukünftigen Dienstleistungen an
              Sie, wenn GlobalEnglish berechtigten Grund zur Annahme hat, dass Sie die Dienstleistungen unter Verletzung
              dieser Nutzungsbedingungen benutzen. Sie vereinbaren, dass GlobalEnglish für jegliche Änderungen,
              Aussetzungen oder Einstellungen der Dienstleistungen bzw. jeglicher Teile dieser nicht haftbar ist.
            </span>
            <br />
            <br />
            <b>7.2 Benutzerinhalt</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              {' '}
              Die Dienstleistungen geben Ihnen und anderen Nutzern die Gelegenheit, Daten und Informationen zu erstellen
              (z.B. durch Antworten auf unsere Tests) und Materialien auf gemeindebasierte Dienstleistungen
              („Benutzerinhalt“) hochzuladen. Vorbehaltlich der uns in diesen Bedingungen gewährten Rechte behalten Sie
              die vollständigen Eigentumsrechte an Ihrem Benutzerinhalt.
              <br />
              <br /> Durch Hochladen von Benutzerinhalt auf unsere Dienstleistungen gewähren Sie GlobalEnglish eine
              unwiderrufbare, unbefristete, einfache, übertragbare, weltweite Lizenz (mit dem Recht zur
              Unterlizenzierung) zur Aufbewahrung, Nutzung, Reproduktion, öffentlichen Darbietung, Änderung,
              Übersetzung, Erstellung von Auszügen (als Ganzes oder in Teilen), Verarbeitung und Verbreitung eines
              solchen Benutzerinhaltes, wenn dies in Verbindung mit der Bereitstellung der Dienstleistungen notwendig
              ist, um:
              <br />
              <br />
              <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
                (a) den Rechtsweg einzuhalten, unter anderem auf rechtliche Anfragen, richterliche Anordnungen und
                andere ähnliche behördliche Anforderungen zu reagieren;
                <br />
                (b) die Vereinbarung durchzusetzen;
                <br />
                (c) auf Ansprüche zu reagieren, dass der Inhalt Rechte Dritter verletzt;
                <br />
                (d) die Rechte, das Eigentum und die persönliche Sicherheit von GlobalEnglish, seinen Mitarbeitern,
                Nutzern und der Öffentlichkeit zu schützen.
              </span>
              <br />
              <br /> Soweit Sie Benutzerinhalt mit anderen Nutzern der Dienstleistungen teilen, sind Sie verpflichtet,
              diesen Nutzern die Einsicht Ihres Benutzerinhalt zu gewähren und sofern zutreffend, die Zusammenarbeit mit
              Ihnen an solchem Benutzerinhalt zu ermöglichen.
              <br />
              <br /> Beachten Sie, dass GlobalEnglish nicht dazu verpflichtet ist, die Richtigkeit solchen
              Benutzerinhalts zu kontrollieren, zu überprüfen, zu bestätigen, zu korrigieren oder zu löschen.
              GlobalEnglish behält sich jedoch das Recht vor, den Benutzerinhalt zu überwachen und jene Inhalte zu
              entfernen, welche GlobalEnglish nach eigenem Ermessen als anstößig oder anderweitig als Verletzung der
              Nutzungsbedingungen erachtet.
              <br />
              <br />
              GlobalEnglish hat hinsichtlich des Benutzerinhalts keinerlei Backup- oder Aufbewahrungsverpflichtungen.
              Sie sind allein und auf eigene Kosten für die Erstellung von Sicherungskopien und den Ersatz von
              Benutzerinhalt, den Sie auf der Webseite posten oder aufbewahren oder GlobalEnglish bereitstellen,
              verantwortlich.
              <br />
              <br /> Benutzerinhalt gibt nicht zwangsläufig die Ansichten von GlobalEnglish wieder. Ihnen ist bewusst,
              dass Benutzerinhalt die alleinige Verantwortung des Nutzers ist, von welchem solcher Benutzerinhalt
              ausgeht. Sie verstehen, dass Sie durch die Nutzung der Dienstleistungen Benutzerinhalt ausgesetzt werden
              können, der beleidigend, anstößig oder bedenklich ist.
              <br />
              <br /> Zusätzlich zur vorstehend aufgeführten Lizenz zur Gewährung des Benutzerinhaltes bestätigen Sie,
              dass jegliche Fragen, Anmerkungen, Vorschläge, Ideen, Feedback oder andere Informationen über die
              Dienstleistungen („Einreichungen“), die Sie GlobalEnglish bereitstellen, nicht vertraulich sind, und
              GlobalEnglish zur uneingeschränkten Nutzung und Verbreitung dieser Einreichungen für jeglichen,
              wirtschaftlichen oder anderweitigen, Zweck ohne Ihre Anerkennung oder Entschädigung berechtigt ist.
              Weiterhin sind von Ihnen in oder durch gemeindebasierte GlobalEnglish-Dienstleistungen oder Produkte
              gepostete, offenbarte oder geteilte Informationen von der Definition vertraulicher Informationen
              ausgeschlossen, da dieser Begriff allgemein definiert ist.
              <br />
              <br /> Ihnen ist bewusst, dass die technische Verarbeitung und Übertragung der Webseite, inklusive
              Benutzerinhalt, (a) die Übertragung über verschiedene Netzwerke; und (b) Änderungen zur Einhaltung und
              Anpassung an technische Anforderungen verbindender Netzwerke oder Geräte beinhalten kann.
              <br />
              <br />
              <b>
                GlobalEnglish gibt hinsichtlich des Benutzerinhaltes keinerlei Zusicherungen oder Garantien. Der
                Benutzerinhalt stellt nicht die Ansichten von GlobalEnglish dar. GlobalEnglish ist unter keinen
                Umständen für den Benutzerinhalt oder dessen Verlust aus jeglichen Gründen oder Ansprüchen, Schäden oder
                Verlusten verantwortlich oder haftbar, welche aus deren Nutzung oder Verlust entstehen.
              </b>
            </span>
            <br />
            <br />
            <b>7.3 Vorgeschriebenes Nutzerverhalten</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              {' '}
              Sie sind in vollem Maße für alle Aktivitäten verantwortlich, die im Rahmen Ihres Kontos geschehen. Sie
              sind verpflichtet,:
              <br />
              <br />
              <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
                (a) GlobalEnglish von jeder unberechtigten Nutzung Ihres Kontos oder einer jeglichen anderen
                Sicherheitsverletzung unmittelbar zu informieren, und
                <br />
                (b) sicherzustellen, dass Sie sich am Ende einer jeden Sitzung von Ihrem Konto abmelden.
              </span>
              <br />
              <br />
              GlobalEnglish kann nicht haftbar sein und ist nicht für Verlust oder Schäden haftbar, die aus Ihrer
              Nichteinhaltung entstehen.
              <br />
              <br />
              Sie sind allein dafür verantwortlich sicherzustellen, dass diese Dienstleistungsbedingungen alle für Sie
              geltenden Gesetze, Vorschriften und Regelungen erfüllen, und das Recht zum Zugriff auf die Dienstleistung
              wird aufgehoben, wenn diese Dienstleistungsbedingungen oder die Nutzung der Dienstleistung untersagt ist
              oder wenn das Angebot, der Verkauf oder die Bereitstellung der Dienstleistungen gegen anwendbare Gesetze,
              Vorschriften oder Bestimmungen verstößt.
            </span>
            <br />
            <br />
            <b>7.4 Verbotenes Nutzerverhalten </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Nachstehend sind Beispiele für Nutzungsarten, die illegal oder von GlobalEnglish verboten sind.
              GlobalEnglish behält sich das Recht vor, Untersuchungen durchzuführen und angemessene rechtliche Maßnahmen
              gegen jede Person einzuleiten, die nach GlobalEnglishs eigenem Ermessen diese Bestimmung verletzen,
              inklusive die Entfernung von beleidigendem Inhalt von der Webseite oder Applikation, die Sperrung oder
              Kündigung des Kontos eines solchen Nutzers und Meldung der Person an die entsprechenden
              Strafverfolgungsbehörden.
              <br />
              <br />
              Sie dürfen nicht:
              <br />
              <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
                (a) GlobalEnglishs Inhalt oder andere Materialien auf den Dienstleistungen anpassen, herunterladen,
                überarbeiten, senden, rückentwickeln, duplizieren, veröffentlichen, ändern, verbreiten, ausstellen,
                übertragen oder anderweitig kopieren und/oder vertreiben sofern nicht von GlobalEnglish ausdrücklich
                zugelassen.
                <br />
                (b) Frames oder Framing- Techniken verwenden, um einen Teil der Dienstleistung einzubinden;
                <br />
                (c) einen Teil der Dienstleistung durch Nutzung von Bots, Spiders, Crawlers, Spyware, Engines,
                Ausrüstung, Software oder anderen automatischen Geräten, Anwendungen oder manuellen Prozessen jeglicher
                Art erfassen, erlangen, nutzen, auf diese zugreifen oder anderweitig kopieren;
                <br />
                (d) die Dienstleistungen oder jegliche in den Dienstleistungen verfügbaren Funktionen in jeglicher Weise
                mit der Absicht nutzen, die Dienstleistungen zu unterbrechen, zu stören, zu deaktivieren, zu überlasten
                oder zu beeinträchtigen;
                <br />
                (e) die Dienstleistungen für kommerzielle Unternehmungen verkaufen, lizenzieren, vermieten oder
                anderweitig nutzen; oder
                <br />
                (f)sich an Aktivitäten beteiligen, welche den Zugriff, die Nutzung oder den Gebrauch dieser
                Dienstleistung durch andere Nutzer beeinträchtigen.
                <br />
                (g) jeglichen Benutzerinhalt posten, mailen oder anderweitig übertragen, (i) der rechtswidrig,
                schädlich, bedrohlich, beleidigend, belästigend, sittenwidrig, übermäßig gewalttätig, diffamierend,
                vulgär, obszön, anstößig, pornographisch, verleumderisch, rassistisch, ethnisch beleidigend oder
                anderweitig unzulässig ist oder die Privatsphäre anderer verletzt (bis einschließlich von Anschrift,
                E-Mail, Telefonnummer und anderen Kontaktinformationen); (ii) zu dessen Übertragung Sie rechtlich oder
                gemäß vertraglicher oder finanzieller Verhältnisse nicht berechtigt sind; (iii) der ein Risiko für die
                Privatsphäre oder ein Sicherheitsrisiko für eine Person darstellt; (iv) der geistiges Eigentum oder
                andere Eigentumsrechte einer Partei verletzt; (v) der unerwünschte oder ungenehmigte Werbung,
                Werbematerialien, kommerzielle Aktivitäten und/oder Verkäufe, „Junkmail“, „Spam“, „Kettenbriefe“,
                „Pyramidensysteme“, „Wettbewerbe“, „Sweepstakes“ oder jegliche andere Form der Bewerbung darstellt; (vi)
                der Softwareviren oder anderen Computercode, Dateien oder Programme enthält, welche zur Unterbrechung,
                Zerstörung oder Einschränkung der Funktionalität von Computersoftware oder Hardware oder
                Telekommunikationsausrüstung entwickelt wurden; oder (vii) der nach Ermessen von GlobalEnglish, anstößig
                ist oder eine andere Person an der Nutzung oder dem Gebrauch der Dienstleistungen hindert oder
                einschränkt, oder der GlobalEnglish oder seine Nutzer einem Schaden oder einer Haftung jeglicher Art
                aussetzt;
                <br />
                (h) eine falsche Identität erstellen, Ihre Identität falsch darstellen, sich als eine andere Person
                ausgeben, oder Ihre Zugehörigkeit zu einer Person oder juristischen Person falsch darstellen oder
                anderweitig entstellen;
                <br />
                (i) persönliche Informationen von Personen unter 18 Jahren einholen;
                <br />
                (j) Minderjährige auf jegliche Art schädigen;
                <br />
                (k) körperlichen Schaden oder Verletzungen gegen Gruppen oder Einzelpersonen fördern, eine Person
                belästigen oder anderweitig bedrängen;
                <br />
                (l) Anweisungen über illegale Aktivitäten geben oder fördern, wie zum Beispiel Anweisungen zum Bau von
                Bomben, Granaten und anderen Waffen oder Brandsätzen;
                <br />
                (m) die Inhalte der Webseite oder E-Mail-Adressen, Kontaktinformationen oder andere persönliche
                Informationen anderer Nutzer von der Webseite durch die Nutzung automatisierter Scripts oder anderweitig
                für jegliche Zwecke erfassen oder sammeln, unter anderem für die Versendung von unerwünschten E-Mails
                oder anderen unerwünschten Mitteilungen an Nutzer oder die Reproduktion des Inhalts der Webseite;
                <br />
                (n) Waren oder Dienstleistungen für Geschäftszwecke bewerben, anbieten oder verkaufen, die nicht
                ausdrücklich zugelassen wurden;
                <br />
                (o) die Webseite oder mit der Webseite verbundene Server oder Netzwerke stören, oder Anforderungen,
                Verfahren, Richtlinien oder Vorschriften von mit der Webseite verbundenen Netzwerken missachten;
                <br />
                (p) anwendbare örtliche, staatliche, nationale oder internationale Gesetze oder jegliche rechtskräftige
                Vorschriften verletzen; kriminelle Aktivitäten oder Unternehmungen fördern oder unterstützen,
                Anweisungen über illegale Aktivitäten bieten;
                <br /> (q)Materialien oder Informationen durch Mittel erlangen oder Zugriff auf diese suchen oder
                anderweitig erlangen, welche nicht absichtlich durch die Webseite zur Verfügung gestellt oder
                bereitgestellt werden;
                <br />
                (r) Sicherheitsfunktionen außer Kraft setzen oder Zugangskontrollen überspringen oder umgehen; oder
                <br />
                (s) die Dienstleistungen auf eine Weise nutzen, welche Interessenkonflikte kreieren könnte oder den
                Zweck der Dienstleistungen untergraben könnte, wie der gegenseitige Austausch von Beurteilungen mit
                anderen Nutzern oder das Schreiben von falschen Reviews.
              </span>
            </span>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. GEISTIGE EIGENTUMSRECHTE </b>
            <br />
            <br />
            <b>8.1 Geistige Eigentumsrechte von GlobalEnglish </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Der gesamte Inhalt der Dienstleistungen, inklusive der Inhalt und jegliche unterstützende Software sind
              geschütztes Eigentum von GlobalEnglish, seinen Lizenzgebern oder seinen Subunternehmern und sind durch
              U.S.- und internationale Urheberrechte und andere geistige Eigentumsgesetze geschützt.
              <br />
              <br /> Die Reproduktion, Weiterverteilung, Änderung oder Veröffentlichung von Teilen der Dienstleistungen
              ohne die ausdrückliche schriftliche Zustimmung von GlobalEnglish und/oder seinen Lizenzgebern oder
              Abweichung von den nachstehenden Vorschriften sind strengstens untersagt.
              <br />
              <br /> Autorisierte Nutzer können auf die Dienstleistungen und den Inhalt zugreifen und diese nutzen und
              eine angemessene Anzahl von Kopien von Teilen des Inhalts herunterladen oder ausdrucken, auf welche der
              Nutzer ausschließlich zur persönlichen Nutzung durch den Nutzer zur Sprachausbildung ordnungsgemäß Zugriff
              erhalten hat, vorausgesetzt dass der Nutzer alle Urheberrechte oder andere Eigentumsvermerke auf allen
              Kopien solchen Inhalts bewahrt.
              <br />
              <br /> Sofern nicht anderweitig angedeutet, sind die auf den Dienstleistungen erscheinenden Warenzeichen
              Warenzeichen von GlobalEnglish, seinen Lizenzgebern oder seinen verbundenen Unternehmen. Sie verpflichten
              sich, die Warenzeichen weder abzubilden, zu verunglimpfen oder zu besudeln noch verwechslungsfähige
              ähnliche Marken zu verwenden oder so zu verwenden, dass diese das Eigentum an solchen Marken fälschlich
              darstellen oder den Eindruck erwecken, dass GlobalEnglish Produkte oder Dienstleistungen billigt. Sie
              dürfen die Warenzeichen, Markennamen und/oder Handelsaufmachung von GlobalEnglish nicht ohne die vorherige
              schriftliche Genehmigung von GlobalEnglish reproduzieren oder nutzen. Jeglicher durch die Nutzung der
              GlobalEnglish-Warenzeichen erzeugte Goodwill geht zu unserem alleinigen Vorteil.
              <br />
              <br />
              Jegliche Nutzung der Webseiten, Applikationen oder Inhalte mit Ausnahme der hier ausdrücklich genehmigten
              Nutzung ist untersagt und beendet automatisch Ihre hierin erteilten Rechte hinsichtlich der Nutzung der
              Dienstleistungen und des Inhalts. Alle Rechte von GlobalEnglish oder seinen Lizenzgebern, die in diesen
              Bedingungen nicht ausdrücklich gewährt werden, sind GlobalEnglish und seinen Lizenzgebern vorbehalten.
            </span>
            <br />
            <br />
            <b>8.2 Warenzeichen Dritter </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Produkt- und Dienstleistungsnamen und Logos, welche auf der Webseite benutzt und ausgestellt werden,
              welche nicht die vorstehend beschriebenen Warenzeichen von GlobalEnglish sind, können Warenzeichen und
              Dienstleistungsmarken ihrer jeweiligen Eigentümer sein, welche eventuell mit GlobalEnglish verbunden sein
              oder diese unterstützen können oder nicht. Nichts in diesen Bedingungen oder auf der Webseite soll ohne
              unsere jeweilige vorherige schriftliche Genehmigung implizit, durch Rechtsverwirkung oder anderweitig als
              Gewährung einer Lizenz oder eines Rechtes zur Nutzung von auf der Webseite ausgestellte Warenzeichen
              ausgelegt werden. Jeglicher durch die Nutzung der GlobalEnglish-Warenzeichen erzeugte Goodwill geht zu
              unserem alleinigen Vorteil.
            </span>
            <br />
            <br />
            <b>8.3 Urheberrechtliche Beschwerden laut Digital Millennium Copyright Act (DMCA) </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Bitte benachrichtigen Sie uns im Falle von urheberrechtlichen Bedenken hinsichtlich von anderen auf den
              Dienstleistungen geposteten Materialien.
              <br />
              <br />
              GlobalEnglish wird angebliche Verletzungen bearbeiten und untersuchen und hinsichtlich der angeblichen
              oder tatsächlichen Verletzung angemessene Maßnahmen gemäß des Digital Millennium Copyright Act („DMCA“)
              und anderen anwendbaren geistigen Eigentumsgesetzen einleiten.
              <br />
              <br />
              Die Mitteilung der behaupteten Urheberrechtsverletzung ist an GlobalEnglishs Urheberrechtsbeauftragten
              unter Copyright@globalenglish.com (Betreff: „DMCA Takedown Request“) zu mailen.
              <br />
              <br /> Sie können uns auch auf dem Postweg kontaktieren unter:
              <br />
              <b>z.Hd.: Copyright Complaints/Legal Department</b>
              <br /> 1875 South Grant Street, Suite 700
              <br /> San Mateo, CA 94402 USA
              <br />
              <br />
              <br />
              Die Mitteilung ist nur wirksam, wenn diese schriftlich erfolgt und folgende Informationen enthält: (i)
              eine elektronische oder physische Unterschrift der Person, die im Auftrag der Person handelt, deren
              Urheberrechte oder anderen geistigen Eigentumsrechte berührt sind; (ii) eine Beschreibung der
              urheberrechtlich geschützten Arbeit oder anderen geistigen Eigentums, welche/s Ihrer Meinung nach verletzt
              wurde; (iii) eine Beschreibung, wo dieses Material, das Ihrer Meinung nach die Urheberrechte verletzt,
              sich auf den Dienstleistungen befindet, mit ausreichenden Einzelheiten, dass es uns ermöglicht, dieses auf
              der Webseite zu finden; (iv) Informationen, mittels welcher GlobalEnglish Sie kontaktieren kann
              (Anschrift, Telefonnummer und E-Mail-Adresse); (v) eine Erklärung Ihrerseits, dass Sie guten Glaubens
              annehmen, dass die umstrittene Nutzung nicht durch den Rechteinhaber oder Inhaber des geistigen Eigentums,
              seinen Beauftragten oder die Rechtslage genehmigt ist; (vi) eine eidesstattliche Versicherung Ihrerseits,
              dass alle Informationen in Ihrer Mitteilung korrekt sind und dass Sie der Rechteinhaber oder Inhaber des
              geistigen Eigentums sind bzw. im Auftrag des Rechteinhabers oder Inhabers des geistigen Eigentums handeln.
              <br />
              <br /> Gemäß DMCA und anderen anwendbaren Gesetzen kündigt GlobalEnglish, unter gegebenen Umständen und
              auf eigenes Ermessen, Nutzer, die als Wiederholungstäter gelten. GlobalEnglish kann auch auf eigenes
              Ermessen den Zugriff auf die Webseite einschränken und/oder die Mitgliedschaft von Nutzern kündigen,
              welche geistige Eigentumsrechte anderer verletzen, unabhängig davon, ob es sich um einen Wiederholungsfall
              handelt.
            </span>
            <br />
            <br />
            <b>
              8.4 Entfernung von Benutzerinhalt, der im Verdacht steht, Rechte des geistigen Eigentums zu verletzen{' '}
            </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Falls GlobalEnglish Teile Ihres Benutzerinhaltes entfernt hat, die im Verdacht stehen, geistige
              Eigentumsrechte Dritter zu verletzen und Sie glauben, dass Ihr entfernter Benutzerinhalt (oder Inhalt, auf
              welchen der Zugriff deaktiviert wurde) keine Eigentumsrechte verletzt, oder dass Sie die Genehmigung des
              Rechteinhabers oder des Beauftragten des Rechteinhabers zum Posten und Nutzen des Inhaltes in Ihrem
              Benutzerinhalt besitzen bzw. laut Gesetz dazu berechtigt sind, können Sie an den Urheberrechtsbeauftragten
              eine Gegendarstellung senden, welche folgende Informationen enthält: (i) Ihre elektronische oder physische
              Unterschrift; (ii) Angabe des Inhaltes, der entfernt wurde oder auf welchen der Zugriff deaktiviert wurde,
              und des Ortes, an welchem der Inhalt vor seiner Entfernung oder Deaktivierung erschien; (iii) eine
              Erklärung Ihrerseits, dass Sie guten Glaubens annehmen, dass der Inhalt aufgrund eines Fehlers oder einer
              Fehlidentifizierung des Inhaltes entfernt wurde; und (iv) Ihren Namen, Ihre Anschrift, Telefonnummer und
              E-Mail-Adresse, eine Erklärung, dass Sie der Zuständigkeit des im Northern District of California
              gelegenen Bundesgerichtes zustimmen, und eine Erklärung, dass Sie die Klagezustellung von der Person
              akzeptieren, welche die Mitteilung der behaupteten Urheberrechtsverletzung eingereicht hatte.
              <br />
              <br /> Falls der Urheberrechtsbeauftragte eine Gegendarstellung erhält, sendet GlobalEnglish eine Kopie
              der Gegendarstellung an die ursprüngliche beschwerdeführende Partei, welche diese Person davon informiert,
              dass GlobalEnglish den entfernten Inhalt in 10 Werktagen ersetzen oder wieder aktivieren kann. Sofern der
              Rechteinhaber keine Klage auf Gerichtsverfügung gegen den Inhaltsanbieter (Content Provider), das Mitglied
              oder den Nutzer einreicht, kann der entfernte Inhalt innerhalb von 10 bis 14 Werktagen oder später nach
              Erhalt der Gegendarstellung nach unserem eigenen Ermessen ersetzt oder Zugriff auf diesen
              wiederhergestellt werden.
            </span>
            <br />
            <br />
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
              {' '}
              9. INTERNATIONALE NUTZUNG UND EXPORTKONTROLLEN{' '}
            </b>
            <br />
            <br /> In Anbetracht der globalen Natur des Internets verpflichten Sie sich, alle örtlichen Vorschriften in
            Bezug auf das Online-Verhalten und akzeptablen Benutzerinhalt einzuhalten. Sie verpflichten sich, alle
            anwendbaren Gesetze bezüglich der Übertragung von technischen Daten einzuhalten, welche aus den Vereinigten
            Staaten oder dem Land Ihres Wohnsitzes exportiert werden.
            <br />
            <br /> Sie bestätigen, dass die Dienstleistungen bzw. Teile davon dem Exportkontrollrecht der Vereinigten
            Staaten unterliegen können. Sie werden keine Teile der Dienstleistung oder verwandte technische
            Informationen oder Materialien unter direkter bzw. indirekter Verletzung von anwendbaren Exportgesetzen oder
            Vorschriften exportieren, reexportieren oder übertragen. Sie versichern und garantieren, dass Sie kein
            Staatsbürger eines Landes sind bzw. sich in einem Land aufhalten, das einem Embargo der US-Regierung
            unterliegt oder das von der US-Regierung als „Terrorismus unterstützender“ Staat bestimmt wurde, und dass
            Sie in keiner Liste der US-Regierung verbotener oder beschränkter Parteien aufgeführt sind.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. UNTERLASSUNGSANSPRUCH </b>
            <br />
            <br /> Sie bestätigen, dass eine von den Nutzungsbedingungen abweichende Nutzung der Dienstleistungen
            GlobalEnglish, seinen verbundenen Unternehmen, Lizenzgebern, Subunternehmern, Wiederverkäufern und Nutzern
            irreparable Schäden zufügen kann. Unter solchen Umständen sind GlobalEnglish, seine verbundenen Unternehmen,
            Lizenzgeber, Subunternehmer, Wiederverkäufer und Nutzer zu angemessener Entschädigung ohne Erbringung einer
            Kaution oder anderen Sicherheit berechtigt, inklusive eines temporären und permanenten
            Unterlassungsanspruchs.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11. AUSFALLZEITEN </b>
            <br />
            <br /> Die Dienstleistungen können in Abständen aufgrund von Wartung oder aus anderen Gründen zeitweise
            nicht verfügbar sein. GlobalEnglish trägt keine Verantwortung für jegliche Unterbrechungen, Betriebs- oder
            Übertragungsverzögerungen, Diebstahl oder Zerstörung, unberechtigten Zugriff auf die oder Änderung der
            Benutzerkommunikationen oder anderen mittels Webseite zur Verfügung gestellten Inhalts.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12. GEWÄHRLEISTUNGSAUSSCHLUSS </b>
            <br />
            <br /> Die Dienstleistungen können in Abständen aufgrund von Wartung oder aus anderen Gründen zeitweise
            nicht verfügbar sein. GlobalEnglish trägt keine Verantwortung für jegliche Unterbrechungen, Betriebs- oder
            Übertragungsverzögerungen, Diebstahl oder Zerstörung, unberechtigten Zugriff auf die oder Änderung der
            Benutzerkommunikationen oder anderen mittels Dienstleistungen zur Verfügung gestellten Inhalts. Unter keinen
            Umständen ist GlobalEnglish für Personenschaden oder Tod haftbar, welche aus der Nutzung der
            Dienstleistungen, des Benutzerinhalts oder Inhalts Dritter, oder der Nutzung der vom Nutzer bereitgestellten
            Produkten oder Dienstleistungen resultieren.
            <br />
            <br /> SIE NEHMEN ZUR KENNTNIS UND ERKLÄREN SICH DAMIT EINVERSTANDEN, DASS:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              {' '}
              (A) DIE DIENSTLEISTUNGEN, INKLUSIVE JEGLICHER DARIN ENTHALTENER INHALT, IN DER VORLIEGENDEN FORM UND JE
              NACH VERFÜGBARKEIT BEREITGESTELLT WERDEN.
              <br />
              <br /> (B)GLOBALENGLISH JEGLICHE AUSDRÜCKLICHEN ODER IMPLIZIERTEN ZUSICHERUNGEN UND GEWÄHRLEISTUNGEN
              HINSICHTLICH DER DIENSTLEISTUNGEN AUSSCHLIESST, INKLUSIVE IMPLIZIERTE EIGENTUMSANSPRÜCHE, ZUSICHERUNG
              ALLGEMEINER GEBRAUCHSTAUGLICHKEIT, EIGNUNG FÜR EINEN BESTIMMTEN ZWECK ODER NICHTVERLETZUNG VON
              EIGENTUMSANSPRÜCHEN.
              <br />
              <br /> (C) GLOBALENGLISH KONKRETE ERGEBNISSE VON DER NUTZUNG DER DIENSTLEISTUNGEN WEDER GEWÄHRLEISTET NOCH
              VERSPRICHT, NOCH, DASS DIE DIENSTLEISTUNGEN IHRE ANFORDERUNGEN ODER ERWARTUNGEN ERFÜLLEN WERDEN.
              <br />
              <br />
              (D) GLOBALENGLISH KEINE ZUSICHERUNGEN ODER GEWÄHRLEISTUNGEN GIBT, DASS DIE SOFTWARE, DER INHALT ODER DIE
              MATERIALIEN IN DEN DIENSTLEISTUNGEN ODER AUF DER WEBSEITE KORREKT, VOLLSTÄNDIG, ZUVERLÄSSIG, AKTUELL ODER
              FEHLERFREI SIND, ODER DASS DIE WEBSEITE ODER DIENSTLEISTUNGEN ODER SEINE SERVER VIRENFREI ODER FREI VON
              ANDEREN SCHÄDLICHEN KOMPONENTEN SIND. DESHALB NEHMEN SIE ZUR KENNTNIS UND ERKLÄREN SICH OHNE EINSCHRÄNKUNG
              DES VORSTEHENDEN DAMIT EINVERSTANDEN, DASS SIE INHALT, MATERIAL, DATEN ODER SOFTWARE VON ODER DURCH DIE
              DIENSTLEISTUNGEN ODER VON EINEM DIENSTLEISTER AUF EIGENES ERMESSEN UND RISIKO HERUNTERLADEN ODER
              ANDERWEITIG ERLANGEN, UND DASS SIE ALLEIN FÜR IHRE NUTZUNG DERSELBEN UND JEGLICHE SCHÄDEN AN IHREM
              COMPUTERSYSTEM, DATENVERLUST ODER ANDERE SCHÄDEN JEGLICHER ART VERANTWORTLICH SIND.
              <br />
              <br /> (E) GLOBALENGLISH NICHT GARANTIERT, DASS JEGLICHE FEHLER ODER MÄNGEL IN DEN DIENSTLEISTUNGEN
              KORRIGIERT WERDEN.
              <br />
              <br />
              (F) GLOBALENGLISH NICHT FÜR DAS VERHALTEN VON NUTZERN, INHALTE DRITTER UND BENUTZERINHALT VERANTWORTLICH
              IST.
              <br />
              <br />
              (G) Die Dienstleistungen können in Abständen aufgrund von Wartung oder aus anderen Gründen zeitweise nicht
              erreichbar sein. GlobalEnglish trägt keine Verantwortung für jegliche Unterbrechungen, Betriebs- oder
              Übertragungsverzögerungen, Diebstahl oder Zerstörung, unberechtigten Zugriff auf die oder Änderung der
              Benutzerkommunikationen oder anderen mittels Dienstleistungen zur Verfügung gestellten Inhalts. Unter
              keinen Umständen ist GlobalEnglish für Personenschaden oder Tod haftbar, welche aus der Nutzung der
              Dienstleistungen, des Benutzerinhalts oder Inhalts Dritter, oder der Nutzung der vom Nutzer
              bereitgestellten Produkten oder Dienstleistungen resultieren.
            </span>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. HAFTUNGSBEGRENZUNG</b>
            <br />
            <br /> IN KEINEM FALL SIND GLOBALENGLISH ODER SEINE DIREKTOREN, MITARBEITER, BEAUFTRAGTEN, LIZENZGEBER ODER
            SUBUNTERNEHMER IHNEN GEGENÜBER ODER EINEM ANDEREN DRITTEN FÜR INDIREKTE SCHADENSANSPRÜCHE,
            FOLGESCHADENSANSPRÜCHE, ENTSCHÄDIGUNG MIT STRAFCHARAKTER, NEBENSCHADENSANSPRÜCHE, BESONDERE
            SCHADENSANSPRÜCHE ODER STRAFSCHADENSANSPRÜCHE HAFTBAR, INKLUSIVE FÜR ENTGANGENE GEWINNE ODER DATENVERLUSTE,
            WELCHE ENTSTEHEN AUS: (I) IHRER NUTZUNG ODER NUTZUNGSUNFÄHIGKEIT DER DIENSTLEISTUNGEN ODER JEGLICHEN
            INHALTES ODER ANDEREN MATERIALIEN, DIE VON DER WEBSEITE, DEN DIENSTLEISTUNGEN ODER DEM DIENSTANBIETER
            HERUNTERGELADEN WERDEN, SICH DORT BEFINDEN ODER DORT AUF DIESE ZUGEGRIFFEN WIRD, (II) DEN BESCHAFFUNGSKOSTEN
            VON ERSATZWAREN UND DIENSTLEISTUNGEN, DIE ENTSTEHEN AUS JEGLICHEN WAREN, DATEN, INFORMATIONEN ODER
            DIENSTLEISTUNGEN, DIE DURCH ODER VON DER WEBSEITE GEKAUFT ODER BESCHAFFT WERDEN, ODER DURCH ODER VON DER
            WEBSEITE ERHALTENEN NACHRICHTEN ODER EINGEGANGENEN TRANSAKTIONEN; (III) UNBERECHTIGTEM ZUGRIFF AUF IHRE
            ÜBERTRAGUNGEN ODER DATEN ODER DEREN ÄNDERUNG DURCH EINE PARTEI; (IV) ERKLÄRUNGEN ODER VERHALTEN VON DRITTEN
            AUF DER WEBSEITE; ODER (V) JEGLICHER ANDEREN ANGELEGENHEIT IN BEZIEHUNG MIT DEN DIENSTLEISTUNGEN. IN KEINEM
            FALL ÜBERSCHREITET DIE GESAMTHAFTUNG VON GLOBALENGLISH IHNEN GEGENÜBER FÜR ALLE SCHÄDEN, VERLUSTE ODER
            KLAGEGRÜNDE DEN BETRAG, DEN SIE GLOBALENGLISH IN DEN VERGANGENEN ZWÖLF (12) MONATEN GEZAHLT HABEN, ODER
            FALLS MEHR, EINHUNDERT DOLLAR ($100), SELBST WENN SICH GLOBALENGLISH DER MÖGLICHKEIT SOLCHER SCHÄDEN BEWUSST
            IST ODER VON DIESER MÖGLICHKEIT UNTERRICHTET WURDE. DAS BESTEHEN VON MEHR ALS EINEM ANSPRUCH ERHÖHT DIESE
            OBERGRENZE NICHT.
            <br />
            <br /> EINIGE RECHTSSYSTEME ERLAUBEN NICHT DEN AUSSCHLUSS BESTIMMTER GEWÄHRLEISTUNGEN ODER DIE
            HAFTUNGSBESCHRÄNKUNG FÜR NEBENSCHÄDEN ODER FOLGESCHÄDEN. DEMENTSPRECHEND KÖNNEN EINIGE DER VORSTEHEND
            AUFGEFÜHRTEN HAFTUNGSBESCHRÄNKUNGEN NICHT FÜR SIE ZUTREFFEND SEIN.
            <br />
            <br /> SOLLTEN SIE MIT EINEM TEIL DER WEBSEITE ODER MIT DIESEN BESTIMMUNGEN ZUR WEBSEITE NICHT ZUFRIEDEN
            SEIN, BESTEHT IHR EINZIGER UND AUSSCHLIESSLICHER RECHTSBEHELF IN DER EINSTELLUNG DER NUTZUNG DER WEBSEITE.
            <br />
            <br /> UNTER KEINEN UMSTÄNDEN IST GLOBALENGLISH FÜR PERSONENSCHADEN ODER TOD HAFTBAR, WELCHE AUS DER NUTZUNG
            DER DIENSTLEISTUNGEN, DES INHALTS DRITTER ODER DES BENUTZERINHALTS RESULTIEREN.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14.VERJÄHRUNG </b>
            <br />
            <br />
            Sie sind damit einverstanden, dass ungeachtet jeglicher gegenteiligen Gesetze oder Vorschriften jeglicher
            Anspruch oder Klagegrund gegen GlobalEnglish, der aus oder in Verbindung mit Ihrer Nutzung der
            Dienstleistungen entsteht, innerhalb von einem (1) Jahr eingereicht werden muss, nachdem solch ein Anspruch
            oder Klagegrund entstand, oder für immer verjährt.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>15. HAFTUNGSFREISTELLUNG </b>
            <br />
            <br />
            Sie vereinbaren, GlobalEnglish und seine verbundenen Unternehmen und deren Beauftragten, Mitarbeiter,
            Direktoren und Vertreter von jeglichen Ansprüchen, Klagen, Kosten, Haftung, Verlusten, Schadensersatz,
            Ausgaben, inklusive angemessener Rechtsanwaltskosten, welche von Dritten gegen GlobalEnglish geltend gemacht
            werden, aufgrund oder in Verbindung mit Ihrer Nutzung der Webseite, Dienstleistungen, Ihrem Benutzerinhalt,
            Ihrer Verbindung zur Webseite oder den Dienstleistungen, Ihrer Verletzung dieser Bedingungen oder Ihrer
            Verletzung von Rechten eines anderen Nutzers oder Gesetzen oder die anderweitig aus Ihrer Nutzung der
            Dienstleistungen entstehen, schad- und klaglos zu halten. Ihre Freistellungsverpflichtung umfasst unter
            anderem Ansprüche gegen GlobalEnglish, dass der Benutzerinhalt die geistigen Eigentumsrechte Dritter
            verletzt.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>16. KÜNDIGUNG </b>
            <br />
            <br /> Wird Ihr Abonnement aus wichtigem Grund gekündigt, deaktiviert GlobalEnglish Ihren Zugriff auf die
            Dienstleistungen. Sie können auf keinen Inhalt zugreifen, inklusive Ihren Benutzerinhalt, obwohl Kopien
            solcher Daten für Sicherungszwecke für drei (3) Jahre in unserem System aufbewahrt werden.
            <br />
            <br /> Wenn Sie verlangen, dass GlobalEnglish Ihre Persönlichen Daten und Benutzerinhalt löscht, werden wir
            uns nach besten Kräften darum bemühen und dies nach erfolgter Löschung bestätigen.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>17. VERZICHT- UND SALVATORISCHE KLAUSEL </b>
            <br />
            <br /> Die Nichtgeltendmachung oder Nichtdurchsetzung eines Rechtes oder einer Bestimmung dieser Bedingungen
            seitens GlobalEnglish stellt keinen Verzicht auf solch ein Recht oder eine Bestimmung in jenem oder einem
            anderen Fall dar. Falls eine Bestimmung dieser Bedingungen für gesetzwidrig, nichtig oder aus einem
            jeglichen Grund undurchsetzbar befunden wird, soll dies nicht die Gültigkeit und Durchsetzbarkeit der
            übrigen Bestimmungen beeinträchtigen.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>18. GESAMTHEIT DER VEREINBARUNGEN </b>
            <br />
            <br /> Diese Vereinbarung stellt die Gesamtheit der Vereinbarungen zwischen Ihnen und GlobalEnglish
            hinsichtlich der Nutzung der Dienstleistungen dar und ersetzt jegliche vorherigen Abmachungen und
            Vereinbarungen zwischen Ihnen und GlobalEnglish hinsichtlich Ihrer Nutzung der Dienstleistungen.
            <br />
            <br /> Falls Ihr Unternehmen eine bestehende Vereinbarung mit GlobalEnglish zum Kauf von Dienstleistungen
            hat, sollen diese Bedingungen ungeachtet des Vorstehenden nicht die Bestimmungen oder Bedingungen von
            Vereinbarungen ändern, welche Ihr Unternehmen mit GlobalEnglish, seinen Tochtergesellschaften oder
            verbundenen Unternehmen abgeschlossen hat, und im Falle eines Widerspruchs sind die Bedingungen einer
            solchen Vereinbarung maßgeblich.
            <br />
            <br /> Diese Vereinbarung wurde in englischer Sprache erstellt und jene Version soll in jeder Hinsicht
            maßgeblich sein und jegliche nicht englische Version dieser Vereinbarung dient nur der Zweckmäßigkeit. Eine
            Druckversion dieser Vereinbarung und jeglicher elektronisch übermittelter Mitteilungen soll in Gerichts- und
            Verwaltungsverfahren aufgrund oder bezüglich dieser Vereinbarung in gleichem Maße und zu den gleichen
            Konditionen zulässig sein, wie andere ursprünglich in schriftlicher Form erstellte und verwaltete
            Geschäftsdokumente und Aufzeichnungen. Die Überschriften der Abschnitte in diesen Bedingungen dienen nur der
            besseren Übersichtlichkeit und haben keinerlei rechtliche oder vertragliche Wirkung.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
              19. ANZUWENDENDES RECHT, GERICHTSSTAND UND SCHIEDSVERFAHREN{' '}
            </b>
            <br />
            <br />
            Diese Bedingungen werden von den Gesetzen des Staates Kalifornien geregelt ohne Berücksichtigung der
            Bestimmungen hinsichtlich von Gesetzeskonflikten.
            <br />
            <br /> Auf GlobalEnglishs Wahl und eigenes Ermessen können alle Streitigkeiten, Ansprüche oder
            Unstimmigkeiten, welche sich aus oder in Verbindung mit diesen Bedingungen oder der Webseite ergeben, oder
            die nicht durch gegenseitiges Einvernehmen gelöst werden, durch ein verbindliches Schiedsgerichtsverfahren
            gelöst werden, das vor dem JAMS oder seinem Nachfolger durchzuführen ist. Sofern nicht anderweitig von den
            Parteien vereinbart, erfolgt das Schiedsgerichtsverfahren in San Francisco, Kalifornien vor einem einzigen,
            von den Parteien gegenseitig vereinbarten Schiedsrichter, oder falls die Parteien sich nicht gegenseitig
            einigen können, vor einem einzigen, von JAMS ernannten Schiedsrichter, und wird gemäß den von JAMS
            veröffentlichten Regeln und Vorschriften durchgeführt, sofern nicht in diesen Bedingungen gezielt
            modifiziert. Das Schiedsverfahren muss innerhalb von fünfundvierzig (45) Tagen ab dem Datum beginnen, an
            welchem ein schriftlicher Schiedsantrag von einer Partei eingereicht wurde. Die Entscheidung des
            Schiedsrichters und der Schiedsspruch hat und ist innerhalb von sechzig (60) Tagen nach dem Abschluss des
            Schiedsverfahrens und innerhalb von sechs (6) Monaten nach der Auswahl eines Schiedsrichters zu erfolgen und
            zuzustellen. Der Schiedsrichter ist nicht berechtigt, Schadensersatz zu gewähren, welcher die in den
            Bedingungen aufgeführten Einschränkung auf tatsächliche, direkte Entschädigungszahlungen übersteigt und darf
            tatsächliche Schäden nicht vervielfachen oder Strafschadensersatz oder jeglichen anderen Schadensersatz
            gewähren, welcher laut diesen Bedingungen ausdrücklich ausgeschlossen ist, und jede Partei verzichtet
            hiermit unwiderruflich auf die Geltendmachung eines solchen Schadensersatzes. Der Schiedsrichter kann nach
            eigenem Ermessen Kosten und Ausgaben gegen eine Verfahrenspartei veranschlagen (inklusive angemessene
            Gerichtskosten und -Gebühren der obsiegenden Partei). Jegliche Partei, welche sich weigert, die Anordnungen
            des Schiedsrichters zu befolgen, ist für die Kosten und Ausgaben inklusive Anwaltskosten, welche der anderen
            Partei bei der Durchsetzung des Schiedsspruchs entstehen. Dessen ungeachtet kann eine Partei zur Vermeidung
            von unmittelbaren und irreparablen Schäden im Falle eines temporären oder vorläufigen Unterlassungsanspruchs
            ohne ein vorheriges Schiedsverfahren gerichtlich Klage erheben. Die Bestimmungen dieses Abschnittes zum
            Schiedsverfahren sind in einem beliebigen zuständigen Gericht durchsetzbar.
            <br />
            <br /> Hinsichtlich von Streitigkeiten oder Ansprüchen, welche nicht dem vorstehend beschriebenen
            Schiedsverfahren unterliegen, vereinbaren Sie und GlobalEnglish, sich der persönlichen und ausschließlichen
            Gerichtsbarkeit der Staats- und Bundesgerichte zu unterwerfen, welche sich in San Francisco County,
            Kalifornien befinden.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. MITTEILUNGEN UND KONTAKTE </b>
            <br />
            <br />
            Sie können die Kündigung Ihres GlobalEnglish Dienstleistungskontos durch Senden der Kündigung an
            GlobalEnglish mittels des „Kontaktieren Sie uns“ Formularlinks unter
            <a href="https://support.globalenglish.com/" rel="noopener noreferrer" target="_blank">
              https://support.globalenglish.com/
            </a>{' '}
            fordern. Ihr Zugriff auf die Dienstleistung und deren Nutzung, inklusive jeglichen enthaltenen Inhalts, kann
            jederzeit nach Kündigung Ihres Kontos von GlobalEnglish verhindert werden.
            <br />
            <br /> Mitteilungen an Sie können entweder per E-Mail oder auf dem normalen Postweg erfolgen. Die Webseite
            kann Ihnen auch Mitteilungen über Änderungen dieser Bedingungen oder zu anderen Angelegenheiten durch
            Darstellen von Mitteilungen oder Links zu Mitteilungen senden, die sich normalerweise auf der Dienstleistung
            befinden.
            <br />
            <br /> Mitteilungen bezüglich unserer Datenschutzerklärung können gesandt werden an
            <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
              privacy@globalenglish.com.
            </a>
            <br />
            <br /> Alle anderen rechtlichen Mitteilungen oder andere Korrespondenz an GlobalEnglish sind an folgende
            E-Mail-Adresse:
            <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
              legal@globalenglish.com
            </a>{' '}
            oder folgende Postanschrift zu senden:
            <br />
            <br /> 1875 S Grant Street, Suite 700
            <br /> San Mateo, CA 94402
            <br /> to the attention of
            <b>Legal Counsel</b>
            <br />
          </p>
          <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
            <b>SPEZIELLE BEDINGUNGEN FÜR B2B-TRANSAKTIONEN</b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            {' '}
            Wenn Sie Ihr Konto bei GlobalEnglish durch Ihren Arbeitgeber erhalten haben, unterliegt Ihre Nutzung unserer
            Dienstleistungen auch unserer Vereinbarung mit Ihrem Unternehmen (ein „Unternehmen“). Die
            Nutzungsbedingungen sollen die Bedingungen der Vereinbarung mit Ihrem Unternehmen nicht ändern. Sie dürfen
            die Dienstleistungen nicht nutzen (oder deren Nutzung fortsetzen), wenn Ihr Unternehmen Sie nicht als ein
            Nutzer auf der Lizenz mit GlobalEnglish bevollmächtigt. Wenn Sie uns zur Kündigung Ihres Kontos auffordern,
            kann es notwendig sein, dass Ihr Unternehmen vor der Kündigung Ihres Kontos durch GlobalEnglish seine
            Zustimmung gibt.
            <br />
            <br /> Sie vereinbaren, die Dienstleistungen nur in dem von Ihrem Unternehmen genehmigten Rahmen zu nutzen.
            Sie haben eventuell die Gelegenheit, mit anderen innerhalb Ihres Unternehmens zu kommunizieren oder
            Dokumente bezüglich Ihres Unternehmens durch die Dienstleistungen hochzuladen, dies ist jedoch ohne
            Genehmigung Ihres Unternehmens untersagt und Sie dürfen dies nicht tun.
            <br />
            <br />
            Sie bestätigen und vereinbaren, dass Ihr Unternehmen auf alle Daten, die in Verbindung mit Ihrer Nutzung der
            Dienstleistungen erzeugt werden („Benutzerdaten“), zugreifen kann, und dass GlobalEnglish Ihnen gegenüber
            aufgrund der Informationen, die mit Ihrem Unternehmen im Ergebnis Ihrer Nutzung unserer Dienstleistungen
            geteilt werden, nicht haftbar ist.
            <br />
            <br /> Da Ihr Abonnement zum Zugriff auf die Dienstleistungen nur für die Dauer der Unternehmenslizenz
            fortbesteht, verlieren Sie Zugriff auf die Dienstleistungen und den Benutzerinhalt, wenn Ihr Unternehmen
            seine Lizenz mit GlobalEnglish kündigt. GlobalEnglish kann das Konto Ihres Unternehmens, Ihr Konto oder die
            Fähigkeit zur Nutzung der Dienstleistungen ganz oder teilweise kündigen oder suspendieren, falls (i) Ihr
            Unternehmen hiernach an GlobalEnglish fällige Gebühren bei Fälligkeit nicht zahlt, oder (ii) Sie oder Ihr
            Unternehmen die Bedingungen verletzen oder andere Regeln, welche die Nutzung der Dienstleistungen regeln.
            <br />
            <br /> GlobalEnglish kann verpflichtet sein, alle Daten bezüglich Ihrer Nutzung der Dienstleistungen,
            inklusive Ihre Persönlichen Daten und Benutzerinhalt an Ihr Unternehmen zurückzugeben. Falls ihr Unternehmen
            die Rückgabe oder Löschung solcher Daten nicht fordert, wird GlobalEnglish die Daten für einen Zeitraum von
            drei (3) Jahren nach Ablauf oder Kündigung seines Vertrages mit Ihrem Arbeitgeber aufbewahren. In solchen
            Fällen können Sie ein Abonnement auf die Dienstleistungen direkt von GlobalEnglish kaufen, und Ihren Zugriff
            auf das Programm ohne Verlust Ihrer Fortschrittsdaten fortsetzen. Sie können die Übertragung Ihrer
            Fortschritts- und Profilinformationen in ein neues Abonnement mit Ihrem neuen Arbeitgeber autorisieren. Sie
            können ebenfalls verlangen, dass alle Daten bezüglich Ihrer Nutzung der Dienstleistungen in ein Format
            übertragen werden, das mit zukünftigen Importen kompatibel ist.
          </p>
          <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
            <b>SPEZIELLE BEDINGUNGEN FÜR B2C-TRANSAKTIONEN </b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            Sie können ebenfalls als Einzelperson eine Abonnementsvereinbarung mit GlobalEnglish abschließen, welche
            entweder aus einem dreimonatigen (3) oder sechsmonatigen (6) Abonnement oder einem einjährigen oder
            mehrjährigen Abonnementplan besteht (jedes ein „Abonnementplan“).
            <br />
            <br /> Sie werden für den jeweiligen Abonnementplan am gleichen oder ungefähr gleichen Tag jeden
            Monats/jeden Jahrs (je nach Fall) eine monatliche oder jährliche Abrechnung erhalten, bis Sie Ihren
            Abonnementplan kündigen. Vorsorglich wird angemerkt, dass Sie bis zum Jahrestag Ihres Abonnementplans die
            gewählte Dienstleistung nicht kündigen, downgraden oder die Anzahl der Plätze reduzieren können. Zahlungen
            für einen Abonnementplan werden nicht rückerstattet.
            <br />
            <br /> Die für die Dienstleistung anfallenden Gebühren („Gebühren“) sind auf der Webseite und/oder in
            GlobalEnglishs jeweils aktuellen veröffentlichen Preislisten erhältlich. Der für die Dienstleistungen
            aufgeführte Preis enthält keinerlei Steuern und Kosten, sofern nicht anderweitig angegeben. Sie sind für
            alle Steuern und alle anderen Kosten verantwortlich, welche durch die Nutzung der Dienstleistungen anfallen
            (zum Beispiel Datengebühren und Währungsverluste). Sie bezahlen die Gebühren in der Währung, welche
            GlobalEnglish für Ihr Konto angegeben hat. GlobalEnglish behält sich das Recht vor, die angegebene Währung
            jederzeit zu ändern.
            <br />
            <br />
            <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>Zahlung </b>
            <br />
            <br /> Die Zahlung ist bei Kauf einer Abonnement-Lizenz fällig. Zur Abwicklung Ihrer Zahlung haben wir uns
            mit PayPal und Stripe zusammengetan. Wir erhalten keine Kreditkarteninformationen oder andere
            Zahlungsmethoden, welche Sie diesen Dienstanbietern zur Verfügung stellen, und bearbeiten diese auch nicht.
            <br />
            <br /> Alle Dienstleistungen für den gewählten Zeitraum werden im Voraus bezahlt (monatlich, jährlich oder
            anderweitig) und sind nicht erstattungsfähig. Dies betrifft auch Konten, die verlängert werden.
            <br />
            <br /> -----
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>Hinweis für Nutzer aus Kalifornien </b>
            <br />
            <br /> Sollten Sie in Kalifornien ansässig sein, können Sie aufgrund des California Civil Code Abschnitt
            1789.3 Informationen hinsichtlich der Offenbarung Ihrer Persönlichen Daten an Dritte für
            Direktmarketingzwecke der Dritten anfordern. Der 2021 in Kraft tretende California Consumer Privacy Act
            gewährt Ihnen bestimmte Rechte:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) das Recht auf Informationen darüber, welche Persönliche Daten über Sie gesammelt wurden, und auf den
              Erhalt von Kopien,
              <br />
              <br />
              (b) das Recht auf Löschung,
              <br />
              <br />
              (c) das Recht auf Informationen darüber, an welche Dritte Ihre Persönlichen Daten verkauft worden wären
              und das Recht auf Beantragung der Einstellung eines solchen Verkaufs und
              <br />
              <br />
              (d) das Recht auf Diskriminierungsfreiheit, falls Sie Ihre Verbraucherrechte ausüben.
            </span>
            <br /> Weiterhin ermöglicht der California Business and Profession Code Abschnitt 22581 gegenwärtig
            Einwohnern von Kalifornien, die minderjährig sind, die Entfernung von Inhalt zu fordern, den sie öffentlich
            auf der Webseite gepostet haben. Beachten Sie jedoch, dass GlobalEnglish eine vollständige oder umfassende
            Entfernung des von Ihnen geposteten Inhalts nicht sicherstellen kann, und dass Umstände bestehen können,
            unter welchen das Gesetz eine Entfernung nicht fordert oder zulässt, selbst wenn diese beantragt wird.
            <br />
            <br />
            Falls Sie einen Antrag zur Ausübung Ihrer Rechte stellen möchten:
            <br />
            <br /> Der Dienstleister der Webseite und verbundener Dienstleistungen ist GlobalEnglish Corporation, mit
            Sitz in 1875 South Grant Street, Suite 700, San Mateo, CA 94402, USA. Die E-Mail Anschrift lautet:
            privacy@globalenglish.com.
            <br />
            Zum Zeitpunkt dieser Bedingungen werden dem Verbraucher keine direkten Gebühren von der Webseite auferlegt.
            <br /> Falls Sie eine Beschwerde zu den auf der Webseite bereitgestellten Dienstleistungen haben oder
            weitergehende Informationen anfordern möchten, kontaktieren Sie uns bitte unter der vorstehenden
            Postanschrift mit einer Beschreibung Ihrer Anfrage. Wir können eine Antwort nicht in einem bestimmten
            Zeitraum garantieren. Sollten Sie weiterhin unzufrieden sein, können Sie die Complaint Assistance Unit of
            the Division of Consumer Services of the California Department of Consumer Affairs schriftlich unter 1625
            North Market Blvd., Suite N 112, Sacramento, CA 95834, oder telefonisch unter (916) 445-1254 oder (800)
            952-5210 kontaktieren.
          </p>
        </div>
      </div>
    </>
  );
}

export default DeDeContent;
