import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: "none",
      borderRadius: 0
    },
    fontFamily: ["NotoSans-Regular, Arial, Verdana"].join(","),
  },
  palette: {
    primary: {
      main: "#42BABD",
      contrastText: "#000",
    },
    secondary: {
      main: "#707070",
      contrastText: "#fff",
    },
    action: {
      hover: "#C6EAEB",
    }
  },
});

export default theme;
