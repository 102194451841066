// @ts-nocheck
import * as React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  Paper,
  DialogContent,
  Link,
  DialogContentText,
  TextField,
  withStyles,
  Accordion,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
  AccordionDetails,
  Grid,
  List,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { FormattedMessage } from "react-intl";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import messages from "../../bundles/common/intl/messages";
import "./ResetPassword.css";
import Cookies from "universal-cookie";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { Done } from "@material-ui/icons";
import { locales } from "../../bundles/common/intl/locales";
import { datadogLogs } from "@datadog/browser-logs";
const isUrlParams = window.location.search === "" ? false : true;
const universalCookies = new Cookies();

const logos = {
  1: `${process.env.REACT_APP_PRODUCT_IMG_URL}/edge.png?v=${process.env.IMG_VERSION}`,
  2: `${process.env.REACT_APP_PRODUCT_IMG_URL}/bloom.png?v=${process.env.IMG_VERSION}`,
  3: `${process.env.REACT_APP_PRODUCT_IMG_URL}/lingo.png?v=${process.env.IMG_VERSION}`,
  4: `${process.env.REACT_APP_PRODUCT_IMG_URL}/coach.png?v=${process.env.IMG_VERSION}`,
  5: `${process.env.REACT_APP_PRODUCT_IMG_URL}/step.png?v=${process.env.IMG_VERSION}`,
  6: `${process.env.REACT_APP_PRODUCT_IMG_URL}/step.png?v=${process.env.IMG_VERSION}`,
  7: `${process.env.REACT_APP_PRODUCT_IMG_URL}/motivator.png?v=${process.env.IMG_VERSION}`,
  8: `${process.env.REACT_APP_PRODUCT_IMG_URL}/executive_coach.png?v=${process.env.IMG_VERSION}`,
  9: `${process.env.REACT_APP_PRODUCT_IMG_URL}/reportcenter.png?v=${process.env.IMG_VERSION}`,
  10: `${process.env.REACT_APP_PRODUCT_IMG_URL}/versant_logo.png?v=${process.env.IMG_VERSION}`,
  11: `${process.env.REACT_APP_PRODUCT_IMG_URL}/virutalClassroom.png?v=${process.env.IMG_VERSION}`,
  12: `${process.env.REACT_APP_PRODUCT_IMG_URL}/blt.png?v=${process.env.IMG_VERSION}`,
  13: `${process.env.REACT_APP_PRODUCT_IMG_URL}/dashboard.png?v=${process.env.IMG_VERSION}`,
  14: `${process.env.REACT_APP_PRODUCT_IMG_URL}/professional.png?v=${process.env.IMG_VERSION}`,
  15: `${process.env.REACT_APP_PRODUCT_IMG_URL}/reach.png?v=${process.env.IMG_VERSION}`,
  16: `${process.env.REACT_APP_PRODUCT_IMG_URL}/gepro.png?v=${process.env.IMG_VERSION}`,
  17: `${process.env.REACT_APP_PRODUCT_IMG_URL}/ge_new_coach.png?v=${process.env.IMG_VERSION}`,
  18: `${process.env.REACT_APP_PRODUCT_IMG_URL}/ge_sprint.png?v=${process.env.IMG_VERSION}`,
  19: `${process.env.REACT_APP_PRODUCT_IMG_URL}/ge_coach_skills.png?v=${process.env.IMG_VERSION}`,
  20: `${process.env.REACT_APP_PRODUCT_IMG_URL}/sprint_business_en.png?v=${process.env.IMG_VERSION}`,
  21: `${process.env.REACT_APP_PRODUCT_IMG_URL}/solo_business_en.png?v=${process.env.IMG_VERSION}`,
  22: `${process.env.REACT_APP_PRODUCT_IMG_URL}/solo_business_de.png?v=${process.env.IMG_VERSION}`,
  23: `${process.env.REACT_APP_PRODUCT_IMG_URL}/solo_business_fr.png?v=${process.env.IMG_VERSION}`,
  24: `${process.env.REACT_APP_PRODUCT_IMG_URL}/solo_business_es.png?v=${process.env.IMG_VERSION}`,
  25: `${process.env.REACT_APP_PRODUCT_IMG_URL}/elevate_progress.png?v=${process.env.IMG_VERSION}`,
  26: `${process.env.REACT_APP_PRODUCT_IMG_URL}/sprint_business_de.png?v=${process.env.IMG_VERSION}`,
  27: `${process.env.REACT_APP_PRODUCT_IMG_URL}/sprint_business_fr.png?v=${process.env.IMG_VERSION}`,
  28: `${process.env.REACT_APP_PRODUCT_IMG_URL}/sprint_business_es.png?v=${process.env.IMG_VERSION}`,
};

const CustomPaper = withStyles({
  root: {
    maxWidth: "600px",
    width: "500px",
    padding: "30px 20px",
    borderRadius: "0px",
  },
})(Paper);

const useComponentWillMount = (func: any) => {
  const willMount = React.useRef(true);

  if (willMount.current) func();

  willMount.current = false;
};

const ResetPassword: React.FC = () => {
  const [userName, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [multipleUserAccounts, setMultipleUserAccounts] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [showIcon, setShowIcon] = React.useState(false);
  const [mobileHeaderHideAccordion, setMobileHeaderHideAccordion] =
    React.useState(false);
  const [userType, setUserType] = React.useState("");
  const [validatePassword, setValidatePassword] = React.useState({});
  const [passwordStrength, setPasswordStrength] = React.useState({});
  const [isPasswordStrong, setIsPasswordStrong] = React.useState(false);
  const [haloFlag, setHaloFlag] = React.useState(false);
  const [isInvalidLink, setIsInvalidLink] = React.useState(false);
  const [validateConfirmPassword, setValidateConfirmPassword] = React.useState(
    {}
  );
  const [userGeId, setUserGeId] = React.useState(null);
  const [error, setError] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showValidationError, setShowValidationError] = React.useState(false);
  const [licenses, setLicenses] = React.useState({});
  const [accordionLoader, setAccordionLoader] = React.useState(false);

  const checkHaloFlag = (features: string) => {
    return features.substr(16 - 1, 1);
  };

  const MultipleAccountUserCard: React.FC = ({ accounts }) => {
    function getLicenses(event, expanded, geId) {
      if (expanded) {
        setMobileHeaderHideAccordion(true);
        setUserGeId(geId);
        setAccordionLoader(true);
        const singleUserPplCall = `${process.env.REACT_APP_API_GATEWAY_URL}/get_user?geId=${geId}`;
        fetch(singleUserPplCall)
          .then((response) => response.json())
          .then((result) => {
            setAccordionLoader(false);
            setUserGeId(result.geId);
            setUsername(result.username);
            setLicenses(result.license);
            setHaloFlag(checkHaloFlag(result.legacyFeatures));
          })
          .catch((e) => {
            setAccordionLoader(false);
            setLoader(false);
            setError({
              message: (
                <FormattedMessage {...messages.somethingWentWrongError} />
              ),
            });
            datadogLogs.logger.error('Reset password exception', e);
          });
      } else {
        setAccordionLoader(false);
        setUserGeId(null);
      }
    }

    const accountItems = accounts.map((account) => (
      <Accordion
        expanded={account.geId === userGeId ? true : false}
        onChange={(e, expanded) => getLicenses(e, expanded, account.geId)}
        style={{
          margin: "15px 0",
          border: "1px solid rgba(128, 128, 128, 0.38)",
        }}
        elevation={0}
      >
        <AccordionSummary
          aria-label="Expand"
          aria-controls="additional-actions"
          id="multiple-user"
        >
          {account.geId === userGeId && accordionLoader ? (
            <></>
          ) : (
            <FormControlLabel
              aria-label="additional-actions"
              label={account.userName}
              control={
                <Checkbox
                  icon={
                    <CircleUnchecked
                      style={{ color: "#000000", opacity: 0.2 }}
                    />
                  }
                  checkedIcon={<CircleChecked style={{ color: "#42babd" }} />}
                  checked={account.geId === userGeId ? true : false}
                />
              }
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} alignItems="center" justify="center">
            {account.geId === userGeId && accordionLoader ? (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  className="CircularProgress"
                  style={{ color: "grey" }}
                  size={50}
                ></CircularProgress>
              </div>
            ) : (
              <>
                {licenses.length > 0 ? (
                  licenses.map((product) => (
                    <Grid item xs align="center">
                      <img
                        src={logos[product.productId]}
                        alt={product.productName}
                        width="100"
                      />
                    </Grid>
                  ))
                ) : (
                  <div>
                    <FormattedMessage {...messages.inactiveUserError} />
                  </div>
                )}
              </>
            )}
          </Grid>
        </AccordionDetails>
        {account.geId === userGeId && accordionLoader ? (
          <></>
        ) : (
          <Button
            centerRipple={false}
            disableRipple={true}
            onFocus={handleFocus}
            disableTouchRipple={true}
            style={{
              margin: "10px 30px",
              padding: "10px 30px",
              width: "90%",
              height: "55px",
              borderRadius: "0px",
              color: "#424242",
            }}
            className="loaderButton"
            variant="contained"
            disableElevation
            size="large"
            fullWidth
            onClick={selectUserFromMultipleAccount}
            // eslint-disable-next-line
            color="primary"
          >
            <FormattedMessage {...messages.choose} />
          </Button>
        )}
      </Accordion>
    ));

    return accountItems;
  };

  const handleClickOpen = () => {
    setOpen(true);
    setLoader(false);
    if (haloFlag === "1") {
      setTimeout(() => {
        
        window.location = '/';
      }, 2000);
    } else {
      setTimeout(() => {
        window.location = '/';
      }, 2000);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFocus = () => {
    setMobileHeaderHideAccordion(true);
  };

  React.useEffect(() => {
    if (password) {
      var passwordChecks = {};
      if (password.length >= 8 && password.length <= 32) {
        passwordChecks.conditionLength = true;
        setPasswordStrength({ passwordChecks });
      } else {
        passwordChecks.conditionLength = false;
        setPasswordStrength.conditionLength = false;
      }
      if (password.match(/[A-Z]+/g)) {
        passwordChecks.conditionUpperCase = true;
        setPasswordStrength({ passwordChecks });
      } else {
        passwordChecks.conditionUpperCase = false;
        setPasswordStrength({ passwordChecks });
      }
      if (password.match(/[a-z]+/g)) {
        passwordChecks.conditionLowerCase = true;
        setPasswordStrength({ passwordChecks });
      } else {
        passwordChecks.conditionLowerCase = false;
        setPasswordStrength({ passwordChecks });
      }
      // eslint-disable-next-line
      if (!!!password.match(new RegExp(/[,"'.`~<>;:\-/{/}\[\]+=|]+/g))) {
        if (password.match(/[0-9]+/g) || password.match(/[$!#&@?%*_]+/g)) {
          passwordChecks.conditionSpecialCharacters = true;
          setPasswordStrength({ passwordChecks });
        } else {
          passwordChecks.conditionSpecialCharacters = false;
          setPasswordStrength({ passwordChecks });
        }
      } else {
        setPasswordStrength({ passwordChecks });
      }

      if (
        passwordChecks.conditionLength &&
        passwordChecks.conditionUpperCase &&
        passwordChecks.conditionLowerCase &&
        passwordChecks.conditionSpecialCharacters
      ) {
        setIsPasswordStrong(true);
      } else {
        setIsPasswordStrong(false);
      }

      setValidatePassword(false);
    }

    if (confirmPassword) {
      setValidateConfirmPassword(false);
    }
  }, [password, confirmPassword]);

  React.useEffect(() => {
    if (password || userName || confirmPassword) {
      setShowValidationError(false);
    }
  }, [userName, password, confirmPassword]);

  function validateResetPassword() {
    if (password) {
      var passwordChecks = {};
      if (password.length >= 8 && password.length <= 32) {
        passwordChecks.conditionLength = true;
        setPasswordStrength({ passwordChecks });
      } else {
        passwordChecks.conditionLength = false;
        setPasswordStrength.conditionLength = false;
      }
      if (password.match(/[A-Z]+/g)) {
        passwordChecks.conditionUpperCase = true;
        setPasswordStrength({ passwordChecks });
      } else {
        passwordChecks.conditionUpperCase = false;
        setPasswordStrength({ passwordChecks });
      }
      if (password.match(/[a-z]+/g)) {
        passwordChecks.conditionLowerCase = true;
        setPasswordStrength({ passwordChecks });
      } else {
        passwordChecks.conditionLowerCase = false;
        setPasswordStrength({ passwordChecks });
      }
      // eslint-disable-next-line
      if (!!!password.match(new RegExp(/[,"'.`~<>;:\-/{/}\[\]+=|]+/g))) {
        if (password.match(/[0-9]+/g) || password.match(/[$!#&@?%*_]+/g)) {
          passwordChecks.conditionSpecialCharacters = true;
          setPasswordStrength({ passwordChecks });
        } else {
          passwordChecks.conditionSpecialCharacters = false;
          setPasswordStrength({ passwordChecks });
        }
      } else {
        setPasswordStrength({ passwordChecks });
      }

      if (
        passwordChecks.conditionLength &&
        passwordChecks.conditionUpperCase &&
        passwordChecks.conditionLowerCase &&
        passwordChecks.conditionSpecialCharacters
      ) {
        setIsPasswordStrong(true);
      } else {
        setIsPasswordStrong(false);
      }
      setValidatePassword(false);
    }
    if (confirmPassword) {
      setValidateConfirmPassword(false);
    }

    if (!confirmPassword || password !== confirmPassword) {
      setValidateConfirmPassword(true);
    }
  }

  function backBtnClick() {
    setShowIcon(false);
    setMobileHeaderHideAccordion(true);
    setUserType("multiple");
    if (password) {
      setPassword("");
      setIsPasswordStrong(false);
      setPasswordStrength({});
    }
    if (confirmPassword) {
      setConfirmPassword("");
    }
  }

  function selectUserFromMultipleAccount() {
    setMobileHeaderHideAccordion(true);
    setUserType("single");
  }

  function updatePassword() {
    setMobileHeaderHideAccordion(true);
    validateResetPassword();
    if (password === confirmPassword && isPasswordStrong) {
      setLoader(true);
      const updatePwdUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/update_password?geId=${userGeId}&password=${password}`;
      fetch(updatePwdUrl)
        .then((response) => {
          if (!response.ok) throw response;
          return response.json();
        })
        .then((data) => {
          setTimeout(() => {
            handleClickOpen();
          }, 3000);
        })
        .catch((e) => {
          setLoader(false);
          if (e.status === 406) {
            setConfirmPassword("");
            setPassword("");
            setShowValidationError(true);
            setError({
              message: <FormattedMessage {...messages.samePasswordError} />,
            });
          } else {
            setShowValidationError(true);
            setError({
              message: (
                <FormattedMessage {...messages.somethingWentWrongError} />
              ),
            });
          }
          datadogLogs.logger.error('Reset password exception', e);
        });
    }
  }

  function handleHideAccordion() {
    setMobileHeaderHideAccordion(false);
  }

  function getAppDomain() {
    var hostParts = window.location.hostname.split(".");
    return hostParts[1] + "." + hostParts[2];
  }

  /*
    Checks if the Url Params has an lang param to translate the page.
    NOTE: For multiple email associated users, no param will be passed. 
  */
  useComponentWillMount(() => {
    if (isUrlParams) {
      const urlParams = new URLSearchParams(window.location.search);
      const ulang = urlParams.get("ulang") || "EN";
      var domain = getAppDomain();
      let cookieUlangValue = "EN";
      if (Object.values(locales).indexOf(ulang) > -1) {
        cookieUlangValue = ulang;
      }
      if (universalCookies.get("ulang") !== cookieUlangValue) {
        universalCookies.set("ulang", cookieUlangValue, {
          path: "/",
          domain: domain,
          secure: true,
        });
        window.location.reload(false);
      }
    }
  });

  /* 
    Gets email, signature, timestamp from URL Params.
    Calls /get_users API to check the Count of the Email associated with the Email.
    If more than 1, check accountItems fn.
    If it's a single account, Calls /get_user to list down the licenses, obtain geId for updating the password.
  */
  React.useEffect(() => {
    if (isUrlParams) {
      setLoader(true);
      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get("email") || "";
      const signature = urlParams.get("signature") || "";
      const timestamp = urlParams.get("timestamp") || "";
      const ulang = urlParams.get("ulang") || "EN";
      var domain = getAppDomain();
      let cookieUlangValue = "EN";
      if (Object.values(locales).indexOf(ulang) > -1) {
        cookieUlangValue = ulang;
      }
      universalCookies.set("ulang", cookieUlangValue, {
        path: "/",
        domain: domain,
        secure: true,
      });
      const checkCountUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/get_users?email=${email}&signature=${signature}&timestamp=${timestamp}`;
      fetch(checkCountUrl)
        .then((response) => {
          if (!response.ok) throw response;
          return response.json();
        })
        .then((result) => {
          if (result.length > 1) {
            setLoader(false);
            setUserType("multiple");
            const userAccounts = result.map((user) => ({
              geId: user.geId,
              name: `${encodeURIComponent(user.firstName)} ${encodeURIComponent(
                user.lastName
              )}`,
              userName: user.username,
            }));
            setMultipleUserAccounts(userAccounts);
          } else if (result.totalCount === 0) {
            setLoader(false);
            setTimeout(() => {
              window.location.replace("/");
            }, 3000);
          } else {
            const singleUserPplCall = `${process.env.REACT_APP_API_GATEWAY_URL}/get_user?geId=${result[0].geId}`;
            fetch(singleUserPplCall)
              .then((response) => response.json())
              .then((result) => {
                setUserType("single");
                setUsername(result.username);
                setUserGeId(result.geId);
                setHaloFlag(checkHaloFlag(result.legacyFeatures)); // to redirect to halo or not.
                setLoader(false);
              })
              .catch((e) => {
                setLoader(false);
                setShowValidationError(true);
                setError({
                  message: (
                    <FormattedMessage {...messages.somethingWentWrongError} />
                  ),
                });
                datadogLogs.logger.error('Reset password exception', e);
                setTimeout(() => {
                  window.location.replace("/");
                }, 1000);
              });
          }
        })
        .catch((e) => {
          setLoader(false);
          if (e.status === 401) {
            setIsInvalidLink(true);
            setOpen(true);
            setTimeout(() => {
              window.location.replace("/");
            }, 3000);
          } else {
            setShowValidationError(true);
            setError({
              message: (
                <FormattedMessage {...messages.somethingWentWrongError} />
              ),
            });
            setTimeout(() => {
              window.location.replace("/");
            }, 3000);
          }
          datadogLogs.logger.error('Reset password exception', e)
        });
    } else {
      setLoader(true);
      setTimeout(() => {
        window.location.replace("/");
      }, 3000);
    }
  }, []);

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <DialogContentText
            style={{
              textAlign: "center",
            }}
          >
            {isInvalidLink ? (
              <span>
                Password reset link has expired.
                <br />
                <FormattedMessage
                  {...messages.redirectToLogin}
                  values={{
                    login: <Link href="/">login</Link>,
                  }}
                />
              </span>
            ) : (
              <>
                <span>
                  <b>
                    <FormattedMessage {...messages.passwordUpdated} />
                  </b>
                </span>
                <br />
                <FormattedMessage {...messages.profileSuccess} />
                <br />
                <FormattedMessage
                  {...messages.redirectToLogin}
                  values={{
                    login: <Link href="/">login</Link>,
                  }}
                />
              </>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <div className="WelcomeTextBlock">
        <h1 style={{ paddingLeft: userType === "multiple" ? "0px" : "25px" }}>
          {userType === "multiple" ? (
            <FormattedMessage {...messages.resetPasswordTitle} />
          ) : (
            <>
              <FormattedMessage {...messages.resetPassword} />
            </>
          )}
        </h1>
        <br />
      </div>
      <div className="LoginForm-Container">

        <CustomPaper
          className="CustomPaper"
          style={{
            overflow: userType === "single" ? "unset" : "scroll",
            height: userType === "multiple" ? "325px" : "auto",
          }}
        >
          <div className="modalBody">
            {loader ? (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  padding: "100px 0px",
                }}
              >
                <CircularProgress
                  className="CircularProgress"
                  style={{ color: "grey" }}
                  size={50}
                ></CircularProgress>
              </div>
            ) : (
              <>
                <div className="login-form">
                  {userType === "multiple" &&
                    multipleUserAccounts.length > 0 ? (
                    <MultipleAccountUserCard accounts={multipleUserAccounts} />
                  ) : (
                    <>
                      <TextField
                        id="lEmail"
                        label={<FormattedMessage {...messages.username} />}
                        variant="outlined"
                        value={userName}
                        disabled
                        fullWidth
                        onFocus={handleFocus}
                        margin="normal"
                        color="primary"
                      />
                      <TextField
                        id="lPassword"
                        label={<FormattedMessage {...messages.newPassword} />}
                        variant="outlined"
                        value={password}
                        onFocus={handleFocus}
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        error={validatePassword && !error ? true : false}
                        margin="normal"
                        color="primary"
                      />
                      {password && password.length && !isPasswordStrong && (
                        <div className="passwordInsTitle">
                          <FormattedMessage {...messages.passwordMustInclude} />
                          <List dense>
                            <ListItem
                              alignItems="center"
                              style={{
                                color:
                                  passwordStrength &&
                                    passwordStrength.passwordChecks &&
                                    passwordStrength.passwordChecks
                                      .conditionLength
                                    ? "green"
                                    : "red",
                                fontFamily: "NotoSans-Regular",
                                fontSize: "14px",
                              }}
                            >
                              <ListItemIcon style={{ minWidth: "20px" }}>
                                {passwordStrength &&
                                  passwordStrength.passwordChecks &&
                                  passwordStrength.passwordChecks
                                    .conditionLength ? (
                                  <Done
                                    style={{ width: "15px", color: "green" }}
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    style={{ width: "10px" }}
                                  />
                                )}
                              </ListItemIcon>
                              <FormattedMessage {...messages.conditionLength} />
                            </ListItem>
                            <ListItem
                              alignItems="center"
                              style={{
                                color:
                                  passwordStrength &&
                                    passwordStrength.passwordChecks &&
                                    passwordStrength.passwordChecks
                                      .conditionUpperCase
                                    ? "green"
                                    : "red",
                                fontFamily: "NotoSans-Regular",
                                fontSize: "14px",
                              }}
                            >
                              <ListItemIcon style={{ minWidth: "20px" }}>
                                {passwordStrength &&
                                  passwordStrength.passwordChecks &&
                                  passwordStrength.passwordChecks
                                    .conditionUpperCase ? (
                                  <Done
                                    style={{ width: "15px", color: "green" }}
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    style={{ width: "10px" }}
                                  />
                                )}
                              </ListItemIcon>
                              <FormattedMessage
                                {...messages.conditionUppercase}
                              />
                            </ListItem>
                            <ListItem
                              alignItems="center"
                              style={{
                                color:
                                  passwordStrength &&
                                    passwordStrength.passwordChecks &&
                                    passwordStrength.passwordChecks
                                      .conditionLowerCase
                                    ? "green"
                                    : "red",
                                fontFamily: "NotoSans-Regular",
                                fontSize: "14px",
                              }}
                            >
                              <ListItemIcon style={{ minWidth: "20px" }}>
                                {passwordStrength &&
                                  passwordStrength.passwordChecks &&
                                  passwordStrength.passwordChecks
                                    .conditionLowerCase ? (
                                  <Done
                                    style={{ width: "15px", color: "green" }}
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    style={{ width: "10px" }}
                                  />
                                )}
                              </ListItemIcon>
                              <FormattedMessage
                                {...messages.conditionLowercase}
                              />
                            </ListItem>
                            <ListItem
                              alignItems="center"
                              style={{
                                color:
                                  passwordStrength &&
                                    passwordStrength.passwordChecks &&
                                    passwordStrength.passwordChecks
                                      .conditionSpecialCharacters
                                    ? "green"
                                    : "red",
                                fontFamily: "NotoSans-Regular",
                                fontSize: "14px",
                              }}
                            >
                              <ListItemIcon style={{ minWidth: "20px" }}>
                                {passwordStrength &&
                                  passwordStrength.passwordChecks &&
                                  passwordStrength.passwordChecks
                                    .conditionSpecialCharacters ? (
                                  <Done
                                    style={{ width: "15px", color: "green" }}
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    style={{ width: "10px" }}
                                  />
                                )}
                              </ListItemIcon>
                              <FormattedMessage
                                {...messages.conditionSpecialCharacters}
                              />
                            </ListItem>
                          </List>
                        </div>
                      )}
                      {validatePassword && (
                        <div
                          className="errorMsg"
                          style={{ color: "red", fontSize: "0.75rem" }}
                        >
                          <span>
                            {!password && !error ? (
                              <FormattedMessage
                                {...messages.passwordRequiredError}
                              />
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                      )}
                      <TextField
                        id="lPassword"
                        type="password"
                        onFocus={handleFocus}
                        label={
                          <FormattedMessage {...messages.confirmNewPassword} />
                        }
                        variant="outlined"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        fullWidth
                        error={validateConfirmPassword && !error ? true : false}
                        margin="normal"
                        color="primary"
                      />
                      {confirmPassword &&
                        password !== confirmPassword &&
                        isPasswordStrong && (
                          <div className="passwordInsTitle">
                            <List dense>
                              <ListItem
                                alignItems="center"
                                style={{
                                  color:
                                    confirmPassword.length &&
                                      password === confirmPassword
                                      ? "green"
                                      : "red",
                                  fontFamily: "NotoSans-Regular",
                                  fontSize: "14px",
                                }}
                              >
                                <ListItemIcon style={{ minWidth: "20px" }}>
                                  {confirmPassword.length &&
                                    password === confirmPassword ? (
                                    <Done
                                      style={{ width: "15px", color: "green" }}
                                    />
                                  ) : (
                                    <FiberManualRecordIcon
                                      style={{ width: "10px" }}
                                    />
                                  )}
                                </ListItemIcon>
                                <FormattedMessage
                                  {...messages.passwordsDontMatch}
                                />
                              </ListItem>
                            </List>
                          </div>
                        )}
                      {showValidationError && error && (
                        <div
                          className="errorMsg"
                          style={{ color: "red", fontSize: "0.75rem" }}
                        >
                          <span>{error.message}</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {userType === "multiple" ? (
                  <> </>
                ) : (
                  <div className="buttonsBlock loginB">
                    {!loader && multipleUserAccounts.length > 0 && (
                      <Button
                        centerRipple={false}
                        disableRipple={true}
                        disableTouchRipple={true}
                        className="loaderButton"
                        variant="contained"
                        disableElevation
                        size="large"
                        onMouseEnter={() => setShowIcon(true)}
                        onMouseLeave={() => setShowIcon(false)}
                        startIcon={showIcon ? <ChevronLeftIcon /> : null}
                        style={{
                          width: "150px",
                          marginRight: "10px",
                          borderRadius: "0px",
                          color: "#424242",
                        }}
                        onClick={backBtnClick}
                      >
                        <FormattedMessage {...messages.back} />
                      </Button>
                    )}
                    <Button
                      centerRipple={false}
                      disableRipple={true}
                      disableTouchRipple={true}
                      onFocus={handleFocus}
                      className="loaderButton"
                      variant="contained"
                      disableElevation
                      size="large"
                      fullWidth
                      style={{
                        height: "55px",
                        borderRadius: "0px",
                        color: "#424242",
                      }}
                      disabled={showValidationError || loader}
                      onClick={updatePassword}
                      // eslint-disable-next-line
                      color="primary"
                    >
                      <FormattedMessage {...messages.update} />
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </CustomPaper>

      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
