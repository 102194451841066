// @ts-nocheck
import React from 'react';

function KoKoContent() {
  return (
    <div id="agreement_container">
      <div style={{ padding: '1% 5% 0 5%' }}>
        <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
          <b>사용자 계약서</b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
          <b>도입 </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          웹사이트 또는 모바일 애플리케이션으로 GlobalEnglish 서비스(이하 총칭하여 "서비스")를 이용해주셔서 감사합니다.
          <br />
          <br /> 본사의 서비스는 웹사이트와 모바일 애플리케이션을 통해 생성되었거나 이용할 수 있게 된 데이터, 텍스트,
          온라인 시험, 디자인, 비디오, 오디오 클립, 음성 녹음, 문자로 작성된 게시물과 댓글, 대본, 그래픽, 이미지, 그러한
          자료의 선별 및 배치, 소프트웨어, 튜토리얼, 수업, 코칭, 훈련, 소통, 상호작용 기능 등을 포함한 영어 학습 도구와
          온라인 솔루션(이하 총칭하여 "컨텐츠")입니다. 본사 웹사이트에 접속하여 본사에 관한 정보를 읽거나 서비스에 대해
          문의하시는 것 역시 서비스의 일부분으로 간주됩니다.
          <br />
          <br /> 계속 진행하시기 전에 다음을 잘 읽어주신 다음 아래 조건을 이해하고 동의해주시기 바랍니다.
          <br />
          <br />
          <b>
            귀하의 나이가 18세 미만이라면 본사 서비스를 이용하시기 전에 부모님 또는 보호자와 본 계약서를 읽고 동의를
            받아주시기 바랍니다. 본사의 서비스를 이용하시면 귀하의 나이가 18세 이상이거나 부모님 또는 보호자로부터
            그러한 동의를 받고 GLOBALENGLISH와 본 계약을 체결하도록 허락을 받았음을 인정하시게 됩니다.{' '}
          </b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
          <b>일반 조항</b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 1. 법적 구속력을 가진 계약&nbsp;</b>
          <br />
          <br />
          <b>
            {' '}
            귀하의 본사 웹사이트 접속과 GLOBALENGLISH의 서비스 이용은 아래 사용자 계약, 개인정보 보호정책 및 쿠키 정책이
            적용됩니다(이 세 가지 문서를 총칭하여 "이용 약관"이라 함). GLOBALENGLISH의 서비스를 접속, 방문, 등록 및/또는
            이용함으로써 귀하는 본사 서비스 이용 약관을 읽고 이해하였으며 약관의 취소불능한 법적 구속력의 적용에
            동의하며 GLOBALENGLISH와 구속력을 가진 계약을 체결할 것에 동의하게 됩니다.{' '}
          </b>
          <br />
          <br /> 본사의 정책 개요는
          <a
            href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
            rel="noopener noreferrer"
            target="_blank"
          >
            여기
          </a>{' '}
          에서 확인하실 수 있습니다. 이 정책들은 본 "사용자 계약" 또는 "계약"에 참조를 통해 통합되며 함께 이용약관을
          구성합니다.
          <br />
          <br />
          <b>
            본 계약서는 귀하와 본사가 상대방에게 제기한 청구를 해결하는 방법에 대한 조항이 포함되어 있습니다. 특히 아래
            보증 한계, 책임 제한, 법적 분쟁 조항을 확인해주십시오. 계약서에는 또한 제한적으로 예외를 적용하여 귀하께서
            본사에 구속력을 가진 최종 중재를 제출하도록 하는 중재 합의가 포함됩니다. 귀하께서는 고소인 또는 집단
            소송이나 대표 소송의 구성원이 아닌 개인 자격으로만 청구를 제기하실 수 있습니다. 귀하는 개인 자격으로만
            구제조치를 요청할 권한이 있습니다(금전적 구제조치, 금지 명령, 선언적 구제조치).
            <br />
            <br /> 언제든지 본문의 어떠한 부분이라도 동의하지 않으신다면 서비스를 이용하지 마시고 본사 웹사이트를 더
            이상 탐색하지 마십시오.
          </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. 책임 </b>
          <br />
          <br /> 본 계약은 본사의 서비스 이용자와 사이트 방문자 모두에게 적용됩니다. 시험판 이용자를 포함하여 본사에
          직접 등록한 개인(
          <b>“B2C 거래”</b>), 고용인을 통해 본사에 등록한 개인(
          <b>“B2B 거래”</b>)은
          <b> “사용자”</b>라 하며 본사 웹사이트에 등록하지 않은 사용자와 방문자는
          <b>“방문자”</b>라고 합니다.
          <b> “사용자”</b>는 또한 교사, 코치, 학습 전문가, 기타 다른 사용자에게 서비스를 제공하기 위해 GlobalEnglish
          사이트에 접속한 사람을 포함합니다. 사용자와 방문자 모두
          <b>“귀하”</b>라는 호칭으로 부릅니다.
          <br />
          <br />본 계약은 모든 GlobalEnglish 서비스에 적용됩니다.
          <br />
          <br /> 사용자로 처음 등록하실 때와 본사 서비스에 접속하실 때, 본사는 "동의" 상자를 클릭하거나 전자 서명 또는
          기타 인증 수단을 통해 계약 조항의 적용에 다시 동의하시도록 할 수 있습니다.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>3.이용 약관의 변경 </b>
          <br />
          <br />
          GlobalEnglish는 귀하의 서비스 이용에 적용되는 본 계약과 쿠키 정책, 개인정보 보호정책을 포함한 이용 약관을
          언제든지 수정할 수 있습니다. 그러한 수정은 서비스 내 게시, 귀하께서 서비스 계정과 관련하여 제공하신 이메일
          주소로 전자 메시지 전달, 기타 다른 수단을 통해 통지하였을 때 즉시 효력을 갖습니다. 귀하께서 고용인이나 회사를
          통해 본사 서비스에 등록하신 경우, 귀하의 고용인이나 회사가 귀하께 본사의 이용 약관 변경을 통지해드릴 수
          있습니다.
          <br />
          <br /> 이러한 변경에 동의하지 않으시면 계정을 폐쇄하고 본사 웹사이트 접속을 중단하실 수 있습니다. 하지만
          본사가 이용 약관 변경 통지를 발표하거나 전달한 후 본사 서비스를 계속 이용하시면 귀하께서 그러한 이용 약관
          수정을 인정하신 것으로 간주합니다.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4.제삼자 이용 약관 </b>
          <br />
          <br />
          서비스에는 다른 웹사이트 링크 ("제삼자 사이트") 및 제삼자의 소유이거나 제삼자가 만든 글, 사진, 텍스트, 번역,
          그래픽, 사진, 디자인, 사운드, 비디오, 정보, 기타 컨텐츠나 아이템("제삼자 컨텐츠")이 포함될 수 있습니다. 그러한
          제삼자 사이트와 제삼자 컨텐츠는 본사가 정확성과 적합성, 완전성을 조사, 모니터링, 점검하지 않으며 본사는
          웹사이트를 통해 접속되는 제삼자 사이트 또는 해당 웹사이트에 게시되어 있거나 해당 웹사이트를 통해 이용할 수
          있는 어떠한 제삼자 컨텐츠에 대해서도 책임을 지지 않습니다.
          <br />
          <br /> 제삼자 사이트나 제삼자 컨텐츠의 포함, 링크, 이용 허가는 GlobalEnglish의 승인 또는 추천을 의미하지
          않습니다. GlobalEnglish는 언제든지 사전 통지 없이 그러한 링크 이용을 종료할 권한을 갖고 있습니다. 서비스에
          포함된 회사, 조직, 제품, 서비스, 기타 정보에 대한 참조는 GlobalEnglish의 추천, 후원, 권고, 기타 어떠한 관계를
          의미하지 않습니다.
          <br />
          <br /> 본사의 웹사이트에서 나가 제삼자 사이트에 접속하시거나 제삼자 컨텐츠를 이용하실 때 귀하는 본인이 위험을
          부담하시게 되며 본사의 약관과 정책이 귀하의 제삼자 사이트 이용에 적용되지 않는다는 점을 인지하셔야 합니다.
          이용하시기 전에 그러한 제삼자 제품과 서비스에 적용되는 약관 및 정책을 확인하십시오.
          <br />
          <br /> 본사는 Android Market과 iTunes Store(그러한 상점을 운영하는 법인과 함께 총칭하여 "제공자")를 통해
          모바일 애플리케이션을 제공합니다. 그러한 모바일 앱의 이용은 또한 App Store 서비스 약관에 규정된 이용 규칙을
          포함한 제공자의 약관이 적용됩니다. 이러한 모바일 앱을 iTunes Store에서 다운로드하셨다면 App Store 서비스
          약관을 읽고 동의하시게 됩니다. Android Market에서 앱을 다운로드하셨다면 Android Market 서비스 약관을 읽고
          동의하시게 됩니다. 제공자는 귀하의 서비스 이용과 관련하여 어떠한 유지보수나 지원 서비스도 제공하지 않을 것이며
          제공할 의무가 없습니다. 제공자는 서비스에 대한 어떠한 보증도 제공하지 않습니다. 귀하께서 iTunes Store에서
          다운로드한 모바일 앱이 앱에 적용되는 보증을 준수하지 않을 경우, 귀하께서는 Apple에 이를 통지하고 Apple은 해당
          앱의 구입 가격을 환불합니다(유료로 구입한 경우). 이러한 약관은 귀하의 모든 모바일 앱 및 본사의 기타 서비스
          이용에 적용됩니다.
          <br />
          <br /> 귀하는 GlobalEnglish가 제삼자 웹사이트나 애플리케이션의 컨텐츠, 제품, 서비스, 성능에 대한 책임이 없음에
          동의하시게 됩니다. 귀하께서는 제삼자 웹사이트나 애플리케이션에 대하여 GlobalEnglish에 대한 모든 청구를
          면책하시게 됩니다. 특히 GLOBALENGLISH는 귀하나 다른 당사자가 어떠한 방식으로든 귀하의 컴퓨터에 설치한 쿠키, 웹
          비콘, 클리어 GIF, 기타 코드 및 해당 코드를 가진 웹사이트 컨텐츠의 관계에 대해 책임을 지지 않습니다. 예를 들어
          귀하께서 웹사이트 상의 링크를 통해 제삼자 사이트에 접속하였을 때, 귀하의 브라우저나 기타 컴퓨터 상의 쿠키는
          참조 헤더를 해당 사이트에 보내 귀하의 검색 내역 일부나 귀하의 방문이 시작된 URL을 알릴 수 있습니다.
          GlobalEnglish는 그러한 전송이 GlobalEnglish에 관련된 정보를 포함하고 있거나 GlobalEnglish에서 시작되었다
          하더라도 전송에 관련하여 귀하에 대한 어떠한 책임도 없습니다.
          <br />
          <br /> 귀하께서 GOOGLE이 제공하는 번역을 이용하시는 경우: GOOGLE은 정확성과 신뢰성에 대한 보증, 상업성, 특정
          목적의 적합성, 비침해성에 대한 암묵적 보증을 포함하여 번역에 관련된 모든 명시적, 암묵적 보증을 부인합니다.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>5. 서비스 자격 기준 </b>
          <br />
          <br />
          <b>5.1 아동 개인정보 보호 및 본사 서비스 이용 제한 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            서비스에 접속하는 국가의 최소 연령에 도달하지 않은 사람은 서비스를 이용할 수 없습니다. 예를 들어 유럽연합,
            유렵경제구역, 스위스의 "최소 연령"은 특정 국가 법률에 별도로 규정되어 있지 않은 한 16세입니다. 미국의 경우
            최소 연령은 13세입니다. 최소 연령 미만인 자의 서비스 등록이나 접속은 금지되며 본 약관의 위반으로 성립됩니다.
            <br />
            <br /> 귀하는 서비스 이용 시 본인이 13세 이상임을 대표 및 보증하게 됩니다.
          </span>
          <br />
          <br />
          <b>5.2 학교 및 교사 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            13세 미만의 학생이 GlobalEnglish 계정을 만들도록 하는 학교, 교육구, 교사는 본인이 미국 아동 온라인 개인정보
            보호법 (U.S. Children's Online Privacy Protection Act, "COPPA")을 준수하고 적용 범위 내에서 가족교육권 및
            개인정보보호법 (Family Educational Rights and Privacy Act, "FERPA")을 준수할 것에 동의합니다. 이에 따라
            학교, 교육구, 교사는 학생의 부모나 보호자에게 GlobalEnglish가 수집하는 개인 식별 정보를 알려야 하며 학생이
            계정을 만들어 서비스를 이용하기 전에 부모/보호자의 동의를 받아야 합니다. 그러한 동의를 받기 전에 학교,
            교육구, 교사는 부모/보호자에게 본사의 개인정보 보호정책 사본을 제공하고 파일 상에서 모든 동의를 받아 본사의
            요청 시 이를 제출해야 합니다. COPPA 준수에 대한 정보는
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
              rel="noopener noreferrer"
              target="_blank"
            >
              연방거래위원회 웹사이트
            </a>
            를 참조하십시오.
            <br />
            <br /> 귀하께서 미국 외 국가에 거주하실 경우, 본사는 이와 유사한 법률이 적용되는 학생의 부모나 보호자로부터
            필요한 동의 또는 승인을 받는 일을 귀하께 의지하게 되며 귀하는 그러한 법률을 준수할 책임에 동의하시게 됩니다.
          </span>
          <br />
          <br />
          <b>5.3 부모 동의 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            <b>
              본사의 서비스를 이용하시면 귀하의 나이가 18세 이상이거나 부모님 또는 보호자로부터 그러한 동의를 받고
              GLOBALENGLISH와 본 계약을 체결하도록 허락을 받았음을 인정하시게 됩니다.
              <br />
              <br /> 본사는 부모, 보호자 및/또는 권한을 가진 학교 직원의 지도와 감독, 동의 하에만 아동이 서비스를 이용할
              것으로 기대하고 있습니다. GlobalEnglish는 미성년자가 이용약관에 명시된 본인의 권리와 책임을 이해하는
              경우에만 서비스를 이용하는 것을 부모, 보호자, 학교에 의지합니다.
              <br />
              <br /> GlobalEnglish는 단독 결정에 따라 어떠한 개인이나 법인에 대한 서비스 제공을 거부할 수 있으며
              언제든지 자격 기준을 변경할 수 있습니다.{' '}
            </b>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6.개인정보 </b>
          <br />
          <br />
          <b>6.1 개인정보 보호 준칙 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            개인정보 보호 준칙은
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              여기
            </a>
            에서 확인하실 수 있습니다.
            <br />
            <br /> 본사는 귀하께서 서비스에 처음 접속하실 때나 개인정보 보호정책 업데이트 시 동의하신 개인정보
            보호정책에 따라 개인정보를 처리합니다.
          </span>
          <br />
          <br />
          <b>6.2귀하의 제공 정보. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            서비스를 이용하시기 위해 귀하는 유효한 계정을 만들어야 합니다. 본사 서비스에 등록하시려면 GlobalEnglish에
            사용자 ID, 이메일 주소, 기타 개인식별 정보로 간주되는 정보("개인정보")를 제공하셔야 합니다. 본사가
            귀하로부터 수집하는 일부 정보는 귀하께서 문의하신 정보를 단순히 보내드리는 과정에서도 필요하며 일부 정보는
            선택 사항입니다. 서비스는 또한 귀하께서 본인과 조직에 대한 정보를 표시할 수 있도록 합니다.
            <br />
            <br /> 귀하는 다음에 동의합니다. (i) 서비스 등록 양식의 안내에 따라 본인에 대하여 정확하고 완전한 최신
            정보를 제공하며 B2B 거래의 경우, 귀하의 조직에 대한 정보를 제공합니다, (ii) 귀하의 비밀번호와 기타 계정 보안
            관련 정보를 기밀로 유지합니다, (iii) GlobalEnglish에 제공한 개인정보와 기타 정보를 유지하고 즉시
            업데이트하여 정보를 정확한 최신 상태로 유지합니다, (iv) 귀하 계정의 모든 이용과 계정을 통해 이루어지는 모든
            행위에 대한 완전한 책임을 인정합니다.
            <br />
            <br /> GlobalEnglish는 귀하께서 등록을 위해 제공하신 개인정보의 정확성을 확인할 의무가 없습니다.
          </span>
          <br />
          <br />
          <b>6.3 개인정보의 국제 전송 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            <b>
              GlobalEnglish는 서비스를 제공하기 위해 전세계 인력에 의지하며 스토리지와 기타 기술 관련 및 교육 서비스를
              위해 제삼자 공급업체, 호스팅 파트너를 이용합니다. 귀하의 개인정보는 따라서 여러 국가의 GlobalEnglish의
              직원과 계약업체에 전달되고 처리되며 이들 중 일부는 귀하의 국가에서 제공하는 것과 같은 수준의 개인정보
              보호를 제공하지 못할 수 있습니다. 본사 서비스에 접속, 등록, 이용함으로써 귀하는 귀하의 개인정보를 서비스를
              위해 GlobalEnglish와 그 계열사, 계약업체가 위치한 여러 국가에 전송하여 처리하도록 할 것에 동의하시게
              됩니다.{' '}
            </b>
          </span>
          <br />
          <br />
          <b>6.4 통지 및 메시지. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            귀하는 서비스에 서비스 발표, 행정 메시지 등 GlobalEnglish가 전달하는 소통활동이 포함되는데 동의하며 그러한
            소통활동은 서비스의 일부분으로 간주되어 수신을 거부할 수 없음을 동의하시게 됩니다.
            <br />
            <br /> 귀하는 GlobalEnglish가 다음과 같은 방법으로 귀하께 통지와 메시지를 제공하도록 하는데 동의합니다. (i)
            서비스 내에서, (ii) 귀하께서 GlobalEnglish에 제공한 연락처로 (예: 이메일 또는 휴대전화 번호). 귀하는 귀하의
            연락처 정보를 항상 최신 상태로 유지할 것에 동의합니다.
          </span>
          <br />
          <br />
          <b>6.5 타인의 개인정보 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            귀하께서 서비스 이용 과정에서 다른 사용자의 정보를 얻게 되신 경우, 귀하는 이 정보를 귀하께 제공된 목적에
            따라서만 이용할 것에 동의합니다. 귀하는 사용자 정보를 서비스와 무관한 다른 목적으로 제삼자에게 공개, 판매,
            유포하실 수 없습니다. 귀하는 해당 사용자로부터 동의를 받지 않은 한 해당 정보를 마케팅 목적으로 이용하실 수
            없습니다.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. 사용자 라이선스 </b>
          <br />
          <br />
          <b>7.1 본사 서비스 사용자 라이선스 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            귀하는 비상업적인 개인 용도로 서비스에 접속하여 이용할 수 있는 제한, 개인, 비독점, 양도 불능, 재 라이선스
            불능, 이전 불능 라이선스를 제공받습니다. GlobalEnglish는 단독 결정에 따라 사전 통지 없이 비정기적으로 서비스
            이용을 임시적 또는 영구적으로 중단하거나 서비스 기능을 변경할 수 있습니다.
            <br />
            <br /> 서비스를 이용하시려면 직접 또는 웹 컨텐츠 접속 기기를 통해 월드와이드웹에 접속하셔야 합니다. 또한
            컴퓨터와 모뎀 또는 기타 접속 기기를 포함하여 월드와이드웹에 접속하는데 필요한 모든 장비를 갖추어야 합니다.
            귀하는 정확성, 완전성, 유용성에 대한 의지를 포함하여 서비스 이용에 관련된 모든 위험성을 평가하고 이를 감수할
            것에 동의합니다. GlobalEnglish나 그 라이선스 제공자는 컨텐츠의 정확성과 완전성을 보증하지 않습니다.
            <br />
            <br /> 서비스는 위험 환경(예: 운전 중 사용, 물속이나 주변에서 사용)에서 이용하도록 디자인하거나 그에 대한
            면허를 취득하지 않았습니다.
            <br />
            <br />
            서비스 이용이 금지되었거나 서비스의 제안, 판매, 제공이 준거법, 규칙, 규정에 저촉되는 곳에서는 서비스 접속
            권리가 취소됩니다.
            <br />
            <br />
            이용 약관에 제시된 목적이 아닌 다른 목적으로 서비스를 이용할 경우 GlobalEnglish의 이용 약관 위반 및/또는
            라이선스 제공자, 계약업체의 저작권 및 독점권 위반으로 간주됩니다. GlobalEnglish는 귀하의 서비스 이용이 본
            이용 약관을 위반한 것으로 합리적으로 의심되는 경우 귀하의 계정을 일시 정지하거나 폐쇄하고 현재 또는 미래의
            모든 서비스 제공을 거부할 권리를 갖습니다. 귀하는 GlobalEnglish가 서비스나 서비스 일부의 수정, 일시 정지,
            중단에 대한 책임을 지지 않을 것에 동의합니다.
          </span>
          <br />
          <br />
          <b>7.2 사용자 컨텐츠</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            서비스는 귀하와 다른 사용자들에게 데이터 및 정보를 생성하고(예: 본사의 시험 응시) 자료를 커뮤니티 기반
            서비스("사용자 컨텐츠")에 업로드할 수 있도록 합니다. 본 약관으로 본사에 부여된 권리에 따라 귀하는 본인의
            사용자 컨텐츠에 대한 완전한 소유권을 유지합니다.
            <br />
            <br /> 본사 서비스에 사용자 컨텐츠를 업로드하시면 GlobalEnglish에 서비스 제공과 관련하여 사용자 컨텐츠를
            저장, 이용, 복제, 공연, 전시, 수정, 번역, 발췌(전체 또는 일부), 처리 및 유포할 수 있도록 하는 취소 불능,
            영속적, 비독점, 양도 가능, 대금 완불, 전세계 라이선스를 제공하시게 됩니다(재 라이선스 권리 포함).
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) 법적 요청, 사법 명령 및 기타 이와 유사한 정부 요청에 따른 법률 절차 준수
              <br />
              (b) 계약 이행
              <br />
              (c) 컨텐츠의 제삼자 권리 침해 청구 대응
              <br />
              (d) GlobalEnglish와 그 직원, 사용자 및 대중의 권리, 재산권, 개인적 안정 보호.
            </span>
            <br />
            <br /> 귀하께서 사용자 컨텐츠를 다른 서비스 사용자와 공유하기로 선택하시면 이 사용자가 귀하의 사용자
            컨텐츠를 열람하고 허용된 범위 내에서 귀하와 해당 사용자 컨텐츠에 협력하도록 하는데 동의하시게 됩니다.
            <br />
            <br /> GlobalEnglish는 해당 사용자 컨텐츠를 선별, 검토하거나 정확성 검증, 수정, 삭제할 의무가 없음을
            알아두십시오. 하지만 본사는 사용자 컨텐츠를 모니터링하여 절대적 임의 결정권에 따라 모욕적인 컨텐츠 또는 이용
            약관을 위반한 컨텐츠로 판단할 권한을 갖습니다.
            <br />
            <br /> GlobalEnglish는 사용자 컨텐츠의 백업 또는 보관 의무가 없습니다. 귀하는 단독으로 비용을 부담하여
            웹사이트에 게시 또는 저장하거나 GlobalEnglish에 제공한 사용자 컨텐츠의 사본 백업과 교체 책임을 갖습니다.
            <br />
            <br /> 사용자 컨텐츠는 GlobalEnglish의 견해를 반영하지 않을 수도 있습니다. 귀하는 사용자 컨텐츠가 해당
            사용자 컨텐츠를 만든 사용자의 단독 책임임을 인정합니다. 귀하는 서비스를 이용하여 모욕적이거나 불쾌한 사용자
            컨텐츠에 노출될 수 있음을 인정합니다.
            <br />
            <br /> 위에 명시된 사용자 컨텐츠에 제공된 라이선스와 함께 귀하께서 GlobalEnglish에 제공하신 모든 질문, 댓글,
            제안, 아이디어, 피드백, 기타 서비스 관련 정보("제출물") 는 기밀이 아니며, GlobalEnglish는 그러한 제출물을
            어떠한 목적으로든 상업성 여부에 관계 없이 귀하에게 보상을 하지 않고 무제한 이용하고 배포할 수 있습니다. 또한
            GlobalEnglish의 커뮤니티 기반 서비스나 제품을 통해 귀하께서 게시, 공개, 공유한 정보는 일반적인 의미의
            기밀정보의 정의에 포함되지 않습니다.
            <br />
            <br /> 귀하는 귀하의 사용자 컨텐츠를 포함한 웹사이트의 기술적 처리와 전송이 (a) 여러 네트워크를 통한 전송,
            (b) 네트워크 또는 기기 연결을 위한 기술 조건 준수 및 조정을 포함할 수 있음을 인정합니다.
            <br />
            <br />
            <b>
              GlobalEnglish는 사용자 컨텐츠에 대한 어떠한 대표나 보증도 하지 않습니다. 사용자 컨텐츠는 GlobalEnglish의
              견해를 반영하지 않을 수도 있습니다. 어떠한 경우에도 GlobalEnglish는 사용자 컨텐츠의 이용이나 손실로 인한
              청구, 손해배상에 대한 책임을 인정하지 않습니다.
            </b>
          </span>
          <br />
          <br />
          <b>7.3 사용자 의무 사항</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            귀하는 본인의 계정에서 이루어지는 모든 활동에 대한 책임이 있습니다. 귀하는 다음의 동의합니다.
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) 귀하의 계정 무단 이용이나 기타 보안 위반을 GlobalEnglish에 즉시 통지합니다,
              <br />
              (b) 각 세션 종료 시 귀하의 계정 접속을 종료합니다.
            </span>
            <br />
            <br />
            GlobalEnglish는 귀하의 미준수로 인해 발생하는 모든 손실이나 피해에 대한 책임을 질 수 없으며 책임을 지지
            않습니다.
            <br />
            <br />
            귀하는 본 서비스 약관이 본인에게 적용되는 모든 법률과 규칙, 규정을 모두 준수하는지 확인할 단독 책임이 있으며
            본 서비스 약관이나 서비스 이용이 금지되었거나 서비스의 제공이나 판매, 제안이 준거법이나 규칙, 규정에 저촉될
            경우 서비스 접속 권리가 철회됩니다.
          </span>
          <br />
          <br />
          <b>7.4 사용자 금지 사항 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            다음은 불법 또는 GlobalEnglish의 금지 사항 예시입니다. GlobalEnglish는 본사의 단독 결정에 따라 웹사이트나
            애플리케이션에서 모욕적인 컨텐츠의 제거, 해당 사용자의 계정 정지 또는 폐쇄, 사법 당국에 대한 신고 등 본
            조항을 위반한 사람을 대상으로 조사를 실시하고 적절한 법적 조치를 취할 권리를 보유합니다.
            <br />
            <br /> 귀하는 다음을 할 수 없습니다.
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) GlobalEnglish의 명시적 승인 없이 GlobalEnglish의 컨텐츠나 기타 서비스 자료의 개작, 다운로드, 수정,
              방송, 역 엔지니어링, 복제, 출판, 수정, 유포, 전시, 양도, 기타 복사 및/또는 유통
              <br />
              (b) 서비스의 일부분을 담는 프레임 또는 프레임 기술 이용
              <br />
              (c) 봇, 스파이더, 크롤러, 스파이웨어, 엔진, 장치, 소프트웨어 기타 다른 자동 장치, 유틸리티, 수작업 절차를
              통한 서비스 일부분의 수집, 획득, 이용, 접속, 기타 복제
              <br />
              (d) 서비스에 지장, 손해를 초래하거나 비활성화, 손상시킬 목적으로 서비스 이용 또는 서비스의 기능 이용,
              <br />
              (e) 상업적 목적을 위해 서비스를 판매, 라이선스, 대여, 기타 다른 방식으로 이용,
              <br />
              (f) 다른 사용자의 서비스 접속, 이용, 향유를 방해하는 활동에 참여
              <br />
              (g) (i) 불법, 유해, 위협, 학대, 괴롭힘, 과도한 폭력성, 비방, 외설, 도색, 명예 훼손, 타인의 개인정보
              침해(주소, 이메일, 전화번호, 기타 연락처 정보 등을 포함), 인종 혐오, 민족 혐오, 기타 불쾌한 컨텐츠에
              해당하는 사용자 컨텐츠, (ii) 어떠한 법률이나 계약 관계, 신탁 관계에 따라서도 전송할 권리가 없는 컨텐츠,
              (iii) 타인의 개인정보나 안전을 침해하거나 위협하는 컨텐츠, (iv) 타인의 지식재산권이나 기타 독점 권리를
              침해하는 컨텐츠, (v) 요청하지 않았거나 승인을 받지 않은 광고, 홍보 자료, 상업적 활동 및/또는 판매,
              "정크메일", "스팸", "체인 레터", "피라미드 계획", "컨테스트", "독점 내기", 기타 다른 유형의 호객으로
              성립되는 컨텐츠, (vi) 컴퓨터 소프트웨어나 하드웨어, 통신 장비를 파괴하거나 기능을 제한할 수 있는
              소프트웨어 바이러스, 기타 컴퓨터 코드, 파일, 프로그램을 포함한 컨텐츠, (vii) GlobalEnglish의 임의 단독
              판단에 따라 타인의 서비스 이용이나 향유를 제한하거나 침해하는 컨텐츠 또는 GlobalEnglish나 그 사용자에게
              모든 유형의 유해나 책임을 초래하는 컨텐츠를 게시, 이메일 전송, 기타 다른 방식으로 전송함,
              <br />
              (h) 허위 신원을 만들거나 본인의 신원정보를 왜곡함, 본인과 개인 또는 법인의 관계를 위장하거나 허위 진술함,
              <br />
              (i) 18세 미만인 사람의 개인 정보를 요구함,
              <br />
              (j) 어떠한 식으로든 미성년자에게 해를 입힘,
              <br />
              (k) 어떤 집단이나 개인에게 신체적 해를 가하거나 상해를 입힘, "스토킹"을 하거나 기타 다른 방식으로 괴롭힘을
              가함,
              <br />
              (l) 폭탄 및 기타 무기의 조립, 방화 도구의 조립 설명 등 불법 활동에 대한 교육 정보를 제공하거나 홍보함,
              <br />
              (m) 동의하지 않은 이메일이나 기타 통신을 사용자에게 보내거나 웹사이트 컨텐츠를 복제하는 등의 목적을 위해
              자동 스크립트 등을 이용하여 웹사이트나 이메일 주소, 연락처 정보, 기타 개인 정보를 다른 웹사이트
              사용자로부터 수집함,
              <br />
              (n) 명시적으로 승인되지 않은 사업적 목적을 위해 상품이나 서비스를 광고, 제안, 매매함,
              <br />
              (o) 웹사이트나 서버, 웹사이트에 연결된 네트워크에 지장을 초래함, 웹사이트에 연결된 네트워크의 조건이나
              절차, 정책, 규정을 준수하지 않음,
              <br />
              (p) 지역, 주, 국가, 국제 법률, 기타 강제력을 가진 규정을 위반함, 범죄 활동이나 연합을 촉진하거나 불법
              활동을 안내하는 정보를 제공함,
              <br /> (q) 웹사이트를 통해 그러한 목적으로 제공되지 않은 수단을 이용하여 자료나 정보를 입수하거나 입수하려
              시도함,
              <br />
              (r) 보안 기능을 무시하거나 우회, 회피하여 통제 기능에 접근함,
              <br />
              (s) 다른 사용자와 리뷰를 거래하거나 허위 리뷰를 작성하거나 유도하는 등 서비스의 이익에 저촉되거나 서비스
              목적을 침해할 수 있는 방식으로 서비스를 이용함.
            </span>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. 지식재산권 </b>
          <br />
          <br />
          <b>8.1GlobalEnglish의 지식재산권 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            컨텐츠와 지원 소프트웨어를 포함한 서비스의 전체 컨텐츠는 GlobalEnglish와 그 라이선스 제공자 또는 계약업체의
            독점 자산이며 미국 및 국제 저작권법과 기타 지식재산권법에 의해 보호됩니다.
            <br />
            <br /> GlobalEnglish 및/또는 라이선스 제공자의 명시적 서면 동의 없이 서비스의 일부분을 복제, 재배포, 수정,
            출판하는 행위는 엄격히 금지합니다.
            <br />
            <br /> 정식 사용자는 서비스에 접속하여 서비스와 컨텐츠를 이용할 수 있으며 사용자의 개인 언어 합습에 필요한
            만큼 컨텐츠의 일부분을 합당한 수의 사본으로 만들어 다운로드하거나 인쇄할 수 있습니다. 단, 사용자는 해당
            컨텐츠의 모든 사본에 모든 저작권이나 기타 독점권 표기를 유지해야 합니다.
            <br />
            <br />
            별도 명시가 없는 한 서비스에 표시된 상표는 GlobalEnglish나 그 라이선스 제공자, 그 계열사의 상표입니다.
            귀하는 상표를 폄하하거나 오염시키지 않을 것에 동의하며 혼동스러운 유사한 표시를 이용하거나 해당 표시의
            소유권을 왜곡하거나 GlobalEnglish가 어떤 제품이나 서비스를 추천하는 인상을 주는 방식으로 이용하지 않을 것에
            동의합니다. 귀하는 GlobalEnglish의 상표, 상호 및/또는 트레이드 드레스를 GlobalEnglish의 사전 서면 승인 없이
            복제하거나 이용할 수 없습니다. GlobalEnglish 상표를 이용하여 만들어진 모든 영업권은 본사의 독점적 이익을
            위해 효력을 발생하게 됩니다.
            <br />
            <br /> 본문에 명시되지 않은 웹사이트, 애플리케이션, 컨텐츠의 이용은 금지되며 자동으로 귀하의 서비스 및
            컨텐츠 이용 권리가 종료됩니다. 본 약관에서 명시적으로 부여되지 않은 GlobalEnglish와 그 라이선스 제공자의
            모든 권리는 GlobalEnglish와 그 라이선스 제공자가 보유합니다.
          </span>
          <br />
          <br />
          <b>8.2 제삼자 상표</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            위에 제시된 GlobalEnglish의 상표를 제외한 웹사이트를 통해 이용되고 전시된 제품과 서비스 이름 및 로고는
            GlobalEnglish를 추천하거나 추천하지 않으며 GlobalEnglish와 관련이 있거나 없는 각 소유권자의 상표 또는 서비스
            마크일 수 있습니다. 본 약관이나 웹사이트 상의 어떠한 내용도 암묵적, 금반언 원칙이나 기타 어떠한 원리에
            따라서도 각 사례에 대한 본사의 사전 서면 허가 없이 상표 라이선스나 이용 권리가 부여된 것으로 해석해서는
            안됩니다. GlobalEnglish 상표를 이용하여 만들어진 모든 영업권은 본사의 독점적 이익을 위해 효력을 발생하게
            됩니다.
          </span>
          <br />
          <br />
          <b>8.3 디지털 밀레니엄 저작권법에 따른 저작권 불만 제기 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            타인이 서비스에 게시한 자료와 관련된 저작권 문제가 있을 경우 저희에게 알려주십시오.
            <br />
            <br /> GlobalEnglish는 침해 혐의 통지를 처리하고 조사할 것이며 침해 혐의와 실제 침해 사안에 대하여 디지털
            밀레니엄 저작권법 ("DMCA") 및 기타 지식재산권법에 따라 적절한 조치를 취할 것입니다.
            <br />
            <br /> 저작권 침해 청구 통지는 GlobalEnglish의 저작권 에이전트 copyright@globalenglish.com으로
            보내주십시오(제목: “DMCA Takedown Request”).
            <br />
            <br /> 귀하는 또한 다음 주소를 통해 우편으로도 연락을 취하실 수 있습니다.
            <br />
            <b>Attn: Copyright Complaints/Legal Department</b>
            <br /> 1875 South Grant Street, Suite 700
            <br /> San Mateo, CA 94402 USA
            <br />
            <br />
            <br /> 통지는 효력을 갖기 위해 서면으로 작성해야 하며 다음 정보를 포함해야 합니다. (i) 저작권자 또는 기타
            지식재산권자를 대표하는 사람의 전자 서명 또는 실제 서명, (ii) 침해를 주장하시는 저작물이나 기타 지식재산에
            대한 설명, (iii) 서비스 내에서 침해를 주장하시는 자료가 있는 위치에 대한 설명, 저희가 웹사이트에서 찾을 수
            있도록 상세히 설명해주십시오, (iv) GlobalEnglish가 귀하께 연락을 드릴 수 있는 연락처 정보(주소, 전화번호,
            이메일 주소), (v) 분쟁이 발생한 이용 사례가 저작권자나 지식재산권자, 그 대리인, 또는 법률에서 허용되지
            않았다고 생각하시는 근거에 대한 설명, (vi) 위증 시 처벌을 조건으로 하여 귀하의 통지서에 포함된 위 정보가
            정확하며 귀하께서 저작권자 또는 지식재산권자이거나 저작권자나 지식권자를 대표하는 자임을 명시한 진술.
            <br />
            <br /> DMCA 및 기타 준거법에 따라 GlobalEnglish는 적절한 상황을 고려한 GlobalEnglish의 단독 결정에 따라 반복
            침해자로 간주된 사용자의 이용을 종료할 정책을 채택하였습니다. GlobalEnglish는 또한 단독 결정에 따라 반복
            침해 여부에 관계 없이 타인의 지식재산권을 침해한 사용자의 웹사이트 접속을 제한하거나 회원권을 종료할 수
            있습니다.
          </span>
          <br />
          <br />
          <b>8.4 침해 의심 사용자 컨텐츠의 제거 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            GlobalEnglish가 제삼자 지식재산권 침해가 의심되는 귀하의 사용자 컨텐츠 일부를 삭제하였을 때 귀하의 삭제된
            사용자 컨텐츠(또는 접속이 차단된 컨텐츠)가 침해를 하지 않았다고 생각하시거나 저작권자나 저작권자의 대리인의
            승인 또는 법률에 따른 컨텐츠 게시 및 이용 승인을 받았다고 생각하시는 경우, 다음 정보를 포함한 통지 이의서를
            저작권 에이전트에 제출하실 수 있습니다. (i) 귀하의 전자 서명 또는 실제 서명, (ii) 삭제되었거나 접속이 차단된
            컨텐츠의 식별 정보 및 삭제 차단 전 컨텐츠가 표시되었던 위치, (iii) 컨텐츠가 실수 또는 컨텐츠의 잘못된 식별로
            인해 제거되었거나 차단되었다고 생각하시는 근거에 대한 진술, (iv) 귀하의 이름, 주소, 전화번호, 이메일 주소,
            Northern District of California 내 연방 법원 관할지에 대한 동의, 침해 혐의 통지를 제출한 사람의 처리
            서비스에 대한 동의.
            <br />
            <br /> 저작권 에이전트가 통지 이의서를 받으면 GlobalEnglish는 이의서 사본을 이의 제기 당사자에게 제공하여 10
            영업일 이내에 삭제된 컨텐츠나 차단된 컨텐츠를 교체할 수 있음을 알립니다. 저작권자가 컨텐츠 제공자, 회원 또는
            사용자에 대해 법원 명령을 신청하지 않는 한, 제거된 컨텐츠는 통지 이의서 수령일로부터 10~14 영업일 이내에
            본사의 단독 결정에 따라 교체되거나 접속이 복구될 수 있습니다.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 9. 내부 이용 및 수출 통제 </b>
          <br />
          <br /> 인터넷의 전세계적인 특성을 고려하여 귀하는 온라인 행동 및 허용 가능한 사용자 컨텐츠에 대한 모든 지역
          규칙을 준수할 것에 동의합니다. 귀하는 미국이나 귀하께서 거주하시는 국가로부터 전송되는 기술 데이터 전송에 관한
          모든 준거법을 준수하실 것에 동의합니다.
          <br />
          <br /> 귀하는 서비스나 서비스의 일부분에 미국의 수출 통제법이 적용될 수 있음을 인정합니다. 귀하는 서비스나
          관련 기술 정보, 자료의 어떠한 부분도 수출법이나 규정을 위반하여 직간접적으로 수출하거나 재수출, 전송하지
          않습니다. 귀하는 미국 정보 수출금지가 적용되는 국가나 미국 정부로부터 "테러 지원국"으로 지정된 국가의
          국민이거나 그러한 국가에 거주하고 있지 않음을 보증하며 어떠한 미국 정부목록에도 금지 또는 제한 당사자로
          등록되지 않았음을 보증합니다.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. 금지명령 구제 </b>
          <br />
          <br /> 귀하는 이용약관에 위배된 서비스 이용 시 GlobalEnglish와 그 계열사, 라이선스 제공자, 계약업체, 재판매자,
          사용자에게 복구 불가능한 손상이 발생할 수 있음을 인정합니다. 그러한 상황을 고려하여 GlobalEnglish와 그 계열사,
          라이선스 제공자, 계약업체, 재판매자, 사용자는 예비 금지명령 및 영구적 금지명령을 포함하여 보석금이나 기타 보증
          없이 형평성 원칙에 따른 금지명령을 신청할 권리를 갖습니다.{' '}
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11. 서비스 중단 </b>
          <br />
          <br /> 유지보수 등의 이유에 따라 서비스를 비정기적으로 이용하지 못하게 될 수 있습니다. GlobalEnglish는 운영
          또는 전송의 중단이나 지연, 웹사이트를 통한 사용자 통신이나 기타 컨텐츠의 도난, 파괴, 무단 접속에 대한 책임을
          지지 않습니다.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12. 보증 한계 </b>
          <br />
          <br /> 유지보수 등의 이유에 따라 서비스를 비정기적으로 이용하지 못하게 될 수 있습니다. GlobalEnglish는 운영
          또는 전송의 중단이나 지연, 서비스를 통한 사용자 통신이나 기타 컨텐츠의 도난, 파괴, 무단 접속에 대한 책임을
          지지 않습니다. GlobalEnglish는 어떠한 경우에도 서비스 이용, 사용자 컨텐츠나 제삼자 컨텐츠의 이용, 사용자가
          제공한 제품이나 서비스의 이용으로 인해 발생하는 상해나 사망에 대하여 어떠한 책임도 지지 않습니다.
          <br />
          <br /> 귀하는 다음을 이해하고 동의합니다.
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            {' '}
            (a) 모든 컨텐츠를 포함한 서비스는 이용 가능한 시기에 "있는 그대로" 제공됩니다.
            <br />
            <br /> (b) GLOBALENGLISH는 권리, 상업성, 특정 목적에 대한 적합성, 비침해에 대한 암묵적 보증을 포함하여
            서비스에 대한 어떠한 명시적, 암묵적 보증도 부인합니다.
            <br />
            <br /> (c) GLOBALENGLISH는 서비스의 이용에 따른 구체적인 결과를 보장하거나 약속하지 않으며 서비스가 귀하의
            조건이나 기대를 충족한다는 보장을 하지 않습니다.
            <br />
            <br />
            (d) GLOBALENGLISH는 서비스나 웹사이트의 소프트웨어나 컨텐츠, 자료의 정확성, 신뢰성, 완전성, 최신 상태,
            무오류, 웹사이트나 서버에 바이러스 등 유해한 구성요소가 없음을 대표 또는 보증하지 않습니다. 따라서 상기
            내용을 제한하지 않고 귀하는 본인의 임의 결정에 따라 본인이 위험을 감수하여 서비스를 이용하거나 제공자로부터
            컨텐츠, 자료, 데이터, 소프트웨어를 다운로드하거나 기타 다른 방식으로 확보할 것이며 이용과 본인 컴퓨터
            시스템의 피해, 데이터 손실, 기타 유해한 결과에 대한 책임을 단독으로 부담할 것에 동의합니다.
            <br />
            <br /> (e) GLOBALENGLISH는 서비스의 오류나 결함 수정을 보증하지 않습니다.
            <br />
            <br />
            (f) GLOBALENGLISH는 사용자의 행동과 제삼자 컨텐츠, 사용자 컨텐츠에 대한 책임이 없습니다.
            <br />
            <br />
            (g) 유지보수 등의 이유에 따라 서비스를 비정기적으로 이용하지 못하게 될 수 있습니다. GlobalEnglish는 운영
            또는 전송의 중단이나 지연, 서비스를 통한 사용자 통신이나 기타 컨텐츠의 도난, 파괴, 무단 접속에 대한 책임을
            지지 않습니다. GlobalEnglish는 어떠한 경우에도 서비스 이용, 사용자 컨텐츠나 제삼자 컨텐츠의 이용, 사용자가
            제공한 제품이나 서비스의 이용으로 인해 발생하는 상해나 사망에 대하여 어떠한 책임도 지지 않습니다.
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. 책임의 제한</b>
          <br />
          <br /> 어떠한 경우에도 GLOBALENGLISH나 그 임직원, 대리인, 라이선스 제공자, 계약업체는 귀하나 어떠한
          제삼자에게도 다음으로 인해 발생한 수익의 상실이나 데이터 손실을 포함한 간접 손해, 우발적 손해, 특수 손해,
          징벌적 손해 배상에 대한 책임을 지지 않습니다. (I) 귀하의 웹사이트, 서비스, 제공자를 통해 접속하거나 다운로드한
          서비스나 컨텐츠 이용, 기타 자료의 이용 또는 이용 불능, (II) 웹사이트를 통해 획득하거나 거래한 상품, 데이터,
          정보, 서비스를 통한 대체 상품과 서비스의 조달 비용, (III) 어떤 당사자의 무단 접속 또는 전송 또는 변경, (IV)
          웹사이트 상에서 제삼자의 진술이나 행동, (V) 기타 서비스에 관련된 자료. GLOBALENGLISH가 손해 발생 가능성을
          인지하였거나 알림을 받았다 하더라도 어떠한 경우에도 귀하의 손해, 손실, 행위 원인에 대한 GLOBALENGLISH의 총
          책임은 귀하께서 최근 12개월 이내에 GLOBALENGLISH에 지불한 총 금액 또는 $100를 초과할 수 없습니다. 한 건 이상의
          청구가 더 존재하더라도 이 한도는 높아지지 않습니다.
          <br />
          <br /> 일부 관할지는 특정 보증의 배제나 우발적 손해 또는 결과적 손해의 제한을 허용하지 않습니다. 따라서 위
          제한의 일부는 귀하께 적용되지 않을 수 있습니다.
          <br />
          <br /> 귀하께서 웹사이트나 본 웹사이트 약관의 일부분에 만족하지 않으신다면 귀하께서 이용하실 수 있는 유일한
          구제조치는 웹사이트 이용을 중지하는 것입니다.
          <br />
          <br /> 어떠한 경우에도 GLOBALENGLISH는 서비스나 제삼자 컨텐츠, 사용자 컨텐츠 이용에 의해 발생한 개인 상해나
          사망에 대한 책임을 지지 않습니다.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14. 제한 법령 </b>
          <br />
          <br />
          귀하는 상반된 법령이나 법률에 관계 없이 귀하의 서비스 이용과 관련하여 발생한 GlobalEnglish에 대한 모든 청구와
          소송 사유를 청구나 소송 사유가 발생한 날로부터 1년 이내에 제기해야 하며 그렇지 않으면 영원히 제기할 수
          없습니다.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>15. 면책 </b>
          <br />
          <br />
          귀하는 제삼자가 귀하의 웹사이트 이용, 서비스 이용, 귀하의 사용자 컨텐츠, 웹사이트 또는 서비스와의 관계, 귀하의
          이용 약관 위반, 다른 사용자의 권리 위반, 본사 서비스 이용 시 법률 등의 위반과 관련하여 GlobalEnglish를 상대로
          제기한 합당한 변호사 선임료를 포함하여 모든 청구와 소송, 비용, 책임, 손실, 손해, 지출로부터 GlobalEnglish와 그
          계열사, 임직원, 이사, 대리인을 면책할 것에 동의합니다. 귀하의 면책 의무는 사용자 컨텐츠의 제삼자 지식재산권
          침해에 대한 청구 등을 포함합니다.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>16. 종료 </b>
          <br />
          <br /> 귀하의 가입이 어떠한 이유로든 종료되면 GlobalEnglish는 귀하의 서비스 접속을 중지합니다. 귀하는 귀하의
          사용자 컨텐츠를 포함한 모든 컨텐츠에 접속할 수 없게 되나 그러한 데이터의 사본은 저희 시스템에 3년간 백업
          목적으로 유지됩니다.
          <br />
          <br /> GlobalEnglish에 귀하의 개인정보와 사용자 컨텐츠 삭제를 요청하실 경우 본사는 합당한 노력을 통해 이에
          따르며 조치 시기를 확인할 것입니다.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>17. 포기 및 계약의 분리 </b>
          <br />
          <br /> GlobalEnglish가 본 약관의 조항이나 권리를 행사하지 않았더라도 그러한 조항이나 권리를 다른 경우에도
          포기하는 것으로 성립되지 않습니다. 본 약관의 어떠한 조항이 불법이나 무효로 결정되거나 어떠한 이유로든 집행이
          불가능한 경우, 그러한 결정은 나머지 조항의 타당성과 효력에 영향을 미치지 않습니다.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>18. 완전 합의 </b>
          <br />
          <br /> 본 계약은 귀하와 GlobalEnglish의 서비스 이용에 대한 완전한 합의로 성립되며 귀하의 서비스 이용과
          관련하여 귀하와 GlobalEnglish 사이의 이전 이해와 합의를 대체합니다.
          <br />
          <br /> 상기 내용에 관계 없이 귀하의 회사가 GlobalEnglish와 서비스 구입에 대한 기존 계약을 체결한 경우, 본
          약관은 귀하의 회사가 GlobalEnglish나 그 자회사, 계열사와 체결한 계약 조건을 변경하고자 하지 않으며 상충이
          발생할 경우 해당 계약서 조항이 우선 적용됩니다.
          <br />
          <br /> 본 계약서는 영문으로 작성되며 영문판이 계약서의 다른 비영어판을 통제합니다. 본 계약서와 전자 문서로
          제공된 통지서의 출력본은 인쇄 양식으로 생성하여 유지하는 다른 사업 문서 및 기록과 같은 정도와 조건을 적용하여
          사법 또는 행정 절차에 제출할 수 있습니다. 본 약관의 섹션 제목은 편의를 위해 제공된 것이며 법적, 계약적 효력을
          갖지 않습니다.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>19. 법률, 재판소 및 중재 선택 </b>
          <br />
          <br />본 약관은 국제사법 조항과 관련하여 State of California 법률의 지배를 받습니다.
          <br />
          <br /> GlobalEnglish의 선택권 및 단독 결정에 따라 본 약관이나 웹사이트에서 발생하여 상호 합의로 해결하지 못한
          모든 분쟁, 청구, 이견은 JAMS 또는 그 승계인이 실시하는 구속력을 가진 중재로 해결할 수 있습니다. 당사자들이
          별도 합의하지 않은 한, 중재는 California 주 San Francisco에서 당사자들이 상호 합의한 단독 중재인의 중재를
          받거나, 당사자들이 상호 합의하지 못한 경우 JAMS가 지명한 단독 중재인의 중재를 받으며 중재는 본 약관에서
          명시적으로 수정하지 않은 한 JAMS가 정한 규칙 및 규정에 따릅니다. 중재는 양측 당사자가 서면으로 중재를 요청한
          날로부터 45일 이내에 개시해야 합니다. 중재인의 결정과 판결은 중재 종결일로부터 60일 이내, 중재인 선정일로부터
          6개월 이내에 전달되어야 합니다. 중재인은 약관에 규정된 실제 보상 한도, 직접 손해를 초과한 손해 보상을 결정할
          권한이 없으며 실제 손해나 징벌적 손해 배상을 적용하거나 기타 약관에서 명시적으로 배제된 손해 배상을 적용할 수
          없고 각 당사자는 그러한 손해에 대한 청구를 포기하며 이를 취소할 수 없습니다. 중재인은 본인의 임의 결정에 따라
          당사자의 소송 비용과 지출을 평가할 수 있습니다(승소 당사자의 합당한 법률 수수료 및 지출 금액 포함). 중재인의
          명령을 거부하는 당사자는 상대 당사자가 중재를 집행하는데 발생한 변호사 선임료 등의 비용과 지출을 부담해야
          합니다. 위 내용에도 불구하고 임시 금지명령 구제조치가 내려진 경우, 당사자들은 복구가 불가능한 즉각적인 피해를
          피할 수 있도록 사전 중재 없이 법원에 소송을 제기할 수 있습니다. 본 중재 조항은 자격을 갖춘 관할지의 모든
          법원에서 집행 가능합니다.
          <br />
          <br /> 중재가 적용되지 않는 분쟁이나 청구에 대하여 귀하와 GlobalEnglish는 California 주 San Francisco County에
          위치한 주 법원과 연방 법원을 독점 관할지로 소송을 제기할 것에 동의합니다.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. 통지 및 계약 </b>
          <br />
          <br />
          귀하는
          <a href="https://support.globalenglish.com/" rel="noopener noreferrer" target="_blank">
            https://support.globalenglish.com/
          </a>
          의 "문의" 링크를 통해 GlobalEnglish에 종료 통지를 제출하여 GlobalEnglish 서비스 계정 종료를 요청하실 수
          있습니다. 귀하의 컨텐츠를 포함한 서비스 접속과 이용은 계정 종료 후 언제든지 GlobalEnglish에 의해 차단될 수
          있습니다.
          <br />
          <br />
          귀하에 대한 통지는 이메일이나 일반 우편으로 전달될 수 있습니다. 웹사이트는 또한 귀하에게 통지사항을 표시하거나
          서비스 통지 링크를 제공하여 약관의 변경과 기타 사안에 대한 통지를 제공할 수 있습니다.
          <br />
          <br />
          본사의 개인정보 보호정책에 관한 통지는
          <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
            privacy@globalenglish.com
          </a>
          으로 보내실 수 있습니다.
          <br />
          <br /> GlobalEnglish에 보내는 다른 모든 법적 통지나 서신은 다음 전자 우편주소
          <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
            legal@globalenglish.com
          </a>{' '}
          또는 다음 주소로 보내주십시오.
          <br />
          <br /> 1875 S Grant Street, Suite 700
          <br /> San Mateo, CA 94402
          <br /> 수신인:
          <b>Legal Counsel</b>
          <br />
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>B2B 거래 약관 </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          {' '}
          귀하께서 고용인을 통해 GlobalEnglish 계정을 받으신 경우, 귀하의 본사 서비스 이용은 귀하와 귀하의 회사("회사")
          사이의 계약에 따릅니다. 본 이용 약관은 귀하의 회사와의 계약 조건을 변경할 의도를 갖지 않습니다. 회사가 귀하를
          GlobalEnglish 라이선스 최종 사용자로 승인하지 않으면 귀하는 서비스를 이용하실 수 없습니다(또는 계속 이용하실
          수 없습니다). 귀하께서 계정 종료를 저희에게 요청하시면 GlobalEnglish가 귀하의 계정을 종료하기 전에 귀하의
          회사가 먼저 허가해야 할 수 있습니다.
          <br />
          <br /> 귀하는 서비스를 귀하의 회사의 단독 허가에 따라 이용하실 것에 동의합니다. 귀하는 귀하의 회사 내에서 다른
          사람과 소통하거나 귀하의 회사 관련 문서를 서비스를 통해 업로드하실 수 있으나 귀하의 회사가 승인하지 않은
          경우에는 불가합니다.
          <br />
          <br /> 귀하는 귀하의 서비스 이용과 관련하여 생성된 모든 데이터("사용자 데이터")에 회사가 접속할 수 있으며
          GlobalEnglish는 귀하의 서비스 이용 결과에 따른 귀하의 회사 정보 공유에 어떠한 책임도 없습니다.
          <br />
          <br /> 귀하의 서비스 가입은 해당 회사의 라이선스 조건에 따라서만 계속되므로 귀하의 회사가 GlobalEnglish
          라이선스를 종료하면 귀하는 서비스와 사용자 컨텐츠 접속을 상실하시게 됩니다. GlobalEnglish는 (i) 귀하의 회사가
          GlobalEnglish에 납부해야 할 요금을 미납한 경우, (ii) 귀하나 귀하의 회사가 약관을 위반하거나 다른 서비스 이용
          규칙을 위반한 경우, 귀하 회사의 계정이나 귀하의 계정, 귀하의 서비스 이용을 전체 또는 일부 종료하거나 정지할 수
          있습니다.
          <br />
          <br /> GlobalEnglish는 귀하의 개인정보와 사용자 컨텐츠를 포함하여 귀하의 서비스 이용에 관련된 모든 데이터를
          귀하의 회사에 반환할 의무가 적용될 수 있습니다. 귀하의 회사가 그러한 데이터의 반환이나 삭제를 요청하지 않으면
          GlobalEnglish는 귀하의 고용주와 계약이 종료되거나 만료된 날로부터 3년 동안 데이터를 유지합니다. 그러한 경우,
          귀하는 GlobalEnglish에서 직접 서비스 구독권을 구입하여 진행 정보를 잃지 않고 프로그램 접속을 계속 하실 수
          있습니다. 귀하는 새로운 고용주에 소속되어 새로운 구독권으로 기존 진행 상황 및 프로필 정보를 이전하실 수
          있습니다. 귀하는 또한 귀하의 서비스 이용에 관한 모든 데이터를 나중에 가져올 수 있는 형식으로 요청하실 수
          있습니다.
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>B2C 거래 약관 </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          귀하는 개인 자격으로 GlobalEnglish와 3개월 또는 6개월, 연간 또는 다년간 플랜에 따라 (각각 "구독 플랜") 구독
          계약을 체결하실 수 있습니다.
          <br />
          <br /> 귀하는 구독 플랜에 따라 구독 플랜을 취소하실 때까지 매달 또는 매년 같은 날 요금 청구를 받으실 수
          있습니다. 다음 연도의 귀하의 구독 플랜 가입일이 도래할 때까지 서비스를 취소하거나 참석권 수 축소, 서비스
          다운그레이드를 할 수 없다는 점을 기억해주십시오. 구독 플랜 결제 시 환불이 되지 않습니다.
          <br />
          <br /> 서비스에 적용되는 요금("요금")은 웹사이트 및/또는 GlobalEnglish의 당시 게시된 가격표에 따릅니다.
          서비스에 명시된 가격은 별도 명시가 없는 한 모든 세금과 부과금을 제외한 금액입니다. 귀하는 서비스 이용 시
          발생하는 모든 세금과 기타 부과금을 납부하실 책임이 있습니다(예: 데이터 요금 및 환전 수수료). 귀하는 귀하의
          계정에 제시된 현재 GlobalEnglish 요금을 납부하시게 됩니다. GlobalEnglish는 언제든지 요금을 제시한 통화를
          변경할 수 있습니다.
          <br />
          <br />
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}> 결제 </b>
          <br />
          <br /> 결제는 구독 라이선스 구입 시 처리해야 합니다. 본사는 PayPal 및 Stripe와 파트너쉽을 통해 귀하의 결제를
          처리합니다. 본사는 귀하께서 이러한 서비스 제공자에게 제공하신 신용카드 정보나 기타 결제수단 정보를 수령하거나
          처리하지 않습니다.
          <br />
          <br /> 모든 서비스는 선택 기간(월, 연간 등) 동안 선불로 처리되며 환불이 되지 않습니다. 여기에는 갱신 계정이
          포함됩니다.
          <br />
          <br /> -----
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>California 사용자 통지 </b>
          <br />
          <br /> 귀하께서 California 주 주민이신 경우, California 민법(Civil Code) Section 1789.3에 따라 귀하께서는
          제삼자 직접 마케팅 목적에 따른 제삼자에 대한 본인 개인정보 관련 정보를 요청하실 수 있습니다. 2021년 효력을
          갖는 California Consumer Privacy Act(소비자 개인정보 보호법)(Civil Code Section 1798.100 to 1709.198)은 또한
          귀하께 다음과 같은 권리를 제공합니다.
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            (a) 귀하와 관련하여 어떤 개인정보가 수집되었는지 확인하고 사본을 받을 권리
            <br />
            <br />
            (b) 삭제 권리
            <br />
            <br />
            (c) 귀하의 개인정보를 어떤 제삼자에게 판매하였는지 알 권리 및 해당 판매의 중지를 요청할 권리
            <br />
            <br />
            (d) 소비자 권리를 행사할 때 차별을 받지 않을 권리
          </span>
          <br /> 또한 California Business and Profession Code(상업 직업법) Section 22581은 현재 미성년 California 주
          주민이 웹사이트에 공개한 컨텐츠 삭제를 요청하도록 허용합니다. 단 GlobalEnglish는 귀하께서 게시하신 정보를
          완전히 또는 포괄적으로 삭제할 것을 보증할 수 없으며, 요청 시에도 법률에 따라 삭제를 강제하지 않거나 삭제를
          허용하지 않는 경우가 있습니다.
          <br />
          <br />
          귀하의 권리를 행사하시려면 다음으로 신청해주십시오.
          <br />
          <br /> 웹사이트 및 관련 서비스 제공자는 GlobalEnglish Corporation, located at 1875 South Grant Street, Suite
          700, San Mateo, CA 94402, USA입니다. 이메일 주소: privacy@globalenglish.com.
          <br /> 본 약관 작성일 당시 웹사이트는 소비자에게 직접 요금을 부과하지 않습니다. 웹사이트에서 제공하는 서비스에
          대한 불만이 있거나 추가로 정보를 요청하고자 하시는 경우, 귀하의 요청에 대한 설명을 위 주소지로 문의해주십시오.
          본사는 답변 시기를 보장할 수 없습니다. 불만이 남아있을 경우, California 주 소비자부 소비자보호국 불만지원과
          (The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer
          Affairs) 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, 또는 전화 (916) 445-1254 또는 (800)
          952-5210으로 문의해주십시오.
        </p>
      </div>
    </div>
  );
}

export default KoKoContent;
