// @ts-nocheck
import React from "react";

function ZhCnContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>版权、商标和其它法律注意事项</b>
          </center>
        </div>
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>GLOBALENGLISH 版权所有</b>
          </p>
          <p />
          <p> 版权所有 © 1999-2013 GlobalEnglish Corporation, 保留所有权利 </p>
          <br />
          <p>
            {" "}
            <b>第三方版权所有</b>{" "}
          </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>，第一版，1995
            <br /> © Longman Group UK Limited 1995{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> © Longman Group Limited 1995{" "}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p*/}
          <p> 版权所有© 1995 - 1998 Macromedia, Inc., 保留所有权利 </p>
          <p> 版权所有© 1999 Microsoft Corporation, 保留所有权利 </p>
          <p> 版权所有© 1999 Sipro Lab Telecom Inc., 保留所有权利 </p>
          <p>
            {" "}
            版权所有© Business English Pod Limited,
            保留所有权利。包括播客、播客字词集萃、视频播客、学习札记和音频电子书在内的所有内容都是
            Business English Pod Limited 的财产，只有获得 GlobalEnglish
            Corporation 许可才能使用。{" "}
          </p>
          <p>
            {" "}
            WGrammar Grammar Checker Engine 版权所有© 1998-2006 Wintertree
            Software Inc. ({" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.wintertree-software.com"
            >
              <div
                onmouseout="this.className='blu11'"
                onmouseover="this.className='blu11rllover'"
                className="blu11"
              >
                www.wintertree-software.com
              </div>
            </a>
            ){" "}
          </p>
          <p>
            {" "}
            EduSpeak<sup>®</sup> 语音识别软件包含在 SRI International
            <sup>®</sup> 提供的许可证下。版权所有© SRI International.{" "}
          </p>
          {/*p> Systran Translation Engine &copy; SYSTRAN Software, Inc </p*/}
          <p>
            {" "}
            AP/Wide World Photos 提供的照片受版权保护，是 Associated Press
            的财产。未经 AP/Wide World Photos
            许可，不得使用这些照片。任何违规行为将诉诸法律解决。{" "}
          </p>
          <p>
            {" "}
            PhotoDisc 提供的照片受版权保护，是 PhotoDisc, Inc. 的财产。Images ©
            版权所有 1999 PhotoDisc, Inc.{" "}
          </p>
          <p>
            {" "}
            HOW TO PREPARE FOR THE TOEIC<sup>®</sup>，版权所有© Barron's
            Educational Series, Inc.{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i> © 1998 AMACOM NEW MEDIA，American
            Management Association, New York 的全资子公司。保留所有权利。{" "}
          </p>
          <p>
            {" "}
            Microsoft 提供的图库受版权保护，是 Microsoft Corporation
            和/或其供应商的财产。未经 Microsoft 许可，不得使用这些图库。{" "}
          </p>
          <p>
            {" "}
            ArtToday 提供的图库受版权保护，是 Zedcor, Inc.,（IMSI Inc.
            的全资子公司）和/或其供应商的财产。未经 Zedcor
            许可，不得使用这些图库。{" "}
          </p>
          <p>
            {" "}
            Eyewire 提供的图库受版权保护，是 Eyewire Inc.
            和/或其供应商的财产。未经 Eyewire 许可，不得使用这些图库。{" "}
          </p>
          <p>
            {" "}
            Corbis 提供的照片受版权保护，是 Corbis Corporation
            和/或其供应商的财产。未经 Corbis 许可，不得使用这些照片。{" "}
          </p>
          <p>
            {" "}
            ImageQuest 提供的照片受版权保护，是 ImageQuest
            和/或其供应商的财产。未经 ImageQuest 许可，不得使用这些照片。{" "}
          </p>
          <p />
          <br />
          <p>
            <b>GlobalEnglish 商标</b>
          </p>
          <p />
          <p>
            以下是 GlobalEnglish Corporation
            在美国和/或其他国家/地区所属的一些商标及其所建议的统称。此表中未列出的名称或徽标并不代表
            GlobalEnglish Corporation
            或其子公司放弃所拥有的产品、功能或服务的名称或徽标的任何或全部知识产权。欲了解如何正确使用
            GlobalEnglish 的产品名称和商标，请获取一份 GlobalEnglish
            通用商标指南。
          </p>
          <p />
          <br />
          <style
            dangerouslySetInnerHTML={{
              __html: " \t\ttd{padding:10px !important} \t\t",
            }}
          />
          <table
            border={1}
            cellPadding={10}
            cellSpacing={0}
            bordercolor="#808080"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  TradeMark
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Status
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  Enterprise Fluency
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Bloom
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Corporate Learning Service
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Edge
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo Pro
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Magazine
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Mobile Learning Network
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Productivity Toolbar
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP+
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Motivator
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Executive Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>第三方商标</b>
          </p>
          <p />
          <p>
            {" "}
            Adobe 和 Flash 是 Adobe Systems Incorporated
            在美国和/或其他国家/地区所有的商标或注册商标。{" "}
          </p>
          <p> Microsoft 是 Microsoft Corporation 的注册商标。 </p>
          {/*p> Langenscheidt KG &#26159; Langenscheidt KG, Berlin and Munich &#30340;&#27880;&#20876;&#21830;&#26631;&#12290; </p*/}
          <p>
            {" "}
            Living Language 和 Colophon 是 Random House, Inc. 的注册商标。{" "}
          </p>
          <p>
            {" "}
            Sipro 和 Sipro 徽标是 Sipro Lab Telecom Inc. 的商标。ACELP
            <sup>®</sup> 是 Université Sherbrooke 在 Sipro Lab Telecom Inc.
            授权下使用的注册商标。{" "}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International、SRI International 徽标、EduSpeak 和
                  EduSpeak 徽标是 SRI International 的商标或注册商标。{" "}
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p>SYSTRAN &#26159; SYSTRAN Software, Inc. &#30340;&#27880;&#20876;&#21830;&#26631;&#25110;&#26381;&#21153;&#26631;&#35760;&#65292;SYSTRAN &#24509;&#26631;&#26159; SYSTRAN Software, Inc &#30340;&#27880;&#20876;&#26381;&#21153;&#26631;&#35760;&#12290; </p*/}
          <p> AP/Wide World Photos 是 Associated Press 的商标。 </p>
          <p> PhotoDisc 是 PhotoDisc, Inc. 的注册商标。 </p>
          <p>
            {" "}
            Business English Pod、www.businessenglishpod.com 和 Business English
            Pod 徽标是 Business English Pod Limited 的商标。{" "}
          </p>
          <p> 所有其它商标是其各自所有者的财产。 </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default ZhCnContent;
