import Cookies from "universal-cookie";
import jwt from 'jsonwebtoken';
import { datadogLogs } from "@datadog/browser-logs";

export const getJWTTokenValidity = ()=>{
    const tokenValue = getJWTToken();
    return isTokenValid(tokenValue);
  }
export const getJWTToken=()=>{
  const universalCookies = new Cookies();
  const tokenValue = universalCookies.get(process.env.REACT_APP_USER_JWT_TOKEN);
  return tokenValue
}  
const isTokenValid =(jwtToken: string | null)=>{
    if (!jwtToken) {
      return false
    }
    try {
      const parts = jwtToken.split('.');
      if (parts.length !== 3) {
        clearCookie(process.env.REACT_APP_USER_JWT_TOKEN);
        return false
      }
      const payloadBase64 = parts[1];
      const payloadJson = atob(payloadBase64); // Decode Base64
      const payload = JSON.parse(payloadJson);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      if (payload.exp && currentTime >= payload.exp) {
        clearCookie(process.env.REACT_APP_USER_JWT_TOKEN);
        return false
      }
      return true
    } catch (error) {
      datadogLogs.logger.error('Verify token exception', error);
      clearCookie(process.env.REACT_APP_USER_JWT_TOKEN);
      return false
  }
}

export const decodeJWTToken = (token: any)=>{
  const decodedToken = jwt.decode(token); 
 return decodedToken;
}       

export const setJWTToken = (token: any) =>{
  const userToken = getJWTToken();
  if(userToken == "" || userToken == null){
  const universalCookies = new Cookies();
  const loginUrl = process.env.REACT_APP_GE_LOGIN_URL; 
  const domain = loginUrl.split("//")[1].split(".").slice(-2).join("."); 
  universalCookies.set(process.env.REACT_APP_USER_JWT_TOKEN, token.idToken.jwtToken, {
      path: "/",
      domain: domain,
      secure: true,
    });
  } 
  
}

export function clearCookie(name) {
  const universalCookies = new Cookies();
  const loginUrl = process.env.REACT_APP_GE_LOGIN_URL; 
  const domain = loginUrl.split("//")[1].split(".").slice(-2).join("."); 
  universalCookies.remove(name, { path: "/", domain: domain });
}