/* eslint-disable */
// @ts-nocheck
import React from 'react';

function EnContent() {
  return (
    <>
      <div id="agreement_container">
        <div style={{ padding: '1% 5% 0 5%' }}>
          <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
            <b>USER AGREEMENT</b>
          </p>
          <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
            <b>INTRODUCTION</b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            Welcome to GlobalEnglish services, whether website or mobile applications (collectively referred to as the
            "Services").
            <br />
            <br />
            Our Services are English learning tools and online solutions, including without limitation data, text,
            online tests, designs, videos, audio clips, voice recording, written posts and comments, scripts, graphics,
            images and their selection and arrangement, software, tutorials, classes, coaching, training, communications
            and interactive features generated or made accessible through the website and mobile applications
            (collectively, the "Content"). Access to our website to read about our company and enquire about the
            Services is considered part of the Services.
            <br />
            <br />
            Please read the following very carefully and acknowledge your understanding and agreement with the terms
            below before continuing.
            <br />
            <br />
            <b>
              IF YOU ARE UNDER 18 YEARS OLD, PLEASE BE SURE TO READ THIS AGREEMENT WITH YOUR PARENT OR GUARDIAN AND
              OBTAIN THEIR CONSENT PRIOR TO USING OUR SERVICES. BY USING OUR SERVICES, YOU REPRESENT THAT YOU ARE OVER
              18 YEARS OLD OR HAVE OBTAINED SUCH CONSENT FROM YOUR PARENT OR GUARDIAN AND ARE PERMITTED TO ENTER INTO
              THIS CONTRACT WITH GLOBALENGLISH.
            </b>
          </p>
          <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
            <b>GENERAL TERMS</b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 1. LEGALLY BINDING AGREEMENT</b>
            <br />
            <br />
            <b>
              YOUR ACCESS TO OUR WEBSITE AND USE OF GLOBALENGLISH'S SERVICES ARE SUBJECT TO THE USER AGREEMENT BELOW,
              THE PRIVACY POLICY AND THE COOKIE POLICY (ALL THREE DOCUMENTS REFERRED TO COLLECTIVELY AS THE "TERMS OF
              USE"). BY ACCESSING, VISITING, REGISTERING AND /OR USING GLOBALENGLISH'S SERVICES, YOU REPRESENT THAT YOU
              HAVE READ AND UNDERSTOOD THE TERMS OF USE OF OUR SERVICES AND AGREE TO BE LEGALLY AND IRREVOCABLY BOUND BY
              THEM AND TO BE OF LEGAL AGE TO AGREE TO BE BOUND BY THESE TERMS AND ENTER INTO A LEGALLY BINDING CONTRACT
              WITH GLOBALENGLISH.
            </b>
            <br />
            <br />
            You can find an overview of our policies
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
            . These policies are incorporated by reference into this "User Agreement" or "Agreement" and altogether,
            they constitute the terms of use.
            <br />
            <br />
            <b>
              Please be advised that this Agreement contains provisions that govern how claims you and we may have
              against each other are resolved. See in particular the Disclaimer of Warranties, Limitation of Liability
              and Legal Disputes provisions below. It also contains an agreement to arbitrate which, with limited
              exceptions, require you to submit claims you have against us to binding and final arbitration. You will
              only be permitted to pursue claims on an individual basis, not as a plaintiff or class member in any class
              or representative action or proceeding. You will only be permitted to seek relief (including monetary,
              injunctive and declaratory relief) on an individual basis.
              <br />
              <br />
              DO NOT USE THE SERVICES OR FURTHER NAVIGATE OUR WEBSITE IF AT ANY TIME YOU DO NOT AGREE WITH ANY PART OF
              THE TERMS HEREUNDER.
            </b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. APPLICABILITY</b>
            <br />
            <br />
            This Agreement applies to both Users of our services and Visitors on our sites. Individuals who have
            registered with us directly (a <b>"B2C transaction"</b>), even on a trial basis, and those who are
            registered by or through their employers (a <b>"B2B transaction"</b>) are referred to as <b>"Users"</b>{' '}
            whereas unregistered users and visitors perusing our websites are <b>"Visitors"</b>. The term <b>"Users"</b>{' '}
            also includes teachers, coaches, learning specialists and any other person accessing GlobalEnglish sites to
            provide services to the other Users. Both Users and Visitors are referred to as <b>"You"</b>.
            <br />
            <br />
            This Agreement applies to all GlobalEnglish's Services .
            <br />
            <br />
            When you register for the first time as a User and when you access our Services, we may ask you to reiterate
            your agreement to be bound by the terms herein by clicking on the box <b>"I agree" </b>or by providing your
            electronic signature or any other means of authentication.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>3.CHANGES TO THE TERMS OF USE</b>
            <br />
            <br />
            GlobalEnglish may modify the Terms of Use at any time, including this Agreement, the Cookie Policy and the
            Privacy Policy applicable to your use of the Services. Such modifications shall be effective immediately
            upon notice which shall be given by posting within the Service, electronic message to your email address you
            have provided in connection with your Services account (if applicable) or other means. If you were
            registered for our Services through your employer or a company, your employer or company may notify you of
            the changes to our Terms of Use.
            <br />
            <br />
            If you object to these changes, you may close your account and stop accessing our website. However, your
            continued use of our Services after we publish or send a notice about the changes to these terms shall be
            deemed to constitute your acceptance of such modifications to the Terms of Use.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4.THIRD PARTIES' TERMS OF USE</b>
            <br />
            <br />
            The Services may contain links to other web sites ("Third-Party Sites") as well as articles, photographs,
            text, translations, graphics, pictures, designs, sound, video, information, and other content or items
            belonging to or originating from third parties (the "Third-Party Content"). Such Third-Party Sites and
            Third-Party Content are not investigated, monitored or checked for accuracy, appropriateness, or
            completeness by us, and we are not responsible for any Third-Party Sites accessed through the Website or any
            Third-Party Content posted on or available through the website.
            <br />
            <br />
            Inclusion of, linking to or permitting the use of any Third-Party Site or any Third-Party Content does not
            imply approval or endorsement thereof by GlobalEnglish. GlobalEnglish reserves the right to put an end to
            these links at any time without notice. References on the Services to any companies, organizations,
            products, or services, or other information do not constitute or imply endorsement, sponsorship or
            recommendation thereof, or any affiliation therewith, by GlobalEnglish.
            <br />
            <br />
            If you decide to leave our website and access the Third-Party Sites or to access or use any Third-Party
            Content, you do so at your own risk and you should be aware that our terms and policies no longer govern
            your use of such Third Party Sites. Please make sure to check the terms and policies applicable to such
            other products and services of third parties prior to their use.
            <br />
            <br />
            We offer mobile applications through both the Android Market and the iTunes Store (collectively and with the
            entities which maintain them, "Providers"). Your use of these mobile apps is also subject to the Providers'
            terms and conditions, including without limitation the Usage Rules set forth in the App Store Terms of
            Service. If you have downloaded any of these mobile apps from the iTunes Store, you acknowledge and agree
            that you have read and agree to the App Store Terms of Service. If you have downloaded them from the Android
            Market, you acknowledge and agree that you have read and agree to the Android Market Terms of Service. The
            Providers have no obligation to, and will not, provide any maintenance or support services to you in
            connection with your use of the Services. The Providers offer no warranty of any kind for the Services. In
            the event that any of the mobile apps fails to conform to any applicable warranty and you have downloaded
            such app from the iTunes Store, you may notify Apple and Apple will refund the purchase price (if any) of
            such app. These Terms apply to your use of all mobile apps as well as our other Services.
            <br />
            <br />
            You acknowledge and agree that GlobalEnglish is not responsible nor liable for the content, products,
            services or performance of these third-party websites or applications. You irrevocably waive any claim
            against GlobalEnglish in relation to third parties' websites or applications. In particular, GLOBALENGLISH
            IS NOT RESPONSIBLE FOR COOKIES, WEB BEACONS, CLEAR GIFS, OR OTHER CODE YOU OR OTHER PARTIES PLACE ON YOUR
            COMPUTER IN ANY WAY, INCLUDING ANY ASSOCIATION OF WEBSITE CONTENT WITH SUCH CODE. For example, if you access
            a Third-Party Site through a link on the Website, your browser or other cookies on your computer may cause a
            referrer header to be sent to that site indicating some of your browsing history or the URL from which your
            visit originated. GlobalEnglish shall have no liability to you in connection with any such transmission,
            whether or not that transmission contains information associated with or originating from GlobalEnglish.
            <br />
            <br />
            IF YOU USE TRANSLATIONS POWERED BY GOOGLE: GOOGLE DISCLAIMS ALL WARRANTIES RELATED TO THE TRANSLATIONS,
            EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, RELIABILITY AND ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>5. SERVICE ELIGIBILITY CRITERIA</b>
            <br />
            <br />
            <b>5.1 Protection of Children's Privacy and Restrictions on the Use of our Services. </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              The Services are not for use by anyone under the Minimum Age in the country from which they are accessing
              the Services. For example, "Minimum Age" in the European Union, European Economic Area and Switzerland
              means 16 years old, unless otherwise set forth by specific country laws. In the United States, the minimum
              age is 13 years old. Any registration by, use of or access to the Services by anyone under the Minimum Age
              is prohibited and constitutes a violation of these Terms.
              <br />
              <br />
              By using the Services, you represent and warrant that you are 13 years of age or older.
            </span>
            <br />
            <br />
            <b>5.2 Schools and Teachers</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Schools, districts or teachers in the United States who want their students under the age of 13 to create
              GlobalEnglish accounts agree that they are responsible for complying with the U.S. Children's Online
              Privacy Protection Act ("COPPA") and, to the extent applicable, the Family Educational Rights and Privacy
              Act ("FERPA"). This means that schools, districts or teachers must notify the students' parents or
              guardians of the personally identifiable information that GlobalEnglish will collect, and to obtain
              parental/guardian consent before the students establish accounts and use the Services. When obtaining such
              consent, schools, districts and teachers must provide parents/guardians with a copy of our Privacy Policy
              and keep all consents on file and provide them to us if we request them. For more information on complying
              with COPPA, see
              <a
                style={{ textDecoration: 'underline' }}
                href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
                rel="noopener noreferrer"
                target="_blank"
              >
                the Federal Trade Commission's website.
              </a>
              <br />
              <br />
              If you are located outside of the United States, we will rely upon you to obtain any required consent or
              approval from the parent or guardian of any student covered by similar laws, and, as a condition to your
              and your students' use of our Services, you agree that you will be responsible for complying with such
              laws.
            </span>
            <br />
            <br />
            <b>5.3 Parental Consent</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              <b>
                BY USING OUR SERVICES, YOU REPRESENT THAT YOU ARE OVER 18 YEARS OLD OR HAVE OBTAINED SUCH CONSENT FROM
                YOUR PARENT OR GUARDIAN AND ARE PERMITTED TO ENTER INTO THIS CONTRACT WITH GLOBALENGLISH.
                <br />
                <br />
                We expect that any use by children will only be done with the guidance, supervision and consent of their
                parents, guardians and/or authorized school officials. GlobalEnglish relies on parents, guardians and
                schools to ensure that minors only use the Services if they understand their rights and responsibilities
                as laid out in these Terms of Use.
                <br />
                <br />
                GlobalEnglish may in its sole discretion refuse to offer the Services to any individual or entity and
                change its eligibility criteria at any time.
              </b>
            </span>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6.PRIVACY</b>
            <br />
            <br />
            <b>6.1 Privacy Statement</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Please review the Privacy Policy available
              <a
                href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
                rel="noopener noreferrer"
                target="_blank"
              >
                here
              </a>
              .
              <br />
              <br />
              We will process personal data in accordance with the privacy policy that you have consented to at the time
              you first accessed the service or as part of a scheduled updated to the privacy policy.
            </span>
            <br />
            <br />
            <b>6.2 Data You Provide. </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              In order to use the Services, you must have a valid account. To register for our Services, you must
              provide GlobalEnglish with a User ID, an email address and other information that are considered
              personally identifiable information ("Personal Data"). Some information we collect from you are necessary
              for GlobalEnglish to provide the Services or even just only to send you information about the Services you
              have been enquiring about, others are optional. The Services also allow you to display information about
              yourself and your organization, if applicable.
              <br />
              <br />
              You agree: (i) to provide accurate, current and complete information about you and, in the case of a B2B
              transaction, your organization as prompted by the registration forms within the Services; (ii) to maintain
              the confidentiality of your password and other information related to the security of your account; (iii)
              to maintain and promptly update the Personal Data and any other information you provide to GlobalEnglish,
              to keep such information accurate, current and complete; and (iv) to be fully responsible for all use of
              your account and for any actions that take place through your account.
              <br />
              <br />
              GlobalEnglish assumes no duty to verify the accuracy of the Personal Data you provide for purpose of
              registration.
            </span>
            <br />
            <br />
            <b>6.3 International Transfer of Personal Data</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              To provide the Services, GlobalEnglish relies on its workforce around the world and use third party
              vendors and hosting partners for storage and other technology-related and teaching services. Your Personal
              Data is thus processed and transferred to GlobalEnglish's employees and subcontractors in various
              countries, some of which may not offer the same level of protection legally afforded to your Personal Data
              in your country of citizenship or residence.
              <b>
                By accessing, registering and/or using our Services, you consent to have your Personal Data transferred
                to and processed abroad in the various countries in which GlobalEnglish, its affiliates, and
                subcontractors are located for purposes of the Services.
              </b>
            </span>
            <br />
            <br />
            <b>6.4 Notices and Messages.</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              You understand and agree that the Services may include certain communications from GlobalEnglish, such as
              service announcements, administrative messages, etc., and that these communications are considered part of
              the Services and you will not be able to opt out of receiving them.
              <br />
              <br />
              You hereby agree that GlobalEnglish may provide notices and messages to you in the following ways: (i)
              within the Service; (ii) to the contact information you provided to GlobalEnglish (e.g. email or mobile
              number). You agree to keep your contact information up to date at all times.
            </span>
            <br />
            <br />
            <b>6.5 Privacy of Others</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              If you obtain information about another User in the course of your use of our Services, you agree that you
              will use this information only for the purpose it was provided to you. You may not disclose, sell or
              distribute a User's information to a third party for purposes unrelated to the Services. You may not use
              that information for marketing purposes unless you obtain the consent of the specific User to do so.
            </span>
            <br />
            <br />
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. USER LICENSES </b>
            <br />
            <br />
            <b>7.1 License to Use our Services</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              You are granted a limited, personal, non-exclusive, non-assignable, non-sublicensable and non-transferable
              license to access and use the Services for non-commercial, personal use only. GlobalEnglish may at its
              sole discretion discontinue temporarily or permanently the Service or modify the features of the Services
              from time to time without prior notice.
              <br />
              <br />
              In order to use the Services, you must obtain access to the World Wide Web, either directly or through
              devices that access web-based content. In addition, you must have all equipment necessary to make such
              connection to the World Wide Web, including a computer and modem or other access device. You agree that
              you must evaluate, and bear all risks associated with, the use of the Services, including any reliance on
              the accuracy, completeness, or usefulness of the content they provide. Neither GlobalEnglish nor its
              licensors guarantee the accuracy or completeness of any content.
              <br />
              <br />
              The Services are not designed or licensed for use in hazardous environments (e.g. while driving, in or
              around water).
              <br />
              <br />
              The right to access the Service is revoked where the use of the Service is prohibited or to the extent
              offering, sale or provision of the Service conflicts with any applicable law, rule or regulation.
              <br />
              <br />
              Use of the Service for any purpose other than as contemplated in the Terms of Use is a violation of the
              Terms of Use and of GlobalEnglish's and/or its licensors and subcontractors' copyright and proprietary
              rights. GlobalEnglish has the right to suspend or terminate your account and refuse any and all current or
              future Services to you if GlobalEnglish has reasonable grounds to suspect that you use the Services in
              violation of these Terms of Use. You agree that GlobalEnglish shall not be liable to you for any
              modification, suspension or discontinuation of the Services or any part thereof.
            </span>
            <br />
            <br />
            <b>7.2 User Content </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              The Services provide you and other Users with an opportunity to create data and information (e.g. by
              providing responses to our tests) and upload materials on community based services ("User Content").
              Subject to the rights granted to us in these Terms, you retain full ownership of your User Content.
              <br />
              <br />
              By uploading any User Content to our Services, you grant GlobalEnglish an irrevocable, perpetual,
              non-exclusive, transferable, fully paid, worldwide license (with the right to sublicense) to store, use,
              reproduce, publicly perform, publicly display, modify, translate, excerpt (in whole or in part), process
              and distribute such User Content as necessary in connection with the provision of the Services to:
              <br />
              <br />
              <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
                (a) Comply with legal process, including without limitation responding to legal requests, judicial
                orders and other similar governmental requests; <br />
                (b) Enforce the Agreement; <br />
                (c) Respond to claims that any Content violates third parties' rights; <br />
                (d) Protect the rights, property and personal safety of GlobalEnglish, its employees, Users and the
                public.
              </span>
              <br />
              <br />
              To the extent you choose to share any User Content with other Users of the Services, you agree to allow
              these Users to view your User Content and to the extent applicable, collaborate with you on such User
              Content.
              <br />
              <br />
              Be advised that GlobalEnglish has no obligation to screen, review, verify accuracy, correct or delete such
              User Content at any time. However, it reserves the right to monitor User Content and to remove those
              which, in its absolute discretion, it considers offensive or otherwise in breach of the Terms of Use.
              <br />
              <br />
              GlobalEnglish has no backup or storage obligations regarding User Content. You are solely responsible at
              your sole cost and expense for creating backup copies and replacing any User Content you post or store on
              the Website or provide to GlobalEnglish.
              <br />
              <br />
              User Content do not necessarily reflect the views of GlobalEnglish. You understand that User Content is
              the sole responsibility of the User from which such User Content originates. You understand that by using
              the Services, you may be exposed to User Content that is offensive, indecent or objectionable.
              <br />
              <br />
              In addition to the license grant to User Content set forth above, you acknowledge and agree that any
              questions, comments, suggestions, ideas, feedback or other information about the Services ("Submissions"),
              provided by you to GlobalEnglish are non-confidential and GlobalEnglish shall be entitled to the
              unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without
              acknowledgment or compensation to you. In addition, information posted, disclosed or shared on or through
              any of GlobalEnglish's community based Services or products by you shall be excluded from the definition
              of confidential information, as that term is generally defined.
              <br />
              <br />
              You understand that the technical processing and transmission of the website, including your User Content,
              may involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical
              requirements of connecting networks or devices.
              <br />
              <br />
              <b>
                GlobalEnglish makes no representations or warranties with respect to User Content. User Content do not
                necessarily reflect the views of GlobalEnglish. In no event shall GlobalEnglish have any responsibility
                or liability for the User Content or loss thereof for any reason or for any claims, damages or losses
                resulting from their use or loss.
              </b>
            </span>
            <br />
            <br />
            <b>7.3 Required User Conduct </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              You are fully responsible for all activities that occur under your account. You agree to:
              <br />
              <br />
              <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
                (a) immediately notify GlobalEnglish of any unauthorized use of Your account or any other breach of
                security, and <br />
                (b) ensure that you exit from your account at the end of each session.
              </span>
              <br />
              <br />
              GlobalEnglish cannot and will not be liable for any loss or damage arising from your failure to comply.
              <br />
              <br />
              You are solely responsible for ensuring that these Terms of Service are in compliance with all laws, rules
              and regulations applicable to you and the right to access the Service is revoked where these Terms of
              Service or use of the Service is prohibited or to the extent offering, sale or provision of the Service
              conflicts with any applicable law, rule or regulation.
            </span>
            <br />
            <br />
            <b>7.4 Prohibited User Conduct</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              The following are examples of the kind of use that is illegal or prohibited by GlobalEnglish.
              GlobalEnglish reserves the right to investigate and take appropriate legal action against anyone who, in
              GlobalEnglish's sole discretion, violates this provision, including without limitation, removing the
              offending content from the website or application, suspending or terminating the account of such Users and
              reporting you to the law enforcement authorities.
              <br />
              <br />
              You may not:
              <br />
              <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
                (a) adapt, download, revise, broadcast, reverse engineer, duplicate, publish, modify, disseminate,
                display, transfer, or otherwise copy and/or distribute any of GlobalEnglish's Content or other material
                on the Service, unless specifically authorized by GlobalEnglish. <br />
                (b) frame or utilize framing techniques to enclose any part of the Service; <br />
                (c) gather, obtain, use, access or otherwise copy any part of the Service by using any bot, spider,
                crawler, spy ware, engine, device, software or any other automatic device, utility or manual process of
                any kind; <br />
                (d) use the Services or any features available on the Services in any manner with the intent to
                interrupt, damage, disable, overburden or impair the Services; <br />
                (e) sell, license, rent or otherwise use the Services for commercial endeavors; or <br />
                (f) engage in any activity that interferes with another User's access, use or enjoyment of this Service.{' '}
                <br />
                (g) post, email or otherwise transmit any User Content that (i) is unlawful, harmful, threatening,
                abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, indecent, pornographic,
                libelous, invasive of another's privacy (up to but not excluding any address, email, phone number and
                other contact information), hateful racially, ethnically or otherwise objectionable; (ii) you do not
                have a right to transmit under any law or under contractual or fiduciary relationships; (iii) poses or
                creates a privacy or security risk to any person; (iv) infringes any intellectual property or other
                proprietary rights of any party; (v) constitutes unsolicited or unauthorized advertising, promotional
                materials, commercial activities and/or sales, "junk mail," "spam," "chain letters," "pyramid schemes,"
                "contests," "sweepstakes," or any other form of solicitation; (vi) contains software viruses or any
                other computer code, files or programs designed to interrupt, destroy or limit the functionality of any
                computer software or hardware or telecommunications equipment; or (vii) in the sole judgment of
                GlobalEnglish, is objectionable or restricts or inhibits any other person from using or enjoying the
                Services, or which may expose GlobalEnglish or its users to any harm or liability of any type; <br />
                (h) create a false identify, misrepresent your identity, impersonate any person or entity, or falsely
                stating or otherwise misrepresenting your affiliation with a person or entity; <br />
                (i) solicit personal information from anyone under the age of 18; <br />
                (j) harm minors in any way; <br />
                (k) promote physical harm or injury against any group or individual, "stalk" or otherwise harass anyone;{' '}
                <br />
                (l) provide or promote instructional information about illegal activities such as, e.g. instructions on
                how to assemble bombs, grenades and other weapons or incendiary devices; <br />
                (m) harvest or collect, through use of automated scripts or otherwise, the contents of the website or
                email addresses, contact information or other private information of other Users from the Website for
                any purpose, including without limitation for the purposes of sending unsolicited emails or other
                unsolicited communications to Users or reproducing the content of the website; <br />
                (n) advertise or offer to sell or buy any goods or services for any business purpose that is not
                specifically authorized; <br />
                (o) interfere with or disrupt the website or servers or networks connected to the website, or disobey
                any requirements, procedures, policies or regulations of networks connected to the website; <br />
                (p) violate any applicable local, state, national or international law, or any regulations having the
                force of law; further or promote any criminal activity or enterprise, or provide instructional
                information about illegal activities; <br />
                (q) obtain or attempt to access or otherwise obtain any materials or information through any means not
                intentionally made available or provided for through the website; <br />
                (r) override security features or bypass or circumvent any access controls; or <br />
                (s) use the Services in a manner that may create a conflict of interest or undermine the purposes of the
                Services, such as trading reviews with other Users or writing or soliciting shill reviews.
              </span>
            </span>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. INTELLECTUAL PROPERTY RIGHTS</b>
            <br />
            <br />
            <b>8.1 GlobalEnglish's Intellectual Property Rights</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              The entire content of the Services, including their Content and any supporting software are the
              proprietary property of GlobalEnglish, its licensors or its subcontractors and are protected by U.S. and
              international copyright and other intellectual property laws.
              <br />
              <br />
              The reproduction, redistribution, modification or publication of any part of the Services without the
              express written consent of GlobalEnglish and/or its licensors or other than set forth below is strictly
              prohibited.
              <br />
              <br />
              Authorized Users may access and use the Services and the Content and download or print a reasonable number
              of copies of portions of the Content to which the User has properly gained access solely for the User's
              personal use for language training purposes, provided that the User maintains all copyright or other
              proprietary notices on all copies of such Content.
              <br />
              <br />
              Unless otherwise indicated, trademarks that appear on the Services are trademarks of GlobalEnglish, its
              licensors or its affiliates. You agree not to display, disparage or taint the trademarks or use any
              confusing similar marks or use them in a way that would misrepresent the ownership of such marks or create
              the impression that GlobalEnglish endorses any product or service. You may not reproduce or use
              GlobalEnglish's trademarks, trade names and/or trade dress without the prior written permission of
              GlobalEnglish. All goodwill generated from the use of GlobalEnglish trademarks will inure to our exclusive
              benefit.
              <br />
              <br />
              Any use of the websites, applications or Content other than as specifically authorized herein is
              prohibited and will automatically terminate your rights with respect to use of the Services and the
              Content granted herein. All rights of GlobalEnglish or its licensors that are not expressly granted in
              these Terms are reserved to GlobalEnglish and its licensors.
            </span>
            <br />
            <br />
            <b>8.2 Third Party's Trademarks</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Product and Service names and logos used and displayed via the Website, other than the trademarks of
              GlobalEnglish described above, may be trademarks or service marks of their respective owners who may or
              may not endorse or be affiliated with or connected to GlobalEnglish. Nothing in these Terms or on the
              website should be construed as granting, by implication, estoppel, or otherwise, any license or right to
              use any of trademarks displayed on the Website without our prior written permission in each instance. All
              goodwill generated from the use of GlobalEnglish trademarks will inure to our exclusive benefit.
            </span>
            <br />
            <br />
            <b>8.3 Copyright Complaints Under the Digital Millennium Copyright Act</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              If you have any copyright concerns about any materials posted on the Services by others, please let us
              know.
              <br />
              <br />
              GlobalEnglish will process and investigate notices of alleged infringement and will take appropriate
              actions under the Digital Millennium Copyright Act ("DMCA") and other applicable intellectual property
              laws with respect to any alleged or actual infringement.
              <br />
              <br />
              A notification of claimed copyright infringement should be emailed to GlobalEnglish's Copyright Agent at
              copyright@globalenglish.com (Subject line: "DMCA Takedown Request").
              <br />
              <br />
              You may also contact us by mail at:
              <br />
              <b>Attn: Copyright Complaints/Legal Department</b>
              <br />
              1875 South Grant Street, Suite 700 <br />
              San Mateo, CA 94402 USA
              <br />
              <br />
              <br />
              To be effective, the notification must be in writing and contain the following information: (i) an
              electronic or physical signature of the person authorized to act on behalf of the owner of the copyright
              or other intellectual property interest; (ii) a description of the copyrighted work or other intellectual
              property that you claim has been infringed; (iii) a description of where the material that you claim is
              infringing is located on the Service, with enough detail that we may find it on the website; (iv)
              information sufficient for GlobalEnglish to contact you (address, telephone number, and email address);
              (v) a statement by you that you have a good faith belief that the disputed use is not authorized by the
              copyright or intellectual property owner, its agent, or the law; (vi) a statement by you, made under
              penalty of perjury, that the above information in your Notice is accurate and that you are the copyright
              or intellectual property owner or authorized to act on the copyright or intellectual property owner's
              behalf.
              <br />
              <br />
              In accordance with the DMCA and other applicable law, GlobalEnglish has adopted a policy of terminating,
              in appropriate circumstances and at GlobalEnglish's sole discretion, users who are deemed to be repeat
              infringers. GlobalEnglish may also at its sole discretion limit access to the Website and/or terminate the
              memberships of any users who infringe any intellectual property rights of others, whether or not there is
              any repeat infringement.
            </span>
            <br />
            <br />
            <b>8.4 Removal of User Content Suspected of Infringing </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              If GlobalEnglish has removed some of your User Content suspected on infringing upon the intellectual
              property rights of third parties and you believe that your User Content that was removed (or to which
              access was disabled) is not infringing, or that you have the authorization from the copyright owner, the
              copyright owner's agent, or pursuant to the law, to post and use the content in your User Content, you may
              send a written counter-notice containing the following information to the Copyright Agent: (i) your
              electronic or physical signature; (ii) identification of the content that has been removed or to which
              access has been disabled and the location at which the content appeared before it was removed or disabled;
              (iii) a statement that you have a good faith belief that the content was removed or disabled as a result
              of mistake or a misidentification of the content; and (iv) your name, address, telephone number, and
              e-mail address, a statement that you consent to the jurisdiction of the federal court located within
              Northern District of California and a statement that you will accept service of process from the person
              who provided notification of the alleged infringement.
              <br />
              <br />
              If a counter-notice is received by the Copyright Agent, GlobalEnglish will send a copy of the
              counter-notice to the original complaining party informing that person that it may replace the removed
              content or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a
              court order against the content provider, member or user, the removed content may be replaced, or access
              to it restored, in 10 to 14 business days or more after receipt of the counter-notice, at our sole
              discretion.
            </span>
            <br />
            <br />
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 9. INTERNATIONAL USE AND EXPORT CONTROLS</b>
            <br />
            <br />
            Recognizing the global nature of the Internet, you agree to comply with all local rules regarding online
            conduct and acceptable User Content. You agree to comply with all applicable laws regarding the transmission
            of technical data exported from the United States or the country in which you reside.
            <br />
            <br />
            You acknowledge that the Services or portion thereof may be subject to the export control laws of the United
            States. You will not export, re-export or transfer any portion of the Service or any related technical
            information or materials directly or indirectly in violation of any applicable export law or regulation. You
            represent and warrant that you are not a citizen of, nor located in, a country subject to a U.S. Government
            embargo or that has been designated by the U.S. Government as a "terrorist supporting" country and that you
            are not listed on any U.S. Government list of prohibited or restricted parties.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. INJUNCTIVE RELIEF</b>
            <br />
            <br />
            You acknowledge that any use of the Service contrary to the Terms of Use may cause irreparable injury to
            GlobalEnglish, its affiliates, licensors, subcontractors, resellers and Users. Under such circumstances,
            GlobalEnglish, its affiliates, licensors, subcontractors, resellers and Users will be entitled to equitable
            relief without posting bond or other security, including but not limited to preliminary and permanent
            injunctive relief.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11. DOWNTIME</b>
            <br />
            <br />
            The Services may be temporarily unavailable from time to time for maintenance or other reasons.
            GlobalEnglish shall have no responsibility for any interruption, delay in operation or transmission, theft
            or destruction of, unauthorized access to, or alteration of, User communications or any other content made
            available via the website.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12. DISCLAIMER OF WARRANTIES</b>
            <br />
            <br />
            The Services may be temporarily unavailable from time to time for maintenance or other reasons.
            GlobalEnglish shall have no liability for any interruption, delay in operation or transmission, theft or
            destruction of, unauthorized access to, or alteration of, User communications or any other content made
            available via the Services. Under no circumstances will GlobalEnglish be liable in any way for any personal
            injury or death resulting from the use of the Services, any User Content or Third-Party Content, or any use
            of products or services provided by Users.
            <br />
            <br />
            YOU UNDERSTAND AND AGREE THAT:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) THE SERVICES, INCLUDING ALL CONTENT THEREIN, ARE PROVIDED "AS IS" AND AS AVAILABLE.
              <br />
              <br />
              (b) GLOBALENGLISH DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE SERVICES,
              WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE OR NON-INFRINGEMENT.
              <br />
              <br />
              (c) GLOBALENGLISH DOES NOT GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF THE SERVICE NOR
              THAT THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS.
              <br />
              <br />
              (d) GLOBALENGLISH DOES NOT REPRESENT OR WARRANT THAT SOFTWARE, CONTENT OR MATERIALS IN THE SERVICES OR ON
              THE WEBSITE ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE OR THAT THE WEBSITE OR SERVICES, OR
              ITS SERVERS, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THEREFORE, WITHOUT LIMITING THE FOREGOING,
              YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR OTHERWISE OBTAIN CONTENT, MATERIAL, DATA OR SOFTWARE FROM OR
              THROUGH THE SERVICES OR FROM A PROVIDER AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY
              RESPONSIBLE FOR YOUR USE THEREOF AND ANY DAMAGES TO YOUR COMPUTER SYSTEM, LOSS OF DATA OR OTHER HARM OF
              ANY KIND THAT MAY RESULT.
              <br />
              <br />
              (e) GLOBALENGLISH DOES NOT GUARANTEE THAT ANY ERRORS OR DEFECTS IN THE SERVICES WILL BE CORRECTED.
              <br />
              <br />
              (f) GLOBALENGLISH IS NOT RESPONSIBLE FOR THE CONDUCT OF USERS, THIRD PARTY CONTENT AND USER CONTENT.
              <br />
              <br />
              (g) The Services may be temporarily unavailable from time to time for maintenance or other reasons.
              GlobalEnglish shall have no liability for any interruption, delay in operation or transmission, theft or
              destruction of, unauthorized access to, or alteration of, User communications or any other content made
              available via the Services. Under no circumstances will GlobalEnglish be liable in any way for any
              personal injury or death resulting from the use of the Services, any User Content or Third-Party Content,
              or any use of products or services provided by Users.
            </span>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. LIMITATION OF LIABILITY</b>
            <br />
            <br />
            IN NO EVENT WILL GLOBALENGLISH OR ITS DIRECTORS, EMPLOYEES, AGENTS, LICENSORS OR SUBCONTRACTORS BE LIABLE TO
            YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES,
            INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING FROM: (I) YOUR USE OF OR INABILITY TO USE THE SERVICES,
            OR ANY OF THE CONTENT OR OTHER MATERIALS ON, ACCESSED THROUGH OR DOWNLOADED FROM THE WEBSITE, SERVICES OR
            THE PROVIDERS, (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA,
            INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR
            FROM THE WEBSITE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA BY ANY PARTY;
            (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE WEBSITE; OR (V) ANY OTHER MATTER RELATING TO THE
            SERVICES. IN NO EVENT SHALL GLOBALENGLISH'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF
            ACTION EXCEED THE AMOUNT YOU HAVE PAID GLOBALENGLISH IN THE LAST TWELVE (12) MONTHS, OR, IF GREATER, ONE
            HUNDRED DOLLARS ($100), EVEN IF GLOBALENGLISH IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. THE EXISTENCE OF MORE THAN ONE CLAIM HEREUNDER WILL NOT INCREASE THIS LIMIT.
            <br />
            <br />
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE LIMITATIONS SET FORTH ABOVE MAY
            NOT APPLY TO YOU.
            <br />
            <br />
            IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE WEBSITE OR WITH THESE TERMS OF WEBSITE, YOUR SOLE AND
            EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE WEBSITE.
            <br />
            <br />
            UNDER NO CIRCUMSTANCES, WILL GLOBALENGLISH BE RESPONSIBLE FOR PERSONAL INJURY OR DEATH RESULTING FROM THE
            USE OF THE SERVICES OR THIRD-PARTY CONTENT OR USER CONTENT.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14. STATUTE OF LIMITATION</b>
            <br />
            <br />
            You agree that regardless of any statute or law to the contrary, any claim or cause of action against
            GlobalEnglish arising out of or related to your use of the Services must be filed within one (1) year after
            such claim or cause of action arose or be forever barred.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 15. INDEMNIFICATION</b>
            <br />
            <br />
            You agree to release, indemnify and hold GlobalEnglish and its affiliates and their officers, employees,
            directors and agent harmless from any from any and all claims, actions, costs, liabilities, losses, damages,
            expenses, including reasonable attorneys' fees, made against GlobalEnglish by any third party due to or
            arising out of or relating to your use of the website, Services, your User Content, your connection to the
            Website or Services, your violation of these Terms or your violation of any rights of another User or of any
            law or otherwise arising out of your use of the Services. Your indemnity obligations include without
            limitation claims against GlobalEnglish that any User Content infringes a third party's intellectual
            property rights.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 16. TERMINATION</b>
            <br />
            <br />
            If your subscription is terminated for any reason, GlobalEnglish will disable your access to the Services.
            You will not be able to access any Content, including your User Content, although copies of such data shall
            remain in our system for three (3) years for back-up purposes.
            <br />
            <br />
            If you request that GlobalEnglish delete your Personal Data and User Content, we will make all reasonable
            efforts to do so and will confirm when the erasure has taken place.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 17. WAIVER AND SEVERABILITY</b>
            <br />
            <br />
            The failure of GlobalEnglish to exercise or enforce any right or provision of these Terms shall not
            constitute a waiver of such right or provision in that or any other instance. If any provision of these
            Terms is determined to be unlawful, void or for any reason unenforceable, such determination shall not
            affect the validity and enforceability of any of the remaining provisions.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 18. ENTIRE AGREEMENT</b>
            <br />
            <br />
            This Agreement constitute the entire agreement between you and GlobalEnglish regarding the use of the
            Services and supersede any prior understandings and agreements between you and GlobalEnglish relating to
            your use of the Services.
            <br />
            <br />
            Notwithstanding the foregoing, if your Company has an existing Agreement with GlobalEnglish for the purchase
            of any Services, these Terms are not intended to alter the terms or conditions of such agreement your
            Company may have with GlobalEnglish, its subsidiaries or affiliates, and to the extent of any conflict, the
            terms of such Agreement will prevail.
            <br />
            <br />
            This Agreement has been prepared in the English language and such version shall be controlling in all
            respects and any non-English version of this Agreement is solely for accommodation purposes. A printed
            version of this agreement and of any notice given in electronic form shall be admissible in judicial or
            administrative proceedings based upon or relating to this agreement to the same extent and subject to the
            same conditions as other business documents and records originally generated and maintained in printed form.
            The section titles in these Terms are for convenience only and have no legal or contractual effect.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 19. CHOICE OF LAW, FORUM AND ARBITRATION </b>
            <br />
            <br />
            These Terms shall be governed by the laws of the State of California without regard to its conflict of law
            provisions.
            <br />
            <br />
            At GlobalEnglish's election and in its sole discretion, all disputes, claims, or controversies arising out
            of or relating to these Terms or the Website that are not resolved by mutual agreement may be resolved by
            binding arbitration to be conducted before JAMS, or its successor. Unless otherwise agreed by the parties,
            arbitration will be held in San Francisco, California before a single arbitrator mutually agreed upon by the
            parties, or if the parties cannot mutually agree, a single arbitrator appointed by JAMS, and will be
            conducted in accordance with the rules and regulations promulgated by JAMS unless specifically modified in
            these Terms. The arbitration must commence within forty-five (45) days of the date on which a written demand
            for arbitration is filed by either party. The arbitrator's decision and award shall be made and delivered
            within sixty (60) days of the conclusion of the arbitration and within six (6) months of the selection of
            the arbitrator. The arbitrator will not have the power to award damages in excess of the limitation on
            actual compensatory, direct damages set forth in the Terms and may not multiply actual damages or award
            punitive damages or any other damages that are specifically excluded under the Terms, and each party hereby
            irrevocably waives any claim to such damages. The arbitrator may, in his or her discretion, assess costs and
            expenses (including the reasonable legal fees and expenses of the prevailing part) against any party to a
            proceeding. Any party refusing to comply with an order of the arbitrators will be liable for costs and
            expenses, including attorneys' fees, incurred by the other party in enforcing the award. Notwithstanding the
            foregoing, in the case of temporary or preliminary injunctive relief, any party may proceed in court without
            prior arbitration for the purpose of avoiding immediate and irreparable harm. The provisions of this
            arbitration section will be enforceable in any court of competent jurisdiction.
            <br />
            <br />
            With respect to any disputes or claims not subject to arbitration, as set forth above, you and GlobalEnglish
            agree to submit to the personal and exclusive jurisdiction of the state and federal courts located within
            San Francisco County, California.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. NOTICES AND CONTACTS</b>
            <br />
            <br />
            You may ask to terminate your GlobalEnglish Service account by providing GlobalEnglish with notice of
            termination through the "Contact Us" form link at
            <a href="https://support.globalenglish.com/" rel="noopener noreferrer" target="_blank">
              https://support.globalenglish.com/
            </a>
            . Your access to and use of the Services, including any content therein, may be prevented by GlobalEnglish
            at any time after your termination of your account.
            <br />
            <br />
            Notices to you may be made via either email or regular mail. The website may also provide notices to you of
            changes to these Terms or other matters by displaying notices or links to notices generally on the Service.
            <br />
            <br />
            Notices related to our privacy policy may be sent to
            <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
              privacy@globalenglish.com.
            </a>
            <br />
            <br />
            All other legal notices or other correspondence to GlobalEnglish must be sent to the following electronic
            mail address:{' '}
            <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
              legal@globalenglish.com
            </a>{' '}
            or to the following physical address:
            <br />
            <br />
            1875 S Grant Street, Suite 700 <br />
            San Mateo, CA 94402 <br />
            to the attention of
            <b>Legal Counsel</b>
            <br />
          </p>
          <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
            <b>SPECIFIC TERMS FOR B2B TRANSACTIONS</b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            If you obtained your account with GlobalEnglish through your employer, your use of our Services is also
            subject to our agreement with your company (a "Company"). The Terms of Use are not intended to alter the
            terms of the agreement with your Company. You may not use (or continue to use) the Services if your Company
            does not authorize you as an end-user on their license with GlobalEnglish. If you ask us to terminate your
            account, your Company may be required to give permission before GlobalEnglish terminates your account.
            <br />
            <br />
            You agree to use the Services solely as permitted by your Company. You may have the opportunity to
            communicate with others within your Company or upload documents related to your Company through the
            Services, but you are not permitted to do so, and may not do so, without the authorization of your Company.
            <br />
            <br />
            You acknowledge and agree that all data generated in connection with your use of the Services ("User Data")
            may be accessible by your Company and that GlobalEnglish shall have no liability to you based upon the
            information shared with your Company as a consequence of your use of our Services.
            <br />
            <br />
            Because your subscription to access the Services continues only for the term of that Company's license, if
            your Company terminates its license with GlobalEnglish, you will lose access to the Services and to the User
            Content. GlobalEnglish may terminate or suspend your Company's account, your account or ability to use the
            Services, in whole or in part, without notice in the event that (i) your Company fails to pay any fees due
            hereunder to GlobalEnglish when due, or (ii) you or your Company violate the Terms or violate any other
            rules that govern the use of the Services.
            <br />
            <br />
            GlobalEnglish may have an obligation to return all data pertaining to your use of the Services, including
            your Personal Data and User Content to your company. If your company does not request that such data be
            returned to it or erased, GlobalEnglish shall keep the data for a period of three (3) years following
            expiration or termination of its contract with your employer. In such cases, you are welcome to purchase a
            subscription to the service directly from GlobalEnglish and continue your access to the program with no loss
            of progress information. You could authorize the transfer of your progress and profile information into a
            new subscription with your new employer. You can also request all data pertaining to your use of the
            Services in a format compatible with future imports.
          </p>
          <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
            <b>SPECIFIC TERMS FOR B2C TRANSACTIONS</b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            You may also enter into a subscription agreement with GlobalEnglish as an individual which may comprise of
            either a three (3) or six (6) month subscription or an annual or multiyear subscription plan (each, a
            "Subscription Plan").
            <br />
            <br />
            You will be billed monthly or annually for the applicable Subscription Plan on or about the same day each
            month/year (as applicable) until such time that you cancel your Subscription Plan. For the avoidance of
            doubt, please note, you will not be permitted to cancel, reduce the number of seats, or downgrade the
            Service you have selected until the anniversary of your Subscription Plan. There will be no refunds for
            Subscription Plan payments.
            <br />
            <br />
            The fees applicable for the Service ("Fees") are available on the Website and/or in GlobalEnglish's
            then-current published price list. The price stated for the Service excludes all taxes and charges, unless
            stated otherwise. You're responsible for any taxes and for all other charges incidental to using the
            Services (for example, data charges and currency exchange settlements). You will pay the Fees in the
            currency GlobalEnglish quoted for your account. GlobalEnglish reserves the right to change the quoted
            currency at any time.
            <br />
            <br />
            <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}> Payment</b>
            <br />
            <br />
            Payment is due upon purchase of a subscription license. We have partnered with Paypal and Stripe to process
            your payments. We do not receive or process any credit card information or other payment method you provide
            to these service providers.
            <br />
            <br />
            All Services are prepaid for the period selected (monthly, yearly or otherwise) and are non-refundable. This
            includes accounts that are renewed.
            <br />
            <br />
            -----
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>Notice for California Users</b>
            <br />
            <br />
            If you are a California resident, the California Civil Code Section 1789.3 permits you to request
            information regarding the disclosure of your Personal Data to third parties for the third parties' direct
            marketing purposes. The California Consumer Privacy Act which will come into force in 2021 (Civil Code
            Section 1798.100 to 1709.198) also gives you certain rights:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) right to know which Personal Data has been collected about you and receive copies,
              <br />
              <br />
              (b) right of deletion,
              <br />
              <br />
              (c) right to know to which third parties your Personal Data would have been sold and right to request that
              such sale cease and
              <br />
              <br />
              (d) right to be free from discrimination in the event you exercise some of your consumer's rights.
            </span>
            <br />
            In addition, the California Business and Profession Code Section 22581 currently permits California resident
            who are minor to request the removal of content they have publicly posted on the Website. Note however that
            GlobalEnglish cannot ensure complete or comprehensive removal of the content you have posted and that there
            may be circumstances under which the law does not require or allow removal even if requested.
            <br />
            <br />
            If you want to make a request to exercise your rights:
            <br />
            <br />
            The provider of the Website and associated services is GlobalEnglish Corporation, located at 1875 South
            Grant Street, Suite 700, San Mateo, CA 94402, USA. The email address is: privacy@globalenglish.com. <br />
            As of the date of these Terms, no direct charges are imposed upon the consumer by the Website. If you have a
            complaint with the services provided on the Website or wish to request further information, please contact
            us at the physical address above with a description of your request. We cannot guarantee a response in any
            particular time period. If you remain unsatisfied, The Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market
            Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
          </p>
        </div>
      </div>
    </>
  );
}

export default EnContent;
