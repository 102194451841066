import React from "react";
import { AppContainer } from "containers/App";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from '@datadog/browser-rum';

const App = () => {

  datadogRum.init({
    applicationId: `${process.env.REACT_APP_DATADOG_APPLICATION_ID}`,
    clientToken: `${process.env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
    site: 'datadoghq.eu',
    service: 'login-frontend',
    env: `${process.env.REACT_APP_ENV}`,
    allowedTracingUrls: [`${process.env.REACT_APP_GE_LOGIN_URL}`, (url) => url.startsWith(`${process.env.REACT_APP_GE_LOGIN_URL}`)],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogLogs.init({
    clientToken:`${process.env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
    site: 'datadoghq.eu',
    service:'login-frontend',
    version:`${process.env.TF_DD_APP_VERSION}`,
    env:`${process.env.REACT_APP_ENV}`,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });

  return (
  <CookiesProvider>
    <ThemeProvider theme={theme}>
      <AppContainer />
    </ThemeProvider>
  </CookiesProvider>
  );
};

export default App;