/* eslint-disable */
// @ts-nocheck
import React from 'react';

function CzCzContent() {
  return (
    <>
      <div id="agreement_container">
        <div style={{ padding: '1% 5% 0 5%' }}>
          <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
            <b>SMLUVNÍ PODMÍNKY</b>
          </p>
          <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
            <b>ÚVOD</b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            Vítejte ve službě GlobalEnglish, ať už na webu nebo v mobilní aplikaci (souhrnně dále jen jako “Služby”).
            <br />
            <br />
            Našimi Službami jsou nástroje pro výuku angličtiny a online řešení zahrnující zejména data, texty, online
            testy, designy, videa, audio klipy, hlasové nahrávky, psané příspěvky a komentáře, scénáře, grafické
            materiály, obrázky a jejich výběry a sestavy, software, návody, lekce, poradenství, trénink, komunikaci a
            interaktivní prvky vygenerované nebo přístupné na našich stránkách a mobilních aplikacích (souhrnně
            “Obsah”). Vstupte na naše stránky a seznamte se s informacemi o naší společnosti a které Služby jsou
            považovány za součást našich Služeb.
            <br />
            <br />
            Před pokračováním si, prosím, důkladně pročtěte text níže a potvrďte, že podmínkám níže rozumíte a
            souhlasíte s nimi.
            <br />
            <br />
            <b>
              POKUD VÁM JE MÉNĚ NEŽ 18 LET, TUTO DOHODU SI PROSÍM PŘEČTĚTE SE SVÝM RODIČEM NEBO ZÁKONNÝM ZÁSTUPCEM A
              PŘED POUŽÍVÁNÍM NAŠICH SLUŽEB SI VYŽÁDEJTE JEJICH SOUHLAS. POUŽÍVÁTE-LI NAŠE SLUŽBY, POTVRZUJETE TÍM, ŽE
              JE VÁM VÍCE NEŽ 18 LET A NEBO ŽE VÁM VÁŠ RODIČ ČI ZÁKONNÝ ZÁSTUPCE POVOLIL VSTOUPIT DO TOHOTO SMLUVNÍHO
              VZTAHU S GLOBALENGLISH.
            </b>
          </p>
          <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
            <b>VŠEOBECNÉ PODMÍNKY</b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 1. PRÁVNĚ ZÁVAZNÁ SMLOUVA</b>
            <br />
            <br />
            <b>
              VÁŠ PŘÍSTUP NA NAŠE STRÁNKY A K POUŽÍVÁNÍ SLUŽEB GLOBALENGLISH JSOU PŘEDMĚTEM SMLUVNÍCH PODMÍNEK NÍŽE,
              PRAVIDEL OCHRANy SOUKROMÍ A PRAVIDEL COOKIES (VŠECHNY TYTO TŘI DOKUMENTY SOUHRNNĚ DÁLE JEN JAKO “SMLUVNÍ
              PODMÍNKY”). VSTUPEM, NÁVŠTĚVOU, REGISTRACÍ A/NEBO POUŽÍVÁNÍM SLUŽEB GLOBALENGLISH SOUHLASÍTE S TÍM, ŽE
              JSTE SI PŘEČETLI SMLUVNÍ PODMÍNKY VYUŽÍVÁNÍ NAŠICH SLUŽEB, SOUHLASÍTE SE ZÁVAZNOSTÍ TĚCHTO PODMÍNEK ,
              PROHLAŠUJETE, ŽE TENTO SOUHLAS NEODVOLÁTE A DÁLE ŽE MÁTE ZÁKONNÝ VĚK POTŘEBNÝ K UDĚLENÍ SOUHLASU SE
              ZÁVAZNOSTÍ TĚCHTO PODMÍNEK A KE VSTUPU DO PRÁVNĚ ZÁVAZNÉHO SMLUVNÍHO VZTAHU S GLOBALENGLISH.
            </b>
            <br />
            <br />
            Přehled našich pravidel můžete najít
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              zde
            </a>
            . Tato pravidla jsou zahrnuta ve formě odkazů v těchto “Smluvních podmínkách” či “Smlouvě” a společně s nimi
            tvoří smluvní uživatelské podmínky.
            <br />
            <br />
            <b>
              Vezměte prosím na vědomí, že tato Smlouva obsahuje opatření, která definují způsoby řešení nároků, které
              vy i my můžeme vůči sobě navzájem mít. Podrobnosti naleznete v Prohlášení o zárukách, omezeních
              odpovědnosti a dále také níže v ustanoveních Právní spory. Tato Smlouva obsahuje také rozhodčí doložka,
              která od vás vyžaduje - až na výjimky - předložit vaše požadavky a nároky, které vůči nám máte, k
              závaznému a konečnému rozhodnutí v rámci rozhodčího řízení. Požadavky a nároky můžete předložit pouze
              individuálně, nikoli v rámci hromadné žaloby či řízení. Nároky (včetně finančních, zákazových, nebo
              deklaratorních) můžete uplatňovat pouze individuálně.
              <br />
              <br />
              NEPOUŽÍVEJTE NAŠE SLUŽBY ANI DÁLE NENAVŠTĚVUJTE NAŠE STRÁNKY, POKUD VE KTERÉMKOLI BODĚ NESOUHLASÍTE S
              JAKOUKOLI ČÁSTÍ NÍŽE DEFINOVANÝCH PODMÍNEK.
            </b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. PLATNOST </b>
            <br />
            <br />
            Tato Smlouva se vztahuje jak na Uživatele našich služeb, tak na Návštěvníky našich stránek. Na osoby, které
            se u nás zaregistrovali přímo (<b>“B2C transakce”</b>), včetně zkušebních verzí, ale také na ty, co byly
            registrovány jejich zaměstnavatelem nebo jeho prostřednictvím (<b>“B2B transakce”</b>) bude dále odkazováno
            jako na
            <b>“Uživatele”</b> zatímco neregistrované uživatele a návštěvníky našich stránek budeme dále označovat jen
            jako
            <b>“Návštěvníky”</b>. Pojem <b>“Uživatelé”</b> zahrnuje také učitele, lektory, výukové specialisty a
            jakékoli další osoby, který používí stránky GlobalEnglish k poskytování služeb dalším Uživatelům. Jak
            uživatelé, tak Návštěníci jsou v textu souhrnně označováni také jako <b>“Vy”</b>.
            <br />
            <br />
            Tato smlouva se vztahuje na všechny Služby GlobalEnglish.
            <br />
            <br />
            Když se u nás registrujete poprvé jako Uživatel a když používáte naše Služby, můžeme vás požádat o opětovný
            souhlas s našimi podmínkami a vyjádření jejich závaznosti kliknutím na políčko
            <b>“Souhlasím”</b> nebo poskytnutím elektronického podpisu či jakéhokoliv dalšího způsobu autentizace.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>3.ZMĚNY SMLUVNÍCH PODMÍNEK</b>
            <br />
            <br />
            GlobalEnglish může kdykoliv změnit Smluvní podmínky včetně této Smlouvy, Pravidel ochrany soukromí a
            Pravidel cookies, které se vztahují na vaše používání Služeb. Tyto změny nabývají platnosti okamžitě po
            jejich oznámení, které proběhne formou zveřejnění v prostředí našich Služeb, zasláním zprávy na e-mail,
            který jste nám k vašemu Uživatelskému účtu poskytli nebo jinými způsoby. Pokud jste se k našim Službám
            registrovali prostřednictvím vašeho zaměstnavatele nebo společnosti, může vás o změnách Smluvních podmínek
            informovat váš zaměstnavatel nebo společnost.
            <br />
            <br />
            Pokud se změnami nebudete souhlasit, můžete svůj účet zrušit a naše stránky přestat používat. Vaše
            pokračující používání našich Služeb po oznámení změn Smluvních podmínek však znamená, že s těmi změnami
            Smluvních podmínek souhlasíte.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4.UŽIVATELSKÉ PODMÍNKY TŘETÍCH STRAN</b>
            <br />
            <br />
            Naše služby mohou obsahovat odkazy na jiné webové stránky (“Weby třetích stran”) a stejně tak na články,
            fotografie, texty, překlady, grafické materiály, obrázky, designy, zvukové nahrávky, videa, informace i
            další obsah či položky patřící třetím stranám nebo od nich pocházející (“Obsah třetích stran”). Tyto Weby
            třetích stran, stejně jako ani Obsah třetích stran nezkoumáme, nesledujeme ani neručíme za jejich správnost,
            vhodnost a celistvost. Nejsme zodpovědni za žádný z Webů třetích stran přístupný z našich stránek ani za
            Obsah třetích stran zveřejněný na našich stránkách nebo z našich stránek dostupný.
            <br />
            <br />
            Stejně tak platí, že odkazy na a dostupnost jakéhokoli Webu třetích stran nebo Obsah třetích stran z našich
            stránek nepředstavují náš souhlas s předmětným obsahem ani jejich schvalování ze strany GlobalEnglish.
            GlobalEnglish si vyhrazuje právo takovéto odkazy kdykoliv odstranit, a to i bez předchozího upozornění.
            Odkazy na služby jakýchkoli společností či organizací, na produkty, služby a na jiné informace nepředstavují
            ani nelze vykládat jako jejich podporu, sponzoring či doporučení ze strany GlobalEnglish, ani jakékoli
            propojení s nimi.
            <br />
            <br />
            Rozhodnete-li se opustit naše stránky a přejít na Web třetí strany nebo vstoupit na/použít Obsah třetích
            stran, činíte tak na vlastní riziko a berete na vědomí, že naše smluvní podmínky a pravidla se na vás při
            používání Webů třetích stran nevztahují. Ujistěte se prosím, že jste se před používáním produktů či služeb
            třetích stran seznámili s jejich smluvními podmínkami a pravidly.
            <br />
            <br />
            Poskytujeme mobilní aplikace, a to prostřednictvím Android Market i iTunes Store (tyto, stejně jako
            právnické osoby, které je provozují, označujeme jako “Poskytovatele“). Vaše používání těchto mobilních
            aplikací podléhá tovněž smluvním podmínkám Poskytovatelů, zejména Pravidly používání uvedenými ve Smluvních
            podmínkách App Store. Pokud jste si stáhnuli kteroukoli z těchto mobilních aplikací z iTunes Store,
            potvrzujete a souhlasíte s tím, že jste si přečetli a souhlasili se Smluvními podmínkami App Store. Pokud
            jste si je stáhli z Android Market, potvrzujete a souhlasíte, že jste si přečetli a souhlasili se Smluvními
            podmínkami Android Market. Poskytovatelé nemají žádnou povinnost (ani tak neučiní) poskytovat vám v
            souvislosti s vaším používáním našich Služeb technickou ani zákaznickou podporu. Poskytovatelé nenabízejí
            žádnou záruku pro naše Služby. V případě, že se v jakékoli z mobilních aplikací vyskytne vada, na kterou se
            nevztahuje záruka, a vy jste si tuto aplikaci stáhnuli z iTunes Store, můžete to společnosti Apple oznámit,
            přičemž Apple vám následní vrátí kupní cenu (pokud nějaká byla) takové aplikace zpět. Tyto Podmínky se
            vztahují na vaše používání všech mobilních aplikací a také všech našich Služeb.
            <br />
            <br />
            Berete na vědomí a souhlasíte s tím, že GlobalEnglish nenese odpovědnost za obsah, produkty, služby ani
            funkčnost webů ani aplikací třetích stran. Neodvolatelně se vzdáváte jakýchkoli nároků vůči GlobalEnglish ve
            vztahu k webům a aplikacím třetích stran. Zvláště pak platí, že GLOBALENGLISH NENÍ ODPOVĚDNÉ ZA COOKIES,
            PIXELOVÉ TAGY, CLEAR GIFS A DALŠÍ KÓDY, KTERÉ VY NEBO DALŠÍ STRANY JAKÝMKOLI ZPŮSOBEM UMÍSTÍTE DO SVÉHO
            POČÍTAČE, VČETNĚ JAKÉHOKOLI PROPOJENÍ MEZI OBSAHEM STRÁNEK S TAKOVÝMTO KÓDEM. Pokud například navštívíte Web
            třetí strany skrze odkaz na našich stránkách, váš prohlížeč nebo jiné cookies ve vašem počítači mohou
            takovému webu odeslat referer hlavičku, která jim sdělí část vaší historie prohlížení internetu nebo URL
            předcházejícího webu, ze kterého jste na jejich web vstoupili. GlobalEnglish vůči vám v souvislosti s
            takovým přenosem informací nenese žádnou odpovědnost, ať takový přenos obsahuje informaci spojenou
            s/pocházející z Global English či nikoliv.
            <br />
            <br />
            POKUD VYUŽÍVÁTE PŘEKLADY OD GOOGLE: GOOGLE ODMÍTÁ VŠECHNY ZÁRUKY SPOJENÉ S JEJICH PŘEKLADY, A TO VÝSLOVNĚ ČI
            IMPLICITNĚ, VČETNĚ VŠECH ZÁRUK TÝKAJÍCÍCH SE PŘESNOSTI A SPOLEHLIVOSTI A TAKÉ VŠECH IMPLICITNÍCH ZÁRUK
            TÝKAJÍCÍCH SE KOMERČNÍHO VYUŽITÍ NEBO VHODNOSTI PRO VYUŽITÍ KE KONKRÉTNÍM ÚČELŮM A ZAMEZENÍ PORUŠOVÁNÍ PRÁV.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>5. KRITÉRIA ZPŮSOBILOSTI K VYUŽÍVÁNÍ SLUŽEB</b>
            <br />
            <br />
            <b>5.1 Ochrana soukromí nezletilých a omezení v používání našich Služeb.</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Naše Služby nejsou určeny osobám, které pro využívání Služeb nedosáhly potřebného minimálního věku
              určeného zemí, ze které naše Služby využívají. Například v Evropské Unii, Evropském hospodářském prostoru
              a ve Švýcarsku činí “Minimální věk” 16 let, není-li zvláštními zákony jednotlivých zemích stanoven jinak.
              Ve Spojených státech činí Minimální věk 13 let. Jakákoli registrace, využívání, nebo vstup na naše Služby
              kýmkoli, kdo nedosáhl Minimálního věku, je zakázaná a představuje porušení těchto Pravidel.
              <br />
              <br />
              Využíváním Služeb stvrzujete, že jste dosáhli 13 let věku.
            </span>
            <br />
            <br />
            <b>5.2 Školy a učitelé</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Školy, komunity nebo učitelé ve Spojených státech, kteří požadují od svých studentů mladších 13 let
              vytvoření účtů GlobalEnglish, souhlasí, že jsou zodpovědní za dodržování amerického zákona o online
              ochraně dětí (Children's Online Privacy Protection Act, "COPPA") a v použitelném rozsahu také zákona o
              rodinných vzdělávacích právech a soukromí (Family Educational Rights and Privacy Act, "FERPA"). Výše
              uvedené znamená, že školy, komunity a učitelé musí rodiče nebo zákonné zástupce studentů informovat o tom,
              jaké osobní údaje GlobalEnglish vyžaduje a uchovává a musí předtím, než si studenti založí své účty a
              začnou využívat Služby, k tomuto od rodičů či zákonných zástupců získat nezbytný souhlas. K získání tohoto
              souhlasu musí školy, komunity nebo učitelé poskytnout rodičům/zákonným zástupcům kopii našich Pravidel
              ochrany osobních údajů, dále musí uchovávat archivované všechny souhlasy a na naše požádání nám je
              poskytnout. Více informací o dodržování zákona COPPA najdete na
              <a
                style={{ textDecoration: 'underline' }}
                href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
                rel="noopener noreferrer"
                target="_blank"
              >
                internetových stránkách Federální obchodní komise.
              </a>
              <br />
              <br />
              Pokud se nacházíte mimo území Spojených států, jakýmkoli zákonem danou povinnost získat jakýkoliv potřebný
              souhlas ze strany rodičů nebo zákonných zástupců jakéhokoliv studenta necháváme na vás. Podmínkou k
              využívání našich Služeb vámi nebo vašimi studenty je váš souhlas s tím, že jste zodpovědní za dodržování
              takových zákonů.
            </span>
            <br />
            <br />
            <b>5.3 Souhlas rodičů </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              <b>
                POUŽÍVÁNÍM NAŠICH SLUŽEB STVRZUJETE, ŽE JE VÁM VÍCE NEŽ 18 LET NEBO ŽE JSTE ZÍSKALI POTŘEBNÝ SOUHLAS
                RODIČŮ NEBO ZÁKONNÝCH ZÁSTUPCŮ KE VSTUPU DO TOHOTO SMLUVNÍHO VZTAHU S GLOBALENGLISH.
                <br />
                <br />
                Očekáváme, že využívání našich služeb dětmi bude probíhat pouze za dohledu, pod vedením a se souhlasem
                rodičů, zákonných zástupců a/nebo k tomu oprávněných zaměstnanců školy. GlobalEnglish spoléhá na to, že
                rodiče, zákonní zástupci a školy dbají toho, že nezletilí mohou Služby využívat pouze rozumí-li svým
                právům a povinnostem definovaným v těchto Smluvních podmínkách.
                <br />
                <br />
                GlobalEnglish si vyhrazuje právo kdykoli na základě vlastního uvážení odmítnout poskytování Služeb
                kterékoliv fyzické či právnické osobě nebo změnit kritéria potřebná k využívání Služeb.
              </b>
            </span>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6.OCHRANA OSOBNÍCH ÚDAJŮ </b>
            <br />
            <br />
            <b>6.1 Prohlášení o ochraně osobních údajů </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              S Pravidly ochrany osobních údajů se prosím seznamte
              <a
                href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
                rel="noopener noreferrer"
                target="_blank"
              >
                zde
              </a>
              .
              <br />
              <br />
              Osobní údaje zpracováváme v souladu s pravidly ochrany osobních údajů, se kterými jste vyjádřili svůj
              souhlas při prvním využití našuch Služby, nebo při jejich pravidelné aktualizaci.
            </span>
            <br />
            <br />
            <b>6.2 Údaje, které poskytujete. </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Za účelem využívání našich Služeb musíte mít platný účet. Pro registraci k využívání Služeb od vás
              GlobalEnglish potřebuje uživatelské ID, e-mailovou adresu a další údaje, které jsou považovány za osobní
              údaje (“Osobní údaje”). Některé údaje, které od vás dostáváme, jsou nezbytné k tomu, aby vám GlobalEnglish
              mohlo poskytovat Služby nebo vám o těchto službách na základě vašeho dotazu zaslat informace, jiné údaje
              jsou nepovinné. Naše Služby vám také umožňují zobrazovat případné informace o vás nebo vaší organizaci.
              <br />
              <br />
              Souhlasíte, že: (i) poskytnete přesné aktuální a kompletní údaje o vás a - v případě B2B transakcí - o
              vaší organizaci, které jsou vyžadovány v registračních formulářích našich Služeb; (ii) budete bezpečně
              uchovávat své heslo a ostatní informace spojené s bezpečností vašeho účtu mimo dosah ostatních osob; (iii)
              budete neprodleně aktualizovat své Osobní údaje a jakékoli další informace poskytnuté GlobalEnglish, aby
              vaše údaje byly stále správné, aktuální a kompletní; (iv) jste plně zodpovědní za jakékoli používání
              vašeho účtu a všechny úkony uskutečněné prostřednictvím vašeho účtu.
              <br />
              <br />
              GlobalEnglish nemá povinnost ověřovat správnost Osobních údajů, které jste nám poskytli za účelem
              registrace.
            </span>
            <br />
            <br />
            <b>6.3 Mezinárodní transfer Osobních údajů </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Při poskytování Služeb se GlobalEnglish spoléhá na své pracovní síly po celém světě a využívá obchodníky
              třetích stran, hostingové partnery pro uchovávání dat a jiné technické a vyučovací služby. Vaše osobní
              údaje jsou proto zpracovávány a předávány zaměstnancům a subdodavatelům GlobalEnglish v různých zemích, z
              nichž některé nemusí poskytovat stejný zákonem daný stupeň ochrany Osobních údajů jako země, jejímž jste
              občanem či rezidentem.
              <b>
                Vstupem na naše stránky a registrací a/nebo využíváním našich Služeb souhlasíte s předáváním vašich
                Osobních údajů do a jejich zpracováním v různých zemích, ve kterých se GlobalEnglish, jeho přidružené
                organizace a subdodavatelé fungují za účelem Služeb.
              </b>
            </span>
            <br />
            <br />
            <b>6.4 Oznámení a zprávy. </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Berete na vědomí a souhlasíte s tím, že Služby zahrnují i určitou komunikaci ze strany GlobalEnglish, jako
              třeba oznámení o službách, administrativní zprávy atd., a že tato komunikace je neoddělitelnou součástí
              Služeb a z jejího odběru se nelze odhlásit.
              <br />
              <br />
              Tímto souhlasíte, že vám může GlobalEnglish poskytovat oznámení a zprávy následujícími způsoby: (i) v
              prostředí Služeb; (ii) prostřednictvím kontaktních údajů, které jste poskytli GlobalEnglish (např. e-mail
              nebo telefonní číslo). Souhlasíte s tím, že vaše kontaktní údaje budou vždy aktuální.
            </span>
            <br />
            <br />
            <b>6.5 Ochrana osobních údajů ostatních uživatelů </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Pokud během používání Služeb získáte údaje o jiném uživateli, souhlasíte, že tyto údaje použijete jen za
              účelem, za kterým vám byly poskytnuty. Údaje o jiných uživatelých nesmíte zveřejňovat, prodávat ani sdílet
              s třetími stranami za účelem nesouvisejícím se Službami. Tyto údaje nesmíte použít ani k marketingovým
              účelům, ledaže byste k tomu získali svolení od dotyčného uživatele.
            </span>
            <br />
            <br />
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. UŽIVATELSKÁ LICENCE </b>
            <br />
            <br />
            <b>7.1 Licence k používání našich Služeb </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Je vám poskytnuta omezená osobní nevýhradní, nepřevoditelná, nesublicencovatelná a nenosná licence k
              přístupu a používání našich Služeb, a to pouze pro osobní a nekomerční účely. GlobalEnglish je oprávněno
              na základě vlastního uvážení dočasně i trvale zastavit poskytování Služeb nebo příležitostně změnit
              charakter Služeb, a to i bez předchozího upozornění.
              <br />
              <br />
              K využívání Služeb musíte mít přístup k World Wide Webu, buďto přímo nebo ze zařízení, která mají přístup
              k internetovému obsahu. Kromě toho musíte mít všechna zařízení potřebná k navázání spojení s World Wide
              Webem, včetně počítače a modemu nebo další přístupových zařízení. Souhlasíte s tím, že musíte vyhodnotit a
              přijmout všechna rizika spojená s používáním Služeb, včetně spoléhání se na přesnost, kompletnost a
              použitelnost jejich obsahu. Ani GlobalEnglish ani jeho poskytovatelé licencí negarantují správnost ani
              kompletnost jakéhokoli obsahu.
              <br />
              <br />
              Naše Služby nejsou navrženy ani licencovány k tomu, aby byly používány v potenciálně nebezpečných
              situacích (např. při řízení nebo v blízkosti vody).
              <br />
              <br />
              Právo k přístupu ke Službám neplatí v situacích, kdy je používání Služeb zakázáno nebo kde je jeho
              poskytování nebo prodej v konfliktu s jakýmkoli zákonem, pravidlem nebo nařízením.
              <br />
              <br />
              Používání Služeb za jiným účelem, než jaké jsou definovány Smluvními podmínkami, je porušením Smluvních
              podmínek a autorských a vlastnických práv GlobalEnglish a/nebo jeho poskytovatelů licencí nebo
              subdodavatelů. Má-li Global English důvodné podezření, že využíváte Služby v rozporu se Smluvními
              podmínkami, GlobalEnglish má právo váš účet pozastavit nebo trvale uzavřít a odmítnout vám jakékoli nebo
              všechny současné i budoucí Služby nadále poskytovat. Souhlasíte s tím, že GlobalEnglish nenese žádnou
              odpovědnost za důsledky jakýchkoli změn nebo přerušení v poskytování Služeb.
            </span>
            <br />
            <br />
            <b>7.2 Uživatelský obsah </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Služby vám i ostatním Uživatelům umožňují vytvářet data a informace (např. poskytovat reakce na naše
              testy) a nahrávat materiály do služeb poskytovaných na úrovni komunit (“Uživatelský obsah”). Na základě
              práv poskytnutých nám v těchto Smluvních podmínkách se plně vzdáváte vlastnictví svého Uživatelského
              obsahu.
              <br />
              <br />
              Nahráním Uživatelského obsahu do našich Služeb udělujete GlobalEnglish ve spojitosti s poskytováním Služeb
              nevratnou, trvalou, nevýlučnou, přenosnou a plně uhrazenou a globální licenci (s právem k sublicencování)
              podle potřeby k uchovávání, používání, rozmnožování, zveřejňování, úpravování, překladání, vyjímání
              úryvků, zpracování a šíření vašeho Uživatelského obsahu k :
              <br />
              <br />
              <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
                (a) Dodržování zákonných procesů, zejména odpovídání na zákonné nároky, soudní příkazy a další podobné
                požadavky ze strany státu; <br />
                (b) Naplnění této smlouvy; <br />
                (c) Odpovídání na vyjádření, že jakýkoliv obsah narušuje práva třetích stran;
                <br />
                (d) Ochraně práv, majetku a bezpečnosti osob spojených s GlobalEnglish, jeho zaměstnanců, Uživatelů a
                veřejnosti.
              </span>
              <br />
              <br />
              V rozsahu, v jakém se rozhodnete sdílet jakýkoli Uživatelský obsah s ostatními Uživateli Služeb,
              souhlasíte s tím, že těmto Uživatelům umožňujete zobrazovat si váš Uživatelský obsah a v příslušném
              rozsahu se s vámi na vytváření takového Uživatelského obsahu podílet.
              <br />
              <br />
              Berete na vědomí, že GlobalEnglish nemá žádnou povinnost kdykoliv váš Uživatelský obsah sledovat,
              kontrolovat, ověřovat jeho správnost, opravovat ani jej odstraňovat. GlobalEnglish si nicméně vyhrazuje
              právo Uživatelský obsah monitorovat a odstranit takový obsah, který na základě vlastního uvážení považuje
              za urážlivý nebo v rozporu se Smluvními podmínkami.
              <br />
              <br />
              GlobalEnglish nemá povinnost Uživatelský obsah zálohovat ani uchovávat. Jste plně zodpovědní za to, že
              budete na vlastní náklady vytvářet záložní kopie svého Uživatelského obsahu poskytnutého GlobalEnglish na
              našich stránkách a že těmito kopiemi Uživatelský obsah případně nahradíte.
              <br />
              <br />
              Uživatelský obsah nemusí nutně odrážet názory GlobalEnglish. Rozumíte, že za Uživatelský obsah je
              odpovědný pouze Uživatel, od kterého tento obsah pochází. Rozumíte, že používáním Služeb můžete být
              vystaveni urážlivému, nevhodnému nebo spornému Uživatelskému obsahu.
              <br />
              <br />
              Kromě licence udělené k Uživatelskému obsahu definované výše berete na vědomí a souhlasíte s tím, že
              jakékoli dotazy, komentáře, návrhy, nápady, zpětné vazby nebo jiné informace týkající se Služeb (“Návrhy”)
              poskytnuté vámi GlobalEnglish nejsou důvěrné a GlobalEnglish je může neomezeně užívat a šířit za jakýmkoli
              účelem, komerčním i jiným, bez vašich nároků na odměnu nebo kompenzaci. Dále platí, že informace vámi
              publikované, zveřejněné nebo sdílené v jakémkoli produktu nebo službě GlobalEnglish poskytované na úrovni
              komunit nespadají do definice důvěrných informací, tak jak je tento výraz obecně definován.
              <br />
              <br />
              Rozumíte tomu, že technické zpracovávání a převody na našich stránkách, včetně Uživatelského obsahu, mohou
              zahrnovat (a) převody mezi několika sítěmi; a (b) změny provedené za účelem přizpůsobení se technickým
              požadavkům připojených sítí nebo zařízení.
              <br />
              <br />
              <b>
                GlobalEnglish ve vztahu k Uživatelskému obsahu neposkytuje žádné záruky ani nečiní žádná závazná
                prohlášení. Uživatelský obsah nemusí nutně vyjadřovat názory a stanoviska GlobalEnglish. GlobalEnglish
                za žádných okolností není odpovědné ani neručí za Uživatelský obsah nebo jeho ztrátu ani za nároky,
                škody nebo ztráty vzniklé jeho používáním nebo ztrátou.
              </b>
            </span>
            <br />
            <br />
            <b>7.3 Vyžadované chování uživatele </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Jste plně zodpovědní za všechny aktivity vycházející z vašeho účtu. Souhlasíte, že:
              <br />
              <br />
              <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
                (a) neprodleně GlobalEnglish oznámíte jakékoli neautorizované použití vašeho účtu nebo jakékoli narušení
                bezpečnosti, a
                <br />
                (b) budete dbát na to, že na konci každého používání se odhlásíte z účtu.
              </span>
              <br />
              <br />
              GlobalEnglish nemůže a nebude ručit za důsledky jako jsou jakékoliv ztráty nebo škody vzniklé vaší
              neschopností dodržovat výše uvedené.
              <br />
              <br />
              Jste plně zodpovědní za to, že tyto Smluvní podmínky jsou v souladu se všemi zákony, pravidly a předpisy,
              které se na vás vztahují. Právo k přístupu ke Službám neplatí v situacích, kdy je používání Služeb
              zakázáno nebo kde je jeho poskytování nebo prodej v konfliktu s jakýmkoli zákonem, předpisem nebo
              nařízením.
            </span>
            <br />
            <br />
            <b>7.4 Zakázané chování uživatele </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Následující situace jsou příklady užívání, které je nezákonné nebo ze strany GlobalEnglish zakázané.
              GlobalEnglish si vyhrazuje právo vyšetřovat a podniknout příslušné právní kroky proti každému, kdo podle
              vlastního uvážení GlobalEnglish tato opatření porušuje, zejména odstranění urážlivého obsahu ze stránek
              nebo aplikací, pozastavení nebo trvalého uzavření účtu takových Uživatelů a jejich nahlášení orgánům
              činným v trestním řízení.
              <br />
              <br />
              Je zakázáno:
              <br />
              <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
                (a) upravovat, stahovat, měnit, používat ve vysílání, provádět zpětný vývoj (reverse engineering),
                duplikovat, publikovat, modifikovat, šířit, zobrazovat, přenášet nebo jinak kopírovat a/nebo
                distribuovat jakýkoli Obsah GlobalEnglish nebo jiný materiál Služeb, ledaže byste k tomu byli ze strany
                GlobalEnglish zvlášť oprávněni. <br />
                (b) rámovat nebo používat rámovací techniky k zachycení jakékoli části Služeb; <br />
                (c) shromažďovat, získávat, používat, vstupovat na nebo jinak kopírovat jakoukoli část Služeb za použití
                jakýchkoli botů, spiderů, crawlerů, spyware, enginů, zařízení, softwaru nebo jakéhokoli jiného
                automatického zařízení, nástroje nebo jakéhokoli manuálního procesu; <br />
                (d) používat Služby nebo jakékoli funkce dostupné na Službách za účelem přerušení, poškození, blokování,
                zakrývání nebo narušování Služeb;
                <br />
                (e) prodávat, licencovat, pronajímat nebo jinak používat Služby za komerčním účelem; nebo <br />
                (f) podílet se na jakékoli aktivitě, která narušuje přístup, používání nebo užívání si Služeb jiným
                Uživatelem. <br />
                (g) Publikovat, zasílat e-mailem či jiným způsobem jakýkoli Uživatelský obsah který (i) je nezákonný,
                škodlivý, výhružný, urážlivý, obtěžující, protiprávní, přehnaně násilnický, pověst poškozující,
                vulgární, obscénní, nedůstojný, pornografický, hanlivý, narušující soukromí (zejména adresy, e-maily,
                telefonní čísla a další kontaktní údaje), rasově nenávistný, etnicky i jinak nevhodný; (ii) nemáte právo
                sdílet na základě žádného zákona, smlouvy nebo zmocnění; (iii) představuje nebo vytváří ohrožení
                soukromí nebo bezpečnosti jakékoliv osoby; (iv) nerespektuje duševní vlastnictví nebo jakákoli jiná
                vlastnická práva jakékoli strany; (v) představuje nevyžádanou nebo neautorizovanou propagaci, propagační
                materiály, komerční aktivity a/nebo prodej, “nevyžádaný mail,” “spam,” “řetězový dopis,” “pyramidové
                schéma,” “soutěž,” “sázku,” nebo jakoukoli další formu žádosti; (vi) obsahuje softwarové viry nebo
                jakékoli jiné počítačové kódy, soubory nebo programy určené k narušení, zničení nebo omezení funkčnosti
                jakéhokoli počítačového softwaru nebo hardwaru či telekomunikačního zařízení; nebo (vii) je na základě
                vlastního zvážení stranou GlobalEnglish shledán rozponým nebo omezuje či zabraňuje jakékoli další osobě
                v užívání/užívání si Služeb, nebo který může vystavit GlobalEnglish či jeho uživatele jakémukoliv
                poškození nebo nechtěné odpovědnosti; <br />
                (h) vytvořit falešnou identitu, zkreslovat vlastní identitu, vydávat se za jinou osobu nebo subjekt,
                falešně předstírat nebo jinak zkreslovat vaše spojení s nějakou osobou nebo subjektem; <br />
                (i) požadovat osobní informace od kohokoli mladšího 18 let; <br />
                (j) jakkoli poškozovat nezletilé; <br />
                (k) podporovat nebo vyzývat k fyzickému ublížení nebo zranění osoby nebo skupiny osob, praktikovat
                “stalking” nebo jinak kohokoli obtěžovat; <br />
                (l) navádět nebo pobízet k ilegálním aktivitám, jako jsou instrukce k sestavování bomb, granátů a jiných
                zbraní nebo zápalných zařízení; <br />
                (m) pomocí automatických skriptů nebo jinak získávat a shromažďovat obsah stránek nebo e-mailové adresy,
                kontaktní údaje nebo jiné osobní údaje ostatních Uživatelů ze stránek, a to za jakýmkoli účelem, včetně
                a mimo jiné za účelem odesílání nevyžádaných e-mailů nebo jiné nevyžádané komunikace směrem k Uživatelům
                nebo reprodukování obsahu stránek; <br />
                (n) dělat reklamu nebo nabízet prodej nebo nákup jakéhokoli zboží nebo služeb za jakýmkoli obchodním
                účelem, ke kterému vám nebylo poskytnuto zvláštní oprávnění; <br />
                (o) zasahovat nebo narušovat webové stránky nebo servery nebo sítě připojené k webovým stránkám, neřídit
                se požadavky, procedurami, pravidly nebo regulacemi sítí připojených k webovým stránkám; <br />
                (p) porušovat jakékoli příslušný lokální, státní, národní nebo mezinárodní právo nebo jakékoli závazné
                právní předpisy; napomáhat nebo propagovat jakékoli ilegální aktivity nebo činnosti nebo poskytovat
                instrukce k ilegálním aktivitám;
                <br />
                (q) získávat, pokoušet se o přístup nebo jinak získávat materiály nebo informace jakýmkoli způsobem,
                který nebyl našimi stránkami poskytnut nebo učiněn záměrně dostupným; <br />
                (r) nerespektovat bezpečnostní prvky nebo se vyhýbat nebo obcházet přístupové kontroly; nebo <br />
                (s) používat Služby způsobem, který může způsobit střet zájmů, podkopávat účel Služeb, a to například
                způsobem jako je obchodovat hodnocení/recenze s ostatními Uživateli nebo psát nebo žádat zaujatá
                hodnocení/recenze.
              </span>
            </span>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. PRÁVA DUŠEVNÍHO VLASTNICTVÍ </b>
            <br />
            <br />
            <b>8.1 Práva duševního vlastnictví GlobalEnglish </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Celý obsah Služeb, včetně jejich Obsahu a podpůrného softwaru, je vlastnictvím GlobalEnglish, subjektů,
              kterým poskytnulo licenci nebo jeho smluvních partnerů a je chráněn americkým i a mezinárodním autorským
              právem a dalšími zákony o duševním vlastnictví.
              <br />
              <br />
              Rozmnožování, šíření, změny a publikování jakékoli součásti Služeb bez výslovného písemného souhlasu ze
              strany GlobalEnglish a/nebo subjektů, kterým GlobalEnglish poskytnulo licenci nebo jiným níže uvedeným
              způsobem, je přísně zakázáno.
              <br />
              <br />
              Oprávnění Uživatelé mají přístup ke Službám a jejich obsahu a mohou je využívat, mohou stahovat a tisknout
              přiměřený počet kopií částí Obsahu, ke kterému Uživatelé získali standardní cestou přístup, a to pouze pro
              jejich osobní potřebu za účelem výuky jazyka, a to pod podmínkou, že Uživatel bude mít na všech kopiích
              Obsahu vyznačena autorská nebo vlastnická práva GlobalEnglish.
              <br />
              <br />
              Není-li uvedeno jinak, obchodní značky zobrazující se ve Službách jsou obchodními značkami GlobalEnglish,
              subjektů, kterým poskytnulo licenci nebopartnerů. Souhlasíte s tím, že tyto značky nebudete zobrazovat,
              znevažovat ani poškozvat, nebo používat matoucí podobné značky nebo je používat způsobem, který by klamně
              vyvolával dojem vlastnictví takových značek, nebo by vytvářel dojem, že GlobalEnglish podporuje nějaký
              produkt nebo službu. Není vám dovoleno rozmnožovat nebo užívat obchodní značky GlobalEnglish, stejně tak
              ani jeho obchodní jméno a/nebo obchodní oděv bez předchozího písemného svolení ze strany GlobalEnglish.
              Dobrá pověst získaná užíváním obchodních značek GlobalEnglish slouží výhradně našemu užitku.
              <br />
              <br />
              Jakékoli používání našich stránek, aplikací a Obsahu, které se liší od specificky autorizovaného
              používání, je zakázáno a povede k automatickému zrušení vašich práv souvisejících s používáním Služeb a
              jejich Obsahu. Všechna práva GlobalEnglish nebo subjektů, kterým poskytnulo licenci, která nejsou výslovně
              uvedena v těchto Smluvních podmínkách, jsou vyhrazena GlobalEnglish a subjektům, kterým byla licence
              poskytnula.
            </span>
            <br />
            <br />
            <b>8.2 Obchodní značky třetích stran</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Jména a loga produktů a služeb používaných a zobrazovaných na našich stránkách, která nejsou výše
              popsanými obchodními značkami GlobalEnglish, mohou být obchodními značkami nebo značkami služeb jejich
              příslušných vlastníků, kteří mohou nebo nemusí být s GlobalEnglish propojeni či s GlobalEnglish jinak
              spolupracovat. Nic v těchto Smluvních podmínkách nebo na našich stránkách nesmí být chápán, a to ani
              implicitně, jako estoppel, ani jinak jako udělení jakékoli licenci nebo práva k používání jakékoli
              obchodní značky zobrazené na našich internetových stránkách, aniž je k tomu v každém jednotlivém případě
              uděleno příslušné písemné povolení. Dobrá pověst získaná užíváním obchodních značek GlobalEnglish slouží
              výhradně našemu užitku.
            </span>
            <br />
            <br />
            <b>8.3 Stížnosti na porušení autorských práv podle zákona Digital Millennium Copyright Act (DMCA)</b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              V případě jakýchkoliv pochybností o porušování autorských práv v materiálech, zveřejněnými ostatními v
              našich Službách, nás prosím informujte.
              <br />
              <br />
              GlobalEnglish zpracuje a prošetří všechna oznámení na domnělé porušení zákona a podnikne odpovídající
              kroky v souladu se zákonem Digital Millennium Copyright Act (“DMCA”) a ostatními platnými zákony o
              duševním vlastnictví, a to ve vztahu k jakémukoli domnělému nebo skutečnému porušení zákona.
              <br />
              <br />
              Oznámení domnělých porušení autorských práv zasílejte e-mailem našemu zástupci dbajícího na dodržování
              autorských práv GlobalEnglish na copyright@globalenglish.com (Předmět e-mailu: “DMCA Takedown Request”).
              <br />
              <br />
              Můžete nás kontaktovat také poštou na:
              <br />
              <b>k rukám: Copyright Complaints/Legal Department</b>
              <br />
              1875 South Grant Street, Suite 700 <br />
              San Mateo, CA 94402 USA
              <br />
              <br />
              <br />
              Aby bylo oznámení platné, musí být provedeno písemně a obsahovat následující informace: (i) elektronický
              nebo fyzický podpis osoby oprávněné jednat jménem vlastníka autorských práv nebo jiných zájmů
              vyplývajících z duševního vlastnictví; (ii) popis díla nebo jiného duševního vlastnictví, o kterém se
              domnívate, že došlo k porušení jejich autorských práv; (iii) popis umístnění materiálu, na které se vaše
              domnělé porušení autorských práv vztahuje, v rámci Služeb dostatečně detailní k tomu, abychom jej dokázali
              na našich internetových stránkách lokalizovat; (iv) dostatek informací k tomu, aby vás GlobalEnglish mohlo
              kontaktovat (adresa, telefonní číslo a e-mailová adresa); (v) vaše prohlášení, že máte dobrý důvod věřit,
              že sporná věc nemá oprávnění vlastníka autorských práv nebo jiných práv duševního vlastnictví, jeho
              zástupce nebo nevyplývá ze zákona; (vi) Vaše prohlášení, postižitelné trestem za křivé svědectví, o tom,
              že informace ve vašem oznámení jsou správné a že jste vlastníkem autorských práv nebo duševního
              vlastnictví nebo že jste oprávněni jednat jménem vlastníka autorských práv nebo dušeního vlastnictví.
              <br />
              <br />V souladu s DMCA a dalšími příslušnými zákony přijalo GlobalEnglish opatření o uzavření účtu
              uživatelů, kteří se výše definovaného porušování pravidel a zákonů opakovaně dopustili, a tov příslušných
              situacích a na základě vlastního uvážení Global English. Na základě vlastního zvážení může GlobalEnglish
              také omezit přístup na naše internetové stránky a/nebo ukončit členství kteréhokoli uživatele, který
              poruší jakákoli práva duševního vlastnictví jiných osob, ať už k takovému porušení došlo opakovaně nebo
              nikoli.
            </span>
            <br />
            <br />
            <b>8.4 Odstranění podezřelého Uživatelského obsahu </b>
            <br />
            <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
              Pokud GlobalEnglish na základě podezření z porušování vlastnických práv třetích stran odstranilo
              jakoukoliv část vašeho Uživatelského obsahu a domnívátel-li se, že obsah který byl odstraněn (nebo k němu
              byl zablokován přístup) neporušuje práva nebo pokud jste ke zveřejnění a používání takovéhoto obsahu ve
              vašem Uživatelském obsahu vlastníkem autorských práv nebo jeho zástupcem oprávněni, nebo jste tak činili v
              souladu s právními předpisy, můžete našemu zástupci pro oblast autorských práv zaslat “proti-oznámení”
              obsahující následující informace: (i) váš elektronický nebo fyzický podpis (ii) popis obsahu, který byl
              odstraněn nebo ke kterému byl zablokován přístup a popis toho, kde byl umístněn před jeho odstraněním nebo
              zablokováním přístupu k němu; (iii) prohlášení, že podle svého nejlepího vědomí a svědomí věříte, že obsah
              byl odstraněn nebo zablokován v důsledku chyby nebo chybné identifikace obsahu; a (iv) vaše jméno, adresu,
              telefonní číslo a e-mailovou adresu a prohlášení o tom, že souhlasíte s příslušností federálního soudu
              sídlícího v Northern District v Kaliforni a dále prohlášení, že přijímáte výsledky řízení iniciovaného
              osobou, která domnělé porušení práv namítnula.
              <br />
              <br />
              Pokud bude “proti-oznámení” zástupcem pro oblast autorských práv přijato, zašle GlobalEnglish kopii
              “proti-oznámení”straně, která provedla oznámení, a informuje ji, že odstraněný nebo zablokovaný obsah může
              být po uplynutí 10 pracovních dní nahrazen nebo odblokován. Pokud vlastník autorských práv nezahájí řízení
              proti poskytovateli obsahu, členovi nebo uživateli, odstraněný obsah může být nahrazen nebo zpřístupněn
              během 10 až 14 pracovních dní nebo více, počítáno od doručení “proti-oznámení”, a to na základě našeho
              osobního uvážení.
            </span>
            <br />
            <br />
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>9. MEZINÁRODNÍ POUŽÍVÁNÍ A EXPORTNÍ KONTROLY</b>
            <br />
            <br />
            S vědomím o globální povaze internetu souhlasíte s dodržováním všech místních pravidel vztahujících se na
            online aktivity a akceptovatelnost Uživatelského obsahu. Souhlasíte s dodržováním všech příslušných zákonů
            spojených s přenosem technických dat exportovaných ze Spojených států nebo ze země, které jste rezidentem.
            <br />
            <br />
            Bereta na vědomí, že Služby nebo jejich části mohou podléhat americkým zákonům o exportní kontrole. Nebudete
            exportovat, re-exportovat nebo přenášet žádnou část Služeb ani souvisejících technických informací nebo
            materiálů tak, že byste tím přímo nebo nepřímo porušili příslušné exportní zákony či právní předpisy.
            Potvrzujete a odpovídáte za to, že nejste občanem země, na kterou vláda Spojených států uvalila embargo nebo
            která byla vládou Spojených států označena za zemi “podporující terorismus” a že nejste uvedeni na zádném
            americkou vládou vedeném seznamu zakázaných stran.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. SOUDNÍ PŘÍKAZ </b>
            <br />
            <br />
            Berete na vědomí, že jakékoli úžívání Služeb, které je v rozporuou se Smluvními podmínkami, může
            GlobalEnglish nenapravitelně poškodit, stejně tak může poškodit jeho partnery, subjekty, kterým poskytnulo
            licence, subdodavatele, prodejce a Uživatele. Za takových okolností mají GlobalEnglish, jeho přidružené
            společnosti, subjekty, kterým byla poskytnuta licence a subdodavatelé nárok na náhradu újmy podle práva
            ekvity, a to bez nutnosti skládání jistoty či jiného zaštění, zejména předběžným a trvalým soudním příkazem.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11. ODSTÁVKY </b>
            <br />
            <br />
            Služby mohou být čas od času dočasně nedostupné z důvodu údržby nebo z jiných důvodů. GlobalEnglish v
            souvislosti s odstávkami nenese žádnou odpovědnost za žádné přerušení, zpoždění provozu nebo přenosů,
            odcizení, zničení, neoprávněný přístup nebo pozměňování uživatelské komunikace nebo jakéhokoli obsahu
            dostupného na našich stránkách.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12. PROHLÁŠENÍ O ZÁRUKÁCH </b>
            <br />
            <br />
            Služby mohou být čas od času dočasně nedostupné z důvodu údržby nebo z jiných důvodů. GlobalEnglish v
            souvislosti s odstávkami nenese žádnou odpovědnost za žádné přerušení, zpoždění provozu nebo přenosů,
            odcizení, zničení, neoprávněný přístup nebo pozměňování uživatelské komunikace nebo jakéhokoli obsahu
            dostupnéh prostřednictvím Služeb. GlobalEnglish nenese za žádných okolností jakoukoli odpovědnost za újmu
            nebo smrt způsobenou vyžíváním Služeb, jakéhokoli Uživatelského obsahu nebo Obsahu třetích stran nebo
            jakýchkoli Uživateli poskytovaných produktů či služeb.
            <br />
            <br />
            ROZUMÍTE A SOUHLASÍTE S TÍM, ŽE:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) SLUŽBY, VČETNĚ VEŠKERÉHO JEJICH OBSAHU, JSOU POSKYTOVÁNY “TAK JAK STOJÍ A LEŽÍ”, TAK, JAK JSOU
              DOSTUPNÉ.
              <br />
              <br />
              (b) GLOBALENGLISH ODMÍTÁ VŠECHNY ZÁRUKY SPOJENÉ S JEJICH SLUŽBAMI, A TO VÝSLOVNĚ ČI IMPLICITNĚ, VČETNĚ
              ZÁRUKY VLASTNICTVÍ, OBCHODOVATELNOSTI A VHODNOSTI PRO VYUŽITÍ KE KONKRÉTNÍM ÚČELŮM AND VYUŽITÍ V SOULADU
              SE ZÁKONY.
              <br />
              <br />
              (c) GLOBALENGLISH NEGARANTUJE A NESLIBUJE ŽÁDNÉ SPECIFICKÉ VÝSLEDKY JAKO VÝSLEDEK POUŽÍVÁNÍ SLUŽEB ANI ŽE
              SLUŽBY SPLNÍ VAŠE POŽADAVKY NEBO OČEKÁVÁNÍ.
              <br />
              <br />
              (d) GLOBALENGLISH NETVRDÍ ANI NEZARUŽUJE SPRÁVNOST, CELISTVOST, SPOLEHLIVOST, AKTUÁLNOST NEBO BEZCHYBNOST
              SOFTWARU, OBSAHU NEBO MATERIÁLŮ OBSAŽENÝCH VE SLUŽBÁCH NEBO NA NAŠICH STRÁNKÁCH A ANI TO, ŽE NAŠE STRÁNKY
              NEBO SLUŽBY NEBO JEJICH SERVERY NEMOHOU OBSAHOVAT VIRY NEBO JINÉ ŠKODLIVÉ KOMPONENTY. PROTO (A NEJEN
              PROTO) VYJADŘUJETE TÍMTO SVÉ POROZUMĚNÍ A SOUHLAS S TÍM, ŽE SI STAHUJETE NEBO JINAK ZÍSKÁVÁTE OBSAH,
              MATERIÁLY, DATA NEBO SOFTWARE Z NEBO PROSTŘEDNICTVÍM SLUŽEB NEBO OD POSKYTOVATELE NA ZÁKLADĚ VLASTNÍHO
              UVÁŽENÍ A NA VLASTNÍ RIZIKO A DÁLE ŽE JSTE PLNĚ ZODPOVĚDNÍ ZA VAŠE POUŽÍVÁNÍ A JAKÉKOLI POŠKOZENÍ VAŠEHO
              POČÍTAČOVÉHO SYSTÉMU, ZTRÁTU DAT NEBO JAKOUKOLI JINOU ŠKODU, KTERÁ SE MŮŽE VYSKYTNOUT.
              <br />
              <br />
              (e) GLOBALENGLISH NEGARANTUJE, ŽE DOJDE K OPRAVENÍ JAKÝCHKOLI CHYB NEBO DEFEKTŮ VE SLUŽBÁCH.
              <br />
              <br />
              (f) GLOBALENGLISH NENÍ ZODPOVĚDNÉ ZA JEDNÁNÍ UŽIVATELŮ, ZA OBSAH TŘETÍCH STRAN ANI ZA UŽIVATELSKÝ OBSAH.
              <br />
              <br />
              (g) Služby mohou být čas od času dočasně nedostupné z důvodu údržby nebo z jiných důvodů. GlobalEnglish v
              souvislosti s odstávkami nenese žádnou odpovědnost za žádné přerušení, zpoždění provozu nebo přenosů,
              odcizení, zničení, neoprávněný přístup nebo pozměňování uživatelské komunikace nebo jakéhokoli obsahu
              dostupnéh prostřednictvím Služeb. GlobalEnglish nenese za žádných okolností jakoukoli odpovědnost za újmu
              nebo smrt způsobenou vyžíváním Služeb, jakéhokoli Uživatelského obsahu nebo Obsahu třetích stran nebo
              jakýchkoli Uživateli poskytovaných produktů či služeb.
            </span>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. OMEZENÍ ODPOVĚDNOSTI</b>
            <br />
            <br />
            V ŽÁDNÉM PŘÍPADĚ GLOBALENGLISH ANI JEHO STATUTÁRNÍ ORGÁNY, ZAMĚSTNANCI, ZÁSTUPCI, SUBJETY, KTERÝM BYLA
            POSKYTNULA LICENCE NEBO PODDODAVATELÉ NEODPOVÍDAJÍ VÁM NEBO JAKÉKOLI TŘETÍ OSOBĚ ZA JAKÉKOLI NEPŘÍMÉ,
            NÁSLEDNÉ, NÁHODNÉ, ZVLÁŠTNÍ NEBO SANKČNÍ NÁROKY NA NÁHRADU ŠKODY, VČETNĚ JAKÉHOKOLI UŠLÉHO ZISKU A ZTRÁTY
            DAT VYPLÝVAJÍCÍ Z: (I) VAŠEHO UŽÍVÁNÍ NEBO NEMOŽNOSTI UŽÍVÁNÍ SLUŽEB, NEBO JAKÉHOKOLI OBSAHU ČI JINÝCH
            MATERIÁLŮ PŘÍSTUPNÝCH NA NEBO STAŽENÝCH Z WEBU, SLUŽEB NEBO POSKYTOVATELŮ, (II) NÁKLADŮ NA OBSTARÁNÍ
            NÁHRADNÍHO ZBOŽÍ A SLUŽEB SOUVISEJÍCÍCH S JAKÝMKOLI ZAKOUPENÝM ČI ZÍSKANÝM ZBOŽÍM, DATY, INFORMACEMI NEBO
            SLUŽBAMI, NEBO Z PŘIJATÝCH ZPRÁV NEBO TRANSAKCÍ USKUTEČNĚNÝCH PROSTŘEDNICTVÍM WEBU NEBO NA WEBU; (III)
            NEOPRÁVNĚNÉHO PŘÍSTUPU NEBO ZMĚNY VAŠICH PŘENOSŮ NEBO DAT JAKOUKOLI OSOBOU; (IV) PROHLÁŠENÍ NEBO JEDNÁNÍ
            JAKÉKOLI TŘETÍ STRANY NA WEBU; NEBO (V) JAKÉKOLI JINÉ ZÁLEŽITOSTI TÝKAJÍCÍ SE SLUŽEB. V ŽADNÉM PŘÍPADĚ
            CELKOVÁ ODPOVĚDNOST GLOBALENGLISH VŮČI VÁM ZA VEŠKERÉ ŠKODY, ZTRÁTY NEBO NÁROKY NEPŘEKROČÍ ČÁSTKU, KTEROU
            JSTE UHRADILI GLOBALENGLISH ZA POSLEDNÍCH DVANÁCT (12) MĚSÍCŮ, NEBO, JE-LI TATO ČÁSTKA VYŠŠÍ, STO AMERICKÝCH
            DOLARŮ ($100), A TO I V PŘÍPADĚ, KDY BY GLOBALENGLISH BYLO VEDOMO NEBO BYLO UPOZORNĚNO NA MOŽNOST TAKOVÝCH
            ŠKOD. EXISTENCE VÍCE NEŽ JEDNOHO NÁROKU PODLE TÉTO SMLOUVY TENTO LIMIT NEZVYŠUJE.
            <br />
            <br />
            NĚKTERÉ PRÁVNÍ ŘÁDY NEUMOŽŇUJÍ VYLOUČENÍ URČITÝCH ZÁRUK NEBO OMEZENÍ NEBO VYLOUČENÍ ODPOVĚDNOSTI ZA NÁHODNÉ
            NEBO NÁSLEDNÉ ŠKODY. V SOULADU S TÍM SE NA VÁS NEMUSEJÍ NĚKTERÁ OMEZENÍ STANOVENÁ VÝŠE VZTAHOVAT.
            <br />
            <br />
            POKUD JSTE NESPOKOJENI S JAKOUKOLI ČÁSTÍ WEBU NEBO TĚMITO PODMÍNKAMI, VAŠÍ JEDINOU MOŽNOSTÍ JE UKONČENÍ
            POUŽÍVÁNÍ WEBU.
            <br />
            <br />
            ZA ŽÁDNÝCH OKOLNOSTÍ GLOBALENGLISH NEODPOVÍDÁ ZA ÚJMU NEBO SMRT OSOB VYPLÝVAJÍCÍ Z UŽÍVÁNÍ SLUŽEB NEBO
            Z OBSAHU TŘETÍCH OSOB NEBO UŽIVATELSKÉHO OBSAHU.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14. PROMLČENÍ </b>
            <br />
            <br />
            Souhlasíte s tím, že bez ohledu na jakýkoli jinak stanovující zákon nebo právní předpis musí být jakýkoli
            nárok nebo žalobní důvod proti GlobalEnglish vyplývající z nebo týkající se vašeho využívání Služeb uplatněn
            ve lhůtě jednoho (1) roku od vzniku takového nároku nebo žalobního důvodu, přičemž v opačném případě bude
            jednou prvždy promlčen.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 15. ODŠKODNĚNÍ </b>
            <br />
            <br />
            Zavazujete se GlobalEnglish a její partnery, představitele, zaměstnance, statutární rgány a zástupce
            odškodnit a zbavit odpovědnosti za jakékoli nároky, žaloby, náklady, závazky, ztráty, škody, výdaje, včetně
            příměřených poplatků za právní služby, uplatněné vůči GlobalEnglish třetí stranou v důsledku nebo
            v souvislosti s vaším užíváním Služeb, vaším Uživatelským obsahem, vaším připojením k Webu a Službám, vaším
            porušením těchto Podmínek nebo vaším porušením jakýchkoli práv jiného Uživatele nebo jakéhokoli právního
            předpisu nebo jiným způsobem vyplývající z vašeho užívání Služeb. Vaše povinnost k odškodnění zahrnuje
            zejména nároky vůči GlobalEnglish z titulu, že jakýkoli Uživatelský obsah zasahuje do práv duševního
            vlastnictví třetí strany.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 16. UKONČENÍ </b>
            <br />
            <br />
            Dojde-li z jakéhokoli důvodu k ukončení vašeho předplatného, GlobalEnglish zablokuje váš přístup ke Službám.
            Nebudete mít přístup k žádnému Obsahu, včetně vašeho Uživatelského obsahu, přestože kopie těchto dat
            zůstanou v našem systému uloženy pro účely zálohování po dobu tří (3) let.
            <br />
            <br />
            Pokud požádáte GlobalEnglish o smazání vašich Osobních údajů a Uživatelského obsahu, vynaložíme k dosažení
            požadovaného cíle veškeré přiměřené úsilí a provedení smazání vám následně potvrdíme.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>17. VZDÁNÍ SE PRÁVA A ODDĚLITELNOST</b>
            <br />
            <br />
            Neuplatnění či nedomáhání se jakéhokoli práva nebo ustanovení těchto Podmínek ze strany GlobalEnglish
            v žádném jednotlivém případě nezakládá ani neznamená vzdání se takového práva nebo ustanovení. Jestliže bude
            jakékoli ustanovení těchto Podmínek shledáno nezákonným, neplatným nebo z jakéhokoli důvodu nevymahatelným,
            není tím žádným způsobem dotčena platnost ani vymahatelnost žádného ze zbývajících ustanovení.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 18. ÚPLNÁ DOHODA </b>
            <br />
            <br />
            Tato Smlouva představuje úplnou dohodu mezi vámi a GlobalEnglish ohledně užívání Služeb a nahrazuje jakékoli
            předchozí ujednání a dohody mezi vámi a GlobalEnglish vztahující se k vašemu využívání Služeb.
            <br />
            <br />
            Bez ohledu na výše uvedené platí, že pokud má vaše Společnost uzavřenu s GlobalEnglish Dohodu o koupi
            jakýchkoli Služeb, účelem těchto Podmínek není měnit obchodní podmínky takové dohody existující mezi vaší
            Společností a GlobalEnglish, jejími dceřinými společnostmi nebo partnery, přičemž v případě jakéhokoliv
            rozporu mezi zněním těchto podmínek a Dohody mají podmínky takovéto Dohody před zněním těchto smluvních
            podmínek přednost.
            <br />
            <br />
            Tato Smlouva byla zhotovena v anglickém jazyce, když anglická verze je ve všech ohledech rozhodná a jakákoli
            jiná jazyková verze této Smlouvy má pouze informativní charakter. Tištěná verze této smlouvy a jakékoli
            oznámení podané v elektronické formě budou přípustné v soudním nebo správním řízení vyplývajícím z nebo
            vztahujícím se k této smlouvě ve stejném rozsahu a za stejných podmínek jako ostatní obchodní dokumenty a
            záznamy původně vytvořené a uchovávané v tištěné formě. Nadpisy oddílů v těchto Podmínkách jsou pouze
            informativní a nemají žádný právní či smluvní účinek..
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>19. VOLBA PRÁVA, PŘÍSLUŠNOST A ROZHODČÍ ŘÍZENÍ</b>
            <br />
            <br />
            Tyto podmínky se bez ohledu na ustanovení kolizních norem řídí právem státu Kalifornie.
            <br />
            <br />
            Podle volby a výhradního uvážení GlobalEnglish mohou být veškeré spory, nároky nebo nesrovnalosti
            vyplývající z nebo související s těmito Podmínkami nebo Webem, k jejichž vyřešení nedojde společnou dohodou,
            závazně rozhodovány v rozhodčím řízení vedeném JAMS (Soudní arbitrážní a mediační služba), nebo jejím
            nástupcem. Nedohodnou-li se strany jinak, bude se rozhodčí řízení konat v San Francisku, Kalifornie, před
            jediným rozhodcem, na němž se strany dohodnou, nebo nedohodnou-li se strany na osobě rozhodce, před jediným
            rozhodcem jmenovaným JAMS, a to v souladu s pravidly a řádem vydaným JAMS, není-li v těchto Podmínkách
            sjednáno jinak. Rozhodčí řízení musí být zahájeno ve lhůtě čtyřiceti pěti (45) dnů ode dne podání rozhodčí
            žaloby kteroukoli ze stran. Rohodnutí a rozhodčí nález musí být vydán a doručen stranám ve lhůtě šedesáti
            (60) dnů od skončení rozhodčího řízení a do šesti (6) měsíců od výběru rozhodce. Rozhodce nemá pravomoc
            přiznat náhradu škody nad rámec omezení o skutečných kompenzačních, přímých škodách stanovených v
            Podmínkách, a nenímoprávněn násobit skutečnou škodu nebo ukládat sankční náhradu škody nebo jakoukoli jinou
            náhradu škody, která je vyloučena v těchto Podmínkách, přičemž každá ze stran se v této souvislosti tímto
            neodvolatelně vzdává nároku na náhradu takovéto škody. Rozhodce může podle svého uvážení rozhodnout o
            náhradě nákladů a výdajů (včetně přiměřených nákladů na právní služby a výdajů vítězné strany) kterékoli
            straně řízení. Kterákoli strana, jež se odmítne podřídit rozhodnutí rozhodčího senátu, bude odpovídat za
            náklady a výdaje, včetně odměny právních zástupců, vynaložené druhou stranou v souvislosti s výkonem
            rozhodčího nálezu. Bez ohledu na výše uvedené, v případě dočasného nebo předběžného soudního příkazu platí,
            že se kterákoli ze stran může za účelem odvrácení bezprostřední a nenapravitelné újmy obrátit přímo na soud
            bez nutnosti předchozího zahájení rozhodčího řízení. Ustanovení této části o rozhodčím řízení jsou
            vynutitelná před jakýmkoli příslušným soudem.
            <br />
            <br />
            Pokud jde o jakékoli spory nebo nároky , na které se rozhodčí řízení nevztahuje, tak jak je stanoveno výše,
            vy a GlobalEnglish se zavazujete podrobit se osobní a výlučné pravomoci státních a federálních soudů v San
            Francisco County, Kalifornie.
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. OZNÁMENÍ A KONTAKTY </b>
            <br />
            <br />O ukončení vašeho účtu Služeb GlobalEnglish můžete požádat oznámením o ukončení prostřednictvím
            formuláře “Kontaktujte nás” na
            <a href="https://support.globalenglish.com/" rel="noopener noreferrer" target="_blank">
              https://support.globalenglish.com/
            </a>
            . Váš přístup a využívání Služeb, včetně jakéhokoli jejich obsahu, může GlobalEnglish znemožnit kdykoli po
            uzavření vašeho účtu.
            <br />
            <br />
            Výzvy vůči vám mohou být činěny prostřednictvím e-mailu nebo pošty. O změnách těchto Podmínek nebo o jiných
            záležitostech můžete být vyrozuměni rovněž prostřednictvím webových stránek, a to zobrazením výzvy nebo
            odkazu na výzvy ve Službě.
            <br />
            <br />
            Oznámení týkající se našich zásad ochrany osobních údajů mohou být zasílána na
            <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
              privacy@globalenglish.com.
            </a>
            <br />
            <br />
            Všechna ostatní právní oznámení nebo jiná korespondence vůči GlobalEnglish musí být zasílána na následující
            e-mailovou adresu:
            <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
              legal@globalenglish.com
            </a>{' '}
            nebo na následující fyzickou adresu:
            <br />
            <br />
            1875 S Grant Street, Suite 700 <br />
            San Mateo, CA 94402 <br />k rukám
            <b>právního poradce</b>
            <br />
          </p>
          <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
            <b>SPECIFICKÉ PODMÍNKY PRO B2B TRANSAKCE </b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            Pokud jste získali váš účet u GlobalEnglish prostřednictvím vašeho zaměstnavatele, podléhá vaše užívání
            našich Služeb take naší dohodě s vaší společností (“Společnost”). Podmínky používání nemění podmínky smlouvy
            s vaší Společností. Služby nesmíte využívat (nebo v jejich využívání pokračovat), pokud vás vaše Společnost
            nezmocní jako koncového uživatele v rámci její licence s GlobalEnglish. Požádáte-li nás o uzavření vašeho
            účtu, předtím, než GlobalEnglish ukončí váš učet, může být požádána o udělení souhlasu vaše Společnost.
            <br />
            <br />
            Souhlasíte s využíváním služeb výhradně v souladu s povolením vaší Společností. Prostřednictvím Služeb
            můžete komunikovat s ostatními osobami ve vaší Společnosti nebo nahrávat dokumenty související s vaší
            Společností, nicméně pouze za předpokladu existence oprávnění ze strany vaší Společnosti.
            <br />
            <br />
            Berete na vědomí a souhlasíte s tím, aby k veškerým datům vygenerovaným v souvislosti s vaším využíváním
            Služeb („Uživatelská data“) měla přístup vaše Společností, a že GlobalEnglish vůči vám ve vztahu k s vaší
            Společností sdíleným údajům v souvislosti s vaším využíváním Služeb nenese žádnou odpovědnost.
            <br />
            <br />
            Vzhledem k tomu, že vaše předplatné pro přístup ke Službám trvá pouze po dobu trvání licence Společnosti,
            ukončíli-li vaše Společnost svou licenci s GlobalEnglish, ztratíte přístup ke Službám a Uživatelskému obsahu
            i vy. GlobalEnglish je oprávněna ukončit nebo pozastavit účet vaší Společnosti, váš účet nebo možnost
            využívat Služby zcela nebo částečně bez předchozího upozornění v případě, že (i) vaše Společnost neuhradí
            GlobalEnglish jakékoli poplatky splatné podle této smlouvy GlobalEnglish k datu splatnosti, nebo (ii) vy
            nebo vaše Společnost poruší Podmínky nebo jakákoli jiná pravidla, kterými se řídí využívání Služeb.
            <br />
            <br />
            GlobalEnglish může vzniknout povinnost vrátit veškerá data vztahující se k vašemu využívání Služeb, včetně
            vašich Osobních údajů a Uživatelského obsahu vaší Společnosti. Pokud vaše Společnost nepožádá o vrácení nebo
            smazání těchto dat, GlobalEnglish uchová data po dobu tří (3) let po uplynutí nebo ukončení smlouvy s vaším
            zaměstnavatelem. V takovém případě můžete zakoupit předplatné služby přímo od GlobalEnglish a pokračovat ve
            vašem přístupu k programu bez ztráty informací o vašem pokroku. Můžete povolit převod vašeho pokroku a
            profilu do nového předplatného u vašeho nového zaměstnavatele. Můžete také požádat o veškerá data vztahující
            se k vašemu využívání Služeb ve formátu kompatibilním s budoucími vstupy.
          </p>
          <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
            <b>SPECIFICKÉ PODMÍNKY PRO B2C TRANSAKCE </b>
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            Smlouvu o předplatném s GlobalEnglish můžete uzavřít také jako fyzická osoba, a to po dobu buď tří (3) nebo
            šesti (6) měsíců, jednoho roku či více let (každý plán jednotlivě dále jen „Plán předplatného“).
            <br />
            <br />
            Příslušný Plán předplatného vám bude fakturován měsíčně nebo ročně ve stejný nebo přibližně stejný den
            měsíce/roku (podle relevance), dokud váš Plán předplatného nezrušíte. Pro vyloučení pochybností vezměte
            prosím na vědomí, že nebudete moci zrušit, snížit počet míst nebo přejít na nižší úroveň vámi zvolených
            Služeb až do konce platnosti vašeho předplatného. Za platby Plánu předplatného nenáleží žádná finanční
            náhrada.
            <br />
            <br />
            Příslušné poplatky za Služby („Poplatky”) jsou zobrazené na Webu a/nebo v aktuálním ceníku GlobalEnglish.
            Cena stanovená za Služby nezahrnuje daně a poplatky, není-li stanoveno jinak. Odpovídáte za veškeré daně a
            za veškeré další poplatky související s používáním služeb (například poplatky za přenos dat a směnné kurzy).
            Poplatky uhradíte v měně stanovené GlobalEnglish pro váš účet. GlobalEnglish si vyhrazuje právo stanovenou
            měnu kdykoli změnit.
            <br />
            <br />
            <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}> Platba</b>
            <br />
            <br />
            Platba je splatná při zakoupení licence předplatného. Pro zpracování vašich plateb spolupracujeme s PayPal a
            Stripe. Nepřijímáme ani nezpracováváme žádné informace o kreditních kartách nebo jiných platebních metodách,
            které sdělíte těmto poskytovatelům služeb.
            <br />
            <br />
            Veškeré Služby jsou předplacené na zvolenou dobu (měsíčně, ročně nebo jinak) a platby jsou nevratné. To se
            vztahuje též na obnovené účty.
            <br />
            <br />
            -----
          </p>
          <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>Upozornění pro uživatele v Kalifornii </b>
            <br />
            <br />
            Pokud jste rezidentem v Kalifornii, jste podle paragrafu 1789.3 občanského zákoníku Kalifornie oprávněn
            požadovat informace týkající se zpřístupnění vašich Osobních údajů třetím stranám pro účely přímého
            marketingu třetích stran. Kalifornský zákon o ochraně soukromí spotřebitele, který vstoupí v platnost v roce
            2021 (paragraf 1798.100 až 1709.198 občanského zákoníku) vám také přiznává jistá práva:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) právo vědět, jaké Osobní údaje byly o vás shromážděny, a obdržet jejich kopie,
              <br />
              <br />
              (b) právo na smazání údajů,
              <br />
              <br />
              (c) právo vědět, kterým třetím stranám byly vaše Osobní údaje prodány, a právo požadovat ukončení takového
              prodeje, a
              <br />
              <br />
              (d) právo nebýt diskriminován v případě, že využijete jakékoli ze svých spotřebitelských práv.
            </span>
            <br />
            Kromě toho paragraf 22581 Kalifornského obchodního a profesního zákoníku v současnosti opravňuje nezletilé
            obyvatele Kalifornie požádat o smazání jimi na Webu zveřejněného obsahu. Vezměte však na vědomí, že
            GlobalEnglish nemůže zajistit kompletní nebo úplné odstranění obsahu, který jste zveřejnili, a že mohou
            existovat situace, za kterých právo nevyžaduje nebo nepovoluje odstranění ani na žádost.
            <br />
            <br />
            Chcete-li požádat o uplatnění vyšich práv:
            <br />
            <br />
            Poskytovatel Služeb a přidružených služeb je GlobalEnglish Corporation, se sídlem 1875 South Grant Street,
            Suite 700, San Mateo, CA 94402, USA. E-mailová adresa je: privacy@globalenglish.com. <br />
            Ke dni těchto Podmínek nejsou Webem účtovány spotřebitelům žádné přímé poplatky. Pokud máte stížnost na
            služby poskytované na Webu nebo chcete požádat o další informace, kontaktujte nás na adrese uvedené výše
            s popisem vašeho požadavku. Nemůžeme zaručit odpověď v určitém časovém období. Pokud budete nadále
            nespokojeni, kontaktujte Útvar pro řešení stížností Oddělení spotřebitelských služeb Kalifornského
            ministerstva pro spotřebitelské záležitosti písemně na 1625 North Market Blvd., Suite N 112, Sacramento, CA
            95834, nebo telefonicky na (916) 445-1254 nebo (800) 952-5210.
          </p>
        </div>
      </div>
    </>
  );
}

export default CzCzContent;
