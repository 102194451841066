// @ts-nocheck
import React from 'react';

function PlPlContent() {
  return (
    <div id="agreement_container">
      <div style={{ padding: '1% 5% 0 5%' }}>
        <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
          <b>UMOWA UŻYTKOWNIKA</b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
          <b>WSTĘP </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          Witamy osoby korzystające z serwisów GlobalEnglish, poprzez stronę internetową lub aplikacje mobilne (zwane
          łącznie „Serwisami”).
          <br />
          <br />
          Na nasze Serwisy składają się narzędzia do nauki języka angielskiego oraz rozwiązania online, w tym, między
          innymi, testy, układy/projekty, filmy wideo, klipy audio, nagrania, wpisy i komentarze, skrypty / scenariusze,
          grafika, obrazy online oraz ich dobór i ustawienie, oprogramowanie, samouczki, lekcje, coaching, szkolenie,
          wiadomości oraz funkcje interaktywne wygenerowane lub udostępnione za pośrednictwem strony internetowej i
          aplikacji mobilnej (łącznie „Zawartość”). Dostęp do naszej strony internetowej w celu zapoznania się z naszą
          spółką oraz zapytania na temat Serwisów uznaje się za część Serwisów.
          <br />
          <br /> Przed kontynuacją prosimy o uważne zapoznanie się z poniższym tekstem i potwierdzenie zapoznania się i
          zrozumienia poniższych warunków oraz ich akceptacji.
          <br />
          <br />
          <b>
            W PRZYPADKU OSOBY PONIŻEJ 18 ROKU ŻYCIU PROSIMY O ZAPOZNANIE SIĘ Z TREŚCIĄ NINIEJSZEJ UMOWY W OBECNOŚCI
            RODZICA LUB OPIEKUNA I UZYSKANIE ICH ZGODY PRZED SKORZYSTANIEM Z NASZYCH SERWISÓW. KORZYSTAJĄC Z NASZYCH
            SERWISÓW POTWIERDZAJĄ PAŃSTWO, ŻE UKOŃCZYLI 18 LAT LUB UZYSKALI ODPOWIEDNIĄ ZGODĘ OD SWOJEGO RODZICA LUB
            OPIEKUNA I MAJĄ ZEZWOLENIE NA ZAWARCIE NINIEJSZEJ UMOWY Z GLOBALENGLISH.{' '}
          </b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
          <b>WARUNKI OGÓLNE </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 1. WIĄŻĄCA PRAWNIE UMOWA </b>
          <br />
          <br />
          <b>
            {' '}
            PAŃSTWA DOSTĘP DO NASZEJ STRONY INTERNETOWEJ ORAZ KORZYSTANIE Z SERWISÓW GLOBALENGLISH PODLEGA
            POSTANOWIENIOM PONIŻSZEJ UMOWY, POLITYCE PRYWATNOŚCI ORAZ POLITYCE PLIKÓW COOKIE (WSZYSTKIE TRZY DOKUMENTY
            ZWANE SĄ ŁĄCZNIE „WARUNKAMI KORZYSTANIA Z SERWISÓW”). POPRZEZ DOSTĘP, ODWIEDZENIE, REJESTRACJĘ I/LUB
            KORZYSTANIE Z SERWISÓW GLOBALENGLISH OŚWIADCZAJĄ PAŃSTWO, ŻE ZAPOZNALI SIĘ I ZROZUMIELI WARUNKI KORZYSTANIA
            Z NASZYCH SERWISÓW I WYRAŻAJĄ ZGODĘ NA PRAWNE I NIEODWRACALNE ZWIĄZANIE POSTANOWIENIAMI TYCHŻE WARUNKÓW JAK
            RÓWNIEŻ OSIĄGNĘLI PAŃSTWO ZDOLNOŚĆ DO CZYNNOŚCI PRAWNYCH W KONTEKŚCIE WIEKU UMOŻLIWIAJĄCĄ WYRAŻENIE ZGODY NA
            ZWIĄZANIE TYMI WARUNKAMI I ZAWARCIE PRAWNIE WIĄŻĄCEJ UMOWY Z GLOBALENGLISH.{' '}
          </b>
          <br />
          <br />
          <a
            href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Tutaj{' '}
          </a>
          można znaleźć ogólne omówienie naszych regulaminów. Regulaminy te są włączone poprzez odniesienie do
          niniejszej „Umowy użytkownika” albo „Umowy” i łącznie stanowią one warunki korzystania z serwisów.
          <br />
          <br />
          <b>
            Informujemy, że niniejsza Umowa zawiera postanowienia dotyczące sposobu rozstrzygania wzajemnych roszczeń.
            Prosimy o zapoznanie się w szczególności z postanowieniami poniższych punktów: „Wyłączenie gwarancji”,
            „Ograniczenie odpowiedzialności” oraz „Spory prawne”. Zawiera ona również postanowienia arbitrażowe, które,
            z niewielkimi wyjątkami, nakładają na Państwa wymóg poddania roszczeń, które mają Państwo wobec nas, pod
            wiążący i prawomocny arbitraż. Będą Państwo mogli dochodzić roszczeń w trybie indywidualnym, nie jako powód
            lub uczestnik pozwu zbiorowego w ramach jakiegokolwiek pozwu zbiorowego lub powództwa albo postępowania o
            charakterze powództwa reprezentacyjnego. Będą Państwo mogli dochodzić zaspokojenia roszczeń (w tym
            pieniężnych, zabezpieczenia roszczeń lub orzeczenia deklaratywnego) wyłącznie w trybie indywidualnym.
            <br />
            <br /> W PRZYPADKU BRAKU ZGODY NA JAKĄKOLWIEK CZĘŚĆ NINIEJSZYCH WARUNKÓW, W DOWOLNYM MOMENCIE, NIE NALEŻY
            KORZYSTAĆ Z SERWISÓW ANI PORUSZAĆ SIĘ DALEJ W OBRĘBIE NASZEJ WITRYNY INTERNETOWEJ.
          </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. ZAKRES OBOWIĄZYWANIA </b>
          <br />
          <br />
          Niniejsza Umowa ma zastosowanie zarówno względem Użytkowników naszych serwisów oraz Internautów odwiedzających
          nasze strony. Osoby fizyczne, które dokonały u nas bezpośredniej rejestracji (<b>„Transakcja B2C”</b>), nawet
          w trybie próbnym, oraz osoby, które są zarejestrowane przez swoich pracodawców lub za ich pośrednictwem (
          <b>„Transakcja B2B”</b>) zwane są
          <b>„Użytkownikami”</b>, natomiast użytkownicy niezarejestrowani oraz internauci zagłębiający się w treści
          naszych stron internetowych to
          <b>„Internauci”</b>. Termin
          <b>„Użytkownicy”</b> uwzględnia również nauczycieli, coachów, dydaktyków oraz dowolne inne osoby odwiedzające
          strony GlobalEnglish w celu świadczenia usług na rzecz innych Użytkowników. Użytkownicy i Internauci określani
          są terminem:
          <b>„Państwo”</b>.
          <br />
          <br /> Niniejsza Umowa ma zastosowanie względem wszystkich Serwisów GlobalEnglish.
          <br />
          <br /> W przypadku pierwszej rejestracji jako Użytkownik lub przy uzyskiwaniu dostępu do naszych Usług, możemy
          zwrócić się do Państwa o ponowne potwierdzenie Państwa zgody na związanie się warunkami określonymi w
          niniejszej Umowie poprzez kliknięcie rubryki:
          <b>„I agree”</b> [„Wyrażam zgodę/ Zgadzam się”] lub poprzez umieszczenie Państwa podpisu elektronicznego lub
          dokonanie uwierzytelnienia w inny sposób.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            3.ZMIANY W NINIEJSZYCH WARUNKACH KORZYSTANIA Z SERWISÓW{' '}
          </b>
          <br />
          <br />
          GlobalEnglish może w dowolnym momencie dokonywać modyfikacji „Warunków korzystania z serwisów”, z
          uwzględnieniem niniejszej Umowy, „Polityki plików cookie” oraz „Polityki prywatności” obowiązujących w
          odniesieniu korzystania przez Państwa z Serwisów. Takie modyfikacje wchodzą w życie w trybie natychmiastowym
          po dokonaniu powiadomienia, które będzie udzielone w formie umieszczenia go w Serwisie, wiadomości
          elektronicznej przesłanej na Państwa adres e-mail podany nam w związku z Państwa kontem Serwisów (w zależności
          od okoliczności) lub przy pomocy innych środków. W przypadku dokonania rejestracji w celu korzystania z
          naszych Serwisów przez Państwa pracodawcę lub spółkę, Państwa pracodawca lub spółka mogą dokonać powiadomienia
          o zmianach wprowadzonych do naszych „Warunków korzystania z serwisów”.
          <br />
          <br /> W przypadku jakichkolwiek zastrzeżeń wobec tych zmian, mogą Państwo zamknąć swoje konto i zaprzestać
          uzyskiwania dostępu do naszej strony. Natomiast kontynuacja korzystania z naszych Serwisów po opublikowaniu
          lub wysłaniu powiadomienia o zmianach niniejszych warunków uznawana jest za akceptację przez Państwa tychże
          modyfikacji „Warunków korzystania z serwisów”.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4.WARUNKI KORZYSTANIA Z SERWISÓW OSÓB TRZECICH </b>
          <br />
          <br />
          Serwisy mogą zawierać linki do innych stron internetowych („Stron Osób Trzecich”), jak również artykułów,
          zdjęć, tekstu, tłumaczeń, grafiki, obrazów, układów, dźwięku, filmu wideo, informacji lub innych treści lub
          pozycji należących do osób trzecich lub od nich pochodzących („Treść Osób Trzecich”). Takie Strony Osób
          Trzecich oraz Treść Osób Trzecich nie jest przez nas badana, monitorowana ani sprawdzana pod kątem
          dokładności, stosowności ani kompletności i nie odpowiadamy za jakiekolwiek Strony Osób Trzecich, do których
          uzyskano dostęp za pośrednictwem Strony Internetowej ani też za jakąkolwiek Treść Osób Trzecich zamieszczoną
          na stronie internetowej lub udostępnioną za jej pośrednictwem.
          <br />
          <br /> Włączenie, zamieszczenie linka lub zezwolenie na korzystanie z jakiejkolwiek Strony Osób Trzecich lub
          dowolnej Treści Osób Trzecich nie implikuje zatwierdzenia ani przyjęcia ich zawartości przez GlobalEnglish.
          GlobalEnglish zastrzega sobie prawo do zakończenia publikacji takich linków w dowolnym momencie, bez
          powiadomienia. Odniesienia w Serwisach do jakichkolwiek spółek, organizacji, produktów lub usług albo do
          innych informacji nie stanowią ani nie implikują potwierdzenia, sponsorowania ani polecenia ich zawartości ani
          żadnego powiązania z nim ze strony GlobalEnglish.
          <br />
          <br /> W przypadku podjęcia decyzji o opuszczeniu naszej strony internetowej oraz odwiedzeniu Stron Osób
          Trzecich lub uzyskaniu dostępu do Treści Osób Trzecich lub korzystaniu z Treści Osób Trzecich, czynią to
          Państwo na własne ryzyko i powinni Państwo mieć świadomość, że korzystanie przez Państwa z tychże Stron Osób
          Trzecich nie podlega już naszym warunkom ani postanowieniom naszych regulaminów. Prosimy o sprawdzenie
          warunków i postanowień regulaminów obowiązujących dla takich produktów i usług osób trzecich przed
          skorzystaniem z nich.
          <br />
          <br /> Oferujemy aplikacje mobilne zarówno za pośrednictwem Android Market, jak i iTunes Store (zwanych
          łącznie oraz w powiązaniu z podmiotami, które je prowadzą „Dostawcami”). Korzystanie przez Państwa z tych
          aplikacji mobilnych również podlega warunkom Dostawców, w tym, między innymi, „Zasadom korzystania” określonym
          w „Warunkach Serwisu App Store”. Jeśli pobrali Państwo dowolne z tych aplikacji mobilnych z iTunes Store,
          potwierdzają Państwo i zobowiązują się do tego, że zapoznali się Państwo z treścią i wyrażają zgodą na
          „Regulamin Usług App Store”. Jeśli pobrali je Państwo z Android Market, potwierdzają Państwo i zobowiązują się
          do tego, że zapoznali się Państwo z treścią i wyrażają zgodą na „Regulamin Usług Android Market”. Dostawcy nie
          mają obowiązku i nie będą świadczyć żadnych usług konserwacji ani obsługi na Państwa rzecz w związku z
          korzystaniem przez Państwa z Serwisów. Dostawcy nie oferują żadnego rodzaju gwarancji na Serwisy. W przypadku,
          gdy dowolna z aplikacji mobilnych nie będzie odpowiadać żadnej obowiązującej gwarancji, a Państwo pobrali taką
          aplikację z iTunes Store, mogą Państwo powiadomić Apple, a Apple zwróci cenę zakupu (o ile taka istnieje)
          danej aplikacji. Niniejsze Warunki mają zastosowanie względem korzystania przez Państwa ze wszystkich
          aplikacji mobilnych, jak również naszych innych Serwisów.
          <br />
          <br /> Przyjmują Państwo do wiadomości i wyrażają zgodę na to, że GlobalEnglish nie ponosi odpowiedzialności
          ani nie odpowiada za treść, produkty, usługi ani działanie takich stron internetowych lub aplikacji osób
          trzecich. Nieodwołalnie zrzekają się Państwo wszelkich roszczeń wobec GlobalEnglish w związku ze stronami
          internetowymi lub aplikacjami osób trzecich. W szczególności GLOBALENGLISH NIE PONOSI ODPOWIEDZIALNOŚCI ZA
          PLIKI COOKIE, WEB BEACONS, CLEAR GIFS, ANI ZA INNE KODY, KTÓRE PAŃSTWO LUB INNE OSOBY UMIESZCZĄ W PAŃSTWA
          KOMPUTERZE W DOWOLNY SPOSÓB, W TYM WSZELKIEGO POWIĄZANIA TREŚCI STRONY INTERNETOWEJ Z TAKIM KODEM. Na przykład
          jeśli uzyskują Państwo dostęp do Strony Osób Trzecich za pomocą linku na Stronie Internetowej, Państwa
          przeglądarka lub inne pliki cookie na Państwa komputerze mogą powodować przesłanie nagłówka przekierowania do
          takiej strony, pokazując pewną historię przeglądania przez Państwa Internetu lub URL, z którego rozpoczęło się
          odwiedzenie danej strony przez Państwa. GlobalEnglish nie ponosi względem Państwa odpowiedzialności w związku
          z taką transmisją, bez względu na to, czy transmisja zawiera informacje związane z GlobalEnglish lub z niej
          pochodzące.
          <br />
          <br /> JEŚLI KORZYSTAJĄ PAŃSTWO Z TŁUMACZEŃ OBSŁUGIWANYCH PRZEZ GOOGLE: GOOGLE WYŁĄCZA WSZELKIE GWARANCJE
          DOTYCZĄCE TŁUMACZEŃ, WYRAŻONE ALBO DOROZUMIANE, W TYM GWARANCJĘ DOKŁADNOŚCI, RZETELNOŚCI I WSZELKIE INNE
          DOROZUMIANE GWARANCJE MOŻLIWOŚCI SPRZEDAŻY, PRZYDATNOŚCI DO OKREŚLONEGO CELU ORAZ BRAKU NARUSZENIA.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            5. KRYTERIA KWALIFIKOWALNOŚCI W ZAKRESIE KORZYSTANIA Z SERWISU{' '}
          </b>
          <br />
          <br />
          <b>5.1 Ochrona prywatności dzieci oraz ograniczenia w zakresie korzystania z naszych Serwisów. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Serwisy nie są przeznaczone dla osób poniżej Minimalnego [Uprawniającego] Wieku w kraju, w którym osoby
            uzyskują dostęp do Serwisów. Na przykład „Minimalny [Uprawniający] Wiek” w Unii Europejskiej, Europejskim
            Obszarze Gospodarczym i Szwajcarii oznacza 16 lat, o ile przepisy szczegółowe danego kraju nie stanowią
            inaczej. W Stanach Zjednoczonych minimalny [uprawniający] wiek to 13 lat. Wszelkie rejestracje, korzystanie
            lub uzyskiwanie dostępu do Serwisów przez osoby, które nie osiągnęły Minimalnego [Uprawniającego] Wieku są
            zabronione i stanowią naruszenie niniejszych Warunków.
            <br />
            <br /> Korzystając z Serwisów oświadczają Państwo i gwarantują ukończenie13 lat.
          </span>
          <br />
          <br />
          <b>5.2 Szkoły i nauczyciele </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Szkoły, dystrykty lub nauczyciele w Stanach Zjednoczonych, którzy chcą, aby ich uczniowie w wieku poniżej 13
            roku życia utworzyli konta GlobalEnglish, zobowiązują się do ponoszenia odpowiedzialności za zachowanie
            zgodności z Ustawą o ochronie prywatności online dzieci na terenie USA [U.S. Children's Online Privacy
            Protection Act („COPPA")] oraz, w obowiązującym zakresie, z Ustawą o prywatności i prawach edukacyjnych
            rodziny [Family Educational Rights and Privacy Act („FERPA")]. Oznacza to, że szkoły, dystrykty lub
            nauczyciele są zobowiązani powiadamiać rodziców lub opiekunów uczniów, których dotyczą informacje
            umożliwiające identyfikację osobową, które GlobalEnglish będzie gromadzić oraz uzyskać zgodę
            rodzicielską/opiekuna przed założeniem kont przez uczniów i korzystaniem z Serwisów. W trakcie uzyskiwania
            takiej zgody szkoły, dystrykty i nauczyciele są zobowiązani przekazać rodzicom/ opiekunom egzemplarz
            „Polityki prywatności”, jak również prowadzić ewidencję wszelkich zgód i przekazać ją, nam jeśli się o nie
            zwrócimy. Więcej informacji na temat zgodności z postanowieniami COPPA można uzyskać na stronie:
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
              rel="noopener noreferrer"
              target="_blank"
            >
              the Federal Trade Commission's website.
            </a>
            <br />
            <br /> Jeśli przebywają Państwo poza terytorium Stanów Zjednoczonych, będziemy działać w oparciu o
            założenie, że uzyskają Państwo wszelkie wymagane zgody lub zatwierdzenia od rodzica lub opiekuna każdego
            ucznia objętego podobnymi przepisami, natomiast jako warunek korzystania przez Państwa oraz Państwa uczniów
            z naszych Serwisów, zobowiązują się Państwo do przyjęcia odpowiedzialności za zachowanie zgodności z takimi
            przepisami.
          </span>
          <br />
          <br />
          <b>5.3 Zgoda rodzicielska </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            <b>
              KORZYSTAJĄC Z NASZYCH SERWISÓW OŚWIADCZAJĄ PAŃSTWO, ŻE UKOŃCZYLI 18. ROK ŻYCIA LUB UZYSKALI PAŃSTWO
              STOSOWNĄ ZGODĘ OD SWOJEGO RODZICA LUB OPIEKUNA I MOGĄ ZAWRZEĆ NINIEJSZĄ UMOWĘ Z GLOBALENGLISH.
              <br />
              <br /> Oczekujemy, że dzieci będą korzystać z serwisów wyłącznie pod opieką, nadzorem i za zgodą ich
              rodziców, opiekunów i/lub uprawnionych pracowników szkoły. GlobalEnglish działa w oparciu o przekonanie,
              że rodzice, opiekunowie oraz szkoły zapewnią, aby osoby niepełnoletnie korzystały z Serwisów tylko jeśli
              rozumieją swoje prawa i obowiązki określone w niniejszych „Warunkach korzystania z Serwisów”.
              <br />
              <br />
              GlobalEnglish może, według własnego uznania, odmówić oferowania Serwisów osobie fizycznej, podmiotowi lub
              zmienić swoje kryteria kwalifikowalności do korzystania z usług w dowolnym momencie.{' '}
            </b>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6. PRYWATNOŚĆ </b>
          <br />
          <br />
          <b>6.1 Oświadczenie o prywatności </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Prosimy o zapoznanie się z „Polityką prywatności” dostępną
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              tutaj
            </a>
            .
            <br />
            <br /> Będziemy przetwarzać dane osobowe zgodnie z polityką prywatności, na którą wyrazili Państwo zgodę w
            czasie pierwszego uzyskania dostępu do serwisu lub w ramach zaplanowanej aktualizacji polityki prywatności.
          </span>
          <br />
          <br />
          <b>6.2 Przekazywane przez Państwa dane. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            W celu korzystania z Serwisów są Państwo zobowiązani posiadać ważne konto. W celu dokonania rejestracji do
            korzystania z naszych Serwisów muszą Państwo podać GlobalEnglish ID Użytkownika, adres email oraz inne
            informacje, które uznaje się za informacje umożliwiające identyfikację osobową („Dane Osobowe”). Niektóre
            informacje, które od Państwa zbieramy, są dla firmy GlobalEnglish niezbędne do udostępnienia Serwisów bądź
            nawet jedynie po to, aby podać Państwu informacje na temat Serwisów, o które Państwo pytali, inne natomiast
            mają charakter opcjonalny. Serwisy umożliwiają Państwu również wyświetlanie informacji na swój temat oraz na
            temat swojego przedsiębiorstwa, w zależności od okoliczności.
            <br />
            <br /> Zobowiązują się Państwo: (i) do podawania dokładnych, aktualnych i pełnych informacji na swój temat
            oraz, w przypadku transakcji B2B, na temat Państwa przedsiębiorstwa, zgodnie ze wskazówkami formularzy
            rejestracyjnych w ramach Serwisów; (ii) do zachowywania swojego hasła oraz innych informacji dotyczących
            bezpieczeństwa Państwa konta w tajemnicy; (iii) do zapewnienia aktualnego stanu i dokonywania aktualizacji
            Danych Osobowych oraz wszelkich innych informacji podawanych przez Państwa GlobalEnglish na bieżąco, do
            utrzymywania ich dokładnego, aktualnego i pełnego charakteru oraz (iv) do ponoszenia pełnej
            odpowiedzialności za korzystanie z Państwa konta oraz za wszelkie działania, do których dochodzi za
            pośrednictwem Państwa konta.
            <br />
            <br /> GlobalEnglish nie przyjmuje żadnej odpowiedzialności za weryfikację poprawności Danych Osobowych
            przekazywanych przez Państwa do celów rejestracji.
          </span>
          <br />
          <br />
          <b>6.3 Przesyłanie Danych Osobowych w skali międzynarodowej </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Celem udostępniania Serwisów, firma GlobalEnglish korzysta z usług swoich pracowników na całym świecie oraz
            korzysta z zewnętrznych dostawców i partnerów hostingowych w zakresie przechowywania danych oraz innych
            usług związanych z technologią oraz edukacją. Państwa Dane Osobowe są zatem przetwarzane i przesyłane do
            pracowników oraz podwykonawców GlobalEnglish w różnych krajach; część z tych krajów może nie oferować
            takiego samego poziomu ochrony, jaki jest prawnie zapewniony Państwa Danym Osobowym w kraju Państwa
            obywatelstwa lub przebywania.
            <b>
              Uzyskując dostęp, rejestrując się i/lub korzystając z naszych Serwisów wyrażają Państwo zgodę na
              przesyłanie swoich Danych Osobowych oraz przetwarzanie ich za granicą w różnych krajach, w których
              GlobalEnglish, jej jednostki stowarzyszone oraz podwykonawcy posiadają swoje siedziby, na potrzeby
              Serwisów.
            </b>
          </span>
          <br />
          <br />
          <b>6.4 Powiadomienia i wiadomości. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Przyjmują Państwo do wiadomości i wyrażają zgodę na to, że Serwisy uwzględniają pewne przekazy z
            GlobalEnglish takie jak: ogłoszenia serwisowe, komunikaty o charakterze administracyjnym, itp. i że uznaje
            się je za część Serwisów oraz że nie będą Państwo w stanie zrezygnować z ich otrzymywania.
            <br />
            <br /> Niniejszym wyrażają Państwo zgodę na to, aby firma GlobalEnglish mogła przekazywać powiadomienia i
            wiadomości do Państwa w następujący sposób: (i) w ramach Serwisu; (ii) wykorzystując informacje teleadresowe
            przekazane przez Państwa firmie GlobalEnglish (np. email lub numer komórkowy). Zobowiązują się Państwo do
            aktualizacji dotyczących Państwa informacji teleadresowych w trybie ciągłym.
          </span>
          <br />
          <br />
          <b>6.5 Prywatność innych osób </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            W przypadku uzyskania informacji na temat innego Użytkownika w toku korzystania przez Państwa z naszych
            Serwisów, zobowiązują się Państwo do wykorzystywania tych informacji tylko do celu, w jakim zostały one
            Państwu przekazane. Nie mogą Państwo ujawniać, sprzedawać ani rozprowadzać informacji na temat Użytkownika
            osobom trzecim do celów niezwiązanych z Serwisami. Nie mogą Państwo wykorzystywać informacji do celów
            marketingowych bez uzyskania na to zgody konkretnego Użytkownika.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. LICENCJE UŻYTKOWNIKÓW </b>
          <br />
          <br />
          <b>7.1 Licencje na korzystanie z naszych Serwisów </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Uzyskują Państwo ograniczoną, osobistą, niewyłączną, niepodlegającą cesji, niepodlegającą sublicencji oraz
            nieprzenaszalną licencję na uzyskanie dostępu do Serwisów i korzystanie z Serwisów wyłącznie do osobistego
            użytku o charakterze niekomercyjnym. GlobalEnglish może, według własnego uznania, zaprzestać prowadzenia
            Serwisu w trybie tymczasowym lub trwałym lub okresowo modyfikować funkcje Serwisów bez uprzedniego
            powiadomienia.
            <br />
            <br /> W celu korzystania z Serwisów należy uzyskać dostęp do Internetu, albo bezpośrednio albo poprzez
            urządzenia, które umożliwiają dostęp do treści internetowych. Ponadto muszą Państwo posiadać całość sprzętu
            potrzebnego do nawiązania połączenia z Internetem, w tym komputer i modem albo inne urządzenie dostępowe.
            Zobowiązują się Państwo do konieczności przeprowadzenia oceny oraz wyrażają Państwo zgodę na ponoszenie
            wszelkiego ryzyka związanego z korzystaniem z Serwisów, w tym w zakresie polegania na dokładności,
            kompletności oraz użyteczności podawanych treści. Ani GlobalEnglish ani jej licencjodawcy nie gwarantują
            dokładności ani kompletności żadnej treści.
            <br />
            <br /> Serwisy nie są przeznaczone, ani też nie została udzielona w takim zakresie licencja, do korzystania
            w środowiskach niebezpiecznych (np. w trakcie prowadzenia pojazdu lub w pobliżu wody). Prawo dostępu do
            Serwisu jest uchylone w przypadku korzystania z Serwisu tam, gdzie jest to zabronione lub w takim zakresie,
            w jakim oferowanie, sprzedaż lub udostępnianie Serwisu jest sprzeczne z jakimkolwiek obowiązującym prawem,
            przepisem lub rozporządzeniem.
            <br />
            <br />
            Korzystanie z Serwisu w dowolnym celu innym niż rozważany w „Warunkach korzystania z serwisów” stanowi
            naruszenie „Warunków korzystania z serwisów” oraz prawa autorskiego i praw własności GlobalEnglish i/lub jej
            licencjodawców i podwykonawców. GlobalEnglish ma prawo zawiesić lub zakończyć prowadzenie Państwa konta i
            odmówić Państwu możliwości korzystania z wszelkich bieżących lub przyszłych Serwisów w przypadku, gdy
            GlobalEnglish posiada uzasadnione podstawy, aby podejrzewać, że korzystają Państwo z Serwisów z naruszeniem
            niniejszych „Warunków korzystania z serwisów”. Wyrażają Państwo zgodę na to, aby GlobalEnglish nie ponosiła
            odpowiedzialności za modyfikacje, zawieszenie lub wstrzymanie Serwisów lub dowolnej ich części.
          </span>
          <br />
          <br />
          <b>7.2 Treść Użytkownika</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Serwisy dają Państwu i innym Użytkownikom możliwość tworzenia danych i informacji (np. poprzez podanie
            odpowiedzi do naszych testów) oraz wgrywania materiałów do serwisów społecznościowych („Treść Użytkownika”).
            Z zastrzeżeniem praw przyznanych nam na mocy niniejszych Warunków, zachowują Państwo pełne prawo własności
            do swojej Treści Użytkownika.
            <br />
            <br /> Wprowadzając Treść Użytkownika do naszych Serwisów, przyznają Państwo GlobalEnglish nieodwołalną,
            wieczystą, niewyłączną, przenaszalną, w pełni opłaconą licencję na obszar całego świata (z prawem do
            udzielania podlicencji) na przechowywanie, korzystanie, odtwarzanie, publiczne wystawianie, publiczne
            wyświetlanie, modyfikowanie, tłumaczenie, pobieranie wyciągów (w całości lub w części), przetwarzanie i
            rozprowadzanie takiej Treści Użytkownika w związku z prowadzeniem Serwisów w celu:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) zapewnienia zgodności z procedurą prawną, z uwzględnieniem, między innymi, udzielania odpowiedzi na
              zapytania prawne, nakazy sądowe lub inne podobne wnioski rządowe;
              <br />
              (b) egzekwowania Umowy;
              <br />
              (c) reakcji na roszczenia, że dowolna Treść narusza prawa osób trzecich;
              <br />
              (d) ochrony praw, mienia lub bezpieczeństwa osobistego firmy GlobalEnglish, jej pracowników, Użytkowników
              i społeczeństwa.
            </span>
            <br />
            <br /> W zakresie w jaki postanowią Państwo udostępniać Treść Użytkownika innym Użytkownikom Serwisów,
            zobowiązują się Państwo zezwolić tymże Użytkownikom na przeglądanie Państwa Treści Użytkownika oraz w
            zakresie jakim ma to zastosowanie, na prowadzenie z Państwem współpracy w ramach takiej Treści Użytkownika.
            <br />
            <br /> Informujemy, że GlobalEnglish nie ma w żadnym momencie obowiązku przesiewania, przeglądania,
            weryfikowania dokładności, korygowania ani usuwania takiej Treści Użytkownika. Zastrzega sobie jednak prawo
            do monitorowania Treści Użytkownika oraz usuwania tych z nich, które, według własnego swobodnego uznania,
            uznaje za obraźliwe lub naruszające w inny sposób „Warunki korzystania z serwisów”.
            <br />
            <br /> GlobalEnglish nie ma obowiązku tworzenia kopii zapasowych ani przechowywania Treści Użytkownika.
            Ponoszą Państwo wyłączną odpowiedzialność, na całkowicie własny koszt, za utworzenie kopii zapasowych oraz
            wymianę jakiejkolwiek Treści Użytkownika, jaką zamieszczają Państwo lub przechowują na Stronie Internetowej
            lub udostępniają firmie GlobalEnglish.
            <br />
            <br /> Treść Użytkownika niekoniecznie odzwierciedla poglądy GlobalEnglish. Rozumieją Państwo, że Treść
            Użytkownika stanowi wyłączną odpowiedzialność Użytkownika, od którego dana Treść Użytkownika pochodzi.
            Rozumieją Państwo, że korzystając z Serwisów mogą być Państwo narażeni na Treść Użytkownika, która jest
            obraźliwa, nieprzyzwoita lub rodząca sprzeciw.
            <br />
            <br /> W uzupełnieniu do licencji udzielonej na ww. Treść Użytkownika przyjmują Państwo do wiadomości i
            wyrażają Państwo zgodę na to, aby wszelkie pytania, komentarze, sugestie, pomysły, informacje zwrotne lub
            inne na temat Serwisów („Zgłoszenia”) przekazywane przez Państwa GlobalEnglish nie mają charakteru poufnego,
            a firma GlobalEnglish jest uprawniona do nieograniczonego korzystania i rozpowszechniania takich Zgłoszeń do
            dowolnego celu, komercyjnego lub innego, bez potwierdzania Państwu tego faktu i bez wynagrodzenia z tego
            tytułu. Ponadto informacje zamieszczone, ujawnione lub udostępnione w Serwisach społecznościowych lub
            produktach GlobalEnglish są wyłączone z definicji informacji poufnych w charakterze, w jakim termin ten jest
            ogólnie zdefiniowany.
            <br />
            <br /> Rozumieją Państwo, że przetwarzanie techniczne i transmisja strony internetowej, z uwzględnieniem
            Treści Użytkownika, może obejmować (a) transmisje za pośrednictwem różnych sieci; oraz (b) zmiany w celu
            zachowywania zgodności oraz adaptacji do wymogów technicznych połączonych sieci lub urządzeń.
            <br />
            <br />
            <b>
              GlobalEnglish nie składa zapewnień ani nie udziela gwarancji w odniesieniu do Treści Użytkownika. Treść
              Użytkownika niekoniecznie odzwierciedla poglądy GlobalEnglish. W żadnym wypadku GlobalEnglish nie ponosi
              odpowiedzialności ani nie odpowiada za Treść Użytkownika lub jej utratę z dowolnego powodu lub z tytułu
              jakichkolwiek roszczeń, odszkodowań lub strat wynikających z ich wykorzystania lub utraty.
            </b>
          </span>
          <br />
          <br />
          <b>7.3 Wymagane zachowanie Użytkownika</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Ponoszą Państwo pełną odpowiedzialność za wszelkie działania, które zachodzą na Państwa koncie. Zobowiązują
            się Państwo:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) bezzwłocznie zawiadamiać GlobalEnglish o wszelkim nieuprawnionym korzystaniu z Państwa konta lub
              dowolnym innym naruszeniu zabezpieczeń, oraz
              <br />
              (b) dopilnować, aby wychodzili Państwo ze swojego konta po zakończeniu każdej sesji.
            </span>
            <br />
            <br />
            GlobalEnglish nie może ani nie będzie ponosić odpowiedzialności za żadne straty ani szkody wynikające z
            nieprzestrzegania przez Państwa powyższych zasad.
            <br />
            <br />
            Ponoszą Państwo wyłączną odpowiedzialność za dopilnowanie, aby niniejsze „Warunki Serwisu” były zgodne z
            wszelkimi ustawami, przepisami oraz rozporządzeniami, które Państwa obowiązują, a prawa dostępu do Serwisu
            zostają uchylone, gdy niniejsze „Warunki Serwisu” lub korzystanie z Serwisu są zabronione lub w zakresie, w
            jakim oferowanie, sprzedaż lub świadczenie Usług pozostaje w konflikcie z dowolną obowiązującą ustawą,
            przepisem lub rozporządzeniem.
          </span>
          <br />
          <br />
          <b>7.4 Zabronione zachowanie Użytkownika </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Poniżej przytoczono przykłady rodzajów korzystania, które są niezgodne z prawem lub zabronione przez
            GlobalEnglish. GlobalEnglish zastrzega sobie prawo do zbadania lub podjęcia stosownych działań prawnych
            przeciwko każdemu, kto, według własnego uznania GlobalEnglish narusza niniejsze postanowienie, z
            uwzględnieniem, między innymi, usunięcia obraźliwej treści ze strony internetowej lub aplikacji, zawieszenia
            lub zaprzestania prowadzenia konta takich Użytkowników oraz zgłoszenia Państwa organom ścigania.
            <br />
            <br /> Nie mogą Państwo:
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) adaptować, pobierać, zmieniać, nadawać, odtwarzać w trybie reverse engineer, powielać, publikować,
              modyfikować, rozpowszechniać, wyświetlać, przesyłać ani w inny sposób kopiować i/lub rozprowadzać żadnej
              Treści GlobalEnglish ani innego materiału bez specjalnego upoważnienia GlobalEnglish.
              <br />
              (b) Ramkować [frame] ani korzystać z technik ramkowania [flaming techniques] w celu wydzielenia
              jakiejkolwiek części Serwisu;
              <br />
              (c) zbierać, pozyskiwać, korzystać, uzyskiwać dostęp lub w inny sposób kopiować jakiejkolwiek części
              Serwisu z wykorzystaniem dowolnego bota, pająka (spiker), crawlera, oprogramowania szpiegującego, silnika,
              urządzenia, oprogramowania lub jakichkolwiek innych urządzeń automatycznych, funkcjonalności lub procesów
              ręcznych jakiegokolwiek rodzaju;
              <br />
              (d) korzystać z Serwisów lub jakichkolwiek funkcji dostępnych na Serwisach w dowolny sposób zamierzając
              dokonać zakłóceń, szkód, dezaktywacji, przeciążenia lub osłabienia Serwisów;
              <br />
              (e) sprzedawać, udzielać licencji, wynajmować lub w inny sposób korzystać z Serwisów na potrzeby
              przedsięwzięć komercyjnych; ani
              <br />
              (f) angażować się w jakąkolwiek działalność, która zakłóca innym Użytkownikom dostęp, korzystanie lub
              przyjemność korzystania z Serwisu.
              <br />
              (g) zamieszczać wpisów, wysyłać emaili lub w inny sposób przesyłać jakiejkolwiek Treści Użytkownika, która
              (i) jest niezgodna z prawem, szkodliwa, zawiera groźby, jest obraźliwa, napastliwa, ma charakter czynu
              niedozwolonego, ma nadmiernie przemocowy charakter, jest oszczercza, wulgarna, obsceniczna,
              pornograficzna, zniesławiająca, naruszająca czyjąś prywatność (do poziomu, nie wyłączając, jakiegokolwiek
              adresu, e-maila, numeru telefonu lub innych informacji teleadresowych), siejąca nienawiść na tle rasowym,
              etnicznym lub rodzącym obiekcje w inny sposób; (ii) co do której nie mają Państwo prawa do jej przesyłania
              na mocy jakiegokolwiek prawa lub stosunków umownych lub powierniczych; (iii) wywołuje lub stwarza ryzyko
              naruszenia prywatności lub bezpieczeństwa dowolnej osoby; (iv) narusza jakąkolwiek własność intelektualną
              lub inne prawa własności dowolnej osoby; (v) stanowi niepożądaną lub nieuprawnioną reklamę, materiały
              promocyjne, działania komercyjne i/lub sprzedaż, „pocztę śmieciową,” „spam,” „korespondencję o charakterze
              łańcuszka,” „programy piramid,” „zawody,” „loterie,” lub jakąkolwiek inną formę nakłaniania; (vi) zawiera
              wirusy oprogramowania lub dowolny inny kod, pliki lub programy komputerowe służące do zakłócania,
              niszczenia lub ograniczania funkcjonalności dowolnego oprogramowania lub oprzyrządowania komputerowego lub
              sprzętu telekomunikacyjnego; lub (vii) według własnego uznania GlobalEnglish rodzi zastrzeżenia lub
              ogranicza albo zniechęca dowolną inną osobę do korzystania lub pozbawia ją przyjemności korzystania z
              Serwisów albo która może narazić GlobalEnglish lub jego użytkowników na jakąkolwiek szkodę lub
              odpowiedzialność jakiegokolwiek rodzaju;
              <br />
              (h) tworzyć sztucznej tożsamości, składać nieprawdziwych oświadczeń dotyczących Państwa tożsamości,
              podszywać się pod jakąkolwiek inną osobę lub podmiot lub składać nieprawdziwe oświadczenia lub w inny
              sposób wprowadzać w błąd w kwestii swojego powiązania z osobą lub podmiotem;
              <br />
              (i) pozyskiwać informacji osobowych od dowolnej osoby, która nie ukończyła 18 lat;
              <br />
              (j) w dowolny sposób szkodzić osobom niepełnoletnim;
              <br />
              (k) nakłaniać do wyrządzenia fizycznej szkody lub spowodowania uszczerbku na zdrowiu jakiejkolwiek grupy
              lub osoby fizycznej, „napastowania” lub prześladowania w inny sposób dowolnej osoby;
              <br />
              (l) podawania lub propagowania informacji o charakterze instrukcji na temat działań nielegalnych takich
              jak np.: instrukcji konstruowania bomb, granatów lub innej broni albo urządzeń zapalających;
              <br />
              (m) prowadzić zbiorów lub gromadzić, za pomocą korzystania ze zautomatyzowanych skryptów lub w inny
              sposób, treści strony internetowej lub adresów e-mailowych, informacji teleadresowych lub innych
              informacji prywatnych innych Użytkowników ze Strony Internetowej w jakimkolwiek celu, z uwzględnieniem,
              między innymi, celów rozsyłania niechcianych e-maili lub innych niechcianych przesyłek do Użytkowników lub
              odtwarzania treści strony internetowej;
              <br />
              (n) reklamować lub oferować sprzedaż lub zakup jakichkolwiek towarów lub usług, do jakiegokolwiek celu
              biznesowego, który nie został konkretnie autoryzowany;
              <br />
              (o) ingerować w funkcjonowanie lub zakłócać stronę internetową lub serwery albo sieci podłączone ze stroną
              internetową lub nie przestrzegać jakichkolwiek wymogów, procedur, regulaminów lub rozporządzeń sieci
              połączonych ze stroną internetową;
              <br />
              (p) naruszać jakiekolwiek obowiązujące miejscowe, stanowe, krajowe lub międzynarodowe prawo albo dowolne
              rozporządzenia mające moc prawną; wspierać lub propagować dowolną działalność przestępczą lub
              przedsięwzięcie o takim charakterze lub udzielać informacji o charakterze instrukcji na temat działań
              nielegalnych;
              <br /> (q) pozyskiwać lub podejmować próby uzyskania dostępu lub w inny sposób pozyskiwać dowolne
              materiały lub informacje przy pomocy środków, które nie są celowo udostępnione lub podane za pomocą strony
              internetowej;
              <br />
              (r) obchodzić funkcje bezpieczeństwa lub omijać dowolne środki kontroli dostępu; albo
              <br />
              (s) korzystać z Serwisów w sposób, który może powodować powstanie konfliktu interesów lub negatywnie
              wpływać na cele Serwisów, np. poprzez wymienianie się opiniami / ocenami z innymi Użytkownikami lub
              pisanie lub pozyskiwanie opinii „podbijających”.
            </span>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. PRAWA WŁASNOŚCI INTELEKTUALNEJ</b>
          <br />
          <br />
          <b>8.1 Prawa własności intelektualnej GlobalEnglish </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Całość treści Serwisów, w tym Treści oraz wszelkiego rodzaju oprogramowanie, które je obsługuje, stanowią
            własność GlobalEnglish, jej licencjodawców lub jej podwykonawców i chronią ją przepisy USA oraz
            międzynarodowego prawa autorskiego i prawa własności intelektualnej.
            <br />
            <br /> Odtwarzanie, redystrybucja, modyfikowanie albo publikowanie dowolnej części Serwisów bez wyraźnej
            pisemnej zgody GlobalEnglish i/lub jej licencjodawców lub innych podmiotów niż określone powyżej jest surowo
            wzbronione.
            <br />
            <br />
            Autoryzowani Użytkownicy mogą uzyskiwać dostęp i korzystać z Serwisów oraz Treści i pobierać lub drukować
            zasadną liczbę egzemplarzy części Treści, do której Użytkownik uzyskał dostęp w sposób odpowiedni wyłącznie
            na potrzeby osobistego użytku Użytkownika, do celów nauki języki pod warunkiem, że Użytkownik zachowuje
            wszystkie informacje o prawie autorskim lub innych prawach własności na wszystkich egzemplarzach takiej
            Treści.
            <br />
            <br /> O ile nie wskazano inaczej, znaki towarowe, które pojawiają się w Serwisach są znakami towarowymi
            GlobalEnglish, jej licencjodawców oraz jej jednostek stowarzyszonych. Zobowiązują się Państwo do
            nieeksponowania, niedyskredytowania ani niewpływania w sposób negatywny na znaki towarowe ani niekorzystania
            z jakichkolwiek wprowadzających w błąd podobnych znaków ani niekorzystania z nich w sposób, który mógłby
            wprowadzić w błąd w sprawie prawa własności do tychże znaków lub wywołać wrażenie, że GlobalEnglish wspiera
            jakikolwiek produkt lub usługę. Nie mogą Państwo odtwarzać znaków towarowych, nazw handlowych i/lub oznaczeń
            / wizualizacji handlowych bez uprzedniej pisemnej zgody GlobalEnglish. Wszelka wartość firmy / renoma
            powstała na skutek używania znaków towarowych GlobalEnglish będzie przysługiwać wyłącznie nam.
            <br />
            <br /> Wszelkie korzystanie ze stron internetowych, aplikacji lub Treści w sposób inny niż konkretnie
            autoryzowany na mocy niniejszego dokumentu jest wzbronione i spowoduje automatyczne wygaśnięcie Państwa praw
            w odniesieniu do korzystania z Serwisów oraz Treści przyznanych na mocy niniejszego dokumentu. Wszelkie
            prawa GlobalEnglish lub jej licencjodawców, które nie zostały wyraźnie przyznane na mocy niniejszych
            Warunków, są zastrzeżone odpowiednio dla GlobalEnglish i jej licencjodawców.
          </span>
          <br />
          <br />
          <b>8.2 Znaki towarowe Osób Trzecich </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Nazwy i logotypy Produktu i Serwisu wykorzystane i wyświetlane za pomocą Strony Internetowej, inne niż
            opisane powyżej znaki towarowe GlobalEnglish, mogą być znakami towarowymi lub znakami usługowymi ich
            odpowiednich właścicieli, którzy mogą lecz nie muszą aprobować firmy GlobalEnglish, i mogą, lecz nie muszą
            być powiązani lub stowarzyszeni z GlobalEnglish. Żadnej części niniejszych Warunków na tej stronie
            internetowej nie należy interpretować jako przyznania, w drodze implikacji, poprzez instytucję prawną
            estoppel, albo w innym trybie jakiejkolwiek licencji lub prawa do używania dowolnego ze znaków towarowych
            wyeksponowanych na Stronie Internetowej, bez naszej uprzedniej każdorazowej pisemnej zgody. Wszelka
            reputacja / wartość firmy powstała na skutek używania znaków towarowych GlobalEnglish będzie przysługiwać
            wyłącznie nam.
          </span>
          <br />
          <br />
          <b>
            8.3 Skargi dotyczące praw autorskich na mocy Milenijnej ustawy o cyfrowym prawie autorskim [Digital
            Millennium Copyright Act]
          </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Prosimy o informację w przypadku jakichkolwiek wątpliwości dotyczących praw autorskich do jakichkolwiek
            materiałów zamieszczanych w Serwisach przez innych.
            <br />
            <br /> GlobalEnglish będzie przetwarzać i badać powiadomienia zawierające zarzuty naruszenia i będzie
            podejmować odpowiednie działania na mocy Milenijnej ustawy o cyfrowym prawie autorskim [Digital Millennium
            Copyright Act („DMCA”)] i innych obowiązujących ustaw dotyczących własności intelektualnej w odniesieniu do
            zarzutów naruszeń lub rzeczywistych naruszeń.
            <br />
            <br /> Powiadomienie o zarzucie naruszenia prawa autorskiego należy wysłać e-mailem do Przedstawiciela ds.
            Prawa Autorskiego GlobalEnglish na adres: copyright@globalenglish.com (Temat: „Wniosek o zdjęcie treści na
            mocy ustawy DMCA [„DMCA Takedown Request”]).
            <br />
            <br />
            Można się z nami również skontaktować korespondencyjnie pod adresem:
            <br />
            <b>Do wiadomości: Departamentu ds. Prawa Autorskiego/ Prawnego </b>
            <br /> 1875 South Grant Street, Suite 700
            <br /> San Mateo, CA 94402 USA
            <br />
            <br />
            <br /> Pod rygorem nieważności powiadomienie musi być dokonane w formie pisemnej i zawierać następujące
            informacje: (i) podpis elektroniczny lub odręczny osoby uprawnionej do działania w imieniu właściciela prawa
            autorskiego lub innego prawa własności intelektualnej; (ii) opis utworu/ pracy chronionej prawem autorskim
            lub innego mienia chronionego prawem własności intelektualnej, które Państwa zdaniem, zostało naruszone;
            (iii) opis miejsca, w którym materiał, który, Państwa zdaniem, narusza prawo, jest zlokalizowany w Serwisie
            z odpowiednim poziomem szczegółowości, abyśmy mogli odnaleźć go na stronie; (iv) informacje pozwalające
            GlobalEnglish skontaktować się z Państwem (adres, nr telefonu, adres e-mail); (v) oświadczenie złożone przez
            Państwa, że w dobrej wierze żywią Państwo przekonanie, że sporne wykorzystanie nie jest autoryzowane przez
            właściciela prawa autorskiego lub prawa własności intelektualnej, jego przedstawiciela lub z mocy prawa;
            (vi) oświadczenie złożone przez Państwa pod groźbą kary za składanie fałszywych oświadczeń, że powyższe
            informacje zawarte w Państwa Powiadomieniu są precyzyjne oraz że są Państwo właścicielem praw autorskich lub
            praw własności intelektualnej lub że są Państwo uprawnieni do działania na w imieniu właściciela praw
            autorskich lub praw własności intelektualnej.
            <br />
            <br /> Zgodnie z DMCA oraz innym obowiązującym prawem, GlobalEnglish przyjęła strategię kończenia
            współpracy, w odpowiednich okolicznościach oraz według własnego uznania GlobalEnglish, z użytkownikami, co
            do których uznaje się, że notorycznie dopuszczają się naruszeń. GlobalEnglish może również, według swojego
            własnego uznania, ograniczyć dostęp do Strony Internetowej i/lub wypowiedzieć członkostwo dowolnym
            użytkownikom, którzy naruszają jakiekolwiek prawa własności intelektualnej innych osób, bez względu na to,
            czy proceder ma charakter notoryczny, czy nie.
          </span>
          <br />
          <br />
          <b>8.4. Usuwanie Treści Użytkownika, co do której istnieje podejrzenie naruszania</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Jeśli GlobalEnglish usunęła jakąś część Państwa Treści Użytkownika, co do której istnieje podejrzenie
            naruszenia praw własności intelektualnej osób trzecich, a Państwo żywią przekonanie, że Państwa Treść
            Użytkownika, która została usunięta (lub do której dezaktywowano dostęp) nie ma charakteru naruszenia lub że
            posiadają Państwo na to upoważnienie od właściciela praw autorskich, przedstawiciela właściciela praw
            autorskich lub zgodnie z prawem na zamieszczanie i korzystanie z zawartości Państwa Treści Użytkownika, mogą
            Państwo wysłać pisemne kontrpowiadomienie do Przedstawiciela ds. Praw Autorskich zawierające następujące
            informacje: (i) Państwa podpis elektroniczny lub odręczny; (ii) identyfikację treści, która została usunięta
            lub do której dostęp dezaktywowano oraz lokalizację, w której treść się pojawiła zanim została usunięta lub
            dezaktywowana; (iii) oświadczenie, że żywią Państwo przekonanie w dobrej wierze, że treść została usunięta
            lub dezaktywowana w wyniku pomyłki lub błędnej identyfikacji treści; oraz (iv) Państwa imię i nazwisko,
            adres, numer telefonu oraz adres e-mail, oświadczenie, że wyrażają Państwo zgodę na jurysdykcję sądu
            federalnego znajdującego się w Dystrykcie Północnym stanu Kalifornia oraz oświadczenie, że przyjmą Państwo
            doręczenia pism procesowych od osoby, która przekazała powiadomienie o domniemanym naruszeniu.
            <br />
            <br /> Jeśli Przedstawiciel ds. Praw Autorskich otrzyma kontrpowiadomienie, GlobalEnglish wyśle egzemplarz
            kontrpowiadomienia osobie występującej z roszczeniem, z informacją, że może przywrócić usuniętą treść lub
            wstrzymać dezaktywację w ciągu 10 dni roboczych. O ile właściciel praw autorskich nie złoży pozwu o wydanie
            nakazu sądowego przeciwko dostawcy treści, członkowi lub użytkownikowi, usuniętą treść można przywrócić lub
            zapewnić do niej ponowny dostęp w ciągu przynajmniej 10-14 dni roboczych po otrzymaniu kontrpowiadomienia,
            według naszego własnego uznania.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            {' '}
            9. KORZYSTANIE W SKALI MIĘDZYNARODOWEJ ORAZ ŚRODKI KONTROLI EKSPORTU{' '}
          </b>
          <br />
          <br />
          Uznając globalny charakter Internetu, zobowiązują się Państwo do przestrzegania wszelkich miejscowych zasad
          dotyczących zachowania online oraz akceptowalnej Treści Użytkownika. Zobowiązują się Państwo do przestrzegania
          wszelkich obowiązujących przepisów dotyczących przesyłania danych technicznych eksportowanych ze Stanów
          Zjednoczonych lub kraju, w którym Państwo przebywają.
          <br />
          <br /> Przyjmują Państwo do wiadomości, że Serwisy lub ich część mogą podlegać przepisom o kontroli eksportu
          Stanów Zjednoczonych. Nie będą Państwo eksportować, re-eksportować ani przesyłać żadnej części Serwisu lub
          dowolnych informacji ani materiałów technicznych z bezpośrednim lub pośrednim naruszeniem dowolnych
          obowiązujących przepisów lub rozporządzeń dotyczących eksportu. Oświadczają Państwo i gwarantują, że nie są
          Państwo obywatelami, ani nie przybywają Państwo w kraju objętym embargiem rządu USA, ani kraju, który został
          wskazany przez rząd USA jako kraj „wspierający terroryzm”, jak również nie znajdują się Państwo na żadnej
          liście stron objętych zakazem lub ograniczeniami przez rząd USA.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. ZABEZPIECZENIE ROSZCZEŃ </b>
          <br />
          <br /> Przyjmują Państwo do wiadomości, że wszelkie korzystanie z Serwisu, pozostające w sprzeczności z
          „Warunkami korzystania z serwisów”, może spowodować nieodwracalne straty dla GlobalEnglish, jej jednostek
          stowarzyszonych, licencjodawców, podwykonawców, podmiotów prowadzących odsprzedaż i Użytkowników. W takich
          okolicznościach GlobalEnglish, jej jednostki stowarzyszone, licencjodawcy, podwykonawcy, osoby prowadzące
          odsprzedaż oraz Użytkownicy będą uprawnieni do zadośćuczynienia na zasadzie prawa słuszności bez składania
          kaucji lub innego zabezpieczenia, z uwzględnieniem, między innymi, tymczasowego lub stałego zabezpieczenia
          roszczeń.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11.PRZERWA </b>
          <br />
          <br /> Serwisy mogą być okresowo czasowo niedostępne z powodu konserwacji lub z innych przyczyn. GlobalEnglish
          nie ponosi odpowiedzialności za jakiekolwiek przerwy, opóźnienia działania lub transmisji, kradzież lub
          zniszczenie, nieuprawniony dostęp lub zmianę, wiadomości Użytkownika ani dowolnej innej treści udostępnionej
          przez stronę internetową.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12.WYŁĄCZENIA GWARANCJI </b>
          <br />
          <br /> Serwisy mogą być okresowo czasowo niedostępne z powodu konserwacji lub z innych przyczyn. GlobalEnglish
          nie ponosi odpowiedzialności za jakiekolwiek przerwy, opóźnienia działania lub transmisji, kradzież lub
          zniszczenie, nieuprawniony dostęp lub zmianę, wiadomości Użytkownika ani dowolnej innej treści udostępnionej w
          ramach Serwisów. W żadnych okolicznościach GlobalEnglish nie będzie ponosić żadnej odpowiedzialności za
          jakikolwiek uszczerbek na zdrowiu lub śmierć wynikającą z korzystania z Serwisów, jakiejkolwiek Treści
          Użytkownika albo Treści Osoby Trzeciej lub korzystania z produktów udostępnianych albo usług świadczonych
          przez Użytkowników.
          <br />
          <br /> ROZUMIEJĄ PAŃSTWO I WYRAŻAJĄ ZGODĘ NA PONIŻSZE:
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            {' '}
            (a) SERWISY, Z UWZGLĘDNIENIEM CAŁEJ ZAWARTEJ W NICH TREŚCI SĄ UDOSTĘPNIANE W STANIE „W JAKIM SĄ” I „W JAKIM
            SĄ DOSTĘPNE”.
            <br />
            <br /> (b) GLOBALENGLISH WYŁĄCZA WSZELKIE ZAPEWNIENIA ORAZ GWARANCJE W ODNIESIENIU DO SERWISÓW, ZARÓWNO
            WYRAŻONE, JAK I DOROZUMIANE, Z UWZGLĘDNIENIEM DOROZUMIANYCH GWARANCJI TYTUŁU WŁASNOŚCI, MOŻLIWOŚCI
            SPRZEDAŻY, PRZYDATNOŚCI DO OKREŚLONEGO CELU LUB BRAKU NARUSZENIA.
            <br />
            <br /> (c) GLOBALENGLISH NIE GWARANTUJE ANI NIE OBIECUJE ŻADNYCH KONKRETNYCH WYNIKÓW Z KORZYSTANIA Z
            SERWISU, ANI TEŻ ŻE SERWISY SPEŁNIĄ PAŃSTWA WYMOGI LUB OCZEKIWANIA.
            <br />
            <br />
            (d) GLOBALENGLISH NIE SKŁADA OŚWIADCZENIA ANI NIE GWARANTUJE, ŻE OPROGRAMOWANIE, TREŚĆ LUB MATERIAŁY W
            SERWISACH LUB NA STRONIE INTERNETOWEJ SĄ PRECYZYJNE, KOMPLETNE, RZETELNE, AKTUALNE LUB WOLNE OD BŁĘDÓW ANI
            ŻE STRONA INTERNETOWA LUB SERWISY, ICH SERWERY, SĄ WOLNE OD WIRUSÓW LUB INNYCH SZKODLIWYCH ELEMENTÓW. W
            ZWIĄZKU Z POWYŻSZYM, BEZ OGRANICZEŃ DLA POWYŻSZEGO, ROZUMIEJĄ PAŃSTWO, ŻE POBIERAJĄ PAŃSTWO LUB UZYSKUJĄ W
            INNYM TRYBIE TREŚĆ, MATERIAŁ, DANE LUB OPROGRAMOWANIE Z SERWISÓW LUB ZA ICH POŚREDNICTWEM LUB OD DOSTAWCY
            WEDŁUG WŁASNEGO UZNANIA I NA WŁASNE RYZYKO ORAZ ŻE BĘDĄ PAŃSTWO WYŁĄCZNIE ODPOWIADALI ZA KORZYSTANIE Z NICH
            PRZEZ SIEBIE, JAK RÓWNIEŻ ZA WSZELKIE SZKODY ODNIESIONE PRZEZ PAŃSTWA SYSTEM KOMPUTEROWY, UTRATĘ DANYCH LUB
            INNE SZKODY DOWOLNEGO RODZAJU, JAKIE MOGĄ W WYNIKU TAKIEGO KORZYSTANIA ZAISTNIEĆ.
            <br />
            <br /> (e) GLOBALENGLISH NIE GWARANTUJE, ZE JAKIEKOLWIEK BŁĘDY LUB WADY SERWISÓW ZOSTANĄ SKORYGOWANE.
            <br />
            <br />
            (f) GLOBALENGLISH NIE ODPOWIADA ZA ZACHOWANIE UŻYTKOWNIKÓW, TREŚĆ OSÓB TRZECICH ANI ZA TREŚĆ UŻYTKOWNIKÓW.
            <br />
            <br />
            (g) Serwisy mogą być okresowo czasowo niedostępne z powodu konserwacji lub z innych przyczyn. GlobalEnglish
            nie ponosi odpowiedzialności za jakiekolwiek przerwy, opóźnienia działania lub transmisji, kradzież lub
            zniszczenie, nieuprawniony dostęp lub zmianę, wiadomości Użytkownika ani dowolnej innej treści udostępnionej
            w ramach Serwisów. W żadnych okolicznościach GlobalEnglish nie będzie ponosić żadnej odpowiedzialności za
            jakikolwiek uszczerbek na zdrowiu lub śmierć wynikającą z korzystania z Serwisów, jakiejkolwiek Treści
            Użytkownika albo Treści Osoby Trzeciej lub korzystania z produktów udostępnianych albo usług świadczonych
            przez Użytkowników
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. OGRANICZENIE ODPOWIEDZIALNOŚCI</b>
          <br />
          <br /> W ŻADNYM PRZYPADKU GLOBALENGLISH, JEJ CZŁONKOWIE ZARZĄDU, PRACOWNICY, LICENCJODAWCY ANI PODWYKONAWCY
          NIE BĘDĄ PONOSIĆ WZGLĘDEM PAŃSTWA ANI JAKIEJKOLWIEK OSOBY TRZECIEJ ODPOWIEDZIALNOŚCI ZA ODSZKODOWANIA ZA
          SZKODY POŚREDNIE, WTÓRNE, ODSZKODOWANIA O CHARAKTERZE NAWIĄZKI, SZKODY PRZYPADKOWE, SZCZEGÓLNE ANI
          ODSZKODOWANIA RETORSYJNE, W TYM Z TYTUŁU UTRATY ZYSKÓW LUB UTRATY DANYCH WYNIKAJĄCE Z: (I) KORZYSTANIA PRZEZ
          PAŃSTWA LUB BRAKU MOŻLIWOŚCI KORZYSTANIA PRZEZ PAŃSTWA Z SERWISÓW LUB DOWOLNEJ TREŚCI LUB INNYCH MATERIAŁÓW NA
          STRONIE INTERNETOWEJ, DO KTÓRYCH UZYSKUJE SIĘ DOSTĘP LUB MOŻLIWOŚĆ POBRANIA ZE STRONY INTERNETOWEJ, SERWISÓW
          ALBO DOSTAWCÓW, (II) KOSZTÓW POZYSKANIA TOWARÓW I SERWISÓW ZASTĘPCZYCH WYNIKAJĄCE Z DOWOLNYCH TOWARÓW, DANYCH,
          INFORMACJI LUB USŁUG NABYTYCH LUB UZYSKANYCH ALBO WIADOMOŚCI OTRZYMANYCH LUB TRANSAKCJI ZAWARTYCH ZA
          POŚREDNICTWEM STRONY LUB ZE STRONY INTERNETOWEJ; (III) NIEUPRAWNIONEGO DOSTĘPU LUB ZMIANY PAŃSTWA TRANSMISJI
          LUB DANYCH PRZEZ DOWOLNĄ OSOBĘ; (IV) OŚWIADCZEŃ LUB ZACHOWANIA DOWOLNEJ OSOBY TRZECIEJ NA STRONIE
          INTERNETOWEJ; LUB (V) DOWOLNEJ INNEJ KWESTII DOTYCZĄCEJ USŁUG. W ŻADNYM WYPADKU ŁĄCZNE ZOBOWIĄZANIA
          GLOBALENGLISH WZGLĘDEM PAŃSTWA Z TYTUŁU WSZELKICH ODSZKODOWAŃ, STRAT LUB PRZYCZYN POWÓDZTWA NIE PRZEKROCZĄ
          KWOTY ZAPŁACONEJ PRZEZ PAŃSTWA GLOBALENGLISH W OKRESIE OSTATNICH DWUNASTU (12) MIESIĘCY LUB, JEŚLI JEST ONA
          WIĘKSZA, STU DOLARÓW (100 USD), NAWET, JEŚLI FIRMA GLOBALENGLISH POSIADA ŚWIADOMOŚĆ LUB ZOSTAŁA POINFORMOWANA
          O MOŻLIWOŚCI WYSTĄPIENIA TAKICH ODSZKODOWAŃ / STRAT. ISTNIENIE WIĘCEJ NIŻ JEDNEGO ROSZCZENIA ZGODNIE Z
          NINIEJSZYM DOKUMENTEM NIE ZWIĘKSZA TEGO LIMITU.
          <br />
          <br /> W PEWNYCH JURYSDYKCJACH DOKONYWANIE WYŁĄCZENIA OKREŚLONYCH GWARANCJI LUB OGRANICZANIE CZY WYŁĄCZANIE
          ODPOWIEDZIALNOŚCI ZA ODSZKODOWANIA Z TYTUŁU SZKÓD PRZYPADKOWYCH CZY WTÓRNYCH NIE JEST DOZWOLONE. W ZWIĄZKU Z
          TYM NIEKTÓRE OKREŚLONE TUTAJ OGRANICZENIA MOGĄ NIE MIEĆ ZASTOSOWANIA WZGLĘDEM PAŃSTWA.
          <br />
          <br /> JEŚLI SĄ PAŃSTWO NIEZADOWOLENI Z JAKIEJKOLWIEK CZĘŚCI STRONY INTERNETOWEJ LUB NINIEJSZYCH WARUNKÓW
          KORZYSTANIA ZE STRONY JEDYNYM I WYŁĄCZNYM ŚRODKIEM ZARADCZYM DLA PAŃSTWA JEST ZAPRZESTANIE KORZYSTANIA ZE
          STRONY INTERNETOWEJ.
          <br />
          <br /> W ŻADNYCH OKOLICZNOŚCIACH GLOBALENGLISH NIE BĘDZIE PONOSIĆ ODPOWIEDZIALNOŚCI ZA USZCZERBEK NA ZDROWIU
          ANI ŚMIERĆ OSÓB WYNIKAJĄCE Z KORZYSTANIA Z SERWISÓW LUB TREŚCI OSÓB TRZECICH ALBO TREŚCI UŻYTKOWNIKÓW.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14. PRZEDAWNIENIE </b>
          <br />
          <br />
          Wyrażają Państwo zgodę na to, aby, bez względu na postanowienia dowolnej ustawy lub prawa stanowiącego
          inaczej, istniał wymóg złożenia jakiegokolwiek roszczenia lub wystąpienia z powództwem przeciwko GlobalEnglish
          wynikających lub związanych z korzystaniem przez Państwa z Serwisów, w ciągu jednego (1) roku od powstania
          takiego roszczenia lub przyczyny powództwa pod rygorem przedawnienia.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>15. ZWOLNIENIE Z ODPOWIEDZIALNOŚCI </b>
          <br />
          <br />
          Zobowiązują się Państwo do zwolnienia, niepociągania do odpowiedzialności i obrony GlobalEnglish oraz jej
          jednostek stowarzyszonych oraz ich osób funkcyjnych, pracowników, członków zarządu oraz przedstawiciela przez
          jakimikolwiek roszczeniami, powództwami, kosztami, zobowiązaniami, stratami, odszkodowaniami, nakładami, z
          uwzględnieniem zasadnych honorariów prawników, wnoszonymi przeciwko GlobalEnglish przez dowolną osobę trzecią
          z powodu lub w wyniku albo w związku z korzystaniem przez Państwa ze strony internetowej, Serwisów, Państwa
          Treści Użytkownika, Państwa powiązaniem / połączeniem ze Stroną Internetową lub Serwisami, naruszeniem przez
          Państwa postanowień niniejszych Warunków lub naruszeniem przez Państwa dowolnych praw innego Użytkownika lub
          dowolnego prawa lub wynikających w innym trybie z korzystania przez Państwa w Serwisów. Obowiązki zwolnienia z
          odpowiedzialności obejmują, bez ograniczeń, roszczenia przeciwko GlobalEnglish, oparte na zarzucie, że
          jakakolwiek Treść Użytkownika narusza prawa własności intelektualnej osoby trzeciej.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>16. ZAKOŃCZENIE/ WYPOWIEDZENIE </b>
          <br />
          <br /> Jeśli subskrypcja zostanie zakończona/ wypowiedziana z dowolnego powodu, GlobalEnglish dezaktywuje
          Państwa dostęp do Serwisów. Nie będą Państwo w stanie uzyskać żadnego dostępu do jakiejkolwiek Treści, z
          uwzględnieniem Państwa Treści Użytkownika, chociaż kopie takich danych pozostają w naszym systemie przez okres
          trzech (3) lat w charakterze kopii zapasowej.
          <br />
          <br /> Jeśli zawrócą się Państwo do GlobalEnglish o usunięcie Państwa Danych Osobowych oraz Treści
          Użytkownika, dołożymy wszelkich zasadnych starań, aby tego dokonać i potwierdzimy, gdy usunięcie dojdzie do
          skutku.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>17. UCHYLENIE I ROZDZIELNOŚĆ POSTANOWIEŃ </b>
          <br />
          <br /> Niewykonanie albo niewyegzekwowanie przez GlobalEnglish jakiegokolwiek prawa lub postanowienia
          niniejszych Warunków nie stanowi uchylenia takiego prawa ani postanowienia w odniesieniu do tego lub dowolnego
          innego przypadku. Jeśli postanowienia niniejszych Warunków zostanie uznane za niezgodne z prawem, nieważne lub
          z dowolnego powodu niemożliwe do wyegzekwowania, stwierdzenie takie nie ma wpływu na ważność i możliwość
          wyegzekwowania dowolnego z pozostałych postanowień.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>18. CAŁOŚĆ UMOWY </b>
          <br />
          <br /> Niniejsza Umowa stanowi całość umowy pomiędzy Państwem a GlobalEnglish w zakresie korzystania z
          Serwisów oraz zastępuje wszelkie uprzednie ustalenia i umowy pomiędzy Państwem a GlobalEnglish dotyczące
          korzystania przez Państwa z Serwisów.
          <br />
          <br /> Bez uszczerbku dla powyższego, jeśli Państwa Spółka posiada aktualną Umowę z GlobalEnglish na zakup
          dowolnego Serwisu, niniejsze Warunki nie mają na celu zmiany warunków takiej umowy, jaką Państwa Spółka może
          posiadać z GlobalEnglish, jej spółkami zależnymi lub jednostkami stowarzyszonym oraz w zakresie jakiegokolwiek
          konfliktu, obowiązywać będą warunki takiej Umowy.
          <br />
          <br /> Niniejsza Umowa została sporządzona w języku angielskim i wersja taka będzie obowiązywać we wszelkich
          aspektach, a dowolna nieanglojęzyczna wersja wersji niniejszej Umowy jest sporządzona wyłącznie dla wygody.
          Wydrukowana wersja niniejszej umowy oraz wszelkie powiadomienia udzielone w formie elektronicznej są
          dopuszczalne w postępowaniu sądowym lub administracyjnym w ramach niniejszej Umowy lub gdy jej dotyczą w takim
          samym zakresie i podlegają takim samym warunkom jak inne dokumenty i ewidencja służbowa pierwotnie
          wygenerowane i prowadzone w formie wydrukowanej. Tytuły części niniejszych Warunków stosuje się wyłącznie dla
          wygody i nie rodzą skutków prawnych ani umownych.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            19. WYBÓR PRAWA, MIEJSCA PROWADZENIA SPORU I ARBITRAŻU{' '}
          </b>
          <br />
          <br />
          Niniejsze Warunki podlegają przepisom stanu Kalifornia bez względu na przepisy kolizyjne.
          <br />
          <br /> Z wyboru GlobalEnglish i według jej własnego uznania, wszelkie spory, roszczenia lub konflikty
          wynikające lub dotyczące niniejszych Warunków lub Strony Internetowej, które nie są rozstrzygnięte w drodze
          obopólnego porozumienia, mogą być rozstrzygnięte w drodze wiążącego arbitrażu, który przeprowadzą służby JAMS
          lub ich prawni następcy. O ile strony nie uzgodnią inaczej, arbitraż będzie prowadzony w San Francisco w
          Kalifornii przed jednym arbitrem wspólnie uzgodnionym przez strony lub jeśli strony nie mogą dojść do
          obopólnego porozumienia, jednym arbitrem wyznaczonym przez służby JAMS przy czym arbitraż będzie prowadzony
          zgodnie z zasadami i regulacjami określonymi przez JAMS, o ile nie zostały konkretnie zmienione w niniejszych
          Warunkach. Arbitraż musi rozpocząć się w terminie czterdziestu pięciu (45) dni od daty złożenia przez dowolną
          ze stron pisemnego żądania arbitrażu. Decyzja i orzeczenie arbitra zapadają w ciągu sześćdziesięciu (60) dni
          od daty zakończenia arbitrażu i w ciągu sześciu (6) miesięcy od wyboru arbitra. Arbiter nie będzie miał
          uprawnień do przyznania odszkodowania w wysokości przekraczającej limit rzeczywistych kompensacyjnych,
          bezpośrednich szkód określonych w Warunkach i nie może mnożyć rzeczywistych szkód ani przyznawać odszkodowania
          retorsyjnego ani żadnego innego odszkodowania, które zostało konkretnie wyłączone z niniejszych Warunków, a
          każda ze stron niniejszym nieodwołalnie uchyla dowolne roszczenia o takie odszkodowanie. Arbiter może, według
          własnego uznania, ocenić koszty i nakłady (z uwzględnieniem zasadnych opłat i wydatków prawnych strony
          wygrywającej) przeciwko dowolnej stronie postępowania. Dowolna strona odmawiająca podporządkowania się
          nakazowi arbitrów będzie odpowiadać za koszty i nakłady, z uwzględnieniem honorariów prawników, poniesionych
          przez drugą stronę przy egzekucji orzeczenia. Bez uszczerbku dla powyższego, w przypadku tymczasowego lub
          wstępnego zabezpieczenia roszczenia, dowolna ze stron może wystąpić do sądu bez uprzedniego arbitrażu w celu
          uniknięcia natychmiastowej i nieodwracalnej szkody. Postanowienia tego punktu o arbitrażu będą możliwe do
          wyegzekwowania w dowolnym sądzie właściwej jurysdykcji.
          <br />
          <br /> W odniesieniu do wszelkich sporów i roszczeń niepodlegające wyżej wymienionemu arbitrażowi, Państwo i
          GlobalEnglish zobowiązują się poddać osobistej i wyłącznej jurysdykcji sądów stanowych i federalnych
          znajdujących się w hrabstwie San Francisco w stanie Kalifornia.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. POWIADOMIENIA I MOŻLIWOŚCI KONTAKTU </b>
          <br />
          <br />
          Mogą się Państwo zwrócić o zamknięcie Państwa konta Serwisu GlobalEnglish przekazując GlobalEnglish
          powiadomienie o rozwiązaniu poprzez link do formularza “Contact Us” [Skontaktuj się z nami”] na stronie
          <a href="https://support.globalenglish.com/" rel="noopener noreferrer" target="_blank">
            https://support.globalenglish.com/
          </a>
          . Państwa dostęp oraz korzystanie z Serwisów, z uwzględnieniem dowolnych, zawartych tam treści, może zostać
          uniemożliwiony przez GlobalEnglish w dowolnym momencie po zamknięciu przez Państwa konta.
          <br />
          <br /> Powiadomienia mogą być przekazywane Państwu pocztą elektroniczną/ emailem lub pocztą standardową.
          Powiadomienia o zmianach w niniejszych Warunkach lub o innych sprawach mogą być również przekazywane przez
          stronę internetową poprzez wyświetlanie powiadomień lub linków do powiadomień o charakterze ogólnym
          dotyczących Serwisu.
          <br />
          <br /> Powiadomienia dotyczące naszej polityki prywatności można przesyłać na adres:
          <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
            privacy@globalenglish.com.
          </a>
          <br />
          <br /> Wszelkie inne powiadomienia o charakterze prawnym lub inną korespondencję z GlobalEnglish należy
          kierować na następujący adres poczty elektronicznej:
          <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
            legal@globalenglish.com
          </a>{' '}
          lub poniższy adres fizyczny:
          <br />
          <br /> 1875 S Grant Street, Suite 700
          <br /> San Mateo, CA 94402
          <br /> do wiadomości
          <b>Radcy Prawnego </b>
          <br />
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>WARUNKI SZCZEGÓLNE DLA TRANSAKCJI B2B </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          {' '}
          Jeśli uzyskają Państwo swoje konto w GlobalEnglish za pośrednictwem swojego pracodawcy, korzystanie przez
          Państwa z naszych Serwisów również podlega postanowieniom naszej umowy z Państwa spółką („Spółką”). „Warunki
          korzystania z serwisów” nie mają służyć doprowadzeniu do zmiany warunków umowy z Państwa Spółką. Nie mogą
          Państwo korzystać (ani kontynuować korzystania) z Serwisów, jeśli Państwa Spółka nie autoryzuje Państwa jako
          użytkownika końcowego na jej licencji z GlobalEnglish. Jeśli zwrócą się Państwo do nas o zamknięcie Państwa
          konta, może to wymagać udzielenia zgody przez Państwa Spółkę, po otrzymaniu której GlobalEnglish zamknie
          Państwa konto.
          <br />
          <br />
          Zobowiązują się Państwo do korzystania z Serwisów wyłącznie w zakresie dozwolonym przez Państwa Spółkę. Mogą
          Państwo mieć okazję do komunikowania się z innymi osobami w swojej Spółce lub wprowadzania dokumentów
          dotyczących Państwa Spółki za pośrednictwem Serwisów, ale nie mają Państwo na to zezwolenia, ani nie mogą
          Państwo tego zrobić, bez posiadania autoryzacji Państwa Spółki.
          <br />
          <br /> Przyjmują Państwo do wiadomości i wyrażają zgodę na to, aby wszystkie dane generowane w związku z
          korzystaniem przez Państwa z Serwisów („Dane Użytkownika”) mogły być udostępniane Państwa Spółce oraz aby
          GlobalEnglish nie ponosiła żadnej odpowiedzialności wobec Państwa w związku z informacjami udostępnionymi
          Państwa Spółce w wyniku korzystania przez Państwa z naszych Serwisów.
          <br />
          <br />Z uwagi na fakt, że Państwa subskrypcja na dostęp do Serwisów działa przez okres obowiązywania licencji
          Spółki, jeśli Państwa Spółki zakończy swoją licencję z GlobalEnglish, utracą Państwo dostęp do Serwisów oraz
          do Treści Użytkownika. GlobalEnglish może zamknąć lub zawiesić konto Państwa spółki, Państwa konto lub
          możliwość korzystania z Serwisów, w całości lub w części, bez powiadomienia w przypadku, gdy (i) Państwa
          Spółka nie zapłaci jakiejkolwiek należnej opłaty na mocy niniejszej Umowy na rzecz GlobalEnglish, gdy przypada
          do zapłaty lub (ii) Państwo albo Państwa Spółka naruszy Warunki lub naruszy dowolne inne zasady, którym
          podlega korzystanie z Serwisów.
          <br />
          <br /> GlobalEnglish może być objęta obowiązkiem zwrócenia wszystkich danych dotyczących korzystania przez
          Państwa z Usług, z uwzględnieniem Państwa Danych Osobowych i Treści Użytkownika do Państwa spółki. Jeśli
          Państwa spółka nie wystąpi o zwrócenie do niej lub usunięcie takich danych, GlobalEnglish przechowuje takie
          dane przez okres trzech (3) lat po wygaśnięciu lub zakończeniu jej umowy z Państwa pracodawcą. W takich
          przypadkach zachęca się Państwa do nabycia subskrypcji na takie usługi bezpośrednio od GlobalEnglish i
          kontynuowania Państwa dostępu do programu bez utraty informacji o postępach. Mogą Państwo dokonać autoryzacji
          przesłania informacji o Państwa postępów i profilu do nowej subskrypcji u Państwa nowego pracodawcy. Mogą
          Państwo zwrócić się o wszystkie dane dotyczące korzystania przez Państwa z Serwisów w formacie kompatybilnym z
          przyszłym importowaniem danych.
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>WARUNKI SZCZEGÓLNE DLA TRANSAKCJI B2C </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          Istnieje również możliwość zawarcia umowy subskrypcji z GlobalEnglish jako osoba fizyczna, co może obejmować
          subskrypcję na trzy (3) lub sześć (6) miesięcy lub plan subskrypcji rocznej lub wieloletniej (każda „Plan
          Subskrypcji”).
          <br />
          <br /> Będą Państwo rozliczani w trybie miesięcznym lub rocznym za obowiązujący Plan Roczny w tym samym dniu
          każdego miesiąca/ roku (w zależności od okoliczności) lub w przybliżeniu w tym terminie to czasu anulowania
          Planu Subskrypcji. Celem rozwiania wątpliwości, prosimy o zwrócenie uwagi, że nie będą mieli Państwo
          możliwości anulowania, zredukowania liczby miejsc ani obniżenia poziomu Serwisu, który Państwo wybrali do
          rocznicy Państwa Planu Subskrypcji. Nie dokonuje się zwrotów z tytułu płatności w ramach Planu Subskrypcji.
          <br />
          <br /> Opłaty obowiązujące dla Serwisu („Opłaty”) są dostępne na Stronie Internetowej i/lub w opublikowanym
          cenniku GlobalEnglish aktualnym na dany moment. Cena podana dla Serwisu nie obejmuje żadnych podatków ani
          opłat, chyba, że określono inaczej. Odpowiadają Państwo za wszelkie podatki i za inne opłaty związane z
          wykorzystaniem Serwisu (na przykład, opłaty z tytułu transmisji danych oraz rozliczenia wymiany walutowej).
          Będą Państwo uiszczać Opłaty w walucie GlobalEnglish podawanej dla Państwa konta. GlobalEnglish zastrzega
          sobie prawo do zmiany, w dowolnym momencie, podawanej waluty.
          <br />
          <br />
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}> Płatność </b>
          <br />
          <br /> Terminem płatności jest moment zakupu licencji subskrypcji. Nawiązaliśmy relację partnerską z serwisami
          PayPal i Stripe w celu obsługi Państwa płatności. Nie otrzymujemy ani nie przetwarzamy żadnych informacji
          dotyczących kart kredytowych ani innych metod płatności, które udostępniają Państwo tymże usługodawcom.
          <br />
          <br /> Wszystkie Serwisy są przedpłacone na określony okres (miesiąca, roku lub inny) i są bezzwrotne.
          Obejmuje to konta, które są przedłużone.
          <br />
          <br /> -----
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>Informacja dla Użytkowników z Kalifornii </b>
          <br />
          <br /> Jeśli są Państwo mieszkańcami Kalifornii, punkt 1789.3 Kodeksu Cywilnego stanu Kalifornia pozwala
          Państwu zwracać się o informacje dotyczące ujawnienia Państwa Danych Osobowych osobom trzecim do bezpośrednich
          celów marketingowych osób trzecich. Ustawa o Prywatności Konsumentów stanu Kalifornia [The California Consumer
          Privacy Act], która wejdzie w życie w roku 2021 (punkty 1798.100 - 1709.198 Kodeksu Cywilnego) również daje
          Państwu pewne prawa:
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            (a) prawo do wiedzy, które Dane Osobowe na Państwa temat zostały zebrane oraz otrzymania ich kopii,
            <br />
            <br />
            (b) prawo do usunięcia,
            <br />
            <br />
            (c) prawo do informacji, którym osobom trzecim sprzedano Państwa Dane Osobowe oraz prawo do zażądania, aby
            taką sprzedaż przerwać oraz
            <br />
            <br />
            (d)prawo do braku dyskryminacji w przypadku wyegzekwowania niektórych swoich praw konsumenta.
          </span>
          <br /> Ponadto punkt 22581 Kodeksu Zawodowego i Prowadzenia Działalności stanu Kalifornia zezwala obecnie
          mieszkańcowi Kalifornii, który jest osobą niepełnoletnią, na zażądanie usunięcia treści, którą umieszczono
          jako dostępną publicznie na Stronie Internetowej. Należy jednak mieć na uwadze, że GlobalEnglish nie może
          zapewnić pełnego i kompleksowego usunięcia zamieszczonych przez Państwa treści oraz że mogą wystąpić
          okoliczności, w których prawo nie wymaga ani nie pozwala na to nawet, jeśli wystąpią Państwo z takim żądaniem.
          <br />
          <br />
          Jeśli chcą Państwo złożyć wniosek umożliwiający wyegzekwowanie Państwa praw:
          <br />
          <br /> Dostawcą Strony Internetowej oraz usług powiązanych jest GlobalEnglish Corporation, z siedzibą pod
          adresem: 1875 South Grant Street, Suite 700, San Mateo, CA 94402, USA. Adres e-mail:
          privacy@globalenglish.com.
          <br /> Z datą niniejszych Warunków Strona Internetowa nie nakłada się bezpośrednich obciążeń na konsumenta. W
          przypadku reklamacji usług świadczonych na Stronie Internetowej lub życzenia złożenia wniosku o dalsze
          informacje, prosimy o kontakt pod powyższym adresem fizycznym z opisem Państwa prośby/ żądania. Jeśli nadal
          nie będą Państwo usatysfakcjonowani prosimy o kontakt z Jednostką ds. Pomocy przy Składaniu Reklamacji Pionu
          Usług Konsumenckich w formie pisemnej na adres: 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834 lub
          telefonicznie pod nr (916) 445-1254 lub (800) 952-5210.
        </p>
      </div>
    </div>
  );
}

export default PlPlContent;
