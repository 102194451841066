// @ts-nocheck
import React, { Component } from "react";
import { ErrorPage } from "../../bundles/common/components/ErrorPage";

class ErrorContainer extends Component<any, any> {
  render() {
    return <ErrorPage />;
  }
}

export default ErrorContainer;
