// @ts-nocheck
/* eslint-disable */

import React, { useEffect, useState } from "react";
import { BasicSkeleton } from "bundles/common/components/BasicSkeleton"
import "./main.css"
import { ButtonField } from "bundles/common/components/ButtonField";
import { ClimbingBoxLoader, RotateLoader } from "react-spinners";
import PulseLoader from "react-spinners/PulseLoader";
import Timer from "containers/OTPVerfiy/timer";
import { useParams } from "react-router-dom";
import { getUserDetails, resendOTP, validateOTP } from "containers/OTPVerfiy/API";
import { datadogLogs } from "@datadog/browser-logs";

export default function OTPVerify(props: any) {
    const { username, email } = useParams()
    const [otp, setOtp] = useState({})
    const [enable, setEnable] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [screenLoading, setScreenLoading] = useState(false)
    const [resendDisable, setResendDisable] = useState(true)
    const [UserData, setUserData] = useState()
    const [error, setError] = useState("")
    const [buttonValue, setButtonValue] = useState("Verify")
    const url = "https://r6jnit71zf.execute-api.eu-central-1.amazonaws.com/dev/validateOTP"
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const encodedCookies = urlParams.get('cookies');
        if(encodedCookies){
            const rootElement = document.getElementById("root");
            const otpContainerElement = rootElement.querySelector("div");
            otpContainerElement.style.display='none'

            const cookiesJSON = decodeURIComponent(encodedCookies);
            const cookiesArray = JSON.parse(cookiesJSON);
            cookieSetter(cookiesArray)
            const productRedirectionURL=urlParams.get('redirect_url');
            setTimeout(() => {
                window.location.href=decodeURIComponent(productRedirectionURL)
            }, 1000);
        }
        setFocus(0)
    }, [])
    const cookieSetter = (cookies) => {
        for (let cookie of cookies) {
            console.log(cookie)
            document.cookie = cookie
        }
    }
    const handleKeydown = (e) => {

        switch (e.keyCode) {
            case 39: {
                if (e.target.id < 5) {
                    setFocus(Number(e.target.id) + 1)
                }
                break;
            }

            case 37: {
                if (e.target.id > 0) {
                    setFocus(Number(e.target.id) - 1)
                }
                break;
            }
        }
    }
    const resendCode = () => {
        setIsLoading(true)
        setError("")
        setResendDisable(true)
        resendOTP({ username: username, email: email }).then(json => json.json()).then((res) => {
            console.log(res)
            if (res.headers?.Location?.includes("NO_SUCH_USER")) {
                cookieSetter(res["cookies"])
                window.location.href = res.headers.Location
            }
            setIsLoading(false)
            if (res.status == "OTP_SENT") {
                setButtonValue("Code sent")
                setTimeout(() => {
                    setButtonValue("Verify")
                    setResendDisable(false)
                }, 3000)
            }
            if (res.status == "EXHAUSTED_ATTEMPTS") {
                setError("You have exhausted the attempts to receive otp, please try again after 24hrs")
            }

        })
    }

    const onSubmit = () => {
        setIsLoading(true)
        setError("")
        let joinedotp = Object.values(otp).join("")
        console.log(joinedotp, { otp: joinedotp, username: username })
        validateOTP({ otp: joinedotp, username: username, email: email }).then((response) => response.json()).then((res) => {
            console.log("Validated resp", JSON.stringify(res));

            if (res.body?.err) {
                setError(res.body.err)
            }
            if (res.err) {
                setError(res.err)
            }
            if (res.message == "Internal Server Error") {
                // alert("Something went wrong, please try again...")
            }
            if (res && res.location && res.cookies) {
                console.log("cookies",res.cookies);
                console.log("location",res.location);
                
                setButtonValue("Verified")
                cookieSetter(res.cookies)
                window.location.href = res.location
            }
            if (res.headers?.Location?.includes("error_type")) {
                window.location.href = res.headers.Location
            }
        }).catch((err) => {
            console.error(err)
            datadogLogs.logger.error('OTP Verify', err);
        }).finally(() => {
            setIsLoading(false)
        })

    }
    const sizeChecker = (val) => {
        let keys = Object.keys(val)
        if (keys.length < 6) {
            return false
        }
        return keys.every((num) => {
            return val[num] != undefined && val[num] != ''
        })
    }
    const setFocus = (n) => {
        document.getElementById(n).focus()
    }
    const valueChecker = (e) => {
        console.log(e.target, e.target.value, e.target.id, otp, e.keyCode)
        e.target.value = e.target.value.trim()
        if (e.target.value.length == 6 || e.target.value.length == 7) {
            console.log({ ...e.target.value.split("") })
            setOtp({ ...e.target.value.trim().split("") })
            setEnable(true)
        }
        if (e.target.value >= 0 && e.target.value <= 9) {
            let t = otp
            t[e.target.id] = e.target.value
            setEnable(sizeChecker(t))
            if (e.target.id < 5 && e.target.value != '') {
                setFocus(Number(e.target.id) + 1)
            }

            setOtp({ ...t })
        }
    }
    if (screenLoading) {
        return <div style={{
            position: "absolute",
            top: "40%",
            left: "48%",

        }}>
            <ClimbingBoxLoader color="black" size="30" />
            &nbsp; &nbsp;&nbsp;&nbsp; Loading...
        </div>

    }
    return (
        <>
            <div className="otp-container" style={{
                background: "#333",
                height: "65vh",
                padding: 0,
            }}>
                <div style={{
                    background: " rgb(51, 51, 51)",
                    height: "5vh",
                    position: "relative",
                    top: "10vh",
                    padding: "3vh"
                }}></div>
                <div style={{
                    position: "relative",
                    top: "10vh"
                }}>
                    <h1
                    style={{
                        "text-align": "center",
                        font: "normal normal bold 40px/60px Poppins",
                        "letter-spacing": "0px",
                        color: "#FFFFFF"
                    }}>Welcome {username} !</h1>
                </div>
                {/* otp box */}

                <div style={{
                    background: "rgb(255, 255, 255)",
                    height: "40vh",
                    width: "35vw",
                    position: "relative",
                    top: "16vh",
                    left: "32.5vw"
                }}>
                    <div style={{
                        "text-align": "center",
                        "letter-spacing": "0px",
                        color: "#333333",
                        position: "relative",
                        top: "5vh",
                        fontSize: "14px",
                        font: "normal normal medium 14px/19px Noto Sans"
                    }}>
                        Enter the code sent to
                        <b
                        > {email}</b>
                    </div>
                    {/* <div style={{
                height:"3vh",
                background: "#F0F0F0 0% 0% no-repeat padding-box",
                width:"4vw",
                borderRadius:"3px",
                "text-align": "center",
                position:"relative",
                top:"8vh",
                left:"15vw"
            }}>
<b style={{"text-align": "center"}}><Timer disable={()=>setResendDisable(false)} /></b>

            </div> */}
                    <div style={{
                        display: "inline-block",
                        textAlign: "center",
                        alignItems: "center",
                        position: "relative",
                        top: "11vh",
                        left: "10.5vw"
                    }}>
                        <input className="otpBox" type="string" id="0" onChange={valueChecker} value={otp[0]} onKeyDown={handleKeydown} />
                        <input className="otpBox" type="string" id="1" onChange={valueChecker} value={otp[1]} onKeyDown={handleKeydown} />
                        <input className="otpBox" type="string" id="2" onChange={valueChecker} value={otp[2]} onKeyDown={handleKeydown} />
                        <input className="otpBox" type="string" id="3" onChange={valueChecker} value={otp[3]} onKeyDown={handleKeydown} />
                        <input className="otpBox" type="string" id="4" onChange={valueChecker} value={otp[4]} onKeyDown={handleKeydown} />
                        <input className="otpBox" type="string" id="5" onChange={valueChecker} value={otp[5]} onKeyDown={handleKeydown} />
                    </div>
                    <div className="Error" style={{
                        color: "#E40000", textAlign: "center", alignItems: "center",
                        position: "relative", top: "12vh",
                        font: "normal normal normal 12px/17px Noto Sans"
                    }}>
                        <b>{error}</b>
                    </div>
                    <div style={{
                        position: "relative", top: "13vh", textAlign: "center", alignItems: "center",
                    }}>
                        <h4>
                            <b>Didn’t receive the code? <b style={{
                                color: "lightblue",
                                cursor: "pointer"
                            }}
                                onClick={resendCode}
                            >Resend code</b></b>
                        </h4>
                    </div>
                    <div style={{
                        position: "relative",
                        top: "11vh",
                        margin: "4vw"
                    }}>
                        <ButtonField
                            value={isLoading ?
                                <PulseLoader color="black" /> : <div style={{ color: "black" }}>{buttonValue}</div>}
                            id="btnLogin"
                            type="submit"
                            name="yt0"
                            disableBtn={!enable}
                            onClickFn={onSubmit}
                        //   isLoading={props.error}

                        />
                    </div>

                    {/* <button
          disabled={!enable}
          style={{background: "#42BABD 0% 0% no-repeat padding-box",
         
            height:"6vh",
            width:"30vw",
            textAlign:"center",
            color:"#333333",

        }}
          >Verify</button> */}
                </div>


            </div>


        </>
    )
}