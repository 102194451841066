// @ts-nocheck
import React from "react";

function FrFrContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>Copyrights, marques et autres annonces légales</b>
          </center>
        </div>
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>COPYRIGHT GLOBALENGLISH</b>
          </p>
          <p />
          <p>
            {" "}
            Copyright © 1999-2013 GlobalEnglish Corporation, tous droits
            réservés{" "}
          </p>
          <br />
          <p>
            <b>COPYRIGHTS ÉDITEURS TIERS</b>{" "}
          </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>, première édition, 1995
            <br /> © Longman Group UK Limited 1995{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> © Longman Group Limited 1995{" "}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p*/}
          <p>
            {" "}
            Copyright © 1995 - 1998 Macromedia, Inc., tous droits réservés{" "}
          </p>
          <p> Copyright © 1999 Microsoft Corporation, tous droits réservés </p>
          <p> Copyright © 1999 Sipro Lab Telecom Inc., tous droits réservés </p>
          <p>
            {" "}
            Copyright © Business English Pod Limited, tous droits réservés. Tous
            les contenus, notamment les podcasts, guides des expressions,
            podcasts vidéo, notes explicatives et livres électroniques audio,
            sont la propriété de Business English Pod Limited et sont utilisés
            avec autorisation par GlobalEnglish Corporation.{" "}
          </p>
          <p>
            {" "}
            WGrammar Grammar Checker Engine Copyright © 1998-2006 Wintertree
            Software Inc. ({" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.wintertree-software.com"
            >
              <div
                onmouseout="this.className='blu11'"
                onmouseover="this.className='blu11rllover'"
                className="blu11"
              >
                www.wintertree-software.com
              </div>
            </a>
            ){" "}
          </p>
          <p>
            {" "}
            Intègre le logiciel de reconnaissance vocale EduSpeak<sup>
              ®
            </sup>{" "}
            sous licence de SRI International
            <sup>®</sup>. Copyright© SRI International.{" "}
          </p>
          {/*p> Systran Translation Engine&copy; SYSTRAN Software, Inc. </p*/}
          <p>
            {" "}
            Les photos fournies par AP/Wide World Photos sont protégées par
            copyright et sont la propriété d'Associated Press. Toute utilisation
            n'ayant pas fait l'objet d'une autorisation préalable d'AP/Wide
            World Photos est interdite. Toute infraction pourra entraîner des
            poursuites judiciaires.{" "}
          </p>
          <p>
            {" "}
            Les photos fournies par PhotoDisc sont protégées par copyright et
            sont la propriété de PhotoDisc, Inc. Images © copyright 1999
            PhotoDisc, Inc.{" "}
          </p>
          <p>
            {" "}
            Copyright © Barron's Educational Series, Inc., extrait de HOW TO
            PREPARE FOR THE TOEIC<sup>®</sup>{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i> © 1998 AMACOM NEW MEDIA, une filiale
            appartenant exclusivement à l'American Management Association, New
            York. Tous droits réservés.{" "}
          </p>
          <p>
            {" "}
            Les clip arts fournis par Microsoft sont protégés par copyright et
            sont la propriété de Microsoft Corporation et/ou de ses
            fournisseurs. Toute utilisation n'ayant pas fait l'objet d'une
            autorisation préalable de Microsoft est interdite.{" "}
          </p>
          <p>
            {" "}
            Les cliparts fournis par ArtToday sont protégés par copyright et
            sont la propriété de Zedcor, Inc., une filiale appartenant
            exclusivement à IMSI Inc. et/ou de ses fournisseurs. Toute
            utilisation n'ayant pas fait l'objet d'une autorisation préalable de
            Zedcor est interdite.{" "}
          </p>
          <p>
            {" "}
            Les cliparts fournis par Eyewire sont protégés par copyright et sont
            la propriété de Eyewire Inc. et/ou de ses fournisseurs. Toute
            utilisation n'ayant pas fait l'objet d'une autorisation préalable de
            Eyewire est interdite.{" "}
          </p>
          <p>
            {" "}
            Les photographies fournies par Corbis sont protégées par copyright
            et sont la propriété de Corbis Corporation et/ou de ses
            fournisseurs. Toute utilisation n'ayant pas fait l'objet d'une
            autorisation préalable de Corbis est interdite.{" "}
          </p>
          <p>
            {" "}
            Les photographies fournies par ImageQuest sont protégées par
            copyright et sont la propriété d'ImageQuest et/ou de ses
            fournisseurs. Toute utilisation n'ayant pas fait l'objet d'une
            autorisation préalable d'ImageQuest est interdite.{" "}
          </p>
          <p />
          <br />
          <p>
            <b>Marques de commerce de GlobalEnglish</b>
          </p>
          <p />
          <p>
            Les marques commerciales suivantes div partie des marques détenues
            par GlobalEnglish Corporation. Les termes génériques utilisés pour
            ces marques sont également déposés aux États-Unis et/ou dans
            d'autres pays. L'absence de nom ou de logo dans la liste ne
            constitue une renonciation à aucun droit de propriété
            intellectuelle, intégralement ou en partie, que GlobalEnglish
            Corporation ou ses filiales ont établi sur des produits,
            caractéristiques, noms de service ou logos. Pour savoir comment
            nommer correctement les produit et marques commerciales de
            GlobalEnglish, il vous suffit de demander un exemplaire des
            directives générales relatives aux marques commerciales de
            GlobalEnglish.
          </p>
          <p />
          <br />
          <style
            dangerouslySetInnerHTML={{
              __html: " \t\ttd{padding:10px !important} \t\t",
            }}
          />
          <table
            border={1}
            cellPadding={10}
            cellSpacing={0}
            bordercolor="#808080"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  TradeMark
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Status
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  Enterprise Fluency
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Bloom
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Corporate Learning Service
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Edge
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo Pro
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Magazine
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Mobile Learning Network
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Productivity Toolbar
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP+
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Motivator
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Executive Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>MARQUES COMMERCIALES DES ÉDITEURS TIERS</b>
          </p>
          <p />
          <p>
            {" "}
            Adobe et Flash sont des marques commerciales ou déposées d’Adobe
            Systems Incorporated aux États-Unis et/ou dans d’autres pays
          </p>
          <p> Microsoft est une marque déposée de Microsoft Corporation. </p>
          {/*p> Langenscheidt KG est une marque d&#233;pos&#233;e de Langenscheidt KG, Berlin and Munich. </p*/}
          <p>
            {" "}
            Living Language et Colophon sont des marques déposées de Random
            House, Inc.{" "}
          </p>
          <p>
            {" "}
            Sipro et le logo de Sipro sont des marques de Sipro Lab Telecom Inc.
            ACELP<sup>®</sup> est une marque déposée de l'Université de
            Sherbrooke, utilisée sous licence de Sipro Lab Telecom Inc.{" "}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International, EduSpeak et leurs logos respectifs sont des
                  marques de commerce ou des marques déposées de SRI
                  International.
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p> SYSTRAN est une marque d&#233;pos&#233;e ou une marque de service de SYSTRAN Software, Inc., et le logo SYSTRAN est une marque de service d&#233;pos&#233;e de SYSTRAN Software, Inc. </p*/}
          <p> AP/Wide World Photos est une marque d'Associated Press. </p>
          <p> PhotoDisc est une marque déposée de PhotoDisc, Inc. </p>
          <p>
            {" "}
            Business English Pod, www.businessenglishpod.com, et les logos de
            Business English Pod sont des marques de Business English Pod
            Limited.{" "}
          </p>
          <p>
            {" "}
            Toutes les autres marques appartiennent à leurs propriétaires
            respectifs.{" "}
          </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default FrFrContent;
