import React from "react";

export default function Popup({ text, children, removePopup }) {
  return (
    <div
      id="popup"
      className="popup"
      style={{
        position: "fixed",
        right: "0%",
        left: "0%",
      }}
    >
      <div className="lightbox"></div>
      <div className="overlay">
        <div className="olHolder">
          <div className="olClose">
            <span onClick={removePopup}></span>
          </div>
          <div className="olContainer">
            <div id="popup_content" className="olContent">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
