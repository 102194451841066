// @ts-nocheck
import React from "react";

export const GDPRPolicyPopup = (props) => (
  <div id="popup" className="popup" style={{ zIndex: 100 }}>
    <div className="lightbox" />
    <div className="overlay">
      <div className="olHolder" style={{ position: "fixed", top: "15%" }}>
        <div className="olClose">
          <span onClick={props.closeGDPRPolicy} />
        </div>
        <div className="olContainer">
          <div id="popup_content" className="olContent">
            <div
              className="olPrint"
              style={{
                cursor: "pointer",
              }}
            ></div>
            <div id="agreement_container">
              <div id="PrivacyPolicyDiv">
                <strong>
                  <center>GLOBALENGLISH COOKIE POLICY</center>
                </strong>
                <div
                  id="cookienew"
                  style={{
                    display: "block",
                  }}
                  className="cpolicynew"
                >
                  <center>
                    <strong>TYPES OF COOKIES</strong>
                  </center>
                  <p>There are two categories of Cookies:</p>
                  <p>
                    <strong>NECESSARY COOKIES</strong>
                  </p>
                  <p>
                    These cookies are essential in order to enable you to move
                    around websites or online properties and use their features,
                    such as accessing secure areas of a website. Without these
                    cookies, GlobalEnglish cannot provide services you have
                    asked for, like secure login areas.
                  </p>
                  <p>
                    <strong>PERFORMANCE (OR ANALYTICS) COOKIES</strong>
                  </p>
                  <p>
                    These cookies collect information about how visitors use a
                    website or online property, for instance which pages
                    visitors go to most often, and if they get error messages
                    from web pages. These cookies don’t collect information
                    about the actual identity (e.g. first and last name) of a
                    visitor. All information these cookies collect is aggregated
                    and therefore anonymous. They are only used to improve how a
                    website or other online property works. We generally
                    contract with independent research and measurement
                    companies, such as Google Analytics, to provide these
                    services on our behalf and these third parties also have
                    their own privacy policies, in addition to data processing
                    agreements with us. For further information about Google
                    Analytics, and for links to Google’s Privacy Policy and an
                    opt-out tool for Google Analytics, go to
                    <a
                      href="http://www.google.com/intl/en/analytics/privacyoverview.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.google.com/intl/en/analytics/privacyoverview.html.
                    </a>
                    By using this Site you agree we may place these types of
                    cookies on your device.
                  </p>
                  <p>
                    <strong>FUNCTIONALITY COOKIES</strong>
                  </p>
                  <p>
                    These cookies allow the website or online property to
                    remember choices you make (such as your user name, language
                    or the region you are in) and provide enhanced, more
                    personal features. These cookies can also be used to
                    remember changes you have made to text size, fonts and other
                    parts of web pages that you can customize. They may also be
                    used to provide services you have asked for such as watching
                    a video. The information these cookies collect may be
                    anonymized and they cannot track your browsing activity on
                    other websites.
                  </p>
                  {/*  <p>
                                            <strong>ADVERTISING COOKIES</strong>
                                            </p>
                                            <p>These cookies are used to deliver adverts more relevant to you and your interests. They also help limit the number
                                            of times you see an advertisement as well as measure the effectiveness of the advertising campaign. They
                                            are usually placed by advertising networks with the website or online property operator’s permission. They
                                            remember that you have visited a website and this information is shared with other organizations such as
                                            advertisers. Quite often advertising cookies will be linked to site functionality provided by the other organization.
                                            In certain countries GlobalEnglish may partner with third party online advertising and analytics companies
                                            to tailor and deliver advertisements, products, services, and promotions on GlobalEnglish Properties, as
                                            well as other online properties which we do not control. To help select the ads of greatest interest to you,
                                            these companies may use cookies, Flash technology, and similar technologies to collect and track information
                                            such as demographic data (e.g., gender, occupation, etc.), aggregated information, search keywords, geo-location
                                            and device-related data (for example, browser type, language, IP address, time/date) to select advertisements
                                            that are more relevant to your interests, to better personalize the experience for you and tailor advertisements.
                                            In some instances, third-parties may also use cookies or similar technologies to collect anonymized or device-related
                                            information about your visits to GlobalEnglish Properties in order to provide third party advertisements
                                            about goods and services likely to be of greater interest to you.
                                            </p>
                                            <p>To learn more about Advertising Cookies visit the Network Advertising Initiative (NAI) at
                                            <a href="http://networkadvertising.org" rel="noopener noreferrer" target="_blank">http://networkadvertising.org</a> or
                                            <a href="http://youronlinechoices.com" rel="noopener noreferrer" target="_blank">http://youronlinechoices.com</a>
                                            </p>
                                            GlobalEnglish may use and share anonymized or aggregated information obtained from cookies with third parties such as Vendors
                                            or advertising partners. An example of the use of such information would be creating interest categories or segments
                                            based on region, preferences, or a combination. We may also share personal and non-personally identifiable information
                                            obtained from cookies with third parties such as Vendors or advertising networks in order to permit them to provide
                                            services on our behalf or to partner with us in selecting and serving advertisements for us or our advertising
                                            partners.
                                            <p>
                                            <strong>Social Network Sites</strong>
                                            </p>
                                            <p>If you have signed in to a social networking site, such as Facebook, it may have set a cookie on your device
                                            which recognizes you when you view content on our websites and other online properties. These cookies allow
                                            you to share content across social media via the use of sharing settings. See your social networking site’s
                                            terms of use and privacy policy for details.</p>
                                            <p> */}
                  <strong>More Information</strong>
                  <p />
                  <p>
                    If you would like to find out more about cookies you may
                    find the following link useful:
                    <a
                      href="http://www.allaboutcookies.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://www.allaboutcookies.org/
                    </a>
                  </p>
                  <p>
                    <strong>Managing Cookies &amp; Do Not Track Options</strong>
                  </p>
                  <p>You can review your options to manage cookies by:</p>
                  <ul>
                    <li>
                      by clicking on the opt out links provided in the Cookie
                      List below
                    </li>
                    <li>
                      by opening the “Help” menu on the browser menu bar. Most
                      browsers will tell you how you can disable or delete
                      cookies or set your browser to alert you when cookies are
                      being sent to your computer.
                    </li>
                    <li>
                      visit
                      <a
                        href="http://networkadvertising.org/managing/opt_out.asp"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        http://networkadvertising.org/managing/opt_out.asp
                      </a>{" "}
                      and/or
                      <a
                        href="http://youronlinechoices.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        http://youronlinechoices.com
                      </a>{" "}
                      for choices to opt out of advertising
                    </li>
                  </ul>
                  <p>
                    If you reject all cookies, you may not be able to use
                    certain features of our websites and other products and
                    services (including sign in).
                  </p>
                  <p>
                    <b>Permission to Use Cookies: </b>By using GlobalEnglish
                    sites you agree, and we will assume you consent, to receive
                    cookies unless you manage your cookie choices by one of the
                    means set out above.
                  </p>
                  {/* <p>Please also note that the use of information obtained by third party providers listed below through cookies placed
                                            by them is also subject to their own privacy policies. Information collected by cookies may be associated
                                            with your registration account or user profile.</p> */}
                  <p>
                    <b>Flash cookies, web beacons, IP addresses: </b>
                    Adobe Flash Player is an application that allows sites to
                    deploy dynamic web content. Adobe Flash and similar
                    applications use technology to remember settings,
                    preferences, and usage in ways that are similar to browser
                    cookies, but are managed through a different interface than
                    your web browser. You can access your Flash management tools
                    from
                    <a
                      href="http://www.adobe.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://www.adobe.com/{" "}
                    </a>
                    directly to manage your cookie settings. From time to time,
                    we may use web beacons (or clear GIFs) on our websites or
                    other online properties or include them in the e-mail
                    messages we send you. Web beacons (also known as "web bugs")
                    are small strings of code that provide a method of
                    delivering a graphic image on a web page or in an email
                    message for the purpose of transferring data. We use web
                    beacons for many purposes, including site traffic reporting,
                    unique visitor counts, advertising and email auditing and
                    reporting, and personalization. We also keep track of
                    Internet Protocol (IP) addresses. An IP address is a number
                    that is used by computers on the network to identify your
                    computer every time you log on to the Internet.
                  </p>
                  {/* <p>
                                            <b>Cookies List: </b>Your GlobalEnglish site may contain the following third-party Performance (or Analytics) and Advertising cookies. Please follow the links below for details of the Privacy Policies and Opt-Out Choices offered by these third parties. Please also see the Cookies section above for further options available to manage cookies.
                                            </p>
                                            <p><b>Third-Party Privacy Policies and Opt-Out Choices</b>
                                            <a href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer" target="_blank">Goggle</a>
                                            </p>
                                            <p>Apps for mobile phones and tablets: <a href="https://www.ghosteryenterprise.com/mobile-app-privacy-statement/" rel="noopener noreferrer" target="_blank"> https://www.ghosteryenterprise.com/mobile-app-privacy-statement/</a></p> */}
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        "\n                .cpolicy {\n                    background: none repeat scroll 0 0 #EDEDFF;\n                    display: none;\n                    height: 300px;\n                    overflow: auto;\n                    padding: 10px;\n                }\n\n                .tdesign {\n                    border: 1px solid #ccc;\n                    border-collapse: collapse;\n                }\n\n                .tdesign tr td,\n                .tdesign tr th {\n                    border: 1px solid #ccc;\n                    padding: 5px;\n                }\n            ",
                    }}
                  />
                  {/* <table border="1" cellpadding="10" bordercolor="#003300" class="tdesign">
                                            <tbody>
                                            <tr style="background-color: #C2D6FF;">
                                            <th>ONLINE PROPERTY</th>
                                            <th>THIRD PARTY COOKIES</th>
                                            <th>THIRD PARTY PRIVACY POLICIES AND OPT OUT CHOICES</th>
                                            </tr>
                                            <tr>
                                            <td>Websites</td>
                                            <td>Google Analytics</td>
                                            <td>
                                             <a href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer" target="_blank">https://tools.google.com/dlpage/gaoptout</a>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>Apps for mobile phones and tablets</td>
                                            <td>Ghostery</td>
                                            <td>
                                             <a href="https://www.ghosteryenterprise.com/mobile-app-privacy-statement/" rel="noopener noreferrer" target="_blank">https://www.ghosteryenterprise.com/mobile-app-privacy-statement/ </a>
                                            </td>
                                            </tr>
                                            </tbody>
                                            </table> */}
                </div>
                <div />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
