import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import messages from "bundles/common/intl/messages";
import { Popup } from "components/Popup";
import ContactUs from "components/FooterLinks/ContactUs";
import UserAgreement from "components/FooterLinks/UserAgreement";
import PrivacyPolicy from "components/FooterLinks/PrivacyPolicy";
import Copyright from "components/FooterLinks/Copyright";
import { Link } from "@material-ui/core";
import Cookies from "universal-cookie";

const universalCookies = new Cookies();
const FooterAnchor = (props: any) => (
  <a
    href={props.href}
    title={props.title}
    target={props.target}
    rel={props.rel}
  >
    {props.text}
  </a>
);

const languageCodes: any = {
  "de-de": "de", //german
  en: "en", //english
  "es-mx": "es", //spanish
  "fr-fr": "fr", //french
  "ja-jp": "jp", //Japanese
  "pt-br": "pt", //Portuguese
  "zh-cn": "zh", //Chinese Simplified
};

export const Footer = () => {
  const [showCopyRight, setShowCopyRight] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showUserAgreement, setShowUserAgreement] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const currentDate = new Date();

  const language = universalCookies.get("ulang")
    ? universalCookies.get("ulang").toLowerCase() === "en"
      ? "en-en"
      : universalCookies.get("ulang").toLowerCase()
    : "en-en";

  const [langCode, setLangCode] = useState<string>();

  useEffect(() => {
    setLangCode(languageCodes[language] ? languageCodes[language] : "en");
  }, [language]);
  /**
   * Refactor the below states as genetic popup
   * with one state.
   */

  function handleCopyRight() {
    setShowCopyRight(!showCopyRight);
  }

  function handlePrivacyPolicy() {
    setShowPrivacyPolicy(!showPrivacyPolicy);
  }
  function handleUserAgreement() {
    setShowUserAgreement(!showUserAgreement);
  }
  function handleContactUs() {
    setShowContactUs(!showContactUs);
  }

  return (
    <div>
      {showCopyRight && (
        <Popup
          text={<FormattedMessage {...messages.copyright} />}
          removePopup={handleCopyRight}
        >
          <Copyright />
        </Popup>
      )}
      {showPrivacyPolicy && (
        <Popup
          text={<FormattedMessage {...messages.privacyPolicy} />}
          removePopup={handlePrivacyPolicy}
        >
          <PrivacyPolicy />
        </Popup>
      )}
      {showUserAgreement && (
        <Popup
          text={<FormattedMessage {...messages.userAgreement} />}
          removePopup={handleUserAgreement}
        >
          <UserAgreement />
        </Popup>
      )}
      {showContactUs && (
        <Popup
          text={<FormattedMessage {...messages.contactUs} />}
          removePopup={handleContactUs}
        >
          <ContactUs />
        </Popup>
      )}
      <div
        className="footer-wraper"
        // style={{ zIndex: 1, bottom: "0", position: "fixed" }}
      >
        <div className="footer">
          <ul>
            <span className="logo">
              <Link href="https://www.learnship.com/us/" target="_blank">
                <img
                  width="152px"
                  className="footerlogo"
                  src={`${process.env.REACT_APP_LS_LOGO_WHITE_URL}`}
                  alt="Learnship"
                  title="Learnship"
                />
              </Link>
            </span>
          </ul>
          <ul>
            <li className="first">
              <a
                target="_blank"
                href={`https://www.learnship.com/${langCode}/about-learnship`}
              >
                {<FormattedMessage {...messages.about} />}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href={`https://www.learnship.com/${langCode}/legal`}
              >
                {<FormattedMessage {...messages.legal} />}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href={`https://www.learnship.com/${langCode}/privacy-policy`}
              >
                {<FormattedMessage {...messages.privacyPolicy} />}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href={`https://www.learnship.com/${langCode}/termsofuse`}
              >
                {<FormattedMessage {...messages.terms} />}
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <FooterAnchor text={`© 2010 - ${currentDate.getFullYear()}`} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
