// @ts-nocheck
import React from "react";
import { FormattedMessage } from "react-intl";

export interface InputFieldProps {
  name: string;
  value: any;
  id: string;
  type: string;
  placeHolder: any;
  onChangeFn?: () => void;
  onChangeFnWithParam?: (e:any) => void
  onClickFn?: () => void;
  onKeyDown?: () => void;
  onKeyDownWithParam?:(e:any)=>void;
  className: string;
  labelId: any;
  label: any;
  validation: {};
  showValidation: boolean;
  readOnly?: boolean;
}

export const InputField: React.SFC<InputFieldProps> = (props:InputFieldProps) => {
  return (
    <React.Fragment>
      <fieldset style={{ padding: "10px 0px" }}>
        <label id={props.labelId}>{props.label}</label>
        {props.placeHolder ? (
          <FormattedMessage
            id={props.placeHolder.props.id}
            defaultMessage={props.placeHolder.props.defaultMessage}
          >
            {(placeHolder) => (
              <input
                readOnly={props.readOnly}
                autoComplete="off"
                id={props.id}
                className={props.className}
                name={props.name}
                type={props.type}
                value={props.value}
                onChange={props.onChangeFn?props.onChangeFn:props.onChangeFnWithParam}                
                onClick={props.onClickFn}
                onKeyDown={props.onKeyDown?props.onKeyDown:props.onKeyDownWithParam}
                placeholder={placeHolder}
              />
            )}
          </FormattedMessage>
        ) : (
            <input
              id={props.id}
              className={props.className}
              name={props.name}
              type={props.type}
              value={props.value}
              onChange={props.onChangeFn}
              onClick={props.onClickFn}
              onKeyDown={props.onKeyDown}
            />
          )}
        {props.showValidation && props.validation && (
          <div id={props.validation.validateId} className="error">
            <span>{props.validation.validateMessage}</span>
          </div>
        )}
      </fieldset>
    </React.Fragment>
  );
};
