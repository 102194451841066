// @ts-nocheck
import React from 'react';

function KoKoContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: '14px' }}>
        <div style={{ fontSize: '10pt', fontFamily: 'arial' }}>
          <center>
            <b>저작권, 상표권 및 기타 법적 고지 사항</b>
          </center>
        </div>
        <div style={{ fontFamily: 'arial' }}>
          <p>
            <b>GLOBALENGLISH 저작권</b>
          </p>
          <p />
          <p> Copyright © 1999-2013 GlobalEnglish Corporation, 전권 소유 </p>
          <br />
          <p>
            <b>제3자 저작권</b>{' '}
          </p>
          <p>
            {' '}
            <i>Essential American English Grammar</i>, 초판, 1995
            <br /> © Longman Group UK Limited 1995{' '}
          </p>
          <p>
            {' '}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> © Longman Group Limited 1995{' '}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p*/}
          <p> Copyright © 1995 - 1998 Macromedia, Inc., 전권 소유 </p>
          <p> Copyright © 1999 Microsoft Corporation, 전권 소유 </p>
          <p> Copyright © 1999 Sipro Lab Telecom Inc., 전권 소유 </p>
          <p>
            {' '}
            Copyright © Business English Pod Limited, 전권 소유. 팟캐스트, 문구캐스트, 비디오캐스트, 학습 노트 및 오디오
            전자북을 비롯한 모든 콘텐츠는 Business English Pod Limited의 자산이며, GlobalEnglish Corporation에서 승인을
            받아 사용합니다.{' '}
          </p>
          <p>
            {' '}
            WGrammar Grammar Checker Engine Copyright © 1998-2006 Wintertree Software Inc. ({' '}
            <a rel="noopener noreferrer" target="_blank" href="http://www.wintertree-software.com">
              <div onmouseout="this.className='blu11'" onmouseover="this.className='blu11rllover'" className="blu11">
                www.wintertree-software.com
              </div>
            </a>
            ){' '}
          </p>
          <p>
            {' '}
            SRI International<sup>®</sup>의 라이센스 하에 있는 EduSpeak<sup>®</sup> 음성 인식 소프트웨어 포함. Copyright
            © SRI International.{' '}
          </p>
          {/*p> Systran Translation Engine &copy; SYSTRAN Software, Inc </p*/}
          <p>
            {' '}
            AP/Wide World Photos에서 제공하는 사진은 저작권이 보호되며 Associated Press의 자산입니다. AP/Wide World
            Photos의 사전 승인 없이 무단으로 사용하는 것은 금지되어 있습니다. 모든 위반 행위는 법적 조치의 대상이
            됩니다.{' '}
          </p>
          <p>
            {' '}
            PhotoDisc에서 제공하는 사진은 저작권이 보호되며 PhotoDisc, Inc.의 자산입니다. Images © copyright 1999
            PhotoDisc, Inc.{' '}
          </p>
          <p>
            {' '}
            Copyright © Barron's Educational Series, Inc., 출처 HOW TO PREPARE FOR THE TOEIC<sup>®</sup>{' '}
          </p>
          <p>
            {' '}
            <i>Write for Results</i> © 1998 AMACOM NEW MEDIA는 American Management Association, New York의 100%
            자회사입니다. 전권 소유.{' '}
          </p>
          <p>
            {' '}
            Microsoft에서 제공하는 클립 아트는 저작권이 보호되며 Microsoft Corporation 및/또는 그 공급업체의 자산입니다.
            Microsoft의 사전 승인 없이 무단으로 사용하는 것은 금지되어 있습니다.{' '}
          </p>
          <p>
            {' '}
            ArtToday에서 제공하는 클립 아트는 저작권이 보호되며 IMSI Inc.의 100% 자회사인 Zedcor, Inc. 및/또는 그
            공급업체의 자산입니다. Zedcor의 사전 승인 없이 무단으로 사용하는 것은 금지되어 있습니다.{' '}
          </p>
          <p>
            {' '}
            Eyewire에서 제공하는 클립 아트는 저작권이 보호되며 Eyewire Inc. 및/또는 그 공급업체의 자산입니다. Eyewire의
            사전 승인 없이 무단으로 사용하는 것은 금지되어 있습니다.{' '}
          </p>
          <p>
            {' '}
            Corbis에서 제공하는 사진은 저작권이 보호되며 Corbis Corporation 및/또는 그 공급업체의 자산입니다. Corbis의
            사전 승인 없이 무단으로 사용하는 것은 금지되어 있습니다.{' '}
          </p>
          <p>
            {' '}
            ImageQuest에서 제공하는 사진은 저작권이 보호되며 ImageQuest 및/또는 그 공급업체의 자산입니다. ImageQuest의
            사전 승인 없이 무단으로 사용하는 것은 금지되어 있습니다.{' '}
          </p>
          <p />
          <br />
          <p>
            <b>GlobalEnglish 상표</b>
          </p>
          <p />
          <p>
            아래 내용은 GlobalEnglish Corporation가 소유한 일부 상표와 미국 및/또는 기타 국가에서 이러한 상표에 대해
            제시된 일반 조항입니다. 이 목록에 이름이나 로고가 나타나지 않았다는 점이 GlobalEnglish Corporation 또는 그
            계열사가 제품, 기능, 서비스 이름 또는 로고에 대하여 보유하는 일부 또는 전체 지적재산권의 포기를 의미하지는
            않습니다. GlobalEnglish 제품명과 상표를 적절히 언급하는 방법에 관한 지침이 필요하면 GlobalEnglish 일반 상표
            지침 사본을 요청하십시오.
          </p>
          <p />
          <br />
          <style dangerouslySetInnerHTML={{ __html: ' \t\ttd{padding:10px !important} \t\t' }} />
          <table border={1} cellPadding={10} cellSpacing={0} bordercolor="#808080">
            <tbody>
              <tr>
                <td style={{ fontWeight: 'bold', fontSize: '14px', color: '#666' }}>TradeMark</td>
                <td style={{ fontWeight: 'bold', fontSize: '14px', color: '#666' }}>Status</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>Enterprise Fluency</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish Bloom</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish Coach</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish Corporate Learning Service</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish Edge</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish LinGo</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish LinGo Pro</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish Magazine</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish Mobile Learning Network</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish Productivity Toolbar</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish STEP</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish STEP+</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish Motivator</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
              <tr>
                <td style={{ fontSize: '14px', color: '#666' }}>GlobalEnglish Executive Coach</td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>®</td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', color: '#666' }}>™</td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>제3자 상표</b>
          </p>
          <p />
          <p> Adobe 및 Flash는 미국 및/또는 기타 국가에서 Adobe Systems Incorporated의 상표 또는 등록 상표입니다.</p>
          <p> Microsoft는 Microsoft Corporation의 등록 상표입니다. </p>
          {/*p> Langenscheidt KG&#45716; Langenscheidt KG, Berlin and Munich&#51032; &#46321;&#47197; &#49345;&#54364;&#51077;&#45768;&#45796;. </p*/}
          <p> Living Language 및 Colophon은 Random House, Inc.의 등록 상표입니다. </p>
          <p>
            {' '}
            Sipro 및 Sipro 로고는 Sipro Lab Telecom Inc.의 상표입니다. ACELP<sup>®</sup>는 Université Sherbrooke의 등록
            상표이며 Sipro Lab Telecom Inc.의 라이센스 하에 사용됩니다.{' '}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td style={{ color: '#999999', fontSize: '10pt', fontFamily: 'arial' }}>
                  {' '}
                  SRI International, SRI International 로고, EduSpeak 및 EduSpeak 로고는 SRI International의 상표 또는
                  등록 상표입니다.
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p> SYSTRAN&#51008; SYSTRAN Software, Inc.&#51032; &#46321;&#47197; &#49345;&#54364; &#46608;&#45716; &#49436;&#48708;&#49828; &#47560;&#53356;&#51060;&#47728;, SYSTRAN &#47196;&#44256;&#45716; SYSTRAN Software, Inc.&#51032; &#46321;&#47197; &#49436;&#48708;&#49828; &#47560;&#53356;&#51077;&#45768;&#45796;. </p*/}
          <p> AP/Wide World Photos는 Associated Press의 상표입니다. </p>
          <p> PhotoDisc는 PhotoDisc, Inc.의 등록 상표입니다. </p>
          <p>
            {' '}
            Business English Pod, www.businessenglishpod.com 및 Business English Pod 로고는 Business English Pod
            Limited의 상표입니다.{' '}
          </p>
          <p> 다른 모든 상표는 해당 소유자의 자산입니다. </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default KoKoContent;
