import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import messages from "bundles/common/intl/messages";

class PasswordUpdatedContainer extends Component<any, any> {
  render() {
    setTimeout(() => {
      window.location.replace("/" + window.location.search);
    }, 2000);

    return (
      <div>
        <div id="content" className="content content-wrapper">
          <div className="loginWrap welcome-pod">
            <div
              className="login"
              id="login_block"
              style={{ display: "block !important" }}
            >
              <h2>{<FormattedMessage {...messages.profileSuccess} />}</h2>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordUpdatedContainer;
