// @ts-nocheck
import { decodeJWTToken, setJWTToken } from "containers/Home/verifyToken";
import Cookies from "universal-cookie";
import {isMFAUser, setAWSConfig} from "./Cognito"

const isUrlParams = window.location.search === "" ? false : true;

const languages = {
  en: "English",
  "de-de": "Deutsch anzeigen",
  "es-es": "Español (España)",
  "es-mx": "Español (Latinoamérica)",
  "pt-br": "Português",
  "fr-fr": "Français",
  "it-it": "Italiano",
  "ja-jp": "日本語で見る",
  "ko-ko": "웹사이트 보기",
  "ru-ru": "русском языке",
  "tr-tr": "Türkçe görüntüleyin",
  "zh-cn": "观看网站",
  "zh-tw": "觀看網站",
  "cz-cz": "češtině",
  "pl-pl": "Polskim",
};

const otherLangs = {
  en: "Other Languages",
  "de-de": "Weitere Sprachen",
  "es-es": "Otros idiomas",
  "es-mx": "Otros idiomas",
  "pt-br": "Outros idiomas",
  "fr-fr": "Autres langues",
  "it-it": "Altre lingue",
  "ja-jp": "他の言語",
  "ko-ko": "기타 언어",
  "ru-ru": "Другие языки",
  "tr-tr": "Diğer Diller",
  "zh-cn": "其它语言",
  "zh-tw": "其他語言",
  "cz-cz": "Další jazyky",
  "pl-pl": "Inne języki",
};

export async function setCookies(result) {
  if (!result.idToken) {
    let decodedUserData = decodeJWTToken(result);
    setAWSConfig(result);
    const { peopleInfo, cognitoUser } = await isMFAUser(decodedUserData['custom:geId'] || "", decodedUserData);
    let features = peopleInfo?.data?.getpeopleInformation?.serviceConfiguration.features || {};
    decodedUserData.features = features; 
    result = decodedUserData; 
  }
  
  setJWTToken(result);
  const params = new URLSearchParams(window.location.search);
  const redirectUrl = params.get("redirectUrl");
  if (redirectUrl) {
    const redirectDomain = new URL(redirectUrl).hostname;
    const learnshipDomain = process.env.REACT_APP_LEARNSHIP_DOMAIN?.trim();
    const isLearnshipDomain = redirectDomain.includes(learnshipDomain);
      if (isLearnshipDomain) {
        window.location.href = redirectUrl;
      }else{
        handleWithoutRedirectUrl(result)
      }   
  } else {  
    handleWithoutRedirectUrl(result);
  }

  return !(result.idToken) ? result:result.idToken.payload;
}

const handleWithoutRedirectUrl=(result: any)=>{
  const HALO_FEATURE_FLAG = 15;  // Halo feature bit - 16 (16-1)
  const webShopUrl = process.env.REACT_APP_WEBSHOP_APP_URL;
  const haloUrl = process.env.REACT_APP_HALO_APP_URL;
  const homeUrl = process.env.REACT_APP_HOME_APP_URL;
  const userPayload = !(result.idToken) ? result:result.idToken.payload;
  if (!("custom:geId" in userPayload) && userPayload["custom:source"] === "Webshop") {
    window.location.href = webShopUrl;
  } else {
    const isHaloEnabled = checkIfFeatureIsEnabled(result.features, HALO_FEATURE_FLAG);
    if (isHaloEnabled) {
      window.location.href = haloUrl;
    } else {
      window.location.href = homeUrl;
    }
  }
}

export const getCookieValue = (key) => {
  const value = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  return value ? value.pop() : "";
};

export const getLangFromCode = (langCode) => {
  return languages[langCode.toLowerCase()];
};

export const getOtherLangFromCode = (langCode) => {
  return otherLangs[langCode.toLowerCase()];
};

const checkIfFeatureIsEnabled = (arr: string, featureBitNumber: number) => {
  return !!parseInt(arr[featureBitNumber]);
};