/* eslint-disable */
import React, { Component } from "react";
import { Switch } from "react-router-dom";
import { Header } from "bundles/common/components/Header";
import { Footer } from "bundles/common/components/Footer";
import routes from "bundles/common/routes/common";

class AppContainer extends Component<any, any> {
  render() {
    return (
      <div>
        <Header />
        <Switch>{routes}</Switch>
        <Footer />
      </div>
    );
  }
}

export default AppContainer;
