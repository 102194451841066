// @ts-nocheck
import React from 'react';

function ItItContent() {
  return (
    <div id="agreement_container">
      <div style={{ padding: '1% 5% 0 5%' }}>
        <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
          <b>ACCORDO CON L’UTENTE</b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
          <b>INTRODUZIONE</b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          Benvenuto nei servizi di GlobalEnglish, disponibili sul sito web o sulle applicazioni mobili (indicati
          collettivamente con il termine “Servizi”).
          <br />
          <br /> I nostri Servizi comprendono strumenti e soluzioni on-line per l’apprendimento dell’inglese, inclusi,
          senza limitazione, dati, testi, prove online, disegni, video, brani audio, registrazioni vocali, post e
          commenti scritti, script, grafica, immagini e la relativa selezione e disposizione, software, esercitazioni,
          lezioni, coaching, formazione, comunicazione e funzionalità interattive generate o rese accessibili attraverso
          il sito web e le applicazioni mobili (collettivamente il “Contenuto’). L'accesso al nostro sito web per
          recepire informazioni sulla nostra azienda e sui nostri Servizi è considerato parte dei Servizi.
          <br />
          <br /> Prima di proseguire, si prega di leggere attentamente quanto segue e di confermare di aver compreso e
          di accettare le condizioni e i termini sottostanti.
          <br />
          <br />
          <b>
            I MINORI DI 18 ANNI SONO TENUTI A LEGGERE IL PRESENTE ACCORDO INSIEME A UN GENITORE O UN TUTORE LEGALE E A
            OTTENERE IL LORO CONSENSO PRIMA DI UTILIZZARE I NOSTRI SERVIZI. UTILIZZANDO I NOSTRI SERVIZI, DICHIARATE DI
            AVERE ALMENO 18 ANNI O DI DISPORRE DEL CONSENSO DI UN GENITORE O TUTORE LEGALE E DI ESSERE AUTORIZZATI A
            SOTTOSCRIVERE IL PRESENTE CONTRATTO CON GLOBALENGLISH.{' '}
          </b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
          <b>CONDIZIONI GENERALI </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 1. ACCORDO GIURIDICAMENTE VINCOLANTE </b>
          <br />
          <br />
          <b>
            {' '}
            L'ACCESSO AL NOSTRO SITO WEB E L'UTILIZZO DEI SERVIZI DI GLOBALENGLISH SONO SOGGETTI ALL’ACCORDO DI CUI
            SOTTO, ALL’INFORMATIVA SULLA PRIVACY E ALL’INFORMATIVA SULL’UTILIZZO DEI COOKIE (I TRE DOCUMENTI SONO
            DEFINITI COLLETTIVAMENTE "CONDIZIONI D’UTILIZZO"). ACCEDENDO, VISITANDO, UTILIZZANDO E/O REGISTRANDOSI AI
            SERVIZI DI GLOBALENGLISH, DICHIARATE DI AVER LETTO E COMPRESO LE CONDIZIONI D’UTILIZZO DEI NOSTRI SERVIZI E
            ACCETTATE DI ESSERNE LEGALMENTE E IRREVOCABILMENTE VINCOLATI. DICHIARATE ALTRESÌ DI ESSERE MAGGIORENNI E DI
            ACCETTARE DI ESSERE VINCOLATI DA TALI CONDIZIONI E DI SOTTOSCRIVERE UN CONTRATTO GIURIDICAMENTE VINCOLANTE
            CON GLOBALENGLISH.{' '}
          </b>
          <br />
          <br /> Un quadro generale delle nostre politiche aziendali è disponibile
          <a
            href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
            rel="noopener noreferrer"
            target="_blank"
          >
            qui
          </a>
          . Tali politiche sono integrate, per scopi di riferimento, nel presente "Accordo con l’utente", o "Accordo", e
          costituiscono, complessivamente, le condizioni d’utilizzo.
          <br />
          <br />
          <b>
            Si prega di notare che il presente Accordo contiene disposizioni che regolano il modo in cui verranno
            risolte le controversie che potrebbero sorgere tra voi e la nostra società. Si veda in particolare le
            clausole relative a esclusione di responsabilità, limitazioni di responsabilità e controversie legali di
            seguito citate. Inoltre, il presente contiene un accordo di arbitrato che, con alcune eccezioni di portata
            limitata, prevede che sottoponiate eventuali reclami contro la nostra società ad arbitrato vincolante e
            definitivo. Sarete autorizzati a presentare reclami su base individuale, non di classe né come querelante,
            in qualunque procedimento o azione di classe o rappresentativa. Vi sarà permesso di chiedere un risarcimento
            (incluso eventuale rimborso monetario, provvedimento ingiuntivo o accertamento) solamente su base
            individuale.
            <br />
            <br />
            NON UTILIZZATE I SERVIZI E NON PROCEDETE CON LA NAVIGAZIONE ALL’INTERNO DEL NOSTRO SITO WEB SE NON ACCETTATE
            UNA QUALUNQUE PARTE DELLE CONDIZIONI SOTTOSTANTI.
          </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. APPLICABILITÀ </b>
          <br />
          <br /> Il presente Accordo si applica sia agli Utenti dei nostri servizi che ai Visitatori dei nostri siti. Le
          persone che hanno effettuato l’iscrizione direttamente con noi (<b>"rapporto B2C"</b>), anche a titolo di
          prova, e quelle che sono state iscritte da o attraverso i loro datori di lavoro (<b>"rapporto B2B"</b>) sono
          indicate con il termine di
          <b>"Utenti"</b>, mentre gli utenti e i visitatori non registrati che visitano i nostri siti web sono indicati
          con il termine
          <b>"Visitatori"</b>. Con il termine
          <b>"Utenti"</b> si includono anche gli insegnanti, gli istruttori, gli specialisti dell'apprendimento e
          qualsiasi altra persona che acceda ai siti di GlobalEnglish per fornire servizi agli altri Utenti. Utenti e
          Visitatori sono indicati con il termine
          <b>"Voi"</b>.
          <br />
          <br />
          Il presente Accordo si applica a tutti i Servizi di GlobalEnglish.
          <br />
          <br /> Quando vi registrate per la prima volta come Utente e quando accedete ai nostri Servizi potremmo
          chiedervi di ribadire il vostro consenso ad essere vincolati dai termini qui presenti cliccando sulla casella
          "Accetto", oppure fornendo la vostra firma elettronica o qualsiasi altro mezzo di autenticazione.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>3.MODIFICHE ALLE CONDIZIONI D’UTILIZZO </b>
          <br />
          <br />
          GlobalEnglish potrà, in qualsiasi momento, modificare le Condizioni d’utilizzo, incluso il presente Accordo e
          le Informative sulla privacy e sui cookie applicabili al vostro utilizzo dei Servizi. Tali modifiche
          entreranno in vigore immediatamente dopo che ne verrà fatta comunicazione all'interno del Servizio, oppure
          tramite un messaggio di posta elettronica all’indirizzo che avrete fornito in relazione al vostro account (se
          applicabile) o altri mezzi. Se siete stati iscritti ai nostri Servizi tramite il vostro datore di lavoro o da
          un’azienda, quest’ultimi vi informeranno dell’avvenuta modifica alle nostre Condizioni d’utilizzo.
          <br />
          <br /> Se non accettate tali modifiche, dovrete chiudere il vostro account e smettere di accedere al nostro
          sito web. Tuttavia, continuando a utilizzare i nostri Servizi, dopo la pubblicazione o l'invio della
          comunicazione relativa alle modifiche delle condizioni, dichiarate di accettare implicitamente tali modifiche
          delle Condizioni d'utilizzo.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4.CONDIZIONI D’UTILIZZO DEI SITI DI TERZI </b>
          <br />
          <br />I Servizi possono contenere collegamenti ipertestuali ad altri siti web (“Siti di terzi”), nonché
          articoli, fotografie, testi, traduzioni, grafica, immagini, disegni, brani audio, video, informazioni e altri
          contenuti o elementi appartenenti o provenienti da terzi (il "Contenuto di terzi"). Tali Siti di terzi e il
          Contenuto di terzi non vengono da noi esaminati, controllati o verificati in merito a precisione, adeguatezza
          o completezza e noi non siamo responsabili dei Siti di terzi a cui si acceda attraverso il Sito o di
          qualsuiasi Contenuto di terzi pubblicato o disponibile sul sito.
          <br />
          <br /> L'inclusione, il collegamento o l'autorizzazione all'utilizzo di qualsiasi Sito di terzi o di qualsiasi
          Contenuto di terzi non ne implica l'approvazione o l’accettazione da parte di GlobalEnglish. GlobalEnglish si
          riserva il diritto di porre fine a tali collegamenti in qualsiasi momento, senza preavviso. I riferimenti a
          qualsiasi azienda, organizzazione, prodotto o servizio, o altre informazioni presenti sui Servizi, non
          costituiscono o implicano l’approvazione, la sponsorizzazione o la raccomandazione da parte di GlobalEnglish,
          né eventuale affiliazione con la stessa.
          <br />
          <br /> Se decidete di uscire dal nostro sito web e accedete ai Siti di terzi, o se decidete di accedere o di
          utlizzare qualsiasi Contenuto di terzi, lo effettuerete a vostro rischio e dovrete essere consapevoli che i
          nostri termini e le nostre politiche non regoleranno il vostro utilizzo di tali Siti di terzi. Assicuratevi di
          controllare i termini e le politiche applicabili a tali altri prodotti e servizi di terzi prima dell’utilizzo.
          <br />
          <br /> Offriamo applicazioni mobili sia tramite Android Market che iTunes Store (collettivamente e con le
          entità che li gestiscono, "Fornitori"). L'utilizzo di queste applicazioni mobili è inoltre soggetto ai termini
          e alle condizioni dei Fornitori, incluse, senza limitazione, le regole di utilizzo stabilite nei termini di
          servizio di App Store. Se avete scaricato una di queste applicazioni mobili da iTunes Store, convenite e
          concordate di aver letto e accettato i termini di servizio di App Store. Se li avete scaricati da Android
          Market, convenite e concordate di aver letto e accettato i termini di servizio di Android Market. I Fornitori
          non hanno alcun obbligo e non vi forniranno alcun servizio di manutenzione o supporto in relazione all'uso dei
          Servizi. I Fornitori non offrono alcun tipo di garanzia per i Servizi. Nel caso in cui una delle applicazioni
          mobili non rispetti le eventuali garanzie applicabili e abbiate scaricato tale applicazione da iTunes Store,
          potrete informarne Apple e la stessa vi rimborserà il prezzo di acquisto (se esistente) di tale applicazione.
          Le presenti Condizioni si applicano al vostro utilizzo di tutte le applicazioni mobili e degli altri nostri
          Servizi.
          <br />
          <br /> Convenite e concordate che GlobalEnglish non sarà responsabile dei contenuti, prodotti, servizi o
          prestazioni di tali siti o applicazioni di terzi. Rinunciate irrevocabilmente a presentare qualsiasi reclamo
          nei confronti di GlobalEnglish in relazione a siti o applicazioni di terzi. In particolare, GLOBALENGLISH NON
          SARÀ RITENUTA RESPONSABILE DI COOKIE, FILE WEB, GIF TRASPARENTI O ALTRO CODICE CHE VOI O ALTRE PARTI
          DEPOSITERANNO SUL PROPRIO COMPUTER IN QUALSIASI MODO, INCLUSA QUALSIASI ASSOCIAZIONE DEL CONTENUTO DEI SITI
          WEB CON TALE CODICE. Ad esempio, se accedete a un Sito di terzi tramite un collegamento del Sito web, il
          vostro browser o altri cookie presenti sul vostro computer potrebbero innescare l'invio di un'intestazione del
          referrer a tale sito, indicando parte della cronologia di navigazione o l'URL da cui è stata originata la
          vostra visita. GlobalEnglish non avrà alcuna responsabilità nei vostri confronti di tale trasmissione,
          indipendentemente dal fatto che tale trasmissione contenga informazioni associate a GlobalEnglish od originate
          da quest’ultima.
          <br />
          <br /> SE UTILIZZATE TRADUZIONI FORNITE DA GOOGLE: GOOGLE NON RICONOSCE ALCUNA GARANZIA RELATIVA ALLE
          TRADUZIONI, ESPLICITA O IMPLICITA, INCLUSE LE GARANZIE DI ACCURATEZZA O DI AFFIDABILITÀ, E QUALSIASI GARANZIA
          IMPLICITA DI COMMERCIABILITÀ, DI IDONEITÀ A UN PARTICOLARE SCOPO E DI NON VIOLAZIONE.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>5. CRITERI DI AMMISSIBILITÀ AI SERVIZI </b>
          <br />
          <br />
          <b>5.1 Tutela della privacy dei bambini e restrizioni all’utilizzo dei nostri Servizi </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            I Servizi non possono essere utilizzati dai minori dell'età minima prevista dal Paese dal quale stanno
            accedendo ai Servizi. Ad esempio, l’"età minima" nell'Unione europea, nello Spazio economico europeo e in
            Svizzera è di 16 anni, ferma ogni diversa disposizione specifica della legge nazionale. Negli Stati Uniti
            l'età minima è di 13 anni. Qualsiasi registrazione, utilizzo o accesso ai Servizi da parte di chiunque non
            abbia l'età minima è proibito e costituisce una violazione delle presenti Condizioni.
            <br />
            <br /> Utilizzando i Servizi, dichiarate e garantite di avere almeno 13 anni.
          </span>
          <br />
          <br />
          <b>5.2 Scuole e insegnanti </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Le scuole, i distretti e gli insegnanti degli Stati Uniti che desiderano che i loro studenti di età
            inferiore ai 13 anni creino un account con GlobalEnglish concordano di essere responsabili del rispetto
            della Children's Online Privacy Protection Act ("COPPA") e, per quanto applicabile, la Family Educational
            Rights and Privacy Act ("FERPA"). Ciò significa che le scuole, i distretti e gli insegnanti devono informare
            i genitori o i tutori degli studenti riguardo i dati personali identificabili che GlobalEnglish raccoglierà
            e devono ottenere il consenso dei genitori/tutori prima che gli studenti creino un account e utilizzino i
            Servizi. Quando si ottiene tale consenso, le scuole, i distretti e gli insegnanti dovranno fornire ai
            genitori/tutori una copia della nostra Informativa sulla privacy e depositare tutti i consensi in archivio,
            nonché fornirli a noi se richiesti. Per ulteriori informazioni sul rispetto della COPPA, consultare il sito
            web della
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
              rel="noopener noreferrer"
              target="_blank"
            >
              Federal Trade Commission.
            </a>
            <br />
            <br /> Se risiedete al di fuori degli Stati Uniti, sarà vostra cura ottenere il consenso o l’approvazione da
            parte del genitore o del tutore di qualunque studente protetto da leggi simili e, come condizione per
            l'utilizzo dei nostri Servizi da parte vostra e dei vostri studenti, accettate di essere responsabile del
            rispetto di tali leggi.
          </span>
          <br />
          <br />
          <b>5.3 Consenso dei genitori </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            <b>
              UTILIZZANDO I NOSTRI SERVIZI, DICHIARATE DI AVERE 18 O PIÙ ANNI DI ETÀ OVVERO DI AVER OTTENUTO IL CONSENSO
              DA PARTE DI UN GENITORE O TUTORE E DI ESSERE AUTORIZZATI A SOTTOSCRIVERE IL PRESENTE CONTRATTO CON
              GLOBALENGLISH.
              <br />
              <br /> Ci aspettiamo che qualsiasi uso da parte di bambini sarà fatto solo sotto la guida, la sorveglianza
              e il consenso dei genitori, tutori e/o funzionari scolastici autorizzati. GlobalEnglish si affida a
              genitori, tutori e scuole per garantire che i minori utilizzino i Servizi solo se comprendono i propri
              diritti e le proprie responsabilità, come specificato nelle presenti Condizioni d'utilizzo.
              <br />
              <br /> GlobalEnglish si riserva il diritto, a propria esclusiva discrezione, di rifiutare di offrire i
              Servizi a qualsiasi persona o entità e di modificare i criteri di ammissibilità in qualsiasi momento.{' '}
            </b>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6.PRIVACY</b>
          <br />
          <br />
          <b>6.1 Dichiarazione sulla privacy</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Si prega di leggere l’Informativa sulla privacy disponibile
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              qui
            </a>
            .
            <br />
            <br /> Tratteremo i dati personali in conformità alla politica sulla privacy a cui avete acconsentito al
            momento del primo accesso al servizio o come parte di un aggiornamentto programmato dell'informativa sulla
            privacy.
          </span>
          <br />
          <br />
          <b>6.2 Dati che ci fornite</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Per poter utilizzare i Servizi, è necessario disporre di un account valido. Per iscriversi ai nostri
            Servizi, dovrete fornire a GlobalEnglish un codice d’identificazione utente, un indirizzo e-mail e altre
            informazioni di identificazione personale ("Dati personali"). Alcune delle informazioni che raccogliamo
            servono a GlobalEnglish per fornire i Servizi o anche solo per inviarvi informazioni sui Servizi che avete
            richiesto, mentre altre sono facoltative. I Servizi vi consentono inoltre di visualizzare i vostri dati e
            quelli della vostra organizzazione, ove applicabile.
            <br />
            <br /> Acconsentite di: (i) fornire informazioni accurate, aggiornate e complete su di voi e, nel caso di un
            rapporto B2B, sulla vostra organizzazione, come richiesto dai moduli di registrazione all'interno dei
            Servizi; (ii) mantenere la sicurezza della vostra password e di altre informazioni connesse alla sicurezza
            del vostro account; (iii) mantenere e aggiornare tempestivamente i vostri Dati personali, così come
            qualsiasi altra informazione fornita a GlobalEnglish, allo scopo di mantenerli accurati, aggiornati e
            completi; (iv) essere pienamente responsabili di tutti gli usi del vostro account e di qualsiasi azione che
            si verifichi utilizzando il vostro account.
            <br />
            <br /> GlobalEnglish non si assume alcun obbligo di verificare l'esattezza dei Dati personali da voi forniti
            ai fini della registrazione.
          </span>
          <br />
          <br />
          <b>6.3 Trasferimento internazionale dei dati personali </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Per fornire i Servizi, GlobalEnglish fa affidamento sulla propria forza lavoro in tutto il mondo e utilizza
            fornitori terzi e partner di hosting per lo storage e per altri servizi relativi alla tecnologia e
            all'insegnamento. I vostri Dati personali vengono quindi elaborati e trasferiti ai dipendenti e ai
            subcontraenti di GlobalEnglish in vari Paesi del mondo, alcuni dei quali potrebbero non offrire lo stesso
            livello di protezione legalmente concesso ai vostri Dati personali nel vostro Paese di cittadinanza o di
            residenza.
            <b>
              {' '}
              Accedendo, registrandovi e/o utilizzando i nostri Servizi, acconsentite al trasferimento e
              all'elaborazione dei vostri Dati personali all'estero nei vari Paesi in cui GlobalEnglish, le sue
              affiliate e subcontraenti sono ubicati ai fini dei Servizi.
            </b>
          </span>
          <br />
          <br />
          <b>6.4 Annunci e comunicazioni</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Riconoscete e accettate che i Servizi possono includere determinate comunicazioni da GlobalEnglish, come
            annunci relativi al servizio o messaggi di natura amministrativai, e che tali comunicazioni sono considerate
            parte dei Servizi e non potrete scegliere di non riceverle.
            <br />
            <br />
            Accettate che GlobalEnglish vi possa fornire notifiche e messaggi nei seguenti modi: (i) all'interno del
            Servizio; (ii) ai recapiti forniti a GlobalEnglish (ad esempio, e-mail o numero di cellulare). È vostra cura
            mantenere le vostre informazioni di contatto sempre aggiornate.
          </span>
          <br />
          <br />
          <b>6.5 Privacy altrui</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Se nel corso dell'utilizzo dei nostri Servizi acquisite informazioni su un altro Utente, accettate di
            utilizzare tali informazioni solo allo scopo per il quale vi sono state fornite. Non è consentito divulgare,
            vendere o distribuire le informazioni di un Utente a terze parti per scopi estranei ai Servizi. Non potrete
            utilizzare tali informazioni a scopi di marketing, a meno che non se ne ottenga il consenso da parte
            dell'Utente specifico.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. LICENZE DELL’UTENTE </b>
          <br />
          <br />
          <b>7.1 Licenza per l’utilizzo dei nostri Servizi </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Per accedere e utilizzare i Servizi vi accordiamo una licenza limitata, personale, non esclusiva, non
            cedibile, non concedibile in sottolicenza e non trasferibile, solo ad uso personale e non commerciale.
            GlobalEnglish potrà, a propria esclusiva discrezione, interrompere periodicamente, e in modo temporaneo o
            permanente, il Servizio o modificare le funzionalità dei Servizi senza preavviso.
            <br />
            <br /> Per poter utilizzare i Servizi, è necessario ottenere l'accesso al World Wide Web , direttamente o
            tramite dispositivi che accedono ai contenuti basati sul Web. Inoltre, è necessario disporre di tutte le
            apparecchiature necessarie per effettuare tale connessione al World Wide Web, inclusi un computer e un modem
            o altro dispositivo di accesso. Accettate di dover valutare e assumervi tutti i rischi connessi all'utilizzo
            dei Servizi, ivi incluso l'affidamento dell'accuratezza, della completezza o dell'utilità del contenuto
            fornito. Né GlobalEnglish né i suoi concessori di licenza garantiranno l'accuratezza o la completezza di
            qualsiasi contenuto.
            <br />
            <br /> I Servizi non sono progettati o concessi in licenza per essere utilizzati in ambienti pericolosi
            (durante la guida, in acqua o vicino all’acqua).
            <br />
            <br />
            Il diritto ad accedere al Servizio vi sarà revocato qualora l'utilizzo del Servizio sia proibito o nella
            misura in cui l'offerta, la vendita o la fornitura del Servizio sia in conflitto con qualsiasi legge, norma
            o regolamento applicabile .
            <br />
            <br />
            L'utilizzo del Servizio per scopi diversi da quelli contemplati nelle Condizioni d'utilizzo costituisce una
            violazione delle Condizioni d'utilizzo e dei diritti di copyright e di proprietà di GlobalEnglish e/o dei
            suoi concessori di licenza e subcontraenti. GlobalEnglish ha il diritto di sospendere o chiudere il vostro
            account, e di rifiutare di fornirvi ogni e qualsiasi dei Servizi presenti o futuri, se GlobalEnglish ha
            ragionevoli motivi per sospettare che utilizziate i Servizi in violazione delle presenti Condizioni
            d'utilizzo. Accettate di non ritenere GlobalEnglish responsabile nei vostri confronti di qualsiasi modifica,
            sospensione o interruzione dei Servizi o di parte di essi.
          </span>
          <br />
          <br />
          <b>7.2 Contenuti creati dall’utente</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            I Servizi offrono all’Utente l'opportunità di creare dati e informazioni (ad esempio, fornendo risposte ai
            nostri test) e di caricare materiali sui servizi comunitari ("Contenuto dell’utente"). Fatti salvi i diritti
            a noi concessi come indicato nelle presenti Condizioni d’utlizzo, l'utente conserva la piena proprietà del
            proprio Contenuto dell'utente.
            <br />
            <br /> Con il caricamento di qualsiasi Contenuto dell'utente sui nostri Servizi, garantite a GlobalEnglish
            una licenza irrevocable, perpetua, non esclusiva, trasferibile, integralmente pagata, valida in tutto il
            mondo e licenziabile per poter archiviare, utilizzare, riprodurre, eseguire pubblicamente, visualizzare
            pubblicamente, modificare, tradurre, estrarre (in tutto o in parte), elaborare e distribuire tale Contenuto
            dell'utente, se necessario, in relazione alla fornitura dei Servizi al fine di:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) rispettare le procedure legali, incluso, senza limitazione, la risposta a richieste legali, ordini
              giudiziari e altre richieste governative analoghe;
              <br />
              (b) far rispettare l’Accordo;
              <br />
              (c) rispondere alle affermazioni secondo cui il Contenuto viola i diritti di terzi;
              <br />
              (d) proteggere i diritti, la proprietà e la sicurezza personale di GlobalEnglish, dei suoi dipendenti,
              degli Utenti e del pubblico.
            </span>
            <br />
            <br /> Nella misura in cui scegliete di condividere qualsiasi Contenuto dell’utente con altri Utenti dei
            Servizi, accettate di consentire a tali Utenti di visualizzare il Contenuto dell'utente e, nei limiti
            applicabili, di collaborare con voi in merito a tale Contenuto dell’utente.
            <br />
            <br /> Si tenga presente che GlobalEnglish non ha l'obbligo di esaminare, rivedere, verificare
            l'accuratezza, correggere o eliminare tale Contenuto dell’utente in alcun momento. Tuttavia, si riserva il
            diritto di monitorare il Contenuto dell'utente e di rimuovere quello che, a sua assoluta discrezione, è
            concepito come offensivo o comunque viola le Condizioni d'utilizzo.
            <br />
            <br />
            GlobalEnglish non ha l’obbligo di effettuare il backup o l’archiviazione del Contenuto dell'utente. Siete
            gli unici responsabili, a vostre spese, di creare eventuali copie di backup e di ripristinare qualsiasi
            Contenuto dell’utente che pubblicate o memorizzate sul Sito web o che fornite a GlobalEnglish.
            <br />
            <br /> Il Contenuto dell’utente non riflette necessariamente il punto di vista di GlobalEnglish. Convenite
            che il Contenuto dell'utente è di esclusiva responsabilità dell'Utente dal quale tale Contenuto proviene.
            Comprendete che, utilizzando i Servizi, potreste essere esposti a contenuti offensivi, indecenti o
            discutibili.
            <br />
            <br /> Oltre alla concessione della licenza per il Contenuto dell'utente di cui sopra, riconoscete e
            accettate che eventuali domande, commenti, suggerimenti, idee, feedback o altra informazione ("Contributi")
            da voi fornita a GlobalEnglish sui Servizi non è riservata e che GlobalEnglish avrà diritto all'utilizzo e
            alla divulgazione illimitati di tali Contributi per qualsiasi scopo, commerciale o di altra natura, senza
            alcun riconoscimento o compenso nei vostri confronti. Inoltre, le informazioni da voi pubblicate, divulgate
            o condivise su o attraverso uno qualsiasi dei Servizi comunitari o dei prodotti di GlobalEnglish saranno
            escluse dalla definizione di informazioni riservate, così come tale termine è normalmente definito.
            <br />
            <br /> Comprendete che l'elaborazione tecnica e la trasmissione del sito web, incluso il vostro Contenuto
            dell'utente, possono implicare (a) trasmissioni su varie reti; e (b) modifiche per poter conformarsi e
            adattarsi ai requisiti tecnici delle reti o dei dispositivi di collegamento.
            <br />
            <br />
            <b>
              GlobalEnglish non rilascia affermazioni né garanzie in relazione al Contenuto dell’utente. Il Contenuto
              dell’utente non riflette necessariamente il punto di vista di GlobalEnglish. In nessun caso GlobalEnglish
              potà essere ritenuta responsabile, per qualsiasi motivo, del Contenuto dell’utente o della sua perdita, o
              di eventuali reclami, danni o perdite risultanti dal loro utilizzo o perdita.
            </b>
          </span>
          <br />
          <br />
          <b>7.3 Comportamento richiesto agli utenti</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Accettate di essere i soli responsabili di tutte le attività svolte nell’ambito del vostro account e di:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) contattare immediatamente GlobalEnglish qualora si verificasse l'eventuale utilizzo non autorizzato
              del vostro account, nonché eventuali altre violazioni in materia di sicurezza;
              <br /> (b) assicurarvi di effettuare l’uscita dal vostro account al termine di ogni sessione.
            </span>
            <br />
            <br />
            GlobalEnglish non può essere e non sarà ritenuta responsabile di alcuna perdita o danno derivante dal
            mancato rispetto di queste indicazioni.
            <br />
            <br />
            Riconoscete di essere i soli responsabili a garantire che le presenti Condizioni rispettino tutte le leggi,
            le norme e i regolamenti a voi applicabili e che il diritto di accedere al Servizio è revocato laddove i
            presenti termini di Servizio o l'uso del Servizio siano vietati o nella misura in cui l’offerta, la vendita
            o la fornitura del Servizio sia in conflitto con qualsiasi legge, norma o regolamento applicabile.
          </span>
          <br />
          <br />
          <b>7.4 Comportamento proibito agli utenti</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Di seguito sono riportati esempi del tipo di utilizzo che GlobalEnglish ritiene illegale o vietato.
            GlobalEnglish si riserva il diritto di indagare e intraprendere azioni legali appropriate contro chiunque
            violi, a sola discrezione di GlobalEnglish, questa disposizione, incluso, senza limitazioni, il diritto di
            eliminare il contenuto offensivo dal sito web o dall'applicazione, di sospendere o chiudere l'account di
            tali Utenti e di segnalarli alle forze dell'ordine.
            <br /> Non vi è permesso:
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) adattare, scaricare, rivedere, trasmettere, decodificare, duplicare, pubblicare, modificare,
              divulgare, visualizzare, trasferire o altrimenti copiare e/o distribure qualsiasi Contenuto di
              GlobalEnglish o altro materiale del Servizio, se non espressamente autorizzati da GlobalEnglish;
              <br />
              (b) utilizzare tecniche di framing per racchiudere qualsiasi parte del Servizio;
              <br />
              (c) raccogliere, ottenere, utilizzare, accedere o altrimenti copiare qualsiasi parte del Servizio
              utilizzando qualsiasi bot, spider, crawler, spyware, motore, dispositivo, software o qualsiasi altro
              dispositivo automatico, utility o processo manuale di qualsiasi tipo;
              <br />
              (d) utilizzare i Servizi o le funzionalità disponibili sui Servizi in qualsiasi modo con l'intento di
              interrompere, danneggiare, disabilitare, sovraccaricare o compromettere i Servizi;
              <br />
              (e) vendere, concedere in licenza, noleggiare o utilizzare in altro modo i Servizi per scopi commerciali;
              <br />
              (f)intraprendere qualsiasi attività che interferisca con l'accesso, l'utilizzo o la fruizione di questo
              Servizio da parte di un altro Utente;
              <br />
              (g) pubblicare, inviare per e-mail, o comunque trasmettere qualsiasi Contenuto dell’utente che sia
              considerato (i) illegale, dannoso, minaccioso, offensivo, assillante, illecito, eccessivamente violento,
              diffamatorio, volgare, osceno, indecente, pornografico, calunnioso, invasivo della privacy altrui (fino a,
              ma non escludendo, recapiti, e-mail, numeri di telefono e altre informazioni di contatto), che istighi
              all'odio o che sottintenda discriminazioni razziali, etniche o comunque sgradevoli; (ii) che non si è
              autorizzati a distribuire per legge, per contratto o in virtù di relazioni di fiducia; (iii) che ponga o
              crei un rischio per la privacy o la sicurezza di qualsiasi persona; (iv) che violi qualsiasi diritto di
              proprietà intellettuale o altri diritti di proprietà di qualunque parte; (v) che costituisca materiale
              pubblicitario e promozionale non richiesti o non autorizzati, attività e/o vendite commerciali, “junk
              mail”, “spam”, “catene di S. Antonio”, “schemi piramidali”, “concorsi”, “lotterie” o altri messaggi
              contenenti richieste; (vi) che contenga virus informatici o altro codice informatico, file o programmi
              creati per interrompere, distruggere o limitare la funzionalità dei componenti hardware o software di
              computer o apparecchiature per le telecomunicazioni; o, (vii) a esclusivo giudizio di GlobalEnglish, sia
              discutibile, limiti o inibisca qualsiasi altra persona dall'utilizzare o fruire dei Servizi, o che possa
              esporre GlobalEnglish o i suoi utenti a eventuali danni o responsabilità di qualsiasi tipo;
              <br />
              (h) creare una falsa identità, travisare la vostra identità, presentarvi come una persona o una società
              diversa, fare dichiarazioni false o comunque presentare in modo fuorviante i rapporti con altre persone o
              società;
              <br />
              (i) richiedere i dati personali di persone di età inferiore ai 18 anni;
              <br />
              (j) danneggiare i minori in qualsiasi modo;
              <br />
              (k) incoraggiare altri a causare danni fisici o lesioni contro qualsiasi gruppo o individuo, perseguitare
              o altrimenti molestare chiunque;
              <br />
              (l) fornire o promuovere informazioni contenenti istruzioni relative ad attività illegali come, ad
              esempio, l’assemblaggio di bombe, granate e altre armi o dispositivi incendiari;
              <br />
              (m) raccogliere dal Sito web, tramite l'uso di script automatizzati o in altro modo, i contenuti del sito
              web o gli indirizzi e-mail, le informazioni di contatto o altre informazioni private degli altri Utenti
              per qualsiasi scopo, incluso, a titolo esemplificativo, per l'invio agli Utenti di e-mail indesiderate o
              di altre comunicazioni non richieste o per la riproduzione del contenuto del sito web;
              <br />
              (n) pubblicizzare od offrire di vendere o acquistare beni o servizi per qualsiasi scopo commerciale non
              specificamente autorizzato;
              <br />
              (o) provocare interferenze o interruzioni al sito web, ai server o alle reti collegate al sito web, o
              violare requisiti, procedure, politiche o regolamenti delle reti collegate al sito web;
              <br />
              (p) violare le leggi locali, statali, nazionali o internazionali applicabili, compresi i regolamenti
              aventi valore di legge; incoraggiare o promuovere qualsiasi attività o impresa criminale, o fornire
              istruzioni relative ad attività illegali;
              <br /> (q) ottenere o tentare di accedere o comunque acquisire materiali o informazioni mediante mezzi non
              esplicitamente resi disponibili su o forniti tramite il sito web;
              <br />
              (r) ignorare le funzionalità di sicurezza o eludere i controlli di accesso;
              <br />
              (s) utilizzare i Servizi in maniera tale da creare un conflitto di interessi o compromettere le finalità
              dei Servizi, come ad esempio effettuare lo scambio di recensioni con altri Utenti, o scrivere o
              incoraggiare la pubblicazione di recensioni positive.
            </span>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. DIRITTI DI PROPRIETÀ INTELLETTUALE </b>
          <br />
          <br />
          <b>8.1 Diritti di proprietà intellettuale di GlobalEnglish </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            L’intero contenuto dei Servizi, che includono il Contenuto e qualsiasi altro software di supporto, sono di
            proprietà di GlobalEnglish, dei suoi concessori di licenza o dei suoi subcontraenti e sono protetti dalle
            leggi statunitensi e internazionali in materia di copyright e altre proprietà intellettuali.
            <br />
            <br /> È severamente vietato riprodurre, ridistribuire, modificare o pubblicare alcuna parte dei Servizi
            senza previa autorizzazione scritta da parte di GlobalEnglish e/o dei suoi concessori di licenza o di
            quant’altro indicato a seguito.
            <br />
            <br /> Gli Utenti autorizzati potranno accedere e utilizzare i Servizi e il Contenuto e scaricare o stampare
            un ragionevole numero di copie di qualsiasi porzione del Contenuto al quale l’Utente ha ottenuto l’accesso
            in modo legittimo, solo per uso personale dell’Utente e ai fini dell’apprendimento della lingua, a
            condizione che l’Utente mantenga inalterate tutte le note sul copyright o sugli altri diritti di proprietà
            riportate sulle copie di tale Contenuto.
            <br />
            <br /> Se non diversamente indicato, i marchi commerciali che appaiono sui Servizi sono marchi di
            GlobalEnglish, dei suoi concessori di licenza o delle sue affiliate. Accettate di non visualizzare,
            denigrare o contaminare i marchi, né di utilizzare marchi simili che potrebbero causare confusione o di
            usarli in un modo che potrebbe rappresentare in maniera ingannevole la proprietà di tali marchi o creare
            l'impressione che GlobalEnglish approvi qualsiasi prodotto o servizio. Non è consentito riprodurre o
            utilizzare i marchi, i nomi commerciali e/o le vesti commerciali di GlobalEnglish senza previa
            autorizzazione scritta di GlobalEnglish. Tutti gli avviamenti generati dall’utilizzo dei marchi di
            GlobalEnglish andranno a nostro beneficio esclusivo.
            <br />
            <br /> Qualsiasi utilizzo dei siti web, delle applicazioni o del relativo Contenuto, salvo quanto
            espressamente stabilito nel presente documento, è vietato e comporterà la cessazione automatica dei vostri
            diritti in relazione all'utilizzo dei Servizi e del Contenuto ivi concessi. Tutti i diritti di GlobalEnglish
            o dei suoi concessori di licenza che non siano espressamente concessi nelle presenti Condizioni sono
            riservati a GlobalEnglish e ai suoi concessori di licenza.
          </span>
          <br />
          <br />
          <b>8.2 Marchi di terzi </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            I nomi e i loghi di prodotti e Servizi utilizzati e visualizzati tramite il Sito web, diversi dai marchi di
            GlobalEnglish descritti sopra, possono essere marchi commerciali o marchi di servizio dei rispettivi
            proprietari, i quali possono o meno sostenere, essere affiliati o collegati a GlobalEnglish. Niente di
            quanto contenuto nelle presenti Condizioni e nel sito web va interpretato come concessione, mediante
            implicazione, preclusione o altro, di qualsivoglia licenza o diritto di utilizzare i marchi visualizzati sul
            Sito web senza la nostra previa autorizzazione scritta di volta in volta. Tutti gli avviamenti generati
            dall’utilizzo dei marchi di GlobalEnglish andranno a nostro beneficio esclusivo.
          </span>
          <br />
          <br />
          <b>8.3 Reclami sul copyright ai sensi della normativa Digital Millennium Copyright Act</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Se avete dei dubbi riguardo il copyright di qualsiasi materiale pubblicato sui Servizi da altri, vi
            preghiamo di farcelo sapere.
            <br />
            <br /> GlobalEnglish esaminerà le comunicazioni relative a presunte violazioni e prenderà i provvedimenti
            opportuni ai sensi della Digital Millennium Copyright Act ("DMCA") e di altre leggi applicabili in materia
            di proprietà intellettuale, in relazione a qualsiasi presunta o effettiva violazione.
            <br />
            <br /> Le comunicazioni relative ad eventuali violazioni del diritto di copyright vanno inviate via posta
            elettronica all’agente preposto di GlobalEnglish all’indirizzo copyright@globalenglish.com (Oggetto: “DMCA
            Takedown Request”).
            <br />
            <br /> Potete anche contattarci via posta ordinaria al seguente indirizzo:
            <br />
            <b>Attn: Copyright Complaints/Legal Department</b>
            <br /> 1875 South Grant Street, Suite 700
            <br /> San Mateo, CA 94402 USA
            <br />
            <br />
            <br /> Per essere efficace, la comunicazione deve avvenire per iscritto e contenere le seguenti
            informazioni: (i) la firma fisica o elettronica di una persona autorizzata ad agire per conto del
            proprietario del diritto di copyright o altro diritto di proprietà intellettuale; (ii) una descrizione
            dell'opera protetta da copyright o da altra proprietà intellettuale di cui si contesta la violazione; (iii)
            una descrizione della posizione del materiale oggetto della supposta violazione all'interno del Servizio,
            con sufficienti dettagli per poterlo rintracciare sul sito web; (iv) sufficienti informazioni perché
            GlobalEnglish possa contattarvi (indirizzo, numero di telefono ed e-mail); (v) la dichiarazione da voi resa
            che siete convinti in buona fede che l'uso del materiale contestato non sia autorizzato dal proprietario del
            copyright o della proprietà intellettuale, da un suo agente o dalla legge; (vi) la dichiarazione da voi resa
            sotto giuramento che le informazioni contenute nella notifica sono esatte, che siete il proprietario del
            copyright o di altro diritto di proprietà intellettuale, ovvero siete autorizzati ad agire per conto del
            proprietario del copyright o di altro diritto di proprietà intellettuale.
            <br />
            <br /> In conformità con la DMCA e le altre leggi applicabili, GlobalEnglish ha adottato una politica di
            interruzione, in circostanze opportune e a discrezione esclusiva di GlobalEnglish, degli account degli
            Utenti che sono considerati trasgressori recidivi. GlobalEnglish può anche, a sua sola discrezione, limitare
            l'accesso al Sito web e/o interrompere i privilegi di qualsiasi utente che violi i diritti di proprietà
            intellettuale di altri, indipendentemente dal fatto che vi siano o meno violazioni ripetute.
          </span>
          <br />
          <br />
          <b>8.4 Eliminazione del Contenuto dell’utente in violazione sospetta</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Se GlobalEnglish ha rimosso una parte del vostro Contenuto dell'utente perché sospettato di violare i
            diritti di proprietà intellettuale di terzi e ritenete che il Contenuto dell’utente che è stato rimosso (o
            che è stato disattivato) non violi i diritti, ovvero di avere l'autorizzazione del proprietario del
            copyright, dell'agente del proprietario del copyright o ai sensi della legge di poter pubblicare e
            utilizzare i contenuti del vostro Contenuto dell'utente, potrete presentare all’agente del copyright una
            contro-notifica scritta contenente le seguenti informazioni: (i) la vostra firma fisica o elettronica; (ii)
            una descrizione dettagliata del materiale rimosso da o disattivato e la posizione in cui il contenuto
            appariva prima di essere rimosso o disattivato; (iii) la dichiarazione da voi resa che siete convinti in
            buona fede che il contenuto materiale è stato rimosso o disattivato in seguito a errore o errata
            identificazione del contenuto; (iv) il vostro nome, indirizzo, numero di telefono e indirizzo di posta
            elettronica, e una dichiarazione di consenso alla giurisdizione del Tribunale federale del Northern District
            of California e una dichiarazione di accettazione di notificazione di atti processuali dal terzo che ha
            fornito la notifica della sospetta violazione.
            <br />
            <br /> Se l'Agente del copyright riceve una contro-notifica, GlobalEnglish invierà una copia della
            contro-notifica alla parte reclamante originale, al fine di informare tale persona che potrà sostituire il
            contenuto rimosso o cessarne la disattivazione entro 10 giorni lavorativi. A meno che il proprietario del
            copyright non presenti un'azione in attesa di un'ingiunzione del tribunale contro il fornitore di contenuti,
            il membro o l'utente, il contenuto rimosso potrà essere ripristinato, a nostra esclusiva discrezione, entro
            10-14 giorni lavorativi o più, dopo il ricevimento della contro-notifica.{' '}
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            {' '}
            9. UTILIZZO INTERNAZIONALE E CONTROLLO SULL’ESPORTAZIONE{' '}
          </b>
          <br />
          <br /> Riconoscendo la natura globale di Internet, accettate di rispettare tutte le norme locali relative alla
          condotta on-line e all’accettabile Contenuto dell'utente. Accettate di rispettare tutte le leggi applicabili
          relative alla trasmissione di dati tecnici esportati dagli Stati Uniti o dal Paese in cui risiedete.
          <br />
          <br /> Riconoscete che tutti o una parte dei Servizi può essere soggetta alle leggi statunitensi per il
          controllo dell’esportazione. Accettate di non esportare, riesportare o trasferire alcuna porzione dei Servizi,
          né alcuna relativa informazione tecnica o alcun materiale, direttamente o indirettamente in violazione alla
          legge o alla normativa vigente in materia di esportazione. Dichiarate e garantite di non trovarvi e di non
          essere cittadini o residenti di un Paese sul quale gli Stati Uniti hanno posto l'embargo o che è stato
          indicato dal governo statunitense come Paese “sostenitore del terroristmo” e che non comparite su nessuna
          lista statunitense di persone bandite o limitate.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. PROVVEDIMENTO INGIUNTIVO </b>
          <br />
          <br /> Riconoscete che qualsiasi utilizzo del Servizio contrario alle Condizioni d'utilizzo potrà causare
          danni irreparabili a GlobalEnglish, alle sue affiliate, ai suoi concessori di licenza, subcontraenti,
          rivenditori e Utenti. In tali circostanze, GlobalEnglish, le sue affiliate, i suoi concessori di licenza,
          subcontraenti, rivenditori e Utenti avranno diritto a un provvedimento equo senza l’obbligo di cauzione o
          altra garanzia, incluso, a titolo esemplicativo, un provvedimento ingiuntivo preliminare e permanente.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11. TEMPO D’INATTIVITÀ </b>
          <br />
          <br /> Di tanto in tanto, l’accesso ai Servizi potrà essere temporaneamente sospeso per interventi di
          manutenzione o per altri motivi. GlobalEnglish non sarà ritenuta responsabile di eventuali interruzioni,
          ritardi nell'utilizzo o nella trasmissione, furto o distruzione, accesso non autorizzato o alterazione delle
          comunicazioni dell'Utente o di qualsiasi altro contenuto reso disponibile tramite il sito web.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12. ESLUSIONE DI RESPONSABILITÀ </b>
          <br />
          <br /> Di tanto in tanto, l’accesso ai Servizi potrà essere temporaneamente sospeso per interventi di
          manutenzione o per altri motivi. GlobalEnglish non non sarà ritenuta responsabile di eventuali interruzioni,
          ritardi nell'utilizzo o nella trasmissione, furto o distruzione, accesso non autorizzato o alterazione delle
          comunicazioni dell'Utente o di qualsiasi altro contenuto reso disponibile tramite i Servizi. In nessun caso
          GlobalEnglish sarà ritenuta responsabile in alcun modo di lesioni personali o morte risultanti dall'uso dei
          Servizi, dei Contenuti dell'utente o dei Contenuti di terzi, o dall'utilizzo di prodotti o servizi forniti
          dagli Utenti.
          <br />
          <br /> RICONOSCETE E CONCORDATE CHE:
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            {' '}
            (a) I SERVIZI, IVI COMPRESI TUTTI I CONTENUTI, SONO FORNITI “TALI E QUALI” E SE DISPONIBILI.
            <br />
            <br /> (b) GLOBALENGLISH NON RICONOSCE ALCUNA DICHIARAZIONE O GARANZIA RELATIVA AI SERVIZI, ESPLICITA O
            IMPLICITA, INCLUSE LE GARANZIE IMPLICITE DI TITOLI, COMMERCIABILITÀ, IDONEITÀ A UN PARTICOLARE SCOPO E DI
            NON VIOLAZIONE.
            <br />
            <br /> (c) GLOBALENGLISH NON GARANTISCE E NON PROMETTE ALCUN RISULTATO SPECIFICO DALL’UTILIZZO DEI SERVIZI,
            NÉ CHE I SERVIZI SODDISFINO LE VOSTRE ESIGENZE O ASPETTATIVE.
            <br />
            <br />
            (d) GLOBALENGLISH NON RAPPRESENTA O GARANTISCE CHE IL SOFTWARE, IL CONTENUTO O I MATERIALI PRESENTI NEI
            SERVIZI O SUL SITO WEB SIANO ACCURATI, COMPLETI, AFFIDABILI, ATTUALI O PRIVI DI ERRORI, NÉ CHE IL SITO WEB,
            I SERVIZI O I SERVER SIANO PRIVI DI VIRUS O DI ALTRI COMPONENTI NOCIVI. PERTANTO, SENZA LIMITARE QUANTO
            SOPRA, RICONOSCETE E ACCETTATE DI SCARICARE, OD OTTENERE IN ALTRO MODO, A VOSTRA DISCREZIONE E A VOSTRO
            RISCHIO, I CONTENUTI, MATERIALI, DATI O SOFTWARE RESI DISPONIBILI DAI SERVIZI O DA UN FORNITORE, E CHE
            SARETE GLI UNICI RESPONSABILI DEL VOSTRO UTILIZZO DEGLI STESSI E DI QUALSIASI DANNO AL SISTEMA DEL VOSTRO
            COMPUTER, L’EVENTUALE PERDITA DI DATI O ALTRI DANNI DI QUALSIASI TIPO CHE NE POTREBBE RISULTARE
            <br />
            <br /> (e) GLOBALENGLISH NOT GARANTISCE CHE GLI EVENTUALI ERRORI O DIFETTI DEI SERVIZI SARANNO CORRETTI.
            <br />
            <br />
            (f) GLOBALENGLISH NON È RESPONSABILE DEL COMPORTAMENTO DEGLI UTENTI, DEL CONTENUTO DI TERZI E DEL CONTENUTO
            DEGLI UTENTI.
            <br />
            <br />
            (g) Di tanto in tanto, i Servizi potranno essere temporaneamente sospesi per interventi di manutenzione o
            per altri motivi. GlobalEnglish non sarà ritenuta responsabile di eventuali interruzioni, ritardi
            nell'utilizzo o nella trasmissione, furto o distruzione, accesso non autorizzato o alterazione delle
            comunicazioni dell'Utente o di qualsiasi altro contenuto reso disponibile tramite i Servizi. In nessun caso
            GlobalEnglish sarà ritenuta responsabile in alcun modo di lesioni personali o morte risultanti dall'uso dei
            Servizi, dei Contenuti dell'utente o dei Contenuti di terzi, o dall'utilizzo di prodotti o servizi forniti
            dagli Utenti.
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. LIMITAZIONI DI RESPONSABILITÀ</b>
          <br />
          <br /> GLOBALENGLISH O I SUOI AMMINISTRATORI, DIPENDENTI, AGENTI, CONCESSORI DI LICENZA O SUBCONTRAENTI NON
          SARANNO IN ALCUN CASO RESPONSABILI NEI VOSTRI CONFRONTI, O NEI CONFRONTI DI TERZI, DI EVENTUALI DANNI
          INDIRETTI, CONSEGUENTI, ESEMPLARI, INCIDENTALI, SPECIALI O PUNITIVI, INCLUSE LE PERDITE DI PROFITTI O DI DATI
          DERIVANTI: (I) DAL VOSTRO UTILIZZO O DALL’IMPOSSIBILITÀ DI UTILIZZARE I SERVIZI O QUALSIASI CONTENUTO O ALTRI
          MATERIALI DISPONIBILI SU, ACCESSIBILI TRAMITE O SCARICABILI DAL SITO WEB, DAI SERVIZI O DAI FORNITORI; (II) IL
          COSTO DELL'APPROVVIGIONAMENTO DI BENI E SERVIZI SOSTITUTIVI DERIVANTI DA BENI, DATI, INFORMAZIONI O SERVIZI
          ACQUISTATI O OTTENUTI, O MESSAGGI RICEVUTI O TRANSAZIONI STIPULATE TRAMITE O DAL SITO WEB; (III) ACCESSO NON
          AUTORIZZATO A O ALTERAZIONE DELLE VOSTRE TRASMISSIONI O DATI EFFETTUATO DA QUALUNQUE PARTE; (IV) DICHIARAZIONI
          O COMPORTAMENTI DI TERZE PARTI SUL SITO WEB; O (V) QUALSIASI ALTRA QUESTIONE RELATIVA AI SERVIZI.
          <br />
          <br /> LA RESPONSABILITÀ COMPLESSIVA DI GLOBALENGLISH NEI VOSTRI CONFRONTI PER TUTTI I DANNI, PERDITE O AZIONI
          LEGALI NON POTRÀ IN ALCUN CASO ESSERE SUPERIORE ALL’AMMONTARE CHE AVRETE VERSATO A GLOBALENGLISH NEGLI ULTIMI
          DODICI (12) MESI, OVVERO, SE MAGGIORE, CENTO DOLLARI STATUNITENSI ($100), ANCHE SE GLOBALENGLISH È CONSAPEVOLE
          O È STATA INFORMATA DELL’EVENTUALITÀ DI TALI DANNI. L'ESISTENZA DI PIÙ DI UNA RICHIESTA NON AUMENTERÀ TALE
          LIMITE.
          <br />
          <br /> ALCUNI ORDINAMENTI GIURIDICI NON CONSENTONO L’ESCLUSIONE DI DETERMINATE GARANZIE O LA LIMITAZIONE O
          L’ESCLUSIONE DI RESPONSABILITÀ PER DANNI ACCESSORI O CONSEGUENTI. QUINDI TALI LIMITAZIONI POTREBBERO NON
          ESSERE APPLICATE NEL VOSTRO CASO.
          <br />
          <br /> SE NON SIETE SODDISFATTI DI QUALSIASI PARTE DEL SITO WEB O DEI TERMINI RELATIVI AL SITO WEB, L'UNICO ED
          ESCLUSIVO RIMEDIO È DI INTERROMPERE L'UTILIZZO DEL SITO WEB.
          <br />
          <br /> GLOBALENGLISH NON POTRÀ ESSERE RITENUTA IN ALCUN CASO RESPONSABILE DI LESIONI PERSONALI O DI MORTE
          DERIVANTI DALL'UTILIZZO DEI SERVIZI O DEI CONTENUTI DI TERZI O DEGLI UTENTI.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14. TERMINI DI PRESCRIZIONE </b>
          <br />
          <br />
          Accettate che, indipendentemente da qualsiasi statuto o legge che stabilisca il contrario, qualsiasi reclamo o
          diritto di agire in giudizio nei confronti di GlobalEnglish, derivante da o in relazione al vostro utilizzo
          dei Servizi, deve essere presentato entro un (1) anno dall’evento su cui si basa tale reclamo o causa legale,
          pena l’esclusione.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>15. INDENNIZZO </b>
          <br />
          <br />
          Accettate di difendere, indennizzare ed esentare GlobalEnglish e le sue affiliate, e i loro responsabili,
          dipendenti, dirigenti e agenti, da qualsiasi reclamo, azione, costo, responsabilità, perdita, danno o spesa
          (comprese le ragionevoli spese legali) presentata da terzi nei confronti di GlobalEnglish e derivante da o
          relativa all’utilizzo da parte vostra del sito web, dei Servizi o del vostro Contenuto dell’utente, nonché
          alla vostra connessione al sito web o ai Servizi, o alla violazione da parte vostra delle presenti Condizioni
          o dei diritti nei confronti di un altro Utente, o di qualsiasi legge o derivante in altro modo dal vostro
          utilizzo dei Servizi. I vostri obblighi di indennizzo comprendono, senza limitazioni, i reclami nei confronti
          di GlobalEnglish secondo cui qualsiasi Contenuto dell'utente viola i diritti di proprietà intellettuale di una
          terza parte.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>16. CESSAZIONE </b>
          <br />
          <br /> Se, per qualsiasi motivo, il vostro abbonamento viene concluso, GlobalEnglish disattiverà il vostro
          accesso ai Servizi. Non vi sarà possibile accedere ad alcuno dei Contenuti, incluso il vostro Contenuto
          dell'utente, sebbene le copie di tali dati rimarranno nel nostro sistema a scopo di backup per tre (3) anni.
          <br />
          <br /> Se richiedete a GlobalEnglish di cancellare i vostri Dati personali e il vostro Contenuto dell'utente,
          faremo tutti gli sforzi ragionevoli per soddisfare la richiesta e vi daremo conferma dell’avvenuta
          cancellazione.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>17. RINUNCIA E SEPARABILITÀ </b>
          <br />
          <br /> Il mancato esercizio o la mancata esecuzione da parte di GlobalEnglish di qualsiasi diritto o clausola
          riportata nelle presenti Condizioni non costituirà una rinuncia di tale diritto o clausola in quella o in
          qualsiasi altra istanza. Qualora una disposizione delle presenti Condizioni d’utilizzo risultasse illegale,
          non valida o non applicabile per qualsiasi motivo, tale determinazione non interferirà con la validità e
          l’applicabilità delle rimanenti disposizioni.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>18.INTERO ACCORDO </b>
          <br />
          <br /> Il presente Accordo costituisce tutti i termini e le condizioni concordati tra voi e GlobalEnglish in
          relazione all’utilizzo dei Servizi e sostituisce qualsiasi accordo o intesa precedente tra voi e GlobalEnglish
          in relazione al vostro utilizzo dei Servizi.
          <br />
          <br /> Fermo restando quanto sopra, se la vostra azienda avesse già sottoscritto un accordo con GlobalEnglish
          per l'acquisto di qualsiasi Servizio, le presenti Condizioni non avranno lo scopo di modificare i termini o le
          condizioni dell’accordo che la vostra azienda intrattiene con GlobalEnglish, le sue controllate o affiliate.
          Nell’eventualità che esista un conflitto, prevarranno i termini di tale Accordo.
          <br />
          <br /> Il presente Accordo è stato redatto in lingua inglese e tale versione ne controllerà tutti gli aspetti.
          Qualsiasi versione non in inglese del presente Accordo è fornita esclusivamente per comodità. Una versione
          stampata del presente accordo e di qualsiasi avviso fornito in formato elettronico sono ammissibili in
          procedimenti giudiziari o amministrativi in base o in relazione al presente accordo, nella stessa misura e
          alle stesse condizioni degli altri documenti e registrazioni commerciali originariamente prodotti e conservati
          in forma stampata. I titoli delle sezioni delle presenti Condizioni sono forniti solo per comodità e non hanno
          alcun effetto legale o contrattuale.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>19. SCELTA DI GIURISDIZIONE, CORTE E ARBITRATO </b>
          <br />
          <br />
          Le presenti Condizioni saranno disciplinate dalla legislazione vigente nello Stato della Callfornia, a
          prescindere da eventuali conflitti con disposizioni di legge.
          <br />
          <br /> A esclusiva discrezione e decisione di GlobalEnglish, eventuali cause, reclami o controversie,
          derivanti da o collegate alle presenti Condizioni o al Sito web, che non sono risolte di comune accordo,
          potranno essere risolte mediante un arbitrato vincolante da svolgersi dinanzi a JAMS o a chi ne assumerà le
          funzioni. Salvo diverso accordo tra le parti, l'arbitrato si terrà a San Francisco, in California, di fronte a
          un singolo arbitro concordato di comune accordo tra le parti o, qualora le parti discordassero, un singolo
          arbitro nominato da JAMS, e sarà condotto in conformità con le norme e i regolamenti promulgati da JAMS, salvo
          eventuali modifiche specificate nelle presenti Condizioni. Un eventuale arbitrato dovrà essere iniziato entro
          quarantacinque (45) giorni dalla data di presentazione della domanda di arbitrato da una delle parti. Il lodo
          arbitrale sarà emesso entro sessanta (60) giorni dalla conclusione del procedimento arbitrale ed entro sei (6)
          mesi dalla selezione dell'arbitro. L'arbitro non avrà il potere di concedere alcun risarcimento danni
          eccedente il limite degli effettivi danni compensativi e diretti stabiliti dalle Condizioni e non potrà
          moltiplicare i danni effettivi o accordare danni punitivi o altri danni che sono specificamente esclusi ai
          sensi delle Condizioni e ciascuna parte rinuncia irrevocabilmente a qualsiasi pretesa di tali danni. L'arbitro
          potrà, a sua discrezione, valutare costi e spese (comprese le spese legali di ragionevole importo e le spese
          della parte prevalente) nei confronti di qualsiasi parte di un procedimento. Qualsiasi parte che rifiuti di
          rispettare la decisione degli arbitri sarà responsabile dei costi e delle spese, incluse le spese legali,
          sostenute dall'altra parte per far rispettare il lodo. Fatto salvo quanto sopra, in caso di provvedimenti
          ingiuntivi provvisori o preliminari, ogni parte potrà procedere in giudizio senza l'arbitrato preliminare, al
          fine di evitare danni immediati e irreparabili. Le disposizioni di questa sezione arbitrale saranno
          applicabili in qualsiasi tribunale di una giurisdizione competente.
          <br />
          <br />
          Per quanto riguarda eventuali controversie o reclami non soggetti ad arbitrato, voi e GlobalEnglish accettate,
          come sopra indicato, di sottomettervi alla giurisdizione personale ed esclusiva dei tribunali statali e
          federali situati nella contea di San Francisco, in California.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. AVVISI E CONTATTI </b>
          <br />
          <br />
          Potrete chiedere la revoca del vostro account GlobalEnglish fornendo a GlobalEnglish la disdetta tramite il
          modulo disponibile nella sezione “Contact Us” (Contatta il Supporto Tecnico) alla pagina
          <a href="https://support.globalenglish.com/it-it/contact_support" rel="noopener noreferrer" target="_blank">
            https://support.globalenglish.com/it-it/contact_support
          </a>
          .GlobalEnglish potrà impedirvi l’accesso e l’utilizzo dei Servizi, incluso qualsiasi contenuto, in qualsiasi
          momento dopo la chiusura del vostro account.
          <br />
          <br />
          Le comunicazioni vi saranno inoltrate via e-mail oppure via posta ordinaria. Inoltre, il sito web vi potrà
          fornire avvisi relativi a eventuali modifiche alle presenti Condizioni o ad altre questioni mediante la
          visualizzazione di avvisi o di collegamenti a comunicazioni generalmente disponibili sul Servizio.
          <br />
          <br /> Le comunicazioni relative alla nostra politica sulla privacy possono essere inviate a
          <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
            privacy@globalenglish.com.
          </a>
          <br />
          <br /> Tutte le altre comunicazioni legali o la corrispondenza di altro tipo per GlobalEnglish dovrà essere
          inviata al seguente indirizzo di posta elettronica:
          <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
            legal@globalenglish.com
          </a>{' '}
          o al seguente recapito postale:
          <br />
          <br /> 1875 S Grant Street, Suite 700
          <br /> San Mateo, CA 94402
          <br />
          all’attenzione del
          <b>Legal Counsel</b>
          <br />
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>CONDIZIONI SPECIFICHE PER I RAPPORTI B2B </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          {' '}
          Se avete ottenuto il vostro account di GlobalEnglish tramite il vostro datore di lavoro, l'utilizzo dei nostri
          Servizi da parte vostra sarà soggetto anche al nostro accordo con la vostra azienda (l’"Azienda"). Le
          Condizioni d'utilizzo non sono intese a modificare i termini dell'accordo con la vostra Azienda. Non potrete
          utilizzare (o continuare a utilizzare) i Servizi se la vostra Azienda non vi ha autorizzato ad agire come
          utente finale della loro licenza con GlobalEnglish. Se ci chiedete di chiudere il vostro account, potrebbe
          essere necessario richiedere alla vostra Azienda di fornire il consenso a GlobalEnglish per poter chiudere il
          vostro account.
          <br />
          <br /> Accettate di utilizzare i Servizi esclusivamente secondo quanto consentito dalla vostra Azienda. Vi
          potrà essere data la possibilità di comunicare con altri all'interno della vostra Azienda o di caricare
          documenti relativi alla vostra Azienda attraverso i Servizi, ma non siete autorizzati a farlo, e non potrete
          farlo, senza l'autorizzazione della vostra Azienda.
          <br />
          <br /> Riconoscete e accettate che tutti i dati generati in relazione al vostro utilizzo dei Servizi ("Dati
          dell’utente") potranno essere resi accessibili alla vostra Azienda e che GlobalEnglish non avrà alcuna
          responsabilità nei vostri confronti in merito alle informazioni condivise con la vostra Azienda a seguito del
          vostro utilizzo dei nostri Servizi.
          <br />
          <br /> Poiché il vostro abbonamento per l’accesso ai Servizi è valido soltanto per la durata della licenza
          dell’Azienda, se la vostra Azienda decide di concludere la licenza con GlobalEnglish perderete l'accesso ai
          Servizi e al Contenuto dell'utente. GlobalEnglish potrà chiudere o sospendere l'account della vostra Azienda,
          il vostro account o la possibilità di utilizzare i Servizi, in tutto o in parte, senza preavviso nel caso in
          cui (i) la vostra Azienda non effetui i relativi pagamenti dovuti a GlobalEnglish entro i termini stabiliti, o
          (ii ) voi o la vostra Azienda violi le Condizioni o qualsiasi altra norma che disciplina l'utilizzo dei
          Servizi.
          <br />
          <br /> GlobalEnglish potrà avere l'obbligo di restituire alla vostra Azienda i dati relativi al vostro
          utilizzo dei Servizi, inclusi i Dati personali e il Contenuto dell'utente.. Se la vostra Azienda non richiede
          la restituzione o la cancellazione di tali dati, GlobalEnglish conserverà i dati per un periodo di tre (3)
          anni dalla scadenza o dalla risoluzione del contratto con il vostro datore di lavoro. In tale evenienza, vi
          sarà data la possibilità di acquistare un abbonamento al servizio direttamente da GlobalEnglish e continuare
          ad accedere al programma senza alcuna perdita di informazioni sui vostri progressi. Potrete autorizzare il
          trasferimento dei dati riguardanti il vostro progresso e il vostro profilo su un nuovo abbonamento con il
          vostro nuovo datore di lavoro. Potrete anche richiedere che tutti i dati relativi al vostro utilizzo dei
          Servizi vi vengano forniti in un formato compatibile con le importazioni future.
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>CONDIZIONI SPECIFICHE PER I RAPPORTI B2C </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          Potrete inoltre stipulare con GlobalEnglish un contratto di abbonamento su base individuale che potrà
          prevedere una sottoscrizione di tre (3) o sei (6) mesi, oppure un piano di abbonamento annuale o pluriennale
          (ciascuna tipologia definita con il termine "Piano di abbonamento").
          <br />
          <br /> La fatturazione per il relativo Piano di abbonamento verrà effettuata mensilmente o annualmente e
          porterà la stessa data, o la data prossima, ogni mese/anno (a seconda dei casi), fino al momento in cui si
          annullerà il Piano di abbonamento. A scanso di equivoci, vi preghiamo di notare che non vi sarà permesso di
          cancellare, ridurre il numero di posti o retrocedere dal tipo di Servizio selezionato fino all'anniversario
          del vostro Piano di abbonamento. Non sono previsti rimborsi dei pagamenti per il Piano di abbonamento.
          <br />
          <br /> Le tariffe applicabili al Servizio ("Tariffe") sono disponibili sul Sito web e/o nel listino prezzi più
          recente pubblicato da GlobalEnglish. Il prezzo indicato per il Servizio non è comprensivo di tasse e spese, se
          non diversamente specificato. Sarete responsabili di eventuali tasse e di qualsiasi altro onere accessorio
          all'utilizzo dei Servizi (ad esempio, addebito di dati e transazioni di cambio valuta). Effettuerete il
          pagamento delle Tariffe nella valuta che GlobalEnglish avrà indicato nel vostro account. GlobalEnglish si
          riserva il diritto di modificare in qualsiasi momento la valuta indicata.
          <br />
          <br />
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}> Pagamento</b>
          <br />
          <br />
          Il pagamento è dovuto al momento dell'acquisto di una licenza in abbonamento. Abbiamo stretto un rapporto di
          collaborazione con PayPal e con Stripe per la gestione dei pagamenti. Non riceviamo né elaboriamo alcuna
          informazione relativa alle carte di credito o ad altri metodi di pagamento da voi fornita a tali società di
          servizi.
          <br />
          <br /> Tutti i Servizi vengono acquistati con pagamento anticipato sulla base della periodicità selezionata
          (mensile, annuale o altro) e non sono rimborsabili. Ciò include gli account che vengono rinnovati.
          <br />
          <br /> -----
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>Avviso per gli Utenti della California </b>
          <br />
          <br />
          Se siete residenti in California, la sezione 1789.3 del Codice Civile della California vi permette di
          richiedere informazioni in merito alla divulgazione dei vostri Dati personali a terze parti a scopi di
          marketing diretto da parte di terzi. Anche la California Consumer Privacy Act (legge sulla privacy del
          consumatore), che entrerà in vigore nel 2021 (Codice civile, sezioni 1798.100-1709.198), vi conferisce
          determinati diritti, tra cui:
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            (a) il diritto di sapere quali Dati personali che vi riguardano sono stati raccolti e di riceverne copia,
            <br />
            <br />
            (b) il diritto di cancellazione dei dati,
            <br />
            <br />
            (c) il diritto di sapere a quali terzi sono stati venduti i vostri Dati personali e il diritto di richiedere
            che tale vendita cessi, e
            <br />
            <br />
            (d) il diritto di essere esenti da discriminazioni, nel caso in cui decidiaste di esercitare alcuni dei
            vostri diritti in quanto consumatori.
          </span>
          <br /> Inoltre, la sezione 22581 del California Business and Profession Code attualmente consente ai residenti
          californiani minori di età di richiedere la rimozione dei contenuti che hanno pubblicato sul Sito web. Si
          voglia notare, tuttavia, che GlobalEnglish non è in grado di garantire la rimozione completa o integra dei
          contenuti pubblicati e che potrebbero esservi circostanze in base alle quali la legge non ne prevede o
          consente la rimozione, anche se richiesta.
          <br />
          <br />
          Se desiderate inoltrare una richiesta per esercitare i vostri diritti:
          <br />
          <br />
          il provider del Sito web e dei servizi associati è GlobalEnglish Corporation, con sede a 1875 South Grant
          Street, Suite 700, San Mateo, CA 94402, USA. Indirizzo e-mail: privacy@globalenglish.com.
          <br />
          <br /> Alla data delle presenti Condizioni, il Sito web non prevede oneri diretti a carico del consumatore.
          <br />
          <br />
          Se avete un reclamo riguardo ai servizi forniti all’interno del sito web, o se desiderate ricevere ulteriori
          informazioni, potete contattarci all’indirizzo di cui sopra inviando una descrizione della richiesta. Non
          possiamo garantire una risposta entro un determinato periodo di tempo. Se vi ritenete ancora insoddisfatti,
          contattate la Complaint Assistance Unit della Division of Consumer Services del California Department of
          Consumer Affairs scrivendo al 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, o chiamando il
          numero +1 (916) 445-1254 oppure +1 (800) 952-5210.
        </p>
      </div>
    </div>
  );
}

export default ItItContent;
