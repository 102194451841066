// @ts-nocheck
import React from "react";
import { useCookies } from "react-cookie";
import Cookies from "universal-cookie";
import { domains } from "../../constants";

interface ListItemProps {
  text: string;
  languageTitle: string;
  className: string;
  id: string;
  languageCode: string;
}

const ListItem: React.SFC<ListItemProps> = (props) => {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(["ulang"]);

  function setUlangCookie() {
    setCookie("IsLangPicked", true, {
      path: "/",
      domain: domains.learnship[process.env.REACT_APP_ENV],
    });
    setCookie("language", props.languageCode.toUpperCase(), {
      path: "/",
      domain: domains.learnship[process.env.REACT_APP_ENV],
    });
    setCookie("ulang", props.languageCode.toUpperCase(), {
      path: "/",
      domain: domains.learnship[process.env.REACT_APP_ENV],
    });
  }
  const urlParams = new URLSearchParams(window.location.search);
  const language = urlParams.get("language");
  if (language) {
    urlParams.set("language", props.languageCode);
  } else {
    urlParams.append("language", props.languageCode);
  }

  let ref = "?" + urlParams.toString();

  const universalCookies = new Cookies();
  const ulang = universalCookies.get("ulang")
    ? universalCookies.get("ulang").toLowerCase()
    : "en";

  return (
    <li
      onClick={setUlangCookie}
      className={props.languageCode === ulang ? "active" : ""}
    >
      <a className="d1" href={ref} id={props.id}>
        <span className="view_lang">{props.text}</span>
        <span>{props.languageTitle}</span>
      </a>
    </li>
  );
};

interface LangPopupProps {
  handlePopup: Function;
}

export const LangPopup: React.SFC<LangPopupProps> = (props) => {
  return (
    <React.Fragment>
      <div style={{ display: "block" }} className="langList" id="pop_lang">
        <div className="lightbox"></div>
        <div className="overlay lang" style={{ margin: "125px 0 0 -285px" }}>
          <div className="olClose" id="close">
            <span onClick={props.handlePopup}></span>
          </div>
          <div className="olHolder">
            <div className="olContainer">
              <div className="olContent" id="popup_content">
                <div id="popup_content">
                  <div id="flist" className="normal">
                    <ul>
                      <ListItem
                        className="active"
                        text="View the Web site in "
                        languageTitle="English"
                        id="yt17"
                        languageCode="en"
                      />
                      <ListItem
                        className=""
                        text="Visualizzate il sito in "
                        languageTitle="italiano"
                        id="yt20"
                        languageCode="it-it"
                      />
                      <ListItem
                        className=""
                        text="Web sitesini "
                        languageTitle="Türkçe görüntüleyin"
                        id="yt28"
                        languageCode="tr-tr"
                      />
                    </ul>
                    <ul>
                      <ListItem
                        className=""
                        text="Website auf "
                        languageTitle="Deutsch anzeigen"
                        id="yt19"
                        languageCode="de-de"
                      />
                      <ListItem
                        className=""
                        text="Wyświetl witrynę internetową w języku "
                        languageTitle="polskim"
                        id="yt23"
                        languageCode="pl-pl"
                      />
                      <ListItem
                        className=""
                        text="Web サイトを "
                        languageTitle="日本語で見る"
                        id="yt21"
                        languageCode="ja-jp"
                      />
                    </ul>
                    <ul>
                      <ListItem
                        className=""
                        text="Vea el sitio Web en "
                        languageTitle="español (Latinoamérica)"
                        id="yt26"
                        languageCode="es-mx"
                      />
                      <ListItem
                        className=""
                        text="Veja o site em "
                        languageTitle="português"
                        id="yt24"
                        languageCode="pt-br"
                      />
                      <ListItem
                        className=""
                        id="yt15"
                        languageCode="zh-tw"
                        text="以中文（繁體) "
                        languageTitle="觀看網站"
                      />
                    </ul>
                    <ul>
                      <ListItem
                        className=""
                        id="yt27"
                        languageCode="es-es"
                        text="Vea el sitio Web en "
                        languageTitle="español (España)"
                      />
                      <ListItem
                        className=""
                        id="yt25"
                        languageCode="ru-ru"
                        text="Просмотреть сайт на "
                        languageTitle="русском языке"
                      />
                      <ListItem
                        className=""
                        id="yt14"
                        languageCode="zh-cn"
                        text="用中文(简体) "
                        languageTitle="观看网站"
                      />
                    </ul>
                    <ul>
                      <ListItem
                        className=""
                        id="yt18"
                        languageCode="fr-fr"
                        text="Afficher le site Web en "
                        languageTitle="français"
                      />
                      <ListItem
                        className=""
                        id="yt16"
                        languageCode="cz-cz"
                        text="Zobrazit stránku v "
                        languageTitle="češtině"
                      />

                      <ListItem
                        className=""
                        id="yt22"
                        languageCode="ko-ko"
                        text="한국어로 "
                        languageTitle="웹사이트 보기"
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
