/* eslint-disable */
// @ts-nocheck
import React from "react";

function EnContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>COPYRIGHT, TRADEMARK, and OTHER LEGAL NOTICES</b>
          </center>
        </div>
        <br />
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>GLOBALENGLISH COPYRIGHT</b>
          </p>
          <p>
            {" "}
            Copyright ©&nbsp;1999-<span id="demo">2021</span>
            GlobalEnglish Corporation, all rights reserved{" "}
          </p>
          <br />
          <p>
            {" "}
            <b>THIRD PARTY COPYRIGHTS</b>{" "}
          </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>, First Edition, 1995
            <br /> ©&nbsp;Longman Group UK Limited 1995{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> ©&nbsp;Longman Group Limited 1995{" "}
          </p>
          <p>
            {" "}
            Copyright ©&nbsp;1995 - 1998 Macromedia, Inc., all rights reserved{" "}
          </p>
          <p>
            {" "}
            Copyright ©&nbsp;1999 Microsoft Corporation, all rights reserved{" "}
          </p>
          <p>
            {" "}
            Copyright ©&nbsp;1999 Sipro Lab Telecom Inc., all rights reserved{" "}
          </p>
          <p>
            {" "}
            Copyright ©&nbsp;Business English Pod Limited, all rights reserved.
            All content, including podcasts, phrasecasts, videocasts, study
            notes, and audio e-books, are the property of Business English Pod
            Limited, used with permission by GlobalEnglish Corporation.{" "}
          </p>{" "}
          {/* WinterTree, EduSpeak and Systran  */}
          <p>
            {" "}
            WGrammar Grammar Checker Engine Copyright<sup>©</sup> 1998-2006
            Wintertree Software Inc. (
            <a
              href="http://www.wintertree-software.com"
              rel="noopener noreferrer"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.wintertree-software.com
            </a>
            ){" "}
          </p>
          <p>
            {" "}
            Includes EduSpeak<sup>®</sup> speech recognition software under
            license from SRI International<sup>®</sup>. Copyright<sup>©</sup>{" "}
            SRI International.{" "}
          </p>
          Systran Translation Engine<sup>&copy;</sup> SYSTRAN Software, Inc
          <p>
            {" "}
            Photos provided by AP/Wide World Photos are copyright protected and
            are the property of the Associated Press. Any use without prior
            permission from AP/Wide World Photos is prohibited. Any violation
            would be subject to legal action.{" "}
          </p>
          <p>
            {" "}
            Photos provided by PhotoDisc are copyright protected and are the
            property of PhotoDisc, Inc. Images ©&nbsp;copyright 1999 PhotoDisc,
            Inc.{" "}
          </p>
          <p>
            {" "}
            Copyright © Barron's Educational Series, Inc., from HOW TO PREPARE
            FOR THE TOEIC<sup>®</sup>{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i> ©&nbsp;1998 AMACOM NEW MEDIA, a wholly
            owned subsidiary of American Management Association, New York. All
            rights reserved.{" "}
          </p>
          <p>
            {" "}
            Clip art provided by Microsoft is copyright protected and is the
            property of Microsoft Corporation and/or its suppliers. Any use
            without prior permission from Microsoft is prohibited.{" "}
          </p>{" "}
          <p>
            {" "}
            Clip art provided by ArtToday is copyright protected and is the
            property of Zedcor, Inc., a wholly owned subsidiary of IMSI Inc.
            and/or its suppliers. Any use without prior permission from Zedcor
            is prohibited.{" "}
          </p>{" "}
          <p>
            {" "}
            Clip art provided by Eyewire is copyright protected and is the
            property of Eyewire Inc. and/or its suppliers. Any use without prior
            permission from Eyewire is prohibited.{" "}
          </p>{" "}
          <p>
            {" "}
            Photographs provided by Corbis are copyright protected and are the
            property of Corbis Corporation and/or its suppliers. Any use without
            prior permission from Corbis is prohibited.{" "}
          </p>{" "}
          <p>
            {" "}
            Photographs provided by ImageQuest are copyright protected and are
            the property of ImageQuest and/or its suppliers. Any use without
            prior permission from ImageQuest is prohibited.
          </p>
          <p>
            Audio in the TTS feature includes audio files generated using
            AT&amp;T
            <sup>®</sup> Natural Voices<sup>™</sup>. Copyright ©&nbsp; Wizzard
            Speech LLC. Any use without prior permission from Wizzard Speech is
            prohibited.
          </p>
          <p>
            <b>GLOBALENGLISH TRADEMARKS</b>
            <br />
            <br />
            The following are some of the trademarks owned by GlobalEnglish
            Corporation and the suggested generic terms for those trademarks in
            the United States and/or other countries. To the extent a name or
            logo does not appear on this list does not constitute a waiver of
            any and all intellectual property rights that GlobalEnglish
            Corporation or its subsidiaries has established in any of its
            product, feature, or service names or logos. For guidance on how to
            refer properly to GlobalEnglish product names and trademarks, please
            request a copy of the GlobalEnglish general trademark guidelines.
          </p>
          <p></p>
          <img
            alt=""
            src={require("../../../assets/images/ge_ls_logo.png")}
            alt="GE-Logo"
          />
          <br /> <br />
          <br />
          <p>
            <b>THIRD PARTY TRADEMARKS</b>
          </p>
          <p>
            {" "}
            Adobe and Flash are either trademarks or registered trademarks of
            Adobe Systems Incorporated in the United States and/or other
            countries
          </p>
          <p> Microsoft is a registered trademark of Microsoft Corporation. </p>
          <p>
            {" "}
            Living Language and Colophon are registered trademarks of Random
            House, Inc.{" "}
          </p>
          <p>
            {" "}
            Sipro and the Sipro logo are trademarks of Sipro Lab Telecom Inc.
            ACELP<sup>®</sup> is a registered trademark of the Université
            Sherbrooke used under license by Sipro Lab Telecom Inc.{" "}
          </p>{" "}
          {/* SRI and Systran */}
          <p> </p>
          <table>
            <tbody>
              <tr>
                {" "}
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/images/sri.gif")}
                    alt="gif"
                  />
                  <br />
                </td>{" "}
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International, the SRI International logo, EduSpeak, and
                  the EduSpeak logo are trademarks or registered trademarks of
                  SRI International.{" "}
                </td>{" "}
              </tr>
            </tbody>
          </table>
          <p></p>
          SYSTRAN is a registered trademark or service mark of SYSTRAN Software,
          Inc., and the SYSTRAN logo is a registered service mark of SYSTRAN
          Software, Inc.
          <p> AP/Wide World Photos is a trademark of the Associated Press. </p>
          <p> PhotoDisc is a registered trademark of PhotoDisc, Inc. </p>
          <p>
            {" "}
            Business English Pod, www.businessenglishpod.com, and Business
            English Pod logos are trademarks of Business English Pod Limited.{" "}
          </p>
          <p> All other trademarks are property of their respective owners. </p>
          <p></p>
        </div>
      </div>
    </>
  );
}

export default EnContent;
