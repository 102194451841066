// @ts-nocheck
import React from "react";

function ItItContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>Diritti d'autore, marchi di fabbrica e altri avvisi legali</b>
          </center>
        </div>
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>COPYRIGHT DI GLOBALENGLISH</b>
          </p>
          <p />
          <p>
            {" "}
            Copyright ©&nbsp;1999-2013 GlobalEnglish Corporation, tutti i
            diritti riservati{" "}
          </p>
          <br />
          <p>
            {" "}
            <b>COPYRIGHT DI TERZE PARTI</b>{" "}
          </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>, prima edizione, 1995
            <br /> ©&nbsp;Longman Group UK Limited 1995{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> ©&nbsp;Longman Group Limited 1995{" "}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p*/}
          <p>
            {" "}
            Copyright ©&nbsp;1995 - 1998 Macromedia, Inc., tutti i diritti
            riservati{" "}
          </p>
          <p>
            {" "}
            Copyright ©&nbsp;1999 Microsoft Corporation, tutti i diritti
            riservati{" "}
          </p>
          <p>
            {" "}
            Copyright ©&nbsp;1999 Sipro Lab Telecom Inc., tutti i diritti
            riservati{" "}
          </p>
          <p>
            {" "}
            Copyright ©&nbsp;Business English Pod Limited, tutti i diritti
            riservati. Tutti i contenuti, inclusi i podcast, i phrasecast, i
            videocast, le note di studio e gli e-book sono di proprietà di
            Business English Pod Limited, che ne autorizza l'uso da parte di
            GlobalEnglish Corporation.{" "}
          </p>
          <p>
            {" "}
            Motore per il controllo grammaticale WGrammar Copyright
            ©&nbsp;1998-2006 Wintertree Software Inc. (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.wintertree-software.com"
            >
              <div
                onmouseout="this.className='blu11'"
                onmouseover="this.className='blu11rllover'"
                className="blu11"
              >
                www.wintertree-software.com
              </div>
            </a>
            ){" "}
          </p>
          <p>
            {" "}
            Include il software di riconoscimento vocale EduSpeak<sup>®</sup> su
            licenza SRI International<sup>®</sup>. Copyright ©&nbsp; SRI
            International.{" "}
          </p>
          {/*p> Motore di traduzione Systran &copy;&nbsp;SYSTRAN Software, Inc </p*/}
          <p>
            {" "}
            Le foto concesse da AP/Wide World Photos sono protette da copyright
            e sono di proprietà di Associated Press. È vietato l'uso senza
            autorizzazione preventiva di AP/Wide World Photos. Ogni violazione
            verrà perseguita legalmente.{" "}
          </p>
          <p>
            {" "}
            Le foto fornite da PhotoDisc sono protette da copyright e sono di
            proprietà di PhotoDisc, Inc. Images ©&nbsp;copyright 1999 PhotoDisc,
            Inc.{" "}
          </p>
          <p>
            {" "}
            Copyright © Barron's Educational Series, Inc., tratto da HOW TO
            PREPARE FOR THE TOEIC<sup>®</sup>{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i> ©&nbsp;1998 AMACOM NEW MEDIA, consociata
            interamente appartenente a American Management Association, New
            York. Tutti i diritti riservati.{" "}
          </p>
          <p>
            {" "}
            La clipart fornita da Microsoft è protetta da copyright ed è di
            proprietà di Microsoft Corporation e/o dei suoi fornitori. È vietato
            l'uso senza autorizzazione preventiva di Microsoft.{" "}
          </p>
          <p>
            {" "}
            La clipart fornita da ArtToday è protetta da copyright ed è di
            proprietà di Zedcor, Inc., consociata interamente appartenente a
            IMSI Inc. e/o dei suoi fornitori. È vietato l'uso senza
            autorizzazione preventiva di Zedcor.{" "}
          </p>
          <p>
            {" "}
            La clipart fornita da Eyewire è protetta da copyright ed è di
            proprietà di Eyewire Inc. e/o dei suoi fornitori. È vietato l'uso
            senza autorizzazione preventiva di Eyewire.{" "}
          </p>
          <p>
            {" "}
            Le foto fornite da Corbis sono protette da copyright e sono di
            proprietà di Corbis Corporation e/o dei suoi fornitori. È vietato
            l'uso senza autorizzazione preventiva di Corbis.{" "}
          </p>
          <p>
            {" "}
            Le foto fornite da ImageQuest sono protette da copyright e sono di
            proprietà di ImageQuest e/o dei suoi fornitori. È vietato l'uso
            senza autorizzazione preventiva di ImageQuest.{" "}
          </p>
          <p />
          <br />
          <p>
            <b>Marchi registrati GlobalEnglish</b>
          </p>
          <p />
          <p>
            Di seguito sono elencati i marchi di fabbrica di proprietà di
            GlobalEnglish Corporation e i termini generici consigliati per tali
            marchi negli Stati Uniti e/o in altri Paesi. La mancata presenza di
            un nome o un logo all'interno di questa lista non costituisce una
            rinuncia ad alcuno dei diritti di proprietà intellettuale che
            GlobalEnglish Corporation o le sue consociate detengono sui loro
            prodotti, sulle loro funzionalità o sui loghi o i nomi dei loro
            servizi. Per indicazioni su come riferirsi correttamente ai marchi
            di fabbrica e ai nomi dei prodotti di GlobalEnglish, richiedere una
            copia delle linee guida generali sui marchi di GlobalEnglish.
          </p>
          <p />
          <br />
          <style
            dangerouslySetInnerHTML={{
              __html: " \t\ttd{padding:10px !important} \t\t",
            }}
          />
          <table
            border={1}
            cellPadding={10}
            cellSpacing={0}
            bordercolor="#808080"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  TradeMark
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Status
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  Enterprise Fluency
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Bloom
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Corporate Learning Service
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Edge
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo Pro
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Magazine
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Mobile Learning Network
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Productivity Toolbar
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP+
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Motivator
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Executive Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>MARCHI DI FABBRICA DI TERZE PARTI</b>
          </p>
          <p />
          <p>
            {" "}
            Adobe e Flash sono marchi di fabbrica o marchi registrati di Adobe
            Systems Incorporated negli Stati Uniti e/o in altri paesi.
          </p>
          <p> Flash è un marchio registrato di Microsoft Corporation. </p>
          {/*p> Langenscheidt KG &#232; un marchio registrato di Langenscheidt KG, Berlin and Munich. </p*/}
          <p>
            {" "}
            Living Language e Colophon sono marchi registrati di Random House,
            Inc.{" "}
          </p>
          <p>
            {" "}
            Sipro e il logo Sipro sono marchi registrati di Sipro Lab Telecom
            Inc. ACELP<sup>®</sup> è un marchio registrato di Université
            Sherbrooke, l'uso è concesso in licenza da Sipro Lab Telecom Inc.{" "}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International, il logo SRI International, EduSpeak e il
                  logo EduSpeak sono marchi o marchi registrati di SRI
                  International.
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p> SYSTRAN &#232; un marchio registrato o un marchio di servizio di SYSTRAN Software, Inc., e il logo SYSTRAN &#232; un marchio di servizio registrato di SYSTRAN Software, Inc.</p*/}
          <p>
            {" "}
            AP/Wide World Photos è un marchio registrato di Associated Press.{" "}
          </p>
          <p> PhotoDisc è un marchio registrato di PhotoDisc, Inc. </p>
          <p>
            {" "}
            I logo Business English Pod, www.businessenglishpod.com e Business
            English Pod sono marchi registrati di Business English Pod Limited.{" "}
          </p>
          <p>
            {" "}
            Tutti gli altri marchi registrati sono di proprietà dei rispettivi
            proprietari.{" "}
          </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default ItItContent;
