// @ts-nocheck
import React from "react";

function JaJpContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>著作権、商標、およびその他の法的通告</b>
          </center>
        </div>
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>GlobalEnglish の著作権</b>
          </p>
          <p />
          <p>
            {" "}
            Copyright © 1999-2013 GlobalEnglish Corporation, all rights reserved{" "}
          </p>
          <br />
          <p>
            <b>第三者の著作権</b>{" "}
          </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>, First Edition, 1995
            <br /> © Longman Group UK Limited 1995{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> © Longman Group Limited 1995{" "}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
   <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich</p*/}
          <p> Copyright © 1995 - 1998 Macromedia, Inc., all rights reserved </p>
          <p> Copyright © 1999 Microsoft Corporation, all rights reserved </p>
          <p> Copyright © 1999 Sipro Lab Telecom Inc., all rights reserved </p>
          <p>
            {" "}
            Copyright © Business English Pod Limited, all rights
            reserved.Podcast、Phrasecast、Videocast、学習メモ、オーディオ e-book
            を含むすべてのコンテンツは、Business English Pod Limited
            に帰属し、使用には GlobalEnglish Corporation の許可が必要です。{" "}
          </p>
          <p>
            {" "}
            WGrammar Grammar Checker Engine Copyright© 1998-2006 Wintertree
            Software Inc.（
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.wintertree-software.com"
            >
              <div
                onmouseout="this.className='blu11'"
                onmouseover="this.className='blu11rllover'"
                className="blu11"
              >
                www.wintertree-software.com
              </div>
            </a>
            ）{" "}
          </p>
          <p>
            {" "}
            SRI International<sup>®</sup> の実施許諾に準じた EduSpeak
            <sup>®</sup> 音声認識ソフトウェアを含みます。Copyright © SRI
            International.{" "}
          </p>
          {/*p> Systran Translation Engine&copy; SYSTRAN Software, Inc </p*/}
          <p>
            {" "}
            AP/Wide World Photos 提供の写真は著作権で保護されており、Associated
            Press に帰属します。AP/Wide World Photos
            からの事前の許可なしに使用することは禁じられています。違反した場合は、法的措置の対象になります。{" "}
          </p>
          <p>
            {" "}
            PhotoDisc 提供の写真は著作権で保護されており、PhotoDisc, Inc.
            に帰属します。Images © copyright 1999 PhotoDisc, Inc.{" "}
          </p>
          <p>
            {" "}
            Copyright © Barron's Educational Series, Inc.、HOW TO PREPARE FOR
            THE TOEIC<sup>®</sup>より抜粋{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i> © 1998 AMACOM NEW MEDIA、American
            Management Association, New York の全額出資子会社 All rights
            reserved.{" "}
          </p>
          <p>
            {" "}
            Microsoft 提供のクリップアートは著作権で保護されており、Microsoft
            Corporation および／またはそのサプライヤに帰属します。Microsoft
            からの事前の許可なしに使用することは禁じられています。{" "}
          </p>
          <p>
            {" "}
            ArtToday 提供のクリップアートは著作権で保護されており、IMSI Inc.
            の全額出資子会社である Zedcor, Inc.
            および／またはそのサプライヤに帰属します。Zedcor
            からの事前の許可なしに使用することは禁じられています。{" "}
          </p>
          <p>
            {" "}
            Eyewire 提供のクリップアートは著作権で保護されており、Eyewire Inc.
            および／またはそのサプライヤに帰属します。Eyewire
            からの事前の許可なしに使用することは禁じられています。{" "}
          </p>
          <p>
            {" "}
            Corbis 提供の写真は著作権で保護されており、Corbis Corporation
            および／またはそのサプライヤに帰属します。Corbis
            からの事前の許可なしに使用することは禁じられています。{" "}
          </p>
          <p>
            {" "}
            ImageQuest 提供の写真は著作権で保護されており、ImageQuest
            および／またはそのサプライヤに帰属します。ImageQuest
            からの事前の許可なしに使用することは禁じられています。{" "}
          </p>
          <p />
          <br />
          <p>
            <b>GlobalEnglish の商標</b>
          </p>
          <p />
          <p>
            以下に挙げるのは、GlobalEnglish Corporation
            が所有する商標の一部と、米国および／またはその他の国での、その商標の希望総称です。このリストに名称またはロゴが記載されていない場合において、GlobalEnglish
            Corporation
            またはその子会社が自らの製品、機能ならびにサービスの名称またはロゴに確立した、いかなる知的財産権も放棄したことにはなりません。GlobalEnglish
            の製品名および商標の正式な表記については、GlobalEnglish
            一般商標ガイドラインをお求めください。
          </p>
          <p />
          <br />
          <style
            dangerouslySetInnerHTML={{
              __html: " \t\ttd{padding:10px !important} \t\t",
            }}
          />
          <table
            border={1}
            cellPadding={10}
            cellSpacing={0}
            bordercolor="#808080"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  TradeMark
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Status
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  Enterprise Fluency
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Bloom
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Corporate Learning Service
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Edge
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo Pro
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Magazine
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Mobile Learning Network
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Productivity Toolbar
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP+
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Motivator
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Executive Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>第三者の商標</b>
          </p>
          <p />
          <p>
            {" "}
            Adobe と Flash は、米国および／またはその他の国での Adobe Systems
            Incorporated の商標または登録商標のいずれかです。{" "}
          </p>
          <p> Microsoft は Microsoft Corporation の登録商標です。 </p>
          {/*p> Langenscheidt KG &#12399; Langenscheidt KG, Berlin and Munich &#12398;&#30331;&#37682;&#21830;&#27161;&#12391;&#12377;&#12290; </p*/}
          <p>
            {" "}
            Living Language および Colophon は、Random House, Inc.
            の登録商標です。{" "}
          </p>
          <p>
            {" "}
            Sipro および Sipro ロゴは、Sipro Lab Telecom Inc. の商標です。ACELP
            <sup>®</sup> は、Sipro Lab Telecom Inc.
            の実施許諾に準じた、Université Sherbrooke の登録商標です。{" "}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International、SRI International ロゴ、EduSpeak、EduSpeak
                  ロゴは、SRI International の商標または登録商標です。{" "}
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p> SYSTRAN &#12399;&#12289;SYSTRAN Software, Inc. &#12398;&#30331;&#37682;&#21830;&#27161;&#12414;&#12383;&#12399;&#12469;&#12540;&#12499;&#12473;&#12510;&#12540;&#12463;&#12391;&#12377;&#12290;SYSTRAN &#12525;&#12468;&#12399;&#12289;SYSTRAN Software, Inc. &#12398;&#30331;&#37682;&#12469;&#12540;&#12499;&#12473;&#12510;&#12540;&#12463;&#12391;&#12377;&#12290; </p*/}
          <p> AP/Wide World Photos は Associated Press の商標です。 </p>
          <p> PhotoDisc は PhotoDisc, Inc. の登録商標です。 </p>
          <p>
            {" "}
            Business English Pod、www.businessenglishpod.com、および Business
            English Pod ロゴは、Business English Pod Limited の商標です。{" "}
          </p>
          <p> その他のすべての商標は、それぞれの所有者に帰属します。 </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default JaJpContent;
