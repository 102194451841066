/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import { BasicSkeleton } from "bundles/common/components/BasicSkeleton";
import { InputField } from "bundles/common/components/InputField";
import { ButtonField } from "bundles/common/components/ButtonField";
import { FormError } from "bundles/common/components/FormError";
import { RegisterSection } from "../LoginForm/LoginForm";
import { FormattedMessage } from "react-intl";
import messages from "bundles/common/intl/messages";
import { Loader } from "bundles/common/components/Loader";
import succesImg from "../../assets/images/success.png";


export interface ForgotFormProps {
  submit: Function;
  error: {
    code: string;
    message: string;
    email: string;
  };
  forgotPassword: Function;
  isEmailSent: string;
  resetIsEmailSent: () => void;
  errorCount: number;
}

export const ForgotForm: React.SFC<ForgotFormProps> = (props) => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if (!email) {
      setErrors(validateUser);
      setDisableButton(true);
      return;
    } else {
      setDisableButton(false);
      props.resetIsEmailSent();
    }
  }, [email]);

  /**
   * Enter button to submit form.
   */
  function handleKeyPressForCode(event: React.KeyboardEvent) {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  }

  function handleSubmit() {
    if (!email) {
      setErrors(validateUser);
      return;
    }

    props.submit(email);
    setDisableButton(true);
  }

  function validateUser() {
    let error = {};
    if (!email) {
      error.email = "Email is required";
    }
    return error;
  }

  // function handleChangeToLogin() {
  //   props.forgotPassword(false);
  // }

  useEffect(() => {
    if (props.isEmailSent === "SUCCESS") {
      setEmail("");
      setTimeout(() => {
        window.location.replace("/" + window.location.search);
      }, 3000);
    } else if (props.isEmailSent == "MULTIPLE_USER") {
      setEmail("");
    } else if (props.isEmailSent === "FAIL" || props.isEmailSent === "ERROR") {
      setEmail("");
    }
  }, [props.isEmailSent, props.errorCount]);

  return (
    <React.Fragment>
      <BasicSkeleton title={<FormattedMessage {...messages.resetPassword} />}>
        <React.Fragment>
          {props.isEmailSent && (
            <div id="notificationWrap" className="notificationWrap">
              {props.isEmailSent === "SUCCESS" && (
                <div
                  className="notificationSuccess"
                  style={{
                    textAlign: "left",
                    background: `url(${succesImg}) 0 10px no-repeat`,
                  }}
                >
                  <FormattedMessage {...messages.emailSuccess} />
                </div>
              )}
              {props.isEmailSent === "MULTIPLE_USER" && (
                <div
                  className="notificationFail"
                  style={{ textAlign: "center" }}
                >
                  <FormattedMessage {...messages.emailMultipleUser} />
                </div>
              )}
              {props.isEmailSent === "FAIL" && (
                <div
                  className="notificationFail"
                  style={{ textAlign: "center" }}
                >
                  <FormattedMessage {...messages.emailFail} />
                </div>
              )}
              {props.isEmailSent === "ERROR" && (
                <div
                  className="notificationFail"
                  style={{ textAlign: "center" }}
                >
                  Something went wrong.
                </div>
              )}
              {props.isEmailSent === "INVALID" && (
                <div
                  className="notificationFail"
                  style={{ textAlign: "center" }}
                >
                  <FormattedMessage {...messages.validEmailError} />
                </div>
              )}
            </div>
          )}
          {props.error && (
            <FormError
              id="reqUsername"
              code={props.error.code}
              message={props.error.message}
            />
          )}
          {props.resetRequired && (
            <FormError
              id="reqPasswordReset"
              code='PasswordResetRequired'
              message='Password reset required for the user'
            />
          )}
          <InputField
            label={
              <FormattedMessage {...messages.email}>
                {" "}
                {(labelText) => labelText}{" "}
              </FormattedMessage>
            }
            labelId="lblUsename"
            value={email}
            id="txtEmail"
            className="txt"
            name="LoginForm[username]"
            type="email"
            onClickFn={props.resetIsEmailSent}
            // eslint-disable-next-line no-restricted-globals
            onKeyDown={(event) => handleKeyPressForCode(event)}
            onChangeFn={(e) => {
              setEmail(e.target.value);              
            }}
            placeHolder={
              <FormattedMessage {...messages.email}>
                {" "}
                {(labelText) => labelText}{" "}
              </FormattedMessage>
            }
          />
          {/* Errors for username */}
          <fieldset className="button-padding">
            {props.loading ? (
              <Loader />
            ) : (
              <ButtonField
                value={<FormattedMessage {...messages.confirm} />}
                id="btnLogin"
                type="submit"
                name="yt0"
                onClickFn={handleSubmit}
                disableBtn={disableButton}
                isLoading={false}
              />
            )}
          </fieldset>
        </React.Fragment>
        <div className="login-foot">
          <ul>
            <RegisterSection />
            <div className="first">
              <a href="/" >
                {<FormattedMessage {...messages.logIn} />}
              </a>
            </div>
          </ul>
        </div>
      </BasicSkeleton>
    </React.Fragment>
  );
};
