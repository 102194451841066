// @ts-nocheck
import React, { useState, useContext, useEffect } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Input from "@material-ui/core/Input";
import { FormControl } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import messages from "bundles/common/intl/messages";
import Typography from "@material-ui/core/Typography";
import {
  AccountContext,
  verifyMFACode,
  getQRCode,
  verifyAndEnableMFA,
} from "./../../utils/helper/Accounts";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import "./MFA.css";
import { CircularProgress } from "@material-ui/core";
import { datadogLogs } from "@datadog/browser-logs";
const cookieUtils = require("./../../utils/helper/cookieHelper");

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      "& .MuiPaper-root": {
        borderRadius: "100px",
      },
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function MFA(props: any) {
  const { getSession } = useContext(AccountContext!);
  const [MFAEnabled, setMFAEnabled] = useState(false);
  const [appOtpCode, setAppOtpCode] = useState<string>("");
  const [qRImage, setQRImage] = useState<any>(undefined);
  const [qrImageLoading, setQrImageLoading] = useState(true);
  const [dialogOpenFlag, setDialogOpenFlag] = useState(false);
  const [vCodeError, setVCodeError] = useState(false);
  const [vCodeErrorText, setVCodeErrorText] = useState(<span></span>);
  const [isVerifying, setIsVerifying] = useState(false);
  const [appLinkURLs, setAppLinkURLs] = useState({
    andUrl: '',
    iosUrl: ''
  });

  const handleClose = () => {
    props.triggerOnClose(false);
  };

  const classes = useStyles();
  const [tabViewIndex, setTabViewIndex] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabViewIndex(newValue);
  };

  const changeTab = (modalScreenIndex: number) => {
    switch (modalScreenIndex) {
      case 0:
        setQrImageLoading(true);        
        getQRCode(props.authResult.idToken.payload.preferred_username || "Learnship")
          .then((imageData) => {
            setQrImageLoading(false);
            setQRImage(imageData);
          })
          .catch((err) => {
            setQrImageLoading(false);
            datadogLogs.logger.error('MFA exception', err);
          });
        break;
      case 1:
        break;
      default:
        break;
    }
    setTabViewIndex(modalScreenIndex);
  };

  const showHideVcodeError = (
    shouldShow: boolean,
    errorText: any,
    dialogOpen: boolean,
  ) => {
    setVCodeError(shouldShow);
    setVCodeErrorText(errorText);
    setDialogOpenFlag(dialogOpen);
  };

  const enableAndValidateCode = () => {
    setIsVerifying(true);
    showHideVcodeError(false, <span></span>, true);
    verifyAndEnableMFA(
      appOtpCode,
      props.cogUserEventObj,
      props.authResult,
    ).then(({ resultIndex, authResult }) => {
      setIsVerifying(false);
      switch (resultIndex) {
        case 0:
          // code verified and enabled
          showHideVcodeError(false, <span></span>, false);
          triggerSSO(authResult);
          break;
        case -1:
          showHideVcodeError(true, <span><FormattedMessage {...messages.codeError1} /></span>, true);
          break;
        case -2:
          showHideVcodeError(true, <span><FormattedMessage {...messages.codeError2} /></span>, true);
          break;
        default:
          showHideVcodeError(true, <span><FormattedMessage {...messages.codeError1} /></span>, true);
          break;
      }
    },(err)=>{
    });
  };

  const validateCode = () => {
    setIsVerifying(true);
    showHideVcodeError(false, <span></span>, true);
    verifyMFACode(appOtpCode, props.cogUserEventObj).then(
      (authResult) => {
        // RESULT - CODE VALID
        setIsVerifying(false);
        showHideVcodeError(false, <span></span>, false);
        triggerSSO(authResult.result);
        //call SSO here
      },
      (err) => {
        // RESULT - INCODE VALID
        setIsVerifying(false);
        showHideVcodeError(true, <span><FormattedMessage {...messages.codeError1} /></span>, true);
      },
    );
  };

  const dialogEnter = () => {
    if (props.modalViewIndex >= 0) {
      setMFAEnabled(true);
      changeTab(props.modalViewIndex);
    } else {
      getSession().then(({ mfaEnabled }) => {
        // RESULT - MFA Enabled/Disabled
        setMFAEnabled(mfaEnabled);
        if (!!!mfaEnabled) {
          changeTab(0);
          // Proceed with QRCode  - First time user
        } else {
          changeTab(1);
          // Proceed with Verification code - Second time user
        }
      },(err)=>{
      });
    }
  };

  const handleOTPTypingChange = (evt: any) => {
    let onlyNos = evt.target.value.replace(/\D/g, "");
    setAppOtpCode(onlyNos);
  };

  const triggerSSO = async (AuthResult: any) => {
    var data = await cookieUtils.setCookies(AuthResult);
    if (data) {
      const response = {
        message: "User Signed In Successfully",
        isAuthenticated: true,
        response: data.response,
        lsLearnerPortalUrl: data.response.lsLearnerPortalUrl || null,
      };
      props.triggerAfterMFAAuths(null, response);
      if (data.response.isActive) {
        const response = {
          lsLearnerPortalUrl: data.response.lsLearnerPortalUrl || null,
          message: "User Signed In Successfully",
          isAuthenticated: true,
          response: data.response,
        };
        props.triggerAfterMFAAuths(null, response);
      } else {
        const err = {
          code: "InactiveUser",
          name: "DisableInactiveUser",
          message: "Your account is not active. Contact your administrator.",
        };
        props.triggerAfterMFAAuths(err, null);
      }
    } else {
      const err = {
        code: "SETCOOKIEFAILURE",
        name: "FAILEDTOSETCOOKIE",
        message: "Something went wrong!",
      };
      props.triggerAfterMFAAuths(err, null);
    }
  };


  const generateAppLinks = () => {
    let instruction1Values = {
      andUrl: '',
      iosURL: ''
    };
    const appListUrls: array =
      JSON.parse(process.env.REACT_APP_AVAIL_APP_PLATFORMS || "[]") || [];
      if (appListUrls && appListUrls.length > 0) {
        appListUrls.map(({id, url}) => {
          instruction1Values[id] = url;
        })
      }
      setAppLinkURLs(instruction1Values);
  }

  const completingQRCode = () => {
    changeTab(1);
  };

  const handleEnterClickVerify = (evt: any) => {
    if (evt.key && evt.key.toLowerCase() === "enter") {
      if (appOtpCode.length >= 6 && !isVerifying) {
        if (MFAEnabled) {
          validateCode();
        } else {
          enableAndValidateCode();
        }
      }
    }
  };

  useEffect(() => {
    if (props.openMFAModal) {
      dialogEnter();      
    }
    generateAppLinks();    
    setDialogOpenFlag(props.openMFAModal);
  }, [props.openMFAModal]);

  return (
    <div>
      <Dialog
        maxWidth={tabViewIndex===0?'sm':'xs'}
        fullWidth
        onClose={handleClose}
        aria-labelledby="Scan and verify QR code window"
        open={dialogOpenFlag}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent dividers>
          <div className={classes.root}>
            <AppBar position="static" className="hide">
              <Tabs
                variant="fullWidth"
                value={tabViewIndex}
                onChange={handleTabChange}
                aria-label="Verification tabs"
              ></Tabs>
            </AppBar>
            <TabPanel value={tabViewIndex} index={0}>
              <div className="qr-main-container">
                <div className="qr-head-block">
                  <div className="qr-title"><FormattedMessage {...messages.mfaTitle} /></div>
                  <div className="instructions-list">
                    <div className="instructions-head"><FormattedMessage {...messages.instructionTitle} /></div>
                    <ul>
                      <li><span className="step-count-text"><FormattedMessage {...messages.step1} />: </span><FormattedMessage {...messages.instruction1} values={
                        {
                          andUrl:<a href={appLinkURLs.andUrl ? appLinkURLs.andUrl : ""}>Android</a>,
                          iosUrl:<a href={appLinkURLs.iosUrl ? appLinkURLs.iosUrl : ""}>iOs</a>
                        }
                        }/></li>
                      <li><span className="step-count-text"><FormattedMessage {...messages.step2} />: </span><FormattedHTMLMessage {...messages.instruction2} /></li>
                      <li>
                        <span className="step-count-text"><FormattedMessage {...messages.step3} />: </span><FormattedHTMLMessage {...messages.instruction3} />
                        <div className="qr-code-holder">
                        {!!!qrImageLoading ? (
                            <div className="qr-image-container">
                              <img src={qRImage} alt="MFA-QR-Code" />
                            </div>
                          ) : (
                            <div className="qr-loader-container">
                              <CircularProgress></CircularProgress>
                            </div>
                          )}
                        </div>
                      </li>
                      <li><span className="step-count-text"><FormattedMessage {...messages.step4} />: </span><FormattedMessage {...messages.instruction4} />
                      <div className="otp-code-holder">
                        <div className="vcode-body">
                          <FormControl className="verify-code-formControl">
                            <Input
                              className="custom-Input"
                              id="otpcode1"
                              label="otpcode1"
                              value={appOtpCode}
                              onChange={handleOTPTypingChange}
                              onKeyPress={handleEnterClickVerify}
                              variant="outlined"
                              name="otp_code1"
                              fullWidth={true}
                              required={true}
                              placeholder=""
                              autoFocus
                              inputProps={{ maxLength: 6 }}
                            />
                          </FormControl>
                          {vCodeError && (
                            <div className="code-error">{vCodeErrorText}</div>
                          )}
                          <div className="vcode-buttons-container">
                            {(
                              <Button
                                disabled={appOtpCode.length < 6 || isVerifying || qrImageLoading}
                                className="custom-button"
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={() => {
                                  enableAndValidateCode();
                                }}
                              >
                                {isVerifying ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  <span><FormattedMessage {...messages.verifyText} /></span>
                                )}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="qr-sub-title">
                    Your organization has enabled multi-factor authentication.
                  </div>
                  {!!!qrImageLoading && (
                    <div className="qr-sub-title bold">Scan this QR code</div>
                  )} */}
                </div>
                {/* {!!!qrImageLoading ? (
                  <div className="qr-image-container">
                    <img src={qRImage} alt="MFA-QR-Code" />
                  </div>
                ) : (
                  <div className="qr-loader-container">
                    <CircularProgress></CircularProgress>
                  </div>
                )} */}
                {/* <div className="qr-buttons-container">
                  <Button
                    className="custom-button custom-button-1"
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={() => {
                      completingQRCode();
                    }}
                  >
                    Continue
                  </Button>
                </div> */}
                {/* <div className="qr-footer">
                  <div className="qr-footer-note">
                    Download the authenticator app for{" "}
                    {generateAppAvailablePlatformLink()}
                  </div>
                </div> */}
              </div>
            </TabPanel>
            <TabPanel value={tabViewIndex} index={1}>
              <div className="vcode-container">
                <div className="vcode-title-container">
                  <div className="vcode-title">
                    <FormattedMessage {...messages.mfaTitle2} />
                  </div>
                </div>
                <div className="vcode-body">
                  <FormControl className="verify-code-formControl">
                    <Input
                      className="custom-Input"
                      id="otpcode2"
                      label="otpcode2"
                      value={appOtpCode}
                      onChange={handleOTPTypingChange}
                      onKeyPress={handleEnterClickVerify}
                      variant="outlined"
                      name="otp_code2"
                      fullWidth={true}
                      required={true}
                      placeholder=""
                      autoFocus
                      inputProps={{ maxLength: 6 }}
                    />
                  </FormControl>
                  {vCodeError && (
                    <div className="code-error">{vCodeErrorText}</div>
                  )}
                  <div className="vcode-buttons-container">
                    {(
                      <Button
                        disabled={appOtpCode.length < 6 || isVerifying}
                        className="custom-button"
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() => {
                          validateCode();
                        }}
                      >
                        {isVerifying ? (
                          <CircularProgress size={20} />
                        ) : (
                          <span><FormattedMessage {...messages.verifyText} /></span>
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tabViewIndex} index={2}>
              Reset page
            </TabPanel>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
