// @ts-nocheck
import React from 'react';

function PtBrContent() {
  return (
    <div id="agreement_container">
      <div style={{ padding: '1% 5% 0 5%' }}>
        <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
          <b>CONTRATO DE UTILIZADOR</b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
          <b>INTRODUÇÃO</b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          Bem-vindo aos serviços da GlobalEnglish, seja o sítio seja os aplicativos para telemóvel (denominados de forma
          conjunta como os “Serviços”).
          <br />
          <br /> Nossos Serviços são soluções online e ferramentas de aprendizagem de inglês, inclusive, sem limitação,
          dados, textos, testes online, designs, vídeos, clipes de áudio, gravações de voz, publicações e comentários
          escritos, enredos, gráficos, imagens e sua seleção e organização, softwares, tutoriais, aulas, orientação,
          treinamento, comunicação e recursos interativos gerados ou tornados acessíveis através do sítio e dos
          aplicativos para telemóvel, (coletivamente, o “Conteúdo”). O acesso ao nosso sítio para realizar a leitura a
          respeito da nossa empresa e consultar os Serviços é considerado parte dos Serviços.
          <br />
          <br /> Por favor, leia a seguir com bastante atenção e aceite a sua compreensão e acordo dos termos abaixo
          antes de prosseguir.
          <br />
          <br />
          <b>
            SE V.S.ª TEM MENOS DE 18 ANOS DE IDADE, CERTIFIQUE-SE DE LER O PRESENTE CONTRATO COM SEU PAI OU RESPONSÁVEL
            E OBTER O SEU CONSENTIMENTO ANTES DE FAZER USO DE NOSSOS SERVIÇOS. AO FAZER USO DE NOSSOS SERVIÇOS, V.S.ª
            GARANTE QUE TEM MAIS DE 18 ANOS DE IDADE OU QUE OBTEVE O REFERIDO CONSENTIMENTO DE SEU PAI OU RESPONSÁVEL E
            QUE TEM PERMISSÃO PARA CELEBRAR O PRESENTE ACORDO COM A GLOBALENGLISH.
          </b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
          <b> TERMOS GERAIS</b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 1.CONTRATO JURIDICAMENTE VINCULANTE </b>
          <br />
          <br />
          <b>
            {' '}
            O SEU ACESSO AO NOSSO SÍTIO E A SUA UTILIZAÇÃO DOS SERVIÇOS DA GLOBALENGLISH ESTÃO SUJEITOS AO CONTRATO DE
            UTILIZADOR ABAIXO, À POLÍTICA DE PRIVACIDADE E À POLÍTICA DE COOKIE (TODOS OS TRÊS DOCUMENTOS DENOMINADOS EM
            CONJUNTO COMO OS “TERMOS DE UTILIZAÇÃO”). AO ACEDER, VISITAR, REGISTAR E/OU UTILIZAR OS SERVIÇOS DA
            GLOBALENGLISH, V.S.ª GARANTE QUE LEU E COMPREENDEU OS TERMOS DE UTILIZAÇÃO DE NOSSOS SERVIÇOS E QUE CONCORDA
            EM FICAR OBRIGADA JURÍDICA E IRREVOGAVELMENTE PELOS MESMOS E QUE É MAIOR DE IDADE PARA ACEITAR FICAR
            OBRIGADA PELOS PRESENTES TERMOS E CELEBRAR UM CONTRATO JURIDICAMENTE VINCULATIVO COM A GLOBALENGLISH.{' '}
          </b>
          <br />
          <br /> A V.S.ª pode encontrar uma visão geral de nossas políticas
          <a
            href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
            rel="noopener noreferrer"
            target="_blank"
          >
            aqui
          </a>
          . Essas políticas estão incorporadas por referência no presente “Contrato de Utilizador” ou “Contrato” e, em
          conjunto, constituem os termos de utilização.
          <br />
          <br />
          <b>
            Informamos que o presente Contrato contém disposições que regem a forma de resolução de queixas que possam
            ocorrer entre V.S.ª e nós, uma contra a outra. Consulte em especial as disposições de Exclusão de Garantias,
            Limitação de Responsabilidades e Demandas Judiciais abaixo. Ele também contém um pacto de arbitragem que,
            com exceções limitadas, exige que V.S.ª submeta as queixas que tiver contra nós a arbitragem vinculativa e
            final. V.S.ª somente terá autorização para apresentar queixas individualmente, não como autora ou parte
            interessada em qualquer procedimento ou ação coletiva ou representativa. V.S.ª somente terá autorização para
            recorrer a reparação (inclusive pecuniária, medida cautelar e ação declarativa) individualmente.
            <br />
            <br /> NÃO UTILIZE OS SERVIÇOS OU NAVEGUE ADIANTE EM NOSSO SÍTIO SE, A QUALQUER MOMENTO, V.S.ª NÃO ACEITAR
            QUALQUER PARTE DOS TERMOS DO PRESENTE.
          </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. INCIDÊNCIA </b>
          <br />
          <br /> O presente Contrato incide tanto sobre os Utilizadores de nossos serviços quanto aos Visitantes em
          nossos sítios. Os indivíduos que fizeram cadastro connosco diretamente (uma
          <b>“transação B2C”</b>), mesmo em modo de experimentação, e aqueles que estejam cadastrados por meio de ou
          através de seus patrões (uma
          <b>“transação B2B”</b>) são denominados
          <b>“Utilizadores”</b> ao passo que utilizadores não cadastrados e visitantes que examinam nossos sítios são
          <b>“Visitantes”</b>. O termo
          <b>“Utilizadores”</b>
          também inclui professores, orientadores, especialistas de aprendizagem e qualquer outra pessoa que aceda os
          sítios da GlobalEnglish para prestação de serviços aos demais Utilizadores. Tanto os Utilizadores quanto os
          Visitantes são denominados
          <b>“V.S.ª”</b>.
          <br />
          <br /> O presente Contrato incide sobre todos os Serviços da GlobalEnglish.
          <br />
          <br /> Quando V.S.ª realiza o cadastro pela primeira vez na qualidade de Utilizador e quando acede os nossos
          Serviços, talvez seja solicitado de V.S.ª a reafirmação de seu aceite de ficar obrigada pelos termos do
          presente através de clique no quadrado
          <b>“Aceito”</b> ou através da sua assinatura eletrónica ou de quaisquer outros meios de autenticação.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>3.ALTERAÇÕES NOS TERMOS DE UTILIZAÇÃO </b>
          <br />
          <br />A GlobalEnglish pode modificar os Termos de Utilização a qualquer momento, inclusive o presente
          Contrato, a Política de Cookie e a Política de Privacidade incidente sobre a sua utilização dos Serviços. As
          modificações entrarão em vigor imediatamente mediante notificação que deverá ser providenciada através de
          publicação no Serviço, mensagem eletrónica enviada ao seu endereço de correio eletrónico que V.S.ª
          providenciou em conexão com a conta de seus Serviços (se for o caso) ou outros meios. Se V.S.ª foi cadastrada
          em nossos Serviços através de seu patrão ou de uma empresa, seu patrão ou empresa poderá notificar V.S.ª sobre
          as alterações de nossos Termos de Utilização.
          <br />
          <br /> Se V.S.ª fiver objeção a estas alterações, poderá encerrar a sua conta e cessar o acesso ao nosso
          sítio. No entanto, a sua utilização continuada de nossos Serviços após a nossa publicação ou emissão de uma
          notificação referente às alterações destes termos considerar-se-á como configuração de seu aceite das
          referidas modificações aos Termos de Utilização.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4.TERMOS DE UTILIZAÇÃO DE TERCEIROS </b>
          <br />
          <br />
          Os Serviços podem conter links de outros sítios (“Sítios de Terceiros”), assim como artigos, fotografias,
          textos, traduções, gráficos, gravuras, designs, sons, vídeos, informações e outros conteúdos ou itens que
          pertencem a, ou são oriundos de, terceiros (os “Conteúdos de Terceiros”). Estes Sítios de Terceiros e
          Conteúdos de Terceiros não são investigados, monitorados ou verificados por nós quanto à sua precisão,
          adequação ou integralidade, e não nos responsabilizamos por quaisquer Sítios de Terceiros acedidos através do
          Sítio ou quaisquer Conteúdos de Terceiros publicados ou disponíveis no sítio.
          <br />
          <br /> A inclusão, vinculação ou autorização de utilização de qualquer Sítio de Terceiros ou de quaisquer
          Conteúdos de Terceiros não implica aprovação ou endosso dos mesmos pela GlobalEnglish. A GlobalEnglish
          reserva-se o direito de encerrar estes links a qualquer momento sem aviso. Referências nos Serviços a
          quaisquer empresas, organizações, produtos ou serviços, ou outras informações, não constituem nem implicam
          endosso, patrocínio ou recomendação dos mesmos pela GlobalEnglish, nem qualquer afiliação com estes.
          <br />
          <br /> Se decidir sair de nosso sítio e aceder os Sítios de Terceiros ou aceder ou utilizar quaisquer
          Conteúdos de Terceiros, V.S.ª assim o faz por sua própria conta e risco, e deverá ficar ciente de que nossos
          termos e políticas deixam de reger a sua utilização dos referidos Sítios de Terceiros. Por favor,
          certifique-se de verificar os termos e políticas aplicáveis a esses outros produtos e serviços de terceiros
          antes da sua utilização.
          <br />
          <br /> Oferecemos aplicativos para telemóvel através do Android Market e da iTunes Store, (coletivamente e com
          as entidades que as mantêm, “Provedoras”). A sua utilização destes aplicativos para telemóvel está também
          sujeita aos termos e condições das Provedoras, inclusive, sem limitação, as Regras de Uso estabelecidas nos
          Termos de Serviço da App Store. Se tiver descarregado qualquer um destes aplicativos para telemóvel da iTunes
          Store, V.S.ª reconhece e concorda que leu e aceitou os Termos de Serviço da App Store. Se tiver descarregado
          do Android Market, V.S.ª reconhece e concorda que leu e aceitou os Termos de Serviço do Android Market. As
          Provedoras não têm qualquer obrigação de prestar, e não prestarão, quaisquer serviços de manutenção ou suporte
          a V.S.ª em conexão com a sua utilização dos Serviços. As Provedoras não oferecem qualquer garantia de qualquer
          espécie quanto aos Serviços. Caso qualquer um dos aplicativos para telemóvel deixe de obedecer qualquer
          garantia aplicável e V.S.ª tenha descarregado o referido aplicativo da iTunes Store, V.S.ª poderá notificar a
          Apple e a mesma reembolsará o preço de compra (se houver) do referido aplicativo. Estes Termos aplicam-se à
          sua utilização de todos os aplicativos para telemóvel assim como de nossos outros Serviços.
          <br />
          <br /> V.S.ª reconhece e concorda que a GlobalEnglish não se responsabiliza pelos conteúdos, produtos,
          serviços ou desempenho destes sítios ou aplicativos de terceiros. V.S.ª renuncia de forma irrevogável qualquer
          demanda contra a GlobalEnglish com relação aos sítios ou aplicativos de terceiros. Em particular, A
          GLOBALENGLISH NÃO SE RESPONSABILIZA POR COOKIES, WEB BEACONS, CLEAR GIFS OU OUTROS CÓDIGOS QUE V.S.ª OU OUTRA
          PARTE COLOQUE EM SEU COMPUTADOR DE QUALQUER FORMA, INCLUSIVE QUALQUER ASSOCIAÇÃO DE CONTEÚDOS DE SÍTIOS COM O
          REFERIDO CÓDIGO. Por exemplo, se V.S.ª aceder um Sítio de Terceiros através de um link no Sítio, o seu
          navegador ou outros cookies em seu computador podem ocasionar o envio de um cabeçalho referer àquele sítio que
          indica uma certa quantidade de seu histórico de navegação ou o URL onde sua visita teve origem. A
          GlobalEnglish não terá qualquer responsabilidade perante V.S.ª em conexão com qualquer referida transmissão,
          independentemente daquela transmissão conter ou não informações associadas à GlobalEnglish, ou oriundas da
          mesma.
          <br />
          <br /> SE V.S.ª UTILIZAR TRADUÇÕES PROVIDENCIADAS PELO GOOGLE: O GOOGLE RENUNCIA TODAS AS GARANTIAS EXPRESSAS
          OU IMPLÍCITAS RELACIONADAS ÀS TRADUÇÕES, INCLUSIVE QUAISQUER GARANTIAS DE PRECISÃO, FIABILIDADE E QUAISQUER
          GARANTIAS IMPLÍCITAS DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UM PROPÓSITO EM PARTICULAR E FALTA DE INFRAÇÃO.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>5. CRITÉRIOS DE ELEGIBILIDADE DO SERVIÇO </b>
          <br />
          <br />
          <b>5.1 Proteção da Privacidade de Crianças e Restrições sobre a Utilização de Nossos Serviços. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Os Serviços não visam a utilização por qualquer indivíduo Menor de Idade no país do seu acesso aos Serviços.
            Por exemplo, “Menor de Idade” na União Europeia, Espaço Económico Europeu e Suíça significa 16 anos de
            idade, exceto disposição contrária na legislação específica do país. Nos Estados Unidos, a idade mínima é de
            13 anos. Qualquer cadastro, utilização ou acesso dos Serviços por qualquer indivíduo Menor de Idade está
            proibido e constitui uma violação destes Termos.
            <br />
            <br /> Através da utilização dos Serviços, V.S.ª declara e garante que tem 13 anos de idade ou mais.
          </span>
          <br />
          <br />
          <b>5.2Escolas e Professores</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            As escolas, distritos ou professores nos Estados Unidos que desejem que seus alunos com menos de 13 anos de
            idade criem contas da GlobalEnglish, concordam que são responsáveis pelo cumprimento da&nbsp; Lei de
            Proteção da Privacidade Online das Crianças nos EUA ("COPPA") e, na medida aplicável, a Lei de Privacidade e
            de Direitos Educacionais da Família ("FERPA"). Isto significa que as escolas, distritos ou professores devem
            avisar os pais ou responsáveis dos alunos sobre as informações de identificação pessoal que a GlobalEnglish
            irá colher, além de obterem o consentimento dos pais/responsáveis antes que os alunos estabeleçam contas e
            utilizem os Serviços. Ao obterem o referido consentimento, as escolas, distritos e professores devem
            providenciar aos pais/responsáveis uma cópia de nossa Política de Privacidade e manter todos os
            consentimentos em arquivo, além de providenciá-los a nós se solicitarmos. Para obter mais informações com
            relação ao cumprimento da COPPA, consulte
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
              rel="noopener noreferrer"
              target="_blank"
            >
              {' '}
              o sítio da Comissão Federal do Comércio.{' '}
            </a>
            <br />
            <br /> Se V.S.ª estiver localizada fora dos Estados Unidos, vamos confiar a V.S.ª a obtenção de qualquer
            aprovação ou consentimento necessário do pai ou responsável de qualquer aluno abrangido por legislação
            similar e, como condição à sua utilização de nossos Serviços ou àquela de seus alunos, V.S.ª concorda que
            será responsável pelo cumprimento da referida legislação.
          </span>
          <br />
          <br />
          <b>5.3 Consentimento dos Pais </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            <b>
              AO FAZER USO DE NOSSOS SERVIÇOS, V.S.ª GARANTE QUE TEM MAIS DE 18 ANOS DE IDADE OU QUE OBTEVE O REFERIDO
              CONSENTIMENTO DE SEU PAI OU RESPONSÁVEL E QUE TEM PERMISSÃO PARA CELEBRAR O PRESENTE ACORDO COM A
              GLOBALENGLISH.
              <br />
              <br /> Esperamos que qualquer utilização por crianças seja feita somente mediante orientação, supervisão e
              consentimento de seus pais, responsáveis e/ou funcionários autorizados da escola. A GlobalEnglish confia
              aos pais, responsáveis e escolas garantir a utilização dos Serviços por menores somente se compreenderem
              seus direitos e responsabilidades conforme disposto nestes Termos de Utilização.
              <br />
              <br />A GlobalEnglish poderá, a seu exclusivo critério, recusar a oferta dos Serviços a qualquer indivíduo
              ou entidade e alterar os seus critérios de elegibilidade a qualquer momento.
            </b>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6.PRIVACIDADE </b>
          <br />
          <br />
          <b>6.1 Declaração de Privacidade </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Por favor, examine a Política de Privacidade disponível
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              aqui.
            </a>
            <br />
            <br /> Processaremos dados pessoais de acordo com a política de privacidade a qual V.S.ª consentiu no
            momento em que acedeu pela primeira vez o serviço ou como parte de uma atualização programada da política de
            privacidade.
          </span>
          <br />
          <br />
          <b>6.2 Dados que V.S.ª Providenciar. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Para utilizar os Serviços, V.S.ª precisa de uma conta válida. Para cadastrar-se em nossos Serviços, V.S.ª
            precisa providenciar à GlobalEnglish uma Identificação de Utilizador, um endereço de correio eletrónico e
            outras informações que são consideradas informações de identificação pessoal, (“Dados Pessoais”). Algumas
            das informações que colhemos de V.S.ª são necessárias para que a GlobalEnglish preste os Serviços ou até
            mesmo apenas para o envio de informações a respeito dos Serviços que está a consultar, as demais são
            opcionais. Os Serviços também permitem que V.S.ª apresente informações a seu respeito e a respeito de sua
            organização, se for o caso.
            <br />
            <br /> V.S.ª concorda: (i) em providenciar informações precisas, atuais e completas a seu respeito e, no
            caso de uma transação B2B, a respeito de sua organização conforme solicitado pelos formulários de cadastro
            dos Serviços; (ii) em manter a confidencialidade de sua senha e de outras informações relacionadas à
            segurança de sua conta; (iii) em manter e atualizar os Dados Pessoais e quaisquer outras informações que
            providenciar à GlobalEnglish imediatamente, para manter tais informações precisas, atuais e completas; e
            (iv) em se responsabilizar plenamente por toda a utilização de sua conta e por quaisquer ações que ocorram
            através de sua conta.
            <br />
            <br /> A GlobalEnglish não assume nenhum dever de verificar a precisão dos Dados Pessoais que V.S.ª
            providencia para fins de cadastro.
          </span>
          <br />
          <br />
          <b>6.3 Transferência Internacional de Dados Pessoais</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Para prestar os Serviços, a GlobalEnglish depende da sua força de trabalho ao redor do mundo e utiliza
            prestadores de serviços terceirizados e parceiros de hospedagem para armazenagem e outros serviços
            relacionados a aprendizagem e demais tecnologias. Seus Dados Pessoais são portanto processados e
            transferidos aos funcionários e subcontratados da GlobalEnglish em diversos países, alguns dos quais talvez
            não ofereçam o mesmo nível de proteção legalmente conferido aos seus Dados Pessoais em seu país de cidadania
            ou residência.
            <b>
              Ao aceder, cadastrar e/ou utilizar os nossos Serviços, V.S.ª autoriza a transferência e o processamento de
              seus Dados Pessoais no exterior nos diversos países em que a GlobalEnglish, suas afiliadas e
              subcontratadas estão localizadas para efeitos dos Serviços.
            </b>
          </span>
          <br />
          <br />
          <b>6.4 Avisos e Mensagens. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            V.S.ª compreende e aceita que os Serviços podem incluir determinadas comunicações da GlobalEnglish, tais
            como anúncios do serviço, mensagens administrativas, etc, e que estas comunicações são consideradas parte
            dos Serviços e que V.S.ª não poderá optar pelo não recebimento delas.
            <br />
            <br />
            V.S.ª concorda por meio deste que a GlobalEnglish poderá passar avisos e mensagens à V.S.ª das seguintes
            formas: (i) dentro do Serviço; (ii) nos dados de contato que V.S.ª forneceu à GlobalEnglish (por exemplo,
            correio eletrónico ou número de telemóvel). V.S.ª aceita manter os seus dados de contato atualizados a todo
            momento.
          </span>
          <br />
          <br />
          <b>6.5 Privacidade de Terceiros </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Se obter informações a respeito de outro Utilizador no decurso da sua utilização de nossos Serviços, V.S.ª
            concorda em utilizar estas informações apenas para o propósito em que foi fornecido a V.S.ª. V.S.ª não pode
            divulgar, comercializar nem distribuir as informações de um Utilizador a um terceiro para fins estranhos aos
            Serviços. V.S.ª não pode utilizar essas informações para fins de marketing, a menos que esteja autorizada
            pelo Utilizador específico para assim proceder.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. LICENÇAS DE UTILIZADOR </b>
          <br />
          <br />
          <b>7.1 Licença para Utilizar nossos Serviços </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            É conferido à V.S.ª uma licença limitada, pessoal, não-exclusiva, intransmissível, não-sublicenciável e
            intransferível de acesso e utilização dos Serviços apenas para utilização pessoal não-comercial. A
            GlobalEnglish poderá, a seu critério, paralisar os Serviços temporária ou permanentemente ou modificar as
            funções dos Serviços periodicamente sem aviso prévio.
            <br />
            <br /> Para utilizar os Serviços, V.S.ª precisa obter acesso à Internet, seja diretamente seja através de
            dispositivos que acedem conteúdo virtual. Além disso, V.S.ª precisa possuir todos os equipamentos
            necessários para realizar essa conexão com a Internet, inclusive um computador e modem ou outro dispositivo
            de acesso.V.S.ª concorda que precisa avaliar a utilização dos Serviços, além de suportar todos os riscos
            associados à mesma, inclusive qualquer dependência na precisão, integralidade ou utilidade dos conteúdos que
            providenciarem. Nem a GlobalEnglish nem suas licenciantes garantem a precisão ou integralidade de quaisquer
            conteúdos.
            <br />
            <br /> Os Serviços não foram concebidos nem têm licença para utilização em ambientes de riscos (por exemplo,
            ao conduzir, dentro ou perto d'água).
            <br />
            <br /> O direito de acesso ao Serviço é cancelado sempre que a utilização do Serviço for proibida ou na
            medida em que a oferta, comercialização ou prestação do Serviço entrar em conflito com qualquer legislação,
            norma ou regulamento aplicável.
            <br />
            <br />A utilização do Serviço para qualquer finalidade que não seja aquela contemplada nos Termos de
            Utilização constitui uma violação dos Termos de Utilização e dos direitos proprietários e de autor das
            licenciantes e subcontratadas e/ou da GlobalEnglish. A GlobalEnglish tem o direito de suspender ou encerrar
            a sua conta e recusar todo e qualquer Serviço corrente ou futuro para V.S.ª se a GlobalEnglish tiver motivo
            razoável para suspeitar que V.S.ª utiliza os Serviços em violação destes Termos de Utilização. V.S.ª
            concorda que a GlobalEnglish não se responsabilizará perante a V.S.ª por qualquer modificação, suspensão ou
            paralisação dos Serviços ou de qualquer parte dos mesmos.
          </span>
          <br />
          <br />
          <b>7.2 Conteúdos do Utilizador</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Os Serviços dão a V.S.ª e a outros Utilizadores a oportunidade de criar dados e informações (por exemplo,
            através das respostas em nossos testes) e de carregar materiais em serviços comunitários (“Conteúdos do
            Utilizador”). Sujeito aos termos conferidos a nós nestes Termos, V.S.ª retém a titularidade plena de seus
            Conteúdos do Utilizador.
            <br />
            <br /> Ao carregar quaisquer Conteúdos do Utilizador em nossos Serviços, V.S.ª confere à GlobalEnglish uma
            licença irrevogável, perpétua, não-exclusiva, transferível, paga integralmente, mundial (com direito de
            sublicenciamento) para armazenar, utilizar, reproduzir, executar publicamente, exibir publicamente,
            modificar, traduzir, extrair (no todo ou em parte), processar e distribuir tais Conteúdos do Utilizador
            conforme necessário em conexão com a prestação dos Serviços para:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) Cumprir citação judicial, inclusive, sem limitação, responder a pedidos judiciais, mandados judiciais
              e demais solicitações governamentais similares;
              <br />
              (b) Fazer cumprir o Contrato;
              <br />
              (c) Responder a queixas de violação de direitos de terceiros por quaisquer Conteúdos;
              <br />
              (d) Proteger os direitos, a propriedade e a segurança pessoal da GlobalEnglish, de seus funcionários, dos
              Utilizadores e do público.
            </span>
            <br />
            <br /> Na medida em que opte pelo compartilhamento de quaisquer Conteúdos do Utilizador com outros
            Utilizadores dos Serviços, V.S.ª aceita permitir que estes Utilizadores visualizem os seus Conteúdos do
            Utilizador e, na medida aplicável, colaborem com V.S.ª com relação aos referidos Conteúdos do Utilizador.
            <br />
            <br /> Informamos que a GlobalEnglish não tem qualquer obrigação de examinar, analisar, verificar precisão,
            corrigir ou excluir quaisquer Conteúdos do Utilizador em momento algum. No entanto, reserva-se o direito de
            monitorar os Conteúdos do Utilizador e de remover aqueles que, a seu critério exclusivo, considere ofensivos
            ou que de outra forma violam os Termos de Utilização.
            <br />
            <br /> A GlobalEnglish não tem obrigações de armazenamento ou de cópia de segurança relativas aos Conteúdos
            do Utilizador. V.S.ª é unicamente responsável, às suas custas e despesas exclusivas, pela criação de cópias
            de segurança e substituição de quaisquer Conteúdos do Utilizador que publicar ou armazenar no Sítio ou
            providenciar à GlobalEnglish.
            <br />
            <br /> Os Conteúdos do Utilizador não refletem necessariamente as visões da GlobalEnglish: V.S.ª compreende
            que os Conteúdos do Utilizador são de responsabilidade única do Utilizador que der origem a tais Conteúdos
            do Utilizador. V.S.ª compreende que ao utilizar os Serviços, pode ser exposta a Conteúdos do Utilizador que
            sejam ofensivos, indecentes ou censuráveis.
            <br />
            <br /> Além da licença conferida aos Conteúdos do Utilizador estabelecida acima, V.S.ª reconhece e concorda
            que quaisquer perguntas, comentários, sugestões, ideias, retorno ou demais informações relacionadas aos
            Serviços (“Chamadas”), providenciadas por V.S.ª à GlobalEnglish, não são confidenciais, e a GlobalEnglish
            deverá ter direito de ultilização e disseminação irrestrita destas Chamadas para qualquer finalidade, seja
            comercial ou não, sem aviso ou remuneração para V.S.ª. Além disso, as informações publicadas, divulgadas ou
            compartilhadas através de quaisquer produtos ou Serviços comunitários da GlobalEnglish por V.S.ª deverão ser
            excluídas da definição de informações confidenciais, conforme esse termo é geralmente definido.
            <br />
            <br /> V.S.ª compreende que o processamento técnico e a transmissão do sítio, inclusive dos seus Conteúdos
            do Utilizador, pode envolver (a) transmissões em diversas redes; além de (b) alterações para se aderir e
            adaptar a exigências técnicas de conexão de redes ou dispositivos.
            <br />
            <br />
            <b>
              A GlobalEnglish não dá quaisquer declarações ou garantias quanto aos Conteúdos do Utilizador. Os Conteúdos
              do Utilizador não refletem necessariamente as visões da GlobalEnglish. Em nenhuma hipótese, a
              GlobalEnglish deverá ter qualquer responsabilidade pelos Conteúdos do Utilizador ou pela perda dos mesmos
              por qualquer motivo ou por quaisquer queixas, prejuízos ou danos resultantes de sua utilização ou perda.
            </b>
          </span>
          <br />
          <br />
          <b>7.3 Conduta Exigida do Utilizador</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            V.S.ª é totalmente responsável por todas as atividades que ocorrem em sua conta. V.S.ª aceita:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) avisar a GlobalEnglish imediatamente sobre qualquer utilização não autorizada da conta de V.S.ª ou de
              qualquer outra violação de segurança e
              <br />
              (b) certificar-se de sair de sua conta ao fim de cada sessão.
            </span>
            <br />
            <br />A GlobalEnglish não pode e não irá se responsabilizar por qualquer perda ou dano proveniente da sua
            incapacidade de cumprimento
            <br />
            <br />
            V.S.ª é unicamente responsável por certificar-se de que estes Termos de Serviço cumprem todas as leis,
            normas e regulamentos que se aplicam a V.S.ª, sendo que o direito de acesso ao Serviço é cancelado sempre
            que estes Termos de Serviço ou uso do Serviço estiver proibido ou na medida em que a oferta, comercialização
            ou prestação do Serviço entrar em conflito com qualquer lei, norma ou regulamento aplicável.
          </span>
          <br />
          <br />
          <b>7.4 Conduta Proibida do Utilizador </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            A seguir há exemplos do tipo de utilização que é ilícita ou proibida pela GlobalEnglish. A GlobalEnglish
            reserva-se o direito de investigar e tomar medida judicial apropriada contra qualquer indivíduo que, a
            critério exclusivo da GlobalEnglish, violar esta disposição, inclusive, sem limitação, através da remoção
            dos conteúdos ofensivos do sítio ou do aplicativo, suspensão ou encerramento da conta desses Utilizadores e
            denúncia às autoridades policiais.
            <br />
            <br /> V.S.ª não pode:
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) adaptar, descarregar, revisar, veicular, fazer a engenharia reversa, duplicar, publicar, modificar,
              disseminar, exibir, transferir ou copiar de outra forma e/ou distribuir quaisquer Conteúdos da
              GlobalEnglish ou outros materiais sobre o Serviço, salvo autorização específica da GlobalEnglish.
              <br />
              (b) enquadrar ou utilizar técnicas de enquadramento para fechar qualquer parte do Serviço;
              <br />
              (c) colher, obter, utilizar, aceder ou copiar de outra forma qualquer parte do Serviço através do uso de
              bot, spider, crawler, spyware, mecanismo, dispositivo, software ou qualquer outro dispositivo automático,
              utilidade ou processo manual de qualquer espécie;
              <br />
              (d) utilizar os Serviços ou quaisquer funções disponíveis nos Serviços de qualquer forma com intuito de
              interromper, danificar, desativar, sobrecarregar ou prejudicar os Serviços;
              <br />
              (e) comercializar, licenciar, arrendar ou utilizar de outra forma os Serviços para empreendimentos
              comerciais; ou
              <br />
              (f) participar de qualquer atividade que interfira no acesso, utilização ou usufruio deste Serviço por
              outro Utilizador.
              <br />
              (g) publicar, enviar correio eletrónico ou transmitir de outra forma quaisquer Conteúdos do Utilizador que
              (i) sejam ilícitos, prejudiciais, ameaçadores, abusivos, de assédio, danosos, excessivamente violentos,
              difamatórios, vulgares, obscenos, indecentes, pornográficos, injuriosos, invasivos da privacidade de
              terceiros (inclusive, mas não exclusivamente, qualquer endereço, correio eletrónico, número de telefone ou
              demais dados de contato), de ódio racial, étnico ou de outra forma censuráveis; (ii) V.S.ª não tem um
              direito de transmissão nos termos de qualquer legislação ou contrato ou relação fiduciária; (iii) tragam
              ou criem um risco de privacidade ou segurança a qualquer pessoa; (iv) infrinjam qualquer direito de
              propriedade intelectual ou demais direitos de propriedade de qualquer parte; (v) constituam publicidade,
              materiais promocionais, atividades comerciais e/ou vendas indesejadas ou desautorizadas, “lixo
              eletrónico”, “spam”, “corrente por carta”, “esquemas de pirâmide”, “concursos”, “sorteios” ou qualquer
              outra forma de solicitação; (vi) contenham vírus de software ou qualquer outro código informático,
              arquivos ou programas concebidos para interromper, destruir ou limitar a funcionalidade de qualquer
              hardware ou software informático ou equipamentos de telecomunicação; ou (vii) a critério exclusivo da
              GlobalEnglish, sejam censuráveis ou restrinjam ou inibam a utilização ou usufruio dos Serviços por
              qualquer outra pessoa, ou que venha a expor a GlobalEnglish ou seus utilizadores a qualquer dano ou
              responsabilidade de qualquer tipo;
              <br />
              (h) criar uma identidade falsa, deturpar sua identidade, fazer se passar por qualquer pessoa ou entidade
              ou fazer declaração falsa ou deturpar de outra forma sua afiliação com uma pessoa ou entidade;
              <br />
              (i) solicitar dados pessoais de qualquer indivíduo com menos de 18 anos de idade;
              <br />
              (j)prejudicar menores de qualquer modo;
              <br />
              (k) promover lesão ou dano físico contra qualquer grupo ou indivíduo, "perseguir" ou assediar de outra
              forma qualquer indivíduo;
              <br />
              (l) providenciar ou promover informações instrucionais a respeito de atividades ilícitas tais como, por
              exemplo, instruções de como montar bombas, granadas e demais armas ou dispositivos incendiários;
              <br />
              (m) coletar ou colher, através da utilização de scripts automatizados ou de outra forma, o conteúdo do
              sítio ou os endereços de correio eletrónico, dados de contato ou outras informações particulares de outros
              Utilizadores a partir do Sítio para qualquer finalidade, inclusive, sem limitação, para o efeito de
              encaminhar correio eletrónico indesejado ou outras comunicações indesejadas para Utilizadores ou de
              reprodução dos conteúdos do sítio;
              <br />
              (n) anunciar ou oferecer para venda ou compra quaisquer bens ou serviços para qualquer finalidade
              comercial que não esteja autorizada de forma específica;
              <br />
              (o) interferir ou interromper o sítio ou os servidores ou as redes conectadas ao sítio, ou desobedecer
              quaisquer exigências, procedimentos, políticas ou regulamentos de redes conectadas ao sítio;
              <br />
              (p) violar qualquer lei vigente local, estadual, nacional ou internacionalmente, ou qualquer regulamento
              com vigor de lei; avançar ou promover qualquer empreendimento ou atividade criminosa, ou providenciar
              informações instrutivas sobre atividades ilícitas;
              <br /> (q) obter ou tentar aceder ou obter de outra forma quaisquer materiais ou informações através de
              quaisquer meios que não estejam disponibilizados de forma intencional ou dispostos através do sítio;
              <br />
              (r) cancelar funções de segurança ou contornar ou circundar quaisquer controles de acesso; ou
              <br />
              (s) utilizar os Serviços de uma forma que venha a criar um conflito de interesse ou que prejudique os
              objetos dos Serviços, tais como troca de avaliações com outros Utilizadores ou elaboração ou solicitação
              de avaliações simuladas.
            </span>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. DIREITOS DE PROPRIEDADE INTELECTUAL </b>
          <br />
          <br />
          <b>8.1 Direitos de Propriedade Intelectual da GlobalEnglish </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Todos os conteúdos dos Serviços, inclusive os seus Conteúdos e quaisquer softwares de suporte são bens de
            propriedade da GlobalEnglish, de suas licenciantes ou de suas subcontratadas e estão protegidos pela
            legislação de direitos de autor e demais leis de propriedade intelectual dos EUA e internacionais.
            <br />
            <br /> É proibida de forma rígida a reprodução, redistribuição, modificação ou publicação de qualquer parte
            dos Serviços sem o consentimento expresso escrito da GlobalEnglish e/ou de suas licenciantes ou
            diferentemente do estabelecido abaixo.
            <br />
            <br />
            Os Utilizadores Autorizados podem aceder e utilizar os Serviços e os Conteúdos e descarregar ou imprimir uma
            quantidade razoável de cópias de porções dos Conteúdos aos quais os Utilizadores obtiveram acesso de forma
            adequada, exclusivamente para a utilização pessoal dos Utilizadores, para fins de estudo de idioma, contanto
            que os Utilizadores mantenham todos os avisos de direito de autor ou outros avisos proprietários em todas as
            cópias desses Conteúdos.
            <br />
            <br /> Salvo indicação contrária, as marcas registadas que aparecem nos Serviços são marcas registadas da
            GlobalEnglish, de suas licenciantes ou de suas afiliadas. V.S.ª concorda em não exibir, denegrir ou manchar
            as marcas registadas ou utilizar quaisquer marcas similares confusas ou utilizá-las de uma forma que venha a
            deturpar a titularidade dessas marcas ou criar a impressão de que a GlobalEnglish endossa qualquer produto
            ou serviço. V.S.ª não pode reproduzir ou utilizar as marcas registadas, nomes comerciais e/ou imagem
            comercial da GlobalEnglish sem a autorização escrita prévia da mesma. Todo o fundo de comércio gerado a
            partir da utilização das marcas registadas da GlobalEnglish reverterão em nosso benefício exclusivo.
            <br />
            <br />
            Qualquer utilização dos sítios, aplicativos ou Conteúdos que não seja da forma autorizada de forma
            específica no presente é proibida, além de cessar automaticamente os seus direitos no que diz respeito à
            utilização dos Serviços e dos Conteúdos aqui conferida. Todos os direitos da GlobalEnglish ou de suas
            licenciantes que não estão conferidos de forma expressa nestes Termos são reservados à GlobalEnglish e às
            suas licenciantes.
          </span>
          <br />
          <br />
          <b>8.2 Marcas Registadas de Terceiros</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Nomes e logotipos de Produtos e Serviços utilizados e exibidos através do Sítio, salvo as marcas registadas
            da GlobalEnglish descritas acima, podem ser marcas registadas ou marcas de serviço de suas respectivas
            titulares, as quais podem ou não endossar ou estar afiliadas ou conectadas com a GlobalEnglish. Nada contido
            nestes Termos ou no sítio deverá ser interpretado como concessão, implicitamente, por preclusão ou de outra
            forma, de qualquer licença ou direito de utilização de qualquer uma das marcas registadas exibidas no Sítio
            sem a nossa autorização prévia por escrito em cada ocasião. Todo o fundo de comércio gerado a partir da
            utilização das marcas registadas da GlobalEnglish reverterão em nosso benefício exclusivo.
          </span>
          <br />
          <br />
          <b>8.3 Reclamações de Direitos de Autor nos Termos da Lei de Direitos de Autor do Milénio Digital</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Se tiver quaisquer preocupações sobre direitos de autor referentes a quaisquer materiais publicados nos
            Serviços por terceiros, favor avisar.
            <br />
            <br /> A GlobalEnglish processará e investigará avisos de suposta infração e tomará as medidas apropriadas
            nos termos da Lei de Direitos de Autor do Milénio Digital (“DMCA”) e demais leis de propriedade intelectual
            vigentes no que diz respeito à qualquer suposta ou real infração.
            <br />
            <br /> Uma notificação de alegação de infração de direitos de autor deve ser enviada ao endereço de correio
            eletrónico do Agente de Direitos de Autor da GlobalEnglish copyright@globalenglish.com (Linha de assunto:
            “Pedido de Retirada da DMCA”).
            <br />
            <br /> V.S.ª também pode nos contatar por correio:
            <br />
            <b>A/c: Denúncias de Direitos de Autor/Departamento Jurídico </b>
            <br /> 1875 South Grant Street, Suite 700
            <br /> San Mateo, CA 94402 USA
            <br />
            <br />
            <br /> Para ter validade, a notificação deve ser escrita e conter as seguintes informações: (i) uma
            assinatura eletrónica ou física da pessoa autorizada a agir em nome da detentora dos direitos de autor ou
            demais participação em propriedade intelectual; (ii) uma descrição da obra ou demais propriedade intelectual
            sob direitos de autor que V.S.ª alega ter sido infringida; (iii) uma descrição de onde o material que V.S.ª
            alega ser infração está localizado no Serviço, com detalhes suficientes que para possa ser encontrada no
            sítio; (iv) informações suficientes para que a GlobalEnglish contate V.S.ª (endereço, número de telefone e
            endereço de correio eletrónico); (v) uma declaração de que V.S.ª tem uma crença legítima que a ultilização
            contestada não é autorizada pelo detentor dos direitos de autor ou de propriedade intelectual, pelo seu
            agente ou na forma da lei; (vi) uma declaração sua, feita sob pena de falso testemunho, que as informações
            supra em seu Aviso são precisas e que V.S.ª é a detentora dos direitos de autor ou de propriedade
            intelectual ou que está autorizada a agir em nome da detentora de direitos de autor ou de propriedade
            intelectual.
            <br />
            <br /> De acordo com a DMCA e demais leis vigentes, a GlobalEnglish adotou uma política de cancelamento, nas
            circunstâncias apropriadas e a critério exclusivo da GlobalEnglish, dos utilizadores que sejam considerados
            infratores reincidentes. A GlobalEnglish também pode, a seu exclusivo critério, limitar acesso ao Sítio e/ou
            cancelar as inscrições de quaisquer utilizadores que infrinjam quaisquer direitos de propriedade intelectual
            de terceiros, independentemente da reincidência ou não de qualquer infração.
          </span>
          <br />
          <br />
          <b>8.4 Remoção dos Conteúdos do Utilizador com Suspeita de Infração</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Se a GlobalEnglish tiver removido parte dos seus Conteúdos do Utilizador com suspeita de infração dos
            direitos de propriedade intelectual de terceiros e se V.S.ª crer que os seus Conteúdos do Utilizador que
            foram removidos (ou aos quais foi desativado o acesso) não configuram infração, ou que V.S.ª tem autorização
            da detentora dos direitos de autor, do agente da detentora dos direitos de autor, ou nos termos da
            legislação, para publicar e usar os conteúdos em seus Conteúdos do Utilizador, V.S.ª poderá enviar uma
            contra-notificação que contenha as seguintes informações ao Agente de Direitos de Autor: (i) sua assinatura
            física ou eletrónica; (ii) identificação dos conteúdos que foram removidos ou aos quais foi desativado o
            acesso e a localização na qual os conteúdos apareciam antes de terem sido removidos ou desativados; (iii)
            uma declaração que V.S.ª tem uma crença legítima de que os conteúdos foram removidos ou desativados como
            resultado de erro ou identificação incorreta dos conteúdos; e (iv) seu nome, endereço, número de telefone e
            endereço de correio eletrónico, uma declaração que V.S.ª aceita a jurisdição do tribunal federal situado no
            Distrito Norte da Califórnia e uma declaração que V.S.ª aceitará a citação processual da pessoa que
            providenciou a notificação da suposta infração.
            <br />
            <br /> Se uma contra-notificação for recebida pelo Agente de Direitos de Autor, a GlobalEnglish encaminhará
            uma cópia da contra-notificação à parte reclamante original para informar aquela pessoa que pode recolocar
            os conteúdos removidos ou cessar a sua desativação em 10 dias úteis. A não ser que a detentora dos direitos
            de autor instaure uma ação para recorrer a um mandado judicial contra a provedora dos conteúdos, membro ou
            utilizador, os conteúdos removidos podem ser recolocados, ou o seu acesso restabelecido, de 10 a 14 dias
            úteis ou mais após o recebimento da contra-notificação, a nosso critério exclusivo.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            {' '}
            9. UTILIZAÇÃO INTERNACIONAL E CONTROLE DE EXPORTAÇÃO{' '}
          </b>
          <br />
          <br /> Em reconhecimento da natureza global da Internet, V.S.ª concorda em aderir a todas as normas locais
          relativas a Conteúdos do Utilizador aceitáveis e conduta online. V.S.ª aceita cumprir todas as leis vigentes
          relativas à transmissão de dados técnicos exportados dos Estados Unidos ou do país em que reside.
          <br />
          <br /> V.S.ª reconhece que os Serviços ou parte dos mesmos pode estar sujeita à legislação de controle de
          exportação dos Estados Unidos. V.S.ª não exportará, reexportará nem transferirá qualquer parte do Serviço ou
          quaisquer informações técnicas ou materiais relacionados direta ou indiretamente que violem qualquer norma ou
          lei de exportação vigente. V.S.ª declara e garante que não é cidadã, nem está localizada, em um país sujeito a
          um embargo do Governo Norte-Americano ou que foi designado pelo Governo Norte-Americano como um país que dá
          “apoio a terroristas” e que V.S.ª não está relacionada em nenhuma lista do Governo Norte-Americano de pessoas
          proibidas ou restritas.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. MEDIDA CAUTELAR </b>
          <br />
          <br /> V.S.ª reconhece que qualquer utilização do Serviço contrária aos Termos de Utilização podem ocasionar
          prejuízo irreparável à GlobalEnglish, suas afiliadas, licenciantes, subcontratadas, revendedoras e
          Utilizadores. Em hipótese alguma a GlobalEnglish, suas afiliadas, licenciantes, subcontratadas, revendedoras e
          Utilizadores terão direito a indenmização equitativa sem prestar caução ou outro valor mobiliário, inclusive,
          porém sem limitação, medida cautelar preventiva e permanente.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11.PARALISAÇÃO </b>
          <br />
          <br /> Os Serviços podem ficar indisponíveis temporariamente para manutenção ou por outros motivos
          periodicamente. A GlobalEnglish não terá nenhuma responsabilidade por qualquer interrupção, atraso na operação
          ou transmissão, furto ou destruição, acesso desautorizado, alteração de comunicações de Utilizadores ou de
          qualquer outro conteúdo disponibilizado através do sítio.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12. EXCLUSÃO DE GARANTIAS </b>
          <br />
          <br /> Os Serviços podem ficar indisponíveis temporariamente para manutenção ou por outros motivos
          periodicamente. A GlobalEnglish não terá nenhuma responsabilidade por qualquer interrupção, atraso na operação
          ou transmissão, furto ou destruição, acesso desautorizado, alteração de comunicações de Utilizadores ou de
          qualquer outro conteúdo disponibilizado através dos Serviços. Sob hipótese alguma a GlobalEnglish se
          responsabilizará de qualquer forma por lesão corporal ou morte resultante da utilização dos Serviços, de
          quaisquer Conteúdos do Utilizador ou Conteúdos de Terceiros, ou de qualquer utilização de produtos ou serviços
          fornecidos por Utilizadores.
          <br />
          <br /> V.S.ª COMPREENDE E ACEITA QUE:
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            {' '}
            (a) OS SERVIÇOS, INCLUSIVE TODOS OS CONTEÚDOS NOS MESMOS, SÃO PRESTADOS NO ESTADO EM QUE SE ENCONTRAM E
            CONFORME SUA DISPONIBILIDADE.
            <br />
            <br /> (b) A GLOBALENGLISH RENUNCIA TODA E QUALQUER DECLARAÇÃO E GARANTIA RELACIONADA AOS SERVIÇOS, SEJA
            EXPRESSA SEJA IMPLÍCITA, INCLUSIVE GARANTIAS IMPLÍCITAS DE TITULARIDADE, COMERCIABILIDADE, ADAPTAÇÃO A UM
            PROPÓSITO EM PARTICULAR OU FALTA DE INFRAÇÃO.
            <br />
            <br /> (c) A GLOBALENGLISH NÃO GARANTE E NÃO PROMETE QUAISQUER RESULTADOS ESPECÍFICOS A PARTIR DA UTILIZAÇÃO
            DO SERVIÇO NEM QUE OS SERVIÇOS ATENDERÃO ÀS SUAS EXIGÊNCIAS OU EXPECTATIVAS.
            <br />
            <br />
            (d) A GLOBALENGLISH NÃO GARANTE NEM DECLARA QUE OS SOFTWARES, CONTEÚDOS OU MATERIAIS NOS SERVIÇOS OU NO
            SÍTIO SÃO PRECISOS, COMPLETOS, CONFIÁVEIS, CORRENTES OU LIVRES DE ERROS OU QUE O SÍTIO OU OS SERVIÇOS, OU
            SEUS SERVIDORES, ESTÃO LIVRES DE VÍRUS OU DE OUTROS COMPONENTES NOCIVOS. PORTANTO, SEM LIMITES AO EXPOSTO
            ACIMA, V.S.ª COMPREENDE E ACEITA QUE DESCARREGA OU OBTÉM DE OUTRA FORMA CONTEÚDOS, MATERIAIS, DADOS OU
            SOFTWARES DOS SERVIÇOS OU DE UMA PROVEDORA, OU A PARTIR DESTAS, A SEU PRÓPRIO CRITÉRIO E RISCO, E QUE SERÁ A
            ÚNICA RESPONSÁVEL POR SUA UTILIZAÇÃO DOS MESMOS E POR QUAISQUER DANOS AO SEU SISTEMA DE COMPUTADOR, PERDAS
            DE DADOS OU DEMAIS PREJUÍZOS DE QUALQUER ESPÉCIE QUE VENHAM RESULTAR.
            <br />
            <br /> (e) A GLOBALENGLISH NÃO GARANTE QUE SERÃO CORRIGIDOS QUAISQUER ERROS OU DEFEITOS NOS SERVIÇOS.
            <br />
            <br />
            (f) A GLOBALENGLISH NÃO É RESPONSÁVEL PELA CONDUTA DE UTILIZADORES, POR CONTEÚDOS DE TERCEIROS E POR
            CONTEÚDOS DO UTILIZADOR.
            <br />
            <br />
            (g) Os Serviços podem ficar indisponíveis temporariamente para manutenção ou por outros motivos
            periodicamente. A GlobalEnglish não terá nenhuma responsabilidade por qualquer interrupção, atraso na
            operação ou transmissão, furto ou destruição, acesso desautorizado, alteração de comunicações de
            Utilizadores ou de qualquer outro conteúdo disponibilizado através dos Serviços. Sob hipótese alguma a
            GlobalEnglish se responsabilizará de qualquer forma por lesão corporal ou morte resultante da utilização dos
            Serviços, de quaisquer Conteúdos do Utilizador ou Conteúdos de Terceiros, ou de qualquer utilização de
            produtos ou serviços fornecidos por Utilizadores.
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. LIMITAÇÃO DE RESPONSABILIDADES</b>
          <br />
          <br /> EM NENHUMA HIPÓTESE A GLOBALENGLISH OU SEUS DIRETORES, FUNCIONÁRIOS, AGENTES, LICENCIANTES OU
          SUBCONTRATADAS RESPONSABILIZAR-SE-ÃO PERANTE V.S.ª OU QUALQUER TERCEIRO POR DANOS INDIRETOS, CONSEQUENTES,
          EXEMPLARES, INCIDENTES, ESPECIAIS OU COMINATÓRIOS, INCLUSIVE QUALQUER LUCRO CESSANTE OU PERDA DE DADOS
          PROVENIENTE DE: (I) A SUA UTILIZAÇÃO OU INCAPACIDADE DE UTILIZAR OS SERVIÇOS, OU QUAISQUER CONTEÚDOS OU DEMAIS
          MATERIAIS NO SÍTIO, NOS SERVIÇOS OU NAS PROVEDORAS, OU ACESSADOS OU DESCARREGADOS POR MEIO DESTAS, (II) O
          CUSTO DE AQUISIÇÃO DE BENS E SERVIÇOS SUBSTITUTOS PROVENIENTES DE QUAISQUER BENS, DADOS, INFORMAÇÕES OU
          SERVIÇOS ADQUIRIDOS OU OBTIDOS OU DE MENSAGENS RECEBIDAS OU TRANSAÇÕES CELEBRADAS A PARTIR DO SÍTIO, OU
          ATRAVÉS DO MESMO; (III) O ACESSO DESAUTORIZADO OU ALTERAÇÃO DE SUAS TRANSMISSÕES OU DADOS POR QUALQUER PARTE;
          (IV) AS DECLARAÇÕES OU A CONDUTA DE QUALQUER TERCEIRO NO SÍTIO OU; (V) QUALQUER OUTRA MATÉRIA RELACIONADA AOS
          SERVIÇOS. SOB NENHUMA CIRCUNSTÂNCIA A RESPONSABILIDADE TOTAL DA GLOBALENGLISH PERANTE V.S.ª POR TODOS OS
          PREJUÍZOS, PERDAS OU CAUSAS DE PEDIR DEVERÁ EXCEDER O MONTANTE QUE PAGOU À GLOBALENGLISH NOS ÚLTIMOS DOZE (12)
          MESES OU, SE FOR MAIS, CEM DÓLARES (US$100), MESMO SE A GLOBALENGLISH TIVER CONHECIMENTO OU FOR AVISADA DA
          POSSIBILIDADE DE TAIS PREJUÍZOS. A EXISTÊNCIA DE MAIS DE UMA AÇÃO NO PRESENTE NÃO AUMENTARÁ ESTE LIMITE.
          <br />
          <br /> ALGUMAS JURISDIÇÕES NÃO PERMITEM A EXCLUSÃO DE CERTAS GARANTIAS NEM A LIMITAÇÃO OU EXCLUSÃO DE
          RESPONSABILIDADE PARA PREJUÍZOS INCIDENTES OU CONSEQUENTES. DESTA FORMA, ALGUMAS DAS LIMITAÇÕES ESTABELECIDAS
          ACIMA TALVEZ NÃO SE APLIQUEM A V.S.ª.
          <br />
          <br /> SE NÃO ESTIVER SATISFEITO COM QUALQUER PARTE DO SÍTIO OU COM ESTES TERMOS DO SÍTIO, SEU RECURSO ÚNICO E
          EXCLUSIVO É CESSAR A UTILIZAÇÃO DO SÍTIO.
          <br />
          <br /> SOB HIPÓTESE ALGUMA A GLOBALENGLISH SE RESPONSABILIZARÁ POR LESÃO CORPORAL OU MORTE RESULTANTE DA
          UTILIZAÇÃO DOS SERVIÇOS OU DE CONTEÚDOS DE TERCEIROS OU DE CONTEÚDOS DO UTILIZADOR.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14. PRESCRIÇÃO </b>
          <br />
          <br />
          V.S.ª concorda que independentemente de regimento ou legislação contrária, qualquer ação ou causa de pedir
          contra a GlobalEnglish oriunda da sua utilização dos Serviços, ou relacionada à mesma, deverá ser apresentada
          no prazo de um (1) ano após o surgimento da referida ação ou causa de pedir ou excluída para todo o sempre.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>15. INDEMNIZAÇÃO </b>
          <br />
          <br />
          V.S.ª concorda em desobrigar, indemnizar e isentar de responsabilidade a GlobalEnglish e suas afiliadas e seus
          dirigentes, funcionários, diretores e agentes perante toda e qualquer reclamação, ação, custa,
          responsabilidade, perda, prejuízo, despesa, inclusive honorários advocatícios razoáveis, apresentada contra a
          GlobalEnglish por qualquer terceiro por causa de ou proveniente de ou com relação à sua utilização do sítio,
          dos Serviços, dos seus Conteúdos do Utilizador, da sua conexão com o Sítio ou com os Serviços, da sua violação
          destes Termos ou da sua violação de quaisquer direitos de outro Utilizador ou de qualquer lei ou proveniente
          de outra forma da sua utilização dos Serviços. As suas obrigações de indemnização incluem, sem limitação,
          alegações contra a GlobalEnglish de que quaisquer Conteúdos do Utilizador infringem os direitos de propriedade
          intelectual de um terceiro.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>16. CANCELAMENTO </b>
          <br />
          <br /> Se a sua assinatura for cancelada por qualquer motivo, a GlobalEnglish desativará o seu acesso aos
          Serviços. V.S.ª não poderá aceder quaisquer Conteúdos, inclusive os seus Conteúdos do Utilizador, embora
          cópias de tais dados deverão permanecer em nosso sistema por três (3) anos para fins de cópia de segurança.
          <br />
          <br /> Se V.S.ª solicitar a exclusão de seus Dados Pessoais e dos Conteúdos do Utilizador pela GlobalEnglish,
          envidaremos todos os esforços razoáveis para assim o fazer e confirmaremos quando ocorrer o apagamento.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>17. RENÚNCIA E AUTONOMIA DAS CLÁUSULAS </b>
          <br />
          <br /> A incapacidade da GlobalEnglish de exercer ou fazer cumprir qualquer direito ou disposição destes
          Termos não deverá constituir uma renúncia desse direito ou disposição nessa ou em qualquer outra ocasião. Se
          qualquer disposição destes Termos for considerada ilícita, nula ou por qualquer motivo inexequível, essa
          determinação não deverá afetar a validade e exequibilidade de quaisquer disposições remanescentes.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>18. TOTALIDADE DO CONTRATO </b>
          <br />
          <br /> O presente Contrato constitui a totalidade do contrato entre V.S.ª e a GlobalEnglish quanto à
          utilização dos Serviços e suplanta quaisquer entendimentos e acordos prévios entre V.S.ª e a GlobalEnglish
          quanto à sua utilização dos Serviços.
          <br />
          <br /> Não obstante ao exposto acima, se sua Empresa tiver um Contrato existente com a GlobalEnglish para a
          aquisição de quaisquer Serviços, estes Termos não tem o intuito de alterar os termos ou condições de tal
          eventual contrato da sua Empresa com a GlobalEnglish, suas subsidiárias ou afiliadas e, em caso de qualquer
          conflito, os termos do referido Contrato prevalecerão.
          <br />
          <br /> O presente Contrato foi preparado na língua inglesa e essa versão deverá ser controladora em todos os
          aspectos e qualquer versão do presente Contrato que não seja a inglesa se dá apenas para fins de acomodação.
          Uma versão impressa do presente contrato e de qualquer aviso passado de forma eletrónica deverá ser admissível
          em procedimentos judiciais ou administrativos com base, ou relação, no presente contrato na mesma medida e
          sujeito às mesmas condições a que de outros documentos e registos comerciais originalmente gerados e mantidos
          em forma impressa. Os títulos das secções nestes Termos são apenas para conveniência e não têm efeito jurídico
          ou contratual.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>19.ELEIÇÃO DE FORO, LEI E ARBITRAGEM </b>
          <br />
          <br />
          Estes Termos serão regidos pela legislação do Estado da Califórnia sem levar em conta suas disposições de
          conflito de leis.
          <br />
          <br /> Mediante escolha da GlobalEnglish e a seu critério exclusivo, todas as divergências, ações ou
          controvérsias provenientes, ou relacionadas, destes Termos ou ao Sítio que não forem resolvidas por acordo
          mútuo, podem ser resolvidas por arbitragem vinculante a ser realizada perante a JAMS ou sua sucessora. Salvo
          acordo em contrário das partes, a arbitragem será realizada em São Francisco na Califórnia perante um árbitro
          singular de acordo mútuo das partes, ou se não houver acordo mútuo entre as partes, um árbitro singular
          nomeado pela JAMS, e será conduzida de acordo com as normas e regulamentos promulgados pela JAMS, a não ser
          que seja modificada de forma específica nestes Termos. A arbitragem deve começar no prazo de quarenta e cinco
          (45) dias da data em que uma notificação por escrito de arbitragem for apresentada por qualquer uma das
          partes. A decisão e sentença do árbitro deverão ser passadas e providenciadas no prazo de sessenta (60) dias
          da conclusão da arbitragem e no prazo de seis (6) meses da seleção do árbitro. O árbitro não terá o poder de
          conceder indemnizações que excedam a limitação sobre danos diretos compensatórios estabelecidos nos Termos e
          não poderá multiplicar danos efetivos ou conceder indemnizações punitivas ou quaisquer outras indemnizações
          que estejam excluídas de forma específica no âmbito dos Termos, sendo que cada parte renúncia por meio deste,
          de forma irrevogável, qualquer ação das referidas indemnizações. O árbitro pode, a seu critério, determinar
          custas e despesas (inclusive as despesas e honorários advocatícios razoáveis da parte vencedora) contra
          qualquer parte de um procedimento. Qualquer parte que recusar o cumprimento de um despacho dos árbitros será
          responsável pelas custas e despesas, inclusive honorários advocatícios, contraídas pela outra parte ao fazer
          cumprir a sentença. Não obstante o exposto acima, no caso de medida cautelar temporária ou preventiva,
          qualquer parte pode entrar com processo sem arbitragem prévia com vistas a evitar prejuízo imediato e
          irreparável. As disposições desta secção de arbitragem serão passíveis de execução em qualquer juízo com
          competência jurisdicional.
          <br />
          <br /> No que diz respeito a quaisquer divergências ou ações que não estejam sujeitas a arbitragem, conforme
          estabelecido acima, V.S.ª e a GlobalEnglish aceitam submissão à competência jurisdicional pessoal e exclusiva
          dos tribunais federais e estaduais situados no Condado de São Francisco na Califórnia.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. AVISOS E CONTATOS </b>
          <br />
          <br />
          V.S.ª pode solicitar o cancelamento da sua conta de Serviço da GlobalEnglish mediante aviso de cancelamento à
          GlobalEnglish através do link do formulário de “Contato” no sítio
          <a href="https://support.globalenglish.com/" rel="noopener noreferrer" target="_blank">
            https://support.globalenglish.com/
          </a>
          . O seu acesso e utilização dos Serviços, inclusive quaisquer conteúdos do mesmo, podem ser bloqueados pela
          GlobalEnglish a qualquer momento após o cancelamento da sua conta.
          <br />
          <br /> Avisos para V.S.ª podem ser passados via correio eletrónico ou correio normal. O sítio também fornece
          avisos a V.S.ª de alterações destes Termos ou outros assuntos ao exibir avisos ou links de avisos geralmente
          no Serviço.
          <br />
          <br /> Avisos relacionados à nossa política de privacidade podem ser encaminhados para
          <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
            privacy@globalenglish.com.
          </a>
          <br />
          <br /> Todos os demais avisos jurídicos ou demais correspondências à GlobalEnglish devem ser enviadas ao
          endereço de correio eletrónico a seguir:
          <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
            legal@globalenglish.com
          </a>{' '}
          ou ao endereço físico a seguir:
          <br />
          <br /> 1875 S Grant Street, Suite 700
          <br /> San Mateo, CA 94402
          <br /> aos cuidados da
          <b>Assessoria Jurídica </b>
          <br />
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>TERMOS ESPECÍFICOS PARA TRANSAÇÕES B2B </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          {' '}
          Se V.S.ª obteve sua conta junto à GlobalEnglish através de seu patrão, a sua utilização de nossos Serviços
          também está sujeita ao nosso contrato com a sua empresa (uma “Empresa“). Os Termos de Utilização não têm o
          intuito de alterar os termos do contrato com a sua Empresa. V.S.ª não pode utilizar (ou continuar a utilizar)
          os Serviços se sua Empresa não autorizar V.S.ª como um utilizador final em sua licença com a GlobalEnglish. Se
          V.S.ª solicitar o cancelamento de sua conta, talvez seja exigido que a sua Empresa dê autorização antes que a
          GlobalEnglish cancele a sua conta.
          <br />
          <br /> V.S.ª aceita a utilização dos Serviços unicamente conforme autorização de sua Empresa. V.S.ª pode ter a
          oportunidade de se comunicar com terceiros em sua Empresa ou carregar documentos relacionados à sua Empresa
          através dos Serviços, mas não tem permissão para assim agir, e não poderá assim agir, sem a permissão de sua
          Empresa.
          <br />
          <br /> V.S.ª reconhece e concorda que todos os dados gerados em conexão com a sua utilização dos Serviços
          (“Dados do Utilizador“) poderá ser acessado pela sua Empresa e que a GlobalEnglish não terá nenhuma
          responsabilidade perante V.S.ª com base nas informações compartilhadas com a sua Empresa como consequência da
          sua utilização de nossos Serviços.
          <br />
          <br />
          Como a sua assinatura de acesso dos Serviços continua somente durante o prazo de vigência da licença dessa
          Empresa, se a sua Empresa cancelar a licença dela com a GlobalEnglish, V.S.ª perderá acesso aos Serviços e aos
          Conteúdos do Utilizador. A GlobalEnglish poderá cancelar ou suspender a conta da sua Empresa, a sua conta ou a
          capacidade de utilizar os Serviços, no todo ou em parte, sem aviso, caso (i) a sua Empresa deixar de efetuar o
          pagamento de quaisquer taxas devidas nos termos do presente à GlobalEnglish quando vencidas, ou caso (ii)
          V.S.ª ou a sua Empresa violem os Termos ou quaisquer outras normas que regem a utilização dos Serviços.
          <br />
          <br /> A GlobalEnglish poderá ter uma obrigação de devolver todos os dados pertinentes à sua utilização dos
          Serviços, inclusive seus Dados Pessoais e os Conteúdos do Utilizador à sua empresa. Se sua empresa deixar de
          solicitar a devolução ou apagamento desses dados, a GlobalEnglish deverá manter os dados pelo período de três
          (3) anos após o encerramento ou cancelamento de seu contrato com seu patrão. Em tais casos, fique à vontade
          para comprar uma assinatura do serviço diretamente da GlobalEnglish e continuar a aceder o programa sem perda
          de informações de progresso. V.S.ª pode autorizar a transferência do seu progresso e das informações do perfil
          para uma nova assinatura com o seu novo patrão. V.S.ª também pode solicitar todos os dados pertinentes à sua
          utilização dos Serviços em um formato compatível com importações futuras.
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>TERMOS ESPECÍFICOS PARA TRANSAÇÕES B2C </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          V.S.ª também pode celebrar um contrato de assinatura com a GlobalEnglish na qualidade de indivíduo, o que
          compreende uma assinatura de três (3) ou seis (6) meses ou um plano de assinatura anual ou multianual (cada
          qual, um “Plano de Assinatura”).
          <br />
          <br />
          V.S.ª será cobrada mensal ou anualmente pelo Plano de Assinatura aplicável no mesmo dia, aproximadamente,
          todos os meses/anos (conforme for o caso) até que cancele o seu Plano de Assinatura. Para evitar dúvida,
          observe que V.S.ª não tem permissão para cancelar, reduzir o número de vagas ou diminuir o Serviço que
          selecionou até o aniversário do seu Plano de Assinatura. Não haverá reembolso dos pagamentos do Plano de
          Assinatura.
          <br />
          <br /> As taxas que se aplicam ao Serviço (“Taxas”) estão disponíveis na tabela de preços atual publicada à
          época e/ou no Sítio da GlobalEnglish. O preço especificado para o Serviço exclui todos os tributos e encargos,
          salvo afirmação contrária. V.S.ª é responsável por quaisquer impostos e por todos os encargos incidentes à
          utilização dos Serviços (por exemplo, cobranças de dados e liquidações de câmbio). V.S.ª pagará as Taxas na
          moeda que a GlobalEnglish orçar para a sua conta. A GlobalEnglish reserva-se o direito de alterar a moeda
          orçada a qualquer momento.
          <br />
          <br />
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}> Pagamento </b>
          <br />
          <br /> O pagamento é exigível no momento da compra de uma licença de assinatura. Realizamos uma parceria com
          PayPal e Stripe para o processamento de seus pagamentos. Não recebemos nem processamos quaisquer informações
          de cartão de crédito ou demais método de pagamento que providenciar a estes prestadores de serviço.
          <br />
          <br /> Todos os Serviços são pré-pagos referentes ao período selecionado (mensal, anual ou outro) e não são
          reembolsáveis. Isto inclui contas que forem renovadas.
          <br />
          <br /> -----
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>Aviso para Utilizadores da Califórnia </b>
          <br />
          <br /> Se for residente da Califórnia, a Secção 1789.3 do Código Civil da Califórnia permite que V.S.ª
          solicite informações relacionadas à divulgação de seus Dados Pessoais para terceiros para os fins de marketing
          dos terceiros. A Lei de Privacidade do Consumidor da Califórnia, que entrará em vigor em 2021 (Secção 1798.100
          a 1709.198 do Código Civil) também confere a V.S.ª determinados direitos:
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            (a)o direito de saber quais Dados Pessoais foram colhidos a seu respeito e receber cópias,
            <br />
            <br />
            (b) o direito de apagamento,
            <br />
            <br />
            (c) o direito de saber com quais terceiros seus Dados Pessoais seriam comercializados e o direito de
            solicitar a cessação dessa comercialização e
            <br />
            <br />
            (d) o direito de estar livre de discriminação caso exerça alguns dos seus direitos do consumidor.
          </span>
          <br /> Além disso, a Secção 22581 do Código Profissional e Comercial da Califórnia permite no momento que
          residentes da Califórnia menores de idade solicitem a remoção de conteúdos que veicularam publicamente no
          Sítio. Observe, no entanto, que a GlobalEnglish não pode garantir a remoção completa ou abrangente dos
          conteúdos que publicou e que pode haver circunstâncias sob as quais a legislação não exige nem permite a
          remoção mesmo mediante solicitação.
          <br />
          <br />
          Se V.S.ª deseja fazer uma solicitação para exercer seus direitos:
          <br />
          <br /> A provedora do Sítio e serviços associados é a GlobalEnglish Corporation, situada na 1875 South Grant
          Street, Suite 700, San Mateo, CA 94402 EUA. O endereço de correio eletrónico é: privacy@globalenglish.com.
          <br /> A partir da data destes Termos, não serão impostas cobranças diretas no consumidor pelo Sítio. Se tiver
          uma reclamação dos serviços prestados no Sítio ou quiser solicitar mais informações, favor nos contatar no
          endereço físico acima com uma descrição de sua solicitação. Não podemos garantir uma resposta em qualquer
          prazo em particular. Se continuar insatisfeito, contate a Unidade de Assistência a Reclamações da divisão de
          Serviços do Consumidor do Departamento de Assuntos do Consumidor da Califórnia por escrito no endereço 1625
          North Market Blvd., Suite N 112, Sacramento, CA 95834, ou pelo telefone (916) 445-1254 ou (800) 952-5210.
        </p>
      </div>
    </div>
  );
}

export default PtBrContent;
