// @ts-nocheck
import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { LangPopup } from "components/LangPopup";
import { FormattedMessage } from "react-intl";
import messages from "bundles/common/intl/messages";
import { GDPRPolicyPopup } from "components/GDPRPolicyPopup";
import { GDPRPopup } from "components/GDPRPopup";
import { domains } from "../../../../constants";

import {
  getLangFromCode,
  getOtherLangFromCode,
} from "utils/helper/cookieHelper";
import geLogo from "../../../../assets/images/LS-logo.svg";

export const Header = (props) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [showGDPRPopUp, setGDPRShowPopUp] = useState(false);
  const [showGDPRPolicy, setGDPRPolicy] = useState(false);
  const universalCookies = new Cookies();
  const [lang, setLang] = useState("English");
  const [otherLangs, setOtherLangs] = useState("Other Languages");

  useEffect(() => {
    setGDPRShowPopUp(
      universalCookies.get("gecookiepolicy")
        ? universalCookies.get("gecookiepolicy")
        : 0,
    );
    setShowPopUp(!universalCookies.get("ulang"));
    setLang(language);
    setOtherLangs(otherLanguages);
    // eslint-disable-next-line
  }, [lang, otherLangs]);

  function handlePopup() {
    setShowPopUp(!showPopUp);
  }

  function getAppDomain() {
    var hostParts = window.location.hostname.split(".");
    return hostParts[1] + "." + hostParts[2];
  }

  function acceptCookies() {
    universalCookies.set("gecookiepolicy", 1, {
      path: "/",
      domain: domains.learnship[process.env.REACT_APP_ENV],
      secure: true,
    });
    setGDPRShowPopUp(
      universalCookies.get("gecookiepolicy")
        ? universalCookies.get("gecookiepolicy")
        : 0,
    );
  }

  function handleGDPRPolicy() {
    setGDPRPolicy(true);
  }

  function closeGDPRPolicy() {
    setGDPRPolicy(false);
  }

  let language = universalCookies.get("ulang")
    ? getLangFromCode(universalCookies.get("ulang"))
    : "English";
  let otherLanguages = universalCookies.get("ulang")
    ? getOtherLangFromCode(universalCookies.get("ulang"))
    : "Other Languages";

  function setUlangCookie() {
    universalCookies.set("ulang", "en", {
      path: "/",
      domain: process.env.REACT_APP_LEARNSHIP_DOMAIN,
      secure: true,
      sameSite: 'strict'
    });
    universalCookies.set("language", "en", {
      path: "/",
      domain: process.env.REACT_APP_LEARNSHIP_DOMAIN,
      secure: true,
      sameSite: 'strict'
    });
  }

  useEffect(() => {
    let mounted = true;
    const existingLang = universalCookies.get("ulang");
    if (!existingLang && mounted) {
      try {
        setUlangCookie();
      } catch (error) {
        console.error('Failed to set language cookie:', error);
      }
    }
    return () => {
      mounted = false;
    };
  }, [])

  let supportLang = universalCookies.get("ulang")
    ? universalCookies.get("ulang").toLowerCase() === "en"
      ? "en-en"
      : universalCookies.get("ulang").toLowerCase()
    : "en-en";

  const urlParams = new URLSearchParams(window.location.search);
  const urlLanguage = urlParams.get("language");
  if (urlLanguage) {
    urlParams.set("language", "en");
  } else {
    urlParams.append("language", "en");
  }

  let ref = "?" + urlParams.toString();

  return (
    <div>
      {showGDPRPolicy && <GDPRPolicyPopup closeGDPRPolicy={closeGDPRPolicy} />}
      {!showGDPRPopUp && (
        <GDPRPopup
          handleGDPRPolicy={handleGDPRPolicy}
          acceptCookies={acceptCookies}
        />
      )}
      <div className="container header">
        <div>
          {/* eslint-disable-next-line */}
          <a id="settings-icon" className="settings-icon fr" href="#home" />
        </div>
        <div className="top_holding" id="settings">
          <div className="fl pe-logo">
            <img
              src={`${process.env.REACT_APP_LS_LOGO_WHITE_URL}`}
              alt="Formerly GlobalEnglish"
              title="Formerly GlobalEnglish"
            />
          </div>
          <div className="nav">
            <ul>
              <li>
                <a
                  className="white"
                  href={`${process.env.REACT_APP_LS_SUPPORT_URL}/${supportLang}/contact_support`}
                  title="Help"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.help} />
                </a>
              </li>
            </ul>
          </div>
          <div className="pick_lang white">
            <ul className="cntry" id="country">
              <li className="choose">
                <span style={{ cursor: "pointer" }} className="arrow white">
                  {lang}
                </span>
                <ul id="countryContainer">
                  <div className="cntryContainer">
                    {language === "English" ? (
                      ""
                    ) : (
                      <li>
                        <a href={ref} onClick={setUlangCookie} title="">
                          <span className="icon sl"></span>
                          English
                        </a>
                      </li>
                    )}
                    <li>
                      <a href="#home" onClick={handlePopup} title="">
                        <span className="icon sl" />
                        {otherLangs}
                      </a>
                    </li>
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        {showPopUp && <LangPopup handlePopup={handlePopup} />}
      </div>
    </div>
  );
};
