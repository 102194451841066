import React, { createContext } from 'react'
import AWS from 'aws-sdk'
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { WEB_DOMAIN_URL } from './../../constants';
import { datadogLogs } from '@datadog/browser-logs';
// import { resolve } from 'cypress/types/bluebird';
// import { reject } from 'cypress/types/lodash';

const cognito = new AWS.CognitoIdentityServiceProvider({
  region: `${process.env.REACT_APP_AWS_REGION}`,
});

const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_USERPOOLID,
  ClientId: process.env.REACT_APP_AWS_CLIENT_ID,
  RedirectUriSignIn: `${process.env.REACT_APP_GE_LOGIN_URL}`,
  RedirectUriSignOut: `${process.env.REACT_APP_GE_LOGIN_URL}/logout`,
  AppWebDomain: `${WEB_DOMAIN_URL}`,
  TokenScopesArray: ['email']
};
let Pool: any = new CognitoAuth(poolData);
const Account = (props: any) => {
  return (
    <AccountContext.Provider
      value={{
        getSession,
        logout,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

const verifyAndEnableMFA = async (
  userCode: any,
  cogUserEventObj: any,
  authResult: any,
) => {
  return new Promise((resolve, reject) => {
    getSession().then(({ user, accessToken, headers }) => {
      if (typeof accessToken !== "string") {
        accessToken = accessToken.jwtToken;
      }// MFA URls given previously
      const uri = `${process.env.REACT_APP_API_GATEWAY_URL || ""
        }/mfa?accessToken=${accessToken}&userCode=${userCode}`;
      fetch(uri, {
        method: "POST",
        headers,
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.Status && result.Status === "SUCCESS") {
            const settings = {
              PreferredMfa: true,
              Enabled: true,
            };
            // mfa is enabled here
            user.setUserMfaPreference(null, settings, () => { });
            return resolve({ resultIndex: 0, authResult });
          } else {
            if (result.errorType === "EnableSoftwareTokenMFAException") {
              // Incorrect 6-digit code!
              return resolve({ resultIndex: -1, authResult });
            } else if (result.errorType === "InvalidParameterException") {
              // 'Please provide a 6-digit number'
              return resolve({ resultIndex: -2, authResult });
            }
          }
        })
        .catch((err) => {
          datadogLogs.logger.error('Account exception', err);
          return reject(err);
        });
    });
  });
};

const verifyMFACode = async (userCode: any, cogUserEventObj: any) => {
  return new Promise((resolve, reject) => {
    cogUserEventObj.sendMFACode(
      userCode,
      {
        onSuccess: (res) => {
          // Verification code correct - Second time user
          return resolve({ result: res });
        },
        onFailure: (err) => {
          // Verification code incorrect - Second time user
          return reject(err);
        },
      },
      "SOFTWARE_TOKEN_MFA",
    );
  });
};

const getQRCode = async (uName: string) => {
  return new Promise((resolve, reject) => {
    getSession().then(({ accessToken, headers }) => {
      if (typeof accessToken !== "string") {
        accessToken = accessToken.jwtToken;
      }// MFA URls given previously
      const uri = `${process.env.REACT_APP_API_GATEWAY_URL || ""
        }/mfa?accessToken=${accessToken}&userName=${uName ? uName : "Learnship"}`;
      fetch(uri, {
        headers,
      })
        .then((data) => data.json())
        .then((imageData) => {
          // QR-Code image data
          return resolve(imageData);
        })
        .catch((err) => {
          console.error(err);
          return reject();
        });
    });
  });
};

const getSession = async () =>
  await new Promise((resolve, reject) => {
    const UserPool = new CognitoUserPool(poolData);
    const user = UserPool.getCurrentUser();
    if (user) {
      user.getSession(async (err: Error, session: any) => {
        if (err) {
          reject();
        } else {
          const attributes: any = await new Promise((resolve, reject) => {
            user.getUserAttributes((err, attributes) => {
              if (err) {
                reject(err);
              } else {
                const results = {};
                attributes.map((attribute: any) => {
                  const { Name, Value } = attribute;
                  results[Name] = Value;
                });
                resolve(results);
              }
            });
          });

          const accessToken = session.accessToken.jwtToken;

          const mfaEnabled = await new Promise((resolve) => {
            cognito.getUser(
              {
                AccessToken: accessToken,
              },
              (err, data) => {
                if (err) resolve(false);
                else
                  resolve(
                    data.UserMFASettingList &&
                    data.UserMFASettingList.includes("SOFTWARE_TOKEN_MFA"),
                  );
              },
            );
          });

          const token = session.getIdToken().getJwtToken();

          resolve({
            user,
            accessToken,
            mfaEnabled,
            headers: {
              "x-api-key": attributes["custom:apikey"],
              Authorization: token,
            },
            ...session,
            ...attributes,
          });
        }
      });
    } else {
      reject();
    }
  });

const logout = () => {
  const user = Pool.getCurrentUser();
  if (user) {
    user.signOut();
    window.location.href = "/";
  }
};

const AccountContext = createContext<any>({ getSession, logout });
export {
  Account,
  AccountContext,
  verifyMFACode,
  getQRCode,
  verifyAndEnableMFA,
};
