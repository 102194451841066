// @ts-nocheck
import React from 'react';

function TrTrContent() {
  return (
    <div id="agreement_container">
      <div style={{ padding: '1% 5% 0 5%' }}>
        <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
          <b>KULLANICI SÖZLEŞMESİ</b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
          <b>GİRİŞ </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          GlobalEnglish İnternet Sitesi veya mobil uygulamaları hizmetlerine (birlikte “Hizmetler” olarak anılacaktır)
          hoş geldiniz.
          <br />
          <br /> Hizmetlerimiz, İnternet sitesi ve mobil uygulamalar aracılığıyla oluşturulan veya erişilebilir kılınan
          veri, metin, çevrimiçi testler, tasarımlar, videolar, ses klipleri, ses kayıtları, yazılı gönderiler ve
          yorumlar, senaryolar, grafikler, resimler ve bunların seçilmesi ve düzenlenmesi, yazılım, interaktif
          eğitimler, dersler, koçluk, eğitim, iletişim ve interaktif özellikleri içeren ancak bunlarla sınırlı olmayan
          (birlikte "İçerik"), İngilizce öğrenme araçları ve çevrimiçi çözümlerdir.
          <br />
          <br /> Aşağıda belirtilen şartları devam etmeden lütfen dikkatlice okuyunuz ve anlayarak mutabık olduğunuzu
          kabul ediniz.
          <br />
          <br />
          <b>
            18 YAŞIN ALTINDAYSANIZ, HİZMETLERİMİZİ KULLANMADAN ÖNCE BU SÖZLEŞMEYİ LÜTFEN EBEVEYNİNİZLE VEYA
            VELİ/VASİNİZLE OKUDUĞUNUZDAN VE RIZALARINI ALDIĞINIZDAN EMİN OLUN. HİZMETLERİMİZİ KULLANARAK, 18 YAŞINDAN
            BÜYÜK OLDUĞUNUZU YA DA EBEVEYNİNİZ VEYA VELİ/VASİNİZDEN GEREKLİ ONAYI ALDIĞINIZI VE GLOBALENGLISH İLE İŞBU
            SÖZLEŞMEYİ KURMANIZA İZİN VERİLDİĞİNİ BELİRTMİŞ OLURSUNUZ.
          </b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
          <b>GENEL ŞARTLAR </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 1. HUKUKEN BAĞLAYICI SÖZLEŞME </b>
          <br />
          <br />
          <b>
            {' '}
            İNTERNET SİTEMİZE ERİŞİMİNZ VE GLOBALENGLISH’İN HİZMETLERİNİ KULLANIMINIZ AŞAĞIDAKİ KULLANICI SÖZLEŞMESİ’NE,
            GİZLİLİK POLİTİKASI VE ÇEREZ POLİTİKASI’NA (ÜÇ BELGE BİRLİKTE “KULLANIM ŞARTLARI” OLARAK ANILACAKTIR)
            TABİDİR. GLOBALENGLISH’İN HİZMETLERİNE ERİŞMENİZ, ZİYARET ETMENİZ VE/VEYA KULLANMANIZLA BİRLİKTE,
            HİZMETLERİMİZİN KULLANIM ŞARTLARINI OKUDUĞUNUZU VE ANLADIĞINIZI, VE HUKUKEN VE GAYRİKABİL-İ RÜCU OLARAK BU
            ŞARTLARLA BAĞLI OLMAYI KABUL ETTİĞİNİZİ, VE BU ŞARTLARLA BAĞLI OLMAYI KABUL ETMEK VE GLOBALENGLISH İLE
            HUKUKEN BAĞLAYICI BİR SÖZLEŞME KURMAK İÇİN YASAL OLARAK UYGUN YAŞTA OLDUĞUNUZU BELİRTMİŞ OLURSUNUZ.
          </b>
          <br />
          <br /> Politikalarımızla ilgili genel bilgileri
          <a
            href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
            rel="noopener noreferrer"
            target="_blank"
          >
            burada
          </a>{' '}
          bulabilirsiniz. Bu politikalar, işbu “Kullanıcı Sözleşmesi” veya “Sözleşme” ye referans olarak dahil edilmiş
          hep birlikte kullanım Şartlarını oluştururlar.
          <br />
          <br />
          <b>
            Bu Sözleşme sizin ve bizim birbirimize karşı ileri süreceğimiz taleplerin nasıl çözüleceğine ilişkin
            hükümler içermektedir. Bu konuya ilişkin olarak aşağıdaki Garantinin Reddi, Sorumluluğun Sınırlandırılması
            ve Hukuki Uyuşmazlıklar hükümlerini inceleyiniz. Sözleşme, sınırlı istisnalarla, bağlayıcı ve nihai tahkim
            için bize karşı hak talebinde bulunmanızı gerektiren bir tahkim sözleşmesi de içermektedir. Şikayetçi olarak
            veya herhangi bir toplu veya temsili davada veya grup üyesi olarak değil, yalnızca bireysel olarak ileri
            sürmenize izin verilmektedir. Haksızlığın telafisini (parasal, ihtiyati ve tespitle telafi dahil) sadece
            bireysel olarak talep etmenize izin verilir.
            <br />
            <br /> HERHANGİ BİR ZAMANDA BURADA BELİRTİLEN ŞARTLARIN HERHANGİ BİR KISMINI KABUL ETMİYORSANIZ, HİZMETLERİ
            KULLANMAYINIZ VEYA İNTERNET SİTEMİZDE DAHA FAZLA GEZİNMEYİNİZ.
          </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. UYGULANABİLİRLİK </b>
          <br />
          <br /> Bu Sözleşme, hem hizmetlerimizin kullanıcılarına hem de sitelerimizdeki ziyaretçilere uygulanır. Bize,
          deneme amaçlı olanlar dahil olmak üzere, doğrudan kaydolanlar (<b>“İşletmeden tüketiciye-B2C işlem”</b>) ve
          işverenleri tarafından/aracılığıyla kayıtlı olanlar (<b>“İşletmeler arası-B2B işlem”</b>)<b>“Kullanıcılar”</b>{' '}
          olarak adlandırılırken, kaydolmamış kullanıcılar ile İnternet sitemizde gezinen ziyaretçiler
          <b>“Ziyaretçiler”</b> olarak adlandırılırlar.
          <b>“Kullanıcılar”</b> terimi GlobalEnglish sitelerine diğer Kullanıcılara hizmet sağlamak için erişen
          öğretmenler, koçlar, öğrenme uzmanları ve diğer kişileri de içermektedir. Hem Kullanıcılar hem de Ziyaretçiler
          <b>“Siz”</b> olarak anılmaktadır.
          <br />
          <br /> Bu Sözleşme bütün GlobalEnglish Hizmetleri’ne uygulanır.
          <br />
          <br /> Kullanıcı olarak ilk kez kaydolarak Hizmetlerimize eriştiğinizde, “Kabul ediyorum” butonuna tıklayarak
          veya elektronik imzanızı sağlayarak veya bir doğrulama yöntemiyle işbu hükümlerle bağlı olduğunuzu
          yinelemenizi sizden talep edebiliriz.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>3. KULLANIM ŞARTLARINDA YAPILACAK DEĞİŞİKLİKLER </b>
          <br />
          <br />
          GlobalEnglish herhangi bir zamanda, Hizmetleri kullanımınıza uygulanan işbu Sözleşme, Çerez Politikası ve
          Gizlilik Politikası da dahil Kullanım Şartlarında değişiklik yapabilir. Bu tür değişiklikler Hizmetler
          hesabınızla (varsa) bağlantılı olarak sağlamış olduğunuz e-posta adresinize elektronik mesaj veya başka
          yollarla Hizmet üzerinden gönderilerek iletilen bildirim üzerine derhal geçerli hale gelir. Hizmetlerimize
          işvereniniz veya bir şirket aracılığıyla kaydolduysanız, işvereniniz veya şirketiniz Kullanım Şartlarımızdaki
          değişiklikleri size bildirebilir.
          <br />
          <br /> Bu değişikliklere itiraz etmeniz halinde, hesabınızı kapatabilir ve İnternet sitemize erişmeyi
          durdurabilirsiniz. Bununla beraber, bu Şartlarda yapılan değişikliklerle ilgili bir bildirim yayınlamamız veya
          gönderdiğimizden sonra Hizmetlerimizi kullanmaya devam etmeniz, Kullanım Şartlarına yapılan söz konusu
          değişiklikleri kabul ettiğiniz anlamına gelir.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4.ÜÇÜNCÜ ŞAHISLARIN KULLANIM ŞARTLARI </b>
          <br />
          <br />
          Hizmetler, diğer İnternet sitelerine (“Üçüncü Şahıs Siteleri”) bağlantı linkleri içerebileceği gibi üçüncü
          şahıslara ait veya bu şahıslardan kaynaklı makalelere, fotoğraflara, metinlere, çevirilere, grafiklere,
          resimlere, tasarımlara, seslere, videolara, bilgilere ve diğer içerik ya da öğelere (“Üçüncü Şahıs İçeriği”)
          bağlantı linkleri içerebilir. Bu Üçüncü Şahıs Siteleri ve Üçüncü Şahıs İçerikleri doğrulukları, uygunlukları
          veya tamamlanmış olmalarıyla ilgili olarak tarafımızca incelenmez, izlenmez veya kontrol edilmezler, ve
          İnternet sitesi üzerinden erişilen hiçbir Üçüncü Şahıs Sitelerinden ya da İnternet Sitesinde yayınlanmış veya
          İnternet sitesi üzerinden erişilebilen Üçüncü Şahıs İçeriklerinden tarafımız sorumlu değildir.
          <br />
          <br /> Herhangi bir Üçüncü Şahıs Sitesine veya Üçüncü Şahıs İçeriğine linkle bağlantı yapılması veya bunların
          dahil edilmesi veya kullanımına izin verilmesi, bunların GlobalEnglish tarafından onaylandığı veya uygun
          bulunduğu anlamına gelmez. GlobalEnglish, önceden bir bildirimde bulunmaksızın herhangi bir zamanda bu
          bağlantılara son verme hakkını saklı tutar. Hizmetlerde herhangi bir şirkete, kuruluşa, ürüne veya hizmete
          yapılan atıflar veya yer alan diğer bilgiler, GlobalEnglish tarafından bunların onaylandığı, ya da bununla
          birlikte aralarında sponsorluk veya öneri ilişkisinin var olduğu anlamına gelmez veya buna işaret etmez.
          <br />
          <br /> İnternet sitemizden çıkarak Üçüncü Şahıs Sitelerine erişmeye ya da herhangi bir Üçüncü Şahıs İçeriğine
          erişmeye ya da kullanmaya karar vermeniz halinde, her türlü riski üzerinize almış olursunuz ve bu Üçüncü Şahıs
          Sitelerini kullanımınızı artık bizim şart ve politikalarımızın kapsamadığının bilincinde olmalısınız. Lütfen,
          üçüncü şahısların bu gibi diğer ürün ve hizmetlerine uygulanan Şart ve politikaları, bu ürün ve hizmetleri
          kullanmadan önce kontrol ettiğinizden emin olunuz.
          <br />
          <br /> Hem Android Market hem de iTunes Mağazası üzerinden mobil uygulamalar sunuyoruz (ikisi birlikte ve
          onları idame ettiren kuruluşlarla birlikte, “Sağlayıcılar”). Bu mobil uygulamaları kullanımınız aynı zamanda,
          App Store Hizmet Şartlarında belirtilen Kullanım Kuralları da herhangi bir sınırlama olmaksızın dahil olacak
          şekilde, Sağlayıcıların hüküm ve Şartlarına tabidir. Bu mobil uygulamalardan herhangi birini iTunes
          Mağazası’ndan indirdiyseniz, App Store Hizmet Şartlarını okuduğunuzu ve kabul ettiğinizi onaylamış ve kabul
          etmiş olursunuz. Bu uygulamaları Android Market'ten indirdiyseniz, Android Market Hizmet Şartlarını
          okuduğunuzu ve kabul ettiğinizi onaylamış ve kabul etmiş olursunuz. Sağlayıcıların, Hizmetleri kullanımınızla
          ilgili olarak size hiç bir bakım veya destek hizmeti sağlama yükümlülüğü yoktur ve olmayacaktır. Sağlayıcılar,
          Hizmetler için hiçbir biçimde garanti vermezler. Mobil uygulamalardan herhangi birinin geçerli bir garantiye
          uymaması ve böyle bir uygulamayı iTunes Mağazası’ndan indirmiş olmanız durumunda, Apple’a bildirimde
          bulunursanız söz konusu uygulamanın satın alma ücretini (varsa) Apple iade edecektir. Bu Şartlar, diğer
          Hizmetlerimize olduğu gibi tüm mobil uygulama kullanımlarınıza uygulanacaktır.
          <br />
          <br /> Bu üçüncü şahıs İnternet sitelerinin veya uygulamalarının içeriğinden, ürünlerinden, hizmetlerinden
          veya performansından GlobalEnglish’in sorumlu veya mesul olmadığını onaylar ve kabul edersiniz. Üçüncü
          şahısların İnternet siteleri veya uygulamalarıyla ilgili olarak GlobalEnglish aleyhine herhangi bir talepten
          gayrikabil-i rücu olarak feragat etmiş olursunuz. GLOBALENGLISH bilhassa, ÇEREZLER, İNTERNET İŞARETÇİLERİ,
          TAKİP PİKELLERİ, VEYA BİLGİSAYARINIZA BİR ŞEKİLDE SİZİN VEYA DİĞER BİR KİŞİ TARAFINDAN YERLEŞTİRİLEN DİĞER BİR
          KOD İLE, İNTERNET SİTESİ İÇERİĞİ İLE BU KODUN HERHANGİ BİR BAĞLANTISI DA DAHİL OLMAK ÜZERE SORUMLU DEĞİLDİR.
          Örneğin, İnternet sitesi üzerindeki bir bağlantı linki üzerinden bir Üçüncü Şahıs Sitesine erişmeniz halinde,
          tarayıcınız veya bilgisayarınızdaki diğer çerezler, o İnternet sitesine tarama geçmişinizin bir kısmını veya
          ziyaretinizin kaynaklandığı URL’yi belirtir bir yönlendirici başlık satırı gönderilmesine sebep olabilir.
          GlobalEnglish’in, söz konusu aktarımla ilgili olarak, aktarımın GlobalEnglish ile ilişkili veya kaynaklı
          bilgileri içerip içermediği farketmeksizin hiçbir sorumluluğu bulunmamaktadır.
          <br />
          <br /> GOOGLE’IN SAĞLADIĞI TERCÜMELERİ KULLANMANIZ HALİNDE: GOOGLE, HER TÜRLÜ DOĞRULUK, GÜVENILIRLIK GARANTİSİ
          VE TICARI OLARAK SATILABILIRLIK, BELIRLI BIR AMACA UYGUNLUK VE IHLAL EDILMEME GIBI HER TÜRLÜ ZIMNI GARANTİLER
          DAHIL TERCÜMELERLE İLGİLİ AÇIK VEYA ZIMNİ HER TÜRLÜ GARANTİYİ REDDEDER.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>5. HİZMET UYGUNLUK KRİTERİ </b>
          <br />
          <br />
          <b>5.1 Çocukların Gizliliğinin Korunması ve Hizmetlerimizin Kullanımına İlişkin Kısıtlamalar</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Hizmetler, Hizmetlere eriştikleri ülkedeki Asgari Yaşın altındaki hiç kimsenin kullanımına uygun değildir.
            Örneğin, Avrupa Birliği’ndeki, Avrupa Ekonomik Bölgesi’ndeki ve İsviçre'deki “Asgari Yaş”, özel ülke
            yasalarıyla aksi belirtilmediği sürece, 16 yaş anlamına gelir. ABD’de asgari yaş 13 yaştır. Asgari Yaşın
            altındaki herhangi biri tarafından Hizmetlere herhangi bir şekilde kaydolma, Hizmetlerin kullanımı ya da
            erişimi yasaklanmıştır ve bu durum Şartların ihlalini oluşturur.
            <br />
            <br /> Hizmetleri kullanmakla, 13 yaşında veya daha büyük olduğunuzu belirtmiş ve taahhüt etmiş olursunuz.
          </span>
          <br />
          <br />
          <b>5.2 Okullar ve Öğretmenler </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            ABD'de 13 yaşın altındaki öğrencilerinin GlobalEnglish hesapları oluşturmasını isteyen okullar, bölgeler
            veya öğretmenler ABD Çocukların Çevrimiçi Gizlilik Koruma Yasasına (U.S. Children's Online Privacy
            Protection Act - "COPPA") ve uygulanabildiği kadarıyla Aile Eğitim Hakları ve Gizlilik Yasası’na (Family
            Educational Rights and Privacy Act - "FERPA") uymakla yükümlü olduklarını kabul ederler. Bu, okulların,
            bölgelerin veya öğretmenlerin öğrencilerin ebeveynlerine veya veli/vasilerine GlobalEnglish'in toplayacağı
            kişiyi tanımlamak için kullanılan bilgileri bildirmeleri ve öğrenciler hesapları oluşturup Hizmetleri
            kullanmadan önce ebeveyn/veli/vasi rızalarını almaları gerektiği anlamına gelir. Bu tür bir rıza alırken,
            okullar, bölgeler ve öğretmenler ebeveyn /veli/vasilere Gizlilik Politikamızın bir kopyasını sunmalı ve tüm
            izinleri dosyalamalı ve talep etmemiz halinde bize sağlamalıdır. “COPPA”ya uyum konusunda daha fazla bilgi
            edinmek için
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
              rel="noopener noreferrer"
              target="_blank"
            >
              Federal Ticaret Komisyonu’nun İnternet sitesine
            </a>
            bakabilirsiniz.
            <br />
            <br /> ABD’nin dışında bir yerdeyseniz, benzer yasalar kapsamındaki herhangi bir öğrencinin ebeveyni veya
            velisi/vasisinden gerekli tüm rıza veya onayların alınması konusunda size güvenmekteyiz ve siz, sizin ve
            öğrencilerinizin Hizmetlerimizi kullanmasının bir Şart olarak, bu tür yasalara uymakla yükümlü olacağınızı
            kabul ettiniz.
          </span>
          <br />
          <br />
          <b>5.3 Ebeveyn Rızası </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            <b>
              HİZMETLERİMİZİ KULLANARAK, 18 YAŞINDAN BÜYÜK OLDUĞUNUZU VEYA AİLENİZDEN YA DA VELİ/VASİNİZDEN GEREKEN
              RIZAYI ALDIĞINIZI VE GLOBALENGLISH’LE BU SÖZLEŞMEYİ KURMAYA İZİN VERİLDİĞİNİ BELİRTMİŞ OLURSUNUZ.
              <br />
              <br /> Çocukların yalnızca ebeveynlerinin, veli/vasilerinin ve/veya yetkili okul görevlilerinin
              rehberliği, gözetimi ve rızası ile kullanılmasını bekliyoruz. GlobalEnglish, reşit olmayanların yalnızca
              bu Kullanım Şartlarında belirtilen haklarını ve sorumluluklarını anlamaları halinde Hizmetleri
              kullanmasını sağlamak için ebeveynlere, veli/vasilere ve okullara güvenir.
              <br />
              <br /> GlobalEnglish, tamamen kendi takdir yetkisi dahilinde olarak Hizmetleri herhangi bir kişiye veya
              işletmeye sunmayı reddedebilir ve uygunluk kriterlerini istediği zaman değiştirebilir.
            </b>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6.GİZLİLİK </b>
          <br />
          <br />
          <b>6.1 Gizlilik Beyanı </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Lütfen
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              burada
            </a>{' '}
            bulunan Gizlilik Politikası’nı inceleyiniz.
            <br />
            <br /> Hizmetlere ilk erişiminizde veya planlanan güncellemesinin bir kısmına rıza verdiğiniz gizlilik
            politikasına uygun olarak kişisel verileri işleyeceğiz.
          </span>
          <br />
          <br />
          <b>6.2 Sağladığınız Veriler </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Hizmetleri kullanabilmek için geçerli bir hesabınızın olması gerekmektedir. Hizmetlerimize kaydolmak için
            GlobalEnglish’e Kullanıcı Adı, e-posta adresi ve kişiyi tanımlamak için belirlenen diğer bilgileri (“Kişisel
            Veri”) sağlamanız gerekmektedir. Sizden topladığımız bazı bilgiler GlobalEnglish’in Hizmetleri sağlaması
            için veya yalnızca soruşturduğunuz Hizmetlerle ilgili sizlere bilgi göndermek gerekmekte olup, diğerleri
            isteğe bağlıdır. Hizmetler ayrıca kendiniz ve kuruluşunuzla ilgili bilgileri, varsa, görüntülemenizi sağlar.
            <br />
            <br /> (i) Hizmetlerdeki kayıt formlarında istenen şekilde kendinizle ilgili ve bir B2B işleminin varlığı
            halinde kuruluşunuzla ilgili doğru, güncel ve tam bilgi sağlayacağınızı; (ii) hesabınızın güvenliğiyle
            ilgili olarak şifreniz ve diğer bilgilerinizin gizliliğini koruyacağınızı; (iii) GlobalEnglish’e
            sağladığınız Kişisel Veri veya diğer bilgileri idame ettireceğinizi ve gecikmeden güncelleyeceğinizi, bu
            bilgilerin doğru, güncel ve tam olmasını sağlayacağınızı; (iv) hesabınızın bütün kullanımından ve hesabınız
            üzerinden gerçekleşen işlemlerden tamamen sorumlu olacağınızı, kabul etmektesiniz.
            <br />
            <br /> GlobalEnglish, kayıt amacıyla sağladığınız Kişisel Verilerin doğruluğunu teyit etme yükümlülüğünü
            üstlenmez.
          </span>
          <br />
          <br />
          <b>6.3 Kişisel Verilerin Uluslararası Aktarımı </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            GlobalEnglish, Hizmetleri sağlamak için, dünya genelindeki işgücüne güvenmekte ve üçüncü kişi satıcıları ve
            hosting ortaklarını, depolama ve diğer teknoloji ile ilgili hizmetler ve öğretim hizmetleri için
            kullanmaktadır. Bu sebeple Kişisel Verileriniz işlenerek GlobalEnglish’in çeşitli ülkelerdeki çalışanlarına
            ve taşeronlarına aktarılır; bu ülkelerden bazılarıyla vatandaşı olduğunuz veya ikamet ettiğiniz ülkenizde
            Kişisel Verilerinize yasal olarak sağlanan koruma aynı seviyede sunulmuyor olabilir.
            <b>
              Hizmetlerimize erişerek, kayıt yaptırarak ve/veya Hizmetlerimizi kullanarak, Kişisel Verilerinizin
              GlobalEnglish’in, bağlı kuruluşlarının ve taşeronlarının Hizmetlerle ilgili amaçlar için bulunduğu çeşitli
              yurtdışı ülkelere transfer edilmesini ve buralarda işlenmesini kabul etmiş sayılırsınız.
            </b>
          </span>
          <br />
          <br />
          <b>6.4 Bildirimler ve Mesajlar</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Hizmetlerin, GlobalEnglish'ten hizmet duyuruları, idari mesajları vb. belirli iletişimleri içerebileceğini,
            bu iletişimlerin Hizmetlerinin bir parçası olduğunu ve bunları almaktan vazgeçemeyeceğinizi anlıyor ve kabul
            ediyorsunuz.
            <br />
            <br /> Bu şekilde, GlobalEnglish’in size: (i) Hizmetler içinde; (ii) GlobalEnglish’e sağladığınız iletişim
            bilgisine (ör. E-posta, mobil telefon) yollarıyla bildirim ve mesaj gönderebileceğini kabul etmiş olursunuz.
            İletişim bilgilerinizi her zaman güncel tutacağınızı kabul edersiniz.
          </span>
          <br />
          <br />
          <b>6.5 Diğer Kişilerin Gizliliği </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Hizmetlerimizi kullanımınız esnasında başka bir Kullanıcı hakkında bilgi edinirseniz, bu bilgiyi yalnızca
            size sağlanan amaç altında kullanacağınızı kabul edersiniz. Bir Kullanıcı’ya ait bilgileri Hizmetlerle
            ilgisi olmayan amaçlar için bir üçüncü şahsa açıklayamaz, satamaz veya dağıtamazsınız. Bu bilgileri, bizzat
            ilgili Kullanıcının rızasını almaksızın pazarlama amacıyla kullanamazsınız.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. KULLANICI YETKİLERİ </b>
          <br />
          <br />
          <b>7.1 Hizmetlerimizi Kullanma Yetkisi </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Hizmetlere erişiminiz ve kullanımınız için, size ticari olmayan ve yalnızca kişisel kullanımınız için
            sınırlı, kişisel, münhasır olmayan, devredilemez, alt lisans verilemez ve devredilemez bir yetki verilir.
            GlobalEnglish, tamamen kendi takdir yetkisi dahilinde, geçici veya kalıcı olarak Hizmeti durdurabilir veya
            zaman zaman Hizmetlerin özelliklerini önceden haber vermeksizin değiştirebilir.
            <br />
            <br /> Hizmetleri kullanabilmek için, İnternet’e doğrudan ya da İnternet tabanlı içeriğe erişen cihazlar
            aracılığıyla erişim sağlamanız gerekmektedir. Buna ilaveten, İnternet'e bağlantı kurmak için bilgisayar ve
            modem ya da başka bir erişim cihazı da dahil olmak üzere, gerekli tüm ekipmana sahip olmalısınız.
            Sağladıkları içeriğin doğruluğuna, tamamlanmış olduğuna veya kullanışlılığına güvenilirlik de dahil olmak
            üzere, Hizmetlerin kullanımıyla ilgili tüm riskleri değerlendirmeniz ve bunları üstlenmeniz gerektiğini
            kabul etmektesiniz. GlobalEnglish ya da yetki verenleri herhangi bir içeriğin doğruluğu veya tamamlanmış
            olduklarıyla ilgili bir garanti vermez.
            <br />
            <br /> Hizmetler, riskli ortamlarda (ör. araç kullanırken, su içinde veya su çevresinde) kullanılmak üzere
            tasarlanmamış ya da lisanslanmamıştır.
            <br />
            <br />
            Hizmete erişim hakkı, Hizmetin kullanılmasının yasak olduğu yerlerde veya Hizmetin sunumunun, satılmasının
            veya tedarik edilmesinin geçerli yasa, kural veya yönetmeliklerle çelişmesi halinde iptal edilir.
            <br />
            <br />
            Hizmetin Kullanım Şartlarında tanımlanandan farklı amaçlarla kullanılması, Kullanım Şartlarının ve
            GlobalEnglish’in ve/veya lisans verenleri ile altyüklenici telif hakkı ve mülkiyet haklarının ihlalidir.
            GlobalEnglish’in, Hizmetleri işbu Kullanım Şartları'na aykırı şekilde kullandığınıza şüphe duymak için makul
            nedenleri varsa, hesabınızı askıya almaya veya feshetmeye ve mevcut veya gelecekteki tüm hizmetleri
            sağlamayı reddetmeye hakkı vardır. GlobalEnglish'in, Hizmetlerin kısmen veya tamamen değiştirilmesi, askıya
            alınması veya durdurulması nedeniyle size karşı sorumlu olmayacağını kabul edersiniz.
          </span>
          <br />
          <br />
          <b>7.2 Kullanıcı İçeriği</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Hizmetler, size ve diğer Kullanıcılara veri ve bilgi oluşturma (ör. testlerimizi yanıtlayarak) ve toplum
            tabanlı Hizmetlere içerikler yükleme imkanı (“Kullanıcı İçeriği”) sağlamaktadır. İşbu Şartlarla bize
            sağlanan haklar doğrultusunda, Kullanıcı İçeriğinizin tüm mülkiyeti size aittir.
            <br />
            <br /> Hizmetlerimize herhangi bir Kullanıcı İçeriği yüklediğinize GlobalEnglsh’e, bu Kullanıcı İçeriği’ni
            saklamak, kullanmak, çoğaltmak, kamuya açık işletmek, kamuya açık yayınlamak, değiştirmek, tercüme etmek,
            alıntı yapmak (tamamen veya kısmen), işlemek ve dağıtmak için, Hizmetlerin sunulmasıyla bağlantılı olarak:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) Yasal taleplere, mahkeme emirlerine ve diğer benzer resmi taleplere cevap vermeyi içeren ancak
              bunlarla sınırlı kalmayarak yasal sürece uymak;
              <br />
              (b) Sözleşmeyi uygulamak;
              <br />
              (c) Herhangi bir İçeriğin üçüncü şahısların haklarını ihlal etmesi halinde taleplere cevaplamak;
              <br />
              (d) GlobalEnglish’in, çalışanlarının, Kullanıcıların ve kamunun haklarını, mülkiyetlerini ve kişisel
              güvenliklerini korumak;
            </span>
            <br />
            <br /> İçin, gayrikabil-i rücu, sürekli, münhasır olmayan, devredilebilir, tam ödemeli, dünya genelinde
            geçerli (alt lisans verme yetkisiyle birlikte) bir yetki vermiş olursunuz.
            <br />
            <br /> Herhangi bir Kullanıcı İçeriğini Hizmetlerin diğer Kullanıcılarıyla paylaşmayı seçtiğinizde, bu
            Kullanıcıların Kullanıcı İçeriğinizi görüntülemesini ve uygulanabildiği ölçüde, bu Kullanıcı İçeriğiyle
            ilgili olarak sizinle işbirliği içinde çalışmalarına müsaade etmeyi kabul edersiniz.
            <br />
            <br /> GlobalEnglish'in bu gibi Kullanıcı İçeriklerini hiçbir zaman görüntüleme, gözden geçirme, doğrulama,
            düzeltme veya silme zorunluluğu bulunmadığını unutmayınız. Ancak buna rağmen, Kullanıcı İçeriğini
            görüntüleme ve mutlak takdir yetkisiyle Kullanım Şartlarını ihlal ettiğini veya başka bir şekilde ihlal
            içerdiğini kabul ettiklerini kaldırma hakkını saklı tutar.
            <br />
            <br /> GlobalEnglish’in, Kullanıcı İçeriği ile ilgili hiçbir yedekleme veya saklama yükümlülüğü yoktur.
            İnternet sitesinde yayınladığınız veya sakladığınız, veya GlobalEnglish'e sağladığınız herhangi bir
            Kullanıcı İçeriğini yedekleme ve değiştirme maliyeti ve giderleri tamamen size aittir.
            <br />
            <br /> Kullanıcı İçerikleri GlobalEnglish'in görüşlerini yansıtmayabilir. Kullanıcı İçeriğinin, içeriğin
            kaynaklandığı Kullanıcı'nın sorumluluğu olduğunu anlıyorsunuz. Hizmetleri kullanarak, saldırı içerikli,
            uygunsuz veya sakıncalı Kullanıcı İçeriklerine maruz kalabileceğinizi anlıyorsunuz.
            <br />
            <br /> Yukarıda belirtilen Kullanıcı İçeriğine verilen yetkiye ek olarak, GlobalEnglish'e tarafınızca
            sağlanan herhangi bir soru, yorum, öneri, fikir, geri bildirim veya Hizmetle ilgili diğer bilgilerin
            (“Gönderiler”) gizli olmadığını kabul edersiniz ve GlobalEnglis’in, sizden herhangi bir onay almaksızın veya
            size bir karşılık ödemeksizin, bu Gönderileri herhangi bir sınırlandırılma olmaksızın ticari olarak veya
            herhangi bir amaçla kullanma ve yayma hakkına sahip olacağını teyit ve kabul edersiniz. İlaveten,
            GlobalEnglish’in toplum tabanlı Hizmetleri veya ürünlerinde veya bunlar üzerinden tarafınızca gönderilerek
            açıklanan veya paylaşılan bilgiler, genel olarak tanımlanan gizli bilgi terimi kapsamının dışında
            tutulacaktır.
            <br />
            <br />
            İnternet sitesinin, sizin Kullanıcı İçeriğinizi de içerecek şekilde, teknik işlemesi ve aktarımının, (a)
            çeşitli bilgisayar ağları üzerinden aktarımlar; ve (b) ağların veya cihazları bağlamak için gereken teknik
            gerekliliklerine uyumu ve uyarlanması için yapılan değişiklikleri içerebileceğini anlıyorsunuz.
            <br />
            <br />
            <b>
              GlobalEnglish, Kullanıcı İçeriği ile ilgili olarak hiçbir beyanda bulunmaz veya garanti vermez. Kullanıcı
              İçerikleri GlobalEnglish'in görüşlerini yansıtmayabilir. GlobalEnglish’in, Kullanıcı İçeriğiyle ilgili
              veya herhangi bir sebeple kaybolması veya kullanımı ya da kaybı dolayısıyla talep edilen zarar ya da
              kayıplardan kaynaklanan hiçbir sorumluluğu ya da yükümlülüğü yoktur.
            </b>
          </span>
          <br />
          <br />
          <b>7.3 Zorunlu Kılınan Kullanıcı Tutumu</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Hesabınızda gerçekleşen tüm hareketlerinden tamamen siz sorumlusunuz.
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) Hesabınızda gerçekleşen tüm yetkisiz kullanımları veya herhangi diğer güvenlik ihlallerini derhal
              Global English’e bildirmeyi, ve
              <br />
              (b) Her oturum sonunda hesabınızdan çıkacağınızı, kabul edersiniz.
            </span>
            <br />
            <br />
            GlobalEnglish, uymamanızdan kaynaklanan hiçbir zarar veya ziyandan sorumlu tutulamaz ve tutulmayacaktır.
            <br />
            <br />
            Bu Hizmet Şartlarının, uymakla yükümlü olduğunuz hiçbir yasa, kural ve düzenlemeye aykırılık teşkil
            etmediğinden emin olmak tamamen sizin sorumluluğunuzdadır ve Hizmete erişim hakkı, bu Hizmet Şartlarının
            veya Hizmetin kullanımının yasak olduğu durumlarda veya Hizmetin sunulmasının, satışının veya sağlanmasının
            ilgili bir yasa, kural veya düzenlemeyle çeliştiği yerlerde iptal edilir.
          </span>
          <br />
          <br />
          <b>7.4 Yasaklanan Kullanıcı Tutumu </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Aşağıdakiler, hukuka aykırı veya GlobalEnglish tarafından yasaklanan kullanım biçimlerine örnektir.
            GlobalEnglish işbu hükmü ihlal eden herkes hakkında tamamen kendi takdir yetkisi dahilinde, saldırı içeren
            içeriğin İnternet sitesi veya uygulamadan kaldırılması, bu Kullanıcıların hesabının askıya alınması veya
            sonlandırılması, kolluk kuvvetlerine bildirilmesini içeren ancak bununla sınırlı olmaksızın ve uygun yasal
            işlem başlatma hakkını saklı tutar.
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) GlobalEnglish tarafından özel olarak yetki verilmedikçe, GlobalEnglish’in herhangi bir İçeriğini veya
              Hizmetteki diğer materyalleri uyarlayamaz, indiremez, düzeltemez, yayınlayamaz, ters mühendislik yapamaz,
              çoğaltamaz, yayınlayamaz, değiştiremez, yayamaz, görüntüleyemez, devredemez veya diğer bir şekilde
              kopyalayamaz ve/veya dağıtamazsınız.
              <br />
              (b) Hizmetin hiçbir bölümünü kapsayacak şekilde çevreleyemez veya çerçeveleme tekniklerini
              kullanamazsınız.
              <br />
              (c) Hizmetin herhangi bir bölümün, herhangi bir bot, örümcek, böcek, casus yazılım, motor, cihaz, yazılım
              veya herhangi diğer bir otomatik cihaz, hizmet veya herhangi bir manuel işlem kullanarak toplayamaz, elde
              edemez, kullanamaz veya başka bir şekilde kopyalayamazsınız.
              <br />
              (d) Hizmetleri veya Hizmetlerde bulunan herhangi bir özelliği Hizmetleri kesintiye veya zarara uğratmak,
              devre dışı bırakmak, aşırı yüklemek veya bozmak amacıyla hiçbir şekilde kullanamazsınız.
              <br />
              (e) Hizmetleri satmak, lisanslamak, kiralamak veya başka ticari amaçlar için kullanamazsınız.
              <br />
              (f) Başka bir Kullanıcının bu Hizmete erişimine, kullanımına veya istifade etmesine müdahale eden hiçbir
              faaliyette bulunamazsınız.
              <br />
              (g) (i) Yasadışı, zararlı, tehdit edici, küfürlü, taciz edici, haksız, aşırı şiddet içeren, hakaret
              içeren, kaba, müstehcen, uygunsuz, pornografik, tahkir eden, başkalarının gizliliğini çiğneyen (adres,
              e-posta, telefon numarası ve diğer iletişim bilgileri gibi), ırk, etnik kökene karşı nefret içeren veya
              başka şekillerle kabul edilemez olan; (ii) herhangi bir yasa uyarınca veya sözleşmeye bağlı olarak veya
              güvene dayalı ilişkilerde aktarma hakkınızın olmadığı; (iii) herhangi bir kimse için gizlilik veya
              güvenlik riski taşıyan veya yaratan; (iv) taraflardan herhangi birinin fikri hakkını veya diğer mülkiyet
              haklarını ihlal eden; (v) istenmeyen veya yetkisiz reklamlar, tanıtım malzemeleri, ticari faaliyetler
              ve/veya satışlar, “istenmeyen posta”, “spam”, “zincir mektuplar”, “piramit düzenleri”, “yarışmalar”,
              “çekilişler” veya başka herhangi bir çağrı içeriyorsa; (vi) herhangi bir bilgisayar yazılımı veya donanım
              veya telekomünikasyon cihazının işlevselliğini kesintiye uğratmak, yok etmek veya sınırlandırmak için
              tasarlanmış yazılım virüsleri veya başka herhangi bir bilgisayar kodu, dosya veya programı içeren; veya
              (vii) GlobalEnglish’in tamamen kendi takdirine göre kabul edilebilir olmayan herhangi bir kimsenin
              Hizmetleri kullanması veya hizmetlerden istifade etmesini kısıtlayan veya engelleyen veya GlobalEnglish’i
              veya kullanıcılarını herhangi bir zarara veya yükümlülüğe maruz bırakan Kullanıcı İçeriğini gönderemez,
              e-posta veya başka bir şekilde iletemezsiniz.
              <br />
              (h) Sahte bir kimlik oluşturamaz, kimliğinizi yanlış beyan edemez, herhangi bir kişiyi veya varlığın
              kimliğine bürünemez veya bir kişi veya kuruluşla olan ilişkinizi yanlış beyan veya başka şekilde ifade
              edemezsiniz.
              <br />
              (i) 18 yaş altındaki kimseden kişisel bilgi talep edemezsiniz.
              <br />
              (j) Küçük yaştaki kişilere hiçbir şekilde zarar veremezsiniz.
              <br />
              (k) Herhangi bir gruba veya kişiye karşı fiziksel zarar verilmesini veya yaralamayı teşvik edemez, hiç
              kimseyi “gizlice takip edemez” veya başka şekilde taciz edemezsiniz.
              <br />
              (l) Örneğin bomba, el bombası ve diğer silahların veya yakıcı cihazların yapımına ilişkin talimatlar gibi,
              yasa dışı faaliyetlerle ilgili talimat içeren bilgiler sağlayamaz veya sunamazsınız.
              <br />
              (m) Kullanıcılara istenmeyen e-postalar veya diğer iletişimler gönderme veya İnternet sitesinin içeriğini
              çoğaltmak da dahil ancak bunlarla sınırlı olmamak üzere herhangi bir amaçla otomatik kodları kullanmak
              suretiyle veya başka yollarla İnternet sitesinden sitenin içeriklerini veya e-posta adresleri, iletişim
              bilgileri veya İnternet sitesindeki diğer Kullanıcılara ait diğer özel bilgileri derleyemez ve
              toplayamazsınız.
              <br />
              (n) Özel olarak izin verilmeyen bir iş amacıyla herhangi bir mal veya hizmetin reklamını yapamaz veya
              satmak veya almak için teklif veremezsiniz.
              <br />
              (o) İnternet sitesine veya İnternet sitesine bağlı sunuculara ya da ağlara müdahale edemez veya kesintiye
              uğratamazsınız veya İnternet sitesine bağlı ağların gereksinimlerine, prosedürlerine, politikalarına veya
              düzenlemelerine uymamazlık edemezsiniz.
              <br />
              (p) Hiçbir yerel, eyalet, ulusal veya uluslararası hukuku ya da kanun gücünde herhangi bir düzenlemeyi
              ihlal edemezsiniz; veya daha da ötesinde herhangi bir Suç fiilini veya girişimini destekleyemez veya
              teşvik edemez veya yasa dışı faaliyetlerle ilgili talimatlar içeren bilgiler sağlayamazsınız.
              <br /> (q) Hiçbir materyali veya bilgiyi ihtiyari olarak İnternet sitesinde mevcut olmayan veya
              sağlanmamış bir yöntemle veya başka şekilde edinmeye kalkışamazsınız.
              <br />
              (r) Güvenlik özelliklerini çiğneyemez veya herhangi bir erişim kontrolünü atlamamalı veya atlayamazsınız.
              <br />
              (s) Diğer Kullanıcılarla görüş alış verişi yapmak veya asılsız görüşler yazmak ve talep etmek gibi
              Hizmetleri çıkar çatışması yaratabilecek veya Hizmetin amacına zarar verecek şekilde kullanamazsınız.
            </span>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. FİKRİ MÜLKİYET HAKLARI </b>
          <br />
          <br />
          <b>8.1 GlobalEnglish’in Fikri Mülkiyet Hakları </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Hizmetlerin, İçeriği ve herhangi bir destekleyici yazılımı dahil tüm içeriği GlobalEnglish'in, lisans
            verenlerinin veya alt yüklenicilerinin şahsi mülküdür ve ABD yasaları, uluslararası telif hakları ile diğer
            fikri mülkiyet yasaları ile korunmaktadırlar.
            <br />
            <br /> GlobalEnglish ve/veya lisans verenlerinin ya da aşağıda belirtilenlerin dışında, açık yazılı izni
            olmadan Hizmetlerin herhangi bir kısmının çoğaltılması, yeniden dağıtılması, değiştirilmesi veya
            yayınlanması kesin olarak yasaklanmıştır.
            <br />
            <br /> Yetkili Kullanıcılar, Hizmetlere ve İçeriğe erişebilir ve kullanabilir ve Kullanıcının yalnızca
            kullanıcının dil eğitimi amacıyla kişisel kullanması için düzgün olarak eriştiği İçeriğin bölümlerinin makul
            sayıda kopyasını, Kullanıcının söz konusu İçeriğin tüm kopyalarında telif hakkı veya diğer mülkiyet
            bildirimlerinin muhafaza etmesi şartıyla indirebilir veya yazdırabilir.
            <br />
            <br /> Aksi belirtilmediği sürece, Hizmetlerde görülen markalar GlobalEnglish'in, lisans verenlerinin veya
            bağlı kuruluşlarının markalarıdır. Markaları sergilememeyi, kötülememeyi veya kusurlu hale getirmemeyi ya da
            kafa karıştıracak benzer markaları kullanmamayı ya da markaların aidiyetini yanlış tanıtacak ya da
            GlobalEnglish'in herhangi bir ürün veya hizmeti desteklediği izlenimini yaratacak şekilde kullanmamaya kabul
            edersiniz. GlobalEnglish’in markalarını, ticari unvanlarını ve/veya ticari takdim şekillerini,
            GlobalEnglish’in önceden yazılı onayını almaksızın çoğaltamaz veya kullanamazsınız. GlobalEnglish
            markalarının kullanımından kaynaklanan şerefiyeler, münhasıran lehimize hüküm ifade eder.
            <br />
            <br /> İnternet sitelerinin, uygulamaların veya İçeriğin kullanımının burada özel olarak verilen yetkiler
            dışında başka bir şekilde kullanılması yasaktır ve Hizmetlerin ve İçeriğin kullanımınıza ilişkin burada
            sağlanan haklarınızı otomatik olarak sonlandırılmasıyla sonuçlanır. GlobalEnglish veya lisans verenlerinin
            işbu Şartlarda açıkça belirtilmemiş bütün hakları GlobalEnglish ve lisans verenlerinde saklıdır.
          </span>
          <br />
          <br />
          <b>8.2 Üçüncü Şahısların Markaları </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            GlobalEnglish’in yukarıda belirtilen markalarının haricinde İnternet Sitesi aracılığıyla kullanılan ve
            görüntülenen Ürün ve Hizmet adları ve logoları, GlobalEnglish’i onaylayan veya onaylamayan, GlobalEnglish
            ile ilişkili veya bağlantılı olan veya olmayan diğer ilgili kişilere ait markalar olabilir. Bu Şartlarda
            veya İnternet sitesindeki hiçbir şey, her bir durumda önceden yazılı iznimiz olmadan, dolaylı olarak, itiraz
            hakkının düşmesiyle veya farklı bir şekilde olsun, İnternet Sitesinde gösterilen markaların kullanımına
            ilişkin bir lisans veya hak verildiği şeklinde yorumlanmamalıdır. GlobalEnglish markalarının kullanımından
            kaynaklanan tüm şerefiyeler, münhasıran, lehimize hüküm ifade eder.
          </span>
          <br />
          <br />
          <b>8.3 Dijital Binyıl (Milenyum) Telif Hakkı Yasasına Göre Telif Hakkı Şikayetleri </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Hizmetlere diğer kişilerce gönderilen herhangi bir unsurla ilgili bir telif hakkı kaygınız varsa, lütfen
            bizi bilgilendirin.
            <br />
            <br /> GlobalEnglish, hak ihlali iddialarına ilişkin bildirimleri yürütecek ve araştıracak ve iddia edilen
            veya gerçekleşmiş olan hak ihlalleriyle ilgili olarak Dijital Binyıl Telif Hakkı Yasası (Digital Millennium
            Copyright Act - “DMCA”) ve yürürlükteki diğer fikri mülkiyet yasaları kapsamında uygun eylemleri
            gerçekleştirecektir.
            <br />
            <br /> Telif hakkı ihlaline ilişkin iddia bildirimi, GlobalEnglish’in Telif Hakkı Temsilcisi'ne
            copyright@globalenglish.com adresine e-posta yoluyla gönderilmelidir (Konu: “DMCA Alma Talebi”).
            <br />
            <br /> Bizimle ayrıca posta yoluyla da aşağıdaki adresten iletişime geçebilirsiniz:
            <br />
            <b>Telif Hakkı Şikayetleri/Hukuk Departmanı Dikkatine</b>
            <br /> 1875 South Grant Street, Suite 700
            <br /> San Mateo, CA 94402 USA
            <br />
            <br />
            <br /> Bildiriminizin etkili olabilmesi için, yazılı ve aşağıdaki bilgileri içeriyor olmalıdır: (i) telif
            hakkı veya diğer fikri mülkiyet haklarının sahibi adına hareket etmeye yetkili kişinin bir elektronik imzası
            ya da ıslak imzası; (ii) telif hakkıyla korunan eserin veya ihlal edildiğini iddia ettiğiniz diğer fikri
            mülkiyet hakkın tasviri; (iii) ihlale konu içeriğin Hizmette nerede konumlandığı, İnternet sitesinde
            bulmamız için yeterli detaylarla birlikte açıklanması; (iv) GlobalEnglish'in sizinle iletişime geçebilmesi
            için gerekli bilgi (adres, telefon numarası, ve e-posta adresi); (v) uyuşmazlığa konu kullanımın telif hakkı
            veya fikri mülkiyet sahibi kişi, temsilcisi veya yasa tarafından yetkilendirilmediğine dair iyi niyetli bir
            inanca sahip olduğunuza dair bir beyan; (vi) Bildiriminizde yer alan yukarıdaki bilgilerin doğru olduğunu ve
            telif hakkı veya fikri mülkiyet sahibi olduğunuzu ya da telif hakkı veya fikri mülkiyet sahibi adına hareket
            etmeye yetkili olduğunuzu belirten yeminli beyanınız.
            <br />
            <br /> DMCA ve diğer geçerli yasalara uygun olarak, GlobalEnglish, uygun durumlarda ve GlobalEnglish’in
            kendi takdirine bağlı olarak, tekrarlayan ihlalci olarak kabul edilen kullanıcıları sonlandırma politikasını
            benimsemiştir. GlobalEnglish ayrıca, İnternet Sitesine erişimi sınırlandırabilir ve/veya başkalarının fikri
            mülkiyet haklarını ihlal eden Kullanıcıların üyeliğini, herhangi bir tekrar ihlali olup olmadığı
            farketmeksizin, sonlandırabilir.
          </span>
          <br />
          <br />
          <b>8.4İhlal Ettiğinde Şüphelenilen Kullanıcı İçeriğinin Kaldırılması </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Eğer GlobalEnglish üçüncü kişilerin fikri mülkiyet haklarını ihlal ettiğinden şüphelenilen Kullanıcı
            İçeriğinizin bir kısmını kaldırdıysa, ve siz kaldırılan (veya erişime engellenen) Kullanıcı İçeriğinizin bir
            ihlal teşkil etmediğini düşünüyor, veya içeriği Kullanıcı İçeriğiniz olarak yayınlamak ve kullanmak için
            telif hakkı sahibi, telif hakkı sahibinin temsilcisi, ya da yasa tarafından yetkilendirildiyseniz, Telif
            Hakkı Temsilcisine aşağıdaki bilgileri içeren bir geri bildirim gönderebilirsiniz: (i) elektronik veya ıslak
            imzanız; (ii) kaldırılmış veya erişime engellenmiş içeriğin tasviri ve kaldırılmış veya engellenmiş içeriğin
            kaldırılmadan veya engellenme öncesindeki konumu; (iii) içeriğin bir yanlışlık sonucu yada yanlış
            teşhis sonucu kaldırıldığına veya engellendiğine dair iyi niyetle bir inancınız olduğuna dair beyan;
            (iv) isminiz, adresiniz, telefon numaranız, e-posta adresiniz ve Kuzey Kaliforniya bölgesi federal
            mahkemesinin yargı yetkisine rıza gösterdiğinize dair bir beyan ve  iddia edilen ihlal bildirimi ile ilgili
            süreci kabul edeceğinize dair bir beyan.
            <br />
            <br /> Telif hakkı temsilcisi tarafından bir geri bildirim alınırsa, GlobalEnglish geri bildirimin bir
            kopyasını şikayette bulunulan asıl kişiye göndererek, kaldırılan içeriği 10 iş günü içerisinde
            değiştirebileceği veya engellemeyi durduracağı bilgisini verir. Telif hakkı sahibi, içerik sağlayıcısına,
            üyeye veya kullanıcıya karşı mahkeme kararı almak için yargı yoluna başvurmadıkça, 10 ila 14 iş günü
            içerisinde veya geri bildirimin tebliği akabinde daha uzun bir süre sonra, kendi takdirimiz dahilinde,
            kaldırılan içerik yeniden yerleştirilebilir, veya erişimi yeniden sağlanabilir.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            {' '}
            9. ULUSLARARASI KULLANIM VE DIŞA AKTARIM KONTROLÜ{' '}
          </b>
          <br />
          <br /> İnternetin evrensel yapısını kabul ederek, çevrimiçi davranış ve kabul edilir Kullanıcı İçeriği ile
          ilgili olan bütün yerel kurallara uymayı kabul edersiniz. ABD’den veya ikamet ettiğiniz ülkeden dışarı
          aktarılan teknik verilerin aktarımı ile ilgili tüm yasalara uymayı kabul edersiniz.
          <br />
          <br /> Hizmetlerin veya bir kısmının ABD’nin dışa aktarım kontrol yasalarına tabi olabileceğini kabul
          edesiniz. Hizmetin herhangi bir kısmını veya hizmetle ilgili herhangi bir teknik bilgi veya içeriği, ilgili
          aktarım kanunu veya düzenlemesine aykırı olarak doğrudan veya dolaylı olarak aktarmayacak, tekrar aktarım
          yapmayacak veya transfer edemeyeceksiniz. ABD ambargo uyguladığı veya ABD Hükümeti tarafından “terörü
          destekleyen” ülke olarak tanımlanan bir ülkenin vatandaşı olmadığınız veya böyle bir ülkede bulunmadığınızı ve
          ABD tarafından yasaklanmış veya kısıtlanmış kişiler listesinde yer almadığınızı beyan ve taahhüt ediyorsunuz.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. İHTİYATİ TEDBİR </b>
          <br />
          <br /> Hizmet'in, Kullanım Şartlarına aykırı herhangi bir kullanımının GlobalEnglish, bağlı kuruluşları,
          lisans verenleri, alt yüklenici, satıcıları ve Kullanıcıları için onarılamaz zararlara sebep olabileceğini
          kabul edersiniz. Bu şartlar altında, GlobalEnglish, bağlı kuruluşları, lisans verenleri, alt yüklenici,
          satıcıları ve Kullanıcıları, ihtiyati ve kati tedbirleri içeren ancak bunlarla sınırlı olmamak üzere, senet
          veya başka bir garanti belgesi vermeksizin adil tedbire hak kazanmış olur.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11. HİZMET AKSAMA SÜRESİ </b>
          <br />
          <br /> Hizmetlere, bakım veya başka nedenlerle zaman zaman geçici olarak erişilemiyor olabilir. GlobalEnglish,
          Kullanıcı iletişimlerinin veya İnternet sitesi üzerinden sağlanmış diğer herhangi bir içeriğin kesintiye
          uğraması, işletilmesinde veya iletilmesinde gecikme, çalınma veya imha, izinsiz erişim veya değişiklik
          nedeniyle hiçbir şekilde sorumlu değildir.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12. GARANTİNİN REDDİ </b>
          <br />
          <br /> Hizmetlere, bakım veya başka nedenlerle zaman zaman geçici olarak erişilemiyor olabilir. GlobalEnglish,
          Kullanıcı iletişimlerinin veya İnternet sitesi üzerinden sağlanan başka hiçbir içeriğin kesintiye uğraması,
          işletilmesinde veya iletilmesinde gecikmeler, çalınması veya imhası, yetkisiz erişimi veya değişikliğe
          uğraması nedeniyle hiçbir şekilde sorumlu tutulamaz. GlobalEnglish hiçbir durumda Hizmetlerin, herhangi bir
          Kullanıcı İçeriğinin veya Üçüncü Şahıs İçeriğinin kullanımından veya Kullanıcılar tarafından sağlanan ürün
          veya hizmetlerin her türlü kullanımından kaynaklanan hiçbir ölüm veya yaralanmadan hiçbir şekilde sorumlu
          tutulamaz.
          <br />
          <br />
          AŞAĞIDAKİLERİ ANLAR VE KABUL EDERSİNİZ Kİ:
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            {' '}
            (a) HİZMETLER VE KAPSADIĞI BÜTÜN İÇERİKLER, “OLDUĞU GİBİ” VE MEVCUT OLDUKÇA SAĞLANIR,
            <br />
            <br /> (b) GLOBALENGLISH, HİZMETLERE İLGİLİ, AÇIK VEYA ZIMNİ TÜM VE HER TÜRLÜ TEMİNAT VEYA BEYANI, ZIMNİ
            ÜNVAN, PAZARLANABİLİRLİK, BELİRLİ BİR AMACA UYGUNLUK VEYA İHLAL ETMEME TAAHHÜTLERİ DE DAHİL OLMAK ÜZERE
            REDDEDER.
            <br />
            <br /> (c) GLOBALENGLISH, HİZMETİN KULLANILMASIYLA BELİRLİ SONUÇLARIN ELE EDİLECEĞİ VEYA HİZMETLERİN
            GEREKSİNİMLERİNİZİ VEYA BEKLENTİLERİNİZİ KARŞILAYACAĞI TAAHHÜDÜNDE BULUNMAZ VEYA BU KONUDA BİR SÖZ VERMEZ.
            <br />
            <br />
            (d) GLOBALENGLISH, HİZMETLERDEKİ VEYA İNTERNET SİTESİNDEKİ YAZILIM VEYA İÇERİKLERİN DOĞRU, TAMAMLANMIŞ,
            GÜVENİLİR, GÜNCEL VEYA HATASIZ OLDUĞU YA DA İNTERNET SİTESİ VEYA HİZMETLER YA DA SUNUCULARIN VİRÜS VEYA
            BENZERİ ZARARLI BİLEŞENLERDEN ARİ OLDUĞUNU BEYAN VEYA GARANTİ ETMEZ. BU SEBEPLE
            <br />
            YUKARIDAKİ HÜKÜMLERİ SINIRLAMADAN, HİZMETLER ÜZERİNDEN VEYA KENDİ TAKDİRİNİZDEKİ VE RİSK SORUMLULUĞUNUZDAKİ
            SAĞLAYICILARDAN İÇERİK, VERİ VEYA YAZILIM İNDRİRKEN YA DA BAŞKA BİR YOLLA EDİNİRKEN, BU ŞEKİLDE KENDİ
            KULLANIMINIZDAN YALNIZCA KENDİNİZİN SORUMLU OLDUĞUNUZU VE BİLGİSAYAR SİSTEMİNİZDE BİR ZARARA, VERİLERİN
            KAYBOLMASINA VEYA BAŞKA HERHANGİ BİR TÜR ZARARA SEBEP OLABİLECEĞİNİ ANLAR VE KABUL EDERSİNİZ.
            <br />
            <br /> (e) GLOBALENGLISH, HİZMETLERDEKİ HATA VEYA KUSURUN DÜZELTİLECEĞİNİ TAAHHÜT ETMEZ.
            <br />
            <br />
            (f) GLOBALENGLISH, KULLANICI TUTUMLARINDAN, ÜÇÜNCÜ ŞAHIS İÇERİKLERİNDEN VEYA KULLANICI İÇERİKLERİNDEN
            SORUMLU DEĞİLDİR.
            <br />
            <br />
            (g) Hizmetlere, bakım veya başka nedenlerle zaman zaman geçici olarak erişilemiyor olabilir. GlobalEnglish,
            Kullanıcı iletişimlerinin veya İnternet sitesi üzerinden sağlanan başka hiçbir içeriğin kesintiye uğraması,
            işletilmesinde veya iletilmesinde gecikmeler, çalınması veya imhası, izinsiz erişimi veya değişikliğe
            uğraması nedeniyle hiçbir şekilde sorumlu tutulamaz. GlobalEnglish hiçbir durumda Hizmetlerin, herhangi bir
            Kullanıcı İçeriğinin veya Üçüncü Şahıs İçeriğinin kullanımından veya Kullanıcılar tarafından sağlanan ürün
            veya hizmetlerin her türlü kullanımından kaynaklanan hiçbir ölüm veya yaralanmadan sorumlu tutulamaz.
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. SORUMLULUĞUN SINIRLANDIRILMASI</b>
          <br />
          <br /> GLOBALENGLISH, YÖNETİCİLERİ, ÇALIŞANLARI, TEMSİLCİLERİ, LİSANS VERENLERİ VEYA ALT YÜKLENİCİLERİ HİÇ BİR
          DURUMDA: (I) İNTERNET SİTESİ, HİZMETLER VEYA SAĞLAYICILAR ÜZERİNDEN ERİŞİLEN YA DA İNDİRİLEN HİZMETLERİ VEYA
          HERHANGİ BİR İÇERİĞİ YA DA MALZEMEYİ KULLANIMINIZ VEYA KULLANAMAMANIZ, (II) İNTERNET SİTESİ ÜZERİNDEN YA DA
          DOĞRUDAN İNTERNET SİTESİNDEN SATIN ALINAN VEYA EDİNİLEN HERHANGİ MAL, VERİ, BİLGİ YA DA SERVİSLERDEN VEYA
          ALINAN MESAJLAR VEYA GERÇEKLEŞTİRİLEN İŞLEMLERDEN KAYNAKLANAN İKAME MAL VE HİZMETLERİN SATIN ALMA MALİYETİ;
          (III) HERHANGİ BİR ŞAHIS TARAFINDAN İLETİMLERİNİZE VEYA VERİLERİNİZE YETKİSİZ BİR ERİŞİM VEYA DEĞİŞTİRME; (IV)
          İNTERNET SİTESİNDE HERHANGİ BİR ÜÇÜNCÜ ŞAHISA AİT BEYAN VEYA DAVRANIŞLAR; VEYA (V) HİZMETLERLE İLGİLİ HERHANGİ
          DİĞER BİR HUSUS OLAN SİZE VEYA HERHANGİ BİR ÜÇÜNCÜ ŞAHISA KARŞI KARIN VEYA VERİNİN KAYBEDİLMESİ DE DAHİL OLMAK
          ÜZERE HERHANGİ BİR DOLAYLI, NETİCEYE DAYALI, ÖRNEK TEŞKİL EDEN, TESADÜFİ, ÖZEL VEYA CEZAİ ZARARDAN SORUMLU
          OLMAYACAKTIR. GLOBALENGLISH’İN SİZE TOPLAM SORUMLULUĞU, GLOBALENGLISH SÖZ KONUSU ZARAR OLASILIKLARINDAN
          HABERDAR OLSA VEYA UYARILSA BİLE, BÜTÜN ZARARLAR, KAYIPLAR VEYA DAVANIN SEBEBİ İÇİN, GLOBALENGLISH’E SON ON
          İKİ (12) AYDA ÖDEDİĞİNİZ TUTARDAN VEYA YÜZ DOLARDAN (100$) FAZLA OLAMAZ. BU KAPSAMDA BİRDEN FAZLA TALEBİN
          OLMASI BELİRTİLEN SINIRI YÜKSELTMEYECEKTİR.
          <br />
          <br /> BAZI YARGILAMA MERCİİLERİ BELİRLİ GARANTİLERİN HARİÇ TUTULMASINA VEYA TESADÜFİ YA DA NETİCEYE BAĞLI
          ZARARLARDAN SORUMLULUĞUN SINIRLANDDIRILMASI VEYA HARİÇ TUTULMASINA MÜSAADE ETMEZ. DOLAYISIYLA, YUKARIDA
          BELİRTİLEN SINIRLAMALARDAN BAZILARI SİZE UYGULANMAYABİLİR.
          <br />
          <br /> İNTERNET SİTESİNİN HERHANGİ BİR KISMINDAN VEYA İNTERNET SİTESİNİN BURADAKİ ŞARTLARINDAN MEMNUN
          OLMAMANIZ HALİNDE, SİZİN İÇİN TEK VE SİZE MÜNHASIR ÇÖZÜM İNTERNET SİTESİNİN KULLANIMINI DURDURMAKTIR.
          <br />
          <br /> GLOBALENGLISH HİÇBİR DURUMDA HİZMETLERİN, KULLANICI İÇERİĞİNİN VEYA ÜÇÜNCÜ ŞAHIS İÇERİĞİNİN
          KULLANIMINDAN KAYNAKLANAN ÖLÜM VEYA YARALANMALARDAN SORUMLU TUTULAMAZ.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14. ZAMANAŞIMI </b>
          <br />
          <br />
          Hizmetleri kullanımınızdan kaynaklanan veya kullanımınızla ilgili olarak GloabalEnglish aleyhine herhangi bir
          talep veya dava konusu, aksine herhangi bir yasa veya kanun dikkate alınmaksızın, ortaya çıkmasından itibaren1
          (bir) yıl içinde ilgili merciye bildirilerek başlatılmalıdır, aksi halde tamamen engellenir.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>15. TAZMİNAT</b>
          <br />
          <br />
          GlobalEnglish’i, bağlı kuruluşlarını ve bunların memurlarını, çalışanlarını, yöneticilerini ve temsilcilerini
          herhangi bir üçüncü tarafça GlobalEnglish'e karşı İnternet sitesini, Hizmetleri, Kullanıcı İçeriğinizi,
          İnternet Sitesi veya Hizmetlere olan bağlantınızı, bu Şartları ihlalinizi veya başka bir Kullanıcının veya
          herhangi bir yasaya ilişkin herhangi bir hak ihlalinizi Hizmetleri kullanımınız dolayısıyla ortaya çıkan makul
          vekalet ücreti dahil olmak üzere herhangi bir iddia, eylem, maliyet, borç, zarar, tazminat, masraflardan beri
          kılmayı ve sorumlu tutmamayı kabul edersiniz. Tazminat yükümlülükleriniz, herhangi bir Kullanıcı İçeriğinin
          bir üçüncü kişinin fikri mülkiyet haklarını ihlal etmesine ilişkin GlobalEnglish aleyhine yapılan talepleri
          hiçbir sınırlama olmaksızın içerir.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>16. FESİH </b>
          <br />
          <br /> Kaydınızın herhangi bir nedenle sonlandırılması, GlobalEnglish Hizmetlere erişiminizi engelleyecektir.
          Kullanıcı İçeriğiniz de dahil olmak üzere hiçbir İçeriğe erişemeyeceksiniz, ancak bu verilerin kopyaları
          yedekleme amacıyla üç (3) yıl süresince sistemimizde kalmaya devam edecek.
          <br />
          <br /> GlobalEnglish'in Kişisel Verilerinizi ve Kullanıcı İçeriğinizi silmesini talep ederseniz, bu talebi
          yerine getirmek için gereken makul çabayı göstereceğiz ve içerikleriniz silindiğinde teyit edeceğiz. .
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>17.FERAGAT VE BÖLÜNEBİLİRLİK </b>
          <br />
          <br /> GlobalEnglish'in buradaki Şartlardan herhangi bir hakkını veya hükmünü kullanmaması veya
          gerçekleştirmemesi, ilgili hak veya hükümden o anda veya başka bir anda feragat anlamına gelmez. Bu Şartların
          herhangi bir hükmünün kanuna aykırı, geçersiz veya herhangi bir sebeple uygulanamayacak olduğunun tespit
          edilmesi halinde, bu tespit, kalan hükümlerin hiçbirinin geçerliliğini ve uygulanabilirliğini etkilemez.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>18. SÖZLEŞMENİN BÜTÜNLÜĞÜ </b>
          <br />
          <br /> İşbu Sözleşme, Hizmetlerin kullanımıyla ilgili olarak GlobalEnglish ile aranızdaki anlaşmanın tamamını
          oluşturur ve Hizmetleri kullanımınızla ilgili olarak GlobalEnglish ile aranızdaki önceki anlaşmaların ve
          sözleşmelerin yerine geçer.
          <br />
          <br /> Yukarıda bahsedilenlere rağmen, Şirketinizin GlobalEnglish ile herhangi bir Hizmet alımı için mevcut
          bir Sözleşmesi varsa bu Şartlar, Şirketinizin GlobalEnglish, bağlı şirketleri ve alt yüklenicileri ile
          yapabileceği ilgili sözleşmenin şartlarını veya Şartlarını değiştirmeyi amaçlamamaktadır ve herhangi bir
          uyuşmazlık halinde de bahsi geçen Sözleşme hükümleri geçerli olacaktır.
          <br />
          <br /> Bu Sözleşme İngilizce dilinde hazırlanmıştır ve her bakımdan denetleyici olacak versiyonu budur ve bu
          Sözleşmenin İngilizce olmayan sürümleri yalnızca uyarlama amaçlıdır. İşbu sözleşmenin basılı bir versyonu ve
          elektronik ortamda verilen herhangi bir bildirimin, işbu sözleşmeye dayanarak veya bunlarla ilgili olarak adli
          veya idari işlemlerde kabul edilebilir ve orijinal olarak basılmış şekilde hazırlanmış ve muhafaza edilmiş
          diğer işletme belgeleri ve kayıtlarıyla aynı şartlara tabidir. Bu Şartlardaki bölüm başlıkları sadece kolaylık
          sağlamak için olup yasal veya sözleşmeyle ilgili hiçbir etkisi yoktur.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>19. HUKUK SEÇİMİ, FORUM VE TAHKİM </b>
          <br />
          <br />
          Bu Şartlar, kanun hükümlerindeki çatışmaya bakılmaksızın, Kaliforniya Eyaleti yasalarına tabidir.
          <br />
          <br /> GlobalEnglish’in tamamen kendi takdirine bağlı olarak yaptığı seçiminde, işbu Şartlardan veya İnternet
          Sitesi’nden kaynaklanan ve mutabakatla çözümlenemeyen tüm uyuşmazlıklar, iddialar veya ihtilaflar, JAMS’in
          veya halefinin önünde yapılacak bağlayıcı tahkimle çözümlenebilir. Taraflarca aksi kararlaştırılmadıkça,
          tahkim, taraflarca karşılıklı olarak kararlaştırılan tek bir hakem önünde San Francisco, Kaliforniya'da veya
          tarafların karşılıklı olarak anlaşamaması durumunda, JAMS tarafından atanan tek bir hakem tarafından, bu
          Şartlarda özellikle değiştirilmemişse, JAMS tarafından ilan edilen kurallar ve düzenlemelere tabi olarak
          yürütülecektir. Tahkim, taraflardan birince tahkim için yazılı talepte bulunulduğu tarihten itibaren kırkbeş
          (45) gün içinde başlamalıdır. Hakemin görüşü ve kararı, tahkimin sona ermesinden itibaren altmış (60) gün
          içinde ve hakemin seçilmesinden itibaren altı (6) ay içinde verilecek ve teslim edilecektir. Hakem, fiili
          telafi edici sınırlamaları aşan zararları, Şartlarda belirtilen doğrudan zararları verme yetkisine sahip
          olmayacak ve fiili zararları çarpmayacak veya cezai zararları veya Şartlar altında özel olarak hariç tutulan
          diğer zararları ödeyemeyecektir, bu nedenle her bir taraf gayri kabili rücu, bu tür zararlara ilişkin
          taleplerden feragat eder. Hakem, kendi takdir yetkisi dahilinde, yargılama için taraflardan birine karşı
          ücretleri ve masrafları (makul yasal giderleri ve geçerli kısmın giderlerini içeren) değerlendirebilir.
          Hakemlerin emrine uymayı reddeden herhangi bir taraf, karar yürürlüğe konurken diğer tarafın yaptığı avukat
          masrafları da dahil olmak üzere ücretler ve masrafların karşılanmasından sorumlu olacaktır. Yukarıda
          belirtilenlere rağmen, geçici veya ihtiyati tedbir kararı verilmesi halinde, herhangi bir taraf derhal ve
          onarılamayacak zararlardan kaçınmak amacıyla tahkim olmadan mahkemeye gidebilir. İşbu tahkim bölümünün
          hükümleri herhangi bir yetkili mahkemede uygulanacaktır.
          <br />
          <br /> Yukarıda da belirtildiği gibi, tahkime konu olmayan herhangi bir ihtilaf veya iddiaya ilişkin olarak,
          siz ve GlobalEnglish, San Francisco, California'da bulunan eyalet ve federal mahkemelerin kişisel ve münhasır
          yargı yetkisine başvurmayı kabul edersiniz.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. BİLDİRİM VE İRTİBAT </b>
          <br />
          <br />
          GlobalEnglish hizmetinize,
          <a href="https://support.globalenglish.com/" rel="noopener noreferrer" target="_blank">
            https://support.globalenglish.com/
          </a>{' '}
          adresindeki “Bize Ulaşın” formundaki bağlantı linki aracılığıyla fesih bildiriminde bulunarak GlobalEnglish
          Hizmet hesabınızı sonlandırmayı isteyebilirsiniz. Tüm içerikler dahil olmak üzere Hizmetlere erişiminiz ve bu
          Hizmetleri kullanımınız, hesabınızı sonlandırmanızdan sonra GlobalEnglish tarafından herhangi bir zamanda
          engellenebilir.
          <br />
          <br /> Bildirimler, size e-posta veya normal posta yoluyla yapılabilir. İnternet sitesi ayrıca, genel olarak
          Hizmet üzerindeki bildirimlere veya link bağlantılarını göstererek bu Şartlarda veya diğer konulardaki
          değişiklikleri size bildirebilir.
          <br />
          <br /> Gizlilik politikamız ile ilgili bildirimler
          <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
            privacy@globalenglish.com
          </a>
          adresine gönderilebilir.
          <br />
          <br /> Diğer tüm yasal bildirimler veya GlobalEnglish'e yapılan diğer yazışmalar aşağıdaki elektronik posta
          adresine ve fiziksel adrese gönderilmelidir:
          <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
            legal@globalenglish.com
          </a>
          <br />
          <br /> 1875 S Grant Street, Suite 700
          <br /> San Mateo, CA 94402
          <br />
          <b>Hukuk Danışmanı</b> dikkatine
          <br />
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>İŞLETMELER ARASI (B2B) İŞLEMLER İÇİN GEÇERLİ ÖZEL ŞARTLAR</b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          {' '}
          GlobalEnglish hesabınızı işvereniniz aracılığıyla aldıysanız, Hizmetlerimizi kullanımınız şirketinizle
          (“Şirket”) yaptığımız sözleşmeye de tabidir. Kullanım Şartları, Şirketinizle yapılan sözleşme şartlarını
          değiştirmeyi amaçlamaz. Şirketiniz sizi GlobalEnglish yetkilendirmesi konusunda son kullanıcı olarak
          yetkilendirmezse Hizmetleri kullanamazsınız (veya kullanımı sürdüremezsiniz). Hesabınızı sonlandırmamızı talep
          ederseniz, GlobalEnglish’in hesabınızı sonlandırmasından önce Şirketinizin buna izin vermesi gerekebilir.
          <br />
          <br /> Hizmetleri yalnızca Şirketinizce izin verilen şekilde kullanmayı kabul edersiniz. Hizmetler
          aracılığıyla Şirketinizdeki diğer kişilerle iletişim kurma veya Şirketinizle ilgili belgeleri yükleme
          olanağınız olabilir ancak Şirketinizin yetkilendirmesi olmadan bunları yapmaya yetkiniz yoktur ve
          yapmamalısınız.
          <br />
          <br /> Hizmetleri kullanmanızla bağlantılı olarak oluşturulan tüm verilerin (“Kullanıcı Verileri”) Şirketiniz
          tarafından erişilebilir olabileceğini ve bunun sonucunda GlobalEnglish’in, Hizmetlerimizi kullanımınız
          sonucunda Şirketinizle paylaşılan bilgilere dayanarak size karşı hiçbir yükümlülüğü olmayacağını anlar ve
          kabul edersiniz.
          <br />
          <br /> Hizmetlere erişim için yapılan kaydınızın yalnızca bu Şirketin yetkisi süresince devam etmesi
          sebebiyle, Şirketiniz GlobalEnglish ile yetkilendirmesini sonlandırılması durumunda, Hizmetlere ve Kullanıcı
          İçeriğine erişiminizi kaybedersiniz. GlobalEnglish, (i) Şirketiniz, GlobalEnglish’e ödenmesi gereken herhangi
          bir ücret ödemesini ödeme tarihinde yapmazsa, veya (ii) siz veya Şirketinizin Şartları ihlal ettiğinizde veya
          Hizmetlerin kullanımına ilişkin diğer kuralları ihlal ettiğinizde Şirketinizin hesabını, sizin hesabınızı veya
          Hizmetleri kullanma yetkinizi, herhangi bir bildirimde bulunmadan tamamen veya kısmen feshedebilir veya askıya
          alabilir.
          <br />
          <br /> GlobalEnglish, Kişisel Verileriniz ve Kullanıcı İçeriğiniz de dahil olmak üzere Hizmetleri
          kullanımınızla ilgili tüm verileri şirketinize iade etme yükümlülüğü altında olabilir. Şirketiniz bu talepte
          bulunmazsa, bu veriler şirketinize iade edilir veya silinir, GlobalEnglish verileri işvereninizle
          sözleşmesinin sona erdiği veya feshedildiği tarihi izleyen üç (3) yıllık süreyle saklayacaktır. Bu tür
          durumlarda, doğrudan GlobalEnglish'den hizmete kaydınızı satın alabilir ve bir bilgi kaybı olmadan programa
          erişiminize devam edebilirsiniz. İlerleme ve profil bilgilerinizin yeni işvereninizle yeni bir kayda
          aktarılması için yetki verebilirsiniz. Hizmetleri kullanımınızla ilgili tüm verileri gelecekteki veri alımı
          ile uyumlu bir formatta da isteyebilirsiniz.
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>İŞLETMEDEN TÜKETİCİYE (B2C) İŞLEMLER İÇİN GEÇERLİ ÖZEL ŞARTLAR </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          Ayrıca, GlobalEnglish ile birey olarak üç (3) aylık veya altı (6) aylık bir kayıt veya yıllık ya da çok yıllık
          bir kayıt planından (her biri, “Kayıt Planı”) oluşabilecek bir kayıt sözleşmesi yapabilirsiniz.
          <br />
          <br /> Kayıt Planınızı iptal ettiğiniz tarihe kadar, her ay/yıl aynı gün (geçerli olduğu şekilde) veya geçerli
          Kayıt Planı için aylık veya yıllık olarak faturalandırılırsınız. Her hangi bir şüpheye yer vermemek için,
          unutmayınız ki Kayıt Planınızın yıldönümüne kadar iptal etmenize, yer sayısını azaltmanıza veya seçtiğiniz
          Hizmet seviyesini düşürmenize izin verilmeyecektir. Kayıt Planı ödemelerinin iadesi yapılmayacaktır.
          <br />
          <br /> Hizmet için geçerli olan ücretler ("Ücretler") İnternet sitesinde ve/veya GlobalEnglish’in ilgili
          tarihte güncel yayınlanan fiyat listesinde bulunmaktadır. Hizmet için belirtilen fiyat, aksi belirtilmedikçe
          tüm vergi ve harçlardan aridir. Herhangi bir vergi ve Hizmetleri kullanmaya bağlı tüm diğer masraflardan siz
          sorumlusunuz(örneğin, veri ücretleri ve döviz bozdurma işlemleri). Ücretleri, hesabınız için GlobalEnglish’in
          belirttiği para birimi cinsinde ödeyeceksiniz. GlobalEnglish, belirtilen bu para birimini istediği zaman
          değiştirme hakkını saklı tutar.
          <br />
          <br />
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}> Ödeme </b>
          <br />
          <br /> Ödeme zamanı, kayıt için satın alımın yapılmasıyla gelmiş olur. Ödemelerinizi işleme almak için PayPal
          ve Stripe ile iş ortaklığı yapmaktayız. Bu Hizmet sağlayıcılara sağladığınız hiçbir kredi kartı bilgisini veya
          diğer ödeme yöntemlerini toplamıyor veya işlemiyoruz.
          <br />
          <br /> Tüm Hizmetler, seçilen dönem için (aylık, yıllık veya başka şekilde) iade edilemez şekilde ön ödemeli
          olarak ödenmiştir. Bu husus, yenilenen hesapları içerir.
          <br />
          <br /> -----
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>Kaliforniya Kullanıcılarına Bildirim</b>
          <br />
          <br />
          Eğer Kaliforniya’da ikamet ediyorsanız, Kaliforniya Medeni Kanunu’nun 1789.3, Bölümü şahısların doğrudan
          pazarlama amaçları için, Kişisel Verilerinizin üçüncü şahıslara açıklanması hakkında bilgi istemenize izin
          verir. 2021'de yürürlüğe girecek olan Kaliforniya Tüketici Gizliliği Yasası (Medeni Kanun, Bölüm 1798.100 -
          1709.198) size ayrıca belirli haklar vermektedir:
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            (a) hakkınızda hangi  Kişisel Verilerin toplandığını bilme ve kopya alma hakkı,
            <br />
            <br />
            (b)  silme hakkı,
            <br />
            <br />
            (c) Kişisel Verilerinizin satılacağı üçüncü şahısları bilme ve bu satışın sona ermesini isteme hakkı,
            <br />
            <br />
            (d) bazı tüketici haklarınızı kullanmanız durumunda ayrımcılığa maruz kalmama hakkı.
          </span>
          <br /> İlaveten, Kaliforniya Ticaret ve Meslek Kanunu 22581 Bölümü bugün, küçük olan Kaliforniya sakininin,
          İnternet Sitesinde halka açık şekilde yayınladığı içeriğin, kaldırılmasını talep etmesine izin vermektedir.
          Bununla birlikte, GlobalEnglish'in gönderdiğiniz içeriğin tamamen veya kapsamlı bir şekilde kaldırılmasını
          sağlayamadığını ve talep edilse bile, yasanın kaldırmaya gerek görmediği veya izin vermediği Şartlar
          olabileceğini unutmayınız.
          <br />
          <br />
          Haklarınızı kullanmak için bir talepte bulunmak isterseniz:
          <br />
          <br /> İnternet sitesi ve ilgili hizmetlerin sağlayıcısı, 1875 South Grant Street, Suite 700, San Mateo, CA
          94402, ABD adresinde bulunan GlobalEnglish Corporation'dur. E-posta adresi: privacy@globalenglish.com.
          <br /> Bu Şartların yazıldığı tarih itibariyle, İnternet Sitesi tarafından tüketiciden doğrudan hiçbir ücret
          alınmamaktadır. İnternet Sitesinde sağlanan hizmetlerle ilgili herhangi bir şikayetiniz varsa veya daha fazla
          bilgi istemek istiyorsanız, lütfen isteğinizi açıklayan bir yazıyla birlikte yukarıdaki fiziksel adresten
          bizimle iletişime geçin. Belirli bir sure içinde yanıtı vermeyi garanti edemeyiz. Memnuniyetsizliğiniz
          sürerse, yazı ile 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834’den telefonla ise (916) 445-1254
          veya (800) 952-5210 numaralarından iletişim kurabileceğiniz Kaliforniya Tüketici İşleri Departmanı Tüketici
          Hizmetleri Bölümü Şikayet Destek Birimi ile iletişime geçin.
        </p>
      </div>
    </div>
  );
}

export default TrTrContent;
