// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./index.css"
import { ButtonField } from "../ButtonField";

export const MaintenancePage = (props) => {    
const [ist,setIst] = useState({start:null,end:null})
const [cet,setCet] = useState({start:null,end:null})
const [pst,setPst] = useState({start:null,end:null})
const addHours=(t,h)=>{
    return new Date(t).setHours(new Date(t).getHours()+h)
    }
useEffect(()=>{
let time=process.env.REACT_APP_MAINTENANCE_WINDOW_LASTDATE
let h=Number(process.env.REACT_APP_MAINTENANCE_WINDOW_HOURS)
setIst({
    start:new Date(time),
    end : new Date(addHours(new Date(time),h)),
    month : new Date(time).toLocaleString('default', { month: 'long' })
})
let c=addHours(new Date(new Date(time).toLocaleString("en-US", {
    timeZone: "Europe/Berlin"
  })),0)
setCet({
    start:new Date(c),
    end : new Date(addHours(c,h)),
    month : new Date(c).toLocaleString('default', { month: 'long' })
})
setPst({
    start:new Date(new Date(time).toLocaleString("en-US", {
        timeZone: "America/Los_Angeles"
      })),
    end : new Date(addHours(new Date(time).toLocaleString("en-US", {
        timeZone: "America/Los_Angeles"
      }),h)),
    month : new Date(new Date(time).toLocaleString("en-US", {
        timeZone: "America/Los_Angeles"
      })).toLocaleString('default', { month: 'long' })
})
console.log(new Date(new Date(time).toLocaleString("en-US", {
    timeZone: "Europe/Berlin"
  })))
},[])

    return(
        <>
        <div className="bg-white center">
        <div>
        <div className="grid-container">
                
                <div className="Close" onClick={props.closeWindow}></div>
            </div>
            
            <div className="grid-container">
            <div >
            <h1 className="heading">Maintenance Notice  </h1>
            <div className="brokenImage"></div>              
            </div>
            <div className="message">
            Our system will undergo maintenance between the following times:
            <br></br>
            <br></br>
            

<span><b>{cet.start?.getDate()}/{cet.month}/{cet.start?.getFullYear()}</b> - {cet.start?.toLocaleTimeString('en-US',{ hour: "2-digit", minute: "2-digit" })} to {cet.end?.toLocaleTimeString('en-US',{ hour: "2-digit", minute: "2-digit" })} CET</span>
<br></br>
<span><b>{ist.start?.getDate()}/{ist.month}/{ist.start?.getFullYear()}</b> - {ist.start?.toLocaleTimeString('en-US',{ hour: "2-digit", minute: "2-digit" })} to {ist.end?.toLocaleTimeString('en-US',{ hour: "2-digit", minute: "2-digit" })} India</span>
<br></br>

<span><b>{pst.start?.getDate()}/{pst.month}/{pst.start?.getFullYear()}</b> - {pst.start?.toLocaleTimeString('en-US',{ hour: "2-digit", minute: "2-digit" })} to {pst.end?.toLocaleTimeString('en-US',{ hour: "2-digit", minute: "2-digit" })} PST</span>
<br></br>
<br></br>
<br></br>

<span style={{marginBottom:"30px"}}>During this time, platform access will be unavailable.</span>
<br></br>
<br></br>
 

If your program includes sessions, please ensure you reschedule any sessions planned within this period.

<br></br>
<br></br> 

<span style={{marginBottom:"30px"}}>We apologize for the inconvenience.
Thank you for your understanding and patience.</span>
<br></br>
<br></br>




<b>Your Learnship Team.</b>
<br></br>
<br></br>

            <div style={{
              
            }}>
            <ButtonField
              value={"Got it"}
              id="btnClose"
              type="submit"
              name="yt0"
              onClickFn={props.closeWindow}
              //isLoading={props.error}
            />
            </div>
            </div>
                </div> 
        </div>  
                                
           
        </div>
        
        </>
    )
}