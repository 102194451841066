// @ts-nocheck
import React, { Component } from "react";
import { ForgotForm } from "components/ForgotForm";

class ForgotContainer extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      error: {},
      result: {},
      isEmailSent: "",
      loading: false,
      errorCount: 0,
    };

    this.submit = this.submit.bind(this);
    this.resetIsEmailSent = this.resetIsEmailSent.bind(this);
  }

  resetIsEmailSent() {
    this.setState({ isEmailSent: "" });
  }

  submit(email: string) {
    this.setState({ loading: true });
    if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      fetch(
        `${process.env.REACT_APP_API_GATEWAY_URL}/password?email=${email}`,
      ).then(
        (result: any) => {
          if (result.ok) {
            result.json().then(({ message }) => {
              if (message === "PASSWORDSENT") {
                this.setState({
                  isEmailSent: "SUCCESS",
                  loading: false,
                  errorCount: 0,
                });
              } else if (message === "CONTACTADMIN_FORGOTEMAIL") {
                this.setState({
                  isEmailSent: "MULTIPLE_USER",
                  loading: false,
                  errorCount: 0
                })
              } else {
                console.warn("Forgot Password fails:", message);
                this.setState({
                  isEmailSent: "FAIL",
                  loading: false,
                  errorCount: this.state.errorCount + 1,
                });
              }
            });
          }
        },
        (error) => {
          console.error("Forgot Password fails:", error);
          this.setState({
            isEmailSent: "ERROR",
            loading: false,
            errorCount: this.state.errorCount + 1,
          });
        },
      );
    } else {
      this.setState({ isEmailSent: "INVALID", loading: false });
    }
  }

  render() {
    return (
      <ForgotForm
        result={this.state.result}
        error={this.state.error}
        submit={this.submit}
        isEmailSent={this.state.isEmailSent}
        forgotPassword={this.props.forgotPassword}
        resetRequired={this.props.resetRequired}
        loading={this.state.loading}
        errorCount={this.state.errorCount}
        resetIsEmailSent={this.resetIsEmailSent}
      />
    );
  }
}

export default ForgotContainer;
