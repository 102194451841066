// @ts-nocheck
import React from 'react';

function EsEsContent() {
  return (
    <div id="agreement_container">
      <div style={{ padding: '1% 5% 0 5%' }}>
        <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
          <b>ACUERDO DE USUARIO</b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
          <b>INTRODUCCIÓN </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          Bienvenido a los servicios de GlobalEnglish, ya sea sitio web o aplicaciones móviles (denominados
          colectivamente "Servicios”).
          <br />
          <br /> Nuestros Servicios son herramientas de aprendizaje en inglés y soluciones en línea, incluyendo, sin
          limitación, datos, texto, pruebas en línea, diseños, vídeos, clips de audio, grabación de voz, mensajes
          escritos y observaciones, guiones, gráficos, imágenes y su selección y disposición, software, tutoriales,
          clases, coaching, capacitación, comunicaciones y características interactivas generadas o hechas accesibles a
          través del sitio web y aplicaciones móviles (colectivamente, el “Contenido"). El acceso a nuestro sitio web
          para leer acerca de nuestra empresa y preguntar acerca de los Servicios se considera parte de los Servicios.
          <br />
          <br /> Lea atentamente lo siguiente y reconozca que lo ha comprendido y está de acuerdo con los siguientes
          términos antes de continuar.
          <br />
          <br />
          <b>
            SI ES MENOR DE 18 AÑOS, ASEGÚRESE DE LEER ESTE ACUERDO CON SU PADRE/MADRE O TUTOR LEGAL Y OBTENER SU
            CONSENTIMIENTO ANTES DE USAR NUESTROS SERVICIOS. AL USAR NUESTROS SERVICIOS, DECLARA QUE TIENE MÁS DE 18
            AÑOS DE EDAD O HA OBTENIDO DICHO CONSENTIMIENTO DE SU PADRE/MADRE O TUTOR LEGAL Y SE LE PERMITE CELEBRAR EL
            PRESENTE ACUERDO CON GLOBALENGLISH.
          </b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
          <b>CONDICIONES GENERALES </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 1. ACUERDO LEGALMENTE VINCULANTE </b>
          <br />
          <br />
          <b>
            {' '}
            SU ACCESO A NUESTRO SITIO WEB Y EL USO DE LOS SERVICIOS DE GLOBALENGLISH ESTÁN SUJETOS AL ACUERDO DE USUARIO
            A CONTINUACIÓN, LA POLÍTICA DE PRIVACIDAD Y LA POLÍTICA DE COOKIES (LOS TRES DOCUMENTOS MENCIONADOS SE
            DENOMINARÁN COLECTIVAMENTE LAS “CONDICIONES DE USO”). AL ACCEDER, VISITAR, REGISTRAR Y /O UTILIZAR LOS
            SERVICIOS DE GLOBALENGLISH, DECLARA QUE HA LEÍDO Y ENTENDIDO LAS CONDICIONES DE USO DE NUESTROS SERVICIOS Y
            ACEPTA ESTAR LEGAL E IRREVOCABLEMENTE VINCULADOS POR ELLOS Y SER MAYOR DE EDAD PARA ESTAR VINCULADO POR
            ESTAS CONDICIONES Y CELEBRAR UN ACUERDO LEGALMENTE VINCULANTE CON GLOBALENGLISH.
          </b>
          <br />
          <br /> Puede encontrar una resumen general de nuestras políticas
          <a
            href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
            rel="noopener noreferrer"
            target="_blank"
          >
            aquí
          </a>
          . Estas políticas se incorporan como referencia en el presente “Acuerdo de Usuario” o “Acuerdo” y en conjunto,
          constituyen las condiciones de uso.
          <br />
          <br />
          <b>
            Tenga en cuenta que el presente Acuerdo contiene disposiciones que rigen cómo se resuelven las demandas que
            puedan surgir entre usted y nosotros. Véanse en particular las disposiciones sobre Exención de garantías,
            Limitación de responsabilidad y Disputas legales que figuran a continuación. También contiene un acuerdo de
            arbitraje que, con excepciones limitadas, requiere que interponga las demandas que tiene contra nosotros
            para que se sometan a un arbitraje vinculante y final. Solo se le permitirá presentar demandas de manera
            individual, no como demandante o demandante colectivo en ninguna demanda colectiva o representativa. Solo se
            le permitirá pretender resarcimiento (incluyendo resarcimiento monetario, cautelar y declarativo) de manera
            individual.
            <br />
            <br /> NO UTILICE LOS SERVICIOS NI SIGA NAVEGANDO POR NUESTRO SITIO WEB SI EN CUALQUIER MOMENTO NO ESTÁ DE
            ACUERDO CON NINGUNA PARTE DE LOS TÉRMINOS DEL PRESENTE DOCUMENTO.
          </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. APLICABILIDAD </b>
          <br />
          <br /> El presente Acuerdo se aplica tanto a los Usuarios de nuestros servicios como a los Visitantes en
          nuestros sitios. Las personas físicas que se registraron con nosotros directamente (una
          <b>“Transacción B2C”</b>), incluso de manera experimental, y las que están registradas por o a través de sus
          empleadores (una
          <b>"Transacción B2B"</b>) se refieren como
          <b>"Usuarios"</b>, mientras que los usuarios y los visitantes no registrados que utilizan nuestros sitios web
          son
          <b>"Visitantes"</b>. El término
          <b>“Usuarios”</b>
          también incluye maestros, entrenadores, especialistas en aprendizaje y cualquier otra persona que acceda a
          sitios de GlobalEnglish para brindar servicios a los otros Usuarios. Se referirá a tanto los Usuarios como a
          los Visitantes como
          <b>“Usted”</b>.
          <br />
          <br /> El presente acuerdo se aplica a todos los servicios de GlobalEnglish.
          <br />
          <br /> Cuando se registre por primera vez como Usuario y cuando acceda a nuestros Servicios, puede que le
          pidamos que reitere su acuerdo a estar vinculado por los términos del presente documento haciendo clic en la
          casilla “Acepto” o proporcionando su firma electrónica o cualquier otro medio de autentificación.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>3.CAMBIOS EN LAS CONDICIONES DE USO </b>
          <br />
          <br />
          GlobalEnglish podrá modificar las Condiciones de Uso en cualquier momento, incluyendo el presente Acuerdo, la
          Política de Cookies y la Política de Privacidad aplicable a su uso de los Servicios. Dichas modificaciones
          entrarán en vigor inmediatamente después de la notificación, la cual se dará mediante el envío, dentro del
          Servicio, de un mensaje electrónico a su dirección de correo electrónico que haya proporcionado en relación
          con su cuenta de Servicios (si corresponde) u otros medios. Si se registró para nuestros Servicios a través de
          su superior o una empresa, su superior o empresa podrá notificarle los cambios en nuestras Condiciones de Uso.
          <br />
          <br /> Si se opone a dichos cambios, puede cerrar su cuenta y dejar de entrar en nuestro sitio web. Sin
          embargo, su uso continuado de nuestros Servicios después de publicar o enviar una notificación acerca de los
          cambios de los presentes términos se considerará que constituye su aceptación de dichas modificaciones a las
          Condiciones de Uso.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4. CONDICIONES DE USO DE TERCEROS </b>
          <br />
          <br />
          Los Servicios pueden contener vínculos a otros sitios web (“Sitios de Terceros") así como artículos,
          fotografías, texto, traducciones, gráficos, imágenes, diseños, sonido, vídeo, información y otros contenidos o
          artículos pertenecientes o procedentes de terceros (el "Contenido de Terceros"). Dichos Sitios de Terceros y
          Contenido de Terceros no son comprobados, monitoreados o verificados por nosotros en cuanto a su exactitud,
          idoneidad o integridad, y no somos responsables de ningún Sitio de Terceros al que se acceda a través del
          Sitio Web o de cualquier Contenido de Terceros publicado o disponible a través del sitio web.
          <br />
          <br /> La inclusión, vinculación o autorización del uso de un Sitio de Terceros o de cualquier Contenido de
          Terceros no implica la aprobación del mismo por GlobalEnglish. GlobalEnglish se reserva el derecho de poner
          fin a estos vínculos en cualquier momento sin previo aviso. Las referencias a los Servicios en cualquier
          empresa, organización, producto o servicio, u otra información no constituyen o implican el respaldo,
          patrocinio o recomendación de los mismos, o cualquier afiliación a los mismos, por parte de GlobalEnglish.
          <br />
          <br /> Si decide salir de nuestro sitio web y acceder a los Sitios de Terceros o acceder o usar cualquier
          Contenido de Terceros, lo hace bajo su propio riesgo y debe ser consciente de que nuestros términos y
          políticas ya no rigen su uso de dichos Sitios de Terceros. Asegúrese de revisar los términos y políticas
          aplicables a dichos otros productos y servicios de terceros antes de su uso.
          <br />
          <br /> Ofrecemos aplicaciones móviles tanto a través de Android Market como de iTunes Store (colectivamente y
          con las entidades que las mantienen, “Proveedores"). Su uso de estas aplicaciones móviles también está sujeto
          a los términos y condiciones de los Proveedores, incluidas, sin limitación, las Reglas de Uso establecidas en
          las Condiciones de Servicio de la App Store. Si ha descargado cualquiera de estas aplicaciones móviles de
          iTunes Store, reconoce y acepta que ha leído y acepta los Términos de Servicio de la App Store. Si los ha
          descargado de Android Market, reconoce y acepta que ha leído y acepta las Condiciones de Servicio de Android
          Market. Los Proveedores no tienen ninguna obligación y no le proporcionarán ningún servicio de mantenimiento o
          soporte en relación con su uso de los Servicios. Los Proveedores no ofrecen ninguna garantía de ningún tipo
          para los Servicios. En caso de que alguna de las aplicaciones móviles no cumpla con cualquier garantía
          aplicable y haya descargado dicha aplicación de la iTunes Store, puede notificar a Apple y Apple le
          reembolsará el precio de compra (si lo hubiera) de dicha aplicación. Estas Condiciones se aplican a su uso de
          todas las aplicaciones para móviles, así como de nuestros otros Servicios.
          <br />
          <br /> Usted reconoce y acepta que GlobalEnglish no es responsable por el contenido, los productos, servicios
          o rendimiento de dichos sitios web o aplicaciones de terceros. Renuncia irrevocablemente a cualquier
          reclamación contra GlobalEnglish en relación con sitios web o aplicaciones de terceros. En particular,
          GLOBALENGLISH NO SE HACE RESPONSABLE DE LAS COOKIES, BALIZAS WEB, GIFS TRANSPARENTES U OTRO CÓDIGO QUE USTED U
          OTRAS PARTES COLOQUEN EN SU ORDENADOR DE MANERA ALGUNA, ESTO INCLUYE CUALQUIER ASOCIACIÓN DE CONTENIDO DEL
          SITIO WEB CON DICHO CÓDIGO. Por ejemplo, si accede a un Sitio de Terceros a través de un vínculo en el Sitio
          Web, su navegador u otras cookies de su ordenador pueden hacer que se envíe un encabezado de referencia a ese
          sitio indicando parte de su historial de navegación o la URL de la que se originó su visita. GlobalEnglish no
          tendrá ninguna responsabilidad frente a usted en relación con dicha transmisión, ya sea que dicha transmisión
          contenga o no información asociada u originaria de GlobalEnglish.
          <br />
          <br /> SI USA TRADUCCIONES DE GOOGLE: GOOGLE RENUNCIA A TODAS LAS GARANTÍAS RELACIONADAS CON LAS TRADUCCIONES,
          EXPRESAS O IMPLÍCITAS, INCLUYENDO CUALQUIER GARANTÍA DE PRECISIÓN, CONFIABILIDAD Y CUALQUIER GARANTÍA
          IMPLÍCITA DE COMERCIABILIDAD, DE IDONEIDAD PARA UN PROPÓSITO PARTICULAR Y DE NO INFRACCIÓN.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>5. CRITERIOS DE ELEGIBILIDAD DEL SERVICIO </b>
          <br />
          <br />
          <b>
            5.1 Protección de la privacidad de los menores de edad y restricciones sobre el uso de nuestros Servicios.
          </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Los Servicios no son para el uso de personas por debajo de la Edad Mínima en el país desde el cual acceden a
            los Servicios. Por ejemplo, “Edad Mínima" en la Unión Europea, el Espacio Económico Europeo y Suiza
            significa 16 años de edad, a menos que se establezca lo contrario en las leyes nacionales específicas. En
            los Estados Unidos, la edad mínima es de 13 años. Cualquier registro, uso o acceso a los Servicios por parte
            de cualquier persona menor de la Edad Mínima está prohibido y constituye una violación de estas Condiciones.
            <br />
            <br /> Al hacer uso de los Servicios, Usted declara y garantiza tener 13 años o ser mayor de 13 años.
          </span>
          <br />
          <br />
          <b>5.2 Colegios y Profesores </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Los colegios, distritos o profesores de Estados Unidos que quieren que sus estudiantes menores de 13 años
            creen cuentas de GlobalEnglish están de acuerdo en que son responsables de cumplir con la Children's Online
            Privacy Protection Act (Ley de Protección de la Privacidad en Línea de los Niños) de los Estados Unidos
            ("COPPA") y, en la medida en que sea aplicable, la Family Educational Rights and Privacy Act (Ley de
            Derechos Educativos y Privacidad de la Familia) ("FERPA"). Esto significa que los colegios, distritos o
            profesores deben notificar a los padres o tutores de los estudiantes la información de identificación
            personal que GlobalEnglish recopilará, y obtener el consentimiento de los padres/tutores antes de que los
            estudiantes creen cuentas y usen los Servicios. Al obtener dicho consentimiento, los colegios, distritos y
            profesores deben proporcionar a los padres/tutores una copia de nuestra Política de Privacidad y mantener
            todos los consentimientos en un archivo y proporcionarnoslos si los solicitáramos. Para obtener más
            información sobre el cumplimiento de COPPA, consulte
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
              rel="noopener noreferrer"
              target="_blank"
            >
              el sitio web de la Comisión Federal de Comercio.{' '}
            </a>
            <br />
            <br /> Si está ubicado fuera de los Estados Unidos, confiaremos en que obtenga cualquier consentimiento o
            aprobación requerida del padre o tutor de cualquier estudiante cubierto por leyes similares y, como
            condición para el uso de nuestros Servicios por su parte y por la de sus estudiantes, acepta que será
            responsable de cumplir con dichas leyes.
          </span>
          <br />
          <br />
          <b>5.3 Consentimiento de los Padres </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            <b>
              AL USAR NUESTROS SERVICIOS, DECLARA QUE TIENE MÁS DE 18 AÑOS DE EDAD O HA OBTENIDO DICHO CONSENTIMIENTO DE
              SU PADRE/MADRE O TUTOR LEGAL Y SE LE PERMITE CELEBRAR EL PRESENTE ACUERDO CON GLOBALENGLISH.
              <br />
              <br />
              Esperamos que cualquier uso por parte de niños sólo se haga con la orientación, supervisión y
              consentimiento de sus padres, tutores y/o funcionarios escolares autorizados. GlobalEnglish confía en los
              padres, tutores y colegios para garantizar que los menores solo usen los Servicios si entienden sus
              derechos y responsabilidades según lo establecido en estas Condiciones de Uso.
              <br />
              <br /> GlobalEnglish puede, a su entera discreción, negarse a ofrecer los Servicios a cualquier persona o
              entidad y cambiar sus criterios de elegibilidad en cualquier momento.
            </b>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6.PRIVACIDAD </b>
          <br />
          <br />
          <b>6.1 Declaración de privacidad </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Revise la Política de Privacidad disponible
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              aquí
            </a>
            .
            <br />
            <br /> Trataremos los datos personales de acuerdo con la política de privacidad que usted haya consentido en
            el momento en que accedió por primera vez al servicio o como parte de una actualización programada de la
            política de privacidad.
          </span>
          <br />
          <br />
          <b>6.2 Datos que nos proporciona. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Para usar los Servicios, debe tener una cuenta válida. Para registrarse en nuestros Servicios, debe
            proporcionar a GlobalEnglish un ID de Usuario, una dirección de correo electrónico y otra información que se
            considera información de identificación personal (“Datos Personales"). Algunos datos que recopilamos de
            usted son necesarios para que GlobalEnglish proporcione los Servicios o incluso únicamente para enviarle
            información acerca de los Servicios que ha estado consultando, otros datos son opcionales. Los Servicios
            también le permiten mostrar información acerca de usted y su organización, si corresponde.
            <br />
            <br /> Usted acepta: (i) proporcionar información precisa, actualizada y completa sobre usted y, en el caso
            de una transacción B2B, su organización según lo solicitado por los formularios de registro dentro de los
            Servicios; (ii) mantener la confidencialidad de su contraseña y otra información relacionada con la
            seguridad de su cuenta; (iii) mantener y actualizar rápidamente los Datos Personales y cualquier otra
            información que proporcione a GlobalEnglish, para mantener dicha información precisa, actualizada y
            completa; y (iv) ser plenamente responsable de todo el uso de su cuenta y de cualquier acción que tenga
            lugar a través de su cuenta.
            <br />
            <br />
            GlobalEnglish no asume ningún deber de verificar la exactitud de los Datos Personales que proporcione cuando
            se registra.
          </span>
          <br />
          <br />
          <b>6.3 Transferencia internacional de datos personales </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Para proporcionar los Servicios, GlobalEnglish confía en su fuerza laboral en todo el mundo y utiliza
            proveedores externos y proveedores de hosting para el almacenamiento y otros servicios relacionados con la
            tecnología y la enseñanza.&nbsp; Por lo tanto, sus Datos Personales se procesan y transfieren a los
            empleados y subcontratistas de GlobalEnglish en varios países, algunos de los cuales pueden no ofrecer el
            mismo nivel de protección legalmente otorgado a sus Datos Personales en su país de ciudadanía o residencia.
            <b>
              Al acceder, registrar y/o utilizar nuestros Servicios, consiente que sus Datos Personales sean
              transferidos y procesados al extranjero en los diversos países en los cuales GlobalEnglish, sus afiliados
              y subcontratistas están ubicados para los fines de los Servicios.{' '}
            </b>
          </span>
          <br />
          <br />
          <b>6.4 Notificaciones y mensajes. </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Usted entiende y acepta que los Servicios pueden incluir ciertas comunicaciones de GlobalEnglish, tales como
            anuncios de servicio, mensajes administrativos, etc., y que estas comunicaciones se consideran parte de los
            Servicios y que no podrá optar por no recibirlos.
            <br />
            <br /> Por la presente, acepta que GlobalEnglish puede proporcionarle avisos y mensajes de las siguientes
            maneras: (i) dentro del Servicio; (ii) a la información de contacto que proporcionó a GlobalEnglish (por
            ejemplo, correo electrónico o número de teléfono móvil). Acepta mantener su información de contacto
            actualizada en todo momento.
          </span>
          <br />
          <br />
          <b>6.5 Privacidad de otras personas </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Si obtiene información sobre otro Usuario en el curso de su uso de nuestros Servicios, acepta que utilizará
            dicha información sólo para el propósito que se le proporcionó. No podrá divulgar, vender o distribuir la
            información de un Usuario a un tercero para fines no relacionados con los Servicios. No podrá utilizar dicha
            información con fines de marketing, a menos que obtenga el consentimiento del Usuario específico para
            hacerlo.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. LICENCIAS DE USUARIO </b>
          <br />
          <br />
          <b>7.1 Licencia para usar nuestros Servicios </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Se le otorga una licencia limitada, personal, no exclusiva, no cedible, no sublicenciable e intransferible
            para acceder y utilizar los Servicios únicamente para uso personal y no comercial. GlobalEnglish puede, a su
            entera discreción, suspender temporal o permanentemente el Servicio o modificar las características de los
            Servicios de vez en cuando sin previo aviso.
            <br />
            <br /> Para utilizar los Servicios, debe obtener acceso a Internet, ya sea directamente o a través de
            dispositivos que accedan a contenido basado en la Web. Además, debe tener todo el equipo necesario para
            realizar dicha conexión a Internet, incluyendo un ordenador y módem u otro dispositivo de acceso. Acepta
            también la responsabilidad de evaluar y asumir todos los riesgos asociados al uso de los Servicios,
            incluyendo cualquier dependencia de la exactitud, integridad o utilidad del contenido que proporcionan. Ni
            GlobalEnglish ni sus licenciantes garantizan la exactitud o integridad de cualquier contenido.
            <br />
            <br /> Los Servicios no están diseñados o autorizados para su uso en entornos peligrosos (p. ej., mientras
            conduce, dentro o alrededor del agua).
            <br />
            <br />
            El derecho de acceso al Servicio se revoca cuando el uso del Servicio está prohibido o en la medida en que
            la oferta, venta o suministro del Servicio entra en conflicto con cualquier ley, regla o normativa
            aplicable.
            <br />
            <br />
            El uso del Servicio para cualquier propósito que no sea aquel contemplado en las Condiciones de Uso es una
            violación de las Condiciones de Uso y de los derechos de autor y propiedad de GlobalEnglish y/o sus
            licenciantes y subcontratistas. GlobalEnglish tiene el derecho de suspender o cancelar su cuenta y rechazar
            todos los Servicios actuales o futuros si GlobalEnglish tiene motivos razonables para sospechar que utiliza
            los Servicios en violación de estas Condiciones de Uso. Usted acepta que GlobalEnglish no será responsable
            ante usted por cualquier modificación, suspensión o interrupción de los Servicios o de cualquier parte de
            los mismos.
          </span>
          <br />
          <br />
          <b>7.2 Contenido de Usuario</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            Los Servicios le brindan a usted y a otros Usuarios la oportunidad de crear datos e información (por
            ejemplo, proporcionando respuestas a nuestras pruebas) y cargar materiales en servicios basados en la
            comunidad (“Contenido de Usuario"). Sujeto a los derechos que nos otorgan las presentes Condiciones, usted
            conserva la plena propiedad de su Contenido de Usuario.
            <br />
            <br /> Al cargar cualquier Contenido de Usuario a nuestros Servicios, usted otorga a GlobalEnglish una
            licencia mundial irrevocable, perpetua, no exclusiva, transferible, totalmente pagada (con derecho a
            sublicencia) para almacenar, usar, reproducir, ejecutar públicamente, exhibir, modificar, traducir, extraer
            (en su totalidad o en parte), procesar y distribuir dicho Contenido de Usuario según sea necesario en
            relación con la prestación de los Servicios para:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) Cumplir con el proceso legal, incluyendo, sin limitación, la respuesta a solicitudes legales, órdenes
              judiciales y otras solicitudes gubernamentales similares;
              <br />
              (b) Cumplir con el Acuerdo;
              <br />
              (c) Responder a las reclamaciones de que cualquier Contenido viola los derechos de terceros;
              <br />
              (d) Proteger los derechos, la propiedad y la seguridad personal de GlobalEnglish, sus empleados, los
              Usuarios y el público.
            </span>
            <br />
            <br /> En la medida en que usted elija compartir cualquier Contenido de Usuario con otros Usuarios de los
            Servicios, usted acepta permitir que estos Usuarios vean su Contenido de Usuario y, en la medida aplicable,
            colaboren con usted en dicho Contenido de Usuario.
            <br />
            <br /> Tenga en cuenta que GlobalEnglish no tiene ninguna obligación de revisar, verificar la exactitud,
            corregir o eliminar dicho Contenido de Usuario en cualquier momento. Sin embargo, se reserva el derecho de
            monitorear el Contenido de Usuario y eliminar aquellos que, a su absoluta discreción, considere ofensivos o
            que de otro modo infrinjan las Condiciones de Uso.
            <br />
            <br /> GlobalEnglish no tiene ninguna obligación de almacenamiento o de copia de seguridad con respecto al
            Contenido de Usuario. Usted es el único responsable a su exclusivo coste de crear copias de seguridad y
            reemplazar cualquier Contenido de Usuario que publique o almacene en el Sitio Web o proporcione a
            GlobalEnglish.
            <br />
            <br /> El contenido del usuario no refleja necesariamente las observaciones de GlobalEnglish. Usted entiende
            que el Contenido de Usuario es responsabilidad exclusiva del Usuario del cual se origina dicho Contenido de
            Usuario. Usted entiende que, si utiliza los Servicios, puede estar expuesto a Contenido de Usuario que sea
            ofensivos, indecente u objetable.
            <br />
            <br /> Además de la concesión de licencia al Contenido de Usuario establecida anteriormente, usted reconoce
            y acepta que cualquier pregunta, observación, sugerencia, ideas, retroalimentación u otra información sobre
            los Servicios (“Envíos”), proporcionada por usted a GlobalEnglish no es confidencial y GlobalEnglish tendrá
            derecho al uso y difusión sin restricciones de estos Envíos para cualquier propósito, comercial o de otro
            tipo, sin reconocimiento o compensación para usted. Además, la información publicada, divulgada o compartida
            en o a través de cualquiera de los Servicios o productos basados en la comunidad de GlobalEnglish por usted
            será excluida de la definición de información confidencial, tal y como se define generalmente ese término.
            <br />
            <br /> Usted entiende que el procesamiento técnico y la transmisión del sitio web, incluido su Contenido de
            Usuario, pueden incluir (a) transmisiones a través de diversas redes; y (b) cambios para ajustarse y
            adaptarse a los requisitos técnicos de la conexión de redes o dispositivos.
            <br />
            <br />
            <b>
              GlobalEnglish no hace representaciones o garantías con respecto al Contenido de Usuario. El contenido del
              usuario no refleja necesariamente las observaciones de GlobalEnglish. GlobalEnglish en ningún caso tendrá
              responsabilidad alguna por el Contenido de Usuario o por la pérdida del mismo por cualquier motivo o por
              cualquier reclamación, daño o pérdida resultante de su uso o pérdida.
            </b>
          </span>
          <br />
          <br />
          <b>7.3 Conducta de usuario requerida</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Es exclusivamente responsable de todas y cada una de las actividades que ocurran en su cuenta. Acepta:
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) notificar inmediatamente a GlobalEnglish cualquier uso no autorizado de su cuenta o cualquier otro
              incumplimiento de seguridad, y
              <br />
              (b) asegurarse de salir de su cuenta al finalizar cada sesión.
            </span>
            <br />
            <br />
            GlobalEnglish no puede y no será responsable de ninguna pérdida o daño derivado de su incumplimiento
            <br />
            <br />
            Usted es el único responsable de asegurar que estas Condiciones de Servicio cumplan con todas las leyes,
            reglas y normativas que le sean aplicables, y el derecho de acceso al Servicio es revocado cuando estas
            Condiciones de Servicio o uso del Servicio está prohibido o en la medida en que la oferta, venta o
            suministro del Servicio entren en conflicto con cualquier ley, regla o normativa aplicable.
          </span>
          <br />
          <br />
          <b>7.4 Conducta de usuario prohibida </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Los siguientes son ejemplos del tipo de uso considerado ilegal o prohibido por GlobalEnglish. GlobalEnglish
            se reserva el derecho de investigar y tomar las acciones legales apropiadas contra cualquier persona que, a
            discreción exclusiva de GlobalEnglish, viole esta disposición, incluyendo, sin limitación, la eliminación
            del contenido ofensivo del sitio web o la aplicación, la suspensión o terminación de la cuenta de dichos
            Usuarios y reportarlo a las autoridades policiales.
            <br />
            <br /> No está permitido:
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) adaptar, descargar, revisar, emitir, realizar ingeniería inversa, duplicar, publicar, modificar,
              diseminar, exhibir, transferir o copiar y/o distribuir cualquier Contenido de GlobalEnglish u otro
              material en el Servicio, a menos que sea específicamente autorizado por GlobalEnglish.
              <br />
              (b) enmarcar o utilizar técnicas de enmarcado para incluir cualquier parte del Servicio;
              <br />
              (c) recopilar, obtener, usar, acceder o copiar de otra manera cualquier parte del Servicio mediante el uso
              de cualquier bot, araña, rastreador, software espía, motor, dispositivo, software o cualquier otro
              dispositivo automático, utilidad o proceso manual de cualquier tipo;
              <br />
              (d) utilizar los Servicios o cualquier característica disponible en los Servicios de cualquier manera con
              la intención de interrumpir, dañar, deshabilitar, sobrecargar o dañar los Servicios;
              <br />
              (e) vender, licenciar, arrendar o utilizar de otro modo los Servicios para actividades comerciales; o
              <br />
              (f) participar en cualquier actividad que interfiera con el acceso, uso o disfrute de otro Usuario de este
              Servicio.
              <br />
              (g) Publicar, enviar por correo electrónico o transmitir de otro modo cualquier Contenido de Usuario que
              (i) sea ilegal, dañino, amenazante, abusivo, acosador, malicioso, excesivamente violento, difamatorio,
              vulgar, obsceno, indecente, pornográfico, difamatorio, invasivo de la privacidad de otro (hasta, pero sin
              excluir, cualquier dirección, correo electrónico, número de teléfono y otra información de contacto),
              odioso racialmente, étnicamente o de otra manera objetable; (ii) usted no tenga derecho a transmitir bajo
              ninguna ley o bajo relaciones contractuales o fiduciarias; (iii) plantee o cree un riesgo de privacidad o
              seguridad para cualquier persona; (iv) infrinja cualquier propiedad intelectual u otros derechos de
              propiedad de cualquier parte; (v) constituya publicidad no solicitada o no autorizada, materiales de
              promoción, actividades comerciales y/o ventas, "correo basura", "spam", "cartas en cadena", "esquemas
              piramidales", "concursos", "sorteos" o solicitud de cualquier otro tipo; (vi) contenga virus o cualquier
              otro código de software, archivos o programas diseñados para interrumpir, destruir o limitar la
              funcionalidad de cualquier tipo de software, hardware o material de telecomunicaciones; o (vii) a criterio
              exclusivo de GlobalEnglish, es objetable o restringe o inhibe a cualquier otra persona en su uso del
              disfrute de los Servicios, o que pueda exponer a GlobalEnglish o a sus usuarios a cualquier daño o
              responsabilidad de cualquier tipo.
              <br />
              (h) Crear una identidad falsa, tergiversar su identidad, suplantar a cualquier persona o entidad, o
              falsamente declarar o tergiversar su afiliación con una persona o entidad;
              <br />
              (i) solicitar información personal a cualquier persona menor de 18 años;
              <br />
              (j) dañar a menores de cualquier manera;
              <br />
              (k) promover daños físicos o lesiones contra cualquier grupo o individuo, “vigilar" o acosar a alguien;
              <br />
              (l) proporcionar o promover información instructiva acerca de actividades ilegales, como, por ejemplo,
              instrucciones sobre cómo ensamblar bombas, granadas y otras armas o artefactos incendiarios;
              <br />
              (m) recopilar, mediante el uso de scripts automatizados o de otro modo, los contenidos del sitio web o
              direcciones de correo electrónico, información de contacto u otra información privada de otros Usuarios
              del Sitio Web para cualquier propósito, incluyendo, sin limitación, con el propósito de enviar correos
              electrónicos no solicitados u otras comunicaciones no solicitadas a los Usuarios o reproducir el contenido
              del sitio web;
              <br />
              (n) anunciar u ofrecer la venta o compra de bienes o servicios para cualquier propósito comercial que no
              esté específicamente autorizado;
              <br />
              (o)interferir o interrumpir el sitio web o los servidores o redes conectados al sitio web, o desobedecer
              cualquier requisito, procedimiento, política o normativa de las redes conectadas al sitio web;
              <br />
              (p) violar cualquier ley local, estatal, nacional o internacional aplicable, o cualquier normativa que
              tenga fuerza de ley; agregar o promover cualquier actividad o empresa delictiva, o proporcionar
              información instructiva acerca de actividades ilegales;
              <br /> (q) obtener o tratar de acceder u obtener de otra manera cualquier material o información a través
              de cualquier medio no puesto a disposición intencionalmente o proporcionado a través del sitio web;
              <br />
              (r) anular las características de seguridad o derivar o eludir cualquier control de acceso; o
              <br />
              (s) utilizar los Servicios de una manera que pueda crear un conflicto de intereses o socavar los
              propósitos de los Servicios, tales como evaluaciones comerciales con otros Usuarios o escribir o solicitar
              evaluaciones propias.
            </span>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. DERECHOS DE PROPIEDAD INTELECTUAL </b>
          <br />
          <br />
          <b>8.1 Derechos de propiedad intelectual de GlobalEnglish </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Todo el contenido de los Servicios, incluyendo su Contenido y cualquier software de apoyo son propiedad
            exclusiva de GlobalEnglish, sus licenciantes o sus subcontratistas y están protegidos por los derechos de
            autor estadounidenses e internacionales y otras leyes de propiedad intelectual.
            <br />
            <br /> Queda estrictamente prohibida la reproducción, redistribución, modificación o publicación de
            cualquier parte de los Servicios sin el consentimiento expreso por escrito de GlobalEnglish y/o sus
            licenciantes o que no sean los establecidos a continuación.
            <br />
            <br /> Los Usuarios Autorizados podrán acceder y utilizar los Servicios y el Contenido, así como descargar o
            imprimir un número razonable de copias de partes del Contenido a las que el Usuario haya accedido
            adecuadamente únicamente para uso personal del Usuario con fines de formación lingüística, siempre que el
            Usuario mantenga todos los derechos de autor u otros avisos de propiedad sobre todas las copias de dicho
            Contenido.
            <br />
            <br /> A menos que se indique lo contrario, las marcas comerciales que aparecen en los Servicios son marcas
            comerciales de GlobalEnglish, sus licenciantes o sus filiales. Usted acepta no mostrar, menospreciar o
            manchar las marcas comerciales o utilizar marcas similares confusas o usarlas de una manera que tergiversara
            la propiedad de dichas marcas o creara la impresión de que GlobalEnglish respalda cualquier producto o
            servicio. No puede reproducir o usar las marcas comerciales, nombres comerciales y/o imagen comercial de
            GlobalEnglish sin el permiso escrito previo de GlobalEnglish. Todo fondo de comercio generado por el uso de
            las marcas comerciales de GlobalEnglish redundará en nuestro beneficio exclusivo.
            <br />
            <br /> Queda prohibido el uso de los sitios web, aplicaciones o Contenido que no estén específicamente
            autorizados en el presente documento y terminará automáticamente sus derechos con respecto al uso de los
            Servicios y el Contenido otorgado en el presente documento. Todos los derechos de GlobalEnglish o sus
            licenciantes que no se otorgan expresamente en estas Condiciones se reservan a GlobalEnglish y sus
            licenciantes.
          </span>
          <br />
          <br />
          <b>8.2 Marcas Comerciales de terceros </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Los nombres y logotipos de productos y Servicios utilizados y mostrados a través del Sitio Web, distintos de
            las marcas comerciales de GlobalEnglish descritas anteriormente, pueden ser marcas comerciales o marcas de
            servicio de sus respectivos propietarios que pueden o no endosar o estar afiliados o conectados a
            GlobalEnglish. Nada en estas Condiciones o en el sitio web debe interpretarse como una concesión, por
            implicación, impedimento o de otra manera, de cualquier licencia o derecho de uso de cualquiera de las
            marcas registradas que se exhiben en el sitio web sin nuestro permiso previo por escrito en cada caso. Todo
            fondo de comercio generado por el uso de las marcas comerciales de GlobalEnglish redundará en nuestro
            beneficio exclusivo.
          </span>
          <br />
          <br />
          <b>
            8.3 Reclamaciones por derechos de autor en virtud de la Digital Millennium Copyright Act (Ley de derechos de
            autor Digital Millennium){' '}
          </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Si tiene cualquier inquietud sobre derechos de autor acerca de cualquier material publicado en los Servicios
            por otros, por favor háganoslo saber.
            <br />
            <br /> GlobalEnglish procesará e investigará los avisos sobre presunta infracción y tomará las acciones
            apropiadas bajo la Ley de Derecho de Autor Digital del Milenio (“DMCA") y otras leyes de propiedad
            intelectual aplicables con respecto a cualquier infracción supuesta o real.
            <br />
            <br /> En caso de aviso sobre una presunta infracción, debe enviar una notificación por correo electrónico
            al Agente de Derechos de Autor de GlobalEnglish a copyright@globalenglish.com (Asunto: “DMCA Takedown
            Request”).
            <br />
            <br /> Usted también puede comunicarse con nosotros por correo electrónico en:
            <br />
            <b>Attn: Copyright Complaints/Legal Department</b>
            <br /> 1875 South Grant Street, Suite 700
            <br /> San Mateo, CA 94402 USA
            <br />
            <br />
            <br /> Para ser efectiva, la notificación debe ser por escrito y contener la siguiente información: (i) una
            firma electrónica o física de la persona autorizada para actuar en nombre del propietario del derecho de
            autor u otro interés de propiedad intelectual; (ii) una descripción de la obra protegida por derechos de
            autor u otra propiedad intelectual que usted alega que ha sido infringida; (iii) una descripción de dónde se
            encuentra el material que usted alega que está infringiendo el Servicio, con suficiente detalle para que
            podamos encontrarlo en el sitio web; (iv) información suficiente para que GlobalEnglish se ponga en contacto
            con usted (dirección, número de teléfono y dirección de correo electrónico); (v) una declaración redactada
            por usted indicando que tiene una creencia de buena fe de que el uso en disputa no está autorizado por el
            propietario del derecho de autor o propiedad intelectual, su agente o la ley; (vi) una declaración suya,
            hecha bajo pena de perjurio, de que la información anterior en su Aviso es exacta y que usted es el
            propietario del derecho de autor o propiedad intelectual o está autorizado a actuar en nombre del
            propietario del derecho de autor o propiedad intelectual.
            <br />
            <br /> De acuerdo con la DMCA y otras leyes aplicables, GlobalEnglish adoptó una política de rescisión, en
            las circunstancias apropiadas y a discreción exclusiva de GlobalEnglish, de los usuarios que se consideran
            infractores repetidos. GlobalEnglish también puede, a su entera discreción, limitar el acceso al Sitio Web
            y/o rescindir las membresías de cualquier usuario que infrinja cualquier derecho de propiedad intelectual de
            otros, independientemente de que exista o no una infracción repetida.
          </span>
          <br />
          <br />
          <b>8.4 Eliminación de Contenido de Usuario sospechoso de infracción </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            Si GlobalEnglish ha eliminado parte de su Contenido de Usuario sospechoso de infringir los derechos de
            propiedad intelectual de terceros y cree que su Contenido de Usuario que se eliminó (o al que se
            inhabilitaron los accesos) no infringe, o que tiene la autorización del propietario de los derechos de
            autor, del agente del propietario de los derechos de autor, o de conformidad con la ley, para publicar y
            utilizar el contenido en su Contenido de Usuario, puede enviar una contra-notificación por escrito que
            contenga la siguiente información al Agente de Derechos de Autor: (i) su firma electrónica o física; (ii)
            identificación del contenido que ha sido eliminado o al que se ha inhabilitado el acceso y la ubicación en
            la que el contenido apareció antes de que fuera eliminado o inhabilitado; (iii) una declaración de que tiene
            una creencia de buena fe de que el contenido fue eliminado o inhabilitado como resultado de un error o una
            identificación errónea del contenido; y (iv) su nombre, dirección, número de teléfono y dirección de correo
            electrónico, una declaración de que usted da su consentimiento a la jurisdicción de la corte federal ubicada
            en el Distrito Norte de California y una declaración de que usted aceptará el servicio de proceso de la
            persona que proporcionó la notificación de la presunta infracción.
            <br />
            <br /> Si el Agente de Derechos de Autor recibe una contra-notificación, GlobalEnglish enviará una copia de
            la contra-notificación a la parte reclamante original informándole que puede reemplazar el contenido
            eliminado o dejar de inhabilitarlo en 10 días hábiles. A menos que el propietario de los derechos de autor
            interponga una demanda solicitando una orden judicial contra el proveedor de contenido, miembro o usuario,
            el contenido eliminado puede ser reemplazado, o el acceso al mismo restaurado, de 10 a 14 días hábiles o más
            después de la recepción de la contra-notificación, a nuestra entera discreción.
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
            {' '}
            9. CONTROLES INTERNACIONALES DE USO Y EXPORTACIÓN{' '}
          </b>
          <br />
          <br /> Reconociendo la naturaleza global de Internet, acepta cumplir con todas las reglas locales con respecto
          a la conducta en línea y el Contenido de Usuario aceptable. Usted acuerda específicamente cumplir con todas
          las leyes aplicables relativas a la transmisión de datos técnicos exportados desde los Estados Unidos o del
          país en donde reside.
          <br />
          <br /> Reconoce que los Servicios o parte de los mismos pueden estar sujetos a las leyes de control de
          exportaciones de los Estados Unidos. Usted acepta no exportar, reexportar o transferir ninguna parte del
          Servicio ni información o materiales técnicos relacionados, ya sea directa o indirectamente, si con ello
          infringe cualquiera de las normativas o leyes de exportación aplicables. Usted declara y garantiza que no es
          ciudadano ni está ubicado en un país sujeto a un embargo del Gobierno de los Estados Unidos o que ha sido
          designado por el Gobierno de los Estados Unidos como un país que "apoya el terrorismo” y que no está incluido
          en ninguna lista del Gobierno de los Estados Unidos de partes prohibidas o restringidas.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. MEDIDAS CAUTELARES </b>
          <br />
          <br /> Usted reconoce que cualquier uso del Servicio contrario a las Condiciones de Uso puede causar daños
          irreparables a GlobalEnglish, sus afiliados, licenciantes, subcontratistas, revendedores y Usuarios. En tales
          circunstancias, GlobalEnglish, sus filiales, licenciantes, subcontratistas, revendedores y Usuarios tendrán
          derecho a una indemnización sin depositar fianza u otra garantía, incluyendo pero no limitado a medidas
          cautelares preliminares y permanentes.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11. TIEMPO DE INACTIVIDAD </b>
          <br />
          <br /> Los Servicios pueden no estar disponibles temporalmente de vez en cuando por mantenimiento u otras
          razones. GlobalEnglish no tendrá ninguna responsabilidad por cualquier interrupción, retraso en la operación o
          transmisión, robo o destrucción, acceso no autorizado o alteración de las comunicaciones del Usuario o
          cualquier otro contenido que esté disponible a través del sitio web.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12.EXENCIÓN DE GARANTÍAS </b>
          <br />
          <br /> Los Servicios pueden no estar disponibles temporalmente de vez en cuando por mantenimiento u otras
          razones. GlobalEnglish no tendrá ninguna responsabilidad por cualquier interrupción, retraso en la operación o
          transmisión, robo o destrucción, acceso no autorizado o alteración de las comunicaciones del Usuario o
          cualquier otro contenido disponible a través de los Servicios. Bajo ninguna circunstancia GlobalEnglish será
          responsable de manera alguna por cualquier lesión personal o fallecimiento resultante del uso de los
          Servicios, cualquier Contenido de Usuario o Contenido de Terceros, o cualquier uso de productos o servicios
          proporcionados por los Usuarios.
          <br />
          <br /> USTED COMPRENDE Y ACEPTA QUE:
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            {' '}
            (a) LOS SERVICIOS, INCLUYENDO TODO EL CONTENIDO EN LOS MISMOS, SE PROPORCIONAN “TAL CUAL” Y TAL Y COMO ESTÁN
            DISPONIBLES.
            <br />
            <br /> (b) GLOBALENGLISH RENUNCIA A TODAS Y CADA UNA DE LAS DECLARACIONES Y GARANTÍAS CON RESPECTO A LOS
            SERVICIOS, YA SEAN EXPRESAS O IMPLÍCITAS, INCLUYENDO GARANTÍAS IMPLÍCITAS DE TÍTULO, COMERCIABILIDAD,
            IDONEIDAD PARA UN PROPÓSITO PARTICULAR O NO INFRACCIÓN.
            <br />
            <br /> (c) GLOBALENGLISH NO GARANTIZA NI PROMETE NINGÚN RESULTADO ESPECÍFICO DEL USO DEL SERVICIO NI QUE LOS
            SERVICIOS SATISFAGAN SUS REQUISITOS O EXPECTATIVAS.
            <br />
            <br />
            (d) GLOBALENGLISH NO REPRESENTA NI GARANTIZA QUE EL SOFTWARE, CONTENIDO O MATERIALES EN LOS SERVICIOS O EN
            EL SITIO WEB SEAN PRECISOS, COMPLETOS, CONFIABLES, ACTUALES O LIBRES DE ERRORES O QUE EL SITIO WEB O LOS
            SERVICIOS, O SUS SERVIDORES, ESTÉN LIBRES DE VIRUS U OTROS COMPONENTES DAÑINOS. POR LO TANTO, SIN LIMITAR LO
            ANTERIOR, USTED COMPRENDE Y ACEPTA QUE DESCARGA U OBTIENE DE OTRO MODO CONTENIDO, MATERIAL, DATOS O SOFTWARE
            DE O A TRAVÉS DE LOS SERVICIOS O DE UN PROVEEDOR A SU PROPIA DISCRECIÓN Y RIESGO Y QUE USTED SERÁ EL ÚNICO
            RESPONSABLE DE SU USO Y CUALQUIER DAÑO A SU SISTEMA INFORMÁTICO, PÉRDIDA DE DATOS U OTRO DAÑO DE CUALQUIER
            TIPO QUE PUEDA RESULTAR.
            <br />
            <br /> (e) GLOBALENGLISH NO GARANTIZA QUE SE CORRIJAN LOS ERRORES O DEFECTOS EN LOS SERVICIOS.
            <br />
            <br />
            (f) GLOBALENGLISH NO ES RESPONSABLE DE LA CONDUCTA DE LOS USUARIOS, EL CONTENIDO DE TERCEROS Y EL CONTENIDO
            DE USUARIO.
            <br />
            <br />
            (g) Los Servicios pueden no estar disponibles temporalmente de vez en cuando por mantenimiento u otras
            razones. GlobalEnglish no tendrá ninguna responsabilidad por cualquier interrupción, retraso en la operación
            o transmisión, robo o destrucción, acceso no autorizado o alteración de las comunicaciones del Usuario o
            cualquier otro contenido disponible a través de los Servicios. Bajo ninguna circunstancia GlobalEnglish será
            responsable de manera alguna por cualquier lesión personal o fallecimiento resultante del uso de los
            Servicios, cualquier Contenido de Usuario o Contenido de Terceros, o cualquier uso de productos o servicios
            proporcionados por los Usuarios.
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. LIMITACIÓN DE RESPONSABILIDAD</b>
          <br />
          <br /> EN NINGÚN CASO GLOBALENGLISH O SUS DIRECTIVOS, EMPLEADOS, AGENTES, LICENCIANTES O SUBCONTRATISTAS SERÁN
          RESPONSABLES ANTE USTED O CUALQUIER TERCERO POR CUALQUIER DAÑO INDIRECTO, CONSECUENTE, EJEMPLAR, INCIDENTAL,
          ESPECIAL O PUNITIVO, INCLUYENDO CUALQUIER GANANCIA PERDIDA O DATOS PERDIDOS DERIVADOS DE: (i) SU USO O
          INCAPACIDAD PARA UTILIZAR LOS SERVICIOS, O CUALQUIERA DE LOS CONTENIDOS U OTROS MATERIALES EN, ACCEDIDOS A
          TRAVÉS O DESCARGADOS DEL SITIO WEB, SERVICIOS O LOS PROVEEDORES, (ii) EL COSTO DE ADQUISICIÓN DE BIENES Y
          SERVICIOS SUSTITUTIVOS RESULTANTES DE CUALQUIER BIEN, DATOS, INFORMACIÓN O SERVICIOS COMPRADOS U OBTENIDOS O
          MENSAJES RECIBIDOS O TRANSACCIONES REALIZADAS A TRAVÉS O DESDE EL SITIO WEB; (iii) ACCESO NO AUTORIZADO O
          ALTERACIÓN DE SUS TRANSMISIONES O DATOS POR CUALQUIER PARTE; (iv) DECLARACIONES O CONDUCTA DE CUALQUIER
          TERCERO EN EL SITIO WEB; O (v) CUALQUIER OTRO ASUNTO RELACIONADO CON LOS SERVICIOS. EN NINGÚN CASO LA
          RESPONSABILIDAD TOTAL DE GLOBALENGLISH ANTE USTED POR TODOS LOS DAÑOS, PÉRDIDAS O DERECHO DE DEMANDA EXCEDERÁ
          LA CANTIDAD QUE USTED HA PAGADO A GLOBALENGLISH EN LOS ÚLTIMOS DOCE (12) MESES, O, SI FUERA MAYOR, CIEN
          DÓLARES ($ 100), INCLUSO SI GLOBALENGLISH ES CONSCIENTE O HA SIDO ADVERTIDO DE LA POSIBILIDAD DE TALES DAÑOS.
          LA EXISTENCIA DE MÁS DE UNA RECLAMACIÓN NO AMPLIARÁ ESTE LÍMITE.
          <br />
          <br /> ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN DE CIERTAS GARANTÍAS O LA EXCLUSIÓN O LIMITACIÓN DE
          RESPONSABILIDAD POR DAÑOS ACCESORIOS O EMERGENTES. EN CONSECUENCIA, ALGUNAS DE LAS LIMITACIONES ESTABLECIDAS
          ANTERIORMENTE PUEDEN NO SERLE DE APLICACIÓN.
          <br />
          <br /> SI NO ESTÁ SATISFECHO CON CUALQUIER PARTE DEL SITIO WEB O CON ESTAS CONDICIONES DEL SITIO WEB, SU ÚNICA
          Y EXCLUSIVA SOLUCIÓN ES SUSPENDER EL USO DEL SITIO WEB.
          <br />
          <br /> GLOBALENGLISH NO SERÁ, BAJO NINGUNA CIRCUNSTANCIA, RESPONSABLE DE LESIONES FÍSICAS O FALLECIMIENTO
          RESULTANTE DEL USO DE LOS SERVICIOS O CONTENIDO DE TERCEROS O CONTENIDO DE USUARIO.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14.PLAZO DE PRESCRIPCIÓN </b>
          <br />
          <br />
          Usted se obliga a que, sin importar cualquier disposición o legislación en contrario, cualquier reclamación o
          acción judicial contra GlobalEnglish que surja o se relacione con su uso de los Servicios deberá ser
          presentada no más tarde de un (1) año a partir de que dicha reclamación o acción haya surgido, de lo
          contrario, quedarán sin efectos permanentemente.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>15. INDEMNIZACIÓN </b>
          <br />
          <br />
          Se compromete a liberar, indemnizar y mantener indemne a GlobalEnglish y sus afiliados y funcionarios,
          empleados, directores y agentes de cualquier reclamación, acción, coste, responsabilidad, pérdida, daño,
          gasto, incluidos los honorarios razonables de abogados, hechos contra GlobalEnglish por cualquier tercero
          debido o que surjan de o se relacionen con su uso del sitio web, los Servicios, su Contenido de Usuario, su
          conexión con el Sitio Web o los Servicios, su violación de estas Condiciones o su violación de cualquier
          derecho de otro Usuario o de cualquier ley o de cualquier otra manera que surja de su uso de los Servicios.
          Sus obligaciones de indemnización incluyen sin limitación reclamaciones contra GlobalEnglish que cualquier
          Contenido de Usuario infringe los derechos de propiedad intelectual de un tercero.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>16. RESCISIÓN </b>
          <br />
          <br /> Si su suscripción se cancela por cualquier motivo, GlobalEnglish inhabilitará su acceso a los
          Servicios. No podrá acceder a ningún Contenido, incluido su Contenido de Usuario, aunque las copias de dichos
          datos permanecerán en nuestro sistema durante tres (3) años para fines de respaldo.
          <br />
          <br /> Si solicita que GlobalEnglish elimine sus Datos Personales y su Contenido de Usuario, haremos todos los
          esfuerzos razonables para hacerlo y confirmaremos cuándo se produjo la eliminación.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>17. RENUNCIA Y DIVISIBILIDAD </b>
          <br />
          <br /> El incumplimiento por parte de GlobalEnglish de cualquier derecho o disposición en las presentes
          Condiciones no constituirá una renuncia a dicho derecho o disposición en ese o en cualquier otro caso. Si se
          determina que alguna disposición de las presentes Condiciones es ilegal, nula o por cualquier motivo
          inaplicable, dicha determinación no afectará la validez y aplicabilidad de cualquiera de las disposiciones
          restantes.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>18. ACUERDO ÍNTEGRO </b>
          <br />
          <br /> El presente Acuerdo constituye el acuerdo íntegro entre usted y GlobalEnglish con respecto al uso de
          los Servicios y reemplaza cualquier entendimiento y acuerdo previo entre usted y GlobalEnglish con respecto a
          su uso de los Servicios.
          <br />
          <br /> Sin perjuicio de lo anterior, si su Empresa tiene un Acuerdo existente con GlobalEnglish para la compra
          de cualquier Servicio, las presentes Condiciones no pretenden alterar los términos o condiciones de dicho
          acuerdo que su Empresa pueda tener con GlobalEnglish, sus subsidiarias o afiliadas, y en la medida de
          cualquier conflicto, las condiciones de dicho Acuerdo prevalecerán.
          <br />
          <br /> El presente acuerdo ha sido redactado en inglés y dicha versión será controladora en todos los aspectos
          y cualquier versión no inglesa del presente Acuerdo es únicamente para fines de comodidad. Una versión impresa
          del presente acuerdo y de cualquier notificación enviada en forma electrónica será admitida en procedimientos
          judiciales o administrativos basados en el presente acuerdo o relacionados con el mismo, y tendrán el mismo
          alcance y estarán sujetas a las mismas condiciones que otros documentos y registros comerciales generados y
          mantenidos originalmente en forma impresa. Los títulos de las secciones de las presentes Condiciones solo se
          proporcionan por conveniencia y no poseen ningún efecto legal ni contractual.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>19. ELECCIÓN DE LA LEY, JURISDICCIÓN Y ARBITRAJE </b>
          <br />
          <br />
          Las presentes Condiciones se regirán por las leyes del Estado de California, independientemente de conflictos
          de principios legales.
          <br />
          <br /> A elección de GlobalEnglish y a su entera discreción, todas las disputas, reclamaciones o controversias
          que surjan o se relacionen con las presentes Condiciones o el Sitio Web que no se resuelvan de mutuo acuerdo
          podrán resolverse mediante arbitraje vinculante que se llevará a cabo ante JAMS, o su sucesor. A menos que las
          partes acuerden lo contrario, el arbitraje se llevará a cabo en San Francisco, California, ante un único
          árbitro de mutuo acuerdo entre las partes, o si las partes no pueden llegar a un acuerdo mutuo, ante un único
          árbitro nombrado por JAMS, y se llevará a cabo de acuerdo con las reglas y normativas promulgadas por JAMS a
          menos que se modifiquen específicamente en las presentes Condiciones. El arbitraje debe comenzar dentro de los
          cuarenta y cinco (45) días siguientes a la fecha en que una de las partes presente una demanda de arbitraje
          por escrito. La decisión y el laudo del árbitro se tomarán y entregarán en un plazo de sesenta (60) días desde
          la conclusión del arbitraje y en un plazo de seis (6) meses desde la selección del árbitro. El árbitro no
          tendrá la facultad de otorgar daños y perjuicios que excedan la limitación sobre los daños y perjuicios
          directos y compensatorios reales establecida en las Condiciones y no podrá multiplicar los daños reales u
          otorgar daños punitivos o cualquier otro daño que esté específicamente excluido bajo las Condiciones, y cada
          parte renuncia irrevocablemente a cualquier reclamación por dichos daños. El árbitro podrá, a su discreción,
          evaluar los costes y gastos (incluidos los honorarios y gastos legales razonables de la parte vencedora)
          contra cualquier parte en un procedimiento. Cualquier parte que se niegue a cumplir con una orden de un
          árbitro será responsable de los costes y gastos, incluidos los honorarios de abogados, incurridos por la otra
          parte en la ejecución del laudo. Sin perjuicio de lo anterior, en el caso de medidas cautelares temporales o
          preliminares, cualquiera de las partes podrá proceder ante el tribunal sin arbitraje previo con el fin de
          evitar un daño inmediato e irreparable. Las disposiciones de esta sección de arbitraje serán ejecutables en
          cualquier tribunal de jurisdicción competente.
          <br />
          <br /> Con respecto a cualquier disputa o reclamación no sujeta a arbitraje, como se establece anteriormente,
          usted y GlobalEnglish acuerdan someterse a la jurisdicción personal y exclusiva de los tribunales estatales y
          federales ubicados dentro del Condado de San Francisco, California.
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. NOTIFICACIONES Y CONTACTOS </b>
          <br />
          <br />
          Puede pedir que se cancele su cuenta de GlobalEnglish Service enviando a GlobalEnglish una notificación de
          cancelación a través del vínculo “Contáctenos” en
          <a href="https://support.globalenglish.com/" rel="noopener noreferrer" target="_blank">
            https://support.globalenglish.com/
          </a>
          . GlobalEnglish puede impedir su acceso y uso de los Servicios, incluido cualquier contenido en los mismos, en
          cualquier momento después de la cancelación de su cuenta.
          <br />
          <br /> Las notificaciones se pueden enviar por correo electrónico o por correo ordinario. El sitio web también
          puede proporcionarle avisos de cambios en las presentes Condiciones u otros asuntos mostrando notificaciones o
          vínculos a notificaciones generalmente acerca del Servicio.
          <br />
          <br /> Las notificaciones relacionadas con nuestra política de privacidad pueden enviarse a
          <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
            privacy@globalenglish.com.
          </a>
          <br />
          <br /> Las demás notificaciones legales u otra correspondencia a GlobalEnglish se deberán enviar a la
          siguiente dirección de correo electrónico:
          <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
            legal@globalenglish.com
          </a>{' '}
          o a la siguiente dirección física:
          <br />
          <br /> 1875 S Grant Street, Suite 700
          <br /> San Mateo, CA 94402
          <br /> to the attention of
          <b>Legal Counsel</b>
          <br />
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>CONDICIONES ESPECÍFICAS PARA LAS TRANSACCIONES B2B </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          Si obtuvo su cuenta con GlobalEnglish a través de su empleador, su uso de nuestros Servicios también está
          sujeto a nuestro acuerdo con su empresa (una “Empresa“). Las Condiciones de Uso no pretenden alterar las
          condiciones del acuerdo con su Empresa. Usted no puede usar (o seguir utilizando) los Servicios si su Empresa
          no lo autoriza como usuario final en su licencia con GlobalEnglish. Si nos pide que cancelemos su cuenta, su
          Empresa puede ser obligada a dar permiso antes de que GlobalEnglish cancele su cuenta.
          <br />
          <br /> Usted acepta utilizar los Servicios únicamente según lo permita su Empresa. Puede tener la oportunidad
          de comunicarse con otros dentro de su Empresa o cargar documentos relacionados con su Empresa a través de los
          Servicios, pero no se le permite hacerlo, y no puede hacerlo, sin la autorización de su Empresa.
          <br />
          <br /> Reconoce y acepta que todos los datos generados en relación con su uso de los Servicios (“Datos del
          Usuario") pueden ser accesibles por su Empresa y que GlobalEnglish no tendrá ninguna responsabilidad con usted
          en base a la información compartida con su Empresa como consecuencia de su uso de nuestros Servicios.
          <br />
          <br /> Debido a que su suscripción para acceder a los Servicios continúa sólo durante el período de la
          licencia de esa Empresa, si su Empresa cancela su licencia con GlobalEnglish, perderá el acceso a los
          Servicios y al Contenido de Usuario. GlobalEnglish puede cancelar o suspender la cuenta de su Empresa, su
          cuenta o la capacidad de utilizar los Servicios, en su totalidad o en parte, sin previo aviso en caso de que
          (i) su Empresa no pague las tasas debidas en virtud del presente documento a GlobalEnglish cuando sea debido,
          o (ii) usted o su Empresa violen las Condiciones o violen cualquier otra regla que rija el uso de los
          Servicios.
          <br />
          <br /> GlobalEnglish puede tener la obligación de devolver a su empresa todos los datos relacionados con su
          uso de los Servicios, incluidos sus Datos Personales y su Contenido de Usuario. Si su empresa no solicita que
          dichos datos sean devueltos o borrados, GlobalEnglish conservará los datos durante un período de tres (3) años
          después de la expiración o cancelación de su contrato con su empleador. En tales casos, siempre podrá comprar
          una suscripción al servicio directamente de GlobalEnglish y continuar su acceso al programa sin pérdida de
          información de progreso. Podría autorizar la transferencia de su progreso e información de perfil a una nueva
          suscripción con su nuevo empleador. También puede solicitar todos los datos relacionados con su uso de los
          Servicios en un formato compatible con las importaciones futuras.
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>CONDICIONES ESPECÍFICAS PARA LAS TRANSACCIONES B2C </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          También puede celebrar un acuerdo de suscripción con GlobalEnglish como persona física que podrá comprender
          una suscripción de tres (3) o seis (6) meses o un plan de suscripción anual o multianual (cada uno, un “Plan
          de Suscripción”).
          <br />
          <br /> Será facturado mensualmente o anualmente por el Plan de Suscripción aplicable en o alrededor del mismo
          día cada mes/año (según corresponda) hasta el momento en que cancele su Plan de Suscripción. Para evitar
          dudas, tenga en cuenta que no se le permitirá cancelar, reducir el número de asientos ni rebajar el Servicio
          seleccionado hasta el aniversario de su Plan de Suscripción. No se permitirán reembolsos por pagos del Plan de
          Suscripción.
          <br />
          <br /> Las tarifas aplicables para el Servicio (“Tarifas") están disponibles en el Sitio Web y/o en la lista
          de precios vigentes publicados de GlobalEnglish. El precio establecido para el Servicio excluye todos los
          impuestos y cargos, a menos que se indique lo contrario. Usted es responsable de cualquier impuesto y de todos
          los demás cargos relacionados con el uso de los Servicios (por ejemplo, cargos de datos y liquidaciones de
          cambio de moneda). Pagará las Tarifas en la divisa en que GlobalEnglish le presupuestó su cuenta.
          GlobalEnglish se reserva el derecho a cambiar la divisa en cualquier momento.
          <br />
          <br />
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}> Pago </b>
          <br />
          <br /> El pago será pagadero en el momento de la compra de una licencia de suscripción. Nos hemos asociamos
          con PayPal y Stripe para procesar sus pagos. No recibimos ni procesamos ninguna información de tarjeta de
          crédito u otra forma de pago que proporcione a estos proveedores de servicios.
          <br />
          <br /> Todos los Servicios son pagados por adelantado para el período seleccionado (mensual, anual u otro) y
          no son reembolsables. Esto incluye cuentas que se renuevan.
          <br />
          <br /> -----
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>Aviso para los usuarios de California </b>
          <br />
          <br /> Si es residente de California, la Sección 1789.3 del Código Civil de California le permite solicitar
          información con respecto a la divulgación de sus Datos Personales a terceros para fines de marketing directo
          de terceros. La Ley de Privacidad del Consumidor de California que entrará en vigor en el año 2021 (Código
          Civil, sección 1798.100 a 1709.198) también le otorga ciertos derechos:
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            (a) derecho a saber qué datos personales se recopilaron acerca de usted y recibir copias,
            <br />
            <br />
            (b) derecho de eliminación,
            <br />
            <br />
            (c) derecho a saber a qué terceros se habrían vendido sus Datos Personales y derecho a solicitar que dicha
            venta cese y
            <br />
            <br />
            (d) derecho a no ser discriminado en caso de que usted ejerza algunos de sus derechos de consumidor.
          </span>
          <br /> Además, la Sección 22581 del Código de Negocios y Profesiones de California permite actualmente a los
          residentes de California menores de edad solicitar la eliminación del contenido que han publicado públicamente
          en el Sitio web. Debe observar, sin embargo, que GlobalEnglish no puede garantizar la eliminación completa o
          total del contenido que ha publicado y que puede haber circunstancias bajo las cuales la ley no requiere o
          permite la eliminación, incluso si se solicitara.
          <br />
          <br />
          Si desea solicitar el ejercicio de sus derechos:
          <br />
          <br /> El proveedor del Sitio web y los servicios asociados es GlobalEnglish Corporation, ubicada en 1875
          South Grant Street, Suite 700, San Mateo, CA 94402, Estados Unidos. La dirección de correo electrónico es:
          privacy@globalenglish.com.
          <br />
          <br />A partir de la fecha de las presentes Condiciones, el Sitio web no impondrá cargos directos al
          consumidor.
          <br /> Si tiene alguna queja de los servicios proporcionados en el Sitio web o desea solicitar más
          información, póngase en contacto con nosotros en la dirección física anterior con una descripción de su
          solicitud. No podemos garantizar una respuesta en un período de tiempo determinado. Si permanece insatisfecho,
          póngase en contacto con la Complaint Assistance Unit of the Division of Consumer Services of the California
          Department of Consumer Affairs (Unidad de Asistencia de Quejas de la División de Servicios al Consumidor del
          Departamento de Asuntos del Consumidor de California) por escrito en 1625 North Market Blvd., Suite N 112,
          Sacramento, CA 95834, o por teléfono al (916) 445-1254 o al (800) 952-5210.
        </p>
      </div>
    </div>
  );
}

export default EsEsContent;
