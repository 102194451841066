// @ts-nocheck
import React from 'react';

function ZhCnContent() {
  return (
    <div id="agreement_container">
      <div style={{ padding: '1% 5% 0 5%' }}>
        <p style={{ fontSize: '18px', fontFamily: 'Georgia, "Times New Roman", Times, serif', textAlign: 'center' }}>
          <b>用户协议 </b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"' }}>
          <b>简介</b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          欢迎使用 GlobalEnglish 服务，无论是使用网站还是移动应用程序（统称为“服务”）。
          <br />
          <br />{' '}
          我们的服务为英语学习工具以及在线解决方案，包括但不限于数据、文本、在线测试、设计、视频、音频片段、录音、撰写博文和评论、脚本、图形、图片及其选取和排列、软件、教程、学习班、指导、培训、通过网站和移动应用程序生成或可以访问的交流和互动功能（统称为“内容”）。访问网站阅读有关我司的信息，询问服务均为该服务的一部分。
          <br />
          <br /> 请仔细阅读下列内容，在继续之前确认您理解并同意该条款。
          <br />
          <br />
          <b>
            如果您未满 18
            周岁，请务必与您的家长或监护人一同阅读此协议，并在使用我们的服务之前获得他们的同意。您使用我们的服务，则表示您已年满
            18 周岁或已征得家长或监护人的同意，可与 GLOBALENGLISH 签订此合同。
          </b>
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"georgia"', textAlign: 'center', width: '100%' }}>
          <b>一般条款</b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>1. 法律约束的协议 </b>
          <br />
          <br />
          <b>
            {' '}
            您访问我们网站以及使用 GLOBALENGLISH 的服务时，均受到下方用户协议、隐私政策和 COOKIE
            政策（三份文件统称为“使用条款”）的约束。您读取、访问、注册和/或使用 GLOBALENGLISH
            的服务则代表，您已阅读并理解我们的服务的使用条款，同意受此服务条款不可撤销的法律约束，并已达到合法年龄，同意受此服务条款的约束，与
            GLOBALENGLISH 签订受到法律约束的合同。{' '}
          </b>
          <br />
          <br />
          您可
          <a
            href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
            rel="noopener noreferrer"
            target="_blank"
          >
            在这里
          </a>
          获取我们政策概览。这些政策通过引用结合至此“用户协议”或“协议”中，共同构成使用条款。
          <br />
          <br />
          <b>
            请注意，此协议包含您我双方解决相互之间发生索赔方式的规定。具体参见以下免责声明、归责原则以及法律纠纷规定。本协议还包括一项仲裁协议，除少数例外情况外，要求您对我们的索赔提交具有约束力的最终仲裁。本协议规定您只能以个人名义提出主张，而不能以集体诉讼或代表诉讼中的原告或成员提出。您只能以个人名义提出救济（包括金钱救济、禁令救济以及宣告式救济）。
            <br />
            <br /> 如何您在任何时候不同意此协议下的任何条款，请勿使用此服务或进一步浏览我们网站。
          </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>2. 适用范围 </b>
          <br />
          <br /> 此协议适用于我们的服务用户及网站访客。直接我们注册的个人（
          <b>“B2C 交易”</b>），即使是基于试用，以及由或通过用人单位（
          <b>“B2B 交易”</b>）进行注册的个人将被称为
          <b>“用户”，</b>而未注册用户和浏览我方网站的访客被称为
          <b>“访客”</b>。术语
          <b>“用户”</b>还包括教师、教练、学习专家以及访问 GlobalEnglish
          网站为其他用户提供服务的任何其他人。用户和访客均称为“您/贵方”。
          <br />
          <br /> 此协议适用于所有 GlobalEnglish 的服务。
          <br />
          <br />{' '}
          您首次注册为用户及访问我们的服务时，我们可能会要求您通过点击方框“我同意”或提供您的电子签名或任何其他授权方法，重申您同意受到此协议内的条款约束。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>3.使用条款的变更 </b>
          <br />
          <br />
          GlobalEnglish 可能随时会修改使用条款，包括适用于您的服务使用的此协议、Cookie
          政策和隐私政策。此项修改应在通知后立即生效，通知应通过在本服务中发布，向在相关服务帐户（如适用）或其他方式中提供的电子邮件地址发送电子邮件通知。如果您通过用人单位或公司注册了我们的服务，用人单位或公司应向您通知使用条款的变更。
          <br />
          <br />{' '}
          如果您反对变更，则可以关闭帐户并停止访问我们的网站。然而，在我们发布或发送有关条款变更通知后，您继续使用我们的服务，则被视为您接受使用条款的修改。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>4.第三方使用条款 </b>
          <br />
          <br />
          服务可能包括其他网站的链接（“第三方网站”）以及文章、照片、文本、翻译、图形、图片、设计、声音、视频、信息，以及其他属于或源于第三方的内容或项目（“第三方内容”）。我们不会对此类第三方网站和第三方内容的准确性、适当性或完整性进行调查、监控或检查，对于通过本网站访问的任何第三方网站或通过本网站发布或提供的任何第三方内容，我们概不负责。
          <br />
          <br /> 包含、链接至或允许使用任何第三方网站或任何第三方内容不表示 GlobalEnglish
          认可或支持这些网站或内容。GlobalEnglish
          保留随时关闭这些链接而不另行通知的权利。服务中对任何公司、组织、产品、服务或其他信息的引用，并不构成或暗示
          GlobalEnglish 对这些内容的认可、赞助或推荐，或与之有任何关联。
          <br />
          <br />{' '}
          如果您决定离开我们网站，访问第三方网站，或访问或使用任何第三方内容，您将自行承担风险，并且您应了解我们的条款和政策不再适用于您使用此类第三方网站。在使用之前，请务必查看适用于第三方此类产品和服务的条款和政策。
          <br />
          <br /> 我们通过 Android Market 和 iTunes
          Store（和维护这些平台的实体统称为“供应商”）提供移动应用程序。您使用这些移动应用程序也受到供应商条款与条件的约束，包括但不限于
          App Store 服务条款中规定的使用规则限制。如果您已从 iTunes Store
          下载任何此类移动应用程序，则表示您已确认并同意您已阅读并同意 App Store 服务条款。如果您已从 Android Market
          下载此类移动应用程序，则表示您已确认并同意您已阅读并同意 Android Market
          服务条款。供应商没有义务也不会向您提供与您使用服务相关的任何维护或支持服务。供应商不会对服务提供任何形式的保修条款。如果任何移动应用程序未能符合任何适用的保修条款，而您已从
          iTunes Store 下载此类应用程序，您可以通知 Apple，Apple
          将退还此类应用程序的购买价格（如有）。这些条款适用于您使用所有移动应用程序及我们的其他服务。
          <br />
          <br /> 您承认并同意，GlobalEnglish
          对这些第三方网站或应用程序的内容、产品、服务或性能概不负责。您不可撤销地放弃针对 GlobalEnglish
          与第三方网站或应用程序相关的任何索赔。特别是，GLOBALENGLISH 对于您或其他方以任何方式放置在您计算机中的
          COOKIE、网站信标、透明影像图档、或其他代码不承担任何责任，包括网站内容与此类代码的任何关联。例如，如果您通过网站上的链接访问第三方网站，您的浏览器或计算机上的其他
          cookie 可能会导致向网站发送网址标头，表明您的一些浏览历史记录或您访问的网址。GlobalEnglish
          对任何此类传输概不负责，无论该传输是否包含与 GlobalEnglish 相关或源自 GlobalEnglish的信息。
          <br />
          <br />
          如果您使用 GOOGLE 提供的翻译：GOOGLE
          不承担与翻译有关的所有明示或暗示的担保，包括对准确性、可靠性、适销性及针对特定用途的适用性和非侵权的任何暗示担保。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>5. 服务资格标准 </b>
          <br />
          <br />
          <b>5.1 保护儿童隐私及使用我们服务的限制。 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            本服务不适用于访问本服务所在国家/地区的最低年龄的任何人。例如，除非特定国家/地区法律另有规定，欧盟，欧洲经济区和瑞士的“最低年龄”均为
            16 周岁。在美国，最低年龄则为 13
            周岁。禁止未满最低年龄的任何人注册、使用或访问本服务，并构成违反这些条款的行为。
            <br />
            <br /> 使用服务，则表明并保证您已年满 13 周岁或以上。
          </span>
          <br />
          <br />
          <b>5.2学校和教师 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            希望 13 周岁以下的学生创建 GlobalEnglish
            帐户的美国学校、校区或教师同意他们有责任遵守美国儿童在线隐私保护法（“COPPA”），并在适用范围内遵守《家庭教育权和隐私法》（“FERPA”）。这意味着学校、校区或教师必须将
            GlobalEnglish
            要收集的个人身份信息通知学生的家长或监护人，并在学生创建帐户和使用本服务之前，获得家长/监护人的同意。获得同意后，学校、校区和教师必须向家长/监护人提供我们的隐私政策副本，将所有同意书存档，并在我们需要时提供给我们。有关遵守
            COPPA 的更多信息，请参见
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"
              rel="noopener noreferrer"
              target="_blank"
            >
              联邦贸易委员会的网站。
            </a>
            <br />
            <br />{' '}
            如果您在美国境外，我们将依靠您获得类似法律所涵盖的任何学生的家长/监护人所需的同意或批准，作为您和您的学生使用我们服务的条件，您同意有责任遵守此类法律。
          </span>
          <br />
          <br />
          <b>5.3 家长同意书 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            <b>
              您使用我们的服务，则表示您已年满 18 周岁或已征得家长或监护人的同意，可与 GLOBALENGLISH 签订此合同。
              <br />
              <br /> 我们希望儿童使用本服务只能在其家长、监护人和/或授权学校官员的指导、监督和同意下进行。GlobalEnglish
              依靠家长、监护人和学校来确保未成年人只有在了解了本使用条款中规定的权利和责任的情况下才可使用本服务。
              <br />
              <br /> GlobalEnglish 可自行决定拒绝向任何个人或实体提供本服务并可随时更改其资格标准。
            </b>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>6.隐私 </b>
          <br />
          <br />
          <b>6.1 隐私声明 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            请查看
            <a
              href="https://globalenglish.com/company/about-us/legal/globalenglish-privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              此处
            </a>
            提供的隐私政策。
            <br /> 我们将根据您在首次访问服务时同意的隐私政策，或作为隐私政策定期更新的一部分来处理您的个人资料。{' '}
          </span>
          <br />
          <br />
          <b>6.2 您提供的资料。 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            要使用本服务，您必须拥有一个有效的帐户。要注册我们的服务，您必须向 GlobalEnglish 提供用户
            ID、电子邮件地址和其他被视为个人身份信息（“个人资料”）的信息。我们向您收集的一些信息是 GlobalEnglish
            提供本服务所必需的信息，或甚至仅为了向您发送您一直查询的本服务信息，其他信息则为可选信息。如适用，本服务还允许您显示有关您自己和组织的信息。
            <br />
            <br /> 您同意：(i) 按照本服务内注册表格的提示，在 B2B
            交易情况下，提供有关您和组织的准确、最新和完整的信息；(ii) 对您的密码及与帐户安全相关的其他信息保密；(iii)
            维护并及时更新您向 GlobalEnglish 提供的个人资料和任何其他信息，确保此类信息的准确、最新和完整；并 (iv)
            对您帐户的所有使用和通过您的帐户执行的任何操作承担全部责任。
            <br />
            <br />
            GlobalEnglish 不负责验证您为注册提供的个人资料的准确性。{' '}
          </span>
          <br />
          <br />
          <b>6.3 个人资料的国际传输 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            为了提供本服务，GlobalEnglish
            依靠其遍布全球的工作人口，利用第三方供应商和托管合作伙伴，提供存储和其他技术相关的教学服务。因此，您的个人资料将经过处理并传输至在不同国家/地区的
            GlobalEnglish 员工和分包商，其中有些国家/地区可能无法提供在您的国籍或居住国的个人资料同等水平的法律保护。
            <b>
              通过访问、注册和/或使用我们的服务，您同意将您的个人资料传输至
              GlobalEnglish、其附属公司和分包商所在的各个国家/地区并进行处理。
            </b>
          </span>
          <br />
          <br />
          <b>6.4 通知和消息。</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            您了解并同意服务可能包含 GlobalEnglish
            的某些通信，如服务公告、管理消息等，并且这些通信将视为本服务的一部分，您无法选择不接收这些通信。
            <br />
            <br /> 据此，您同意 GlobalEnglish 可通过以下方式向您发送通知和消息：(i) 本服务内发送；(ii) 通过您向
            GlobalEnglish 提供的联系方式（如电子邮件或手机号码）发送。您同意随时更新您的联系方式。
          </span>
          <br />
          <br />
          <b>6.5 他人隐私 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            如果您在使用我们服务过程中获得了其他用户的相关信息，您同意仅将此信息用于提供给您的目的。出于与本服务无关的目的，您不得向第三方披露、出售或分发用户信息。除非获得特定用户的同意，否则您不得将该信息用于营销目的。
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>7. 用户许可 </b>
          <br />
          <br />
          <b>7.1 使用我们服务的许可 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            您被授予有限、个人、非独占、不可转让、非可再授权且不可转让的许可，仅用于非商业性个人用途的访问和使用本服务。GlobalEnglish
            可自行决定暂时或永久终止本服务或随时更改本服务的功能，恕不另行通知。
            <br />
            <br />{' '}
            要使用本服务，您必须直接或通过访问基于网站内容的设备访问万维网。此外，您必须拥有连接至万维网所需的所有设备，包括计算机和调制解调器或其他访问设备。您同意您必须评估并承担与使用服务相关的所有风险，包括对其所提供内容的准确性、完整性或有用性的任何依赖。GlobalEnglish
            及其许可方均不保证任何内容的准确性或完整性。
            <br />
            <br />
            本服务的设计或许可不适用于危险环境（例如驾驶时、在水中或水周围）。
            在禁止使用本服务的情况下，或在提供、销售本服务或提供与任何适用法律、法规或规章相冲突的情况下，将撤销对本服务的访问权。
            <br />
            <br />
            将本服务用于“使用条款”以外的任何目的，均违反了“使用条款”以及 GlobalEnglish
            和/或许可方及分包商的版权和所有权。如果 GlobalEnglish 有合理理由怀疑您违反这些“使用条款”使用本服务，则
            GlobalEnglish 有权暂停或终止您的帐户，并拒绝为您提供任何及所有当前或未来的服务。您同意，GlobalEnglish
            对于本服务或服务任何部分的任何修改、暂停或中止概不负责。
          </span>
          <br />
          <br />
          <b>7.2 用户内容</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            本服务为您和其他用户提供了创建数据和信息的机会（例如，通过提供对我们测试的响应）以及上传基于社区的服务的材料（“用户内容”）。根据这些条款授予我们的权利，您保留对用户内容的完全所有权。
            <br />
            <br /> 通过上传任何用户内容至我们的服务，您授予 GlobalEnglish
            不可撤销、永久、非独占、可转让、完全支付、全球许可（带转授权），如有需要，有关服务提供，可存储、使用、复制、公开展示、公开显示、修改、翻译、摘录（全部或部分）、处理和散布此类用户内容：
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) 遵守法律程序，包括但不限于回应法律要求、司法命令和其他类似的政府要求；
              <br />
              (b) 执行本协议；
              <br />
              (c) 回应声称任何内容侵犯了第三方的权利；
              <br />
              (d) 保护 GlobalEnglish、其员工、用户和公众的权利、财产和人身安全。
            </span>
            <br />
            <br />{' '}
            如果您选择与本服务的其他用户共享任何用户内容，则同意允许这些用户查看您的用户内容，并在适用范围内与您就此类用户内容进行协作。
            <br />
            <br /> 请注意，GlobalEnglish
            没有义务随时对此类用户内容进行筛选、审核、验证准确性、更正或删除。然而，GlobalEnglish
            保留对用户内容的监控权，并在绝对酌情权内，删除其认为具有攻击性或违反使用条款的内容。
            <br />
            <br /> GlobalEnglish
            没有义务对用户内容进行备份或存储。您需自行承担创建备份副本并替换您在网站上发布或存储的或向 GlobalEnglish
            提供的任何用户内容的费用。
            <br />
            <br /> 此用户内容不一定反映 GlobalEnglish
            的观点。您了解用户内容将由创建此类用户内容的用户单独负责。您了解通过使用本服务，可能会接触到攻击性、不雅或令人反感的用户内容。
            <br />
            <br /> 除了对上述用户内容授予许可外，您承认并同意，您向 GlobalEnglish
            提供的有关本服务的任何问题、评论、建议、想法、反馈或其他信息（“提交内容”）均为非机密信息，GlobalEnglish
            有权以任何目的（商业或其他方式）无限制地使用并传播这些提交内容，不需要经过您的同意或对您进行补偿。此外，您在
            GlobalEnglish
            基于社区的服务或产品上或通过该等服务或产品发布、披露或共享的信息不属于机密信息的定义，因该术语通常已定义了。
            <br />
            <br /> 您了解网站的技术处理和传输，包括您的用户内容，可能涉及 (a) 通过各种网络传输；以及 (b)
            以符合并适应连接网络或设备的技术要求的更改。
            <br />
            <br />
            <b>
              GlobalEnglish 不对用户内容作出任何声明或保证。此用户内容不一定反映 GlobalEnglish
              的观点。在任何情况下，GlobalEnglish
              均不对用户内容或因任何原因或因使用或丢失而导致的任何索赔，损坏或损失承担任何责任或义务。
            </b>
          </span>
          <br />
          <br />
          <b>7.3 必要的用户行为</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            {' '}
            对您帐户下发生的所有行为负全部责任。您同意：
            <br />
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) 任何未经授权使用您的帐户或任何其他违反安全规定的行为，请立即通知 GlobalEnglish，并
              <br />
              (b) 确保在每次会话结束时退出帐户。
            </span>
            <br />
            <br />
            GlobalEnglish 不能也不会对因您未能遵守而造成的任何损失或损坏负责
            <br />
            <br />
            您全权负责确保这些服务条款符合适用于您的所有法律、法规，且在提供、销售本服务或提供与任何适用法律、法规或规章相冲突的情况下，将撤销对本服务的访问权或禁止使用本服务。
          </span>
          <br />
          <br />
          <b>7.4 禁止的用户行为 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            以下是 GlobalEnglish 非法或禁止使用的一些示例。在 GlobalEnglish 的绝对酌情权内，GlobalEnglish
            保留对任何人进行调查并采取适当法律措施的权利，包括但不限于从网站或应用程序中删除不当内容，暂停或终止此类用户的帐户，并向执法当局检举。
            <br />
            <br /> 您不可以：
            <br />
            <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
              (a) 除非经 GlobalEnglish 特别授权，不得对本服务中任何 GlobalEnglish
              的内容或其他材料进行改编、下载、修订、广播、逆向工程、复制、发布、修改、散播、显示、传输或以其他方式复制和/或分发。
              <br />
              (b) 建造或利用框架技术来封闭本服务的任何部分；
              <br />
              (c)
              通过使用任何机器人程序、爬虫程序、网络爬虫、间谍软件、引擎、设备、软件或任何其他自动设备、实用程序或任何类型的手动流程，对服务的任何部分进行收集、获取、使用、访问或以其他方式进行复制；
              <br />
              (d) 以任何方式使用本服务或本服务中的任何可用功能，以中断、损坏、禁用、覆盖或损害本服务；
              <br />
              (e) 出售、许可、出租或以其他方式将本服务用于商业目的；或
              <br />
              (f) 参与任何干扰其他用户访问、使用或享用本服务的活动。
              <br />
              (g) 发布、以电子邮件发送或以其他方式传输以下任何用户内容：(i)
              非法、有害、威胁性、辱骂、骚扰、侵权、过度暴力、诽谤、低俗、淫秽、不雅、色情、损害他人名誉、侵犯他人私隐（包括但不限于任何地址、电子邮箱、手机号码以及其他联系信息）、具种族仇恨、民族仇恨或其他令人不快的用户内容；(ii)
              根据任何法律或根据契约关系或信托关系，您无权传输的用户内容；(iii)
              对任何人构成或造成隐私或安全风险的用户内容；(iv) 侵犯任何一方的任何知识产权或其他专有权利的用户内容；(v)
              构成来路不明或未经授权的广告、宣传资料、商业活动和/或销售、“垃圾邮件”、“垃圾信息”、“连环信”、“非法传销”、“竞赛”、“彩票抽奖”或任何其他形式的业务招揽的用户内容；(vi)
              包含旨在中断、毁坏或限制任何计算机软件、硬件或电信设备功能的软件病毒或任何其他计算机代码、文件或程序的用户内容；或
              (vii) 根据 GlobalEnglish 自身判断，会引起异议或限制或阻碍任何其他人使用或享用服务，或可能让 GlobalEnglish
              或其用户承担任何损害或任何类型的责任的用户内容；
              <br />
              (h) 制造虚假证明、歪曲您的身份、冒充任何人或实体，或虚假陈述或以其他方式歪曲您与他人或实体的关系；
              <br />
              (i) 向未满 18 周岁的任何人索取个人信息；
              <br />
              (j) 以任何方式伤害未成年人；
              <br />
              (k) 对任何团体或个人造成人身伤害或伤亡，“跟踪”或以其他方式骚扰任何人；
              <br />
              (l)提供或宣传有关非法活动的教学信息，例如，关于如何装配炸弹、手榴弹以及其他武器或引爆装置的说明；
              <br />
              (m)
              通过使用自动脚本或以其他方式，用于任何目的从网站收获或收集网站内容或其他用户的电子邮件地址、联系信息或其他用户的私人信息，包括但不限于向用户发送来路不明的电子邮件或者进行其他来路不明的沟通，或复制内容网站；
              <br />
              (n) 为了任何未特定授权的商业目的，进行宣传或提供出售或购买任何商品或服务；
              <br />
              (o) 干扰或破坏网站或连接至网站的服务器或网络，或违反任何连接至网站的网络要求、程序、政策或法规；
              <br />
              (p)
              违反任何适用的地区、州、国家或国际法，或任何具有法律效力的法规；促进或者推动任何犯罪活动或企业，或提供违法活动相关的指导性信息；
              <br /> (q) 通过本网站无意使用或提供的方式，获取或试图访问或以其他方式获取任何材料或信息；
              <br />
              (r) 覆盖安全功能或略过或规避任何访问控制；或
              <br />
              (s) 以可能造成利益冲突或破坏本服务目的的方式使用本服务，例如与其他用户进行交易评论或撰写或征求尖刻评论。
            </span>
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>8. 知识产权</b>
          <br />
          <br />
          <b>8.1 GlobalEnglish 的知识产权</b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            本服务的全部内容，包括其内容和任何支持软件，均为
            GlobalEnglish、其许可方或分包商的专有财产，受美国和国际版权及其他知识产权法的保护。
            <br />
            <br /> 未经 GlobalEnglish
            和/或其许可方的明确书面同意，除下文规定以外，严禁复制、再分发、修改或发布本服务的任何部分。
            <br />
            <br />{' '}
            授权用户可以访问及使用本服务和内容，下载或打印用户已正确获取访问权限的内容部分的合理数量副本，仅供用户个人用于语言培训目的，前提是用户对此类内容的所有副本保留所有版权或其他所有权声明。
            <br />
            <br /> 除非另有说明，本服务中出现的商标均为
            GlobalEnglish、其许可方或其附属公司的商标。您同意不展示、贬低或玷污本商标，也不使用任何容易混淆的类似商标，或以歪曲此类商标所有权的方式使用这些商标，或造成
            GlobalEnglish 认可任何产品或服务的印象。未经 GlobalEnglish 事先书面许可，您不得复制或使用 GlobalEnglish
            的商标、商业名称和/或商业外观。使用 GlobalEnglish 商标产生的所有商誉均属于我们的独家收益。
            <br />
            <br />{' '}
            禁止使用协议特定授权以外的网站、应用程序或内容，将自动终止所授予您的使用本服务与本内容的权利。GlobalEnglish
            及其许可方保留未在本条款中未明确授予的其所有权利。
          </span>
          <br />
          <br />
          <b>8.2 第三方商标 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            通过网站使用和展示的产品和服务的名称和商标，除上述 GlobalEnglish
            商标外，可以是各自所有者的商标或服务商标，其所有者可能认可或不认可 GlobalEnglish，也可能与 GlobalEnglish
            有关联或连接。未经我们事先书面许可，本条款或网站上的任何内容均不得解释为以暗示、禁止反悔或其他方式授予使用本网站显示的任何商标的任何许可或权利。使用
            GlobalEnglish 商标产生的所有商誉均属于我们的独家收益。
          </span>
          <br />
          <br />
          <b>8.3 根据数字千年版权法提出的版权控诉 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            如果您对其他人在本服务上发布的任何资料有任何版权问题，请告知我们。
            <br />
            <br /> GlobalEnglish 将处理并调查涉嫌侵权的通知，并根据“数字千年版权法”
            （“DMCA”）和其他适用知识产权法，对任何涉嫌或实际侵权行为采取适当措施。
            <br />
            <br />
            声明版权侵权的通知应通过电子邮件发送至 GlobalEnglish 的版权代理机构
            copyright@globalenglish.com（主题行：“DMCA 撤除请求”）。
            <br />
            <br /> 您也可以通过电子邮件联系我们：
            <br />
            <b>联系人：版权控诉/法律部 </b>
            <br /> 1875 South Grant Street, Suite 700
            <br /> San Mateo, CA 94402 USA
            <br />
            <br />
            <br /> 仅书面通知有效，并应包含以下信息：(i)
            获授权代表版权所有人或其他知识产权利益相关人的电子或亲笔签名；(ii)
            您声称遭到侵权的受版权保护作品或其他知识产权的描述；(iii)
            描述您声称侵权的材料在本服务中的位置，并在我们网站中可找到该材料的详细资料；(iv) GlobalEnglish
            可联系到您的充分信息（地址、电话号码和电子邮件地址）；(v)
            您声明确信所争议的使用未经版权或知识产权所有人、其代理或法律授权；(vi)
            您以版权或知识产权所有人或授权代表版权或知识产权所有人的名义声明，上述通知信息正确真实，否则将承担作伪证的责任。
            <br />
            <br /> 根据 DMCA 和其他适用法律，在适当情况下 GlobalEnglish
            自行决定采取政策终止被视为屡次侵权者的用户。GlobalEnglish
            还可自行决定限制访问本网站和/或终止任何侵犯他人知识产权的用户的会员资格，无论是否存在任何重复侵权。
          </span>
          <br />
          <br />
          <b>8.4 删除涉嫌侵权的用户内容 </b>
          <br />
          <span style={{ paddingLeft: '18px', display: 'inline-block' }}>
            如果 GlobalEnglish
            删除了一些涉嫌侵犯第三方知识产权的用户内容，并且您认为您删除的用户内容（或禁用的访问权限）未侵权，或您获得版权所有人、版权所有人代理的授权，或根据法律不构成侵权，要发布和使用您的用户内容中的内容，您可以向版权代理机构发出包含以下资料的书面异议通知：(i)
            您的电子或亲笔签名；(ii) 识别已删除或已禁用访问的内容，以及在删除或禁用之前显示内容的位置；(iii)
            声明您确信该内容因错误或错误识别内容而被删除或禁用；以及 (iv)
            您的姓名、地址、电话号码和电子邮件地址，您同意加利福尼亚州北部地区联邦法院管辖权的声明和声明您接受提供涉嫌侵权通知方的传票送达。
            <br />
            <br /> 如果版权代理机构收到异议通知，GlobalEnglish 将向原控诉方发送异议通知副本，通知该人可在 10
            个工作日内替换删除的内容或停止禁用该内容。除非版权所有人提出针对内容提供商，会员或用户寻求法院命令的诉讼，否则在收到异议通知
            10 至 14 个工作日或以上的时间内，将替换已删除的内容，或恢复对该内容的访问，由我们自行决定。
          </span>
          <br />
          <br />
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}> 9. 国际使用和出口管制 </b>
          <br />
          <br />{' '}
          认识到互联网的全球性，您同意遵守有关在线行为和可接受的用户内容的所有当地法规。您同意遵守有关从美国或您所在国家/地区出口的技术数据传输的所有适用法律。
          <br />
          <br />{' '}
          您承认本服务或部分服务可能受美国出口管制法的约束。您不得违反任何适用的出口法律或法规，直接或间接地出口、再出口或转让本服务的任何或任何相关技术信息或材料。您声明并保证您不是受美国政府禁运限制的国家或被美国政府指定为“支持恐怖主义”的国家的居民，也并未列载于美国政府任何禁止或受限人名单中。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>10. 禁令救济</b>
          <br />
          <br /> 您承认任何违反“使用条款”使用本服务均可能对
          GlobalEnglish、其附属公司、许可方、分包商、代理商和用户造成难以弥补的损害。在这种情况下，GlobalEnglish
          及其附属公司、许可方、分包商、代理商和用户将有权获得公平的救济，无需缴纳保金或其他担保，包括但不限于初步和永久禁令救济。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>11. 服务停止时间 </b>
          <br />
          <br /> 出于维护或其他原因，服务可能会不时暂时不可用。GlobalEnglish
          对用户通信或通过网站获取的任何其他内容的任何中断，延迟操作或传输，盗窃或破坏，未经授权的访问或更改不承担任何责任。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>12. 免责条款 </b>
          <br />
          <br /> 出于维护或其他原因，服务可能会不时暂时不可用。GlobalEnglish
          对用户通信或通过本服务获取的任何其他内容的任何中断，延迟操作或传输，盗窃或破坏，未经授权的访问或更改不承担任何责任。在任何情况下，GlobalEnglish
          均不对因使用本服务、任何用户内容或第三方内容、或使用用户提供的任何产品或服务而造成的任何人身伤害或死亡承担任何责任。
          <br />
          <br /> 您了解并同意：
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            {' '}
            (a) 本服务，包括本服务中的所有内容，均按“现状”提供并可用。
            <br />
            <br /> (b) GLOBALENGLISH
            拒绝就服务作出任何及所有明示或暗示的声明和保证，包括对所有权，适销性，特定用途的适用性或非侵权性的暗示保证。
            <br />
            <br /> (c) GLOBALENGLISH 既不保证也不承诺使用本服务的任何具体结果及本服务将满足您的要求或期望。
            <br />
            <br />
            (d) GLOBALENGLISH
            不声明或保证本服务或网站上的软件、内容或材料准确、完整、可靠、最新或无误，或者本网站或本服务或其服务器，没有病毒或其他有害组件。因此，在不限制上述规定的情况下，您了解并同意，您经或通过服务或经基于您自己判断并自承风险的提供方，下载或以其他方式获取内容、材料、数据或软件，对于您的使用以及可能对您的电脑系统造成的任何损害、数据丢失或其他类型的危害，您将承担全部责任。
            <br />
            <br /> (e) GLOBALENGLISH 不保证服务中的任何错误或缺陷都会得到纠正。
            <br />
            <br />
            (f) 对于用户、第三方内容和用户内容的行为， GLOBALENGLISH 概不负责。
            <br />
            <br />
            (g) 出于维护或其他原因，服务可能会不时暂时不可用。GlobalEnglish
            对用户通信或通过本服务获取的任何其他内容的任何中断，延迟操作或传输，盗窃或破坏，未经授权的访问或更改不承担任何责任。在任何情况下，GlobalEnglish
            均不对因使用本服务、任何用户内容或第三方内容、或使用用户提供的任何产品或服务而造成的任何人身伤害或死亡承担任何责任。
          </span>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>13. 责任限制</b>
          <br />
          <br /> 在任何情况下，对于您或任何第三方因任何间接、后果性、惩戒性、偶然性、特殊性或惩罚性的损失，GLOBALENGLISH
          或其董事、员工、代理、许可方或分包商均概不负责，包括因以下原因导致的任何利润损失或丢失数据：(I)
          您使用或无法使用本服务，或通过访问网站、服务或提供方或从中下载的任何内容或其他材料；(II)
          通过或经网站，购买或获取的任何商品、数据、信息或服务，收到的信息，进行的交易，而产生的替换商品或服务的购买成本；(III)
          任何一方未经授权访问或更改您的传输或数据；(IV) 网站上任何第三方的声明或行为；或 (V)
          与本服务相关的任何其他事项。在任何情况下，对于所有损害、亏损或诉因，GLOBALENGLISH
          对您的总负债不能超过您在过去十二 (12) 个月中支付给 GLOBALENGLISH
          的数额，否则，如果超出这个数额，也不得超过一百美元 ($100)，尽管 GLOBALENGLISH
          了解到或已被告知此类损失的可能。此协议下存在超过一项索赔时，不会增加此限额。
          <br />
          <br />{' '}
          某些司法管辖区不允许排除某些保证或限制，或排除偶然性或结果性损失的赔偿责任。因此，上述某些限制可能不适用于您。
          <br />
          <br /> 如果您对本网站的任何部分或这些网站条款不满意，您唯一可用的补救措施即是停止使用本网站。
          <br />
          <br /> 在任何情况下，对于因使用服务、第三方内容或用户内容而造成的人身伤害或死亡，GLOBALENGLISH 概不负责。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>14. 诉讼时效 </b>
          <br />
          <br />
          您同意，无论任何诉讼或法律是否有其他规定，因您使用服务而产生的或与您使用服务相关的针对 GlobalEnglish
          提出的任何索赔或诉讼，须在该等索赔或诉讼发生后一 (1) 年内提出申请，否则将永久禁止申请。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>15. 补偿 </b>
          <br />
          <br />
          您同意，因您使用本网站、服务、您的用户内容、您连接本网站或服务、您违反本条款或您侵犯其他用户的任何权利或违反任何法律，或因您使用本服务而产生的其他不当行为导致任何第三方对
          GlobalEnglish 发起的任何及所有索赔、诉讼、费用、债务、损失、损害赔偿、费用，包括合理的律师费用，您应免除
          GlobalEnglish 及其附属公司和人员、员工、主管和代理之责任，为其补偿并使其免受损害。您的补偿义务包括但不限于对
          GlobalEnglish 提出的任何用户内容侵犯第三方知识产权的索赔。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>16. 终止使用 </b>
          <br />
          <br /> 如果您的订阅由于任何原因而终止，GlobalEnglish
          将禁用您对本服务的访问。您将无法访问任何内容，包括用户内容，但此类数据副本将在我方系统中保留三 (3)
          年作为备份。
          <br />
          <br /> 如果您要求 GlobalEnglish 删除您的个人数据和用户内容，我方将尽一切合理努力删除，并确认删除时间。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>17. 弃权和可分割性 </b>
          <br />
          <br /> 如果 GlobalEnglish 未能行使或执行本条款的任何权利或规定，不应构成在该情况下及任何其他情况下
          GlobalEnglish
          对此类权利或规定的放弃。如果经确定本条款的任何规定非法、无效或因任何原因无法执行，该等确定不影响任何其他规定的有效性和可执行性。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>18. 完整协议</b>
          <br />
          <br /> 本协议构成您与 GlobalEnglish 之间关于使用服务的完整协议，并取代先前您与 GlobalEnglish
          之间就使用服务达成的任何理解和协议。
          <br />
          <br /> 尽管有前述规定，如果贵公司已与 GlobalEnglish 就购买任何服务达成现有协议的，本条款不会改变贵公司与
          GlobalEnglish、其子公司或附属公司已达成的此类协议的条款或条件，若条款发生冲突，以该协议的条款为准。
          <br />
          <br />{' '}
          本协议采用英语编写，该版本在所有方面均占控制主导地位，本协议的任何非英语版本仅用于调解目的。本协议的印刷版本及任何以电子形式发出的通知印刷版本，可用于基于本协议或与本协议有关的司法或行政程序，且须符合与其他业务文件及记录相同的条件，而该等文件及纪录最初以打印形式生成并维护。本条款中的章节标题仅为方便而设，不具有法律或合同效力。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>19. 法律、法庭和仲裁选择 </b>
          <br />
          <br />
          本条款应受加利福尼亚州法律管辖，不考虑其法律条款的冲突。
          <br />
          <br /> 根据 GlobalEnglish
          的选择及自行决定，所有因本条款或网站引起的或与本条款或网站相关的纠纷、索赔或争议如未能通过双方协议解决的，可在JAMS或其继任者面前，通过有约束力的仲裁来解决。除非各方当事人另行商定，仲裁将于双方共同商定的一名仲裁员面前，在加利福尼亚州旧金山进行，或如果双方当事人不同意由
          JAMS 指定一名仲裁员，则按照 JAMS
          颁布的法规法规进行，除非专门修改本条款。仲裁必须于任何一方提出书面仲裁申请之日起四十五 (45)
          天内开始。仲裁员应在仲裁结束后六十 (60) 天内以及选定仲裁员后六 (6)
          个月内作出裁决和判定并传达。仲裁员没有权力根据条款中规定的实际补偿、间接损害判定超过限制的损失，不得增加实际损失或判定惩罚性损失，或任何条款明确排除的其他损失，各方特此永久放弃任何对此类损失的索赔。仲裁员可自行决定对任一诉讼方的费用和开支（包括胜诉方的合理法律费用和开支）进行评估。任何一方拒绝遵守仲裁员的命令，将承担另一方在执行裁决过程中产生的费用和开支，包括律师费。尽管有前述规定，在临时或初步禁令救济的情况下，未经事先仲裁，任何一方不得在法庭上进行诉讼，避免直接且不能挽回的损害。本仲裁章节的规定在任何拥有有效管辖权的法院均可强制执行。
          <br />
          <br /> 对于上文所述无需仲裁的任何争议或索赔，您和 GlobalEnglish
          同意遵守加利福尼亚州旧金山郡的州法院和联邦法院的个人及专属管辖权。
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '12px', fontFamily: '"calibri"' }}>20. 通知和联系方式 </b>
          <br />
          <br />
          您可通过链接
          <a href="https://support.globalenglish.com/" rel="noopener noreferrer" target="_blank">
            https://support.globalenglish.com/
          </a>
          中的“联系我们”表格向 GlobalEnglish 发送终止通知，要求终止您的 GlobalEnglish
          服务帐户。您的帐户终止后，GlobalEnglish 可随时阻止您访问和使用服务，包括其中的任何内容。
          <br />
          <br />{' '}
          我们将通过电子邮件或普通邮件通知您。本网站亦可通过在本服务上显示的一般通知或通知链接，向您提供有关本条款或其他事项的变更通知。
          <br />
          <br /> 有关我方隐私政策的通知可发送至
          <a href="mailto:privacy@globalenglish.com" rel="noopener noreferrer" target="_blank">
            privacy@globalenglish.com
          </a>
          。
          <br />
          <br /> 所有发送给 GlobalEnglish 的其他法律通知或其他通信须发送至以下电子邮件地址:
          <a href="mailto:legal@globalenglish.com" rel="noopener noreferrer" target="_blank">
            legal@globalenglish.com,
          </a>{' '}
          或以下物理地址：
          <br />
          <br /> 1875 S Grant Street, Suite 700
          <br /> San Mateo, CA 94402
          <br />
          <b>提请法律顾问注意</b>
          <br />
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>B2B 交易的特定术语</b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          {' '}
          若您通过您的雇主获得您的 GlobalEnglish
          帐户，在使用我方服务时，应遵守我方与贵公司（“公司”）的协议约定。“使用条款”不会改变与贵公司的协议条款。若贵公司未授权您成为
          GlobalEnglish 许可的最终用户，无法使用（或继续使用）该服务。如果您要求我方终止您的帐户，在 GlobalEnglish
          终止您的账户之前，需得到贵公司的许可。
          <br />
          <br />{' '}
          您同意，仅在贵公司允许时，方可使用该服务。您将机会通过该服务与贵公司内部人员沟通，或上传与贵公司相关的文件，但未经贵公司授权，您不得、且无法有以上行为。
          <br />
          <br />{' '}
          您承认并同意，贵公司可访问您通过使用该服务生成的所有数据（“用户数据”）。基于使用我方服务产生的与贵公司共享的信息，GlobalEnglish
          不承担任何责任。
          <br />
          <br /> 由于您的访问服务订阅仅在贵公司的许可期内进行，如果贵公司终止其 GlobalEnglish
          的许可，您将无法访问服务和用户内容。如果 (i) 贵公司根据本协议，在 GlobalEnglish 到期时未支付任何应付费用，或
          (ii) 您或贵公司违反本条款或任何管制服务使用的其他法规，GlobalEnglish
          可（全部或部分）终止或暂停贵公司帐户、您的账户或使用服务的能力，恕不另行通知。
          <br />
          <br /> GlobalEnglish
          须向贵公司归还与您使用服务相关的所有数据，包括您的个人数据和用户内容。如果贵公司未要求归还或删除此类数据，GlobalEnglish
          将在与贵公司的合同期满或终止后，将该等数据保存三 (3) 年。此情况下，欢迎您直接从 GlobalEnglish
          订购服务，继续访问课程，且不丢失进度信息。您可授权将您的进度和个人资料信息转至您的新雇主的新订阅中。另外，您可要求将您使用该服务的所有相关数据，按照与后期导入数据相兼容的格式。
        </p>
        <p style={{ fontSize: '14px', fontFamily: '"calibri"', textAlign: 'center', width: '100%' }}>
          <b>B2C 交易的特定术语 </b>
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          您可选择以个人名义与GlobalEnglish 签订订阅协议，协议可包括三 (3) 个月或六 (6)
          个月的订阅，或年度或多年的订阅计划（统称为“订阅计划”）。
          <br />
          <br />
          在您取消订阅计划之前，我司将在每月或每年（视情况而定）的某一天或前后，对您的适用订阅计划按月或按年收取费用。为免生疑虑，请注意，在订阅计划满一周年之前，不得取消、减少订阅名额数量或对您选择的服务进行降级。订阅计划付款后不得退款。
          <br />
          <br /> 服务的相应费用（“费用”）可在网站和/或当前公布的 GlobalEnglish
          价目表中查阅。除另有说明外，服务规定价格不包括所有税费。您须支付使用服务附带的任何税费和所有其他费用（例如，数据费用和货币汇率清算）。费用使用
          GlobalEnglish 向您账户中的报价货币支付。GlobalEnglish 保留随时更改报价货币的权利。
          <br />
          <br />
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}> 付款 </b>
          <br />
          <br />
          购买订阅许可时，即可付款。我们已与 PayPal 和 Stripe
          合作，处理付款。我们不会收到或处理您向这些服务提供商提供的任何信用卡信息或其他付款方式。
          <br />
          <br />
          所有服务皆应于选定时期（每月、每年或其他方式）预付，恕不退款。这包括更新的帐户。
          <br />
          <br /> -----
        </p>
        <p style={{ fontSize: '12px', fontFamily: '"calibri"' }}>
          <b style={{ fontSize: '14px', fontFamily: '"calibri"' }}>加利福尼亚用户须知</b>
          <br />
          <br /> 如果您是加利福尼亚州居民，根据《加利福尼亚州民法》第 1789.3
          条，出于第三方的直接营销目的，您可获取有关向第三方披露您的个人数据的信息。《加利福尼亚州消费者隐私法》（州民法典第
          1798.100 条至第 1709.198 条）将于 2021 年生效，其中规定您享有以下权利：
          <br />
          <br />
          <span style={{ padding: '0 2% 0 2%', display: 'inline-block' }}>
            (a) 有权了解收集您个人信息的对象，可获得信息副本
            <br />
            <br />
            (b) 有权删除，
            <br />
            <br />
            (c) 有权了解购买您个人数据的第三方，及有权要求停止此类销售，
            <br />
            <br />
            (d) 在您行使消费者权利时，享有免受歧视的权利。
          </span>
          <br /> 此外，《加州商业和职业规范》第 22581
          条目前允许未成年加州居民要求删除其在网站上公开发布的内容。但需注意，GlobalEnglish
          无法确保完整或全面删除您发布的内容，而且在某些情况下，即使您提出要求，法律也不要求或不允许删除。
          <br />
          <br />
          如果您想要求行使您的权利：
          <br />
          <br /> 本网站及相关服务提供商为 GlobalEnglish Corporation，地址：1875 South Grant Street, Suite 700, San
          Mateo, CA 94402, USA。电子邮箱地址：privacy@globalenglish.com。
          <br /> 自本条款生效之日起，本网站不对消费者收取任何直接费用。
          如果您对本网站提供的服务有任何不满，或希望得到更多信息，请按照上述地址联系我们，并说明您的要求。我们无法保证在任何特定时间段进行回复。如果您仍不满意，请致信
          1625 North Market Blvd., Suite N 112, Sacramento, CA 95834，或致电 (916) 445-1254 或 (800)
          952-5210，联系加利福尼亚州消费者事务部消费者服务部门投诉协助单位。
        </p>
      </div>
    </div>
  );
}

export default ZhCnContent;
