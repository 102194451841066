// @ts-nocheck
import React from "react";

function PtBrContent() {
  return (
    <>
      <div id="agreement_container" style={{ fontSize: "14px" }}>
        <div style={{ fontSize: "10pt", fontFamily: "arial" }}>
          <center>
            <b>
              Direitos Autorais, Marca Registrada e Outras Orientações Legais
            </b>
          </center>
        </div>
        <div style={{ fontFamily: "arial" }}>
          <p>
            <b>DIREITOS AUTORAIS DA GLOBALENGLISH</b>
          </p>
          <p />
          <p>
            {" "}
            Copyright © 1999-2013 GlobalEnglish Corporation. Todos os direitos
            reservados{" "}
          </p>
          <br />
          <p>
            <b>DIREITOS AUTORAIS DE TERCEIROS</b>{" "}
          </p>
          <p>
            {" "}
            <i>Essential American English Grammar</i>, Primeira edição, 1995
            <br /> © Longman Group UK Limited 1995{" "}
          </p>
          <p>
            {" "}
            <i>Longman Dictionary of Contemporary English</i>
            <br /> © Longman Group Limited 1995{" "}
          </p>
          {/*p> <i>Langenscheidt Pocket Dictionary German</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Japanese</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary French</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Spanish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Italian</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Korean</i><br>&copy;&nbsp; 2008 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Chinese</i><br>&copy;&nbsp; 2007 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Portuguese</i><br>&copy;&nbsp; 2010 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Polish</i><br>&copy;&nbsp; 2003 Langenscheidt KG, Berlin and Munich </p>
  <p> <i>Langenscheidt Pocket Dictionary Turkish</i><br>&copy;&nbsp; 2006 Langenscheidt KG, Berlin and Munich </p*/}
          <p>
            {" "}
            Copyright © Business English Pod Limited Todos os direitos
            reservados. Todo o conteúdo, incluindo podcasts, phrasecasts,
            videocasts, notas de estudo e e-books de áudio, são de propriedade
            da Business English Pod Limited, utilizados com a permissão da
            GlobalEnglish Corporation.{" "}
          </p>
          <p>
            {" "}
            WGrammar Grammar Checker Engine Copyright © 1998-2006 Wintertree
            Software Inc. ({" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.wintertree-software.com"
            >
              <div
                onmouseout="this.className='blu11'"
                onmouseover="this.className='blu11rllover'"
                className="blu11"
              >
                www.wintertree-software.com
              </div>
            </a>
            ){" "}
          </p>
          <p>
            {" "}
            Inclui o software de reconhecimento de fala EduSpeak<sup>®</sup> sob
            licença da SRI International
            <sup>®</sup>. Copyright © SRI International.{" "}
          </p>
          {/*p> Systran Translation Engine &copy; SYSTRAN Software, Inc </p*/}
          <p>
            {" "}
            As fotos fornecidas pela AP/Wide World Photos são protegidas por
            direitos autorais e são de propriedade da Associated Press. É
            proibido o uso desse material sem a autorização prévia da AP/Wide
            World Photos. Qualquer violação estará sujeita à instauração de ação
            legal.{" "}
          </p>
          <p>
            {" "}
            As fotos fornecidas pela PhotoDisc são protegidas por direitos
            autorais e são de propriedade da PhotoDisc, Inc. Images © copyright
            1999 PhotoDisc, Inc.{" "}
          </p>
          <p>
            {" "}
            Copyright © Barron's Educational Series, Inc., de HOW TO PREPARE FOR
            THE TOEIC<sup>®</sup>{" "}
          </p>
          <p>
            {" "}
            <i>Write for Results</i> © 1998 AMACOM NEW MEDIA, subsidiária de
            total propriedade da American Management Association, Nova Iorque.
            Todos os direitos reservados.{" "}
          </p>
          <p>
            {" "}
            O clip-art fornecido pela Microsoft é protegido por direitos
            autorais e é de propriedade da Microsoft Corporation e/ou de seus
            fornecedores. É proibido o uso desse material sem a autorização
            prévia da Microsoft.{" "}
          </p>
          <p>
            {" "}
            O clip-art fornecido pela ArtToday é protegido por direitos autorais
            e é de propriedade da Zedcor, Inc., uma subsidiária totalmente de
            propriedade da IMSI Inc. e/ou de seus fornecedores. É proibido o uso
            desse material sem a autorização prévia da Zedcor.{" "}
          </p>
          <p>
            {" "}
            O clip-art fornecido pela Eyewire é protegido por direitos autorais
            e é de propriedade da Eyewire Inc. Corporation e/ou de seus
            fornecedores. É proibido o uso desse material sem a autorização
            prévia da Eyewire.{" "}
          </p>
          <p>
            {" "}
            As fotografias fornecidas pela Corbis são protegidas por direitos
            autorais e são de propriedade da Corbis Corporation e/ou de seus
            fornecedores. É proibido o uso desse material sem a autorização
            prévia da Corbis.{" "}
          </p>
          <p>
            {" "}
            As fotografias fornecidas pela ImageQuest são protegidas por
            direitos autorais e são de propriedade da ImageQuest e/ou de seus
            fornecedores. É proibido o uso desse material sem a autorização
            prévia da ImageQuest.{" "}
          </p>
          <p />
          <br />
          <p>
            <b>Marcas comerciais da GlobalEnglish</b>
          </p>
          <p />
          <p>
            Estas são algumas marcas comerciais de propriedade da GlobalEnglish
            Corporation e os termos genéricos sugeridos para essas marcas
            comerciais nos Estados Unidos e/ou em outros países. A ausência de
            um nome ou de um logotipo nesta lista não constitui uma dispensa de
            algum ou de todos os direitos de propriedade intelectual que a
            GlobalEnglish Corporation ou suas subsidiárias tenham estabelecido
            para qualquer de seus nomes ou logotipos de produtos, recursos ou
            serviços. Para orientação sobre como se referir adequadamente aos
            nomes de produtos e marcas comerciais da GlobalEnglish, solicite uma
            cópia das diretrizes gerais sobre marcas comerciais da
            GlobalEnglish.
          </p>
          <p />
          <br />
          <style
            dangerouslySetInnerHTML={{
              __html: " \t\ttd{padding:10px !important} \t\t",
            }}
          />
          <table
            border={1}
            cellPadding={10}
            cellSpacing={0}
            bordercolor="#808080"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  TradeMark
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Status
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  Enterprise Fluency
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_v1.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_new.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stacked.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bloom.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Bloom
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_coach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Corporate Learning Service
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_edge.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Edge
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingo.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_lingopro.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish LinGo Pro
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Magazine
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Mobile Learning Network
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Productivity Toolbar
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_step.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_stepplus.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish STEP+
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_motivator.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Motivator
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_executivecoach.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px", color: "#666" }}>
                  GlobalEnglish Executive Coach
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ®
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    alt=""
                    src={require("../../../assets/images/globalenglish_bw.png")}
                  />
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#666",
                  }}
                >
                  ™
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>MARCAS COMERCIAIS DE TERCEIROScomerciais</b>
          </p>
          <p />
          <p>
            {" "}
            Adobe e Flash são marcas comerciais ou marcas registradas da Adobe
            Systems Incorporated nos Estados Unidos e/ou em outros países.
          </p>
          <p> Microsoft é uma marca registrada da Microsoft Corporation. </p>
          {/*p> Langenscheidt KG &#233; uma marca registrada da Langenscheidt KG, Berlin and Munich. </p*/}
          <p>
            {" "}
            Living Language e Colophon são marcas registradas da Random House,
            Inc.{" "}
          </p>
          <p>
            {" "}
            Sipro e o logotipo da Sipro são marcas comerciais da Sipro Lab
            Telecom Inc. ACELP<sup>®</sup> é uma marca registrada da Université
            Sherbrooke utilizada sob licença pela Sipro Lab Telecom Inc.{" "}
          </p>
          {/* SRI and Systran */}
          <p></p>
          <table>
            <tbody>
              <tr>
                <td id="sri">
                  <img
                    alt=""
                    align="middle"
                    src={require("../../../assets/gif/sri.gif")}
                  />
                  <br />
                </td>
                <td
                  style={{
                    color: "#999999",
                    fontSize: "10pt",
                    fontFamily: "arial",
                  }}
                >
                  {" "}
                  SRI International, o logotipo da SRI International, EduSpeak e
                  o logotipo do EduSpeak são marcas comerciais ou registradas da
                  SRI International.
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          {/*p> SYSTRAN &#233; uma marca comercial ou de servi&#231;o registrada da SYSTRAN Software, Inc., e o logotipo da SYSTRAN &#233; uma marca de servi&#231;o registrada da SYSTRAN Software, Inc. </p*/}
          <p>
            {" "}
            AP/Wide World Photos é uma marca comercial da Associated Press.{" "}
          </p>
          <p> PhotoDisc é uma marca registrada da PhotoDisc, Inc. </p>
          <p>
            {" "}
            Business English Pod, www.businessenglishpod.com e os logotipos do
            Business English Pod são marcas comerciais da Business English Pod
            Limited.{" "}
          </p>
          <p>
            {" "}
            Todas as outras marcas comerciais pertencem aos seus respectivos
            proprietários.{" "}
          </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default PtBrContent;
