// @ts-nocheck
import React from "react";

function RuRuContent() {
  return (
    <>
      <div id="agreement_container">
        <div id="PrivacyPolicyDiv">
          <div
            style={{ fontFamily: "arial", color: "#999999", fontSize: "10pt" }}
          >
            <strong>
              <center>GLOBALENGLISH PRIVACY STATEMENT</center>
            </strong>
            <strong>Introduction</strong>
            <br />
            <br />
            <p>
              GlobalEnglish Corporation and its
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://geprodls.blob.core.windows.net/legal/GlobalEnglish%20&%20Affiliates_Privacy_v2.pdf"
              >
                affiliates
              </a>{" "}
              (collectively referred to as “GlobalEnglish” or the “Company”) are
              committed to protecting the privacy of individuals who interact
              with them, whether as visitors to the Company’s websites
              (“Visitors”) or as individuals who register to use GlobalEnglish’s
              learning solutions (referred to as “Users” or “Subscribers”).
            </p>
            <p>
              Users may register individually on their own for our Services
              (which is referred to as a “B2C transaction”) or through our
              corporate accounts when the organizations and companies (referred
              to as “Customers”) subscribe to GlobalEnglish
              Software-as-a-Service solutions for their employees, contractors
              and representatives (a “B2B transaction). With respect to
              corporate accounts, in addition to User’s information,
              GlobalEnglish also collect a limited number of personal
              information of its business contacts in these corporate accounts
              (for example, the HR and Legal representative administering our
              contract with that organization).
            </p>
            <p>
              This Privacy Statement describes how GlobalEnglish treats
              information that can be used to directly or indirectly identify an
              individual (“Personal Data”) and how it ensures compliance with
              the new European General Data Protection Regulation (“GDPR”) and
              privacy requirements in relation to the use of the applications
              and services it offers (“Services”). The Privacy Statement
              together with the
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://globalenglish.com/company/about-us/legal/globalenglish-cookie-policy/"
              >
                Cookie Policy
              </a>{" "}
              form GlobalEnglish’s Privacy Policy.
            </p>
            <p>
              All processing of Personal Data provided by you as a Visitor, User
              or Customer’s representatives in connection with your use of our
              Services and all Personal Data that may derive from the Personal
              Data you provide is governed by this GlobalEnglish Privacy
              Statement.
            </p>
            <p>
              <b>
                Personal Data is processed only in accordance with the
                applicable data privacy law and/or with your consent. In a B2B
                transaction, GlobalEnglish’s Processing of your Personal Data is
                subject to the instructions of its Customer, your employer.
              </b>
            </p>
            <p>
              In this Privacy Statement, the terms “You” or “Your” is used to
              refer to individuals, whether Users, Visitors or Customers’
              representatives who interact with GlobalEnglish. “GlobalEnglish”,
              “Company”, “we”, “our” and “us” are terms used to refer to
              GlobalEnglish and its employees and subcontractors. The term
              “Services” includes (a) access to GlobalEnglish’s learning
              solutions, web services, offerings, content, tutorials, classes,
              coaching, training, email communications regarding those and
              events.
            </p>
            <p>
              <br />
              <strong>Data Controller and Contracting Parties</strong>
              <br />
            </p>
            <p>
              GlobalEnglish will be acting as the "Controller" of the Personal
              Data you provide directly to us via a “Contact Us” form, when
              creating an account, or via other contact methods.
            </p>
            <p>
              If you reside in the European Union (“EU”), the European Economic
              Area (“EEA”) or Switzerland, GlobalEnglish France Sarl
              (“GlobalEnglish France”) shall be the controller of your Personal
              Data processed by GlobalEnglish and you will be deemed to enter
              into the User Agreement with GlobalEnglish France.
            </p>
            <p>
              If you reside outside of the EU, EEA or Switzerland, GlobalEnglish
              Corporation (U.S. entity) will be the controller of your Personal
              Data and the User Agreement you will execute shall be deemed to
              have been entered with GlobalEnglish Corporation.
            </p>
            <p>
              In a B2B transaction, GlobalEnglish will be acting as the
              "Processor" of your personal data transmitted by our Customer. Our
              Customer will be acting as Controller of your information. As
              Processor, we do not own, control, or direct the use of any data
              provided to us by our Customers and we can only act upon their
              instructions. As a User, you will be asked to sign a User
              Agreement with GlobalEnglish but will have to direct enquiries
              about your Personal Data to your employer, the Data Controller.
            </p>
            <p>
              <br />
              <strong>
                Why is GlobalEnglish Collecting Your Personal Data?
              </strong>
              <br />
            </p>
            <p>
              As a general principle, GlobalEnglish shall request you to provide
              certain Personal Data only to the extent necessary to:
            </p>
            <p></p>
            <ul style={{ listStyleType: "none" }}>
              <li>
                (a) Provide its Services to you and if applicable, your
                employer, communicate with you and your employer as the case may
                be about the use of our Services, provide performance reports,
                personalize the help and instructions our customer support team
                provides to you, provide troubleshooting and generally assist
                you as you are embarking on this English learning journey with
                us. Some Personal Data need to be collected and processed prior
                to entering into a contract with you or your employer, as the
                case may be for the provision of Services. This would include
                business contact information and conversation or email exchange
                data between you, as a representative of the employer and our
                Sales force or a representative of our Customer Success
                Management Team;
              </li>
              <br />
              <li>
                (b) Improve its Services by gathering metrics to better
                understand the needs of Users, requesting feedback from the
                Users through questionnaires, creating usage statistics,
                evaluating and updating its content, measuring the effectiveness
                of its Services, creating anonymized data sets and develop new
                products and Services which will, ultimately benefit you;
              </li>
              <br />
              <li>
                (c) Ensure compliance with its legal obligations, including,
                e.g. the export laws of the European Union and the United States
                applicable to its products, technologies and Services and the
                specific requirements under U.S. laws to protect children’s
                privacy;
              </li>
              <br />
              <li>
                (d) Prevent and stop misuse of its Services, illegal activities
                and fraud;
              </li>
            </ul>
            <p />
            <p>
              <br />
              <strong>What GlobalEnglish Does Not do?</strong>
              <br />
            </p>
            <p>
              We do not share your Personal Data to third parties for their own
              marketing purposes.
            </p>
            <p>
              <br />
              <strong>
                Can you Choose Not to Disclose Personal Data or Opt out of
                Receiving Communications?
              </strong>
              <br />
            </p>
            <p>
              Your provision of Personal Data and your consent for us to process
              such Personal Data are entirely voluntary and you may choose not
              to provide us with any Personal Data.
            </p>
            <p>
              We encourage you not to share sensitive information such as, e.g.
              health-related information, sexual life, criminal record,
              membership in a trade union or information about your religion and
              philosophical beliefs, when using our Services. You also should
              not post or share Personal Data that you would not want to be
              publicly available.
            </p>
            <p>
              However, because certain Personal Data is critically needed for us
              to provide you with access to our English learning tools, process
              your order, respond to your questions about the Services and give
              you the customer support you need to fully enjoy our Services,
              GlobalEnglish will not be able to take any action without such
              Personal Data. Furthermore, once you have registered for our
              Services, we may need to communicate by phone or email with you to
              resolve a technical difficulty or need to send notices and other
              disclosures required by law. These communications are not
              marketing related but merely required for the relevant business
              relationship. We therefore need to process certain essential
              Personal Data and Users cannot opt out of these communications.
            </p>
            <p>
              With regard to marketing-related types of communications (i.e.
              emails and phone calls), GlobalEnglish shall (a) where legally
              required first ask you to opt in to receive such communications
              and (b) provide you with the opportunity to opt out if you do not
              want to receive further marketing-related types of communications
              from us.
            </p>
            <p>
              <br />
              <strong>
                What Personal Data and Other Information Does GlobalEnglish
                Process and For What Purposes?
              </strong>
              <br />
            </p>
            <p>
              Whether you wish to obtain further information about our Services,
              individually register to take advantage of our services or are
              registered through your employer to use them, GlobalEnglish will
              need to collect and process certain information about you. We
              collect this information with your consent directly from you, from
              your employer and automatically through our website and services.
              This information includes:
            </p>
            <p>
              <br />
              <strong>(A) Usage Information</strong>
            </p>
            <p>
              Like most Software-as-Service companies, we may collect certain
              technical information about your use of the website and mobile
              application when you visit us, and generally this is not
              information that could be used to identify you without reference
              to other information (collectively, “Usage Information”). For
              example:
            </p>
            <ul style={{ listStyleType: "none" }}>
              <li>
                (1) We record usage data when you visit our website and use our
                Services, including our mobile application, when you view or
                click on content (e.g. a learning video), interact with learning
                activities, complete assessments, and so forth.
              </li>
              <br />
              <li>
                (2) We collect and record information about your usage and
                browsing activities. For example, we may collect your internet
                protocol (IP) address, operating system, browser type, the
                identity of your internet service provider (ISP), unique device
                identifier, the date and time of your visit, the number and
                duration of your page visits, the pages visited, how you were
                directed to the Website (URLs), what country your visit
                originates from, the number of clicks you make when you use the
                website or application. We collect and may store information
                that your web browser or mobile device provides to us in
                connection with your use of our Services.
              </li>
            </ul>
            <p />
            <p>
              We use the Usage Information to optimize your visit, make your
              browsing or user experience as effective as possible, and let you
              know about new learning content available or of interest for you
              and features that we think will interest you.
            </p>
            <p>
              On our website, but not currently on the mobile device, you can
              control cookies through your browser settings and opt-out from our
              use of cookies and similar tracking technologies. However, if you
              disallow tracking, your use of GlobalEnglish sites may be
              seriously impaired and we will not be able to make suggestions to
              facilitate and enhance your learning experience.
            </p>
            <p>
              <br />
              <strong>
                Our website does not allow third parties to gather information
                about you over time and across sites.
              </strong>
              <br />
            </p>
            <p>
              For more detailed information, please read our
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://globalenglish.com/company/about-us/legal/globalenglish-cookie-policy/"
              >
                Cookie Policy
              </a>{" "}
              which is part of our Privacy Policy together with the present
              Privacy Statement. For our Visitors, you can opt out of our
              tracking mechanisms on our website. Again, note that the mobile
              device does not yet allow for such option.
            </p>
            <p>
              Usage Information collected from you is used for various purposes,
              such as:
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>to improve our Services;</li>
              <li>to customize the user experience of the Services;</li>
              <li>to administer the Services.</li>
            </ul>
            <p />
            <p>
              <br />
              <strong>
                (B) Users’ Personal Data in the form of account information and
                content
              </strong>
              <br />
            </p>
            <p>
              GlobalEnglish processes two categories of Personal Data pertaining
              to the Users of its Services: (1) Account information of the Users
              of its Services and (2) content provided by Users and
              GlobalEnglish’s Customer Success Management Team in the course of
              the provision of Services. Again, this Personal Data is collected
              with your consent and the consent of your employer in a B2B
              transaction and is necessary to provide the Services you seek from
              us.
            </p>
            <p>(1) Account Information:</p>
            <p>
              When you enquire about our services or create an account on our
              platform, we collect Personal Data that include both required and
              optional information such as:
            </p>
            <p>(a) Required:</p>
            <p>
              Your login name, email address, password, country for all services
            </p>
            <p />
            <p>
              For blended learning solutions such as Virtual Group, Focus,
              Coach, Executive Coach, Professional and future blended solutions
              that involve interactions with teachers: city, time zone, mobile
              and work phone number and skype (or similar communications
              technologies), employer’s name, preferred interface language
            </p>
            <p>(b) Optional:</p>
            <p>
              First name, last name, Job title, manager’s email (if the feature
              is enabled at the request of your employer), study goals.
            </p>
            <p>
              Please note:
              <br /> For those Users who purchase our Services privately or
              renew your subscriptions through web sites using their credit
              card, such B2C transactions are supported through PayPal or
              similar online payment systems, which process the transaction.
              GlobalEnglish does not collect or store your credit card
              information.
            </p>
            <p>
              (2) Your Content:
              <br />
              <br /> When you use our Services, for example to take assessment
              exams, ask questions, complete activities as you progress through
              our English learning solutions, use our class scheduling services,
              request a translation or support in general, take advantage of our
              community-based services (as described below) to post on
              discussion boards and upload content on that particular social
              media tool, we use Personal Data you shared with us on
              registration to assist you.
            </p>
            <p>
              We also track your learning progress by saving and processing
              placement test results, scores, voice recordings that help us
              provide feedback on pronunciation and vocabulary, performance
              improvement recommendations made by your teacher and other content
              you choose to upload, including survey data. Recording of Skype or
              telephone calls or chat session is always done with your knowledge
              and consent
            </p>
            <p>
              Some of our Services have social media tool-like functionality, so
              your interactions within these types of Services are generally
              visible to other users within the community, depending on your
              settings and groups you are part of, which may be administered by
              you or by your organization. Information may include registration
              Information, though you must separately populate your community
              profile within the product. Your full community profile is visible
              to everyone who uses this Service. If you do not want information
              in your profile to be visible outside your employer’s
              organization, then you should not use the community-based Service.
            </p>
            <p />
            <p>
              <br />
              <strong>(C) Business Contact information</strong>
              <br />
            </p>
            <p>
              GlobalEnglish keeps Personal Data of its business contacts such as
              the first and last name, professional address, job title, phone
              number and email address of its customers’ representatives
              administering service agreements and interacting with its Sales
              force and its Customer Success Management Team.
            </p>
            <p>
              If you are one of our business contacts, this information is used
              to keep you up-to-date about our Services and products and inform
              you, where permitted according to local laws, about new products
              or Services and any Services and products similar to or related to
              products and Services which the corporate customer has already
              purchased in the past. We may also invite you to webinars and
              seminars or events and may reach out to you afterwards for
              feedback regarding improvements of the relevant webinar, seminar,
              event, product or Service. You may also receive marketing-related
              newsletters about our Services.
            </p>
            <p>
              You may object at any time to GlobalEnglish’s use of this kind of
              Personal Data by email to
              <a href="mailto:privacy@globalenglish.com">
                privacy@globalenglish.com
              </a>
              . If you do so, GlobalEnglish shall cease using your Personal Data
              and shall remove it from its system unless it is permitted to use
              such Personal Data for other purposes set forth in this Privacy
              Policy.
            </p>
            <p>
              Please note that if you are interested in attending one of our
              events, GlobalEnglish may ask for information about your health
              for the purpose of identifying and attending to the needs of
              individuals who may have a disability or dietary requirements
              which GlobalEnglish wants to be respectful of and accommodate
              during the event. Any use of such information is based on the
              consent you grant. You are of course free not to provide such
              information but note that in this case, GlobalEnglish will not be
              able to take the necessary precautions to ensure your wellness
              during the event.
            </p>
            <p>
              <br />
              <strong>Links to other Websites</strong>
              <br />
            </p>
            <p>
              Our website may contain links to the websites of foreign entities
              (i.e. entities that are not part of the GlobalEnglish group of
              companies). GlobalEnglish is not responsible for the privacy
              practices or content of websites outside of the GlobalEnglish
              group of companies. We advise you to carefully read the privacy
              policies of these sites.
            </p>
            <p>
              <br />
              <strong>
                Where will your Personal Data be Stored and Processed?
              </strong>
              <br />
            </p>
            <p>
              GlobalEnglish’s cloud servers processing Personal Data are located
              in the EEA with Microsoft Azure and Amazon AWS which have both
              committed to comply with the GDPR requirements.
            </p>
            <p>
              As a global company, GlobalEnglish Corporation has affiliates
              within and outside the EEA, each with employees in the Customer
              Success Management and Support Teams dedicated to provide you with
              the best learning experience, regardless of the time zone in which
              you live. For purposes of the Services, your Personal Data and
              usage information may be transferred to affiliates of
              GlobalEnglish in countries outside of the EEA, including countries
              found not to offer a level of protection of Personal Data
              comparable to the level of protection offered in the EU and
              Switzerland. Such transfer occurs under the EU Model Clauses or
              Standard Contractual Clauses according to the
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://geprodls.blob.core.windows.net/legal/EU_Commission_Decision_v1.pdf"
              >
                EU Commission Decision
              </a>{" "}
              87/2010/EC or any future replacement until GlobalEnglish adopts
              Binding Corporate Rules (“BCR”) and have them approved in the EU.
            </p>
            <p>
              <br />
              <strong>Who can view your Personal Data?</strong>
              <br />
            </p>
            <p>
              Only those administrators of the Services which have a need to
              access your Personal Data for the Purposes described above can
              view your Personal Data, subject to confidentiality obligations.
              Those are members of:
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>The Customer Success Team</li>
              <li>The Customer Support Team</li>
              <li>The Account Operations Team</li>
              <li>The Teachers and Coaches Team</li>
              <li>The System Administrator</li>
              <li>The Sales Team for business contact information only</li>
            </ul>
            <p />
            <p>
              <br />
              <strong>Sharing of Your Personal Data with Third Parties</strong>
              <br />
            </p>
            <p>
              We may provide your Personal Data and usage information as set
              forth below and where Users’ consent has been granted.
              <br />
              <br /> <i>Third party service providers:</i> (companies,
              distributors and independent subcontractors) to perform services
              on our behalf, including e-mail delivery, cloud servers (as
              described above), coaching sessions, translation in real time,
              customer service and data analysis. These sub-processors may
              access your Personal Data and usage information in the course of
              and for the sole purpose of, providing Services to you and if
              applicable, your employer under the relevant service agreement.
              All third party service providers, including those which have
              access to your business contact information, are under
              GlobalEnglish’s instructions and cannot use your Personal Data for
              other purposes than to meet GlobalEnglish’s instructions.
              <br />
              <br /> We require such third-party service providers to comply
              with the EU General Data Protection Regulation (GDPR), maintain
              the confidentiality of the information disclosed to them and to
              not use your Personal Information for any purpose other than to
              provide Services to GlobalEnglish. We use Data Processing
              Agreements and the EU Model Clauses.
              <br />
              <br /> Our choice of third party service providers is subject to
              change in our sole discretion and without updating this Privacy
              Statement. Our contracts with these providers guarantee that our
              information will remain confidential and meet GDPR requirements;
              however, no cloud provider and no transmission of data over the
              Internet can be guaranteed to be 100% secure.
              <br />
              <br />{" "}
              <i>
                Compliance with Our Services Agreement with Your Employer:
              </i>{" "}
              If applicable, your Personal Data is also being shared with your
              organization account manager for the purpose of helping
              GlobalEnglish improve the learning experience for you.
              <br />
              <br /> <i>Compliance with Legal requirements:</i> We may disclose
              any information, including Personal Data, we deem necessary to
              comply with any applicable law, regulation, legal process (e.g.
              subpoenas and warrants) or governmental request. The customer
              (employer) and natural persons concerned will be notified of the
              disclosure, as much as possible ahead of the disclosure, provided
              this notification does not conflict with any applicable law,
              regulation, legal process or governmental request. GlobalEnglish
              shall make reasonable efforts to limit the disclosure of Personal
              Data to what is strictly necessary.
              <br />
              <br />
              <i> Protection of Our Rights:</i> We may disclose any information
              where we believe it necessary to respond to claims asserted
              against us, to enforce our rights, for fraud prevention or to
              protect the safety and security of our website, our users or other
              persons and property.
              <br />
              <br /> <i>Business Transition: </i>In the event GlobalEnglish
              undergoes a business transition, such as a major investment
              transaction, merger, acquisition by another company,
              restructuring, sale of all or a portion of its assets, bankruptcy
              or similar event, we may transfer your Personal Information to the
              actual or intended successor organization in such transaction
              subject to confidentiality restrictions and compliance of that
              third party with the GDPR. If the Privacy Policy of GlobalEnglish
              changes as a result of the business transition, GlobalEnglish will
              notify you via email and will post a notice on the Website
              alerting you to the business transition prior to transferring your
              Personal Information, provided we are at liberty to publicize a
              potential transaction.
            </p>
            <p>
              <br />
              <strong>How We protect Your Personal Data</strong>
              <br />
            </p>
            <p>
              The security of your Personal Data is important to us. We use
              Secure Socket Layer (SSL) technology to secure user credentials
              while authenticating access to all Services, and when transmitting
              Personal Data. Any data transmitted between production systems is
              done via secure VPN/SSL/FTP channels.
              <br />
              <br /> GlobalEnglish has administrative and physical security
              measures to protect user’s Personal Data and usage information,
              online and offline. Our employees, contractors, and third-party
              service providers are generally subject to restrictions which
              limit their use of information obtained from GlobalEnglish to the
              business purpose of the relationship for which we contract.
              GlobalEnglish also takes reasonable steps to protect information
              we collect from you to prevent loss, misuse and unauthorized
              access, disclosure, alteration, and destruction. All Personal Data
              related to the account and profile information is encrypted via
              SSL during transmission to GlobalEnglish over the internet. The
              servers on which information is stored are kept in a controlled
              environment with limited access.
              <br />
              <br /> However, no website, Internet transmission or security
              system is completely secure and impenetrable. We cannot guarantee
              that unauthorized access, hacking, data loss, or other breaches
              will never occur. In addition, we cannot guarantee that any
              passively-collected documents you store, or discussions which
              occur, on our systems are maintained at adequate levels of
              protection to meet specific needs or obligations you may have
              relating to that information.
              <br />
              <br /> Your account information and access to our service is
              accessible only through the use of an individual username and
              password. We urge you to take steps to keep your Personal Data
              safe by choosing a strong password and keeping it private and
              logging out of your user account and closing web browsers when you
              finish using our Services on a shared or unsecured device. Please
              advise us immediately if you believe your password has been
              misused. In addition, always logout and close your browser when
              you finish your session.
            </p>
            <p>
              <br />
              <strong>
                Please note that we will never ask you to disclose your password
                in an unsolicited phone call or email.
              </strong>
              <br />
              <br />
              <strong>Duration of Processing of Personal Data</strong>
              <br />
            </p>
            <p>
              GlobalEnglish shall store your Personal Data:
              <br />
              <br /> (a) only for as long as required to fulfill the purposes
              set forth above;
              <br />
              <br /> (b) where we have a legitimate interest in processing your
              Personal Data to improve our Services, until you object to our use
              of your Personal Data; or
              <br />
              <br /> (c) until you withdraw the consent you provided earlier to
              the Processing of your Personal Data.
              <br />
              <br /> In practice, this means that GlobalEnglish may retain your
              Personal Data and Usage Information for the duration of the
              provision of its Services to you and in general, for three (3)
              years thereafter. However, you can object and withdraw your
              initial consent in writing.
              <br />
              <br /> If you have been registered through your employer (our
              “Customer”) in a B2B transaction and no longer want GlobalEnglish
              to process your Personal Data, you must contact your employer
              first, as it is the Data Controller in charge of your Personal
              Data. We, as Data Processor, will work with your employer to
              address your request. You must ask your organization, our
              customer, to request deletion of any individual user information
              associated with its account.
              <br />
              <br /> Your employer, as Data Controller, may also request that
              GlobalEnglish acting in its capacity of Data Processor destroys or
              surrenders all Personal Data to it within thirty (30) days
              following the termination or expiration of the relevant service
              agreement, in which case we will follow the instructions of your
              employer and erase your Personal Data.
              <br />
              <br /> After the three years period, we reserve the right to
              permanently delete all Personal Data and other information related
              to all accounts.
              <br />
              <br /> However, when laws and regulations require GlobalEnglish to
              retain Personal Data for a longer period or where your Personal
              Data is required for GlobalEnglish to assert or defend against
              legal claims, GlobalEnglish shall retain your Personal Data until
              the end of the relevant mandatory retention period or until the
              claims in question have been settled.
            </p>
            <p>
              <br />
              <strong>
                Your Rights to Access and Update Your Personal Data
              </strong>
              <br />
            </p>
            <p>
              You can request from GlobalEnglish at any time information about
              which Personal Data we process about you and request correction or
              deletion of such Personal Data. To inquire about which items of
              Personal Information GlobalEnglish possesses about you or to
              request its modification, please email us at
              <a href="mailto:customersupport@globalenglish.com">
                customersupport@globalenglish.com
              </a>
              .
              <br />
              <br /> As a User of our Services, you can also access your profile
              directly and make certain changes and edits yourself to certain
              Personal Data pertaining to your account and profile information.
              To the extent you have registered as a user of our Services
              through your employer, your employer, as Data Controller, may
              restrict certain access and editing capabilities.
              <br />
              <br /> However, please note that:
            </p>
            <ul style={{ listStyleType: "none" }}>
              <li>
                (a) GlobalEnglish can delete your Personal Data only if there is
                no statutory obligation or prevailing right of GlobalEnglish to
                retain it;
              </li>
              <br />
              <li>
                (b) Once your Personal Data is deleted, you will not be able to
                continue to use any of our Services with that user account;
              </li>
              <br />
              <li>
                (c) Users do not have the capability to edit or delete certain
                Personal Data, such as e.g. their scores or their answers on
                tests completed.
              </li>
            </ul>
            <br /> You can also request that GlobalEnglish restrict your
            Personal Data from further processing in any of the following
            events:
            <br />
            <ul style={{ listStyleType: "none" }}>
              <li>
                (a) You have reasonable ground to believe that the Personal Data
                is incorrect, in which case you may request that Processing of
                that Personal Data shall be suspended for as long as required to
                check the accuracy of the relevant Personal Data;
              </li>
              <br />
              <li>
                (b) You request that GlobalEnglish retain your Personal Data for
                future use of our Services, for example after you have left the
                employer through which you registered for our Services and would
                like to subscribe directly with us or continue using our
                Services while with your next employer.
              </li>
            </ul>
            <br /> Remember that if you registered for our services through your
            employer in a B2B transaction, you must make those requests to your
            employer. GlobalEnglish shall make reasonable efforts to work in
            collaboration with your employer to address your request and keep
            you updated on the status of the processing of your request.
            <p />
            <p>
              <br />
              <strong>Your California Privacy Rights</strong>
              <br />
              <br /> California law permits users who are California residents
              to request and obtain from us, once a year, free of charge, a list
              of the third parties to whom we have disclosed their Personal Data
              (if any) for direct marketing purposes in the prior calendar year,
              as well as the types of Personal Data we disclosed to those
              parties. If you are a California resident and would like to
              request this information, please submit your request to
              <a href="mailto:privacy@globalenglish.com">
                privacy@globalenglish.com
              </a>
              .
            </p>
            <p>
              <br />
              <strong>Your Right to Data Portability</strong>
              <br />
              <br /> You have the right to request a copy of your data at any
              time as long as your account has not been erased. Upon receiving
              your request, GlobalEnglish will provide you all the data directly
              related to the personal account of your choice, within a
              reasonable timeframe.
            </p>
            <p>
              <br />
              <strong>Your Right to Lodge a Complaint</strong>
              <br />
              <br /> If you believe that GlobalEnglish is not processing your
              Personal Data in accordance with its policies set out herein or
              applicable EEA data protection laws, you can at any time lodge a
              complaint with the data protection authority of the EEA country
              where you live.
            </p>
            <p>
              <br />
              <strong>Our Policies Concerning Children</strong>
              <br />
              <br /> Our Services are not intended for children under the age of
              13 (14 for anyone living in Spain or South Korea) and we do not
              knowingly collect any Personal Data from anyone under such age or
              knowingly allow such person to register. Children under the age of
              13 (or 14 in Spain and South Korea) should not use our Services at
              any time. In the event that we become aware that we have
              inadvertently gathered Personal Information from children under
              the age of 13 (or 14) without parental consent, we will use
              reasonable efforts to erase such information from our records.
            </p>
            <p>
              <br />
              <strong>Changes to Our Privacy Statement</strong>
              <br />
              <br /> If we decide to make material changes to our Privacy
              Statement, we will notify Users of the Services by sending a
              notice to the email address that you have provided in connection
              with your account and/or by placing a notice on our website. You
              should periodically check the Services for such notices, provided
              we have not arranged for your employer, when applicable, to notify
              their individual users of such changes. We will only use your
              Personal Data in accordance with the version of the Privacy
              Statement in place at the time you provided your information
              (including without limitation the opt-out provided herein), unless
              you later give your consent for us to do otherwise.
              <br />
              <br /> You acknowledge that your continued use of our Services
              after we publish or send a notice about our changes to this
              Privacy Statement or other policies that form part of our Privacy
              Policy means that the collection and processing of your Personal
              Data is subject to the updated Privacy Policy.
            </p>
            <p>
              <br />
              <strong>Contact Us</strong>
              <br />
              <br /> If you have any questions, comments or concerns regarding
              our Privacy Statement and/or practices, please send an email to:
              <a href="mailto:privacy@globalenglish.com">
                privacy@globalenglish.com
              </a>
              .
            </p>
            <p>
              GlobalEnglish
              <br /> Attn: Privacy Concerns/Data Protection Officer (“DPO”)
              <br /> Address: 1875 South Grant Street, Suite 700, San Mateo, CA
              94402 USA
              <br /> Phone: 82-2-6207-2401
              <br /> Emails:
              <a href="mailto:dpo@globalenglish.com">
                dpo@globalenglish.com
              </a>{" "}
              or
              <a href="mailto:privacy@globalenglish.com">
                privacy@globalenglish.com
              </a>
            </p>
            <p>
              <i>
                Last Modified : May 24th,2018 v.1<i></i>
              </i>
            </p>
            <i>
              <i></i>
            </i>
          </div>
          <i>
            <i></i>
          </i>
        </div>
        <i>
          <i></i>
        </i>
      </div>
    </>
  );
}

export default RuRuContent;
